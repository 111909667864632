export default function concatData(currentData, newData) {
  const concatContent = currentData.contents.concat(newData.contents);
  const mergePaging = {
    ...newData.paging,
    nbContents: currentData.paging.nbContents + newData.paging.nbContents
  };

  return {
    ...newData,
    contents: concatContent,
    paging: mergePaging
  };
}
