/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Content, FormattedProps } from './types';
import { BREAKPOINTS } from 'shared/components/presentational/Picture/constants';
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  ...content,
  pictures: {
    desktop: {
      url: content.mediaDesktop?.link.href ?? undefined,
      w: BREAKPOINTS.DESKTOP + 1
    },
    phone: {
      url: content.mediaMobile?.link.href ?? content.mediaDesktop?.link.href,
      w: BREAKPOINTS.DESKTOP
    },
    phoneMini: {
      url: content.mediaMobile?.link.href ?? content.mediaDesktop?.link.href,
      w: BREAKPOINTS.PHONE
    }
  },
  saleDeviceId: content.saledeviceId,
  buttonBack: {
    theme: themeFactory({
      backgroundColor: content.buttonBack.backgroundColor,
      borderColor: undefined,
      color: content.buttonBack.labelColor
    }),
    label: content.buttonBack.label,
    link: getLinkHrefWithAuthentication(content.buttonBack.link ?? '')
  },
  buttonNext: {
    theme: themeFactory({
      backgroundColor: content.buttonNext.backgroundColor,
      borderColor: undefined,
      color: content.buttonNext.labelColor
    }),
    label: content.buttonNext.label,
    link: getLinkHrefWithAuthentication(content.buttonNext.link ?? '')
  },
  content: content.content,
  popin: {
    template: content.popins[0].template,
    medias: {
      desktop: {
        url: content.popins[0].mediaDesktop?.link.href ?? undefined,
        w: BREAKPOINTS.DESKTOP + 1
      },
      phone: {
        url:
          content.popins[0].mediaMobile?.link.href ??
          content.popins[0].mediaDesktop?.link.href,
        w: BREAKPOINTS.DESKTOP
      },
      phoneMini: {
        url:
          content.popins[0].mediaMobile?.link.href ??
          content.popins[0].mediaDesktop?.link.href,
        w: BREAKPOINTS.PHONE
      }
    },
    title: content.popins[0].title,
    text: content.popins[0].text,
    button: {
      theme: themeFactory({
        backgroundColor: content.popins[0].button.backgroundColor,
        borderColor: undefined,
        color: content.popins[0].button.labelColor
      }),
      label: content.popins[0].button.label,
      link: getLinkHrefWithAuthentication(content.popins[0].button.link ?? '')
    },
    backLink: {
      label: content.popins[0].backLink.label,
      url: getLinkHrefWithAuthentication(content.popins[0].backLink.link)
    }
  }
});
