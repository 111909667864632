import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 24 24" width={width} height={height} className={className}>
    <g stroke="currentColor" strokeWidth={2} fill="none" fillRule="evenodd">
      <path d="M12 3.5c4.7 0 8.5 3.8 8.5 8.5s-3.8 8.5-8.5 8.5-8.5-3.8-8.5-8.5S7.3 3.5 12 3.5z" />
      <path d="M8.5 13.4l2.5 2.5 4.5-7.8" />
    </g>
  </svg>
);
