export default {
  wrapper: {
    flexDirection: 'column'
  },
  text: {
    textAlign: 'center',
    marginTop: '20px'
  },
  image: {
    maxWidth: '530px'
  }
};
