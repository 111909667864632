import styled from 'styled-components';

// global
import BaseSliderOrList from 'shared/components/presentational/SliderOrList/SliderOrList';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
// Shared
import {
  Banner,
  Wrapper as HeroBannerWrapper
} from '../../hero-banner/styles/components';
import { SHIFT_VERTICAL_MARGIN_TOP } from '../../shared/styles/constants';
// Local
import { COVER_BANNER_PADDING_BOTTOM } from './constants';

export const Wrapper = styled(HeroBannerWrapper)`
  ${Banner} {
    padding-bottom: ${COVER_BANNER_PADDING_BOTTOM}px;
  }
`;

export const SliderOrList = styled(BaseSliderOrList)`
  ${mediaQueries.fromTablet`
    margin-top: -${SHIFT_VERTICAL_MARGIN_TOP}px;
  `}
`;
