import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 13"
    fill="none"
  >
    <path
      d="M9 10.5C9.55 10.5 10 10.95 10 11.5C10 12.05 9.55 12.5 9 12.5H7C6.45 12.5 6 12.05 6 11.5C6 10.95 6.45 10.5 7 10.5H9ZM12 5.5C12.55 5.5 13 5.95 13 6.5C13 7.05 12.55 7.5 12 7.5H4C3.45 7.5 3 7.05 3 6.5C3 5.95 3.45 5.5 4 5.5H12ZM15 0.5C15.55 0.5 16 0.95 16 1.5C16 2.05 15.55 2.5 15 2.5H1C0.45 2.5 0 2.05 0 1.5C0 0.95 0.45 0.5 1 0.5H15Z"
      fill="currentColor"
    />
  </svg>
);
