import React from 'react';

import { StyledButton, StyledLink } from './styles/components';
import Button from 'shared/components/presentational/Button/Button';
import { ActionTypes } from './types';

export interface CallToActionRendererProps {
  type: ActionTypes;
  label: string;
  link: string;
}

export default function CallToActionRenderer({
  type,
  label,
  link
}: CallToActionRendererProps) {
  if (type === 'hyperLink') {
    return <StyledLink to={link}>{label}</StyledLink>;
  }

  if (type === 'cta') {
    return (
      <StyledButton to={link}>
        <Button.children.Text>{label}</Button.children.Text>
      </StyledButton>
    );
  }

  return null;
}
