/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import omit from 'lodash/omit';

import TYPES from '../actions/types';

// -----------------------------------------------------------------------------
// EXPORTED REDUCER STATE TYPE

/* export type State = {
  questions: {
    byFormId: {}
  },
  answers: {
    byFormId: {}
  }
}; */

// -----------------------------------------------------------------------------
// PRIVATES

const defaultState = {
  questions: {
    byFormId: {}
  },
  answers: {
    byFormId: {}
  }
};

// -----------------------------------------------------------------------------
// REDUCER

export default function forms(state = defaultState, action) {
  switch (action.type) {
    case TYPES.SAVE_FORM:
      return {
        ...state,
        questions: {
          ...state.questions,
          byFormId: {
            ...state.questions.byFormId,
            [action.payload.id]: action.payload.questions
          }
        },
        answers: {
          ...state.answers,
          byFormId: {
            ...state.answers.byFormId,
            [action.payload.id]: action.payload.answers
          }
        }
      };
    case TYPES.CLEAR_FORMS:
      return defaultState;
    case TYPES.CLEAR_FORM:
      return {
        ...state,
        questions: {
          ...state.questions,
          byFormId: {
            ...omit(state.questions.byFormId, action.payload.id)
          }
        },
        answers: {
          ...state.answers,
          byFormId: {
            ...omit(state.answers.byFormId, action.payload.id)
          }
        }
      };
    default:
      return state;
  }
}
