/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Tile, {
  Props as TileProps
} from 'shared/components/presentational/Tile/Tile';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Grid from 'shared/components/presentational/Grid/Grid';
import { Props } from '../types';

/*
 * Main wrapper should be a section
 * in order to inherit margins
 */
const Wrapper = styled.section`
  position: relative;
  max-width: 1000px;
  margin: auto;
  ${mediaQueries.toTablet`
margin-left: 15px;
margin-right: 15px;
`};
  ${mediaQueries.toPhablet`
margin-left: 0;
margin-right: 0;
`};
`;

const COLS = {
  hd: 3,
  desktop: 3,
  tablet: 3,
  phablet: 1,
  phone: 1,
  phoneMini: 1
};

export default class BasicTiles extends PureComponent<Props> {
  public renderTile = (props: TileProps, index: number) => {
    const { backgroundColor, buttonColor } = this.props;
    return (
      <Tile
        key={`tile.${index}`}
        backgroundColor={backgroundColor}
        html={props.html}
        linkLabel={props.linkLabel}
        linkUrl={props.linkUrl}
        linkColor={buttonColor}
        pictureUrl={props.pictureUrl}
        pictureWidth={props.pictureWidth}
        pictureWrapperHeight={props.pictureWrapperHeight}
        pictureWrapperWidth={props.pictureWrapperWidth}
        disableLazy
      />
    );
  };

  public render(): React.ReactNode {
    const { tiles } = this.props;

    return (
      <Wrapper>
        <Grid cols={COLS}>{tiles.map(this.renderTile)}</Grid>
      </Wrapper>
    );
  }
}
