/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { BREAKPOINTS } from 'shared/components/presentational/Picture/constants';
import { Content, FormattedProps } from './types';
import { getLinkHref } from 'shared/helpers/uri';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => {
  return {
    pictogram: {
      desktop: {
        url: getLinkHref(content.icon.desktopMedia.link),
        w: BREAKPOINTS.DESKTOP + 1
      },
      tablet: {
        url: getLinkHref(
          content.icon.tabletMedia?.link || content.icon.desktopMedia.link
        ),
        w: BREAKPOINTS.DESKTOP
      },
      phone: {
        url: getLinkHref(
          content.icon.mobileMedia?.link || content.icon.desktopMedia.link
        ),
        w: BREAKPOINTS.TABLET
      },
      phoneMini: {
        url: getLinkHref(
          content.icon.mobileMedia?.link || content.icon.desktopMedia.link
        ),
        w: BREAKPOINTS.PHONE
      }
    },
    redirectLink: {
      ...content.redirectButton,
      href: getLinkHrefWithAuthentication(content.redirectButton.link)
    },
    contextCriteria: content.frontDisplayCriteria ?? null,
    ...content
  };
};
