/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

// eslint-disable import/prefer-default-export

import HttpClient from 'axios';

import { FETCHING_STATES } from '../../shared/reducers';

export const TYPES = {
  FETCH_SHOPS: {
    PENDING: 'SHOPS_FETCH_SHOPS_PENDING',
    SUCCESS: 'SHOPS_FETCH_SHOPS_SUCCESS',
    ERROR: 'SHOPS_FETCH_SHOPS_ERROR'
  },
  FETCH_SHOPS_DETAILS: {
    PENDING: 'SHOPS_FETCH_DETAILS_PENDING',
    SUCCESS: 'SHOPS_FETCH_DETAILS_SUCCESS',
    ERROR: 'SHOPS_FETCH_DETAILS_ERROR'
  },
  FILTER_SHOPS: 'SHOPS_FILTER_SHOPS',
  RESET_FILTERS: 'SHOPS_RESET_FILTERS'
};

export const fetchShopsPending = ({ url }) => ({
  type: TYPES.FETCH_SHOPS.PENDING,
  payload: {
    url
  }
});

export const fetchShopsSuccess = payload => ({
  type: TYPES.FETCH_SHOPS.SUCCESS,
  payload
});

export const fetchShopsError = ({ error }) => ({
  type: TYPES.FETCH_SHOPS.ERROR,
  payload: {
    error
  }
});

export const fetchShopsDetailsPending = ({ url, id }) => ({
  type: TYPES.FETCH_SHOPS_DETAILS.PENDING,
  payload: {
    url,
    id
  }
});

export const fetchShopsDetailsSuccess = payload => ({
  type: TYPES.FETCH_SHOPS_DETAILS.SUCCESS,
  payload
});

export const fetchShopsDetailsError = ({ error, id }) => ({
  type: TYPES.FETCH_SHOPS_DETAILS.ERROR,
  payload: {
    error,
    id
  }
});

export const filterShops = (filterOn, value) => ({
  type: TYPES.FILTER_SHOPS,
  payload: {
    filterOn,
    value
  }
});

const shopsScopedState = state => state.shops.shared.list;
const shopsDetailsScopedState = state => state.shops.shared.shopsDetails;

export const shouldFetchShops = url => state => {
  if (shopsScopedState(state).fetchingState === FETCHING_STATES.PENDING) {
    return false;
  }
  return (
    !shopsScopedState(state).fetchedAt ||
    shopsScopedState(state).fetchingUrl !== url
  );
};

export const shouldFetchShopsDetails = id => state => {
  const value = shopsDetailsScopedState(state).byId[id];
  return !value || value === FETCHING_STATES.ERROR;
};

export const fetch = (
  pendingAction,
  successAction,
  errorAction,
  shouldFetch
) => ({ url, ...params }) => async (dispatch, getState) => {
  if (!shouldFetch(getState())) return Promise.resolve('No need to fetch');
  dispatch(pendingAction({ url, ...params }));

  try {
    const { data } = await HttpClient.get(url);
    return dispatch(
      successAction({
        ...params,
        url,
        data
      })
    );
  } catch (error) {
    return dispatch(errorAction({ error, ...params }));
  }
};

export const fetchShops = url =>
  fetch(
    fetchShopsPending,
    fetchShopsSuccess,
    fetchShopsError,
    shouldFetchShops(url)
  )({ url });

export const fetchShopsDetails = (url, id) =>
  fetch(
    fetchShopsDetailsPending,
    fetchShopsDetailsSuccess,
    fetchShopsDetailsError,
    shouldFetchShopsDetails(id)
  )({ id, url });

export const resetFilters = () => ({
  type: TYPES.RESET_FILTERS,
  payload: null
});
