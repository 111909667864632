import { AnyAction } from 'redux';

import { SAVE_OFFER_SUBSCRIPTION_FORM_ACTION_TYPE } from '../constants';
import { EncryptedFormData, FormDataToEncrypt } from '../types';
import encryptFormData from '../../shared/webservices/encryptFormData';

const saveOfferSubscriptionForm = (
  formDataToEncrypt: FormDataToEncrypt
) => async (dispatch: any) => {
  const encryptedFormData = await encryptFormData(formDataToEncrypt);

  dispatch({
    type: SAVE_OFFER_SUBSCRIPTION_FORM_ACTION_TYPE,
    payload: encryptedFormData
  });
};

interface SaveOfferSubscriptionFormAction {
  type: typeof SAVE_OFFER_SUBSCRIPTION_FORM_ACTION_TYPE;
  payload: EncryptedFormData;
}

export function isSaveOfferSubscriptionFormAction(
  action: AnyAction
): action is SaveOfferSubscriptionFormAction {
  return action.type === SAVE_OFFER_SUBSCRIPTION_FORM_ACTION_TYPE;
}

export default saveOfferSubscriptionForm;
