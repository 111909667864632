import React, { useCallback, useMemo } from 'react';
import {
  MenuPlacement,
  OptionProps,
  SingleValueProps,
  ValueType
} from 'react-select';
import styled from 'styled-components';

import Select from 'shared/components/presentational/Select';
import { OptionType } from 'shared/components/presentational/Select/Select';

const ZoneItem = styled.div`
  display: flex;
  padding: 10px 12px;
  cursor: pointer;
  align-items: center;
`;

interface Props {
  options: {
    label: string;
    value: string;
  }[];
  currentValue: string;
  onSelectionChanged: (arg1: string) => void;
  backgroundColor: string;
  textColor: string;
  hoverColor?: string;
  borderColor?: string;
  selectedColor?: string;
  menuPlacement?: MenuPlacement;
  border?: boolean;
}

const SelectField = ({
  options,
  backgroundColor,
  textColor,
  hoverColor,
  borderColor,
  selectedColor,
  menuPlacement = 'top',
  border = false,
  currentValue,
  onSelectionChanged
}: Props) => {
  const valueItemRenderer = useCallback(
    ({
      data,
      innerProps
    }: OptionProps<OptionType> | SingleValueProps<OptionType>) => (
      <ZoneItem {...innerProps}>
        <span lang={data.value}>{data.label}</span>
      </ZoneItem>
    ),
    []
  );

  const handleChange = (option: ValueType<OptionType>) => {
    if (option && 'value' in option) {
      onSelectionChanged(option.value);
    }
  };

  const value = useMemo(
    () => options.find(option => option.value === currentValue),
    [options, currentValue]
  );

  if (options.length === 0) {
    return null;
  }

  return (
    <Select
      menuPlacement={menuPlacement}
      isSearchable={false}
      isClearable={false}
      options={options}
      components={{
        Option: valueItemRenderer,
        SingleValue: valueItemRenderer
      }}
      value={value}
      onChange={handleChange}
      theme={{
        backgroundColor,
        textColor,
        hoverColor,
        borderColor,
        selectedColor
      }}
      border={border}
    />
  );
};

export default SelectField;
