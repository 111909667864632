import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.span<Props>`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  margin-left: 8px;
  :first-child {
    margin-left: 0px;
  }
`;

interface DefaultProps {
  visible?: boolean;
}

interface Props extends DefaultProps {
  children: ReactNode;
  className?: string;
}

export default class Text extends PureComponent<Props> {
  public static readonly displayName = 'Button.Text';

  public static readonly defaultProps = {
    visible: true
  };

  public render(): ReactNode {
    const { children, className, visible } = this.props;
    return (
      <Wrapper className={className} visible={visible}>
        {children}
      </Wrapper>
    );
  }
}
