export const getDomainURL = (state: any, brandNameFallback?: string) => {
  const hostname = state.hostname;
  const brandName = state.page?.data?.site?.domain?.brand;

  /* For development purposes: force http */
  if (process.env.NODE_ENV === 'development') {
    return `http://${hostname}`;
  }
  return brandName || brandNameFallback;
};
