import { css } from 'styled-components';
import get from 'lodash/get';
import { lighten, darken } from 'polished';

import { StyledLoader } from './core/Loader';
import isDarkColor from './helpers/isDarkColor';
import { Props } from './Button';
import {
  COLORS,
  BUTTON_SIZES,
  FONT_WEIGHTS,
  FONT_FAMILIES
} from 'shared/constants/theme';

export default css<Props>`
  height: ${BUTTON_SIZES.default}px;
  width: ${props => (props.fluid ? '100%' : 'auto')};
  padding: ${props => props.theme.button.padding || '0 40px'};
  cursor: pointer;
  border: ${props =>
    get(props, 'theme.button.normal.borderColor') === 'none'
      ? '0'
      : '1px solid'};
  border-radius: ${props => get(props, 'theme.button.borderRadius', '3px')};
  font-size: 1rem;

  transition: background-color 250ms ease;
  position: relative;
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};

  // inherited from wrapper
  display: ${props => (props.fluid ? 'flex' : 'inline-flex')};
  align-items: center;
  justify-content: center;

  ${StyledLoader} {
    border: 3px solid
      ${props =>
        isDarkColor(get(props, 'theme.button.disabled.backgroundColor'))
          ? COLORS.white
          : lighten(0.2, COLORS.boulder)} !important;
    border-top-color: ${props =>
      isDarkColor(get(props, 'theme.button.disabled.backgroundColor'))
        ? darken(0.2, COLORS.white)
        : COLORS.boulder} !important;
  }

  background-color: ${props =>
    get(props, 'theme.button.normal.backgroundColor')};
  border-color: ${props => get(props, 'theme.button.normal.borderColor')};
  color: ${props => get(props, 'theme.button.normal.color')};
  text-decoration: ${props => get(props, 'theme.button.normal.textDecoration')};

  &:hover {
    border-color: ${props => get(props, 'theme.button.hover.borderColor')};
    color: ${props => get(props, 'theme.button.hover.color')};
    background-color: ${props =>
      get(props, 'theme.button.hover.backgroundColor')};
    text-decoration: ${props =>
      get(props, 'theme.button.hover.textDecoration')};
  }

  &:disabled {
    cursor: inherit;
    border-color: ${props => get(props, 'theme.button.disabled.borderColor')};
    color: ${props => get(props, 'theme.button.disabled.color')};
    background-color: ${props =>
      get(props, 'theme.button.disabled.backgroundColor')};
    text-decoration: ${props =>
      get(props, 'theme.button.disabled.textDecoration')};
  }
`;
