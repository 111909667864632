import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSpring } from 'react-spring';
import throttle from 'lodash/throttle';

import { TIMINGS } from 'shared/constants/theme';
import {
  Wrapper,
  ValueLabel,
  Value,
  ArrowLeft,
  ArrowRight,
  SliderWrapper,
  SliderTrack
} from './styles/components';

interface Props<V> {
  name: string;
  options: {
    value: V;
    label: string;
  }[];
  value: V;
  onChange: (value: V) => void;
}

const doNothing = () => {};

const SliderToggle = <V extends string | number>(props: Props<V>) => {
  const { name, options, value, onChange, ...restProps } = props;

  if (!options || !options.length) {
    return null;
  }

  // VALUES / STATES

  const checkedOption = useMemo(
    () => options.find(option => option.value === value),
    [options, value]
  );

  const checkedIndex = useMemo(
    () => options.findIndex(option => option.value === value),
    [options, value]
  );

  const minScrollIndex = 0;

  const maxScrollIndex = options.length - 2;

  const [scrollIndex, setScrollIndex] = useState(minScrollIndex);

  const canScrollStart = minScrollIndex < scrollIndex;

  const canScrollEnd = scrollIndex < maxScrollIndex;

  const trackAnimation = useSpring({
    config: { mass: 0.1, tension: 200, friction: 20 },
    transform: `translate3d(${(scrollIndex * -100) / options.length}%, 0, 0)`
  });

  // ACTIONS

  const handleChange = useCallback(
    (value: V) => () => {
      onChange(value);
    },
    [onChange]
  );

  const handleChangeForNext = useCallback(
    throttle(
      () => {
        if (typeof checkedIndex === 'undefined') {
          return;
        }
        const nextIndex = checkedIndex + 1;
        const nextOption = options[nextIndex];
        if (!nextOption) {
          return;
        }
        onChange(nextOption.value);
      },
      TIMINGS.throttlePace,
      { leading: true, trailing: false }
    ),
    [onChange, options, checkedIndex]
  );

  const handleChangeForPrevious = useCallback(
    throttle(
      () => {
        if (typeof checkedIndex === 'undefined') {
          return;
        }
        const previousIndex = checkedIndex - 1;
        const previousOption = options[previousIndex];
        if (!previousOption) {
          return;
        }
        onChange(previousOption.value);
      },
      TIMINGS.throttlePace,
      { leading: true, trailing: false }
    ),
    [onChange, options, checkedIndex]
  );

  const handleKeyDown = useCallback(
    e => {
      if (e.key === 'ArrowLeft') {
        handleChangeForPrevious();
      }
      if (e.key === 'ArrowRight') {
        handleChangeForNext();
      }
    },
    [handleChangeForPrevious, handleChangeForNext]
  );

  const scrollStart = useCallback(() => {
    setScrollIndex(scrollIndex - 1);
  }, [scrollIndex]);

  const scrollEnd = useCallback(() => {
    setScrollIndex(scrollIndex + 1);
  }, [scrollIndex]);

  // REACTIONS

  useEffect(() => {
    const leftAlignmentScrollIndex = checkedIndex;
    const rightAlignmentScrollIndex = checkedIndex - 1;
    const deltaLeftAlignmentScrollIndex = Math.abs(
      scrollIndex - leftAlignmentScrollIndex
    );
    const deltaRightAlignmentScrollIndex = Math.abs(
      scrollIndex - rightAlignmentScrollIndex
    );

    // scroll the least possible
    if (
      deltaLeftAlignmentScrollIndex < deltaRightAlignmentScrollIndex &&
      deltaLeftAlignmentScrollIndex > 0
    ) {
      setScrollIndex(leftAlignmentScrollIndex);
    }

    if (
      deltaRightAlignmentScrollIndex < deltaLeftAlignmentScrollIndex &&
      deltaRightAlignmentScrollIndex > 0
    ) {
      setScrollIndex(rightAlignmentScrollIndex);
    }
  }, [checkedIndex]);

  if (options.length > 2) {
    return (
      <Wrapper {...restProps} onKeyDown={handleKeyDown} withArrows>
        {canScrollStart && <ArrowLeft onClick={scrollStart} />}
        <SliderWrapper>
          <SliderTrack style={trackAnimation}>
            {options.map(option => {
              const isChecked =
                checkedOption && option.value === checkedOption.value;
              return (
                <ValueLabel key={option.value} checked={isChecked}>
                  {option.label}
                  <Value
                    name={name}
                    value={option.value}
                    checked={isChecked}
                    onChange={
                      isChecked ? doNothing : handleChange(option.value)
                    }
                  />
                </ValueLabel>
              );
            })}
          </SliderTrack>
        </SliderWrapper>
        {canScrollEnd && <ArrowRight onClick={scrollEnd} />}
      </Wrapper>
    );
  }

  return (
    <Wrapper {...restProps} onKeyDown={handleKeyDown}>
      {options.map(option => {
        const isChecked = checkedOption && option.value === checkedOption.value;
        return (
          <ValueLabel key={option.value} checked={isChecked}>
            {option.label}
            <Value
              name={name}
              value={option.value}
              checked={isChecked}
              onChange={isChecked ? doNothing : handleChange(option.value)}
            />
          </ValueLabel>
        );
      })}
    </Wrapper>
  );
};

export default SliderToggle;
