import React from 'react';
import styled from 'styled-components';

import { COLORS } from 'shared/constants/theme';
import { FormattedAdvantage } from '../types';
import pxToRem from 'shared/helpers/styled-components/remHelper';
interface Props {
  advantage: FormattedAdvantage;
  color: string;
}

const TextWrapper = styled.div`
  font-size: ${pxToRem(14)};
  margin: 0px;
  color: ${props => props.color ?? COLORS.black};
  max-width: 120px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 120px;
`;

export const CustomTitle = styled.h3`
  color: ${props => props.color ?? COLORS.black};
  font-size: ${pxToRem(16)};
  padding: 6px 0px;
  margin: 0px;
`;

const FixedAdvantageCard = ({ advantage, color }: Props) => {
  return (
    <ContentWrapper>
      <CustomTitle color={color}>{advantage.title}</CustomTitle>
      {advantage.description ? (
        <TextWrapper color={color}>
          <p>{advantage.description}</p>
        </TextWrapper>
      ) : null}
    </ContentWrapper>
  );
};

export default FixedAdvantageCard;
