import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 34 34" width={width} height={height} className={className}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M2.9 0L34 31.1 31.1 34 0 2.9z" />
      <path d="M0 31.1L31.1 0l2.8 2.8L2.9 34z" />
    </g>
  </svg>
);
