import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 26 34" width={width} height={height} className={className}>
    <path
      d="M13 .3C5.9.3.1 6 .1 13.1.1 20.2 13 33.7 13 33.7s12.9-13.5 12.9-20.5S20.1.3 13 .3zm0 18.9c-4 0-7.3-3.3-7.3-7.3S9 4.7 13 4.7s7.3 3.2 7.3 7.3-3.3 7.2-7.3 7.2z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
