/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { BackendBlockContent } from 'shared/blocks/javascript/types';
import { Props as ComponentProps } from 'shared/blocks/javascript/components/Javascript';

export default (content: BackendBlockContent): ComponentProps => ({
  inlineScript: content.inlineScript,
  script: content.script,
  markup: content.markup,
  onload: content.onload
});
