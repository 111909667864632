import React, { useCallback } from 'react';
import styled, { keyframes } from 'styled-components';

import { Card } from '../Card/Card';
import Title from 'shared/components/presentational/Title';
import { FormattedTab } from 'shared/blocks/one-shop/shared/components/Tabs/component/Tabs/types';
import { OneShopArrow } from 'shared/components/presentational/Slider/styles/OneShopComponents';
import ResponsiveHoc, {
  ResponsiveHocProps
} from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';
import { CardContainer, CardsWrapper, Slider } from './styles/components';
const fadeAnimation = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
  `;

const FadeContainer = styled.div<{ isActive: boolean }>`
  animation: ${fadeAnimation} 500ms ease-in 0s;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  flex-direction: column;
  gap: var(--spacing-xl);
`;

interface Props {
  tab: FormattedTab<any>;
  isActive: boolean;
  tabIndex: number;
}
export const SLIDES_TO_SHOW = 3.2;
const MINIMUM_CARD_FOR_SLIDER = 4;

const Tab = ({
  tab,
  isActive,
  tabIndex,
  mediaQueries: { toDesktop }
}: Props & ResponsiveHocProps) => {
  const isSliderHidden =
    tab.contents.length < MINIMUM_CARD_FOR_SLIDER || toDesktop;

  const renderCards = useCallback(
    (product, index) => (
      <Card
        key={`${index}-${product.title}-${tabIndex}`}
        data-index={`${index}-${product.title}-${tabIndex}`}
        isSliderHidden={isSliderHidden}
        slidesToShow={SLIDES_TO_SHOW}
        {...product}
      />
    ),
    [tabIndex, isSliderHidden]
  );

  return (
    <FadeContainer isActive={isActive}>
      <Title titleLabel={tab.title} titleHtmlTag="h2" subtitle={tab.subtitle} />
      {isSliderHidden ? (
        <CardContainer>
          <CardsWrapper>{tab.contents.map(renderCards)}</CardsWrapper>
        </CardContainer>
      ) : isActive ? (
        <Slider
          showDots={false}
          isCenterMode={false}
          isInfinite={false}
          initialSlide={0}
          nextArrow={<OneShopArrow name="ArrowLeftOneShop" />}
          prevArrow={<OneShopArrow name="ArrowLeftOneShop" />}
          slidesToShow={SLIDES_TO_SHOW}
        >
          {tab.contents.map(renderCards)}
        </Slider>
      ) : null}
    </FadeContainer>
  );
};

export const TabContent = ResponsiveHoc(Tab);
