import React, { PureComponent } from 'react';

import ConfigHelper from 'shared/helpers/config';

export default WrappedComponent =>
  class ConfigHelperHOC extends PureComponent {
    render() {
      const publicConfig = ConfigHelper.getPublicConfig();

      return <WrappedComponent publicConfig={publicConfig} {...this.props} />;
    }
  };
