import { SEARCHBOX_MIN_CHARACTERS_REFINE } from './constants';
interface SearchEngineState {
  showShortcuts: boolean;
  showResults: boolean;
  searchValue: string;
  resultsDisplayMode: 'simple' | 'complete';
  indexFilters: string[];
  maxPagesForIndex: number;
}

export enum SearchEngineActionType {
  // when closing the search engine
  RESET = 'RESET',
  // on searchbox value change (as far as it's not empty)
  SEARCH = 'SEARCH',
  // on "See all results" button click (mobile or desktop-index-specific)
  CLICK_SEE_ALL_RESULTS = 'CLICK_SEE_ALL_RESULTS',
  // on index filter toggle
  TOGGLE_INDEX_REFINEMENT = 'TOGGLE_INDEX_REFINEMENT',
  // on click on "Clear filters" on mobile
  CLEAR_INDEX_REFINEMENT = 'CLEAR_INDEX_REFINEMENT',
  // on receiving search results
  RECEIVING_SEARCH_RESULTS = 'RECEIVING_SEARCH_RESULTS'
}

type SearchEngineAction =
  | { type: SearchEngineActionType.RESET; hasShortcuts: boolean }
  | { type: SearchEngineActionType.SEARCH; value: string }
  | { type: SearchEngineActionType.CLICK_SEE_ALL_RESULTS; indexName?: string }
  | { type: SearchEngineActionType.TOGGLE_INDEX_REFINEMENT; indexName: string }
  | { type: SearchEngineActionType.CLEAR_INDEX_REFINEMENT }
  | {
      type: SearchEngineActionType.RECEIVING_SEARCH_RESULTS;
      maxPagesForIndex: number;
    };

export const getInitialState = (hasShortcuts: boolean): SearchEngineState => ({
  showShortcuts: hasShortcuts,
  showResults: false,
  searchValue: '',
  resultsDisplayMode: 'simple',
  indexFilters: [],
  maxPagesForIndex: 0
});

export function reducer(
  state: SearchEngineState,
  action: SearchEngineAction
): SearchEngineState {
  switch (action.type) {
    case SearchEngineActionType.RESET:
      return getInitialState(action.hasShortcuts);
    case SearchEngineActionType.SEARCH:
      return {
        ...state,
        showResults:
          !action.value && state.resultsDisplayMode === 'simple'
            ? false
            : state.showResults,
        searchValue: action.value,
        maxPagesForIndex:
          !action.value || action.value.length < SEARCHBOX_MIN_CHARACTERS_REFINE
            ? 1
            : state.maxPagesForIndex
      };
    case SearchEngineActionType.RECEIVING_SEARCH_RESULTS:
      return {
        ...state,
        showResults:
          !!state.searchValue && state.resultsDisplayMode === 'simple'
            ? true
            : state.showResults,
        maxPagesForIndex: action.maxPagesForIndex
      };
    case SearchEngineActionType.CLICK_SEE_ALL_RESULTS:
      return {
        ...state,
        resultsDisplayMode: 'complete',
        indexFilters: action.indexName ? [action.indexName] : []
      };
    case SearchEngineActionType.CLEAR_INDEX_REFINEMENT:
      return {
        ...state,
        indexFilters: []
      };
    case SearchEngineActionType.TOGGLE_INDEX_REFINEMENT:
      return {
        ...state,
        indexFilters: state.indexFilters.includes(action.indexName)
          ? state.indexFilters.filter(v => v !== action.indexName)
          : [...state.indexFilters, action.indexName]
      };
    default:
      return state;
  }
}
