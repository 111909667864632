import queryString from 'query-string';
import curry from 'lodash/curry';
import get from 'lodash/get';
import includes from 'lodash/includes';

import createSilentLoginRedirectUrl from './createSilentLoginRedirectUrl';
import getLoginUrlFromPageWithFallback from './getLoginUrlFromPageWithFallback';
import getSilentLoginUrl from './getSilentLoginUrl';
import isLoginLinkExternal from './isLoginLinkExternal';
import { PAGE_TYPE } from '../constants';

export type AuthenticationParams = {
  client_id_external_authentication_URL: 'client_id_external_authentication_URL';
  portail_id_external_authentication_URL: 'portail_id_external_authentication_URL';
};

const createLoginRedirectUrl = (
  domainURL: string,
  zone: string | undefined,
  pageData: object,
  pagePath: string,
  forcedRedirectUrl: string | null
): string => {
  const loginUrl = getLoginUrlFromPageWithFallback(pageData, zone);
  const silentLoginUrl = getSilentLoginUrl(pageData);
  const authenticationIsExternal = isLoginLinkExternal(pageData);
  /* The current page is the default destination after login. */
  let refererUrl = pagePath;
  /*
   * If current page is authentication or silent authentication,
   * it cannot be used as destination: fallback on homepage
   */
  if (
    includes([PAGE_TYPE.LOGIN, PAGE_TYPE.SILENT_LOGIN], get(pageData, 'type'))
  ) {
    refererUrl = '/';
  }

  /* A redirectUrl can be contributed to replace our default destination. */
  if (forcedRedirectUrl) {
    refererUrl = forcedRedirectUrl;
  }

  if (authenticationIsExternal) {
    const redirectUri = `${domainURL}${createSilentLoginRedirectUrl(
      silentLoginUrl,
      loginUrl,
      refererUrl
    )}`;

    return queryString.stringifyUrl({
      url: loginUrl,
      query: {
        redirect_uri: redirectUri
      }
    });
  }
  return queryString.stringifyUrl({
    url: loginUrl,
    query: {
      // redirect_uri, instead of referer param, allows redirection to canalplus.com with auth token
      redirect_uri: refererUrl
    }
  });
};
export default curry(createLoginRedirectUrl, 5);
