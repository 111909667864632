/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2019 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import { Content, FormattedProps } from './types';

export default (content: Content): FormattedProps => ({
  apis: {
    authenticateApi: {
      url: get(content, ['apis', 'hodor_vod.authenticate', 'url'], ''),
      method: get(content, ['apis', 'hodor_vod.authenticate', 'method'], 'GET')
    },
    pathApiMask: {
      // @TODO Check remove path
      url: get(content, ['apis', 'hodor_vod.ws_from_path', 'url'], ''),
      method: get(content, ['apis', 'hodor_vod.ws_from_path', 'method'], 'GET')
    }
  },
  player: {
    bundleURL: get(content, ['player', 'bundleURL'], ''),
    contentId: get(content, ['player', 'contentId'], ''),
    config: {
      base: get(content, ['player', 'base'], ''),
      platform: get(content, ['player', 'platform'], ''),
      context: get(content, ['player', 'context'], ''),
      env: get(content, ['player', 'env'], ''),
      skin: get(content, ['player', 'skin'], ''),
      params: {
        offerZone: get(content, ['player', 'zone'], ''),
        vectorId: get(content, ['player', 'vectorId'], ''),
        autoplay: false
      }
    }
  }
});
