import Csa10 from 'shared/components/presentational/Icons/components/Csa10';
import Csa12 from 'shared/components/presentational/Icons/components/Csa12';
import Csa16 from 'shared/components/presentational/Icons/components/Csa16';
import Csa18 from 'shared/components/presentational/Icons/components/Csa18';

export const AVAILABLE_RATINGS = [2, 3, 4, 5];
export const CSA_MAPPING_SVG = {
  2: Csa10,
  3: Csa12,
  4: Csa16,
  5: Csa18
};
