/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Content, FormattedProps } from './types';
import setLocalStorage from 'shared/helpers/localStorage/setLocalStorage';
import { APASH_PRODUCT_EXTERNAL_ID } from 'shared/constants/localstorage';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  ...content,
  broadcastingWay: content.broadcastingWay,
  icon: {
    link: content.icon.link
      ? getLinkHrefWithAuthentication(content.icon.link)
      : undefined,
    description: content.icon.description ?? undefined,
    filename: content.icon.filename
  },
  beforeEndContent: {
    message: content.before_end.message,
    label: content.before_end.label,
    link: getLinkHrefWithAuthentication(content.before_end.link ?? ''),
    handleClick: () => undefined
  },
  afterEndContent: {
    message: content.after_end.message,
    label: content.after_end.label,
    link: getLinkHrefWithAuthentication(content.after_end.link ?? ''),
    handleClick: content.after_end.productExternalId
      ? () => {
          setLocalStorage(
            APASH_PRODUCT_EXTERNAL_ID,
            content.after_end.productExternalId
          );
        }
      : undefined
  },
  contextCriteria: content.frontDisplayCriteria ?? null
});
