import React from 'react';

import { ButtonCTA, Label, Wrapper } from './ButtonStyled';
import { FormatedMenu } from 'shared/blocks/menu/types';

type Props = {
  items: FormatedMenu;
  href: string;
};

export const Button = ({ items, href }: Props) => {
  const { label, buttonTheme } = items.CTA;
  return (
    <Wrapper>
      <ButtonCTA to={href} theme={buttonTheme}>
        <Label>{label}</Label>
      </ButtonCTA>
    </Wrapper>
  );
};
