import React from 'react';

export default ({ className = '', width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#EC3654" />
    <path
      d="M12.4353 9.5625C11.5607 9.5625 10.8393 8.828 10.8393 7.94725C10.8393 7.047 11.5383 6.3125 12.4353 6.3125C13.3131 6.3125 14.0312 7.047 14.0312 7.94725C14.0312 8.828 13.3099 9.5625 12.4353 9.5625ZM11.1585 17.6875V10.5375H13.7121V17.6875H11.1585ZM9.5625 10.5375H13.7121V13.1375H9.5625V10.5375Z"
      fill="white"
    />
  </svg>
);
