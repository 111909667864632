import { Media } from 'shared/types';
import { SelfcareIcon } from '../types';

export const getSelfcareIcon = (
  prospectSelfcareIcon: Media | null,
  authenticatedSelfcareIcon: Media | null
): SelfcareIcon | null => {
  if (!prospectSelfcareIcon && !authenticatedSelfcareIcon) {
    return null;
  }

  return {
    prospect: (prospectSelfcareIcon || authenticatedSelfcareIcon) as Media,
    authenticated: (authenticatedSelfcareIcon || prospectSelfcareIcon) as Media
  };
};
