import dayjs from 'dayjs';
import range from 'lodash/range';
import upperFirst from 'lodash/upperFirst';

const getLabel = (dayIndex, format = 'dddd D', t) => {
  if (dayIndex === 0) return t('blocks.tvGuide.today');
  if (dayIndex === 1) return t('blocks.tvGuide.tomorrow');

  const day = dayjs().add(dayIndex, 'day');
  return upperFirst(day.format(format));
};

/* eslint-disable */
const getSlug = text =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
/* eslint-enable */

export default (selectedDay, t) =>
  range(8).map(dayIndex => ({
    label: getLabel(dayIndex, 'dddd D', t),
    slug: getSlug(getLabel(dayIndex, 'dddd', t)),
    active: selectedDay === dayIndex
  }));
