import Cookies from 'universal-cookie';

import hasCanalPlusUrl from 'shared/blocks/authentication/helpers/hasCanalPlusUrl';
import { Link } from 'shared/types';
import isConnected from '../auth/isConnected';
import getLinkHref from './getLinkHref';
import isBigramInUrl from './isBigramInUrl';
import addBigramToUrl from './addBigramToUrl';

export default (
  cookies: Cookies | undefined,
  loginUrlFactory: (referer: string) => string
) =>
  function getLinkHrefWithAuthenticationFactory(link: Link | '' | null) {
    const href = getLinkHref(link);

    if (!link || link.rel !== 'external') {
      return href;
    }

    if (!hasCanalPlusUrl(href)) {
      return href;
    }

    const allCookies = cookies ? cookies.getAll() : {};

    let hrefWithBigram = href;
    if (!isBigramInUrl(href) && allCookies.isoCountryCode) {
      hrefWithBigram = addBigramToUrl(
        allCookies.isoCountryCode.toLowerCase(),
        href
      );
    }

    if (isConnected(allCookies)) {
      return hrefWithBigram;
    }

    return loginUrlFactory(hrefWithBigram);
  };
