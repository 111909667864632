import { createTemplate } from 'shared/helpers/template';

export default (programUrlPattern, program) => {
  if (program?.onClick) {
    const ismoUrl = program.onClick.URLPage;
    const ismoUrlChunks = ismoUrl.split('/').reverse();

    const [tvscheduleId, type, channelId] = ismoUrlChunks;
    return `${createTemplate(programUrlPattern)({
      type,
      id: channelId
    })}?${type}=${tvscheduleId}`;
  }
};
