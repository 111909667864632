import React, { PureComponent } from 'react';
import styled from 'styled-components';

const BaseWrapper = styled.div`
  margin: 60px 0 30px;
`;

class TabList extends PureComponent {
  static displayName = 'TabList';

  render() {
    const { activeIndex, onActivate = () => {}, customWrapper } = this.props;
    const children = React.Children.map(this.props.children, (child, index) =>
      React.cloneElement(child, {
        isActive: index === activeIndex,
        onClick: () => onActivate(index)
      })
    );

    const Wrapper = customWrapper ? customWrapper : BaseWrapper;

    return <Wrapper>{children}</Wrapper>;
  }
}

export default TabList;
