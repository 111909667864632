import React, { useCallback } from 'react';

import { TabContent } from 'shared/blocks/one-shop/offers-cards/components/TabContent/TabContent';
import { useTabs } from '../../shared/components/Tabs/component/Tabs/providers/TabsProvider';
import { FormattedTab } from '../../shared/components/Tabs/component/Tabs/types';
import { FormattedProduct } from '../types';
import { Tabs } from './TabWrapper/styles/components';

export const TabsWrapper = ({
  tabs
}: {
  tabs: FormattedTab<FormattedProduct>[];
}) => {
  const { currentTab } = useTabs();

  const renderOffersCard = useCallback(
    function renderOffersCard(tab, index) {
      return (
        <TabContent
          key={`${index}-${tab.title}`}
          tab={tab}
          isActive={currentTab === index}
          tabIndex={index}
        />
      );
    },
    [currentTab]
  );

  const tabsTitle = tabs.map(tab => tab.tabTitle);

  return <Tabs tabsTitle={tabsTitle}>{tabs.map(renderOffersCard)}</Tabs>;
};
