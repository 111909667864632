/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Content, FormattedProps } from './types';

export default (content: Content): FormattedProps => ({
  title: content.title.label,
  titleHtmlTag: content.title.htmlTag,
  titleColor: content.title.color,
  columns: content.columns
});
