/* eslint-disable react/prefer-stateless-function */
/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import BaseLoader from 'shared/components/presentational/Loader/Loader';
import { COLORS } from 'shared/constants/theme';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`;

export default class Loader extends PureComponent {
  render() {
    return (
      <Wrapper>
        <BaseLoader color={COLORS.black} />
      </Wrapper>
    );
  }
}
