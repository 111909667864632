import { Method } from 'axios';

import {
  PostOfferParams,
  FormattedMinorOffer,
  SubmittedAllStepsFormValues
} from '../../shared/types';
import { StateOffer } from '../types';

export default function getPostOffersParams(
  subscriptionAPI: { url: string; method: Method },
  selectedOfferAndOption: StateOffer,
  selectedMinorOffers: Pick<FormattedMinorOffer, 'code'>[],
  values: SubmittedAllStepsFormValues
): PostOfferParams {
  let products = undefined;

  if (selectedOfferAndOption.option) {
    products = selectedOfferAndOption.option.secondaryCodes;
  }

  if (selectedMinorOffers.length) {
    const codes = selectedMinorOffers.map(offer => offer.code);

    products =
      typeof products === 'undefined' ? codes : [...products, ...codes];
  }

  return {
    url: subscriptionAPI.url,
    method: subscriptionAPI.method,
    contractInfo: {
      duration:
        selectedOfferAndOption &&
        selectedOfferAndOption.option &&
        selectedOfferAndOption.option.duration
          ? selectedOfferAndOption.option.duration
          : undefined,
      products,
      refuseNewsletter: values.refuseNewsletter
    },
    personInfo: {
      title: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.mobilePhoneNumber,
      address: {
        city: values.city,
        postalCode: values.postalCode,
        address: values.address,
        additionalAddress: values.additionalAddress
          ? values.additionalAddress
          : '',
        countryId: values.countryCode
      }
    },
    bankInfo: {
      ibanInfo: {
        ibanCode: values.ibanCode
      }
    }
  };
}
