import appendParamsToUrl from './appendParamsToUrl';

export default function appendPassIdToCanalPlusUrl(
  href: string,
  cookies: { [key: string]: string }
) {
  let passId;

  try {
    passId = cookies.AuthIdToken;
  } catch (e) {
    return href;
  }

  if (passId) {
    return appendParamsToUrl(href, { token: passId });
  }
  return href;
}
