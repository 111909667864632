import React, { PureComponent, ReactNode } from 'react';
import upperFirst from 'lodash/upperFirst';
import styled from 'styled-components';

import Icons from '../../Icons';

const Wrapper = styled.span<Partial<Props>>`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  line-height: 100%;
  margin-left: 8px;
  :first-child {
    margin-left: 0;
  }
`;

interface Props {
  xlink: string;
  width?: string;
  height?: string;
  className?: string;
  visible?: boolean;
}

export default class SvgIcon extends PureComponent<Props> {
  public static readonly displayName = 'Button.SvgIcon';

  public render(): ReactNode {
    const { className, width, height, xlink, visible } = this.props;
    return (
      <Wrapper className={className} visible={visible}>
        <Icons
          name={upperFirst(xlink.split('-').reverse()[0])}
          width={width}
          height={height}
        />
      </Wrapper>
    );
  }
}
