/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import RenderMoreButtonContainer from 'shared/blocks/vod/hodor/containers/RenderMoreButtonContainer';
import Link from 'shared/components/presentational/CustomLink/CustomLink';
import BaseMosaic from 'shared/components/presentational/Mosaic/Mosaic';
import Picture from 'shared/components/presentational/Picture';

const Wrapper = styled.section`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0.5rem;
`;

const Image = styled(Picture)`
  max-height: 100px;
`;

class Mosaic extends PureComponent {
  render() {
    const { data, path } = this.props;
    return (
      <Wrapper>
        <BaseMosaic>{data.contents.map(this.renderItem)}</BaseMosaic>
        <RenderMoreButtonContainer paging={data.paging} path={path} />
      </Wrapper>
    );
  }

  renderItem = ({ contentID, onClick, URLImage }) => (
    <Link key={contentID} to={`${this.props.baseLink}${onClick.path}`}>
      <Image alt={onClick.displayName} url={URLImage} height={100} />
    </Link>
  );
}

export default Mosaic;
