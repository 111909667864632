/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*       */
import { createTemplate } from 'shared/helpers/template';

export default (programUrlPattern, program) => {
  if (program?.onClick) {
    const ismoUrl = program.onClick.URLPage;
    const ismoUrlChunks = ismoUrl.split('/').reverse();

    const [id, type] = ismoUrlChunks;
    return createTemplate(programUrlPattern)({ id, type });
  }
};
