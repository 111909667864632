import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

const TAB_MIN_WIDTH_PHONE = 120;
const TAB_MIN_WIDTH_DESKTOP = 160;

export const Wrapper = styled.button<{ isCurrentTab: boolean }>`
  font-size: var(--text-size-h3);
  line-height: var(--line-height-l);
  border: ${({ isCurrentTab }) =>
    `3px solid ${
      isCurrentTab ? 'var(--primary-default)' : 'var(--secondary-dark-default)'
    }`};
  color: ${({ isCurrentTab }) =>
    isCurrentTab ? 'var(--text-default-main)' : 'var(--text-default-light)'};
  background-color: ${({ isCurrentTab }) =>
    isCurrentTab ? 'var(--surface-default)' : 'var(--surface-background)'};
  border-radius: 3px;
  padding: var(--spacing-s) var(--spacing-m);
  text-align: center;
  width: fit-content;
  min-width: ${TAB_MIN_WIDTH_PHONE}px;
  cursor: pointer;
  white-space: nowrap;

  &:focus-visible {
    outline-style: auto;
  }

  ${mediaQueries.fromTablet`
    min-width: ${TAB_MIN_WIDTH_DESKTOP}px;
 `}
`;
