import React from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeFactory } from 'shared/types';
import {
  TunnelCoreWrapper,
  ChoiceWrapper,
  Choice,
  ChoiceLabelValueWrapper,
  ChoiceLabel,
  ChoiceValue,
  ButtonsWrapper,
  RedButton,
  BlackButton,
  UnstyledButton,
  ChoicesSummaryWrapper,
  OtherContractsWrapper,
  HaveOtherContracts,
  ChooseAnotherContract,
  RecapExplanationWrapper,
  RecapExplanation
} from '../styles/components';
import formatAmount from 'shared/blocks/selfcare/subscription-tunnel/funnel/helpers/formatAmount';
import { Props } from '../../funnel/types';
import Spacer from 'shared/components/presentational/Spacer';

interface TunnelSummaryProps {
  summaryContext: 'tunnel-home' | 'payment-recap';
  paymentRecapSuccess?: boolean;
  hideSubscriptionInfos?: boolean;
  recapButtons?: {
    successRedirectButton: {
      label: string;
      theme: ThemeFactory;
      link: string;
    };
    errorRedirectButton: {
      label: string;
      theme: ThemeFactory;
      link: string;
    };
  };
  onEditOfferClick?: () => void;
  onResubscribeClick?: () => void;
  onChooseAnotherContractClick?: () => void;
  subscriberData?: Props['subscriberData']['data'];
  resubscriptionNumber?: string;
  buttonsDisabled?: boolean;
  actualOffer?: {
    code: string;
    label: string;
  };
  actualOptions?: {
    code: string;
    label: string;
  }[]; // TODO: Validate options typing
  actualDuration?: {
    code: string;
    label: string;
  };
  actualAmount?: string;
  currency?: string;
}

export default function TunnelSummary({
  summaryContext,
  paymentRecapSuccess,
  recapButtons,
  hideSubscriptionInfos = false,
  onEditOfferClick,
  onResubscribeClick,
  onChooseAnotherContractClick,
  subscriberData,
  resubscriptionNumber,
  buttonsDisabled = false,
  actualOffer,
  actualOptions,
  actualDuration,
  actualAmount,
  currency
}: TunnelSummaryProps) {
  const { t } = useTranslation();
  //AWA-2522
  //AWA-2522
  const formattedResubscriptionNumber =
    resubscriptionNumber &&
    parseInt(resubscriptionNumber).toLocaleString('fr-FR');
  const isTunnelHomeContext = summaryContext === 'tunnel-home';
  const isPaymentRecapContext = summaryContext === 'payment-recap';
  const formattedAmount = actualAmount && formatAmount(actualAmount);
  const formattedActualDuration =
    actualDuration && actualDuration.label.toUpperCase();

  return (
    <>
      <TunnelCoreWrapper>
        <ChoiceWrapper
          withHPadding
          withBorderB={
            isTunnelHomeContext ||
            (hideSubscriptionInfos && paymentRecapSuccess)
          }
          withRadiusT
        >
          <Choice
            justifyContent={isPaymentRecapContext ? 'center' : 'flex-end'}
          >
            <ChoiceLabelValueWrapper>
              <ChoiceLabel
                fontSize={isPaymentRecapContext ? 'large' : 'small'}
                bold={isPaymentRecapContext}
                textAlign="center"
              >
                {isTunnelHomeContext &&
                  t(
                    'blocks.selfcare.subscriptionTunnel.summary.resubscriptionNumber'
                  )}
                {isPaymentRecapContext &&
                  (paymentRecapSuccess
                    ? t('blocks.selfcare.subscriptionTunnel.recap.congrats')
                    : t(
                        'blocks.selfcare.subscriptionTunnel.recap.errorHappened'
                      ))}
              </ChoiceLabel>
              <ChoiceValue
                data-testid="subscription-summary-resubscription-number"
                fontSize={isPaymentRecapContext ? 'large' : 'medium'}
              >
                {(isTunnelHomeContext && formattedResubscriptionNumber) ?? '—'}
              </ChoiceValue>
            </ChoiceLabelValueWrapper>
          </Choice>
        </ChoiceWrapper>

        <ButtonsWrapper order={isTunnelHomeContext ? 2 : 3}>
          {isTunnelHomeContext && onResubscribeClick && (
            <RedButton disabled={buttonsDisabled} onClick={onResubscribeClick}>
              <RedButton.children.Text>
                {t('blocks.selfcare.subscriptionTunnel.summary.resubscribe')}
              </RedButton.children.Text>
            </RedButton>
          )}

          {isTunnelHomeContext && onEditOfferClick && (
            <BlackButton disabled={buttonsDisabled} onClick={onEditOfferClick}>
              <BlackButton.children.Text>
                {t('blocks.selfcare.subscriptionTunnel.summary.editOffer')}
              </BlackButton.children.Text>
            </BlackButton>
          )}

          {isPaymentRecapContext && recapButtons && (
            <UnstyledButton
              disabled={buttonsDisabled}
              theme={
                paymentRecapSuccess
                  ? recapButtons.successRedirectButton.theme
                  : recapButtons.errorRedirectButton.theme
              }
              to={
                paymentRecapSuccess
                  ? recapButtons.successRedirectButton.link
                  : recapButtons.errorRedirectButton.link
              }
            >
              <UnstyledButton.children.Text>
                {paymentRecapSuccess
                  ? recapButtons.successRedirectButton.label
                  : recapButtons.errorRedirectButton.label}
              </UnstyledButton.children.Text>
            </UnstyledButton>
          )}
        </ButtonsWrapper>

        {isPaymentRecapContext && !paymentRecapSuccess && (
          <RecapExplanationWrapper>
            <RecapExplanation>
              {t('blocks.selfcare.subscriptionTunnel.recap.paymentUnfinished')}
            </RecapExplanation>
          </RecapExplanationWrapper>
        )}

        {!hideSubscriptionInfos && (
          <ChoicesSummaryWrapper
            withBorderB={isPaymentRecapContext}
            order={isTunnelHomeContext ? 3 : 2}
          >
            <Choice>
              <ChoiceLabelValueWrapper>
                <ChoiceLabel>
                  {isTunnelHomeContext
                    ? t(
                        'blocks.selfcare.subscriptionTunnel.summary.actualOffer'
                      )
                    : t('blocks.selfcare.subscriptionTunnel.recap.offerLabel')}
                </ChoiceLabel>
                <ChoiceValue fontSize="large">
                  {actualOffer?.label ?? '—'}
                </ChoiceValue>
              </ChoiceLabelValueWrapper>
            </Choice>

            <Choice>
              <ChoiceLabelValueWrapper>
                <ChoiceLabel>
                  {isTunnelHomeContext
                    ? t(
                        'blocks.selfcare.subscriptionTunnel.summary.actualOptions'
                      )
                    : t(
                        'blocks.selfcare.subscriptionTunnel.recap.optionsLabel'
                      )}
                </ChoiceLabel>
                <ChoiceValue>
                  {actualOptions?.map(o => o.label).join(', ') || '—'}
                </ChoiceValue>
              </ChoiceLabelValueWrapper>
            </Choice>

            <Choice>
              <ChoiceLabelValueWrapper>
                <ChoiceLabel>
                  {isTunnelHomeContext
                    ? t(
                        'blocks.selfcare.subscriptionTunnel.summary.actualDuration'
                      )
                    : t(
                        'blocks.selfcare.subscriptionTunnel.recap.durationLabel'
                      )}
                </ChoiceLabel>
                <ChoiceValue>{formattedActualDuration ?? '—'}</ChoiceValue>
              </ChoiceLabelValueWrapper>
            </Choice>

            <Choice>
              <ChoiceLabelValueWrapper>
                <ChoiceLabel>
                  {isTunnelHomeContext
                    ? t(
                        'blocks.selfcare.subscriptionTunnel.summary.actualAmount'
                      )
                    : t('blocks.selfcare.subscriptionTunnel.recap.amountLabel')}
                </ChoiceLabel>
                <ChoiceValue>
                  {}
                  {actualAmount ? formattedAmount : '—'}
                  {currency && <small>{currency}/MOIS</small>}
                </ChoiceValue>
              </ChoiceLabelValueWrapper>
            </Choice>
          </ChoicesSummaryWrapper>
        )}
      </TunnelCoreWrapper>
      {isPaymentRecapContext ? <Spacer height={10} /> : null}

      {subscriberData?.listOfContracts?.length && onChooseAnotherContractClick && (
        <OtherContractsWrapper>
          <HaveOtherContracts>
            {t('blocks.selfcare.subscriptionTunnel.summary.haveOtherContracts')}
          </HaveOtherContracts>

          <ChooseAnotherContract onClick={onChooseAnotherContractClick}>
            <ChooseAnotherContract.children.Text>
              {t(
                'blocks.selfcare.subscriptionTunnel.summary.chooseAnotherContract'
              )}
            </ChooseAnotherContract.children.Text>
          </ChooseAnotherContract>
        </OtherContractsWrapper>
      )}
    </>
  );
}
