import React, { PureComponent } from 'react';
import styled from 'styled-components';

import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';
import { COLORS } from 'shared/constants/theme';

const ButtonLink = styled.button<Props>`
  color: ${COLORS.cerulean};
  cursor: pointer;
  text-decoration: underline;
  font-size: inherit;
  font-family: inherit;
`;

const StyledCustomLink = styled(CustomLink)<Props>`
  color: ${COLORS.cerulean};
  text-decoration: underline;
`;

interface Props {
  to?: string;
  href?: string;
  className?: string;
  target?: string;
  children?: any;
  onClick?: () => void;
}

class Link extends PureComponent<Props> {
  public render(): React.ReactNode {
    const { to, ...props } = this.props;

    return to ? (
      <StyledCustomLink to={to} {...props} />
    ) : (
      <ButtonLink {...props} />
    );
  }
}
export default Link;
