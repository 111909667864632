import querystring from 'query-string';
/**
 * Return the path to request on the Structure API for the given client uri
 *
 * @param {string} uri Client page URI (ex: site.canal.fr/articles/my-article
 * @param {object} parameters Parameters to be added as query string
 *
 * @returns {string} Path to call on the Structure API
 */
export default (uri: string, parameters?: any): string => {
  const arr = uri.split('/');
  arr.shift();

  return querystring.stringifyUrl({
    url: `/${arr.join('/')}`,
    query: parameters
  });
};
