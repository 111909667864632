import { getDomainURL } from 'shared/modules/hostname/selectors/getDomainURL';

export default function getOnlyDomain(state: any) {
  const domainURL = getDomainURL(state);
  if (domainURL) {
    const hostname = new URL(domainURL).hostname;
    const domainParts = hostname.split('.').slice(-2);
    return domainParts.join('.');
  }
  return '';
}
