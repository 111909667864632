import React, { PureComponent } from 'react';

class TabPanels extends PureComponent {
  static displayName = 'TabPanels';

  render() {
    const { children, activeIndex } = this.props;
    return <div>{children[activeIndex]}</div>;
  }
}

export default TabPanels;
