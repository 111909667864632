import { Content, FormattedProps } from './types';
const FALLBACK_REDIRECTION_URL = '/moncompte-tv';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: content.title.label ?? '',
  titleColor: content.title.color ?? '',
  titleHtmlTag: content.title.htmlTag,
  redirectLink: content.redirectLink
    ? getLinkHrefWithAuthentication(content.redirectLink)
    : FALLBACK_REDIRECTION_URL,
  apis: {
    getUnpaidInfos: {
      url: content.apis?.['selfcare.accouting.get_unpaid_info']?.url ?? '',
      method:
        content.apis?.['selfcare.accouting.get_unpaid_info']?.method || 'GET'
    },
    registerPrepaymentSystempay: {
      url:
        content.apis?.['selfcare.accouting.register_prepayment_systempay']
          ?.url ?? '',
      method:
        content.apis?.['selfcare.accouting.register_prepayment_systempay']
          ?.method || 'PUT'
    },
    getRenewalOffer: {
      url: content.apis?.['selfcare.accounting.get_renewal_offer']?.url ?? '',
      method:
        content.apis?.['selfcare.accounting.get_renewal_offer']?.method || 'GET'
    },
    getSubscriberData: {
      url: content.apis?.getSubscriberData?.url ?? '',
      method: content.apis?.getSubscriberData?.method || 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    }
  }
});
