import { createSelector } from 'reselect';

import { PER_PAGE } from '../constants';
import { getFilteredShops } from '../../shared/selectors';

const byId = id => item => item.id === id;

export default shopId =>
  createSelector([getFilteredShops], shops => {
    if (!shopId) return null;
    const index = shops.findIndex(byId(shopId));
    return Math.max(Math.ceil((index + 1) / PER_PAGE), 1);
  });
