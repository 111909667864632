import get from 'lodash/get';
import queryString from 'query-string';

import { getLinkHref } from 'shared/helpers/uri';
import { createTemplate } from 'shared/helpers/template';
import isLoginLinkExternal from './isLoginLinkExternal';
import getLogoutUrl from 'shared/modules/page/helpers/getLogoutUrl';
import isAuthenticatedPage from 'shared/modules/page/helpers/isAuthenticatedPage';

export default (
  domainURL: string,
  pagePath: string,
  pageData: object,
  offerZone?: string
): string => {
  const logoutUrl = getLogoutUrl(pageData);
  const isExternalLogin = isLoginLinkExternal(pageData);

  // If the page is not an external login, we don't redirect to the external logout
  if (!isExternalLogin) {
    return logoutUrl;
  }

  const redirectPage =
    isAuthenticatedPage(pageData) || !pagePath ? '/' : pagePath;

  const externalLogoutLink = getLinkHref(
    get(pageData, 'authentication.externalLogoutLink')
  );

  const targetUrl = queryString.stringifyUrl({
    url: `${domainURL}${logoutUrl}`,
    query: {
      redirect_uri: `${redirectPage}`
    }
  });

  const logoutLinkParams: Record<string, string> = { targetUrl };

  if (offerZone) {
    logoutLinkParams['offerZone'] = offerZone;
  }

  const formattedLogoutLink = createTemplate(externalLogoutLink)(
    logoutLinkParams
  );

  return formattedLogoutLink;
};
