import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

import Picture from 'shared/components/presentational/Picture';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import BaseRaw from 'shared/components/presentational/Raw/Raw';
import BaseButton from 'shared/components/presentational/Button';
import BaseLink from 'shared/components/presentational/CustomLink/CustomLink';
import { FONT_FAMILIES, COLORS, FONT_WEIGHTS } from 'shared/constants/theme';
import { CONTENT_PADDING } from './constants';

export const Title = styled.h3`
  text-align: center;
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${pxInRem(20)};
  line-height: ${pxInRem(24)};
  margin: 0;
  color: ${props => props.color || COLORS.black};
`;

export const Subtitle = styled(BaseRaw)`
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  font-size: ${pxInRem(10)};
  line-height: ${pxInRem(18)};
`;

export const SubtitleWrapper = styled.div<{ height: number }>`
  position: relative;
  width: 100%;
  ${props =>
    props.height > 0
      ? css`
          height: ${props.height}px;
        `
      : css`
          ${Subtitle} {
            position: relative;
          }
        `}
  margin-bottom: ${pxInRem(0)};
`;

export const ChannelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: ${pxInRem(20)} 0 ${pxInRem(16)};
`;

export const Channel = styled.div`
  width: 33.33%;
  padding: 15px 5px 0;
  & > picture {
    display: flex;
    justify-content: center;
  }
`;

export const ChannelImage = styled(Picture)`
  height: 47px;
  object-fit: contain;
`;

export const DecoderWrapper = styled.div`
  flex: 1;
  margin: 0;
  text-align: right;
`;

export const Decoder = styled(Picture)`
  height: 50px;
`;

export const ToggleWrapper = styled.div`
  margin: 26px auto;
  width: 100%;
  max-width: 200px;
  min-height: 26px;
`;

export const Button = styled(BaseButton)`
  width: 100%;
`;

export const Option = styled.p`
  display: flex;
  justify-content: space-between;
  font-size: ${pxInRem(14)};
`;

export const OptionWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin: 0 -${CONTENT_PADDING}px -25px;
  padding: 0 ${CONTENT_PADDING}px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const Description = styled.div`
  position: absolute;
  top: 0;
  will-change: opacity, transform;
  width: 100%;
`;

export const AnimatedDescription = animated(Description);

export const DescriptionWrapper = styled.div<{ height: number }>`
  position: relative;
  flex: 1;
  padding: 10px;
  ${props =>
    props.height > 0 &&
    css`
      height: ${props.height}px;
    `}
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  font-size: ${pxInRem(9)};
  line-height: ${pxInRem(12)};
  p {
    margin: 10px 0;
  }
`;

export const PricesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin: ${pxInRem(10)} 0 ${pxInRem(16)};
  & > * {
    margin: 0 6px;
  }
`;

export const DescriptionContent = BaseRaw;

export const Text = styled(BaseRaw)`
  margin: ${pxInRem(16)} 0;
  padding-left: 15px;
  padding-right: 15px;
  font-size: ${pxInRem(12)};
  line-height: ${pxInRem(18)};
`;

export const Link = styled(BaseLink)`
  display: block;
  text-align: center;
  margin: ${pxInRem(16)} auto;
  font-size: ${pxInRem(12)};
  line-height: ${pxInRem(18)};
  text-decoration: underline;
`;
