import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import clientCookies from 'client/cookies';
import isExternalURI from 'shared/helpers/uri/isExternalURI';
import hasCanalPlusUrl from 'shared/blocks/authentication/helpers/hasCanalPlusUrl';
import appendPassIdToCanalPlusUrl from 'shared/helpers/uri/appendPassIdToCanalPlusUrl';

export interface Props {
  to?: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => any;
  target?: string;
  className?: string;
  search?: string;
}

export default class CustomLink extends PureComponent<Props> {
  public static readonly defaultProps = {
    target: '_self'
  };

  public render() {
    const {
      to,
      children,
      className,
      target,
      onClick: handleClick,
      search,
      ...props
    } = this.props;

    if (to && !isExternalURI(to)) {
      return (
        <Link
          className={className}
          to={search ? { pathname: to, search } : to}
          target={target}
          onClick={handleClick}
          {...props}
        >
          {children}
        </Link>
      );
    }

    if (to && hasCanalPlusUrl(to)) {
      const handleRedirect = (e?: React.MouseEvent) => {
        const targetUrlWithToken = appendPassIdToCanalPlusUrl(
          to,
          clientCookies.getAll()
        );
        location.assign(targetUrlWithToken);

        // click handler passthrough
        if (e && handleClick) {
          handleClick(e);
        }
      };

      const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
          handleRedirect();
        }
      };

      // render a link without href to prevent "open in new tab" without the token
      return (
        <a
          role="button"
          tabIndex={0}
          className={className}
          onClick={handleRedirect}
          onKeyDown={handleKeyDown}
          {...props}
        >
          {children}
        </a>
      );
    }

    return (
      <a
        className={className}
        href={to}
        target={target}
        onClick={handleClick}
        {...props}
      >
        {children}
      </a>
    );
  }
}
