/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

export default content => ({
  apiUrl: get(content, 'api.url', ''),
  apiMethod: get(content, 'api.method', 'POST'),
  title: get(content, 'title', ''),
  labelButton: get(content, 'labelButton', ''),
  text: get(content, 'text', ''),
  confirmationText: get(content, 'confirmationText', '')
});
