import { Content, FormattedProps } from './types';
import { EngagementUnit } from '../shared/types';
import { getLinkHref } from 'shared/helpers/uri';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  productId: content.productId,
  title: content.title,
  description: content.description,
  users: {
    label: content.users.label,
    icon: getLinkHref(content.users?.icon?.link)
  },
  button: {
    label: content.primaryButton?.label,
    link: getLinkHrefWithAuthentication(content.primaryButton?.link)
  },
  promotionalSticker: content.promotionalSticker,
  offerDetails: {
    availableCommitmentDurations: content.availableCommitmentDurations,
    priceInformation: content.priceInformation,
    netPrice: content.netPrice,
    price: content.price,
    promoDuration: content.promoDuration,
    engagementDuration: content.engagementDuration,
    engagementUnit: EngagementUnit[content.engagementUnit],
    commitmentConditions: content.commitmentConditions
  },
  minors: content.minors
});
