import styled from 'styled-components';

import { mediaQueries } from 'shared/helpers/styled-components/mediaQueries';
import Picture from 'shared/components/presentational/Picture';
import {
  HEADER_UNIFIED_DESKTOP_HEIGHT,
  HEADER_UNIFIED_MOBILE_HEIGHT
} from 'shared/constants/heights';
import pxToRem from 'shared/helpers/styled-components/remHelper';

const CustomPictureWrapper = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
`;

export const PictureStyled = styled(Picture).attrs({
  customWrapper: CustomPictureWrapper
})`
  margin-right: 0.6rem;
  width: 100%;
  heigth: 100%;
`;

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding: 0 16px 16px;
  border-bottom: 1px solid #2d3034;
`;

export const TitleStyled = styled.div`
  font-weight: 600;
  font-size: 22px;
`;

export const DescriptionStyled = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 18px;
`;

export const ButtonStyled = styled.button`
  width: fit-content;
  align-self: flex-end;
`;

export const CloseIconStyled = styled.div<{ colorClosingModals: string }>`
  display: flex;
  width: 40px;
  height: 40px;
  radius: 50%;
  align-items: center;
  justify-content: center;
  left: 315px;
  top: 126px;
  flex-start: end;
  border-radius: 50%;
  padding: 15px;
  align-self: end;
  background: #3a3a3a;
  background: ${({ colorClosingModals }) => colorClosingModals};
  color: white;
  margin-right: 4px;
  margin-bottom: 8px;

  ${mediaQueries.fromTablet`
  display: none;
  `};
`;

export const DropdownMenuStyled = styled.div<{
  backgroundColorModals: string;
  isConnected: boolean;
}>`
  background: ${({ backgroundColorModals }) => backgroundColorModals};
  width: 100%
  
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: ${pxToRem(HEADER_UNIFIED_MOBILE_HEIGHT)};
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0;
  padding-bottom: ${({ isConnected }) => (isConnected ? '16px' : '19px')};
  color: white;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  max-height: calc(100vh-${pxToRem(HEADER_UNIFIED_MOBILE_HEIGHT)});
  overflow: auto;
  z-index: 7;

  ${mediaQueries.fromTablet<{
    backgroundColorModals: string;
    isConnected: boolean;
  }>`
  position: absolute;
  width: 320px;
  height: fit-content;
  top: ${pxToRem(HEADER_UNIFIED_DESKTOP_HEIGHT)};
  right: ${pxToRem(32)};
  bottom: unset;
  left: unset;
  max-height: calc(100vh - ${pxToRem(HEADER_UNIFIED_DESKTOP_HEIGHT)});
  `}
`;

export const OpacityStyled = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 6;
  ${mediaQueries.fromDesktop`
  display: none;
  `}
`;

export const LinkStyled = styled.a`
  display: flex;
  gap: 1em;
  align-items: center;
`;

export const LabelStyled = styled.span<{ color: string }>`
  flex-grow: 1;
  white-space: nowrap;
  align-items: center;
  display: flex;
  color: ${({ color }) => color};
`;

export const LinkWrapperStyled = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 16px 0 0;
  overflow: auto;

  ${mediaQueries.toPhone`
  padding: 0 20px;
`};
`;

export const ListItemStyled = styled.li`
  display: list-item;
`;

export const ButtonConnected = styled.div`
  margin-top: 11px;
  padding: 0 16px;
  width: 100%;
`;
