import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NavigationMenuLink } from '../types';
import {
  PlusDropDownSubMenu,
  PlusListItem,
  ShowMore,
  StyledArrow
} from '../styles/components';
import ResponsiveHoc, {
  ResponsiveHocProps
} from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';

export type ExpandableMenuDropdownProps = {
  children?: React.ReactNode;
  isActive?: boolean;
  isMobile?: boolean;
  link: NavigationMenuLink;
  backgroundColor: string;
};

const ExpandableMenuDropdown = ({
  children,
  link,
  backgroundColor,
  mediaQueries
}: ExpandableMenuDropdownProps & ResponsiveHocProps): JSX.Element => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  const [isTriggerHovered, setIsTriggerHovered] = useState<boolean>(false);
  const [isDropdownHovered, setIsDropdownHovered] = useState<boolean>(false);
  const isDropdownDisplayed = useMemo(
    () => isTriggerHovered || isDropdownHovered,
    [isDropdownHovered, isTriggerHovered]
  );

  // Close dropdown after any click
  useEffect(() => {
    if (isDropdownDisplayed) {
      const onDocumentClick = () => {
        setIsTriggerHovered(false);
        setIsDropdownHovered(false);
      };
      document.addEventListener('click', onDocumentClick);
      return () => document.removeEventListener('click', onDocumentClick);
    }
    return;
  }, [isDropdownDisplayed]);

  return (
    <div ref={ref}>
      <PlusListItem
        link={link}
        onMouseEnter={() => {
          mediaQueries.fromDesktop && setIsTriggerHovered(true);
        }}
        onMouseLeave={() => {
          mediaQueries.fromDesktop &&
            setTimeout(function hover() {
              setIsTriggerHovered(false);
            }, 200);
        }}
      >
        {/* Dropdown trigger */}
        <ShowMore
          type="button"
          aria-expanded={isDropdownDisplayed}
          link={link}
          onClick={() => setIsTriggerHovered(!isTriggerHovered)}
        >
          {t('blocks.menu.showMore')}
          <StyledArrow
            width="12"
            height="7"
            name={isDropdownDisplayed ? 'ArrowTop' : 'ArrowBottom'}
          />
        </ShowMore>
        {/* Dropdown menu */}
        {isDropdownDisplayed && (
          <PlusDropDownSubMenu
            backgroundColor={backgroundColor}
            onMouseEnter={() => {
              mediaQueries.fromDesktop && setIsDropdownHovered(true);
            }}
            onMouseLeave={() => {
              mediaQueries.fromDesktop && setIsDropdownHovered(false);
            }}
          >
            {children}
          </PlusDropDownSubMenu>
        )}
      </PlusListItem>
    </div>
  );
};

ExpandableMenuDropdown.displayName = 'ExpandableMenuDropdown';

export default ResponsiveHoc(ExpandableMenuDropdown);
