import axios, { Method } from 'axios';
import queryString from 'querystring';

import clientCookies from 'client/cookies';
import {
  DEFAULT_URLS_PARAMS,
  DEFAULT_URLS_PARAMS_V2
} from 'shared/blocks/authentication/constants';
import { createTemplate } from 'shared/helpers/template';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import { LoginResponseV2 } from 'shared/blocks/authentication/types';

//0 : OK
//1 : Missing, invalid or expired P@SS identity
//2 : Missing or unknown portal Id
const ERROR_CODES = [-1, 1, 2, 7];

export const hasTokenCreationFailed = (data: {
  response: LoginResponseV2;
}): boolean => ERROR_CODES.includes(data.response.errorCode);

export default async (
  rawUrl: string,
  method: Method,
  authenticationIsExternal: boolean,
  passIdOrSessionTokenParam?: string,
  passIdType?: string
) => {
  const passId = passIdOrSessionTokenParam || clientCookies.get('AuthIdToken');

  if (!passId) {
    throw new Error('Neither AuthIdToken nor passIdParam is defined.');
  }

  let params: {
    portailId: string;
    media: string;
    vect: string;
    passId: string;
    passIdType?: string;
  } = {
    passId,
    ...(authenticationIsExternal ? DEFAULT_URLS_PARAMS_V2 : DEFAULT_URLS_PARAMS)
  };

  // We give a passIdType only for create token after register prospect
  if (passIdType) {
    params = { ...params, passIdType };
  }

  /**
   * Replace applocation placeholder for external authentication (createToken v2)
   */
  const url = createTemplate(rawUrl)({
    applocation: clientCookies.get(COOKIE_KEYS.ZONE)
  });

  const request = {
    url,
    method,
    data: queryString.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };

  const response = await axios.request<{ response: LoginResponseV2 }>(request);
  if (response && !hasTokenCreationFailed(response.data)) {
    return response;
  }

  throw new Error(response.data.response.errorMessage ?? 'response data error');
};
