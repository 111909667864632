import upperFirst from 'lodash/upperFirst';

import breakpoints from 'shared/constants/breakpoints';

// This responsive helper aims to ease the use of the ResponsiveHOC.
// It takes in parameter the device name and return a boolean according to the condition fulfilment

const hasPreviousBreakpoint = breakpoint => {
  const breakpointsArray = Object.keys(breakpoints).reverse();
  return breakpointsArray.indexOf(breakpoint) > 0;
};

const toBreakpoint = (breakpoint, device) => {
  const breakpointsArray = Object.keys(breakpoints);
  return (
    breakpointsArray.indexOf(breakpoint) <= breakpointsArray.indexOf(device)
  );
};

const fromBreakpoint = (breakpoint, device) => {
  const breakpointsArray = Object.keys(breakpoints);
  return (
    breakpointsArray.indexOf(breakpoint) >= breakpointsArray.indexOf(device)
  );
};

export default device =>
  Object.keys(breakpoints).reduce(
    (mediaQueries, breakpoint) =>
      Object.assign(
        mediaQueries,
        { [`to${upperFirst(breakpoint)}`]: toBreakpoint(breakpoint, device) },
        hasPreviousBreakpoint(breakpoint) && {
          [breakpoint]: breakpoint === device,
          [`from${upperFirst(breakpoint)}`]: fromBreakpoint(breakpoint, device)
        }
      ),
    {}
  );
