import get from 'lodash/get';

import { StateOffer } from '../types';
import { FormattedMinorOffer } from '../../shared/types';

export default function getSelectedMinorOffers(
  selectedOfferAndOption?: StateOffer | boolean,
  selectedMinorOffersCodes?: string[]
): FormattedMinorOffer[] {
  if (!selectedMinorOffersCodes || !selectedOfferAndOption) {
    return [];
  }

  const selectedOfferMinorOffers: FormattedMinorOffer[] = get(
    selectedOfferAndOption,
    'minorOffers',
    []
  );

  return selectedOfferMinorOffers.filter(offer =>
    selectedMinorOffersCodes.includes(offer.code)
  );
}
