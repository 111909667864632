import React from 'react';

import {
  ContentWrapper,
  ImageWrapper,
  Image,
  Wrapper
} from './styles/components';

export interface Props {
  imageUrl: string;
  imageAlt: string;
  borderHoverColor?: string;
  children: React.ReactNode;
}

const RoundedCard = (props: Props) => {
  const {
    imageUrl,
    imageAlt,
    borderHoverColor,
    children,
    ...restProps
  } = props;
  return (
    <Wrapper borderHoverColor={borderHoverColor} {...restProps}>
      <ImageWrapper>
        <Image url={imageUrl} alt={imageAlt} />
      </ImageWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};

export default RoundedCard;
