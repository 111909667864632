import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 22 24" width={width} height={height} className={className}>
    <path
      d="M11 12.5c3.3 0 6-2.7 6-6s-2.7-6-6-6-6 2.7-6 6 2.6 6 6 6zM0 23c0 .3.2.5.5.5h21c.3 0 .5-.2.5-.5v-1c0-4-2.7-7.5-8-7.5H8c-5.3 0-8 3.5-8 7.5v1z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
