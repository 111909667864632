/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import setLocalStorage from 'shared/helpers/localStorage/setLocalStorage';
import { APASH_PRODUCT_EXTERNAL_ID } from 'shared/constants/localstorage';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: {
    label: content.title.label,
    color: content.title.color,
    htmlTag: content.title.htmlTag
  },
  subtitle: content.subtitle,
  color: {
    default: content.color.default,
    secondary: content.color.secondary
  },
  description: content.description,
  button: {
    theme: themeFactory({
      backgroundColor: content.button.backgroundColor,
      borderColor: undefined,
      color: content.button.labelColor
    }),
    label: content.button.label,
    link: getLinkHrefWithAuthentication(content.button.link ?? '')
  },
  device: '',
  handleClick: content.productExternalId
    ? () =>
        setLocalStorage(APASH_PRODUCT_EXTERNAL_ID, content.productExternalId!)
    : undefined
});
