import React, { useCallback, ChangeEvent } from 'react';
import debounce from 'lodash/debounce';
import { useSearchBox } from 'react-instantsearch-hooks-web';
import { useTranslation } from 'react-i18next';

import { CloseButton, SearchInput } from '../../styles/searchEngine';
import Icon from 'shared/components/presentational/Icons';
import { ThemeFactory } from 'shared/types';
import { AlgoliaTheme } from '../../types';

export interface HeaderSearchProps {
  onClose: () => void;
  searchId?: string;
  closeButtonTheme: ThemeFactory;
  isSearchOpen: boolean;
  theme: AlgoliaTheme;
  handleShowResult(value: string): void;
}

const HeaderSearch = ({
  closeButtonTheme,
  isSearchOpen,
  onClose,
  theme,
  handleShowResult
}: HeaderSearchProps): JSX.Element => {
  const { t } = useTranslation();

  const { refine, clear } = useSearchBox();
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    if (!isSearchOpen) {
      clear();
      setValue('');
    }
  }, [isSearchOpen]);

  const debouncedRefine = React.useMemo(() => debounce(refine, 300), [refine]);

  const debouncedOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const currentValue = event.currentTarget.value;
      handleShowResult(currentValue);
      if (currentValue.length > 1) {
        debouncedRefine(currentValue);
      }
      setValue(currentValue);
    },
    [debouncedRefine]
  );

  return (
    <label htmlFor="headerSearch">
      <SearchInput
        aria-label="searchAriaLabel"
        autoComplete="off"
        id="headerSearch"
        onChange={debouncedOnChange}
        value={value}
        type="search"
        placeholder={t('blocks.searchEngine.findHelp')}
        theme={theme}
      />

      <CloseButton
        aria-label="closeAriaLabel"
        onClick={onClose}
        type="button"
        theme={closeButtonTheme}
        isSearchOpen={isSearchOpen}
      >
        <Icon name="Close" width="10px" height="10px" />
      </CloseButton>
    </label>
  );
};

HeaderSearch.displayName = 'HeaderSearch';

export default HeaderSearch;
