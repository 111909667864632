export function sendEvent(
  name: string,
  data: Record<string, string | undefined>
) {
  if (window.sendEvent) {
    window.sendEvent({
      name,
      type: 'button',
      data
    });
  }
}
