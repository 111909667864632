import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { shape, number } from 'prop-types';

import mediaQueries from '../../../helpers/styled-components/mediaQueries';

// Generate "grid-template-columns: 1fr 1fr 1fr;" if nbCols = 3
const getGridTemplateColCSS = nbCols => {
  const template = Array(nbCols).fill('1fr').join(' ');
  return `grid-template-columns: ${template};`;
};

const Wrapper = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-row-gap: 24px;
  ${({ cols }) => getGridTemplateColCSS(cols.hd)};

  ${mediaQueries.toDesktop`${({ cols }) =>
    getGridTemplateColCSS(cols.desktop)}`} ${mediaQueries.toTablet`${({
    cols
  }) => getGridTemplateColCSS(cols.tablet)}`} ${mediaQueries.toPhablet`${({
    cols
  }) => getGridTemplateColCSS(cols.phablet)}`} ${mediaQueries.toPhone`${({
    cols
  }) => getGridTemplateColCSS(cols.phone)}`} ${mediaQueries.toPhoneMini`${({
    cols
  }) => getGridTemplateColCSS(cols.phoneMini)}`};
`;

const DEFAULT_COLS = {
  hd: 5,
  desktop: 4,
  tablet: 3,
  phablet: 2,
  phone: 2,
  phoneMini: 1
};

export default class Grid extends PureComponent {
  static propTypes = {
    cols: shape({
      hd: number,
      desktop: number,
      tablet: number,
      phablet: number,
      phone: number,
      phoneMini: number
    })
  };

  render() {
    const { className, children, cols } = this.props;
    const mergedCols = {
      ...DEFAULT_COLS,
      ...cols
    };

    return (
      <Wrapper className={className} cols={mergedCols}>
        {children}
      </Wrapper>
    );
  }
}
