/* eslint-disable react/prop-types */
/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import BaseGrid from 'shared/components/presentational/Grid/Grid';
import MEAProgram from 'shared/components/presentational/MEAProgram/MEAProgram';

const Grid = styled(BaseGrid)`
  margin: 40px 0;
`;

const cols = {
  hd: 4,
  desktop: 4,
  tablet: 2,
  phablet: 1,
  phone: 1,
  phoneMini: 1
};

export default class DetailShowEpisodesList extends PureComponent {
  render() {
    const { data, baseLink } = this.props;

    return (
      <Grid cols={cols}>
        {data.episodes.contents.map(content => (
          <MEAProgram
            key={content.contentID}
            title={content.title}
            subtitle={content.subtitle}
            imageUrl={content.URLImage}
            linkUrl={`${baseLink}${content.path}`}
            imageHeight={113}
          />
        ))}
      </Grid>
    );
  }
}
