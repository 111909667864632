import {
  ButtonApi,
  Link,
  Media,
  ThemeFactory,
  CustomTitle
} from 'shared/types';

export enum Template {
  TWO_SLIDES = 'twoSlides',
  THREE_SLIDES = 'threeSlides'
}

export interface Slide {
  title: string | null;
  subtitle: string | null;
  media: Media;
  link: Link | null;
}

export interface Content {
  title: CustomTitle;
  subtitle: string | null;
  template: Template;
  buttons: {
    redirectButton: ButtonApi;
  };
  slider: Slide[];
}

export interface FormattedSlide {
  className?: string;
  title: string | null;
  subtitle: string | null;
  imageUrl: string;
  href: string;
  alt?: string;
}

export interface FormattedProps {
  title?: string;
  titleColor?: string;
  titleHtmlTag?: string;
  subtitle?: string;
  redirectButtonLabel?: string | null;
  redirectButtonUrl?: string;
  redirectButtonTheme?: ThemeFactory;
  template: Template;
  slider: FormattedSlide[];
}
