import React, { ReactNode, useCallback } from 'react';

import { Tab } from '../Tab/Tab';
import { useTabs } from './providers/TabsProvider';
import { Wrapper } from './styles/components';

export const Tabs = ({
  tabsTitle,
  children,
  ...props
}: {
  tabsTitle: string[];
  children: ReactNode;
}) => {
  const { currentTab } = useTabs();

  const renderTabs = useCallback(
    function renderTabs(title, index) {
      return (
        <Tab
          key={title}
          index={index}
          isCurrentTab={index === currentTab}
          title={title}
        />
      );
    },
    [currentTab]
  );

  return (
    <>
      <Wrapper role="toolbar" {...props}>
        {tabsTitle.map(renderTabs)}
      </Wrapper>
      {children}
    </>
  );
};
