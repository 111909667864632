/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { InnerModalWrapper } from '../styles/components';
import { FormattedArticle, Props } from '../types';
import ArticleSlide from './ArticleSlide';
import ResponsiveHoc from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';
import Title from 'shared/components/presentational/Title';
import Wrapper from 'shared/components/presentational/Wrapper/Wrapper';
import SliderOrList from 'shared/components/presentational/SliderOrList/SliderOrList';
import Loader from 'shared/blocks/selfcare/shared/components/Loader';
import formatArticleContent from '../../shared/helpers/formatArticleContent';
import AlternativeModal from '../../../../components/presentational/AlternativeModal';
import ArticleContentComponent from '../../shared/components/ArticleContent';
import ApiError from '../../../../components/presentational/ApiError';
import useArticleContentQuery from '../../shared/hooks/useArticleContent';

function List({
  title,
  titleColor,
  titleHtmlTag,
  subtitle,
  articles,
  mediaQueries,
  onArticleClose,
  onArticleClick,
  onArticleHover,
  url,
  lang,
  miniSiteDomain,
  domainUrl
}: Props) {
  const { t } = useTranslation();

  const { data, error, isLoading } = useArticleContentQuery(url, {
    miniSiteDomain,
    lang
  });

  const renderInnerModal = useCallback(() => {
    if (data) {
      const article = formatArticleContent(data, domainUrl);

      return (
        <ArticleContentComponent
          article={article}
          displayAsModal={true}
          mediaQueries={mediaQueries}
        />
      );
    }

    if (isLoading) {
      return <Loader />;
    }

    return <ApiError text={t('common.global.error')} />;
  }, [data, error, isLoading]);

  if (articles.length === 0) {
    return null;
  }

  return (
    <>
      <AlternativeModal
        modalProps={{
          isOpen: !!url,
          onCloseRequest: onArticleClose
        }}
      >
        <InnerModalWrapper>{renderInnerModal()}</InnerModalWrapper>
      </AlternativeModal>

      <Wrapper>
        {title && (
          <Title
            titleLabel={title}
            subtitle={subtitle}
            titleColor={titleColor}
            titleHtmlTag={titleHtmlTag}
          />
        )}

        <SliderOrList slidesToShow={mediaQueries.toTablet ? 2 : 3}>
          {articles.map((article: FormattedArticle, index: number) => {
            let imageUrl = article.mainMedia;

            if (mediaQueries.toPhablet && article.responsiveMedia) {
              imageUrl = article.responsiveMedia;
            }

            return (
              <ArticleSlide
                key={`article-${article.id}-${index}`}
                title={article.title}
                subtitle={article.subtitle}
                imageUrl={imageUrl}
                alt={article.alt}
                onClick={
                  onArticleClick
                    ? () => onArticleClick(article.slug)
                    : undefined
                }
                onHover={
                  onArticleHover
                    ? () => onArticleHover(article.slug)
                    : undefined
                }
              />
            );
          })}
        </SliderOrList>
      </Wrapper>
    </>
  );
}

export default ResponsiveHoc(List);
