import { push } from 'redux-first-history';

import hasCanalPlusUrl from 'shared/blocks/authentication/helpers/hasCanalPlusUrl';
import appendPassIdToCanalPlusUrl from '../uri/appendPassIdToCanalPlusUrl';
import isExternalURI from '../uri/isExternalURI';
import clientCookies from 'client/cookies';

export default (url: string) => (dispatch: any) => {
  if (hasCanalPlusUrl(url) && isExternalURI(url)) {
    const targetUrlWithToken = appendPassIdToCanalPlusUrl(
      url,
      clientCookies.getAll()
    );
    return window.location.assign(targetUrlWithToken);
  }

  return isExternalURI(url) ? window.location.assign(url) : dispatch(push(url));
};
