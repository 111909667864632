import { css } from 'styled-components';

import pxInRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

export const accordionHeaderStyled = css`
  position: relative;
  background-color: white;
  text-align: left;
  color: ${COLORS.black};
  justify-content: space-between;
  align-items: center;
  padding: 0 21px;
  ${props => props.isOpened && `font-weight: ${FONT_WEIGHTS.bold};`};
`;

export const accordionItemStyled = css`
  border-radius: var(--border-radius-xs);
  overflow: hidden;
`;

export const accordionContentStyled = css`
  background-color: white;
  padding: 14px 21px;
  border-top: 1px solid var(--border-main);
  margin-bottom: 10px;
  border-radius: 0 0 4px 4px;
`;

export const rawStyled = css`
  font-family: ${FONT_FAMILIES.base};
  color: ${COLORS.tundora};
  line-height: ${pxInRem(20)};
`;

export const containerStyled = css`
  position: relative;
  background-color: ${COLORS.concrete};
  height: 100%;
  padding: 40px;
  margin: 0 !important;

  ${mediaQueries.toPhablet`padding: 40px 20px;`};
`;

export const wrapperStyled = css`
  display: flex;
  justify-content: center;
  margin: auto;
`;

export const contentStyled = css`
  flex: 3;
`;
