import { COLORS } from 'shared/constants/theme';

export default {
  contact: {
    padding: '0',
    border: `1px solid ${COLORS.concrete}`,
    boxShadow: `0 0 2px ${COLORS.concrete}`,
    maxItem: 3
  }
};
