import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 36 36" width={width} height={height} className={className}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M13 9.9l13.1 7.6L13 25.1z" />
      <path d="M18 35.1C8.6 35.1.9 27.4.9 18 .9 8.6 8.6.9 18 .9 27.4.9 35.1 8.6 35.1 18c0 9.4-7.7 17.1-17.1 17.1zm0-30.9C10.4 4.2 4.2 10.4 4.2 18c0 7.6 6.2 13.8 13.8 13.8 7.6 0 13.8-6.2 13.8-13.8 0-7.6-6.2-13.8-13.8-13.8z" />
    </g>
  </svg>
);
