/* eslint-disable no-template-curly-in-string */
import i18next from 'i18next';
import 'server/i18n';

export default function equalTo(ref, msg) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg || i18next.t('common.form.validation.equalTo'),
    params: {
      reference: ref.path
    },
    test(value) {
      return value === this.resolve(ref);
    }
  });
}
