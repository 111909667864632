import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 46 62" width={width} height={height} className={className}>
    <path
      d="M39.8 11.4c3.2 0 5.7-2.4 5.7-5.7 0-3.2-2.4-5.6-5.7-5.6-3.3 0-5.6 2.4-5.6 5.6 0 3.2 2.4 5.7 5.6 5.7zM6.1 45c3.2 0 5.6-2.4 5.6-5.6 0-3.2-2.4-5.7-5.6-5.7-3.2 0-5.6 2.4-5.6 5.7 0 3.3 2.4 5.6 5.6 5.6zM23 61.9c3.2 0 5.6-2.4 5.6-5.6 0-3.3-2.3-5.7-5.6-5.7s-5.6 2.4-5.6 5.7c0 3.2 2.4 5.6 5.6 5.6zM6.1 28.2c3.2 0 5.6-2.4 5.6-5.7 0-3.2-2.4-5.6-5.6-5.6-3.2 0-5.6 2.4-5.6 5.6 0 3.3 2.4 5.7 5.6 5.7zM23 45c3.2 0 5.6-2.4 5.6-5.6 0-3.2-2.3-5.7-5.6-5.7s-5.6 2.4-5.6 5.7c0 3.3 2.4 5.6 5.6 5.6zM6.1 11.4c3.2 0 5.6-2.4 5.6-5.7C11.7 2.5 9.3.1 6.1.1 2.9.1.5 2.5.5 5.7c0 3.2 2.4 5.7 5.6 5.7zM23 28.2c3.2 0 5.6-2.4 5.6-5.7 0-3.2-2.3-5.6-5.6-5.6s-5.6 2.4-5.6 5.6c0 3.3 2.4 5.7 5.6 5.7zM39.8 45c3.2 0 5.7-2.4 5.7-5.6 0-3.2-2.4-5.7-5.7-5.7-3.3 0-5.6 2.4-5.6 5.7 0 3.3 2.4 5.6 5.6 5.6zM23 11.4c3.2 0 5.6-2.4 5.6-5.7 0-3.2-2.3-5.6-5.6-5.6s-5.6 2.4-5.6 5.6c0 3.2 2.4 5.7 5.6 5.7zm16.8 16.8c3.2 0 5.7-2.4 5.7-5.7 0-3.2-2.4-5.6-5.7-5.6-3.3 0-5.6 2.4-5.6 5.6 0 3.3 2.4 5.7 5.6 5.7z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
