import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 36 28" width={width} height={height} className={className}>
    <path
      d="M32.3.1H.9V23.3H14.8v1.9h-4.6V28h15.7v-2.8h-4.6v-1.9H35.2V.1h-2.9zm0 20.4H3.7V2.9h28.7v17.6h-.1z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
