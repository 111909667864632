import { Content, FormattedProps } from './types';
import { COLORS } from 'shared/constants/theme';

export default (content: Content): FormattedProps => ({
  saveButtonLabel: content.saveButton?.label ?? 'Enregistrer',
  saveButtonLabelColor: content.saveButton?.labelColor || COLORS.white,
  saveButtonBackground: content.saveButton?.backgroundColor || COLORS.black,
  editButtonLabel: content.editButton?.label || 'Modifier',
  editButtonLabelColor: content.editButton?.labelColor || COLORS.white,
  editButtonBackground: content.editButton?.backgroundColor || COLORS.black,
  saveSuccessLabel: content.saveSuccessLabel ?? '',
  apis: {
    getAddresses: {
      method: content.apis.selfcare_address.get.method || 'GET',
      url: content.apis.selfcare_address.get.url ?? ''
    },
    updateAddress: {
      method: content.apis.selfcare_address.update.method || 'PUT',
      url: content.apis.selfcare_address.update.url ?? ''
    },
    getPhones: {
      url: content.apis.selfcare_phone.get.url ?? '',
      method: content.apis.selfcare_phone.get.method || 'GET'
    },
    updatePhone: {
      url: content.apis.selfcare_phone.update.url ?? '',
      method: content.apis.selfcare_phone.update.method || 'PUT'
    },
    createPhone: {
      url: content.apis.selfcare_phone.create.url ?? '',
      method: content.apis.selfcare_phone.create.method || 'PUT'
    },
    getSubscriberData: {
      url: content.apis?.getSubscriberData?.url ?? '',
      method: content.apis?.getSubscriberData?.method || 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    }
  },
  contextCriteria: content.frontDisplayCriteria ?? null
});
