import * as React from 'react';

import Accordion from 'shared/components/presentational/Accordion';
import AccordionHeader from 'shared/components/presentational/Accordion/AccordionHeader';
import AccordionHandle from 'shared/components/presentational/Accordion/AccordionHandle';
import AccordionContent from 'shared/components/presentational/Accordion/AccordionContent';
import { Channel, Group } from './types';
import {
  AccordionTitle,
  ChannelGrid,
  ChannelGridItem,
  ChannelImage,
  CustomLink,
  StyledAccordionItem,
  StyledIcon
} from './styles/components';

export interface CardProps {
  channel: Channel;
}

type DisplayGroup = {
  defaultOpened: boolean;
};

const Card = ({ channel }: CardProps) => (
  <ChannelGridItem>
    <ChannelImage url={channel.imageLink} alt={channel.title} />
  </ChannelGridItem>
);

export default function ChannelsGroup({
  children,
  title,
  exponent,
  channels,
  defaultOpened
}: React.PropsWithChildren<DisplayGroup & Group>) {
  const renderChannel = React.useCallback((channel, index) => {
    return (
      <React.Fragment key={`${index}-${channel.title}`}>
        {channel.link ? (
          <CustomLink to={channel.link}>
            <Card channel={channel} />
          </CustomLink>
        ) : (
          <Card channel={channel} />
        )}

        {channel.exponent ? <sup>{channel.exponent}</sup> : null}
      </React.Fragment>
    );
  }, []);
  if (title) {
    return (
      <Accordion forcedOpenItems={defaultOpened ? [0] : []}>
        <StyledAccordionItem>
          <AccordionHeader>
            <AccordionTitle>{title}</AccordionTitle>
            {exponent ? <sup>{exponent}</sup> : null}
            <AccordionHandle>
              <StyledIcon name="ArrowBottom" width={13} height={13} />
            </AccordionHandle>
          </AccordionHeader>

          <AccordionContent>
            <ChannelGrid>{channels.map(renderChannel)}</ChannelGrid>
          </AccordionContent>
        </StyledAccordionItem>
      </Accordion>
    );
  }
  return (
    <ChannelGrid>
      {channels.map(renderChannel)}
      {children}
    </ChannelGrid>
  );
}
