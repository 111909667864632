import styled, { css } from 'styled-components';

import { COLORS } from 'shared/constants/theme';

export interface SelectTheme {
  theme?: {
    backgroundColor?: string;
    textColor?: string;
    hoverColor?: string;
    borderColor?: string;
    selectedColor?: string;
  };
  hasValue?: boolean;
  error?: boolean;
  border?: boolean;
}

export const getSelectTheme = (customTheme: SelectTheme = {}) => {
  const defaultTheme = {
    backgroundColor: COLORS.white,
    textColor: COLORS.mineShaft,
    hoverColor: COLORS.concrete,
    borderColor: COLORS.dodgerBlue,
    emptyColor: COLORS.alto,
    selectedColor: COLORS.black
  };

  const theme = {
    ...defaultTheme,
    ...customTheme
  };

  return css<SelectTheme>`
    .ReactSelect {
      &__single-value {
        color: ${theme.textColor};
      }

      &__placeholder {
        color: ${theme.borderColor};
      }

      &__control {
        outline: none;
        color: ${theme.textColor};
        background: ${theme.backgroundColor};
        border: ${props =>
          props.hasValue
            ? `1px solid ${theme.selectedColor}`
            : props.error
            ? `border: 1px solid ${COLORS.red}`
            : `1px solid ${theme.emptyColor}`};

        &--is-focused {
          border-color: ${theme.borderColor} !important;
          box-shadow: unset;
        }

        &:hover {
          border-color: ${props =>
            props.hasValue
              ? theme.selectedColor
              : props.error
              ? COLORS.red
              : theme.emptyColor};
        }
      }

      &__indicator-separator {
        display: none;
      }

      &__clear-indicator {
        cursor: pointer;

        &:hover {
          color: ${COLORS.red};
        }
      }

      &__menu {
        border: solid 1px ${theme.borderColor};
        border-radius: 3px;
        z-index: 1000;
        margin: 1px;
      }

      &__menu-list {
        border-radius: 3px;
        background: ${theme.backgroundColor};
        color: ${theme.textColor};
      }

      &__menu-list {
        border-radius: ${({ border }) => (border ? '0 2px 2px' : '0')};

        & > div {
          border: ${({ border }) =>
            border ? 'solid ' + theme.borderColor : 'none'};
          border-width: ${({ border }) => (border ? '0 1px 1px' : '0')};
        }
      }

      &__option {
        padding: 10px 12px;

        &--is-focused,
        &--is-selected,
        &:hover {
          cursor: pointer;
          color: ${theme.textColor};
          background-color: ${COLORS.frenchPass};
        }
      }
    }
  `;
};

export const StyledWrapper = styled.span<SelectTheme>`
  ${({ theme }) => getSelectTheme(theme)};
`;
