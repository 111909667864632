/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { Content, FormattedProps } from './types';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { SEE_ALL_RESULTS_BUTTON_LABEL_BY_INDEX } from '../header/propsFormatter';
import { PropsFormatterUtils, Rel } from 'shared/types';
import { getSelfcareIcon } from './helper/getSelfcareIcon';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  theme: {
    backgroundColor: content.menus.backgroundColor,
    backgroundColorModals: content.menus.backgroundColorModals,
    colorClosingModals: content.menus.colorClosingModals,
    link: {
      linksColor: content.menus.linksColor,
      linksColorSecondary: content.menus.linksColorSecondary,
      linksFontWeight: '400'
    }
  },
  link: getLinkHrefWithAuthentication(content.menus.redirectLinkLogo),
  authentication: {
    signup: {
      isCustomLink: content.menus.prospect.signupCTA.link?.rel !== Rel.signUp,
      link: getLinkHrefWithAuthentication(
        content.menus.prospect.signupCTA.link ?? ''
      )
    },

    login: {
      isCustomLink: content.menus.prospect.userMenu.link?.rel !== Rel.loginLink,
      link: getLinkHrefWithAuthentication(
        content.menus.prospect.userMenu.link ?? ''
      ),
      redirectLinkAfterLogin: getLinkHrefWithAuthentication(
        content.menus.prospect.userMenu.redirectLinkAfterLogin || ''
      )
    },
    logout: {
      isCustomLink:
        content.menus.authenticated.userMenu.link?.rel !== Rel.logoutLink,
      link: getLinkHrefWithAuthentication(
        content.menus.authenticated.userMenu.link ?? ''
      )
    }
  },
  languageSwitcher: {
    label: content.menus?.switchLanguage?.label,
    theme: buttonThemeFactory({
      backgroundColor: 'transparent',

      color: content.menus.linksColor,
      hover: {
        color: content.menus.linksColorSecondary
      }
    }),
    link: {
      linksColor: content.menus.linksColor,
      linksColorSecondary: content.menus.linksColorSecondary
    },
    backgroundColorModals: content.menus.backgroundColorModals
  },
  selfcareIcon: getSelfcareIcon(
    content.menus.prospect.selfcareIcon,
    content.menus.authenticated.selfcareIcon
  ),
  navigationMenu: {
    prospect: content.menus.prospect.navigationMenu.map(({ label, link }) => ({
      label,
      link: getLinkHrefWithAuthentication(link)
    })),
    authenticated: content.menus.authenticated.navigationMenu.map(
      ({ label, link }) => ({
        label,
        link: getLinkHrefWithAuthentication(link)
      })
    )
  },
  websiteLogo: content.menus.websiteLogo
    ? {
        media: {
          url: content.menus.websiteLogo?.link?.href,
          alt: content.menus.websiteLogo?.description || ''
        },
        link: getLinkHrefWithAuthentication(content.menus.redirectLinkLogo)
      }
    : null,
  prospect: {
    navigationMenu: content.menus.prospect.navigationMenu.map(
      ({ label, link }) => ({
        label,
        link: getLinkHrefWithAuthentication(link)
      })
    ),
    userMenu: {
      title: content.menus.prospect.userMenu.title,
      description: content.menus.prospect.userMenu.description,
      CTA: {
        buttonTheme: buttonThemeFactory({
          backgroundColor:
            content.menus.prospect.userMenu.loginCTA.backgroundColor,
          color: content.menus.prospect.userMenu.loginCTA.color,
          hover: {
            backgroundColor:
              content.menus.prospect.userMenu.loginCTA.backgroundHover,
            color: content.menus.prospect.userMenu.loginCTA.hover
          }
        }),
        label: content.menus.prospect.userMenu.loginCTA.label
      },
      menu: content.menus.prospect.userMenu.menu.map(
        ({ label, link, icon, color }) => ({
          label,
          link: getLinkHrefWithAuthentication(link),
          icon,
          color
        })
      )
    },
    signupCTA: {
      buttonTheme: buttonThemeFactory({
        backgroundColor: content.menus.prospect.signupCTA.backgroundColor,
        color: content.menus.prospect.signupCTA.color,
        hover: {
          backgroundColor: content.menus.prospect.signupCTA.backgroundHover,
          color: content.menus.prospect.signupCTA.hover
        }
      }),
      label: content.menus.prospect.signupCTA.label,
      icon: content.menus.prospect.signupCTA.icon
    }
  },
  authenticated: {
    navigationMenu: content.menus.authenticated.navigationMenu.map(
      ({ label, link }) => ({
        label,
        link: getLinkHrefWithAuthentication(link)
      })
    ),
    signedCTA: {
      icon: content.menus.authenticated.signedCTA.icon,
      label: content.menus.authenticated.signedCTA.label,
      link: getLinkHrefWithAuthentication(
        content.menus.authenticated.signedCTA?.link || ''
      ),
      buttonTheme: buttonThemeFactory({
        backgroundColor: content.menus.authenticated.signedCTA?.backgroundColor,
        color: content.menus.authenticated.signedCTA?.color,
        hover: {
          backgroundColor:
            content.menus.authenticated.signedCTA?.backgroundHover,
          color: content.menus.authenticated.signedCTA?.hover
        }
      })
    },
    userMenu: {
      title: content.menus.authenticated.userMenu.title,
      description: content.menus.authenticated.userMenu.description,
      CTA: {
        buttonTheme: buttonThemeFactory({
          backgroundColor:
            content.menus.authenticated.userMenu.logoutCTA.backgroundColor,
          color: content.menus.authenticated.userMenu.logoutCTA.color,
          hover: {
            backgroundColor:
              content.menus.authenticated.userMenu.logoutCTA.backgroundHover,
            color: content.menus.authenticated.userMenu.logoutCTA.hover
          }
        }),
        label: content.menus.authenticated.userMenu.logoutCTA.label
      },
      menu: content.menus.authenticated.userMenu.menu.map(
        ({ link, icon, label, color }) => ({
          label,
          link: getLinkHrefWithAuthentication(link),
          icon,
          color
        })
      )
    }
  },
  algolia: {
    icon: content.menus.algolia.icon
      ? {
          link: getLinkHrefWithAuthentication(content.menus.algolia.icon.link),
          alt: content.menus.algolia.icon.description || ''
        }
      : null,
    enableSearchEngine: content.menus.algolia.enableSearchEngine,
    api: {
      appId: content.menus.algolia.parameters.appId,
      apiKey: content.menus.algolia.parameters.appKey
    },
    theme: {
      inputBackgroundColor: content.menus.backgroundColor,
      backgroundColorModals: content.menus.backgroundColorModals,
      link: {
        linksColor: content.menus.linksColor,
        linksColorSecondary: content.menus.linksColorSecondary
      }
    },
    searchButton: buttonThemeFactory({
      backgroundColor: 'transparent',
      color: content.menus.prospect.signupCTA.color,
      hover: {
        color: content.menus.linksColorSecondary
      }
    }),
    seeAllResultsButtons: {
      seeAllResultsButtonsTheme: buttonThemeFactory({
        backgroundColor: content.menus.prospect.signupCTA.backgroundColor,

        color: content.menus.prospect.signupCTA.color,
        hover: {
          hover: content.menus.prospect.signupCTA.hover
        }
      }),
      mobileSeeAllResultsButtonLabel:
        content.menus.algolia.parameters.mobileSeeAllResultsButtonLabel
    },
    searchEngine: {
      indices: [
        ...content.menus.algolia.parameters.indexes.map(index => ({
          indexName: index.indexName,
          searchResultsLabel: index.searchResultsLabel,
          seeAllResultsButtonLabel:
            SEE_ALL_RESULTS_BUTTON_LABEL_BY_INDEX[index.indexName] ??
            SEE_ALL_RESULTS_BUTTON_LABEL_BY_INDEX['default']
        }))
      ],
      seeAllResultsButtonsTheme: buttonThemeFactory({
        backgroundColor: content.menus.prospect.signupCTA.backgroundColor,

        color: content.menus.prospect.signupCTA.color,
        hover: {
          hover: content.menus.prospect.signupCTA.hover
        }
      }),

      shortcuts: {
        shortcutsItems: content.menus.algolia.shortcutAlgoliaMenu.map(item => ({
          id: item.id,
          title: item.title,
          link: getLinkHrefWithAuthentication(item.link)
        })),
        title: content.menus.algolia.shortcutAlgoliaName
      }
    }
  }
});
