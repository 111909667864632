import React from 'react';

interface Props {
  className?: string;
  html: string;
}

const Raw = React.forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { className = '', html } = props;
  return (
    <div
      className={`${className} raw`}
      dangerouslySetInnerHTML={{ __html: html }}
      ref={ref}
    />
  );
});

export default Raw;
