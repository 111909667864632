/* eslint-disable react/prop-types */
/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import getData from 'shared/blocks/vod/hodor/selectors/getData';
import getErrors from 'shared/blocks/vod/hodor/selectors/getErrors';
import getRequested from 'shared/blocks/vod/hodor/selectors/getRequested';
import fetchVodPathIfNeeded from 'shared/blocks/vod/hodor/actions/fetchVodPathIfNeeded';
import ApiError from 'shared/components/presentational/ApiError/ApiError';
import Loader from 'shared/blocks/vod/hodor/components/Loader';

const mapStateToProps = (state, { fetchUrl }) => {
  const dataByPath = getData(state);
  const errorsByPath = getErrors(state);
  const requestedByPath = getRequested(state);

  const childLoading =
    requestedByPath[fetchUrl] ||
    (!dataByPath[fetchUrl] && !errorsByPath[fetchUrl]);

  return {
    childData: dataByPath[fetchUrl],
    childError: errorsByPath[fetchUrl],
    childRequested: requestedByPath[fetchUrl],
    childLoading
  };
};

const mapDispatchToProps = {
  fetch: fetchVodPathIfNeeded
};

/*
  The purpose of this Container is to be used in Hodor > Templates when
  we need to fetch somes child data like in DetailShow or GabaritList
  This container handle the fetch, loading and display the children component
  with the fetched data.

  The childrens components are in Hodor > components > childrens
 */

class HodorChildLoader extends PureComponent {
  UNSAFE_componentWillMount() {
    const {
      fetch,
      pathApiUrlMask,
      fetchUrl,
      childRequested,
      childError,
      childData
    } = this.props;

    if (childRequested || childError || childData) {
      return;
    }
    fetch(pathApiUrlMask, fetchUrl);
  }

  render() {
    const { childData, childLoading, childError } = this.props;

    if (childError) {
      return (
        <ApiError text="Une erreur est survenue" id="hodor_child_api_error" />
      );
    }

    if (childLoading) {
      return <Loader />;
    }

    if (childData) {
      const { baseLink, children, fetchUrl } = this.props;

      return React.cloneElement(children, {
        baseLink,
        path: fetchUrl,
        data: childData
      });
    }

    return false;
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HodorChildLoader)
);
