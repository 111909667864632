import queryString from 'query-string';

import { Content, FormattedProps } from './types';
import buttonThemeFactory from '../../../components/presentational/Button/helpers/themeFactory';
import { COLORS } from '../../../constants/theme';
import { PAYMENT_TYPE } from 'shared/blocks/selfcare/shared/constants';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: content.title.label ?? '',
  titleColor: content.title.color,
  titleHtmlTag: content.title.htmlTag,
  text: content.text ?? '',
  redirectButtonLabel: content.buttons?.redirectButton?.label ?? '',
  redirectButtonTheme: buttonThemeFactory({
    color: content.buttons?.redirectButton?.labelColor || COLORS.white,
    backgroundColor:
      content.buttons?.redirectButton?.backgroundColor || COLORS.cerulean
  }),
  redirectButtonUrl: queryString.stringifyUrl({
    url: getLinkHrefWithAuthentication(
      content.buttons?.redirectButton?.link ?? ''
    ),
    query: { type: PAYMENT_TYPE.RENEWAL }
  }),
  apis: {
    getRenewalOffer: {
      url: content.apis?.get_renewal_offer?.url ?? '',
      method: content.apis?.get_renewal_offer?.method || 'PUT'
    },
    getSubscriberData: {
      url: content.apis?.getSubscriberData?.url ?? '',
      method: content.apis?.getSubscriberData?.method ?? 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    }
  },
  contextCriteria: content.frontDisplayCriteria ?? null
});
