import { TASK_TYPE } from 'shared/modules/tasks/constants';
import clientCookies from 'client/cookies';
import getPageData from 'shared/modules/page/selectors/getPageData';
import getPagePath from 'shared/modules/page/selectors/getPagePath';
import { getDomainURL } from 'shared/modules/hostname/selectors/getDomainURL';
import createLoginRedirectUrl from 'shared/modules/page/helpers/createLoginRedirectUrl';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import getLinkHrefWithAuthenticationFactory from 'shared/helpers/uri/getLinkHrefWithAuthenticationFactory';
import * as ProgramMEAAction from '../shared/actions/index';
import { namespace } from './index';
import propsFormatter from './propsFormatter';

export default ({ dispatch, getState }) => [
  {
    pattern: '/:bigram([a-zA-Z]{2})?/*',
    [TASK_TYPE.DEFERRED_DATA]: async () => {
      const { page } = getState();

      if (page.data && page.data.blocks) {
        await Promise.all(
          page.data.blocks
            .filter(block => block.type === namespace)
            .map(async ({ content, id }) => {
              const state = getState();
              // Create getLinkHrefWithAuthentication helper
              const pageData = getPageData(state);
              const pagePath = getPagePath(state);
              const domainURL = getDomainURL(state);

              const loginUrlFactory = createLoginRedirectUrl(
                domainURL,
                clientCookies.get(COOKIE_KEYS.ZONE),
                pageData,
                pagePath
              );

              const getLinkHrefWithAuthentication = getLinkHrefWithAuthenticationFactory(
                clientCookies,
                loginUrlFactory
              );

              // Get formatted props
              const { apiUrl, timezone, createLinkUrl } = propsFormatter(
                content,
                {
                  getLinkHrefWithAuthentication
                }
              );

              // Fetch programs highlights
              await dispatch(
                ProgramMEAAction.fetchProgramsHighlightsByBlock(
                  id,
                  apiUrl,
                  timezone,
                  createLinkUrl
                )
              );
            })
        );
      }
    }
  }
];
