/* eslint-disable import/namespace */
import React, { PureComponent } from 'react';

import * as Components from './components';

export const ICON_NAMES = Object.keys(Components).reduce(
  (acc, name) => ({
    ...acc,
    [name.toUpperCase()]: name
  }),
  {}
);

export default class Icon extends PureComponent {
  render() {
    const { name, className, ...props } = this.props;

    const Component = Components[name];

    if (!Component || typeof Component !== 'function') return false;

    Component.displayName = `${name}Icon`;

    return <Component className={className} {...props} />;
  }
}
