import React from 'react';

import {
  useHandleModalsOpening,
  useHandleModalsOpeningDispatch
} from '../providers/ModalsOpeningProvider';
import { HandleModalsOpeningType } from '../reducers/ModalsOpeningReducer';

export const useDropdown = () => {
  const dispatch = useHandleModalsOpeningDispatch();
  const { isUserMenuOpened } = useHandleModalsOpening();
  const closeDropdown = React.useCallback(() => {
    dispatch({
      type: HandleModalsOpeningType.CLOSE_USER_MENU
    });
  }, [dispatch]);

  const openUserMenu = React.useCallback(() => {
    dispatch({
      type: HandleModalsOpeningType.OPEN_USER_MENU
    });
  }, [dispatch]);

  const toggleUserMenu = React.useCallback(() => {
    dispatch({
      type: isUserMenuOpened
        ? HandleModalsOpeningType.CLOSE_USER_MENU
        : HandleModalsOpeningType.OPEN_USER_MENU
    });
  }, [isUserMenuOpened, dispatch]);

  return {
    openUserMenu,
    closeDropdown,
    toggleUserMenu
  };
};
