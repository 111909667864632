import styled from 'styled-components';

import { container } from 'shared/constants/mixins';
import _Picture from 'shared/components/presentational/Picture';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

export const BANNHER_HEIGHT = {
  MOBILE_TEMPLATE_SMALL: 140,
  DESKTOP_TEMPLATE_SMALL: 341
};

export const BlockWrapper = styled.div`
  background-color: var(--surface-dark);
`;

export const Wrapper = styled.div`
  display: flex !important;
  flex-direction: column !important;

  ${mediaQueries.fromTablet`
    flex-direction: row-reverse !important;
    grid-auto-flow: column;
    gap: var(--spacing-xl);
  `}
`;

export const PictureWrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;

  ${mediaQueries.fromTablet`
   flex: 2;
  `}
`;

export const PictureShadow = styled.div`
  background: linear-gradient(180deg, transparent 0, #000);
  bottom: 0;
  height: 100px;
  position: absolute;
  width: 100%;
  z-index: 1;

  ${mediaQueries.fromTablet`
      background: linear-gradient(270deg, transparent 0, #000);
      bottom: auto;
      height: 100%;
      top: 0;
      width: 100px;
  `}
`;

export const Picture = styled(_Picture)<{ template: 'big' | 'small' }>`
  width: 100%;
  max-height: ${({ template }) =>
    template === 'small'
      ? `${BANNHER_HEIGHT.MOBILE_TEMPLATE_SMALL}px`
      : 'unset'};

  ${mediaQueries.fromTablet<{ template: 'big' | 'small' }>`
    max-height: ${({ template }) =>
      template === 'small'
        ? `${BANNHER_HEIGHT.DESKTOP_TEMPLATE_SMALL}px`
        : 'unset'};
  `}
  object-fit: cover;
`;

export const ContentWrapper = styled.div`
  ${container}
  display: flex;

  flex-direction: column;
  gap: var(--spacing-xl);
  flex: 1;
  ${mediaQueries.fromTablet`
  margin: auto;
  `}
`;

export const OfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);

  ${mediaQueries.fromTablet`
    align-self: center;
    padding-right: 0;
  `}
`;
