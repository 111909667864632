/* eslint-disable no-template-curly-in-string */
export default {
  mixed: {
    required: 'common.form.validation.required'
  },
  string: {
    email: 'common.form.validation.email',
    required: 'common.form.validation.required',
    min: ({ min }: { min: number }) => ({
      key: 'common.form.validation.minLength',
      values: { min }
    }),
    max: ({ max }: { max: number }) => ({
      key: 'common.form.validation.maxLength',
      values: { max }
    })
  },
  array: {
    min: ({ min }: { min: number }) => ({
      key: 'common.form.validation.minArray',
      values: { min }
    }),
    max: ({ max }: { max: number }) => ({
      key: 'common.form.validation.maxArray',
      values: { max }
    })
  },
  number: {
    default: 'common.form.validation.onlyNumbers'
  }
};
