import { Media, Link } from 'shared/types';

export enum FragmentType {
  text = 'text',
  image = 'image',
  video = 'video'
}

export interface TextFragment {
  title: string;
  type: FragmentType.text;
  content: string;
}

export interface ImageFragment {
  title: string;
  type: FragmentType.image;
  content: Media;
  link: Link | null;
}

export interface VideoFragment {
  title: string;
  type: FragmentType.video;
  content: {
    video: Media;
    poster: Media;
  };
}

export type Fragment = TextFragment | ImageFragment | VideoFragment;

export interface FormattedTextFragment {
  type: FragmentType.text;
  content: string;
}

export interface FormattedImageFragment {
  type: FragmentType.image;
  url: string;
  alt: string | null;
  link?: string;
}

export interface FormattedVideoFragment {
  type: FragmentType.video;
  videoUrl: string;
  posterUrl: string;
}

export type FormattedFragment =
  | FormattedTextFragment
  | FormattedImageFragment
  | FormattedVideoFragment;

export interface FormattedArticleContent {
  id: number | null;
  title: string | null;
  link?: string | null;
  subtitle: string | null;
  slug: string;
  mainMedia: string;
  responsiveMedia: string;
  alt?: string;
  fragments: FormattedFragment[];
}

export interface ArticleContent {
  articleId: number;
  title: string;
  subtitle: null | string;
  slug: string;
  mainMedia: Media;
  responsiveMedia: Media | null;
  fragments: Fragment[];
  category: {
    id: number;
    name: string;
  };
}
