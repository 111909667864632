import get from 'lodash/get';

import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { COLORS } from 'shared/constants/theme';
import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => {
  return {
    src: get(content, 'video.link.href', ''),
    poster: get(content, 'poster.link.href', ''),
    text: get(content, 'text', ''),
    button: {
      label: get(content, 'buttons.main.label', ''),
      link: getLinkHrefWithAuthentication(
        get(content, 'buttons.main.link', '')
      ),
      theme: buttonThemeFactory({
        backgroundColor: get(
          content,
          'buttons.main.backgroundColor',
          COLORS.carnation
        ),
        color: get(content, 'buttons.main.labelColor', COLORS.white)
      })
    }
  };
};
