/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Tab from 'shared/components/presentational/Tab';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { ComponentProps } from 'shared/blocks/tabs/type';

const Wrapper = styled.div`
  margin: 20px 60px;
  ${mediaQueries.toPhablet`
      margin: 0;
  `};
`;

const Items = styled.ul<{ borderColor: string }>`
  list-style-type: none;
  display: flex;
  overflow: auto;

  ${mediaQueries.toPhablet`
      margin: 0;
      flex-wrap: nowrap;
      overflow-x: auto;
  `};

  ${mediaQueries.toPhoneMini`
      margin: 0;
      width: auto;
  `};

  li:first-child {
    margin-left: auto;
  }

  li:last-child {
    margin-right: auto;
    border-right: 1px solid ${props => props.borderColor};
  }
`;

const Item = styled.li<{ borderColor: string }>`
  display: flex;
  width: calc(100% / 3);
  max-width: 260px;
  min-width: 100px;
  border: 1px solid ${props => props.borderColor};
  border-right: none;
`;

export default class DefaultTabs extends PureComponent<
  Pick<
    ComponentProps,
    'tabs' | 'pushURLHandler' | 'labelColor' | 'backgroundColor' | 'borderColor'
  >
> {
  public render(): React.ReactNode {
    const { tabs, labelColor, backgroundColor, borderColor } = this.props;

    return (
      <Wrapper>
        <Items borderColor={borderColor}>
          {tabs.map((tab, index) => (
            <Item key={`Tab_${index}`} borderColor={borderColor}>
              <Tab
                label={tab.label}
                active={tab.active}
                url={tab.url}
                labelColor={labelColor}
                backgroundColor={backgroundColor}
              />
            </Item>
          ))}
        </Items>
      </Wrapper>
    );
  }
}
