import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  AvailableCommitmentDurations,
  DetailsWrapper,
  LeftColumn,
  MarketingPriceExponent,
  MarketingPriceInteger,
  StrikeThroughPrice,
  MarketingPriceTerms,
  PriceInformation,
  PriceWrapper,
  WrapperNetPrice,
  RightColumn,
  Wrapper
} from '../styles/components';
import { createTemplate } from 'shared/helpers/template';
import { currenciesFormatter } from '../../../helpers/currenciesFormatter';
import { ProductMajor } from '../../../types';
import { Currency } from 'shared/types';

type Prices = Pick<ProductMajor['offerDetails'], 'price' | 'netPrice'> &
  Currency;

const getPrices = ({
  price,
  netPrice,
  locale,
  currency,
  currencyDisplay
}: Prices) => {
  if (price && netPrice) {
    return {
      displayPrice: currenciesFormatter(locale, currency, { currencyDisplay })(
        netPrice
      ),
      strikePrice: currenciesFormatter(locale, currency, { currencyDisplay })(
        price
      )
    };
  }
  if (price) {
    return {
      displayPrice: currenciesFormatter(locale, currency, { currencyDisplay })(
        price
      ),
      strikePrice: null
    };
  }
  if (netPrice) {
    return {
      displayPrice: currenciesFormatter(locale, currency, { currencyDisplay })(
        netPrice
      ),
      strikePrice: null
    };
  }
  return {
    displayPrice: null,
    strikePrice: null
  };
};

export type Props = {
  variant?: 'default' | 'modal' | 'selection';
} & ProductMajor['offerDetails'] &
  Currency;

export const OfferDetailsRoc = ({
  availableCommitmentDurations,
  commitmentConditions,
  price,
  netPrice,
  priceInformation,
  engagementUnit,
  promoDuration,
  locale,
  currency,
  currencyDisplay,
  variant = 'default'
}: Props) => {
  const { t } = useTranslation();

  const { displayPrice, strikePrice } = getPrices({
    price,
    netPrice,
    locale,
    currency,
    currencyDisplay
  });
  const { integerPart, decimalPart, currencyPart } = displayPrice || {};

  const { fullPrice } = currenciesFormatter(locale, currency)(price);
  const formatedCommitmentConditions =
    commitmentConditions &&
    createTemplate(commitmentConditions)({
      promoDuration,
      engagementUnit: t(`blocks.oneShop.${engagementUnit}`),
      price: `${fullPrice}`
    });

  const isEnglishFormat = locale.startsWith('en-');

  const DisplayNetPrice = () => {
    if (!currencyPart) {
      return <p>undefined</p>;
    }
    return isEnglishFormat ? (
      <WrapperNetPrice isSmall={variant === 'modal'}>
        <MarketingPriceExponent isSmall={variant === 'modal'}>
          {currencyPart}
        </MarketingPriceExponent>
        <MarketingPriceInteger isSmall={variant === 'modal'}>
          {integerPart}
        </MarketingPriceInteger>
        <MarketingPriceExponent isSmall={variant === 'modal'}>
          {decimalPart}
        </MarketingPriceExponent>
      </WrapperNetPrice>
    ) : (
      <WrapperNetPrice isSmall={variant === 'modal'}>
        <MarketingPriceInteger isSmall={variant === 'modal'}>
          {integerPart}
        </MarketingPriceInteger>
        <MarketingPriceExponent isSmall={variant === 'modal'}>
          {currencyPart}
        </MarketingPriceExponent>
        <MarketingPriceExponent isSmall={variant === 'modal'}>
          {decimalPart}
        </MarketingPriceExponent>
      </WrapperNetPrice>
    );
  };

  return (
    <Wrapper>
      {priceInformation && (
        <PriceInformation>{priceInformation}</PriceInformation>
      )}
      <PriceWrapper>
        <DetailsWrapper>
          <LeftColumn variant={variant}>
            <DisplayNetPrice />
            <MarketingPriceTerms>
              <StrikeThroughPrice>{strikePrice?.fullPrice}</StrikeThroughPrice>/
              {t(`blocks.oneShop.${engagementUnit}`)}
            </MarketingPriceTerms>
          </LeftColumn>
          {formatedCommitmentConditions && (
            <RightColumn variant={variant}>
              {formatedCommitmentConditions}
            </RightColumn>
          )}
        </DetailsWrapper>
        {availableCommitmentDurations && (
          <AvailableCommitmentDurations>
            {availableCommitmentDurations}
          </AvailableCommitmentDurations>
        )}
      </PriceWrapper>
    </Wrapper>
  );
};
