import React from 'react';

export default ({ className = '', width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4849 9V7.5C16.4849 5.0175 14.4674 3 11.9849 3C9.50236 3 7.48486 5.0175 7.48486 7.5V9H8.98486V7.5C8.98486 5.84258 10.3274 4.5 11.9849 4.5C13.6424 4.5 14.9849 5.84258 14.9849 7.5V9H16.4849Z"
      fill="#22252A"
    />
    <path
      d="M5.25 11.9932V19.5007C5.25 20.3256 5.925 21.0007 6.75 21.0007H17.25C18.0749 21.0007 18.75 20.3256 18.75 19.5007V11.9932C18.75 11.1682 18.0749 10.4932 17.25 10.4932C15.0749 10.4932 8.89193 10.4932 6.75 10.4932C5.925 10.4932 5.25 11.1682 5.25 11.9932ZM12 13.5007C12.8325 13.5007 13.5075 14.1756 13.5075 15.0082C13.5075 15.5707 13.2075 16.0581 12.7575 16.3132V17.2432C12.7575 17.6557 12.42 17.9932 12.0075 17.9932C11.595 17.9932 11.2575 17.6557 11.2575 17.2432V16.3207C10.8 16.0656 10.4925 15.5782 10.4925 15.0082C10.4925 14.1756 11.1675 13.5007 12 13.5007Z"
      fill="#22252A"
    />
  </svg>
);
