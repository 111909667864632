import { ACTION_TYPES } from '../constants';

export default (url: string, originUrl: string, error: string) => ({
  type: ACTION_TYPES.FETCH_MORE_VOD_ERROR,
  payload: {
    url,
    originUrl,
    error
  }
});
