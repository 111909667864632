import styled from 'styled-components';

import { mediaQueries } from 'shared/helpers/styled-components/mediaQueries';

export const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  ${mediaQueries.toTablet`
    margin-bottom: 0
  `};
`;
