export const MODULE_NAME = 'Selfcare/Offers';

export enum PRICE_FREQUENCY {
  YEARLY = 'yearly',
  MONTHLY = 'monthly'
}

export enum TEST_IDS {
  WRAPPER = 'selfcareOffers__WRAPPER'
}
