import clientCookies from 'client/cookies';
import { TASK_TYPE } from 'shared/modules/tasks/constants';
import getBlocksByNamespaces from 'shared/modules/page/selectors/getBlocksByNamespaces';
import getPageData from 'shared/modules/page/selectors/getPageData';
import getPagePath from 'shared/modules/page/selectors/getPagePath';
import { getDomainURL } from 'shared/modules/hostname/selectors/getDomainURL';
import createLoginRedirectUrl from 'shared/modules/page/helpers/createLoginRedirectUrl';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import getLinkHrefWithAuthenticationFactory from 'shared/helpers/uri/getLinkHrefWithAuthenticationFactory';
import { namespace } from './index';
import fetchAutomaticVisualsS from './actions/fetchAutomaticVisualsS';
import propsFormatter from './propsFormatter';

export default ({ dispatch, getState }) => [
  {
    pattern: '/:bigram([a-zA-Z]{2})?/*',
    [TASK_TYPE.DEFERRED_DATA]: async () => {
      const state = getState();

      const blocks = getBlocksByNamespaces(state, [namespace]);

      if (blocks.length) {
        await Promise.all(
          blocks.map(async block => {
            const state = getState();
            // Create getLinkHrefWithAuthentication helper
            const pageData = getPageData(state);
            const pagePath = getPagePath(state);
            const domainURL = getDomainURL(state);

            const loginUrlFactory = createLoginRedirectUrl(
              domainURL,
              clientCookies.get(COOKIE_KEYS.ZONE),
              pageData,
              pagePath
            );

            const getLinkHrefWithAuthentication = getLinkHrefWithAuthenticationFactory(
              clientCookies,
              loginUrlFactory
            );

            // Get formatted props
            const { visualsS, folderUrl, detailUrl } = propsFormatter(
              block.content,
              {
                getLinkHrefWithAuthentication
              }
            );
            return dispatch(
              fetchAutomaticVisualsS(block.id, visualsS, folderUrl, detailUrl)
            );
          })
        );
      }
    }
  }
];
