import get from 'lodash/get';

const getPersonalitiesInfos = (data, key, separator = ', ') => {
  const personnalities = get(data, 'detail.informations.personnalities', []);
  if (personnalities.length && personnalities[key]) {
    return {
      content: personnalities[key].personnalitiesList
        .map(p => p.title)
        .join(separator),
      prefix: personnalities[key].prefix
    };
  }
  return null;
};

export default data => {
  const { informations } = data.detail;

  return {
    title: informations.title,
    summary: informations.summary,
    ratingCSA: informations.CSA,
    firstSubtitleA: informations.thirdTitle,
    firstSubtitleB: `${informations.audioLanguage || ''} ${
      informations.HD ? 'HD' : ''
    }`,
    secondSubtitle: informations.editorialTitle,
    reviews: (informations.reviews || []).filter(
      review => review.displayRating
    ),
    authorInfo: getPersonalitiesInfos(data, 0),
    personnalitiesInfo: getPersonalitiesInfos(data, 1),
    isPlayerAvailable: informations.isInOfferForVoD,
    URLImage: informations.URLImage,
    playerContent: informations.contentID
  };
};
