import React from 'react';

import {
  HeaderProfileIconStyled,
  IconStyled,
  PictureStyled,
  ProfileAvatarStyled
} from './ProfileIconStyled';
import Icon from 'shared/components/presentational/Icons';

export type HeaderProfileIconProps = {
  icon: { alt: string | null; url?: string };
};

const HeaderProfileIcon = ({ icon: { url, alt } }: HeaderProfileIconProps) => {
  return (
    <HeaderProfileIconStyled>
      {url ? (
        <ProfileAvatarStyled
          id="profileAvatar_onclick"
          data-e2e="profileAvatar"
        >
          <PictureStyled width={32} height={32} url={url} alt={alt || ''} />
        </ProfileAvatarStyled>
      ) : (
        <IconStyled dark>
          <Icon dark name="User" />
        </IconStyled>
      )}
    </HeaderProfileIconStyled>
  );
};

HeaderProfileIcon.displayName = 'HeaderProfileIcon';

export default React.memo(HeaderProfileIcon);
