import React from 'react';
import styled from 'styled-components';

import { FONT_FAMILIES } from 'shared/constants/theme';
import Picture from 'shared/components/presentational/Picture';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { FormattedAdvantage, TEMPLATE_DIGITAL_ADVANTAGES } from '../types';
import FoldsAdvantageCard from './FoldsAdvantageCard';
import FixedAdvantageCard from './FixedAdvantageCard';

interface Props {
  advantage: FormattedAdvantage;
  color: string;
  toggleColor?: string;
  template: TEMPLATE_DIGITAL_ADVANTAGES;
}

const CardWrapper = styled.div<{ template: TEMPLATE_DIGITAL_ADVANTAGES }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props =>
    props.template === TEMPLATE_DIGITAL_ADVANTAGES.FIXED ? 'auto' : '190px'};
  text-align: center;
  font-family: ${FONT_FAMILIES.brand};
  ${mediaQueries.toTablet<{ template: TEMPLATE_DIGITAL_ADVANTAGES }>`
    max-width: 150px;
    flex-basis:${props =>
      props.template === TEMPLATE_DIGITAL_ADVANTAGES.FIXED ? '33%' : 'auto'};
  `}
  ${mediaQueries.toPhoneMini`
    max-width: 140px;

  `}
`;

const AdvantageItem = ({ advantage, color, toggleColor, template }: Props) => {
  return (
    <CardWrapper template={template}>
      {template === TEMPLATE_DIGITAL_ADVANTAGES.FIXED ? (
        <>
          <Picture url={advantage.logo} alt="logo" />
          <FixedAdvantageCard color={color} advantage={advantage} />
        </>
      ) : (
        <FoldsAdvantageCard
          color={color}
          toggleColor={toggleColor}
          advantage={advantage}
        />
      )}
    </CardWrapper>
  );
};

export default AdvantageItem;
