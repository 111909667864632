import { mapProps } from 'recompose';
import delay from 'lodash/delay';

import { getElementPosition, scrollTo } from '../viewport';

// The error enhance expect to be wrapped by the ResponsiveHOC and withFormik Higher-Order Components
export default mapProps(({ handleSubmit, device, ...ownerProps }) => ({
  ...ownerProps,
  device,
  handleSubmit: e => {
    handleSubmit(e);
    delay(() => scrollTo(getElementPosition('[data-error]')), 100);
  }
}));
