/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { PureComponent } from 'react';
import { bool, shape } from 'prop-types';
import styled from 'styled-components';

import remHelper from 'shared/helpers/styled-components/remHelper';
import { COLORS, FONT_FAMILIES } from 'shared/constants/theme';

const Label = styled.span`
  display: inline-block;
  font-family: ${FONT_FAMILIES.base};
  font-size: ${remHelper(10)};
  border-radius: 2px;
  padding: 5px;
  margin-right: 14px;
  color: ${COLORS.white};
  background: ${COLORS.fluorescentOrange};

  &[name='sensCritique'] {
    background: ${COLORS.mantisGreen};
  }

  &[name='telerama'] {
    background: ${COLORS.strongRed};
  }
`;

export default class Labels extends PureComponent {
  static propTypes = {
    labels: shape({
      allocine: bool,
      sensCritique: bool,
      telerama: bool
    })
  };

  render() {
    const { labels, t } = this.props;
    return (
      <div>
        {labels.allocine && (
          <Label>{t('components.programmDetail.allocine')}</Label>
        )}
        {labels.telerama && (
          <Label name="telerama">
            {t('components.programmDetail.telerama')}
          </Label>
        )}
        {labels.sensCritique && (
          <Label name="sensCritique">
            {t('components.programmDetail.sensCritique')}
          </Label>
        )}
      </div>
    );
  }
}
