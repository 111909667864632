/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useEffect, useRef } from 'react';
import after from 'lodash/after';
import styled, { ThemeProvider } from 'styled-components';

import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import { DefaultTab } from 'shared/blocks/tabs/type';

const Wrapper = styled.div`
  position: relative;
  margin: auto;
  width: 100%;
  scroll-behavior: smooth;

  ${mediaQueries.toPhablet`
    overflow-x: auto;
    overflow-y: hidden;
  `};
`;

const Items = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  display: flex;
  text-align: center;
  flex-wrap: wrap;

  ${mediaQueries.toPhablet`
    display: inline-flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin: 0px 15px;
  `};
`;

const Item = styled.li`
  display: flex;
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  align-items: center;
  text-transform: uppercase;
  * {
    padding: 11px 25px;
    border-bottom: 2px solid transparent;

    ${mediaQueries.toPhablet`
      padding: 8px 12px;
      font-size: ${pxInRem(11)};
      border-bottom: 2px solid transparent;
    `};
  }
`;

const ActiveLink = styled.span`
  color: ${props => props.theme.UnderscoreTabs.currentTabColor};
  border-color: ${props => props.theme.UnderscoreTabs.currentTabColor};
  &:hover {
    cursor: pointer;
  }
`;

interface Props {
  tabs: DefaultTab[];
  currentTabColor?: string;
}

const scrollExceptTheFirstTimeTo = after(
  2,
  (element: HTMLDivElement, left: number) => {
    element.scrollLeft = left;
  }
);

export default ({ tabs, currentTabColor }: Props) => {
  const tabsWrapperRef = useRef<HTMLDivElement>(null);
  const activeTabRef = useRef<HTMLSpanElement>(null);

  // when ref resolves ...
  useEffect(() => {
    const tabsWrapperElement = tabsWrapperRef.current;
    const activeTabElement = activeTabRef.current;

    if (tabsWrapperElement !== null && activeTabElement !== null) {
      // current scroll value
      const currentHorizontalScroll = tabsWrapperElement.scrollLeft;

      // horizontal position in px of wrapper's center
      const tabsWrapperCenterHorizontalPosition =
        tabsWrapperElement.getBoundingClientRect().width / 2;

      // horizontal position in px of active tab
      const activeTabHorizontalOffset =
        // position relative to the viewport + current absolute scroll
        activeTabElement.getBoundingClientRect().left + currentHorizontalScroll;

      // active tab width in px
      const activeTabWidth = activeTabElement.getBoundingClientRect().width;

      // horizontal position in px of active tab's center
      const activeTabCenterHorizontalPosition =
        activeTabHorizontalOffset + activeTabWidth / 2;

      // computed scroll value to make active tab's centered
      const computedTabsHorizontalScroll =
        activeTabCenterHorizontalPosition - tabsWrapperCenterHorizontalPosition;

      // scroll to the computed value
      // we block scroll on the first call to avoid offset issue on SSR
      scrollExceptTheFirstTimeTo(
        tabsWrapperElement,
        computedTabsHorizontalScroll
      );
    }
  }, [tabsWrapperRef.current, activeTabRef.current]);

  return (
    <ThemeProvider
      theme={(inherited: any) => ({
        ...inherited,
        UnderscoreTabs: {
          currentTabColor
        }
      })}
    >
      <Wrapper ref={tabsWrapperRef}>
        <Items>
          {tabs.map((tab, index) => (
            <Item key={`Tab_${index}`}>
              {tab.active ? (
                <ActiveLink ref={activeTabRef}>{tab.label}</ActiveLink>
              ) : (
                <CustomLink to={tab.url}>{tab.label}</CustomLink>
              )}
            </Item>
          ))}
        </Items>
      </Wrapper>
    </ThemeProvider>
  );
};
