import { COLORS } from 'shared/constants/theme';

export default {
  button: {
    normal: {
      backgroundColor: COLORS.black,
      borderColor: COLORS.black,
      color: COLORS.white,
      textDecoration: 'none'
    },
    hover: {
      backgroundColor: COLORS.amaranth,
      borderColor: COLORS.amaranth,
      color: COLORS.white,
      textDecoration: 'none'
    },
    disabled: {
      backgroundColor: '#919191',
      borderColor: '#919191',
      color: '#c8c8c8',
      textDecoration: 'none'
    }
  }
};
