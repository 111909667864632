/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { PureComponent } from 'react';

import { FormattedProps } from '../types';
import Container from 'shared/components/presentational/Container/Container';
import Slide from 'shared/components/presentational/Slider/Slide/Slide';
import ResponsiveHoc, {
  ResponsiveHocProps
} from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';
import LayoutHeroBanner from '../../hero-banner/components/LayoutHeroBanner';
import { Wrapper, SliderOrList } from '../styles/components';
import Title from 'shared/components/presentational/Title';
import Spacer from 'shared/components/presentational/Spacer';

type Props = FormattedProps & ResponsiveHocProps;

class Cover extends PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      title,
      titleColor,
      titleHtmlTag,
      subtitle,
      bannerTitle,
      bannerSubtitle,
      bannerBackgroundImageUrl,
      bannerBackgroundImageAlt,
      bannerTemplate,
      bannerButtonLabel,
      bannerButtonTheme,
      bannerButtonUrl,
      slider,
      mediaQueries
    } = this.props;

    const bannerProps = {
      bannerTitle,
      bannerSubtitle,
      bannerBackgroundImageUrl,
      bannerBackgroundImageAlt,
      bannerTemplate,
      bannerButtonLabel,
      bannerButtonTheme,
      bannerButtonUrl
    };

    let slidesToShow = 3;

    if (mediaQueries.toTablet) {
      slidesToShow = 2;
    }

    return (
      <Wrapper>
        {(title || subtitle) && (
          <Container>
            {title && title.length > 0 ? <Spacer height={2} /> : null}

            <Title
              titleLabel={title}
              subtitle={subtitle}
              titleColor={titleColor}
              titleHtmlTag={titleHtmlTag}
            />
          </Container>
        )}
        <LayoutHeroBanner {...bannerProps} />
        <SliderOrList slidesToShow={slidesToShow}>
          {slider.map((slide, index) => (
            <Slide
              data-index={index}
              key={index}
              disableThumbnailLazyLoad={true}
              {...slide}
            />
          ))}
        </SliderOrList>
      </Wrapper>
    );
  }
}

export default ResponsiveHoc(Cover);
