import checkEncryptedOfferSubscriptionForm from 'shared/blocks/eshop/offer-form/actions/ckeckEncryptedOfferSubscriptionFrom';

/**
 * This initial action is created to check validity of encrypted data in store (persisted in local/sessionStorage).
 * Actions dispatched here are loops which calls themselves in order to perform validity checks regularly.
 */
export default (dispatch: any) => {
  // check validity of saved eshops offer subscription form
  dispatch(checkEncryptedOfferSubscriptionForm);
};
