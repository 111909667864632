import { createSelector } from 'reselect';

import { getShops, getFilters } from './';
import { FILTERS_TYPE } from '../constants';

/*       */

/**
 * applyFilter - returns false if shop does not match filter
 */
const applyFilter = (shop, filter) => {
  const { value, type, filterOn } = filter;

  if (!value) return true;

  if (type === FILTERS_TYPE.SELECT) {
    return shop[filterOn] === value;
  }
  return shop[filterOn].toLowerCase().includes(value.toLowerCase());
};

/**
 * applyFilters - returns false if shop does not match all filters
 */
const applyFilters = (shop, filters) =>
  filters.reduce((acc, filter) => {
    const res = applyFilter(shop, filter);
    return acc === false ? acc : res;
  }, true);

export default createSelector([getShops, getFilters], (shops, filters) =>
  shops.filter(shop => applyFilters(shop, filters))
);
