/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import dayjs from 'dayjs';

import { TYPES } from '../actions';
import { FILTERS_TYPE } from '../constants';

// -----------------------------------------------------------------------------
// EXPORTED REDUCER STATE TYPE

export const FETCHING_STATES = {
  PENDING: 'pending',
  ERROR: 'error',
  SUCCESS: 'success'
};
/*
type FETCHING_STATES_TYPES = {
  PENDING: FETCHING_STATES.PENDING,
  ERROR: FETCHING_STATES.ERROR,
  SUCCESS: FETCHING_STATES.SUCCESS
};

export type State = {
  list: {
    fetchingState:
      | FETCHING_STATES_TYPES.PENDING
      | FETCHING_STATES_TYPES.ERROR
      | FETCHING_STATES_TYPES.SUCCESS,
    error: Object | null,
    fetchingUrl: ?string,
    fetchedAt: ?string,
    byId: {},
    url: string | null
  },
  shopsDetails: {
    byId: {
      string:
        | FETCHING_STATES_TYPES.PENDING
        | FETCHING_STATES_TYPES.ERROR
        | FETCHING_STATES_TYPES.SUCCESS
    }
  },
  filters: { string: Filter }
};
*/

// -----------------------------------------------------------------------------
// PRIVATES

const staticFilters = {
  name: {
    filterOn: 'name',
    id: 1337,
    name: 'blocks.shops.map.shopName',
    type: FILTERS_TYPE.AUTO_COMPLETE
  }
};

export const defaultState = {
  list: {
    fetchingState: FETCHING_STATES.SUCCESS,
    error: null,
    fetchingUrl: null,
    fetchedAt: null,
    byId: {},
    url: null
  },
  shopsDetails: {
    byId: {}
  },
  filters: {
    city: {
      name: 'Ville',
      filterOn: 'city',
      type: FILTERS_TYPE.SELECT
    }
  }
};

// -----------------------------------------------------------------------------

/**
 * Converts an Array to an Object with given key
 *
 * eg: [{id: 1337, name: 'name' }] => { 1337: {id: 1337, name: 'name' }}
 *
 * @param {string} key
 * @param {Array} array
 */
export const convertToObjectWithKey = (key, array) =>
  array.reduce((acc, curr) => {
    acc[curr[key]] = curr;
    return acc;
  }, {});

export default function shopsShared(state = defaultState, action) {
  switch (action.type) {
    case TYPES.FETCH_SHOPS.PENDING:
      return {
        ...state,
        list: {
          ...state.list,
          error: null,
          fetchingState: FETCHING_STATES.PENDING,
          fetchingUrl: action.payload.url
        }
      };

    case TYPES.FETCH_SHOPS.SUCCESS: {
      const { data, url } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          fetchingState: FETCHING_STATES.SUCCESS,
          fetchedAt: dayjs().unix(),
          url,
          byId: convertToObjectWithKey('id', data.boutiques)
        },
        filters: {
          ...state.filters,
          ...convertToObjectWithKey('filterOn', data.filtersAndSorts),
          ...staticFilters
        }
      };
    }
    case TYPES.FETCH_SHOPS.ERROR: {
      return {
        ...state,
        list: {
          ...state.list,
          fetchingState: FETCHING_STATES.ERROR,
          error: action.payload.error
        }
      };
    }
    case TYPES.FETCH_SHOPS_DETAILS.PENDING: {
      return {
        ...state,
        shopsDetails: {
          ...state.shopsDetails,
          byId: {
            ...state.shopsDetails.byId,
            [action.payload.id]: FETCHING_STATES.PENDING
          }
        }
      };
    }

    case TYPES.FETCH_SHOPS_DETAILS.SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          byId: {
            ...state.list.byId,
            [action.payload.id]: action.payload.data.detail.informations
          }
        },
        shopsDetails: {
          ...state.shopsDetails,
          byId: {
            ...state.shopsDetails.byId,
            [action.payload.id]: FETCHING_STATES.SUCCESS
          }
        }
      };
    }

    case TYPES.FETCH_SHOPS_DETAILS.ERROR: {
      return {
        ...state,
        shopsDetails: {
          ...state.shopsDetails,
          byId: {
            ...state.shopsDetails.byId,
            [action.payload.id]: FETCHING_STATES.ERROR
          }
        }
      };
    }

    case TYPES.FILTER_SHOPS: {
      const key = action.payload.filterOn;
      const filter = state.filters[key];

      return {
        ...state,
        filters: {
          ...state.filters,
          [key]: {
            ...filter,
            value: action.payload.value
          }
        }
      };
    }

    case TYPES.RESET_FILTERS: {
      const { filters = {} } = state;

      return {
        ...state,
        filters: {
          ...Object.keys(filters).reduce(
            (resetedFilters, key) => ({
              ...resetedFilters,
              [key]: {
                ...filters[key],
                value: null
              }
            }),
            {}
          )
        }
      };
    }

    default:
      return state;
  }
}
