import React from 'react';

import { ListItem } from '../styles/components';
import { NavigationMenuLink } from '../types';

export type ExpandableMenuItemProps = {
  children: React.ReactNode;
  id?: string;
  isActive?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  link: NavigationMenuLink;
};

const ExpandableMenuItem = ({
  children,
  id,
  isActive,
  onMouseEnter,
  onMouseLeave,
  link
}: ExpandableMenuItemProps): JSX.Element => {
  return (
    <ListItem
      link={link}
      id={id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-is-active={isActive}
    >
      {children}
    </ListItem>
  );
};

ExpandableMenuItem.displayName = 'ExpandableMenuItem';

export default ExpandableMenuItem;
