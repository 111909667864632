import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { TFunction } from 'i18next';

import Button from 'shared/components/presentational/Button';
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import { FormattedProps, PropsFromState } from '../../types';

const Wrapper = styled.div`
  width: 100%;
  background-color: ${COLORS.white};
  padding: 25px 15px 15px;
`;

const Title = styled.p`
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  color: ${COLORS.black};
  margin-bottom: 20px;
`;

const NoAccount = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Text = styled.span`
  font-family: ${FONT_FAMILIES.brand};
  color: ${COLORS.cerulean};
  &::after {
    content: '';
    margin-right: 0.5em;
  }
`;

const buttonThemeFactory = (color: string) =>
  themeFactory({
    backgroundColor: color,
    color: COLORS.white
  });

const linkTheme = {
  button: {
    ...Button.themes.blueTransparent.button,
    padding: '0 0 0 0'
  }
};

export interface Props
  extends Pick<PropsFromState, 'loginUrl'>,
    Pick<FormattedProps, 'boxTitle'> {
  className?: string;
  onItemClick: () => void;
  buttonColor: string;
  signupCanalPlusTitle: string;
  signupCanalPlusLinkLabel: string;
  signupCanalPlusLink: string;
}

export default class BoxNotLogged extends PureComponent<
  Props & { t: TFunction }
> {
  public render(): React.ReactNode {
    const {
      className,
      loginUrl,
      boxTitle,
      onItemClick,
      buttonColor,
      signupCanalPlusTitle,
      signupCanalPlusLinkLabel,
      signupCanalPlusLink,
      t
    } = this.props;

    return (
      <Wrapper className={className}>
        <Title>{boxTitle}</Title>
        <Button
          theme={buttonThemeFactory(buttonColor)}
          to={loginUrl}
          onClick={onItemClick}
        >
          <Button.children.Text>
            {t('common.form.action.connexion')}
          </Button.children.Text>
        </Button>
        <NoAccount>
          <Text>{signupCanalPlusTitle}</Text>
          <Button
            theme={linkTheme}
            to={signupCanalPlusLink}
            onClick={onItemClick}
          >
            <Button.children.Text>
              {signupCanalPlusLinkLabel}
            </Button.children.Text>
          </Button>
        </NoAccount>
      </Wrapper>
    );
  }
}
