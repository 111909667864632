import { ARTICLE_SLUG_REGEXP } from '../constants';

export default (pathname: string) => {
  const regexp = new RegExp(ARTICLE_SLUG_REGEXP);
  const m = regexp.exec(pathname);

  // return null if does not match anything
  if (!m || m.length === 0) {
    return null;
  }

  // return null if url continues after the slug (e.g. articles/{slug}/details)
  if (m[2]) {
    return null;
  }

  return m[1];
};
