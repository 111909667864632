import queryString from 'query-string';

export default function createOfferValidationLink(
  url: string,
  offerCode?: string,
  minorOffers: string[] = []
): string {
  return queryString.stringifyUrl(
    { url, query: { option: offerCode, minor: minorOffers } },
    { arrayFormat: 'comma' }
  );
}
