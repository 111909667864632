import get from 'lodash/get';

import { COLORS } from 'shared/constants/theme';

export default (content, { getLinkHrefWithAuthentication }) => ({
  slides: get(content, 'sliders', []).map(slide => ({
    text: slide.text,
    comparators: [
      {
        label: get(slide, 'labelComparator1', ''),
        time: get(slide, 'timeComparator1', '')
      },
      {
        label: get(slide, 'labelComparator2', ''),
        time: get(slide, 'timeComparator2', '')
      }
    ]
  })),
  backgroundColor: get(content, 'background', COLORS.white),
  title: get(content, 'title', ''),
  buttonText: get(content, 'button.label', ''),
  buttonLink: getLinkHrefWithAuthentication(get(content, 'button.link', ''))
});
