/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import ApiError from 'shared/components/presentational/ApiError';
import Loader from './Loader';
import { DEFAULT_ERROR_MESSAGE, TEMPLATE_TYPES } from '../constants';
import DefaultTemplate from './templates/Default.template';
import ContentGrid from './templates/ContentGrid.template';
import DetailPage from './templates/DetailPage.template';
import Mosaic from './templates/Mosaic.template';
import GabaritList from './templates/GabaritList.template';
import DetailShow from './templates/DetailShow.template';
import DetailSeason from './templates/DetailSeason.template';

const ComponentByTemplate = {
  [TEMPLATE_TYPES.MOSAIC]: Mosaic,
  [TEMPLATE_TYPES.CONTENT_GRID]: ContentGrid,
  [TEMPLATE_TYPES.GABARIT_LIST]: GabaritList,
  [TEMPLATE_TYPES.DETAIL_PAGE]: DetailPage,
  [TEMPLATE_TYPES.DETAIL_SHOW]: DetailShow,
  [TEMPLATE_TYPES.DETAIL_SEASON]: DetailSeason
};

class Hodor extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    loading: PropTypes.bool,
    path: PropTypes.string,
    player: PropTypes.object,
    baseLink: PropTypes.string
  };
  render() {
    const { loading, error, data, path, player, t } = this.props;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      const text = typeof error === 'string' ? error : t(DEFAULT_ERROR_MESSAGE);
      return <ApiError text={text} id="hodor_api_error" />;
    }

    if (data) {
      const { baseLink } = this.props;
      const template = data.currentPage.displayTemplate;
      const Component = ComponentByTemplate[template] || DefaultTemplate;

      return (
        <Fragment>
          <Helmet title={data.currentPage.displayName} />
          <Component
            baseLink={baseLink}
            data={data}
            player={player}
            path={path}
          />
        </Fragment>
      );
    }

    return false;
  }
}

export default withTranslation()(Hodor);
