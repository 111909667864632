import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { TFunction } from 'i18next';

import _Link from 'shared/components/presentational/CustomLink/CustomLink';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Picture from 'shared/components/presentational/Picture';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  height: 40px;

  ${mediaQueries.toTablet`
    padding-left: 20px;
  `};
`;

const Link = styled(_Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledPicture = styled(Picture)`
  width: auto;
  max-height: 40px;

  ${mediaQueries.toPhablet`
      max-height: 20px;
  `};
`;

export interface Props {
  linkUrl: string;
  imageUrl: string;
  onClick: () => void;
  t: TFunction;
}

export default class Logo extends PureComponent<Props> {
  public render(): React.ReactNode {
    const { linkUrl, onClick, imageUrl, t } = this.props;

    return (
      <Wrapper onClick={onClick}>
        <Link to={linkUrl}>
          <StyledPicture
            alt={t('common.misc.internationalCanal')}
            url={imageUrl}
            height={40}
            disableLazy
          />
        </Link>
      </Wrapper>
    );
  }
}
