import { ACTION_TYPES as SUMMARY } from '../constants';

const defaultState = {
  pending: false,
  submit: { success: false, error: undefined, pending: false }
};

export default function summary(state = defaultState, action: any) {
  switch (action.type) {
    case SUMMARY.PENDING: {
      return {
        ...state,
        pending: true
      };
    }
    case SUMMARY.SUBMIT_PENDING: {
      return {
        ...state,
        submit: {
          success: false,
          error: undefined,
          pending: true
        }
      };
    }
    case SUMMARY.SUBMIT_SUCCESS: {
      return {
        ...state,
        submit: {
          success: true,
          error: undefined,
          pending: false
        }
      };
    }
    case SUMMARY.SUBMIT_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        submit: {
          success: false,
          error,
          pending: false
        }
      };
    }
    case SUMMARY.FLUSH: {
      return defaultState;
    }
    default:
      return state;
  }
}
