import i18next from 'i18next';
import 'server/i18n';
import { StringSchema, TestOptionsMessage } from 'yup';
import { isValidIBAN } from 'ibantools';

export default function isIban(this: StringSchema, msg: string): StringSchema {
  return this.test({
    name: 'isIban',
    message:
      msg || (i18next.t('common.form.validation.isIban') as TestOptionsMessage),
    test: value => value && isValidIBAN(value)
  });
}
