import styled from 'styled-components';

import Button from './Button';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { FONT_WEIGHTS } from 'shared/constants/theme';

const ApashButton = styled(Button)<{
  hideForMobile?: boolean;
  fluid?: boolean;
  disabled?: boolean;
}>`
  display: ${props => (props.hideForMobile ? 'none' : 'flex')};
  flex-wrap: wrap;
  height: fit-content;
  padding: 16px 50px;
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${pxInRem(18)};
  width: ${props => (props.fluid ? '100%' : 'auto')};
  text-align: center;
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  ${mediaQueries.toPhablet`
    padding: 16px 41px;
  `}
  ${mediaQueries.fromTablet`
    display: flex;
    width: auto;
  `};
`;

export default ApashButton;
