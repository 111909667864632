export enum HandleModalsOpeningType {
  OPEN_LANGUAGE_SWITCHER = 'OPEN_LANGUAGE_SWITCHER',
  CLOSE_LANGUAGE_SWITCHER = 'CLOSE_LANGUAGE_SWITCHER',
  OPEN_USER_MENU = 'OPEN_USER_MENU',
  CLOSE_USER_MENU = 'CLOSE_USER_MENU'
}

export type HandleModalsOpeningAction =
  | { type: HandleModalsOpeningType.OPEN_LANGUAGE_SWITCHER }
  | { type: HandleModalsOpeningType.CLOSE_LANGUAGE_SWITCHER }
  | { type: HandleModalsOpeningType.OPEN_USER_MENU }
  | { type: HandleModalsOpeningType.CLOSE_USER_MENU };

interface HandleModalsOpeningState {
  isLanguageSwitcherOpened: boolean;
  isUserMenuOpened: boolean;
}

export function reducer(
  state: HandleModalsOpeningState,
  action: HandleModalsOpeningAction
) {
  switch (action.type) {
    case HandleModalsOpeningType.OPEN_LANGUAGE_SWITCHER:
      return {
        isLanguageSwitcherOpened: true,
        isUserMenuOpened: false
      };

    case HandleModalsOpeningType.CLOSE_LANGUAGE_SWITCHER:
      return {
        ...state,
        isLanguageSwitcherOpened: false
      };

    case HandleModalsOpeningType.OPEN_USER_MENU:
      return {
        isLanguageSwitcherOpened: false,
        isUserMenuOpened: true
      };

    case HandleModalsOpeningType.CLOSE_USER_MENU:
      return {
        ...state,
        isUserMenuOpened: false
      };
    default:
      return state;
  }
}
