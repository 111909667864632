import React from 'react';

import Accordion from 'shared/components/presentational/Accordion';
import Icon from 'shared/components/presentational/Icons/Icon';
import { COLORS } from 'shared/constants/theme';
import Title from 'shared/components/presentational/Title';
import {
  Container,
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionHeader,
  StyledAccordionItem,
  StyledQuestion,
  StyledRaw,
  Text
} from '../styles/components';
import { QuestionList as Props } from '../types';

const QuestionList: React.FC<Props> = ({
  title,
  data,
  text,
  titleHtmlTag,
  titleColor
}) => {
  return (
    <Container>
      <Title
        titleLabel={title}
        titleHtmlTag={titleHtmlTag}
        titleColor={titleColor}
      />
      <StyledAccordion>
        {data.map((q, index) => (
          <StyledAccordionItem key={index}>
            <StyledAccordionHeader>
              <StyledQuestion>{q.question}</StyledQuestion>
              <Accordion.Handle>
                <Icon
                  name="ArrowBottom"
                  width={13}
                  height={13}
                  color={COLORS.tundora}
                />
              </Accordion.Handle>
            </StyledAccordionHeader>
            <StyledAccordionContent>
              <StyledRaw html={q.response} />
            </StyledAccordionContent>
          </StyledAccordionItem>
        ))}
      </StyledAccordion>
      <Text html={text} />
    </Container>
  );
};

export default QuestionList;
