/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import styled from 'styled-components';

import BaseModal, {
  ModalScrollWrapper,
  ModalCloseButton,
  StyledButton
} from 'shared/components/presentational/Modal/BaseModal';
import Picture from 'shared/components/presentational/Picture';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { FONT_WEIGHTS } from 'shared/constants/theme';

export const Wrapper = styled.section`
  display: var(--channel-group-wrapper-display);
  gap: var(--channel-group-wrapper-gap);
  margin: var(--channel-group-wrapper-mobile-margin);
  padding-left: var(--channel-group-wrapper-padding-mobile);
  padding-right: var(--channel-group-wrapper-padding-mobile);
  background: var(--channel-group-wrapper-background);
  min-width: 0;

  ${mediaQueries.fromTablet`
  padding-left: var(--channel-group-wrapper-padding-tablette);
  padding-right: var(--channel-group-wrapper-padding-tablette);
  `};

  ${mediaQueries.fromDesktop`
   margin-top: var(--channel-group-wrapper-desktop-margin-top);
   margin: var(--channel-group-wrapper-desktop-margin);
   max-width: var(--channel-group-wrapper-max-width);
  `}
`;

export const CustomModal = styled(BaseModal)`
  width: 90vw;
  background-color: var(--surface-background);
  height: 100vh;

  & ${ModalScrollWrapper} {
    padding: 0 var(--spacing-m) var(--spacing-m) var(--spacing-m);
    display: grid;
    gap: var(--spacing-xl);

    ${mediaQueries.fromDesktop`
      padding: var(--spacing-xl) var(--spacing-xxl) var(--spacing-xxl) var(--spacing-xxl);
    `}
  }

  & ${ModalCloseButton} {
    background-color: var(--surface-background);
    margin: 10px;
  }
  & ${StyledButton} {
    background-color: rgba(235, 235, 235, 0.15);
    color: var(--text-default-main);
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  & span {
    line-height: 0;
  }
`;

export const ModalChannelGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--spacing-l);
  justify-self: center;

  ${mediaQueries.fromDesktop`
  grid-template-columns: repeat(2, 1fr);
  `}
`;

export const ModalChannelCard = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-l);
  border: solid 1px var(--surface-information);
  padding: var(--spacing-xl) var(--spacing-l);

  ${mediaQueries.fromDesktop`
    max-width: 570px;
  `}
`;

const CustomPictureWrapper = styled.div`
  display: flex;
  align-items: center;
  width: unset;
`;

export const CardPicture = styled(Picture).attrs({
  customWrapper: CustomPictureWrapper
})`
  width: 125px;
  min-width: 125px;
`;

export const CardDescription = styled.p`
  font-size: var(--text-size-m);
  line-height: normal;
  margin: 0;
  color: var(--text-default-main);
`;

export const SeeMoreWrapper = styled.span`
  font-size: var(--text-size-m);
  text-align: center;
  color: var(--text-default-main);
  font-weight: ${FONT_WEIGHTS.demi};
  width: 100%;
`;
