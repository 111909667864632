/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';

import { Wrapper } from '../styles/components';
import Spinner from 'shared/components/presentational/Spinner/Spinner';

export default function Loader() {
  return (
    <Wrapper data-testid="loader">
      <Spinner />
    </Wrapper>
  );
}
