import { lighten } from 'polished/lib/index';

import { COLORS } from 'shared/constants/theme';

export default {
  button: {
    normal: {
      backgroundColor: COLORS.black,
      borderColor: COLORS.black,
      color: COLORS.white,
      textDecoration: 'none'
    },
    hover: {
      backgroundColor: lighten(0.2, COLORS.black),
      borderColor: lighten(0.2, COLORS.black),
      color: COLORS.white,
      textDecoration: 'none'
    },
    disabled: {
      backgroundColor: lighten(0.2, COLORS.black),
      borderColor: lighten(0.2, COLORS.black),
      color: lighten(0.2, COLORS.white),
      textDecoration: 'none'
    }
  }
};
