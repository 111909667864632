import { lighten, darken } from 'polished';
import get from 'lodash/get';

import isHexaColor from './isHexaColor';
import isDarkColor from './isDarkColor';
import { ThemeFactory } from 'shared/types';

const applyTransformer = (
  color: string,
  darkTransformer = lighten,
  lightTransformer = darken,
  transformRate = 0.1
) => {
  if (!isHexaColor(color)) return color;

  const method = isDarkColor(color) ? darkTransformer : lightTransformer;
  return method(transformRate, color);
};

interface ThemeFactoryParameters {
  backgroundColor: string;
  color: string;
  borderColor?: string;
  textDecoration?: string;
  padding?: string | number | null;
  hover?: object;
}

export default ({
  backgroundColor,
  color,
  borderColor = 'none',
  textDecoration = 'none',
  padding = null,
  hover = {}
}: ThemeFactoryParameters): ThemeFactory => ({
  button: {
    padding,
    normal: {
      backgroundColor,
      borderColor,
      color,
      textDecoration
    },
    hover: {
      backgroundColor: get(
        hover,
        'backgroundColor',
        applyTransformer(backgroundColor)
      ),
      borderColor: get(hover, 'borderColor', applyTransformer(borderColor)),
      color: get(hover, 'color', color),
      textDecoration: get(hover, 'textDecoration', textDecoration)
    },
    disabled: {
      backgroundColor: applyTransformer(backgroundColor, darken, lighten),
      borderColor: applyTransformer(borderColor, darken, lighten),
      color: applyTransformer(color),
      textDecoration
    }
  }
});
