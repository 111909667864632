import get from 'lodash/get';

export default (page: object) => {
  const loginLink = get(page, 'authentication.loginLink');

  if (!loginLink) {
    return false;
  }

  return loginLink.rel === 'external';
};
