/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*       */

import React from 'react';
import styled from 'styled-components';

import starsImage from 'shared/assets/sprites/stars.png';
import { COLORS } from 'shared/constants/theme';

const typeHandling = type => {
  switch (type) {
    case 'telerama':
      return '-45px';
    case 'sensCritique':
      return '-30px';
    case 'allocine':
      return '-15px';
    default:
      return '-15px';
  }
};

const StyledProgress = styled.progress`
  display: inline-block;
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  width: 75px;
  height: 15px;
  background-color: ${COLORS.concrete};
  background-image: url(${starsImage});
  background-repeat: repeat-x;
  background-position: 0 0;
  background-size: 15px 60px;
  color: transparent;

  &[type='telerama'] {
    width: 45px;
    background-image: none;
    &::-webkit-progress-bar {
      background-image: none;
    }
  }

  &::-ms-fill {
    border: 0;
    background-image: url(${starsImage});
    background-size: 15px 60px;
    background-position: 0 ${props => typeHandling(props.type)};
    background-color: ${COLORS.concrete};
  }

  &::-webkit-progress-bar {
    background-color: ${COLORS.concrete};
    background-image: url(${starsImage});
    background-repeat: repeat-x;
    background-position: 0 0;
    background-size: 15px 60px;
  }

  &::-webkit-progress-value {
    background-image: url(${starsImage});
    background-size: 15px 60px;
    background-position: 0 ${props => typeHandling(props.type)};
    background-color: ${COLORS.concrete};
  }

  &::-moz-progress-bar {
    background-image: url(${starsImage});
    background-size: 15px 60px;
    background-position: 0 ${props => typeHandling(props.type)};
    background-color: ${COLORS.concrete};
  }
`;

const StarRating = ({ max, value, type }) => (
  <StyledProgress max={max} value={value} type={type} />
);

export default StarRating;
