import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    fill="currentColor"
    width={width}
    height={height}
  >
    <path d="M22.66 20.05l-3.994-4.01a8.657 8.657 0 0 0 1.813-5.311c0-4.802-3.91-8.729-8.754-8.729C6.881 2 3 5.927 3 10.757c0 4.802 3.91 8.729 8.754 8.729a8.713 8.713 0 0 0 5.325-1.808l3.995 3.983c.226.226.51.339.793.339.283 0 .566-.113.793-.339.453-.452.453-1.186 0-1.61zM5.266 10.758c0-3.56 2.918-6.469 6.488-6.469 3.569 0 6.458 2.91 6.458 6.47 0 3.558-2.917 6.468-6.487 6.468-3.57 0-6.459-2.91-6.459-6.469z"></path>
  </svg>
);
