import { connect } from 'react-redux';

import { LanguageSwitcher } from '../components/LanguageSwitcher';
import getZoneLangs from 'shared/modules/page/selectors/getZoneLangs';
import getPageLang from 'shared/modules/page/selectors/getPageLang';
import { NavigationMenuLink } from '../types';
import { Langs, ThemeFactory } from 'shared/types';
import getOnlyDomain from 'shared/modules/domain/selectors/getOnlyDomain';

export interface Props {
  langs: Langs;
  pageLang: string;
  domain: string;
}

export interface OwnProps {
  languageSwitcher: {
    theme: ThemeFactory;
    link: NavigationMenuLink;
    backgroundColorModals: string;
    label: string;
  };
}

const mapStateToProps = (state: any, ownProps: OwnProps): Props & OwnProps => {
  const domain = getOnlyDomain(state);
  return {
    ...ownProps,
    langs: getZoneLangs(state),
    pageLang: getPageLang(state),
    domain: domain ?? ''
  };
};

export default connect(mapStateToProps)(LanguageSwitcher);
