import { lighten, transparentize } from 'polished/lib/index';

import { COLORS } from 'shared/constants/theme';

export default {
  button: {
    normal: {
      backgroundColor: 'transparent',
      borderColor: COLORS.black,
      color: COLORS.black,
      textDecoration: 'none'
    },
    hover: {
      backgroundColor: transparentize(0.8, COLORS.black),
      borderColor: lighten(0.2, COLORS.black),
      color: lighten(0.2, COLORS.black),
      textDecoration: 'none'
    },
    disabled: {
      backgroundColor: lighten(0.2, COLORS.black),
      borderColor: lighten(0.2, COLORS.black),
      color: COLORS.white,
      textDecoration: 'none'
    }
  }
};
