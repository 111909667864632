export default function getElementPosition(element: string): number | null {
  // find the first error-related element
  const errorElement = document.querySelector(element);

  if (errorElement !== null) {
    // get the height of the parent element
    // const offset = errorElement.parentElement!.offsetHeight || 0;
    const innerHeight = window.innerHeight;

    // get the top relative element position from the webview
    const elementViewportRelativePosition = errorElement.getBoundingClientRect()
      .top;

    // get the top relative document body position from the webview
    const documentViewportRelativePosition = document.body.getBoundingClientRect()
      .top;

    const elementAbsolutePosition =
      elementViewportRelativePosition - documentViewportRelativePosition;

    return elementAbsolutePosition - innerHeight / 2;
  }
  return null;
}
