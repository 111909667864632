import styled, { css } from 'styled-components';

import pxInRem from 'shared/helpers/styled-components/remHelper';
import { COLORS, FONT_FAMILIES } from 'shared/constants/theme';

export interface Props {
  placeholder?: string;
  value?: string | number;
  focused: boolean;
  afterPlaceholder?: string;
  error?: boolean;
}

export const BasePlaceholder = styled.label<Props>`
  :before {
    pointer-events: none;
    content: ${props =>
      props.placeholder ? `"${props.placeholder}  "` : '""'};
    font-family: ${FONT_FAMILIES.base};
    font-size: ${pxInRem(18)};
    position: absolute;
    inset: 0;
    margin: 18px 16px;
    z-index: 1;
    display: block;
    height: 20px;
    color: ${props => (props.error ? COLORS.amaranth : COLORS.boulderBis)};
    cursor: text;
  }
`;

const Placeholder = styled(BasePlaceholder)<Props>`
  :before {
    transition: margin-top 0.3s, font-size 0.3s;

    ${props =>
      props.value || props.focused
        ? css`
            font-size: ${pxInRem(12)};
            margin-top: 8px;
          `
        : css`
            margin-top: 18px;
          `};
  }
`;

export default Placeholder;
