import React, { useCallback, useMemo } from 'react';
import { ValueType } from 'react-select';
import { FieldProps } from 'formik';
import get from 'lodash/get';
import { WithTranslation, withTranslation } from 'react-i18next';

import Select from '../Select';
import {
  BaseSelectProps,
  OptionType
} from 'shared/components/presentational/Select/Select';
import FieldHint from '../FieldHint';
import translateStaticYupError, {
  Error
} from '../helpers/translateStaticYupError';
import { HintProps } from '../Hint';

type Props = FieldProps<any> & BaseSelectProps & HintProps & WithTranslation;

function SelectField({
  className,
  options,
  form,
  field,
  isSearchable,
  isRequired,
  hint,
  t,
  placeholder = t('common.form.country.label'),
  ...props
}: Props) {
  const handleSelectChange = useCallback(
    (data: ValueType<OptionType>): void => {
      form.setFieldValue(field.name, data ? (data as OptionType).value : null);
      form.setFieldTouched(field.name);
    },
    [field.name]
  );

  const [error, errorI18n] = useMemo(() => {
    const error = get(form.touched, field.name) && get(form.errors, field.name);
    const errorI18n = translateStaticYupError(error as Error, t);

    return [error, errorI18n];
  }, [form.touched, field.name, form.errors]);

  return (
    <>
      <Select
        className={className}
        id={field.name}
        name={field.name}
        value={form.values[field.name]}
        error={error}
        onChange={handleSelectChange}
        onBlur={form.handleBlur}
        options={options}
        isSearchable={isSearchable}
        {...props}
        placeholder={placeholder}
      />
      <FieldHint error={errorI18n} hint={hint} isRequired={isRequired} />
    </>
  );
}

export default withTranslation()(SelectField);
