import React from 'react';

import { FormatedLabeledLink } from '../types';

export const useActiveIndex = (
  selectedNavigationMenu: FormatedLabeledLink[],
  pagePath: string
) => {
  const [activeIndex, setActiveIndex] = React.useState(-1);

  React.useEffect(() => {
    selectedNavigationMenu
      .map(({ link }) => link)
      .forEach((item, index) => {
        if (pagePath.includes(item)) {
          setActiveIndex(index);
        }
      });
  }, [selectedNavigationMenu, pagePath]);
  return activeIndex;
};
