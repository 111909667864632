import orderBy from 'lodash/orderBy';

import {
  SizesByDevice,
  Size
} from 'shared/components/presentational/Picture/types';
import getThumborUrl from 'shared/components/presentational/Picture/helpers/getThumborUrl';

const PIXEL_RATIO = 2;

export default function provideResponsivesImages(
  responsivesImages: SizesByDevice,
  thumborFilter?: number
): { url: string; size: string }[] {
  const sources = orderBy(responsivesImages, 'w');

  const mapSources = sources.map((source, index) => {
    const sourceSize = source as Size;
    const isSVGFile = sourceSize.url?.endsWith('.svg');
    const url = getThumborUrl({
      url: sourceSize.url,
      width: sourceSize.w,
      thumborFilter
    });
    const urlRetina = getThumborUrl({
      url: sourceSize.url,
      width: (sourceSize.w ?? 0) * PIXEL_RATIO,
      thumborFilter
    });
    return {
      url: isSVGFile ? `${sourceSize.url}` : `${url}, ${urlRetina} 2x`,
      size:
        index === sources.length - 1
          ? `(min-width: ${sourceSize.w}px )`
          : `(max-width: ${sourceSize.w}px )`
    };
  });
  return mapSources;
}
