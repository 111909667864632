import styled from 'styled-components';

import { mediaQueries } from 'shared/helpers/styled-components/mediaQueries';
import { FormButton, Container } from 'shared/components/presentational/Form';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { FONT_FAMILIES, COLORS, FONT_WEIGHTS } from 'shared/constants/theme';

/*
 * Main wrapper should be a section
 * in order to inherit margins
 *
 *
 */

export const HrSeparator = styled.hr`
  height: 1px;
  border: none;
  background-color: ${COLORS.gray};
  width: 100%;
`;

export const Wrapper = styled.section`
  max-width: 1000px;
  margin: 55px auto;
  padding: 0 20px;

  ${mediaQueries.fromDesktop`
    padding: 0;
  `};
`;

export const Title = styled.h1`
  margin: ${pxInRem(22)} 0 ${pxInRem(19)};
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  font-size: ${pxInRem(24)};
  text-align: center;
  color: ${COLORS.black};

  ${mediaQueries.fromTablet`
    font-size: ${pxInRem(36)};
  `}
`;

export const Subtitle = styled.span`
  display: block;
  margin-bottom: ${pxInRem(26)};
  font-family: ${FONT_FAMILIES.brand};
  font-size: ${pxInRem(18)};
  text-align: center;
  color: ${COLORS.black};

  ${mediaQueries.fromPhablet`
    margin-bottom: ${pxInRem(34)};
  `}
`;

export const RecaptchaWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const CTA = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 40px;
`;

export const CTAButton = styled(FormButton)`
  margin: 0 10px;
`;

export const Form = styled.form`
  display: grid;
  gap: 24px 20px;

  ${mediaQueries.fromPhablet`
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `};
`;

export const FieldWrapper = styled.fieldset<{ dimension: 50 | 100 }>`
  ${mediaQueries.fromPhablet`
    grid-column: ${(props: { dimension: 50 | 100 }) =>
      props.dimension === 50 ? 'span 1 / span 1' : 'span 2 / span 2'};
  `};
`;

export const MandatoryFieldsMessage = styled.div`
  font-family: ${FONT_FAMILIES.base};
  font-size: ${pxInRem(16)};
  color: ${COLORS.boulder};
  margin-bottom: ${pxInRem(16)};
`;

export const RecaptchaMessage = styled.div`
  font-family: ${FONT_FAMILIES.base};
  font-size: ${pxInRem(16)};
  color: ${COLORS.boulder};
  margin-top: ${pxInRem(16)};
  a {
    text-decoration: underline;
  }
`;

export const FormContainer = styled(Container)`
  box-shadow: 0 5px 8px 1px ${COLORS.concrete};
  border: 1px solid ${COLORS.concrete};
  border-radius: 10px;
`;
