/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { createSelector } from 'reselect';

import { getPrograms } from './';
import {
  getLocalizedTimestampForTonight,
  getLocalizedTimestamp
} from '../helpers';

/**
 * getProgramsForTonight
 *
 * @param <Array> programs - an array of ProgramModel
 * @param <String> timezone - the current timezone
 * @returns Object - eg: {first: null, second: null}
 */
const getProgramsForTonight = (programs, timezone) => {
  if (programs?.length) {
    const referenceTimestamp = programs[0].endTime;
    const tonightTimestamp = getLocalizedTimestampForTonight(
      referenceTimestamp,
      timezone
    );

    // Remove all programs ended before tonightTime
    const filteredPrograms = programs.filter(
      program =>
        getLocalizedTimestamp(program.endTime, timezone) > tonightTimestamp
    );

    const first = filteredPrograms.length ? filteredPrograms[0] : null;
    const second = filteredPrograms.length > 1 ? filteredPrograms[1] : first;

    return { first, second };
  }
  return { first: {}, second: {} };
};

/**
 * Method return a selector that returns an object with channel zapNumber as key
 * and an object (with two optional programs) as value
 *
 * return <Object>
 * eg :
 * {
 *   <zapNumber> : {
 *     first: <?ProgramModel>,
 *     second: <?ProgramModel>
 *   },
 *   <zapNumber> : {
 *     first: <?ProgramModel>,
 *     second: <?ProgramModel>
 *   }....
 * }
 */
const TODAY_DAY_INDEX = 0;
export default timezone =>
  createSelector([getPrograms(TODAY_DAY_INDEX)], allProgramsByZapNumber =>
    Object.keys(allProgramsByZapNumber).reduce((_acc, zapNumber) => {
      const acc = _acc;

      const programs = allProgramsByZapNumber[zapNumber];

      const res = getProgramsForTonight(programs, timezone);

      acc[zapNumber] = {
        first: res.first,
        second: res.second
      };

      return acc;
    }, {})
  );
