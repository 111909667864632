import { Content, FormattedProps } from './types';

export default (content: Content): FormattedProps => ({
  title: content.title,
  subtitle: content.subtitle,
  selectLabel: content.selectLabel,
  displayMode: content.displayMode,
  buttonLabel: content.buttonLabel,
  allZones: content.zones
});
