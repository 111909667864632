export const ACTION_TYPES = {
  FETCH_REQUEST: '@PAGE/FETCH_REQUEST',
  FETCH_SUCCESS: '@PAGE/FETCH_SUCCESS',
  SCROLL_TO: '@PAGE/SCROLL_TO',
  SCROLL_FAILED: '@PAGE/SCROLL_FAILED'
};

export const PAGE_TYPE = {
  LOGIN: 'authentication',
  LOGOUT: 'logout',
  SILENT_LOGIN: 'silent_authentication',
  ARTICLE: 'article'
};

export const HOMEPAGE = '/';

export const MOBILE_DEVICES = [
  'mobile-android',
  'mobile-ios',
  'phoneMini',
  'phone'
];

/**
 * The webapp device has been create in order to create new behavior for pages used on mobile, but not in webview.
 * This kind of pages will be accessed only from the mobile app (by quitting the app and going to the mobile browser).
 * We do not feature a way to close the webview because we are on an actual browser, not a webview.
 */
export const WEBAPP_DEVICES = ['webapp'];
