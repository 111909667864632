import i18next from 'i18next';
import 'server/i18n';

export default function isPhoneNumber(caledoniaMode, msg) {
  const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
  const caledoniaPhoneRegexp = /^\+?\d{6}$/;

  return this.test({
    name: 'phone',
    message: msg || i18next.t('common.form.validation.isPhoneNumber'),
    test: value =>
      !value || value.match(caledoniaMode ? caledoniaPhoneRegexp : phoneRegExp)
  });
}
