/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';

import LayoutHeroBanner from './LayoutHeroBanner';
import {
  LayoutFormattedProps,
  AnimationFormattedProps,
  BannerTemplate
} from '../types';
import AnimatedHeroBanner from './AnimatedHeroBanner';

type Props = LayoutFormattedProps | AnimationFormattedProps;

function isAnimationBannerContent(
  content: Props
): content is AnimationFormattedProps {
  const bannerTemplate = content.bannerTemplate;

  return (
    bannerTemplate === BannerTemplate.AnimationBig ||
    bannerTemplate === BannerTemplate.AnimationSmall
  );
}

const HeroBanner = (props: Props) => {
  if (isAnimationBannerContent(props)) {
    return <AnimatedHeroBanner {...props} />;
  }

  return <LayoutHeroBanner {...props} />;
};

export default HeroBanner;
