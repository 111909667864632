import React, { memo } from 'react';

import {
  LabelStyled,
  LabelWrapper,
  LinkStyled,
  ListItemStyled,
  PictureStyled,
  PisctureWrapperStyled
} from './DropdownMenuItemStyled';
import { Media, ThemeFactory } from 'shared/types';

type Props = {
  data: {
    label: string;
    link: string;
    imageUrl: string;
    description: string | null;
    color: string | undefined;
    icon: Media;
  };
  theme: {
    link: ThemeFactory['link'];
    backgroundColorModals: string;
    colorClosingModals: string;
  };
  onClick?(): void;
};
export const DropdownMenuItem = ({ theme, data, onClick }: Props) => {
  const { link, label, icon, color } = data;

  return (
    <ListItemStyled
      key={label}
      backgroundColorModals={theme.backgroundColorModals}
    >
      <LinkStyled theme={theme} to={link} onClick={onClick}>
        <PisctureWrapperStyled>
          <PictureStyled
            width={26}
            height={26}
            url={icon?.link?.href}
            alt={icon?.description || ''}
          />
        </PisctureWrapperStyled>
        <LabelWrapper>
          <LabelStyled theme={theme.link} color={color || ''}>
            {label}
          </LabelStyled>
        </LabelWrapper>
      </LinkStyled>
    </ListItemStyled>
  );
};

export default memo(DropdownMenuItem);
