import {
  Content,
  FormattedProps
} from 'shared/blocks/selfcare/parent-code/reset/types';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { COLORS } from 'shared/constants/theme';

export default (content: Content): FormattedProps => ({
  isAuthenticationLevelTwoAllow: content.authenticationLevelTwo || false,
  confirmationTitle: {
    title: content.title?.label ?? '',
    titleHtmlTag: content.title?.htmlTag,
    titleColor: content.title?.color
  },
  confirmationText: content.confirmationText ?? '',
  submitButton: {
    label: content.buttons?.submit?.label ?? '',
    theme: buttonThemeFactory({
      backgroundColor:
        content.buttons?.submit?.backgroundColor || COLORS.carnation,
      color: content.buttons?.submit?.labelColor ?? COLORS.white
    })
  },
  logoutButton: {
    label: content.logoutLabel ?? '',
    theme: buttonThemeFactory({
      backgroundColor: 'transparent',
      color: content.logoutLabelColor || COLORS.white
    })
  },
  apis: {
    resetParentCode: {
      url: content.apis?.reset_parent_code?.url ?? '',
      method: content.apis?.reset_parent_code?.method || 'POST'
    },
    authenticationLevelTwo: {
      url: content.apis?.['old_selfcare.authentication_level_two']?.url ?? '',
      method:
        content.apis?.['old_selfcare.authentication_level_two']?.method ||
        'POST'
    },
    sendParentCodeEmail: {
      url: content.apis?.send_parent_code_email?.url ?? '',
      method: content.apis?.send_parent_code_email?.method || 'POST'
    },
    getSubscriberData: {
      url: content.apis?.getSubscriberData?.url ?? '',
      method: content.apis?.getSubscriberData?.method ?? 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    }
  },
  contextCriteria: content.frontDisplayCriteria ?? null
});
