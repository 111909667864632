import React from 'react';

import { FormattedJaquette } from '../../types';
import { Label, Link, SeeMoreCard } from './styles/components';
import Picture from 'shared/components/presentational/Picture';
import {
  CardPicture,
  CardLinkPicture
} from 'shared/blocks/one-shop/jaquettes/styles/components';

export const Card = ({ link, media, type }: FormattedJaquette) => {
  if (!link) {
    return <CardPicture url={media.href} alt={media.description} />;
  }
  return (
    <Link to={link}>
      {type === 'link' ? (
        <SeeMoreCard>
          <Picture url={media.href} alt={media.description} />
          <Label>{media.description}</Label>
        </SeeMoreCard>
      ) : (
        <CardLinkPicture url={media.href} alt={media.description} />
      )}
    </Link>
  );
};
