import { css } from 'styled-components';

import { FONT_FAMILIES, FONT_WEIGHTS } from '../../constants/theme';

export default css`
  // Flex support for react-slick
  .article-list {
    .article-list {
      &__grid {
        &--carousel {
          margin: 0;
          width: 100%;
          max-width: 100vw;

          * {
            min-height: 0;
            min-width: 0;
          }
        }
      }
    }
  }

  // Slick-Carousel's Slider Component Style
  .Slider {
    &__Item {
      margin: 1rem;
      display: block;

      &.overlay {
        .Slider__Item__Image {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          &::before {
            content: '';
            background: rgba(0, 0, 0, 0.7);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }

          &::after {
            content: 'Lecture en cours';
            display: block;
            font-weight: ${FONT_WEIGHTS.bold};
            color: #fff;
            z-index: 10;
          }
        }
      }

      &__Image {
        background-position: center;
        background-size: cover;
        height: 23rem;
      }

      &__Title {
        font-family: ${FONT_FAMILIES.brand};
        font-size: 1.8rem;
        font-weight: ${FONT_WEIGHTS.demi};
        text-align: left;
        padding: 0;
        margin-top: 1rem;
      }
    }

    // Slider's Arrow Common Style
    .slick-arrow {
      background: #fbda42;
      width: 6rem;
      height: 10rem;
      // image height + margins / 2
      top: calc((23rem + 2rem) / 2);
      transition: background-color 250ms;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        background: #282828;
        width: 2.5rem;
        height: 0.5rem;
        opacity: 1;
        transition: background-color 250ms;
      }

      &:hover {
        background: #282828;

        &:before,
        &:after {
          background: #fbda42;
        }
      }

      //Slider's Disabled Arrow Style
      &.slick-disabled {
        opacity: 0.3;

        &:before {
          opacity: 1;
        }
      }
    }

    // Slider's '<' Arrow Previous Style
    .slick-prev {
      left: 0;
      z-index: 10;

      &:before {
        right: calc(50% - 1.5rem);
        top: calc(50% - 0.3rem);
        transform: rotate(45deg);
        transform-origin: 0 0;
      }

      &:after {
        right: calc(50% - 1.5rem);
        bottom: calc(50% - 0.3rem);
        transform: rotate(-45deg);
        transform-origin: 0 100%;
      }
    }

    // Slider's '>' Arrow Next Style
    .slick-next {
      right: 0;

      &:before {
        left: calc(50% - 1.5rem);
        top: calc(50% - 0.2rem);
        transform: rotate(45deg);
        transform-origin: 100% 100%;
      }

      &:after {
        left: calc(50% - 1.5rem);
        bottom: calc(50% - 0.2rem);
        transform: rotate(-45deg);
        transform-origin: 100% 0;
      }
    }
  }
`;
