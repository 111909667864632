import { Link, PropsFormatterUtils } from 'shared/types';

export default function getBasketUrl(
  basketLink: Link | 'block_link' | null,
  basketFallbackUrl: string,
  getLinkHrefWithAuthentication: PropsFormatterUtils['getLinkHrefWithAuthentication']
) {
  // We fallback basket url on `links.basket` if `event.basket.link` is "block_link"
  // @See https://jira.toolbox.canal-overseas.com/browse/AWA-1250
  if (basketLink) {
    if (basketLink === 'block_link') {
      return basketFallbackUrl;
    }

    return getLinkHrefWithAuthentication(basketLink);
  }

  return null;
}
