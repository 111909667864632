import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 48 48" width={width} height={height} className={className}>
    <path
      d="M8.5 29.6l.6-.6L28.9 9.2l.6-.6-7.3-7.3c-.3-.3-.3-.8 0-1.1.2-.2.4-.2.6-.2h24.5c.2 0 .4.1.5.2.1.1.2.3.2.5v24.4c0 .4-.3.8-.8.8-.2 0-.4-.1-.5-.2l-7.3-7.3-.6.6-19.7 19.8-.6.6 7.3 7.3c.3.3.3.8 0 1.1-.3.2-.5.2-.7.2H9 .7c-.2 0-.4-.1-.5-.2-.1-.1-.2-.3-.2-.5v-8.2V22.9c0-.4.3-.8.8-.8.2 0 .4.1.5.2l7.2 7.3z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
