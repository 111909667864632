import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;

export const Chip = styled.p`
  color: var(--text-default-main);
  width: fit-content;
  margin: 0;
  font-size: var(--text-size-m);
  line-height: var(--line-height-h3);
  padding: 10px var(--spacing-m);
  border-radius: var(--border-radius-xxxl);
  background-color: var(--surface-default);
`;
