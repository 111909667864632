import get from 'lodash/get';
import dayjs from 'dayjs';

import { PROGRAM_TIME_FORMAT } from '../constants';

export default (programData, timezone, createLinkUrl) => {
  const getTime = time => {
    if (!time) {
      return '';
    }

    if (timezone) {
      return dayjs.unix(time).tz(timezone).format(PROGRAM_TIME_FORMAT);
    }

    return dayjs.unix(time).format(PROGRAM_TIME_FORMAT);
  };

  return programData.map(program => ({
    id: get(program, 'programID', ''),
    imageUrl: get(program, 'URLImage', ''),
    title: get(program, 'title', ''),
    subtitle: get(program, 'subtitle', ''),
    channel: get(program, 'thirdTitle', ''),
    time: getTime(program.startTime),
    linkUrl: createLinkUrl({
      type: 'event',
      id: get(program, 'diffusionID', '')
    })
  }));
};
