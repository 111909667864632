export const TIMEOUT = {
  NONE: 0,
  DEFAULT: 5000,
  MEDIUM: 15000,
  LONG: 30000
};

export const HEADERS = {
  // Custom header aiming to inform APIs which authentication is used on the website.
  AUTHENTICATION: 'X-AUTHENTICATION',
  TOKEN_PASS: 'X-PASS-TOKEN',
  NUM_SUBSCRIBER: 'X-NUM-SUBSCRIBER',
  OFFER_ZONE: 'X-ZONE',
  SUBSCRIBER_ID: 'X-SUBSCRIBER-ID',
  ISO_COUNTRY_CODE: 'X-ISO-COUNTRY-CODE'
};

export enum AUTHENTICATION_HEADER_VALUES {
  internal = 'internal',
  external = 'external'
}
