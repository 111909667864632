import { CurrencyDisplay } from 'shared/types';

export const currenciesFormatter = (
  locale: string,
  currency?: string | null,
  option?: {
    style?: 'currency' | 'decimal' | 'percent';
    currencyDisplay?: CurrencyDisplay;
  }
) => (price?: number) => {
  const { style = 'currency', currencyDisplay = 'symbol' } = option || {};

  if (!price || !currency) {
    return {
      fullPrice: undefined,
      integerPart: undefined,
      currencyPart: undefined,
      decimalPart: undefined
    };
  }

  let fullPrice = '',
    integerPart = null,
    currencyPart = '',
    decimalPart = '';

  try {
    const formattedNumber = new Intl.NumberFormat(locale, {
      style,
      currency,
      currencyDisplay
    });

    fullPrice = formattedNumber.format(price);

    integerPart = formattedNumber
      .formatToParts(price)
      .map(({ type, value }) => {
        switch (type) {
          case 'fraction':
            decimalPart += value;
            break;
          case 'currency':
            currencyPart += value;
            break;
          case 'decimal':
          case 'literal':
            null;
            return;

          default:
            return value;
        }
      })
      .reduce((string, part) => `${string ? string : ''}${part ? part : ''}`);
  } catch {
    const priceString = price.toString();
    integerPart = parseInt(priceString).toString();
    decimalPart = priceString.split('.')?.[1] ?? '';

    if (decimalPart.length === 0) {
      decimalPart += '00';
    }
    if (decimalPart.length === 1) {
      decimalPart += '0';
    }

    fullPrice = `${integerPart},${decimalPart}`;
    if (currency) {
      fullPrice += ` ${currency}`;
      currencyPart = currency;
    }
  }

  return {
    fullPrice,
    integerPart,
    currencyPart,
    decimalPart
  };
};
