import { FormattedOffer } from '../types';

export default (offers: FormattedOffer[]) =>
  offers.reduce((initialOptionID, offer) => {
    if (initialOptionID === 24) {
      return 24;
    }

    const has24Option = offer.options.find(option => option.id === 24);
    if (has24Option) {
      return 24;
    }

    const maxOptionIDForCurrentOffer = offer.options.reduce(
      (maxOptionID, option) =>
        option.id > maxOptionID ? option.id : maxOptionID,
      0
    );

    if (maxOptionIDForCurrentOffer > initialOptionID) {
      return maxOptionIDForCurrentOffer;
    }

    return initialOptionID;
  }, 0);
