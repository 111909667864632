import clientCookies from 'client/cookies';
import clearCookies from 'shared/helpers/universal-cookie/clearCookies';
import { AUTH_COOKIES_LIST } from 'shared/constants/auth';

export default function clearUserDataAndCookies(flushSelfcare: () => void) {
  // Clear all Authentication related cookies
  clearCookies(clientCookies, AUTH_COOKIES_LIST);
  // Flush all reducers of selfcare
  flushSelfcare();
}
