import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 74 61" width={width} height={height} className={className}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M.3 53.3c0 .1 0 .1-.1.2 1.3.7 2.6 1.4 4 2.1 3.2 1.7 6.7 2.8 10.2 3.6 3.5.8 7.1 1 10.7.9 5.1-.2 10.1-1.2 14.8-3.1 5-2 9.5-5 13.3-8.8 2.8-2.8 5.2-6 7.2-9.5 1.8-3.2 3.3-6.6 4.3-10.2 1.2-4.2 1.7-8.4 1.6-12.8 0-.5.1-.8.5-1.1.8-.6 1.6-1.2 2.3-1.9 1.7-1.6 3.3-3.3 4.7-5.4-2.7 1.1-5.4 2-8.3 2.2 2.9-2 5.1-4.6 6.2-8.2-.4.2-.7.3-.9.4-2.5 1.4-5.2 2.4-8.1 3-.5.1-.7 0-1-.3-2.1-2.1-4.6-3.5-7.5-4-5-.9-9.5.2-13.3 3.7-3.4 3.2-5.1 7.2-4.8 11.9 0 .9.2 1.7.3 2.7-12.7-.9-23-6.2-31.3-15.9-3.6 8-2.2 14.7 4.5 20.3-1.1-.1-2.3-.3-3.3-.6-1.1-.3-2.1-.8-3.2-1.2-.2 2 .5 5 1.9 7.4 2.2 3.9 5.6 6.3 9.9 7.5-1.1.2-2.1.4-3.2.4-1.1.1-2.2 0-3.3 0 1 2.9 2.8 5.4 5.3 7.3 2.5 1.9 5.4 2.9 8.6 3.1-6.6 5-13.9 7-22 6.3z" />
      <path d="M.3 53.3C8.4 54 15.7 52 22.2 47c-3.2-.2-6-1.2-8.6-3.1-2.5-1.9-4.3-4.3-5.3-7.3h3.3c1.1-.1 2.1-.2 3.2-.4-4.3-1.2-7.7-3.6-9.9-7.5-1.3-2.4-2.1-5.4-1.9-7.4 1.1.4 2.1.9 3.2 1.2 1.1.3 2.2.4 3.3.6C2.9 17.5 1.4 10.8 5 2.8c8.3 9.7 18.6 15 31.3 15.9-.1-1-.2-1.8-.3-2.7-.2-4.7 1.4-8.7 4.8-11.9C44.6.6 49.1-.6 54.1.4c2.9.5 5.4 1.9 7.5 4 .3.3.6.4 1 .3 2.8-.7 5.5-1.6 8.1-3 .3-.1.5-.3.9-.4-1.2 3.5-3.3 6.1-6.2 8.2 2.8-.2 5.5-1.1 8.3-2.2-1.4 2.1-2.9 3.9-4.7 5.4-.7.7-1.5 1.3-2.3 1.9-.4.3-.6.6-.5 1.1.2 4.3-.4 8.6-1.6 12.8-1 3.6-2.4 7-4.3 10.2-2 3.5-4.4 6.7-7.2 9.5C49.2 52 44.8 55 39.8 57c-4.7 1.9-9.7 2.9-14.8 3.1-3.6.1-7.2-.1-10.7-.9-3.5-.8-7-1.9-10.2-3.6-1.3-.7-2.7-1.4-4-2.1l.2-.2z" />
    </g>
  </svg>
);
