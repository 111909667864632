import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { COLORS } from 'shared/constants/theme';

export default styled.div`
  border: 1px solid ${COLORS.alto};
  padding: 32px 20px;

  ${mediaQueries.fromDesktop`
    padding: 40px;
  `}
`;
