import get from 'lodash/get';

const featureDecoder = (features = []) =>
  features.map(feature => ({
    title: feature.title,
    text: feature.text,
    exponent: feature.exponent,
    pictograms: get(feature, 'pictograms', []).map(pictogram => ({
      imageUrl: pictogram.link.href
    }))
  }));

const groupsDecoder = (groups = []) =>
  groups.map(group => ({
    title: group.title,
    text: group.text,
    exponent: group.exponent,
    channels: get(group, 'channels', []).map(channel => ({
      title: get(channel, 'channel.title', ''),
      imageUrl: get(channel, 'channel.media.link.href', ''),
      exponent: channel.exponent
    }))
  }));

export default content => ({
  title: content.title,
  subtitle: content.subtitle,
  decoders: get(content, 'decoders', []).map(decoder => ({
    text: decoder.text,
    imageUrl: decoder.media && decoder.media.link.href,
    features: featureDecoder(decoder.features),
    groups: groupsDecoder(decoder.groups)
  }))
});
