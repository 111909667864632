type AnyCallback<A extends any[], R> = (...args: A) => R;

type ThrottleOptions = {
  leading?: boolean;
  trailing?: boolean;
};

/**
 * Function Helper
 * --------------------
 *
 * Create a throttled function that only invokes `callback` at most once per every `timeFrame` milliseconds
 *
 * @param callback The **function** to throttle
 * @param timeFrame The **number** of milliseconds to throttle invocations to
 * @param options.leading Specify invoking on the leading edge of the timeout. `true` by default
 * @param options.trailing Specify invoking on the trailing edge of the timeout. `true` by default
 *
 * @category Function
 */
export const throttle = <A extends any[], R>(
  callback: AnyCallback<A, R>,
  timeFrame: number,
  options: ThrottleOptions = {}
): AnyCallback<A, R> => {
  const { leading = true, trailing = true } = options;

  let isInitialCallPerformed = false;
  let laterArgs: A;
  let result: R;
  let timeout: number | null = null;
  let previous = 0;

  const resetTimeout = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };
  return (...args: A): R => {
    const later = () => {
      resetTimeout();
      previous = !leading ? 0 : Date.now();

      result = callback(...laterArgs);
    };

    if (leading && !isInitialCallPerformed) {
      isInitialCallPerformed = true;

      return callback(...args);
    }

    const now = Date.now();

    if (!previous) {
      previous = now;
    }

    const remaining = timeFrame - (now - previous);

    laterArgs = args;

    if (remaining <= 0 || remaining > timeFrame) {
      resetTimeout();
      previous = now;

      result = callback(...args);
    } else if (!timeout && trailing) {
      // @ts-ignore
      timeout = setTimeout(later, remaining);
    }

    return result;
  };
};
