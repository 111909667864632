import queryString from 'querystring';
import merge from 'lodash/merge';

export default function appendParamsToUrl(
  href: string,
  newParams?: { [key: string]: string }
) {
  const [hostname, queryParams] = href.split('?');
  const parsedQueryParams = queryString.parse(queryParams);

  const mergeQueryParams = merge(parsedQueryParams, newParams);

  if (newParams) {
    return hostname + '?' + queryString.stringify(mergeQueryParams);
  }
  return href;
}
