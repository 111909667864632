import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 70 70" width={width} height={height} className={className}>
    <path
      d="M66.5 0h-63C1.6 0 0 1.6 0 3.5v15.3h70V3.5C70 1.6 68.4 0 66.5 0zM19.2 13.3c-2.3 0-4.2-1.9-4.2-4.2 0-2.3 1.9-4.2 4.2-4.2 2.3 0 4.2 1.9 4.2 4.2 0 2.3-1.8 4.2-4.2 4.2zm31.6 0c-2.3 0-4.2-1.9-4.2-4.2 0-2.3 1.9-4.2 4.2-4.2 2.3 0 4.2 1.9 4.2 4.2 0 2.3-1.9 4.2-4.2 4.2zM0 66.5C0 68.4 1.6 70 3.5 70h63c1.9 0 3.5-1.6 3.5-3.5V23.1H0v43.4zm21-23.8h10.5V32.2h7v10.5H49v7H38.5v10.5h-7V49.7H21v-7z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
