interface ExternalLoginPayload {
  passUid: string;
  microEligibility: string;
  macroEligibility: string;
  collectUserData: string;
  passId: string;
  cgaNumber: string;
  accountId: string;
  expires_in: string;
  passToken: string;
  appLocation: string;
  userLocation: string;
  offerZone: string;
}

export default (body: Partial<ExternalLoginPayload>) => {
  const {
    passToken,
    passId,
    cgaNumber,
    appLocation,
    macroEligibility,
    microEligibility,
    collectUserData
  } = body;
  return {
    AuthOTTTokenV2: passToken,
    AuthIdToken: passId,
    subscriberId: cgaNumber,
    collectUserData,
    // numSubscriber: crmNumber,
    // pdsNormal: epgId,
    // pdsOTT: 'tmp', // epgId_ott,
    // vector: vectCode,
    isoCountryCode: appLocation,
    // offerZone
    // offerLocation,
    macroEligibility,
    microEligibility
    // isAuthenticated
  };
};
