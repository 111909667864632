import React from 'react';

import Title from 'shared/components/presentational/Title';

export const Titles = ({
  title,
  offerLabel,
  description
}: {
  title: string;
  offerLabel: string;
  description: string;
}) => (
  <section>
    <Title
      titleLabel={`${title}${offerLabel ? `\n ${offerLabel}` : ''}`}
      titleHtmlTag="h1"
      subtitle={description}
    />
  </section>
);
