/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*       */
/* eslint-disable react/jsx-curly-brace-presence */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import pxInRem from 'shared/helpers/styled-components/remHelper';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const Title = styled.h1`
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  font-size: ${pxInRem(26)};
  margin: 0;
`;

const Message = styled.div`
  background: #fff;
  padding: 2rem;
  text-align: center;
`;

const Details = styled.div`
  margin-bottom: 2rem;
`;

const Link = styled.a``;

const Error = ({ hasError, message, t }) => (
  <div>
    {hasError && (
      <Wrapper>
        <Message>
          <Title>{t('common.global.error')}</Title>
          {message && <Details>{message}</Details>}
          <Link href="/">{t('common.form.action.backToHome')}</Link>
        </Message>
      </Wrapper>
    )}
  </div>
);

Error.propTypes = {
  hasError: PropTypes.bool.isRequired,
  message: PropTypes.string
};

export default withTranslation()(Error);
