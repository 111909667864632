/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import { getPrograms, getCurrentTimestamp } from './';

/**
 * getProgramsForNow
 *
 * @param <Array> programs - an array of ProgramModel
 * @param <number> currentTimestamp - the current timestamp
 *  return Object
 *  eg: { first: <?ProgramModel>, second: <?ProgramModel> }
 */
const getProgramsForNow = (programs, currentTime) =>
  programs.reduce(
    (acc, currentProgram, index) => {
      if (acc.current && acc.next) return acc;

      const startTime = dayjs.unix(currentProgram.startTime);
      const endTime = dayjs.unix(currentProgram.endTime);
      const now = dayjs.unix(currentTime);

      const isCurrent = () => now.isBetween(startTime, endTime);

      if (isCurrent()) {
        const nextProgramIndex = index + 1;
        const nextProgram = programs[nextProgramIndex];

        return {
          first: currentProgram,
          second: currentProgram && nextProgram
        };
      }

      return acc;
    },
    {
      first: null,
      second: programs.length ? programs[0] : null
    }
  );

/**
 * This selector returns an object with channel zapNumber as key
 * and an object (with two optional programs) as value
 *
 * return <Object>
 * eg :
 * {
 *   <zapNumber> : {
 *     first: <?ProgramModel>,
 *     second: <?ProgramModel>
 *   },
 *   <zapNumber> : {
 *     first: <?ProgramModel>,
 *     second: <?ProgramModel>
 *   }....
 * }
 */
const TODAY_DAY_INDEX = 0;
export default createSelector(
  [getPrograms(TODAY_DAY_INDEX), getCurrentTimestamp],
  (allProgramsByZapNumber, currentTime) =>
    Object.keys(allProgramsByZapNumber).reduce((_acc, zapNumber) => {
      const acc = _acc;

      const programs = allProgramsByZapNumber[zapNumber];
      const res = getProgramsForNow(programs, currentTime);

      acc[zapNumber] = {
        first: res.first,
        second: res.second
      };

      return acc;
    }, {})
);
