/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Button from 'shared/components/presentational/Button/Button';
import {
  Container,
  Label,
  Radios,
  LabelHint
} from 'shared/components/presentational/Form';
import isExternalURI from 'shared/helpers/uri/isExternalURI';

/*
 * Main wrapper should be a section
 * in order to inherit margins
 */
const Wrapper = styled.section`
  max-width: 1000px;
  margin: 0 15px;

  ${mediaQueries.fromDesktop`
    margin: 0 auto;
  `}
`;

const CTA = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
`;

class Referral extends PureComponent {
  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      value: props.options[0].value
    };
  }

  handleChange = data => {
    this.setState({ value: data.target.value });
  };

  handleSubmit = () => {
    const { value } = this.state;
    const { pushURLHandler } = this.props;

    if (value !== null) {
      if (isExternalURI(value)) {
        window.location.replace(value);
      } else {
        pushURLHandler(value);
      }
    } else {
      this.setState({ submitted: true });
    }
  };

  render() {
    const { handleChange, handleSubmit } = this;
    const { label, options, buttonLabel, t } = this.props;

    const { submitted, value } = this.state;

    return (
      <Wrapper>
        <Container>
          <Label name="referral" label={label}>
            <LabelHint error={submitted && t('common.select.chooseAnswer')} />
          </Label>
          <Radios
            name="referral"
            options={options}
            value={value}
            onChange={handleChange}
            horizontal
          />
          <CTA>
            <Button onClick={handleSubmit} theme={Button.themes.blackPlain}>
              <Button.children.Text>{buttonLabel}</Button.children.Text>
            </Button>
          </CTA>
        </Container>
      </Wrapper>
    );
  }
}

export default Referral;
