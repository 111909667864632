import React from 'react';

import Title from 'shared/components/presentational/Title/components/Title';
import { Channel } from 'shared/components/presentational/ChannelsGroup/types';
import {
  CardDescription,
  CardPicture,
  CustomModal,
  ModalChannelCard,
  ModalChannelGrid
} from '../styles/components';

interface Props {
  title?: string;
  channels: Channel[];
  toggleModal(arg: boolean): void;
  isModalOpened: boolean;
}

export const Modal = ({
  title,
  channels,
  isModalOpened,
  toggleModal
}: Props) => {
  const renderModalChannelGroup = React.useCallback(
    (channel, index) => (
      <ModalChannelCard key={`${index}-${channel.title}`}>
        <CardPicture url={channel.imageLink} alt={channel.title} />
        <CardDescription>{channel.description}</CardDescription>
      </ModalChannelCard>
    ),
    []
  );
  return (
    <CustomModal
      modalProps={{
        isOpen: isModalOpened,
        onCloseRequest: () => toggleModal(!isModalOpened),
        closeIconSize: '10'
      }}
    >
      {title && <Title titleLabel={title} titleHtmlTag="h1" />}
      <ModalChannelGrid>
        {channels.map(renderModalChannelGroup)}
      </ModalChannelGrid>
    </CustomModal>
  );
};
