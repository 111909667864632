/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { TYPES } from 'shared/modules/geolocation/actions/index';

// -----------------------------------------------------------------------------
// EXPORTED REDUCER STATE TYPE
/*
export type State = {
  fetchingZonePending: boolean,
  fetchingZoneSuccess: boolean,
  fetchingZoneError: boolean,
  fetchingCoordinatesPending: boolean,
  fetchingCoordinatesSuccess: boolean,
  fetchingCoordinatesError: boolean,
  coordinates: null | {
    latitude: number,
    longitude: number
  },
  countryCode: ?string
};
*/
// -----------------------------------------------------------------------------
// PRIVATES

const defaultState = {
  fetchingZonePending: false,
  fetchingZoneSuccess: false,
  fetchingZoneError: false,
  fetchingCoordinatesPending: false,
  fetchingCoordinatesSuccess: false,
  fetchingCoordinatesError: false,
  coordinates: null,
  countryCode: null
};

// -----------------------------------------------------------------------------
// REDUCER

export default function geolocation(state = defaultState, action) {
  switch (action.type) {
    case TYPES.FETCH_ZONE.PENDING:
      return {
        ...state,
        fetchingZonePending: true
      };

    case TYPES.FETCH_ZONE.SUCCESS:
      return {
        ...state,
        fetchingZonePending: false,
        fetchingZoneSuccess: true,
        countryCode: action.payload.zone.country_code
      };

    case TYPES.FETCH_ZONE.ERROR:
      return {
        ...state,
        fetchingZonePending: false,
        fetchingZoneSuccess: false,
        fetchingZoneError: true
      };

    case TYPES.FETCH_COORDINATES.PENDING:
      return {
        ...state,
        fetchingCoordinatesPending: true,
        coordinates: null
      };

    case TYPES.FETCH_COORDINATES.SUCCESS:
      return {
        ...state,
        fetchingCoordinatesPending: false,
        fetchingCoordinatesSuccess: true,
        coordinates: action.payload
      };

    case TYPES.FETCH_COORDINATES.ERROR:
      return {
        ...state,
        fetchingCoordinatesPending: false,
        fetchingCoordinatesSuccess: false,
        fetchingCoordinatesError: true
      };

    default:
      return state;
  }
}
