import React, { useCallback } from 'react';
import styled, { keyframes } from 'styled-components';

import { Card } from './Card/Card';
import { FormattedJaquette } from '../types';
import { FormattedTab } from 'shared/blocks/one-shop/shared/components/Tabs/component/Tabs/types';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Title from 'shared/components/presentational/Title';

const fadeAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const FadeContainer = styled.div<{ isActive: boolean }>`
  animation: ${fadeAnimation} 500ms ease-in 0s;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const Wrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: var(--spacing-s);
  background-color: var(--surface-background);

  ${mediaQueries.fromTablet`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-s);
  `}

  ${mediaQueries.fromTablet`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${mediaQueries.fromDesktop`
    grid-template-columns: repeat(5, 1fr);
  `}
`;

export const TabContent = ({
  tab,
  isActive
}: {
  tab: FormattedTab<FormattedJaquette>;
  isActive: boolean;
}) => {
  const renderCards = useCallback(
    (jaquette, index) => <Card key={index} {...jaquette} />,
    []
  );

  return (
    <FadeContainer isActive={isActive}>
      <Title titleHtmlTag="h2" titleLabel={tab.title} subtitle={tab.subtitle} />
      <Wrapper>{tab.contents.map(renderCards)}</Wrapper>
    </FadeContainer>
  );
};
