/* eslint-disable react/jsx-key */
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import BaseModal from 'shared/components/presentational/Modal/BaseModal';
import ModalBody from 'shared/components/presentational/Modal/ModalBody/ModalBody';
import Icon from 'shared/components/presentational/Icons/Icon';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { COLORS, FONT_FAMILIES } from 'shared/constants/theme';

const StyledModalBody = styled(ModalBody)`
  margin: 20px 20px 30px 20px;
  height: ${props => (props.items.length < 5 ? 'auto' : '360px')};
  padding: 0;
  border-radius: 3px;
  overflow: scroll;

  ${mediaQueries.toPhoneMini`height: 270px;`};
  ::after {
    content: '';
    display: block;
    position: sticky;
    height: 25px;
    margin-top: -25px;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    bottom: 0;
    right: 0;
    left: 0;
  }
`;

const Menu = styled.ul``;

const Item = styled.li`
  font-family: ${FONT_FAMILIES.brand};
  font-size: ${pxInRem(18)};
  padding: 15px;
  text-transform: uppercase;
  border-bottom: 1px solid ${COLORS.boulder};

  ${mediaQueries.toPhoneMini`font-size: ${pxInRem(16)};`};
`;

const Text = styled.p`
  color: ${COLORS.white};
  font-family: ${FONT_FAMILIES.brand};
  font-size: ${pxInRem(16)};
  text-align: center;
  line-height: ${pxInRem(20)};
`;

const FloatButton = styled.div`
  ${mediaQueries.fromTablet`display: none;`};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 25px;
  bottom: 80px;
  width: 50px;
  height: 50px;
  background-color: ${COLORS.black};
  border-radius: 50%;
`;

const CloseButton = styled(Icon)`
  color: ${COLORS.white};
`;

export default class ModalMenu extends PureComponent {
  render() {
    const { items, modalState, onClick, handleModalState } = this.props;
    return (
      <BaseModal
        modalProps={{
          isOpen: modalState
        }}
      >
        <Text>
          <Trans i18nKey="blocks.faq.chooseCategory" components={[<br />]} />
        </Text>
        <StyledModalBody withCloseButton={false} items={items}>
          <Menu>
            {items.map(item => (
              <Item
                key={item}
                onClick={() => {
                  onClick(item);
                  handleModalState();
                }}
              >
                {item}
              </Item>
            ))}
          </Menu>
        </StyledModalBody>
        <FloatButton onClick={handleModalState}>
          <CloseButton name="Close" widht={13} height={13} />
        </FloatButton>
      </BaseModal>
    );
  }
}
