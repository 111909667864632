/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { ACTION_TYPES } from '../constants';

const defaultState = {
  bySlug: {},
  currentSlug: '/'
};

export default function smartFaq(state = defaultState, action: any) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_REQUEST: {
      const { slug } = action.payload;
      return {
        ...state,
        currentSlug: slug,
        bySlug: {
          ...state.bySlug,
          [slug]: {
            loading: true
          }
        }
      };
    }

    case ACTION_TYPES.FETCH_SUCCESS: {
      const { data, slug } = action.payload;
      return {
        ...state,
        currentSlug: slug,
        bySlug: {
          ...state.bySlug,
          [slug]: {
            data,
            loading: false
          }
        }
      };
    }

    default:
      return state;
  }
}
