import styled from 'styled-components';

import { FONT_WEIGHTS } from 'shared/constants/theme';
import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';
import { mediaQueries } from 'shared/helpers/styled-components/mediaQueries';

const SQUARE_SIZE = 35;

export const Link = styled(CustomLink)`
  height: 100%;
`;

export const SeeMoreCard = styled.div`
  align-items: center;
  background-color: var(--surface-default);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: var(--spacing-s);
  height: 100%;
  padding: var(--spacing-m);
  ${mediaQueries.fromDesktop`
    padding: var(--spacing-l);
  `}

  img {
    height: ${SQUARE_SIZE}px;
    width: ${SQUARE_SIZE}px;
  }
`;

export const Label = styled.span`
  color: var(--text-default-main);
  font-weight: ${FONT_WEIGHTS.demi};
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
`;
