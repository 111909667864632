import breakpoints from 'shared/components/presentational/Picture/constants';
import { SizesByDevice } from 'shared/components/presentational/Picture/types';
import getHeightWithRatio from 'shared/components/presentational/Picture/helpers/getHeightWithRatio';

export default function generateSizes(
  width: number,
  height?: number
): SizesByDevice {
  let isItMinPixelRatioReached = false;

  return Object.entries(breakpoints).reduce((acc, breakpoint) => {
    const breakpointName = breakpoint[0];
    const breakpointWidth = breakpoint[1];

    if (width * 2 > breakpointWidth) {
      const sizeHeight = getHeightWithRatio(width, height, breakpointWidth);
      return {
        ...acc,
        [breakpointName]: { w: breakpointWidth, h: sizeHeight }
      };
    }

    if (!isItMinPixelRatioReached) {
      isItMinPixelRatioReached = true;
      return {
        ...acc,
        [breakpointName]: { w: width * 2, h: height ? height * 2 : 0 }
      };
    }

    return acc;
  }, {});
}
