import React, { PureComponent } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import Raw from 'shared/components/presentational/Raw/Raw';
import Picture from 'shared/components/presentational/Picture';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import {
  visualLeft,
  visualCentral,
  visualCentralLarge,
  visualRight
} from '../themes/index';
import { TEMPLATE_VISUAL } from '../constants';
import { FONT_FAMILIES } from 'shared/constants/theme';
import { FormattedProps, VisualTheme } from 'shared/blocks/presentation/types';

const getPresentationContentTheme = (
  template?: TEMPLATE_VISUAL
): VisualTheme => {
  switch (template) {
    case TEMPLATE_VISUAL.LEFT:
      return visualLeft;
    case TEMPLATE_VISUAL.RIGHT:
      return visualRight;
    case TEMPLATE_VISUAL.CENTRAL:
      return visualCentral;
    case TEMPLATE_VISUAL.CENTRAL_LARGE:
      return visualCentralLarge;
    default:
      return visualCentral;
  }
};

const Wrapper = styled.div<{ theme: VisualTheme }>`
  display: flex;
  align-items: center;
  ${props => props.theme.wrapper};
  ${mediaQueries.toPhablet`
    flex-wrap: wrap;
    flex-direction: column;
  `};
`;

const Text = styled(Raw)<{ theme: VisualTheme }>`
  flex: 0.4;
  font-size: ${pxInRem(14)};
  font-family: ${FONT_FAMILIES.base};
  line-height: ${pxInRem(20)};
  ${props => props.theme.text};
  ${mediaQueries.toPhablet`
    margin: 20px 0 0;
    text-align: center;
  `};
`;

const ImageWrapper = styled.div<{ fullWidth: boolean }>`
  flex: 0.6;
  ${props => (props.fullWidth ? `margin: 0;` : '')}
  ${mediaQueries.toPhablet`
    margin: 0
  `};
`;

const Image = styled(Picture)<{ theme: VisualTheme }>`
  display: block;
  width: 100%;
  height: auto;
  ${props => props.theme.image}
  object-fit: cover;
  max-height: 291px;
`;

class PresentationContent extends PureComponent<
  Pick<FormattedProps, 'template' | 'alt' | 'imageURL' | 'text'>
> {
  public render(): React.ReactNode {
    const { template, alt, imageURL, text } = this.props;

    const theme = getPresentationContentTheme(template);

    return (
      <ThemeProvider theme={theme}>
        <Wrapper theme={theme}>
          <ImageWrapper fullWidth={!text}>
            {imageURL && (
              <Image
                url={imageURL}
                alt={alt}
                placeholderColor="transparent"
                theme={theme}
              />
            )}
          </ImageWrapper>
          {text && <Text theme={theme} html={text} />}
        </Wrapper>
      </ThemeProvider>
    );
  }
}

export default PresentationContent;
