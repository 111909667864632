/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { ACTION_TYPES } from '../constants';

// -----------------------------------------------------------------------------
// EXPORTED REDUCER STATE TYPE

/* export type State = {
  data: {},
  errors: {},
  requested: {}
}; */

// -----------------------------------------------------------------------------
// PRIVATES

const defaultState = {
  data: {},
  errors: {},
  requested: {}
};

export default function legacy(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_VOD_URL_REQUEST: {
      return {
        ...state,
        requested: {
          ...state.requested,
          [action.payload.url]: true
        }
      };
    }
    case ACTION_TYPES.FETCH_VOD_URL_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.url]: action.payload.data
        },
        requested: {
          ...state.requested,
          [action.payload.url]: false
        },
        errors: {
          ...state.errors,
          [action.payload.url]: false
        }
      };
    }
    case ACTION_TYPES.FETCH_VOD_URL_ERROR: {
      return {
        ...state,
        requested: {
          ...state.requested,
          [action.payload.url]: false
        },
        errors: {
          ...state.errors,
          [action.payload.url]: action.payload.error.toString()
        }
      };
    }

    default:
      return state;
  }
}
