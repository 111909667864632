import i18next from 'i18next';
import 'server/i18n';

export default function password(msg) {
  return this.test({
    name: 'password',
    message: msg || i18next.t('common.form.validation.password'),
    test: (value = '') =>
      /[A-Z]/.test(value) && /[a-z]/.test(value) && /\d/.test(value)
  });
}
