/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*       */

import React from 'react';

import Visual from 'shared/components/presentational/Visual';

export default ({ link, imageURL, title, subtitle, alt }) => (
  <Visual
    title={title}
    subtitle={subtitle}
    imageUrl={imageURL}
    linkUrl={link}
    theme={Visual.themes.mea}
    alt={alt}
  />
);
