import { ACTION_TYPES } from '../constants';

export default (valueTop, valueLeft) => {
  try {
    window.scroll(valueLeft, valueTop);
    return {
      type: ACTION_TYPES.SCROLL_TO,
      payload: { y: valueTop, x: valueLeft }
    };
  } catch (e) {
    return { type: ACTION_TYPES.SCROLL_FAILED };
  }
};
