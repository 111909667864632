import React, { ReactElement, ReactNode } from 'react';

function isChildrenOneElement(children: ReactNode): children is ReactElement {
  if (typeof children === 'undefined') {
    return false;
  }

  if (children === null) {
    return false;
  }

  if (children === true) {
    return false;
  }

  if (children === false) {
    return false;
  }

  return React.Children.count(children) === 1;
}

export default isChildrenOneElement;
