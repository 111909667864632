/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { TYPES as GEOLOCATION_TYPES } from 'shared/modules/geolocation/actions/index';
import { TYPES } from '../actions';
import { TYPES as SHARED_TYPES } from '../../shared/actions';

// -----------------------------------------------------------------------------
// EXPORTED REDUCER STATE TYPE

/* export type State = {
  currentPage: number
}; */

// -----------------------------------------------------------------------------
// PRIVATES

export const defaultState = {
  currentPage: 1
};

// -----------------------------------------------------------------------------

export default function shopsMap(state = defaultState, action) {
  switch (action.type) {
    case TYPES.CHANGE_PAGE: {
      return {
        ...state,
        currentPage: action.payload.page || state.currentPage
      };
    }
    case SHARED_TYPES.FILTER_SHOPS:
    case GEOLOCATION_TYPES.FETCH_COORDINATES.SUCCESS:
      return defaultState;
    default:
      return state;
  }
}
