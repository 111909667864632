/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Content, FormattedProps } from './types';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';

export default (content: Content): FormattedProps => ({
  token: content.token,
  button: {
    label: content.button.label,
    theme: buttonThemeFactory({
      backgroundColor: content.button.backgroundColor,
      color: content.button.colorLabel
    })
  },
  apis: {
    PaymentGetToken: {
      method: content.apis.PaymentGetToken.method,
      url: content.apis.PaymentGetToken.url
    },
    PaymentRedirect: content.apis.PaymentRedirect
  }
});
