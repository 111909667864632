import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { Api } from 'shared/types';
import { TIMEOUT } from 'shared/modules/http/constants';
import retrieveHeadersFromCookies from '../helpers/retrieveHeadersFromCookies';
import { FETCH_ERROR_MESSAGE_EN } from '../../../selfcare/shared/constants';

type ErrorOptions<T> = {
  successCodes?: string[];
  errorCodes?: string[];
  customErrorConditions?: Array<(data: T) => boolean>;
  customErrorFormatter?: (data: any) => string | undefined;
};

export interface AbstractResponse {
  returnCode: string;
  errorCode: string;
  errorLabel: string;
  message?: string;
}

export interface AbstractError {
  message: string;
}

const abstractWebservice = <T extends AbstractResponse>(
  api: Api,
  errorOptions?: ErrorOptions<T>,
  props?: AxiosRequestConfig
) =>
  axios
    .request<T>({
      method: api.method,
      url: api.url,
      headers: retrieveHeadersFromCookies(),
      timeout: TIMEOUT.LONG,
      ...props
    })
    .then(({ data }) => {
      const failsMatchingSuccessCodes =
        errorOptions?.successCodes &&
        !errorOptions?.successCodes?.includes(data.returnCode);

      const matchesErrorCode = Boolean(
        errorOptions?.errorCodes?.includes(data.returnCode || data.errorCode)
      );

      const matchesCustomErrorCond = errorOptions?.customErrorConditions?.some(
        cond => cond(data)
      );

      if (
        failsMatchingSuccessCodes ||
        matchesErrorCode ||
        matchesCustomErrorCond
      ) {
        let message = [
          data.errorCode,
          data.errorLabel || data.message || FETCH_ERROR_MESSAGE_EN
        ]
          .filter(Boolean)
          .join(': ');

        if (errorOptions?.customErrorFormatter) {
          const customErrorMessage = errorOptions?.customErrorFormatter(data);
          if (customErrorMessage) {
            message = customErrorMessage;
          }
        }

        throw new Error(message);
      }

      return data;
    })
    .catch((error: AxiosError | Error) => {
      if ('config' in error) {
        const message = FETCH_ERROR_MESSAGE_EN;
        const prospectMessage = error?.response?.data
          ?.prospectMessage as string;
        const status = error?.response?.status;

        throw {
          message,
          prospectMessage,
          status
        };
      }

      // passthrough error
      throw error;
    });

export default abstractWebservice;
