/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { ACTION_TYPES } from './constants';

const defaultState = { hasError: false, message: null };

export default function error(state = defaultState, action) {
  if (action.type === ACTION_TYPES.SET_ERROR) {
    return {
      ...state,
      hasError: true,
      message: action.payload.message,
      shouldShow: action.payload.shouldShow
    };
  }
  return state;
}
