import createToken, {
  hasTokenCreationFailed
} from '../webservices/createToken';
import formatCookies from 'shared/blocks/authentication/helpers/formatCookies';
import { Api } from 'shared/types';
import { AUTH_COOKIES_LIST } from 'shared/constants/auth';
import setCookies from 'shared/helpers/universal-cookie/setCookies';
import clearCookies from 'shared/helpers/universal-cookie/clearCookies';
import clientCookies from 'client/cookies';

/**
 * Handle silent authentication with optional passId.
 *   Clear auth cookies if authentication failed.
 *   Set new auth cookies if authentication succeed.
 */
type SilentAuthenticationData = Promise<ReturnType<typeof formatCookies>>;

export default async function handleSilentAuthentication(
  apiCreateToken: Api,
  authenticationIsExternal: boolean,
  passIdOrSessionTokenParam?: string,
  passIdType?: string
): SilentAuthenticationData {
  let data;
  try {
    const response = await createToken(
      apiCreateToken.url,
      apiCreateToken.method,
      authenticationIsExternal,
      passIdOrSessionTokenParam,
      passIdType
    );
    data = response.data;
  } catch (e) {
    // Clear all authentication-related cookies
    clearCookies(clientCookies, AUTH_COOKIES_LIST);
    throw new Error('Unable to create token.');
  }

  if (hasTokenCreationFailed(data)) {
    // Clear all authentication-related cookies
    clearCookies(clientCookies, AUTH_COOKIES_LIST);
    throw new Error('createToken has failed.');
  }

  // Set authentication cookies
  try {
    const cookiesToSet = formatCookies(data.response, authenticationIsExternal);
    setCookies(clientCookies, cookiesToSet);
    return cookiesToSet;
  } catch (e) {
    // An error can occur when formatting cookies if the errorCode is not handled
    // Clear all authentication-related cookies
    clearCookies(clientCookies, AUTH_COOKIES_LIST);
    throw new Error('Unable to set cookies from createToken response.');
  }
}
