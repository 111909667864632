export enum WEBSERVICES {
  GET_ACTIVATION_STATUS = 'GET_ACTIVATION_STATUS',
  GET_ACTIVATION_TOKEN = 'GET_ACTIVATION_TOKEN'
}

export const MODULE_NAME = 'Selfcare/DisneyService';

export enum STATUS_CODE {
  SUCCESS = 200,
  CREATED = 201
}

export const DISNEY_PLUS_ACTIVATION_URL =
  '{baseUrl}activate?token={activationToken}&providerId=canal';

export const DISNEY_KEY = 'DISNEY';

// Eligibility includes `RDISN` and `RDISNC`.
// Only `RDISN` is needed because we use lodash/includes and collection is a string
// @See https://jira.toolbox.canal-overseas.com/browse/AWA-1484
export const MICRO_ELIGIBILITY_DISNEY = 'RDISN';
