/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

export default content => ({
  success: {
    title: get(content, 'successTitle'),
    text: get(content, 'successText')
  },
  error: {
    title: get(content, 'errorTitle'),
    text: get(content, 'errorText')
  },
  apiUrl: get(content, 'api.url', ''),
  apiMethod: get(content, 'api.method', 'POST')
});
