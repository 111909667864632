/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import loadable from '@loadable/component';

export const namespace = 'canalplus.block.apash.update_payment_method';
export const Component = loadable(
  () => import('./containers/UpdatePaymentContainer')
);
export { default as propsFormatter } from './propsFormatter';
