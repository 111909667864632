import breakpoints from 'shared/constants/breakpoints';
import { DIMENSIONS } from 'shared/constants/theme';

export const MINIMUM_GAP = 30; // in px

export const CONTENT_PADDING = 25; // in px

const MIN_TABLET_WIDTH = parseInt(breakpoints.phablet, 10) + 1; // in px

const MIN_INNER_CONTAINER_WIDTH_TABLET =
  MIN_TABLET_WIDTH - 2 * DIMENSIONS.CONTAINER_MINIMUM_PADDING_TABLET; // in px

/* MIN_WIDTH should allow two offer card to fit until phone breakpoint and bellow */
export const MIN_WIDTH = Math.floor(
  (MIN_INNER_CONTAINER_WIDTH_TABLET - MINIMUM_GAP) / 2
); // in px

/* BREAKPOINT from which two slides can fit */
export const TWO_SLIDES_BREAKPOINT =
  MIN_WIDTH * 2 + DIMENSIONS.CONTAINER_MINIMUM_PADDING * 2 + MINIMUM_GAP;

/* BREAKPOINT from which three slides can fit */
export const THREE_SLIDES_BREAKPOINT =
  MIN_WIDTH * 3 +
  DIMENSIONS.CONTAINER_MINIMUM_PADDING_TABLET * 2 +
  MINIMUM_GAP * 2;
