import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';

import SpinnerLoader from '../../Loader';

const LOADER_WIDTH = '24px';

const Wrapper = styled.div`
  position: absolute;
  top: calc(50% - ${LOADER_WIDTH} / 2);
  left: calc(50% - ${LOADER_WIDTH} / 2);
`;

export const StyledLoader = styled(SpinnerLoader)``;

interface Props {
  className?: string;
}

export default class Loader extends PureComponent<Props> {
  public static readonly displayName = 'Button.Loader';

  public render(): ReactNode {
    const { className } = this.props;
    return (
      <Wrapper className={className}>
        <StyledLoader />
      </Wrapper>
    );
  }
}
