import React from 'react';
import { useTranslation } from 'react-i18next';

import splitPrice from 'shared/helpers/text/splitPrice';
import { PriceWrapper, PriceComplement, PriceHint } from './styles/components';

interface Props {
  value: string | number;
  exponent?: string;
  hint?: string;
  strikeThrough?: boolean;
}

const Price = ({ value, exponent, strikeThrough, hint }: Props) => {
  const { t } = useTranslation();

  let price = value;
  let priceExponent = exponent || '';
  let description = t('components.price.default');

  if (strikeThrough) {
    price = splitPrice(value).price;
    priceExponent = splitPrice(value).priceComplement;
    description = t('components.price.strikeThrough');
  }

  return (
    <PriceWrapper strikeThrough={strikeThrough} description={description}>
      {price}
      <PriceComplement withHint={Boolean(hint)}>
        {priceExponent}
        {hint && <PriceHint>{hint}</PriceHint>}
      </PriceComplement>
    </PriceWrapper>
  );
};

export default Price;
