import getData from '../selectors/getData';
import getRequested from '../selectors/getRequested';
import fetchWebservice from '../webservices/fetch';
import fetchVodUrlError from './fetchVodUrlError';
import fetchVodUrlRequest from './fetchVodUrlRequest';
import fetchVodUrlSuccess from './fetchVodUrlSuccess';

function shouldFetch(state, url) {
  const dataByUrl = getData(state);
  const requestedByUrl = getRequested(state);
  return !dataByUrl[url] && !requestedByUrl[url];
}

function fetch(url) {
  return async dispatch => {
    dispatch(fetchVodUrlRequest(url));
    try {
      const { data } = await fetchWebservice(url);
      dispatch(fetchVodUrlSuccess(url, data));
    } catch (error) {
      dispatch(fetchVodUrlError(url, error));
    }
  };
}

export default url => async (dispatch, getState) =>
  shouldFetch(getState(), url) ? fetch(url)(dispatch) : Promise.resolve();
