export const ENCRYPT_DATA = '/encrypt-data';
export const DECRYPT_DATA = '/decrypt-data';
export const DECRYPT_AND_FORWARD = '/decrypt-and-forward';
export const GET_PAYMENT_MYANMAR_TOKEN = '/get-payment-myanmar-token';
export const VALIDATE_SYSTEMPAY_PAYMENT = '/validate-systempay';
export const CHECKOUT_VISA = '/visa-checkout';
export const PROCESS_VISA_PAYMENT_RECAP = '/process-visa-payment-recap';
export const SEND_MAIL = '/send-mail';
export const CANAL_PLUS_TELECOM_AUTHENTICATION =
  '/canal-plus-telecom-authentication';
