import uuid from 'uuid';
import { createStore, applyMiddleware, compose } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import thunk from 'redux-thunk';

import modulesMiddleware from 'shared/modules/middleware';
import selfcareMiddleware from 'shared/blocks/selfcare/shared/middleware';
import createRootReducer from './reducers';

/**
 * Configure the store
 *
 * @param {object} initialState Initial state
 * @param {string} nonce        Unique ID of the request
 */
function configureStore({ initialState, nonce, history }) {
  let uniqueId = nonce;
  if (!uniqueId) {
    uniqueId = initialState && initialState.nonce ? initialState.nonce : uuid();
  }

  const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
  } = createReduxHistoryContext({ history });

  const enhancers = compose(
    applyMiddleware(
      /**
       * Initialising redux-thunk with extra arguments will pass the below
       * arguments to all the redux-thunk actions.
       */
      thunk.withExtraArgument({ uniqueId }),
      routerMiddleware,
      ...modulesMiddleware,
      selfcareMiddleware
    ),
    /**
     * Redux Dev Tools store enhancer.
     * We only want this enhancer enabled for development and when in a browser
     * with the extension installed.
     *
     * @see https://github.com/zalmoxisus/redux-devtools-extension
     */
    (process.env.NODE_ENV === 'development' ||
      process.env.RAZZLE_NODE_CONFIG_ENV === 'development') &&
      typeof window !== 'undefined' &&
      typeof window.devToolsExtension !== 'undefined'
      ? window.devToolsExtension()
      : f => f
  );

  const store = initialState
    ? createStore(createRootReducer(routerReducer), initialState, enhancers)
    : createStore(createRootReducer(routerReducer), enhancers);

  return { store, history: createReduxHistory(store) };
}

export default configureStore;
