/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import cs from 'classnames';
import axios from 'axios';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import queryString from 'query-string';

import clientCookies from 'client/cookies';
import getComponent from 'shared/modules/page/components/componentFactory';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import getLinkHrefWithAuthenticationFactory from 'shared/helpers/uri/getLinkHrefWithAuthenticationFactory';
import handleSilentAuthentication from 'shared/modules/page/helpers/handleSilentAuthentication';
import clearUserDataAndCookies from 'shared/modules/page/helpers/clearUserDataAndCookies';
import WebviewCloseButton from 'shared/components/container/WebviewCloseButton';
import { hasPageUnifiedMenu } from 'shared/modules/page/selectors/hasPageMenuUnified';
import { PAGE_TYPE } from '../constants';
import hasExternalLoginPayload from '../../../../server/helpers/hasExternalLoginPayload';
import formatCookiesFromExternalLogin from '../../../../server/helpers/formatCookiesFromExternalLogin';
import setCookies from '../../../helpers/universal-cookie/setCookies';
import { createTemplate } from 'shared/helpers/template';
import {
  HEADER_MOBILE_HEIGHT,
  HEADER_UNIFIED_DESKTOP_HEIGHT,
  HEADER_UNIFIED_MOBILE_HEIGHT
} from 'shared/constants/heights';
import hasCanalPlusUrl from 'shared/blocks/authentication/helpers/hasCanalPlusUrl';
import { ThemePaletteGlobalStyle } from 'shared/assets/styles/theme';
import TrackingConsent from './TrackingConsent';

const ContainersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--surface-background);
  justify-content: space-between;
`;

const BlockWrapperCheckout = styled.div`
  ${mediaQueries.fromTablet`
  margin: 0 5%;
  `}
`;

export const BlocksContainers = styled.div`
  &.content {
    ${ContainersWrapper}:not(.${PAGE_TYPE.SILENT_LOGIN}) > & {
      flex: 1; // content should take the whole height, except on silent login page, on which blocks must be centered
    }
    .Block {
      &.Empty {
        margin-bottom: calc(var(-var(--spacing-xxl)) * -1);
      }

      > section {
        margin-top: var(--spacing-xxl);
        margin-bottom: var(--spacing-xxl);
      }

      &:first-child {
        > section {
          &.MEA {
            margin-top: 0;
          }
        }
      }
    }

    ${mediaQueries.toTablet`
      padding-top: ${HEADER_MOBILE_HEIGHT}px;
    `}

    ${mediaQueries.toPhoneMini`
      padding-top: 40px;
    `};

    &.content--two-thirds {
      display: grid;
      grid-template-columns: 1fr;

      ${mediaQueries.fromTablet`
      grid-template-columns: 2fr 1fr;
    `}
    }

    &.unified-menu {
      padding-top: ${HEADER_UNIFIED_MOBILE_HEIGHT}px;

      ${mediaQueries.fromTablet`
      padding-top: ${HEADER_UNIFIED_DESKTOP_HEIGHT}px;
    `}
    }
  }
`;

export default class Page extends Component {
  /**
   * Private
   */
  static isLogoutPage = props => props.data.type === PAGE_TYPE.LOGOUT;
  static isSilentLoginPage = props =>
    props.data.type === PAGE_TYPE.SILENT_LOGIN;

  handleLogout() {
    const { redirect, flushSelfcare, redirectUri } = this.props;

    // Logout user
    clearUserDataAndCookies(flushSelfcare);

    // Redirect after a delay
    setTimeout(() => {
      if (redirectUri) {
        redirect(redirectUri);
      } else {
        redirect('/');
      }
    }, 1000);
  }

  /**
   * Lifecycle
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const offerZoneCookie = { offerZone: nextProps.pentagram };
    if (nextProps.pentagram !== this.props.pentagram) {
      setCookies(clientCookies, offerZoneCookie);
    }

    // User land on logout page
    const hasChangeType = nextProps.data.type !== this.props.data.type;
    if (hasChangeType && Page.isLogoutPage(nextProps)) {
      this.handleLogout();
      return;
    }

    const {
      redirect,
      apiCreateToken,
      passIdParam,
      refererUrl,
      loginUrl,
      authenticationIsExternal,
      sessionTokenParam
    } = nextProps;

    // Handle silent authentication
    if (hasChangeType && Page.isSilentLoginPage(nextProps)) {
      const createTokenPassId = passIdParam || sessionTokenParam;

      handleSilentAuthentication(
        apiCreateToken,
        authenticationIsExternal,
        createTokenPassId
      )
        .then(() => {
          // Redirect to referer url
          redirect(refererUrl);
          return;
        })
        .catch(() => {
          // Redirect to Auth Page if API `createToken` return an error
          redirect(loginUrl);
        });
    }
  }

  async componentDidMount() {
    // Handle logout
    if (Page.isLogoutPage(this.props)) {
      this.handleLogout();
      return;
    }

    const {
      redirect,
      apiCreateToken,
      passIdParam,
      refererUrl,
      loginUrl,
      accessToken,
      accessProviderUrl,
      authenticationIsExternal,
      sessionTokenParam
    } = this.props;

    // Handle silent authentication
    if (Page.isSilentLoginPage(this.props)) {
      /**
       * Set initial cookies if user is coming from external login
       */
      if (accessToken && accessProviderUrl) {
        const accessProviderUrlWithAccessToken = createTemplate(
          accessProviderUrl
        )({ accessToken });
        try {
          const response = await axios(accessProviderUrlWithAccessToken);

          if (response && hasExternalLoginPayload(response.data)) {
            // Set cookies
            const cookiesToSet = formatCookiesFromExternalLogin(response.data);
            setCookies(clientCookies, cookiesToSet);
          }
        } catch (e) {
          // Do not proceed silent authentication if external login failed
          redirect('/');
          return;
        }
      }

      /**
       * Perform silent authentication
       */
      const createTokenPassId = passIdParam || sessionTokenParam;

      try {
        const cookiesToSet = await handleSilentAuthentication(
          apiCreateToken,
          authenticationIsExternal,
          createTokenPassId,
          sessionTokenParam ? 'okta' : 'pass'
        );

        // Redirect to referer url
        if (hasCanalPlusUrl(refererUrl)) {
          const canalPlusUrlWithToken = queryString.stringifyUrl({
            url: refererUrl,
            query: { token: cookiesToSet.AuthIdToken }
          });
          redirect(canalPlusUrlWithToken);
        } else {
          redirect(refererUrl);
        }
        return;
      } catch (e) {
        if (accessToken) {
          // Redirect to homepage coming from external login
          // in order to avoid infinite login loop
          redirect('/');
          return;
        }
        // Redirect to Auth Page if API `createToken` return an error
        redirect(loginUrl);
      }
    }
  }

  componentDidCatch(error) {
    console.error(
      `An error occurred while rendering page "${this.props.data.title}" : ${error.stack}`
    );
  }

  /**
   * Render
   */
  render() {
    const { data, zoneBigram, zoneDefaultLang } = this.props;
    const {
      template,
      meta,
      title,
      favicon,
      containers,
      isOneShopLayout,
      trackingConsent
    } = data;

    const htmlAttributes = {
      'data-theme': isOneShopLayout ? 'one-shop' : 'default'
    };

    return (
      <>
        <ThemePaletteGlobalStyle theme={data.theme} />

        <ContainersWrapper className={template}>
          {/* Page meta and scripts */}
          <Helmet
            meta={meta}
            title={title}
            link={favicon}
            htmlAttributes={htmlAttributes}
          >
            {this.renderScripts()}
          </Helmet>
          <TrackingConsent
            zoneBigram={zoneBigram}
            zoneDefaultLang={zoneDefaultLang}
            {...trackingConsent}
          />
          {/* Page-level components */}
          <WebviewCloseButton />
          {/* Blocks */}
          {containers.map(this.renderContainer)}
        </ContainersWrapper>
      </>
    );
  }

  renderScripts = () => {
    this.props.data.scripts.map((script, index) => {
      if (script.raw) {
        return (
          <script key={`script-${index}`} type={script.type}>
            {script.raw}
          </script>
        );
      }

      return (
        <script
          key={`script-${index}`}
          type="text/javascript"
          src={script.url}
        />
      );
    });
  };

  renderContainer = containerId => {
    const pageHasCartBlock = this.props.data.blocks.find(
      block => block.type === 'canalplus.block.selfcare.apash.checkout'
    );

    const blocks = this.props.data.blocks
      .filter(block => block.container === containerId)
      .map(this.renderBlock);

    return (
      <BlocksContainers
        className={cs(containerId, {
          'content--two-thirds': containerId === 'content' && pageHasCartBlock,
          'unified-menu': hasPageUnifiedMenu(this.props.data)
        })}
        key={`${containerId}`}
      >
        {pageHasCartBlock && containerId === 'content' ? (
          <BlockWrapperCheckout>{blocks}</BlockWrapperCheckout>
        ) : (
          blocks
        )}
      </BlocksContainers>
    );
  };

  renderBlock = (block, index) => {
    /**
     *  PropsFormatterUtils
     *  set of tools/helpers to ease content formatting
     */
    const propsFormatterUtils = {
      getLinkHrefWithAuthentication: getLinkHrefWithAuthenticationFactory(
        this.props.cookies,
        this.props.loginUrlFactory
      )
    };

    return getComponent(
      block.id,
      block.type,
      `${block.type}-${index}`,
      block.content,
      propsFormatterUtils
    );
  };
}
