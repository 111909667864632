import React, { ReactNode, PureComponent } from 'react';

import Icon from 'shared/components/presentational/Icons/Icon';

interface Props {
  isOpen: boolean;
  className?: string;
}

class DropdownIndicator extends PureComponent<Props> {
  public render(): ReactNode {
    const { className, isOpen } = this.props;
    return (
      <Icon
        width="10"
        height="10"
        className={className}
        name={isOpen ? 'ArrowTop' : 'ArrowBottom'}
      />
    );
  }
}

export default DropdownIndicator;
