import { AnyAction, Dispatch, Middleware } from 'redux';
import { replace } from 'redux-first-history';
import { REHYDRATE } from 'redux-persist';

import { actionType as FLUSH } from './actions/flush';
import { useAttempts as useSubscriberDataAttempts } from 'shared/blocks/new-selfcare/shared/hooks/useSubscriberDataQuery';
import getNumContract from 'shared/blocks/selfcare/shared/selectors/getNumContract';
import getLocation from 'shared/modules/router/selectors/getLocation';
import { SET_NUM_CONTRACT_ACTION_TYPE } from './constants';
import getPageType from 'shared/modules/page/selectors/getPageType';
import { ACTION_TYPES } from 'shared/modules/page/constants';
import getQueryParams from 'shared/modules/router/selectors/getQueryParams';

const selfcareMiddleware: Middleware = api => (
  next: Dispatch<AnyAction, any>
) => (action: AnyAction) => {
  /**
   * Intercept FETCH_SUCCESS and add numContract as query params if needed
   */
  if (action.type === ACTION_TYPES.FETCH_SUCCESS) {
    const page = action.payload;
    const state = api.getState();
    const numContract = getNumContract(state);
    const pageType = getPageType({ page });

    const params = new URLSearchParams(page?.path?.split('?')[1]);

    if (
      numContract &&
      !params.get('numcontract') &&
      pageType === 'authenticated'
    ) {
      params.set('numcontract', numContract);
      const location = {
        ...page.location,
        search: `?${params.toString()}`
      };

      // about PUSH action :
      // the location has already been pushed as a new step in the history,
      // we just replace this "step" in the history
      api.dispatch(replace(location));
    }
  }

  /**
   * Intercept numContract changes and set numContract in query params
   */
  if (action.type === SET_NUM_CONTRACT_ACTION_TYPE) {
    const params = new URLSearchParams(
      action.payload?.location?.search?.slice(1)
    );
    params.set('numcontract', action.payload);

    const location = {
      ...getLocation(api.getState()),
      search: `?${params.toString()}`
    };

    api.dispatch(replace(location));
  }

  /**
   * Set numContract in query params after been retrieved from SessionStorage
   */
  if (action.type === REHYDRATE) {
    const state = api.getState();
    const queryParams = getQueryParams(state);
    const numContract = getNumContract(state);
    const pageType = getPageType(state);

    if (numContract && pageType === 'authenticated') {
      const params = new URLSearchParams(
        action.payload?.location?.search?.slice(1) ??
          (queryParams as Record<string, string>)
      );
      params.set('numcontract', numContract);

      const location = {
        ...getLocation(state),
        search: `?${params.toString()}`
      };

      api.dispatch(replace(location));
    }
  }

  /**
   * Intercept selfcare FLUSH action add reset subscriberData fetch attempts
   */
  if (action.type === FLUSH) {
    useSubscriberDataAttempts.getState().reset();
  }

  return next(action);
};

export default selfcareMiddleware;
