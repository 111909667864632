/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { ACTION_TYPES } from '../constants';

export default message => ({
  type: ACTION_TYPES.SET_ERROR,
  payload: {
    message
  }
});
