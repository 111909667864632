/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { PureComponent } from 'react';

import { initScript } from 'shared/helpers/assets';
import Raw from 'shared/components/presentational/Raw/Raw';

export interface Props {
  inlineScript: string;
  script: string;
  markup: string;
  onload: string;
}

export default class Javascript extends PureComponent<Props> {
  private evalInlineScripts = () => {
    const { onload, inlineScript } = this.props;

    /**
     * We know this is dangerous to eval code but this is
     * an explicit request from C+I PO
     */
    if (onload) {
      eval(onload);
    }

    if (inlineScript) {
      eval(inlineScript);
    }
  };

  public componentDidMount() {
    const { script } = this.props;

    const loadScript = script ? initScript : Promise.resolve.bind(Promise);

    loadScript(script).then(this.evalInlineScripts);
  }

  public render(): React.ReactNode {
    const { markup } = this.props;

    if (!markup) {
      return false;
    }

    return <Raw html={markup} />;
  }
}
