/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import debounce from 'lodash/debounce';

import breakpoints, { Device } from 'shared/constants/breakpoints';
import createMediaQueries from 'shared/helpers/viewport/mediaQueries';
import getCurrentBreakpointDevice from 'shared/helpers/viewport/getCurrentBreakpointDevice';

interface State {
  device: Device;
  devicePending: boolean;
}

export interface ResponsiveHocProps {
  device: Device;
  devicePending: boolean;
  mediaQueries: { [mediaQuery: string]: boolean };
}

export default <P extends object>(
  WrappedComponent: React.ComponentType<P & ResponsiveHocProps>
) =>
  class ResponsiveHoc extends React.PureComponent<P, State> {
    public readonly state = {
      device: Object.keys(breakpoints)[0] as Device,
      devicePending: true
    };

    /**
     * Update state with new dimensions
     */
    private handleWindowResize = () => {
      debounce(() => {
        this.setState({ device: getCurrentBreakpointDevice(window) });
      }, 300)();
    };

    /**
     * Add event listener
     */
    public componentDidMount() {
      window.addEventListener('resize', this.handleWindowResize);
      // Compute current device value
      // Note: we do this AFTER the first client side render,
      // as it would break the hydration otherwise
      this.handleWindowResize();
      this.setState({ devicePending: false });
    }

    /**
     * Remove event listener
     */
    public componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowResize);
    }

    public render() {
      const { device, devicePending } = this.state;

      return (
        <WrappedComponent
          device={device}
          devicePending={devicePending}
          mediaQueries={createMediaQueries(device)}
          {...this.props}
        />
      );
    }
  };
