import React, { useMemo } from 'react';
import get from 'lodash/get';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FieldProps } from 'formik';

import Input, { Props as InputProps } from '../Input';
import FieldHint from '../FieldHint';
import { HintProps } from 'shared/components/presentational/Form/Hint';
import translateStaticYupError, {
  Error
} from '../helpers/translateStaticYupError';

export type Props = FieldProps<any> & InputProps & HintProps & WithTranslation;

function InputField({
  form,
  field,
  isRequired,
  placeholder,
  hint,
  t,
  ...props
}: Props) {
  const [error, errorI18n] = useMemo(() => {
    const error = get(form.touched, field.name) && get(form.errors, field.name);
    const errorI18n = translateStaticYupError(error as Error, t);

    return [error, errorI18n];
  }, [form.touched, field.name, form.errors]);

  return (
    <>
      <Input
        {...form}
        {...field}
        error={error}
        {...props}
        placeholder={
          isRequired
            ? placeholder + ` ${hint ?? t('common.form.hint.required')}`
            : placeholder
        }
      />
      <FieldHint error={errorI18n} isRequired={isRequired} />
    </>
  );
}

export default withTranslation()(InputField);
