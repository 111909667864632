/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';

import { constants as ActionTypes } from '../actions';
import { getZapNumber } from '../helpers/getZapNumber';

// -----------------------------------------------------------------------------
// EXPORTED REDUCER STATE TYPE
/*
export type State = {
  /!**
   * The scroll position on the view
   * This is used to restore the position when the user
   * is performing a history back from an other page
   *!/
  scrollPosition: { x, y },

  /!**
   * The current type periode selected
   * This value is used to set the active tab
   * and to load the right component into the view
   *!/
  type,

  /!**
   * The vector url
   * Returned by API, it's used to query channels
   * `channels` & `programs` data need to be re-fetch when this value changes.
   *!/
  vectorUrl,

  /!**
   * The current time
   * Use to get the current programs
   * This value is set when programs are fetched
   *!/
  currentTimestamp,

  /!**
   * All the channels
   *!/
  channels: {
    /!**
     * Ordered by zapNumber
     *!/
    byZapNumber
  },

  /!**
   * All the programs
   *!/
  programs: {
    byDay: {
      0: {
        /!**
         * Ordered by channels' zapNumber
         * Programs are sorted using 'startTimeAsc' method when fetched
         *
         *
         * {
         *   <ZapNumber>: [ <Program>, <Program>, ... ],
         *   <ZapNumber>: [ <Program>, <Program>, ... ],
         *   ...
         * }
         *!/
        byChannelZapNumber
      }
    }
  }
}; */

// -----------------------------------------------------------------------------
// PRIVATES

const defaultState = {
  scrollPosition: { x: 0, y: 0 },

  vectorUrl: null,

  currentTimestamp: dayjs().unix(),

  channels: {
    byZapNumber: {}
  },

  programs: {
    byDay: range(9).reduce((acc, dayIndex) => {
      acc[dayIndex] = { byChannelZapNumber: {} };
      return acc;
    }, {})
  }
};

// -----------------------------------------------------------------------------
// REDUCER

export const startTimeAsc = (a, b) => {
  if (a.startTime < b.startTime) {
    return -1;
  }
  if (a.startTime > b.startTime) {
    return 1;
  }
  return 0;
};

export default function tvGuide(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.SAVE_SCROLL_POSITION: {
      return {
        ...state,
        scrollPosition: action.payload.position
      };
    }

    case ActionTypes.FETCH_CHANNELS_SUCCESS: {
      const newState = cloneDeep(state);
      const vectorUrl = action.payload.url;

      newState.channels.byZapNumber = action.payload.channels.reduce(
        (_acc, curr) => {
          const acc = _acc;
          const zapNumber = getZapNumber(curr);
          acc[zapNumber] = curr;

          return acc;
        },
        {}
      );

      return {
        ...newState,
        vectorUrl,
        currentTimestamp: dayjs().unix()
      };
    }
    case ActionTypes.FETCH_CHANNELS_PROGRAMS_SUCCESS: {
      const newState = cloneDeep(state);
      const day = action.payload.day;

      action.payload.queries.forEach(({ channel, programs }) => {
        const allPrograms = programs.timeSlices.reduce(
          (acc, curr) => [].concat(acc, curr.contents).sort(startTimeAsc),
          []
        );
        const zapNumber = getZapNumber(channel);

        newState.programs.byDay[day].byChannelZapNumber[
          zapNumber
        ] = allPrograms;
      });

      return {
        ...newState,
        currentTimestamp: dayjs().unix()
      };
    }
    default:
      return state;
  }
}
