import { useQuery } from 'react-query';
import axios from 'axios';

import { Api } from 'shared/types';
import { GeographicalAreaResponse } from '../types';

const useGeographicalAreaQuery = (
  api: Api,
  { enabled = true }: { enabled: boolean }
) => {
  return useQuery<GeographicalAreaResponse, string>(
    api.url,
    async () => {
      return axios
        .request<GeographicalAreaResponse>(api)
        .then(response => response.data);
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};

export default useGeographicalAreaQuery;
