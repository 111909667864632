/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';
import dayjs from 'dayjs';

export default content => ({
  rows: get(content, 'rows', []).map(row => {
    const timezone = get(content, 'timeZone');
    const toTzDatetime = timezone
      ? dateTime => dayjs.tz(dateTime, timezone)
      : dateTime => dayjs(dateTime);

    const dateTime = get(row, 'dateTime');

    return {
      title: get(row, 'title'),
      subtitle: get(row, 'subTitle'),
      dateTime: dateTime ? toTzDatetime(dateTime) : undefined,
      logoUrl: get(row, 'channelLogo.link.href'),
      pictoUrl: get(row, 'pictogram.link.href')
    };
  })
});
