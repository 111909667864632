import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 18 18" width={width} height={height} className={className}>
    <path
      d="M17.3 9.038c0-.3-.1-.6-.3-.9l-6.7-7.1c-.5-.4-1.3-.6-1.8-.1s-.5 1.4 0 1.8l4.7 5H1.9c-.7 0-1.2.6-1.2 1.2 0 .7.6 1.3 1.2 1.3h11.3l-4.7 5c-.4.4-.5 1.4 0 1.8.5.5 1.4.3 1.8-.1l6.7-7.1c.2-.2.3-.5.3-.8z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
