import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { hexToCSSFilter } from 'hex-to-css-filter';

import { COLORS, FONT_FAMILIES } from 'shared/constants/theme';
import { KEY_CODES } from 'shared/constants/keycodes';
import Icon from 'shared/components/presentational/Icons';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { FormattedAdvantage } from '../types';
import pxToRem from 'shared/helpers/styled-components/remHelper';
import Picture from 'shared/components/presentational/Picture';

interface Props {
  advantage: FormattedAdvantage;
  color: string;
  toggleColor?: string;
}

const ColorizedPicture = styled(Picture)<{
  filter: typeof hexToCSSFilter;
}>`
  filter: ${props => props.filter};
  height: 72px;
`;

const TextWrapper = styled.div`
  line-height: ${pxToRem(19)};
  font-size: ${pxToRem(16)};
  margin: 17px;
  font-family: ${FONT_FAMILIES.base};
`;

const CustomTitle = styled.h3<{
  color?: string;
}>`
  color: ${props => props.color ?? COLORS.black};
  font-size: ${pxToRem(16)};
  padding: 6px 20px 17px 20px;
  margin: 0px;
  ${mediaQueries.toPhablet`
   padding: 6px 0px 17px 0px;
  `}
`;

const StyledIcon = styled(Icon)`
  color: ${props => props.color ?? COLORS.black};
`;

const FoldsAdvantageCard = ({ advantage, color, toggleColor }: Props) => {
  const [isToggle, setIsToggle] = useState(false);

  const toggleDescription = useCallback(() => {
    setIsToggle(prevToggleValue => !prevToggleValue);
  }, []);

  const onKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const enterOrSpace =
        e.key === KEY_CODES.ENTER || e.key === KEY_CODES.SPACE;
      if (enterOrSpace) {
        toggleDescription();
      }
    },
    [isToggle, toggleDescription]
  );

  const pictureFilter = useMemo(
    () => hexToCSSFilter(isToggle ? toggleColor : color),
    [isToggle]
  );

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={toggleDescription}
      onKeyPress={onKeyPress}
    >
      <ColorizedPicture
        url={advantage.logo}
        filter={pictureFilter}
        alt="logo"
      />

      <CustomTitle color={isToggle ? toggleColor : color}>
        {advantage.title}
      </CustomTitle>
      {advantage.description ? (
        isToggle ? (
          <>
            <StyledIcon color={toggleColor} name="ArrowTop" />
            <TextWrapper>
              <p>{advantage.description}</p>
            </TextWrapper>
          </>
        ) : (
          <StyledIcon color={toggleColor} name="ArrowBottom" />
        )
      ) : null}
    </div>
  );
};

export default FoldsAdvantageCard;
