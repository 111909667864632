import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Button from 'shared/components/presentational/Button';
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { COLORS } from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { ICONS } from '../../constants';

const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const ListItem = styled.li`
  :not(:last-child) {
    margin-right: 20px;
  }
`;

const StyledButton = styled(Button)`
  ${mediaQueries.toTablet`
    height: 54px !important;
    font-size: ${pxInRem(12)} !important;
    color: white !important;    
    :hover{
      color: white !important;
    }
  `};
`;

const StyledButtonText = styled(Button.children.Text)`
  margin-left: 5px;
`;

const buttonThemeFactory = (color?: string) =>
  themeFactory({
    backgroundColor: 'transparent',
    color: color || COLORS.cerulean,
    padding: '0 0 0 0'
  });

interface Props {
  className?: string;
  items: {
    id: string;
    title: string;
    link: string;
  }[];
  onItemClick?: () => void;
  color?: string;
}

export default class QuickAccess extends PureComponent<Props> {
  public render(): React.ReactNode {
    const { className, items, onItemClick, color } = this.props;

    if (items.length === 0) return false;

    return (
      <List className={className}>
        {items.map((item, index) => (
          <ListItem key={`${item.id}-${index}`}>
            <StyledButton
              to={item.link}
              theme={buttonThemeFactory(color)}
              onClick={onItemClick}
            >
              <Button.children.SvgIcon
                // @ts-ignore
                xlink={`/sprite.svg#${ICONS[item.id]}`}
                width="18"
                height="18"
              />
              <StyledButtonText>{item.title}</StyledButtonText>
            </StyledButton>
          </ListItem>
        ))}
      </List>
    );
  }
}
