/* eslint-disable react/prop-types */
/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

import Picture from 'shared/components/presentational/Picture';
import HodorChildLoader from 'shared/blocks/vod/hodor/containers/HodorChildContainer';
import DetailShowEpisodesList from 'shared/blocks/vod/hodor/components/childrens/DetailShowEpisodesList';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { COLORS } from 'shared/constants/theme';

const Wrapper = styled.section`
  margin: auto;
  margin-top: 0px !important;

  ${mediaQueries.toPhoneMini`
    margin-top: 60px !important;
  `};

  ${mediaQueries.fromPhone`
    margin-top: 35px !important;
  `};
`;

const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0.5rem;
`;

const Summary = styled.p`
  font-size: 1.1rem;
  line-height: normal;
`;

const Title = styled.h3`
  padding-top: 2rem;
  font-size: 2.2rem;
`;

const SubTitle = styled.h4`
  padding-right: 1.5rem;
  font-size: 1.6rem;
`;

const ItemSelectedStyles = css`
  color: ${COLORS.white};
  background: ${COLORS.black};
`;

const Cover = styled.div`
  line-height: 0;
  text-align: center;
  width: 100%;
  // background-color: ${COLORS.black};
  position: relative;
`;

const StyledPicture = styled(Picture)`
  margin: auto;
  max-width: 1000px;
  width: 100%;
`;

const SeasonNavigation = styled.div`
  padding-top: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Item = styled.button`
  border: 1px solid black;
  height: 40px;
  width: 40px;
  background: ${COLORS.white};
  display: flex;
  margin: 0.4rem;
  justify-content: center;
  align-items: center;
  transition: ease all 0.3s;
  cursor: pointer;

  &:hover {
    ${ItemSelectedStyles};
  }
  ${({ selected }) => selected && ItemSelectedStyles};
`;

export default class DetailShow extends PureComponent {
  state = {
    selectedSeasonNumber: null
  };

  setSeasonNumber = selectedSeasonNumber => {
    this.setState({ selectedSeasonNumber });
  };

  render() {
    const { baseLink, data } = this.props;
    const { selectedSeasonNumber } = this.state;
    const { seasons, informations } = data.detail;

    const currentSeason = selectedSeasonNumber
      ? seasons.find(season => season.seasonNumber === selectedSeasonNumber)
      : seasons[0];

    return (
      <Wrapper>
        <Cover>
          <StyledPicture url={informations.URLImage} width={1000} />
        </Cover>
        <Content>
          <Title>{data.currentPage.displayName}</Title>
          <Summary>{informations.summary}</Summary>
          {seasons.length > 1 && (
            <SeasonNavigation>
              <SubTitle>Saisons</SubTitle>
              {seasons.map(season => (
                <Item
                  key={season.seasonNumber}
                  selected={currentSeason.seasonNumber === season.seasonNumber}
                  onClick={() => this.setSeasonNumber(season.seasonNumber)}
                >
                  {season.seasonNumber}
                </Item>
              ))}
            </SeasonNavigation>
          )}

          <HodorChildLoader
            key={currentSeason.seasonNumber}
            baseLink={baseLink}
            fetchUrl={currentSeason.onClick.path}
          >
            <DetailShowEpisodesList data={data} />
          </HodorChildLoader>
        </Content>
      </Wrapper>
    );
  }
}
