import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Button from 'shared/components/presentational/Button';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { COLORS } from 'shared/constants/theme';

const Wrapper = styled.div`
  display: none;
  position: absolute;

  ${mediaQueries.toTablet`
      display: block
  `};
`;

const StyledButton = styled(Button)<{ isOpen: boolean }>`
  .bigmac {
    display: ${props => (props.isOpen ? 'none' : 'block')};
  }
  .close {
    display: ${props => (props.isOpen ? 'block' : 'none')};
    margin-left: 0px;
  }
`;

const buttonStyle = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  color: COLORS.white,
  textDecoration: 'none'
};

const theme = {
  button: {
    padding: '0 0 0 0',
    normal: buttonStyle,
    hover: buttonStyle,
    disabled: buttonStyle
  }
};

interface Props {
  onClick?: () => void;
  isOpen: boolean;
}

export default class BigMac extends PureComponent<Props> {
  public render(): React.ReactNode {
    const { onClick, isOpen } = this.props;

    return (
      <Wrapper>
        <StyledButton onClick={onClick} theme={theme} isOpen={isOpen}>
          <Button.children.SvgIcon
            className="bigmac"
            xlink="/sprite.svg#cos-svg-bigmac"
            width="22"
            height="22"
          />
          <Button.children.SvgIcon
            className="close"
            width="20"
            height="20"
            xlink="/sprite.svg#cos-svg-close"
          />
        </StyledButton>
      </Wrapper>
    );
  }
}
