/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import { Content, FormattedProps } from './types';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { PropsFormatterUtils } from 'shared/types';
import { COLORS } from 'shared/constants/theme';
import { Offer } from '../shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: get(content, 'title.label', ''),
  titleColor: get(content, 'title.color', ''),
  titleHtmlTag: get(content, 'title.htmlTag'),
  borderHoverColor: get(content, 'hoverColor', COLORS.cerulean),
  offers: get(content, 'offers').map((offer: Offer) => ({
    bannerURLByDevice: {
      desktop: get(offer, 'desktopBanner.link.href'),
      tablet: get(offer, 'tabletBanner.link.href'),
      mobile: get(offer, 'mobileBanner.link.href')
    },
    title: get(offer, 'title'),
    titleColor: get(offer, 'titleColor'),
    subtitle: get(offer, 'subtitle'),
    channels: get(offer, 'channels').map(channel => ({
      imageAlt: get(channel, 'title'),
      imageUrl: get(channel, 'media.link.href')
    })),
    channelsDescription: get(offer, 'channelsDescription', ''),
    decoder: {
      imageAlt: get(offer, 'decoder.title'),
      imageUrl: get(offer, 'decoder.media.link.href')
    },
    options: get(offer, 'options').map(option => ({
      id: get(option, 'duration'),
      code: get(option, 'code'),
      description: get(option, 'description'),
      marketingPriceInteger: get(option, 'marketingPriceInteger'),
      marketingPriceExponent: get(option, 'marketingPriceExponent'),
      marketingPriceTerms: get(option, 'marketingPriceTerms'),
      strikeThroughPrice: get(option, 'strikeThroughPrice')
    })),
    minorOffers: get(offer, 'minorOffers').map(minorOffer => ({
      code: get(minorOffer, 'code'),
      description: get(minorOffer, 'description'),
      price: get(minorOffer, 'price'),
      marketingPrice: get(minorOffer, 'marketingPrice'),
      imageAlt: get(minorOffer, 'image.filename'),
      imageUrl: get(minorOffer, 'image.link.href')
    })),
    button: {
      label: get(offer, 'selectionButton.label', ''),
      theme: buttonThemeFactory({
        color: get(offer, 'selectionButton.color', COLORS.white),
        backgroundColor: get(
          content,
          'selectionButtonBackgroundColor',
          COLORS.amaranth
        ),
        hover: {
          backgroundColor: get(content, 'hoverColor')
        }
      }),
      link: getLinkHrefWithAuthentication(
        get(content, 'selectionButtonLink', '')
      )
    },
    moreInformation: {
      label: get(offer, 'moreInformationLinkLabel', ''),
      link: getLinkHrefWithAuthentication(get(offer, 'moreInformationLink', ''))
    }
  })),
  selectionOfferReturnButton: {
    label: get(content, 'selectionOfferReturnButtonLabel'),
    theme: buttonThemeFactory({
      color: get(content, 'selectionOfferReturnButtonColor', COLORS.white),
      backgroundColor: get(
        content,
        'selectionButtonBackgroundColor',
        COLORS.amaranth
      ),
      hover: {
        backgroundColor: get(content, 'hoverColor')
      }
    })
  },
  optionSelectionButton: {
    label: get(content, 'optionSelectionButtonLabel'),
    theme: buttonThemeFactory({
      color: get(content, 'optionSelectionButtonColor', COLORS.white),
      backgroundColor: get(
        content,
        'selectionButtonBackgroundColor',
        COLORS.amaranth
      ),
      hover: {
        backgroundColor: get(content, 'hoverColor')
      }
    })
  }
});
