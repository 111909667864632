import { Content, FormattedProps } from './types';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  ...content,
  broadcastingWay: content.broadcastingWay,
  title: content.title.label,
  titleColor: content.title.color,
  titleHtmlTag: content.title.htmlTag,
  subtitle: content.subtitle,
  previousButton: {
    label: content.previousButton.label,
    link: getLinkHrefWithAuthentication(content.previousButton.link ?? ''),
    theme: buttonThemeFactory({
      backgroundColor: content.previousButton.backgroundColor,
      color: content.previousButton.labelColor
    })
  },
  nextButton: {
    label: content.nextButton.label,
    link: getLinkHrefWithAuthentication(content.nextButton.link ?? ''),
    theme: buttonThemeFactory({
      backgroundColor: content.nextButton.backgroundColor,
      color: content.nextButton.labelColor
    })
  }
});
