/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Content, FormattedProps, FormattedApisProps } from './types';
import { COLORS } from 'shared/constants/theme';
import { PropsFormatterUtils } from 'shared/types';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';

export const getFormattedApis = (content: Content): FormattedApisProps => ({
  getUnpaidInfos: {
    url: content.apis?.getUnpaidInfo?.url ?? '',
    method: content.apis?.getUnpaidInfo?.method ?? ''
  },
  getSubscriberData: {
    url: content.apis?.getSubscriberData?.url ?? '',
    method: content.apis?.getSubscriberData?.method ?? 'GET',
    parameters: content.apis?.getSubscriberData?.parameters || {}
  }
});

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  apis: getFormattedApis(content),
  title: content.title ?? '',
  titleColor: content.titleColor ?? '',
  backgroundColor: content.backgroundColor ?? '',
  description: content.description ?? '',
  redirectButtonUrl: getLinkHrefWithAuthentication(
    content.buttons?.redirectButton?.link ?? ''
  ),
  redirectButtonLabel: content.buttons?.redirectButton?.label ?? '',
  redirectButtonTheme: buttonThemeFactory({
    backgroundColor:
      content.buttons?.redirectButton?.backgroundColor || COLORS.white,
    borderColor: 'transparent',
    color: content.buttons?.redirectButton?.labelColor || COLORS.black,
    textDecoration: 'none'
  }),
  contextCriteria: content.frontDisplayCriteria ?? null
});
