import { connect } from 'react-redux';

import { Button } from '../components/Button';
import { Authentication, SignButtonPropsFromState } from '../types';
import getCurrentZone from 'shared/modules/page/selectors/getCurrentZone';
import getPageData from 'shared/modules/page/selectors/getPageData';
import getSignupLink from 'shared/modules/page/helpers/getSignupLink';
import { Media, ThemeFactory } from 'shared/types';
import getLinkWithZone from 'shared/helpers/uri/getLinkWithZone';

const mapStateToProps = (
  state: any,
  ownProps: {
    authentication: Authentication;
    icon?: Media;
    buttonTheme: ThemeFactory;
    label: string;
    connected: boolean;
    link?: string;
  }
): SignButtonPropsFromState => {
  const bigram = getCurrentZone(state);
  const pageData = getPageData(state);
  const signupLink = getSignupLink(pageData);

  function getSignLink() {
    if (ownProps.connected) {
      return ownProps.link;
    } else if (
      !ownProps.connected &&
      ownProps.authentication.signup.isCustomLink
    ) {
      return ownProps.authentication.signup.link;
    }

    return getLinkWithZone(signupLink, bigram);
  }

  return {
    link: getSignLink()
  };
};

export default connect(mapStateToProps)(Button);
