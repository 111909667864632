/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';

import { BaseForm } from 'shared/types';
import { COLORS, TRANSITIONS, FONT_FAMILIES } from 'shared/constants/theme';
import usePropagateClick from 'shared/helpers/dom/usePropagateClick';
import pxInRem from 'shared/helpers/styled-components/remHelper';

const RadioLabel = styled.label<Partial<Props>>`
  display: flex;
  align-items: baseline;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  line-height: ${props => props.scale}rem;
  color: ${COLORS.boulderBis};
  font-size: ${pxInRem(18)};
  text-align: left;
`;

const RadioLabelText = styled.span`
  font-family: ${FONT_FAMILIES.base};
`;

const RadioWrapper = styled.button.attrs({ type: 'button' })<Partial<Props>>`
  position: relative;
  cursor: pointer;

  &:before {
    transition: transform 0.4s ${TRANSITIONS.bounce};
    transform: scale(0, 0);
    content: '';
    position: absolute;
    top: 0;
    left: ${props => (props.scale ? 0.19 * props.scale : 0.19)}rem;
    z-index: 1;
    width: ${props => (props.scale ? props.scale * 0.43 : 0.43)}rem;
    height: ${props => (props.scale ? props.scale * 0.43 : 0.43)}rem;
    background: ${props => (props.color ? props.color : COLORS.torchRed)};
    border-radius: 50%;
  }

  ${props =>
    props.checked &&
    `
    &:before {
      transform: scale(1, 1) translateY(70%);
    }

    & > ${RadioLabel} {
      color: ${COLORS.black};
    }
  `}

  &:hover {
    &:after {
      border: 2px solid
        ${props => (props.error ? COLORS.amaranth : COLORS.black)};
    }

    ${RadioLabel} {
      color: ${COLORS.black};
    }
  }

  &:focus {
    &:after {
      border: 2px solid ${COLORS.black};
    }

    ${RadioLabel} {
      color: ${COLORS.black};
    }
  }

  &:disabled {
    &:after {
      cursor: not-allowed;
      background-color: rgba(239, 239, 239, 0.3);
      border: 2px solid #ddd;
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: ${props => (props.scale ? props.scale * 0.87 : 1.74)}rem;
    height: ${props => (props.scale ? props.scale * 0.87 : 1.74)}rem;
    top: 0;
    left: -1px;
    transform: translateY(10%);
    background: ${COLORS.white};
    border: ${props =>
      props.error
        ? `2px solid ${COLORS.amaranth}`
        : props.checked
        ? `2px solid ${props.borderColor ?? COLORS.black}`
        : `1px solid ${props.borderColor ?? COLORS.alto}`};
    border-radius: 50%;
  }
`;

const RadioInput = styled.input.attrs({ type: 'radio' })<Partial<Props>>`
  margin-right: ${props => (props.scale ? 10 * props.scale * 1.2 : 10)}px;
  opacity: 0;
  &:disabled {
    cursor: not-allowed;
    background-color: rgba(239, 239, 239, 0.3);
  }
`;

const RequiredAsterisk = styled.span`
  font-family: ${FONT_FAMILIES.base};
`;

export interface Props extends BaseForm {
  label: string;
  value?: string;
  checked?: boolean;
  id?: string;
  disabled?: boolean;
  isRequired?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  scale?: number;
  color?: string;
  error?: boolean;
  borderColor?: string;
}

export default function Radio({
  className,
  name,
  onBlur: handleBlur,
  onChange: handleChange,
  value,
  checked,
  label,
  disabled,
  scale = 2,
  color,
  error,
  borderColor,
  isRequired = true
}: Props) {
  const {
    target: input,
    handleClick: propagateInputClick
  } = usePropagateClick<HTMLInputElement>();

  return (
    <RadioWrapper
      value={value}
      name={name}
      checked={checked}
      onClick={propagateInputClick}
      onBlur={handleBlur}
      disabled={disabled}
      scale={scale}
      color={color}
      borderColor={borderColor}
      role="radio"
      aria-checked={checked}
      error={error}
      className={className}
    >
      <RadioLabel scale={scale} disabled={disabled}>
        <RadioInput
          ref={input}
          value={value}
          name={name}
          checked={checked}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
          scale={scale}
          color={color}
          tabIndex={-1}
        />
        <RadioLabelText>
          {label}
          {isRequired && <RequiredAsterisk>*</RequiredAsterisk>}
        </RadioLabelText>
      </RadioLabel>
    </RadioWrapper>
  );
}
