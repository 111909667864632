const FETCH_VISUALS_RESET = 'FETCH_VISUALS_RESET';
const FETCH_VISUALS_SUCCEED = 'FETCH_VISUALS_SUCCEED';

export const ACTION_TYPES = {
  FETCH_VISUALS_RESET,
  FETCH_VISUALS_SUCCEED
};

export const TEMPLATES = {
  AUTOMATIC: 'automatic',
  SEMI_AUTOMATIC: 'semi-automatic',
  MANUAL: 'manual'
};
