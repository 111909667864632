/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, {
  ChangeEventHandler,
  FocusEvent,
  PureComponent,
  ReactNode
} from 'react';
import styled, { css } from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Radio from './Radio';

const RadioList = styled.ul<{
  horizontal?: boolean;
  phoneHorizontal?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => (props.phoneHorizontal ? 'row' : 'column')};

  ${props =>
    props.horizontal &&
    mediaQueries.fromPhablet`
      flex-direction: row;
  `}
`;

const RadioItem = styled.li<{ horizontal?: boolean }>`
    
  :not(:last-child) {
    ${props =>
      props.horizontal
        ? css`
            padding-right: 16px;
          `
        : css`
            margin-bottom: 12px;
          `}
          
`;

export interface Props {
  value: string;
  className?: string;
  name?: string | undefined;
  phoneHorizontal?: boolean;
  horizontal?: boolean;
  onFocus?: (event: FocusEvent<HTMLElement>) => void;
  onBlur?: (event: FocusEvent<HTMLElement>) => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  options: OptionType[];
  error?: string;
  isRequired?: boolean;
  disabled?: boolean;
}

interface OptionType {
  label: string;
  value: string;
}

export default class Radios extends PureComponent<Props> {
  public static readonly defaultProps = {
    horizontal: true
  };

  public render(): ReactNode {
    const {
      className,
      name,
      onBlur,
      onChange,
      value,
      options,
      phoneHorizontal,
      horizontal,
      error,
      isRequired,
      disabled
    } = this.props;

    return (
      <RadioList
        id={name}
        className={className}
        horizontal={horizontal}
        phoneHorizontal={phoneHorizontal}
      >
        {options.map(option => (
          <RadioItem key={option.value} horizontal={horizontal}>
            <Radio
              label={option.label}
              name={name}
              value={option.value}
              checked={value === option.value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!error}
              isRequired={isRequired}
              disabled={disabled}
            />
          </RadioItem>
        ))}
      </RadioList>
    );
  }
}
