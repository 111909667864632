import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { hasPageUnifiedMenu } from 'shared/modules/page/selectors/hasPageMenuUnified';
import getPageData from 'shared/modules/page/selectors/getPageData';
import getUrlParams from '../selectors/getUrlParams';
import FAQ from '../components/FAQ';
import pushURLHandler from 'shared/helpers/router/pushURLHandler';

const mapStateToProps = state => {
  const pageData = getPageData(state);
  const hasUnifiedMenu = hasPageUnifiedMenu(pageData);

  return {
    urlParams: getUrlParams(state),
    hasUnifiedMenu
  };
};

const mapDispatchToProps = {
  pushURLHandler
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(FAQ);
