import { Content, FormattedProps } from './types';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  broadcastingWay: content.broadcastingWay,
  title: content.title.label,
  titleColor: content.title.color,
  titleHtmlTag: content.title.htmlTag,
  subtitle: content.subtitle ?? undefined,
  button: {
    label: content.button.label,
    link: getLinkHrefWithAuthentication(content.button.link ?? ''),
    theme: buttonThemeFactory({
      backgroundColor: content.button.backgroundColor,
      color: content.button.labelColor
    })
  },
  apis: {
    getSubscriberData: {
      url: content.apis?.getSubscriberData?.url ?? '',
      method: content.apis?.getSubscriberData?.method || 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    }
  },
  contextCriteria: content.frontDisplayCriteria ?? null
});
