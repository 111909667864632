import React, { PureComponent, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import BaseLink from 'shared/components/presentational/CustomLink/CustomLink';
import Loader from './Loader';
import { Props as ButtonProps } from '../Button';
import styles from '../styles';

/* eslint-disable @typescript-eslint/no-unused-vars */
const Link = styled(({ fluid, ...props }: Props) => <BaseLink {...props} />)`
  ${styles};
`;

interface Props extends ButtonProps {
  visible: boolean;
  to: string;
  onClick: () => void;
}

export default class ButtonLink extends PureComponent<Props> {
  public render(): ReactNode {
    const { children, to, onClick, loading, disabled, ...props } = this.props;
    const mustBeDisabled = loading || disabled;

    return (
      <Link to={to} disabled={mustBeDisabled} onClick={onClick} {...props}>
        {React.Children.map(children, child =>
          React.cloneElement(child as ReactElement<Props>, {
            visible: !loading
          })
        )}
        {loading && <Loader />}
      </Link>
    );
  }
}
