import { StyledComponent } from 'styled-components';

/**
 * Add ellipsis to text after x lines if it overflows.
 * @param lineNumber
 */
export const ellipsis = (lineNumber = 1) => `
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${lineNumber};
  -webkit-box-orient: vertical;  
`;

/**
 * Disable top-margin of any first content and bottom-margin of any last content of the given styled-component.
 * Useful when given styled-component can not collapse margins with its first and last children.
 * @param styledComponent
 */
export const removeInnerContentVerticalMargin = (
  styledComponent: StyledComponent<any, any>
) => `
  ${styledComponent}{
    & > :first-child,
    & > :first-child > :first-child,
    & > :first-child > :first-child > :first-child {
      margin-top: 0;
    }
    & > :last-child,
    & > :last-child > :last-child,
    & > :last-child > :last-child > :last-child {
      margin-bottom: 0;
    }
  }
`;
