import React, { FC } from 'react';

import { Minor } from '../../types';
import { Wrapper, Label, Description, WrapperMinor } from './styles/components';

export const Minors: FC<{ minors: Minor[] | null }> = ({ minors }) => {
  return (
    <Wrapper>
      {minors &&
        minors.map(minor => (
          <WrapperMinor key={minor.code}>
            <Label>{minor.label}</Label>
            <Description>{minor.definition}</Description>
          </WrapperMinor>
        ))}
    </Wrapper>
  );
};
