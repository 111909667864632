// ICONS keys are matching api menu.id
export const ICONS = {
  guidetv: 'cos-svg-eye',
  livetv: 'cos-svg-tv',
  alademande: 'cos-svg-play'
};

export const Z_INDEX = {
  NAVIGATION_MOBILE: 300,
  HEADER: 400
};
