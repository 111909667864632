import styled from 'styled-components';

import BaseModal, {
  ModalCloseButton,
  StyledButton
} from 'shared/components/presentational/Modal/BaseModal';
import { FONT_WEIGHTS } from 'shared/constants/theme';
import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

export const Commitment = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
`;

export const Link = styled(CustomLink)`
  font-weight: ${FONT_WEIGHTS.bold};
  color: var(--primary-default);
  cursor: pointer;

  &:hover {
    color: var(--primary-hover);
  }

  &:active {
    color: var(--primary-pressed);
  }
`;

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  align-items: flex-start;

  ${mediaQueries.fromTablet`
    align-items: flex-end;
  `};
`;

export const Propal = styled.div`
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-l) * 2);
  flex: 1;

  ${mediaQueries.fromTablet`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  `}
`;

export const Radio = styled.div`
  color: var(--surface-dark);
`;

export const Wrapper = styled.div<{ isSelected?: boolean }>`
  background: var(--surface-background);
  display: flex;
  gap: var(--spacing-s);
  align-items: center;
  border: ${({ isSelected }) =>
    isSelected
      ? '1px solid var(--surface-dark)'
      : '1px solid var(--surface-default)'};
  border-radius: 2px;
  padding: var(--spacing-xl);

  ${mediaQueries.fromTablet`
    &:hover {
      background: var(--surface-hover);
    }

    gap: var(--spacing-xl);
  `}
`;

export const Modal = styled(BaseModal)`
  background-color: var(--surface-background);
  max-width: 800px;

  & ${ModalCloseButton} {
    background-color: var(--surface-background);
    margin: var(--spacing-s);
  }
  & ${StyledButton} {
    background-color: var(--surface-default);
    border-radius: 50%;
    height: 30px;
    width: 30px;
    padding: 0;
  }
`;
