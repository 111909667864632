/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { getLinkHref } from 'shared/helpers/uri';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { COLORS } from 'shared/constants/theme';
import { Content, FormattedEvent, FormattedProps, EVENT_TYPE } from './types';
import { PropsFormatterUtils } from 'shared/types';

export const getFormattedApis = (content: Content): FormattedProps['apis'] => ({
  getAvailableEvents: {
    url: content.apis?.['old_selfcare.get_available_events']?.url ?? '',
    method: content.apis?.['old_selfcare.get_available_events']?.method || 'GET'
  },
  selectObjects: {
    url: content.apis?.['old_selfcare.select_objects']?.url ?? '',
    method: content.apis?.['old_selfcare.select_objects']?.method || 'GET'
  },
  selectEvent: {
    url: content.apis?.['old_selfcare.select_event']?.url ?? '',
    method: content.apis?.['old_selfcare.select_event']?.method || 'GET'
  },
  bookEventObjects: {
    url: content.apis?.['old_selfcare.book_event_objects']?.url ?? '',
    method: content.apis?.['old_selfcare.book_event_objects']?.method || 'PUT'
  },
  allmolIframeUrl: content.apis?.allmol_iframe?.url ?? '',
  allmolQuotas: {
    url: content.apis?.allmol_quotas?.url ?? '',
    method: content.apis?.allmol_quotas?.method || 'GET'
  },
  getSubscriberData: {
    url: content.apis?.getSubscriberData?.url ?? '',
    method: content.apis?.getSubscriberData?.method || 'GET',
    parameters: content.apis?.getSubscriberData?.parameters || {}
  }
});

export const getFormattedEvents = (content: Content): FormattedEvent[] =>
  content.events.map(event => ({
    label: '',
    code: event.code ?? '',
    description: event.description ?? '',
    type: event.type || EVENT_TYPE.CLASSIC,
    imageUrl: getLinkHref(event.media?.link ?? ''),
    imageAlt: event.media?.filename ?? ''
  }));

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  apis: getFormattedApis(content),
  confirmationButton: {
    label: content.buttons.finishButton.label ?? '',
    link: getLinkHrefWithAuthentication(content.buttons.finishButton.link ?? '')
  },
  cancelButton: {
    label: content.buttons.cancelButton.label ?? '',
    link: getLinkHrefWithAuthentication(content.buttons.cancelButton.link ?? '')
  },
  confirmationHtml: content.confirmationText ?? '',
  termsAndConditionsHtml: content.termsAndConditionsText ?? '',
  distributorsHtml: content.collectionPointsLabel ?? '',
  events: getFormattedEvents(content),
  buttonThemes: {
    submit: buttonThemeFactory({
      backgroundColor: content.buttonsBackgroundColor || COLORS.valencia,
      color: content.buttonsLabelColor || COLORS.white
    }),
    confirmation: buttonThemeFactory({
      backgroundColor:
        content.buttons?.finishButton?.backgroundColor || COLORS.valencia,
      color: content.buttons?.finishButton?.labelColor || COLORS.white
    }),
    cancel: buttonThemeFactory({
      backgroundColor: 'transparent',
      color: content.buttons?.cancelButton?.labelColor || '#000',
      textDecoration: 'underline',
      padding: '0 0 0 0'
    })
  }
});
