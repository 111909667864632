import { createSelector } from 'reselect';

import { getCurrentPage } from './';
import { getFilteredShops } from './../../shared/selectors';
import { PER_PAGE } from '../constants';

export default createSelector(
  [getFilteredShops, getCurrentPage],
  (shops, currentPage) => {
    const start = PER_PAGE * (currentPage - 1);
    const expectedEnd = PER_PAGE * currentPage;
    const end = Math.min(shops.length, expectedEnd);
    return shops.slice(start, end);
  }
);
