/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { BREAKPOINTS } from 'shared/components/presentational/Picture/constants';
import { Content, FormattedProps } from './types';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { getLinkHref } from 'shared/helpers/uri';
import { COLORS } from 'shared/constants/theme';

export default (content: Content): FormattedProps => {
  const emptyListIconDesktop = getLinkHref(
    content.empty_list.icon.desktopMedia.link
  );
  const emptyListIconTablet = content.empty_list.icon.tabletMedia?.link
    ? getLinkHref(content.empty_list.icon.tabletMedia.link)
    : emptyListIconDesktop;

  const emptyListIconPhone = content.empty_list.icon.mobileMedia?.link
    ? getLinkHref(content.empty_list.icon.mobileMedia.link)
    : emptyListIconDesktop;

  const consultationIconDesktop = content.medias.consultation.desktopMedia
    ? getLinkHref(content.medias.consultation.desktopMedia?.link)
    : undefined;

  const consultationIconTablet = content.medias.consultation.tabletMedia?.link
    ? getLinkHref(content.medias.consultation.tabletMedia.link)
    : consultationIconDesktop;

  const consultationIconPhone = content.medias.consultation.mobileMedia?.link
    ? getLinkHref(content.medias.consultation.mobileMedia.link)
    : consultationIconDesktop;

  return {
    title: {
      titleLabel: content.title.label,
      titleColor: content.title.color,
      titleHtmlTag: content.title.htmlTag,
      subtitle: content.subtitle ?? undefined
    },
    emptyList: {
      label: content.empty_list.label,
      color: content.empty_list.color,
      icon: {
        desktop: {
          url: emptyListIconDesktop,
          w: BREAKPOINTS.DESKTOP + 1
        },
        tablet: {
          url: emptyListIconTablet,
          w: BREAKPOINTS.DESKTOP
        },
        phone: {
          url: emptyListIconPhone,
          w: BREAKPOINTS.TABLET
        },
        phoneMini: {
          url: emptyListIconPhone,
          w: BREAKPOINTS.PHONE
        }
      }
    },
    consultationMedia: {
      desktop: {
        url: consultationIconDesktop,
        w: BREAKPOINTS.DESKTOP + 1
      },
      tablet: {
        url: consultationIconTablet,
        w: BREAKPOINTS.DESKTOP
      },
      phone: {
        url: consultationIconPhone,
        w: BREAKPOINTS.TABLET
      },
      phoneMini: {
        url: consultationIconPhone,
        w: BREAKPOINTS.PHONE
      }
    },
    loadMoreButton: {
      label: content.pagination.label,
      theme: buttonThemeFactory({
        color: content.pagination.color ?? COLORS.black,
        backgroundColor: 'transparent',
        borderColor: 'none',
        textDecoration: 'underline'
      })
    },
    endListButton: {
      label: content.endList.label,
      theme: buttonThemeFactory({
        color: content.endList.color ?? COLORS.black,
        backgroundColor: 'transparent',
        borderColor: 'none',
        textDecoration: 'none'
      })
    },
    apis: {
      getSubscriberData: {
        url: content.apis?.getSubscriberData?.url ?? '',
        method: content.apis?.getSubscriberData?.method || 'GET',
        parameters: content.apis?.getSubscriberData?.parameters || {}
      },
      CGA_get_documents: {
        url: content.apis?.CGA_get_documents?.url ?? '',
        method: content.apis?.CGA_get_documents?.method || 'GET'
      },
      CGA_documents: {
        url: content.apis?.CGA_documents?.url ?? '',
        method: content.apis?.CGA_documents?.method || 'GET'
      },
      contract_invoice_list: {
        url: content.apis?.contract_invoice_list?.url ?? '',
        method: content.apis?.contract_invoice_list?.method || 'GET'
      }
    },
    contextCriteria: content.frontDisplayCriteria ?? null
  };
};
