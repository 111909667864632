import { AnyAction } from 'redux';

import { TYPES as GEOLOCATION_TYPES } from 'shared/modules/geolocation/actions/index';
import { formatZoneFromPayload } from 'shared/helpers/zones';
import { TYPES } from './actions';

// -----------------------------------------------------------------------------
// EXPORTED REDUCER STATE TYPE

// -----------------------------------------------------------------------------
// PRIVATES

const defaultState = {
  selectedZone: null
};

// -----------------------------------------------------------------------------
// REDUCER

export default function modalGeolocation(
  state = defaultState,
  action: AnyAction
) {
  switch (action.type) {
    case TYPES.SELECT_ZONE:
    case TYPES.VALIDATE_ZONE_SELECTION:
      return {
        ...state,
        selectedZone: action.payload
      };

    case TYPES.RESET_ZONE_SELECTION:
      return {
        ...state,
        selectedZone: null
      };

    case GEOLOCATION_TYPES.FETCH_ZONE.SUCCESS:
      return {
        ...state,
        selectedZone: formatZoneFromPayload(action.payload)
      };

    default:
      return state;
  }
}
