import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 28 36" width={width} height={height} className={className}>
    <path
      d="M26.6.8H1.4c-.5 0-1 .4-1 1v32.5c0 .5.4 1 1 1h18.4c.3 0 .5-.1.7-.3l6.7-7.6c.2-.2.2-.4.2-.6v-25c.2-.6-.3-1-.8-1zm-5.8 30.9v-4.1h3.6l-3.6 4.1zm4.8-6h-5.8c-.5 0-1 .4-1 1v6.7H2.4V2.7h23.2v23zM6.9 19.6h14.3c.5 0 1-.4 1-1s-.4-1-1-1H6.9c-.5 0-1 .4-1 1s.4 1 1 1zm0-5.3h14.3c.5 0 1-.4 1-1s-.4-1-1-1H6.9c-.5 0-1 .4-1 1s.4 1 1 1zm0-5.3h14.3c.5 0 1-.4 1-1s-.4-1-1-1H6.9c-.5 0-1 .4-1 1s.4 1 1 1z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
