import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Step from './Step';
import Path from './Path';
import { ComponentProps } from 'shared/blocks/tabs/type';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

const Container = styled.section`
  position: relative;
  width: 100%;
  max-width: calc(1000px - 220px); /* 110px of padding */
  margin: auto;
  height: 65px;

  ${mediaQueries.toTablet`
    max-width: calc(100% - 100px);
    margin-left: 50px;
    margin-right: 50px;
  `};
`;

const Steps = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export default class ProgressTabs extends PureComponent<
  Pick<ComponentProps, 'tabs' | 'pushURLHandler' | 'progressionColor'>
> {
  public render(): React.ReactNode {
    const { tabs, pushURLHandler: handleClick, progressionColor } = this.props;

    return (
      <Container>
        <Path tabs={tabs} color={progressionColor} />
        <Steps>
          {tabs.map((tab, index) => (
            <Step
              key={`Step_${index}`}
              tab={tab}
              onClick={handleClick}
              color={progressionColor}
            >
              {index + 1}
            </Step>
          ))}
        </Steps>
      </Container>
    );
  }
}
