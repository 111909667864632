import { css } from 'styled-components';

import { COLORS } from 'shared/constants/theme';

interface Props {
  value?: string | number;
  focused?: boolean;
}

export default (props: any) => css<Props>`
  outline: none;
  border-radius: var(--border-radius-xs);
  border: 1px solid ${COLORS.alto};

  :focus {
    border: 1px solid ${COLORS.dodgerBlue};
    box-shadow: 0 0 2px 2px ${COLORS.frenchPass};
  }

  ${(props.focused || props.value) &&
  css`
    border: 1px solid ${COLORS.black};
    box-shadow: 0 0 0 1px ${COLORS.black};
  `};

  ${props.error &&
  css`
    border: 1px solid ${COLORS.torchRed};
    box-shadow: none;
  `};
`;
