import React from 'react';

import { ResponsiveHocProps } from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';
import { FormattedArticleContent } from '../types';
import fragmentsToComponents from '../fragmentsToComponents';
import {
  Container,
  InnerModalContainer,
  MainImage,
  Subtitle,
  Title
} from '../styles/components';

interface Props {
  article: FormattedArticleContent;
  displayAsModal?: boolean;
}

const ArticleContent = ({
  article,
  displayAsModal = false,
  mediaQueries
}: Props & Pick<ResponsiveHocProps, 'mediaQueries'>) => {
  let imageUrl = article.mainMedia;

  if (mediaQueries.toPhablet && article.responsiveMedia) {
    imageUrl = article.responsiveMedia;
  }

  const ContainerComponent = displayAsModal ? InnerModalContainer : Container;

  return (
    <>
      <MainImage url={imageUrl} />
      <ContainerComponent>
        <Title>{article.title}</Title>
        <Subtitle>{article.subtitle}</Subtitle>
        {fragmentsToComponents(article.fragments)}
      </ContainerComponent>
    </>
  );
};

export default ArticleContent;
