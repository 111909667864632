import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { PropsFormatterUtils } from 'shared/types';
import { TEMPLATES } from './constants';
import { COLORS } from 'shared/constants/theme';
import { Content, FormattedProps } from './types';

export default (
  content: Content,

  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  banner: {
    imageUrl: content.media.link.href,
    imageAlt: content.media.description ?? '',
    text: content.text,
    backgroundColor: content.backgroundColor,
    buttonText: content.button.label,
    buttonUrl: getLinkHrefWithAuthentication(content.button.link),
    buttonTheme: themeFactory({
      color: content.button.labelColor,
      backgroundColor: content.button.buttonColor
    })
  },
  template: content.template ?? TEMPLATES.default,
  meaTitle: content.meaTitle?.label ?? '',
  meaTitleColor: content.meaTitle?.color ?? COLORS.black,
  meaTitleHtmlTag: content.meaTitle?.htmlTag,
  meaSubtitle: content.meaSubTitle ?? '',
  meaUrl: getLinkHrefWithAuthentication(content.meaLink ?? '')
});
