import get from 'lodash/get';

import getContextualConfig from './getContextualConfig';

/**
 * Config object
 *
 * Provides a consistent access to application config
 */
export default {
  /**
   * Return the whole public config
   *
   * @returns {object}
   */
  getPublicConfig() {
    return getContextualConfig();
  },

  /**
   * Return the value of the given server config key
   *
   * @param {string} key
   * @returns {any}
   */
  getServerConfig(key) {
    return getContextualConfig(key);
  },

  /**
   * Retrieve client config value for the given key
   *
   * @param {string} key
   * @returns {any}
   */
  getClientConfig(key) {
    return get(this.getPublicConfig(), key, null);
  }
};
