import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';

import pxInRem from 'shared/helpers/styled-components/remHelper';
import Link from '../Link';
import { BaseForm } from 'shared/types';

const Wrapper = styled.div`
  text-align: right;
  font-size: ${pxInRem(12)};
`;

export interface Props extends BaseForm {
  text: string;
  to?: string;
  onClick?: () => void;
}

export default class Help extends PureComponent<Props> {
  public render(): ReactNode {
    const { onClick, to, text, className } = this.props;
    return (
      <Wrapper className={className}>
        <Link onClick={onClick} to={to}>
          {text}
        </Link>
      </Wrapper>
    );
  }
}
