/* eslint-disable react/prop-types */
import styled, { css } from 'styled-components';

import Button from 'shared/components/presentational/Button';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import remHelper from 'shared/helpers/styled-components/remHelper';
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

export const SelectorStyle = css`
  .Select {
    &-menu-outer {
      top: auto;
      bottom: 100%;
      max-height: 220px;
      padding: 10px 10px 10px 0;
      border: 0;
      border-radius: 0;
      box-shadow: 0 -10px 10px 0 rgba(0, 0, 0, 0.05),
        -10px -10px 10px 0 rgba(0, 0, 0, 0.05),
        10px -10px 10px 0 rgba(0, 0, 0, 0.05);

      .Select-option {
        padding-right: 10px;
      }
    }

    &-value {
      padding-right: 20px !important;
    }

    &-value-label {
      svg {
        height: 34px !important;
      }

      .Footer__zoneItemLabel {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-menu {
      .Footer__zoneItem {
        justify-content: flex-start;
      }

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 5px;
      }
    }
  }
`;

export const StyledFooter = styled.footer<{
  backgroundColor: string;
  textColor: string;
}>`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
`;

export const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 55px 80px;

  @media (max-width: 550px) {
    padding: 20px 10px;
  }
`;

export const Columns = styled.div`
  display: flex;
  justify-content: space-around;

  ${mediaQueries.toTablet`
    flex-wrap: wrap;
    justify-content: space-between;
  `};
`;

export const buttonTheme = (props: { textColor: string; hoverColor: string }) =>
  themeFactory({
    color: props.textColor,
    backgroundColor: 'transparent',
    textDecoration: 'none',
    padding: 0,
    hover: {
      color: props.hoverColor
    }
  });

export const StyledText = styled(Button.children.Text)`
  text-decoration: none;
  font-family: ${FONT_FAMILIES.base};
  font-weight: ${FONT_WEIGHTS.normal};

  @media (max-width: 550px) {
    font-size: ${remHelper(11)};
    line-height: ${remHelper(20)};
  }
`;

export const LinkColumn = styled.div`
  ${mediaQueries.toPhablet`
    width: 33%;
    padding: 0 10px;
  `};
`;

export const NavItem = styled.li`
  ${mediaQueries.toPhablet`
    &:not(:first-child) {
      margin-top: 20px;
    }
  `};
`;

export const StyledAside = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;

  ${mediaQueries.toTablet`
    flex-direction: row;
    flex-basis: 100%;
    margin-top: 70px;
    `} @media (max-width: 550px) {
    margin-top: 35px;
  }
`;

export const SocialNetworks = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: ${remHelper(12)};

  ${mediaQueries.toTablet`
    margin-left: -4px;
  `};
`;

export const SocialButton = styled(Button)`
  padding: 0;
  &:not(:first-child) {
    margin-left: 15px;
  }
`;

export const StyledButton = styled(Button)`
  font-size: ${remHelper(12)};
  padding: 0;
  line-height: ${remHelper(13.8)};
  text-align: left;
`;

export const StyledSelectField = styled.div`
  width: 200px;
  margin: 0 10px;

  .ReactSelect__control {
    cursor: pointer;
  }

  @media (max-width: 550px) {
    max-width: 200px;
    flex: 1;
  }
`;
