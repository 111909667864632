import { Cookies } from 'react-cookie';

import { COOKIES_MAX_AGE } from 'shared/constants/cookies';

const setCookie = (
  cookies: Cookies,
  name: string,
  value: string,
  options = {} // https://www.npmjs.com/package/universal-cookie#setname-value-options
) => {
  const defaultOptions = { maxAge: COOKIES_MAX_AGE, path: '/' };
  cookies.set(name, value, Object.assign(defaultOptions, options));
};

export default setCookie;
