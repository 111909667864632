import fetchWebservice from 'shared/blocks/vod/hodor/webservices/fetch';
import fetchVodPathError from './fetchVodPathError';
import fetchVodPathRequest from './fetchVodPathRequest';
import fetchVodPathSuccess from './fetchVodPathSuccess';
import shouldFetchVodPath from '../selectors/shouldFetchVodPath';
import getToken from '../selectors/getToken';
import getHomePath from '../selectors/getHomePath';
import isHomePath from '../helpers/isHomePath';

function fetch(pathApiUrlMask, token, path) {
  return async dispatch => {
    dispatch(fetchVodPathRequest(path));
    try {
      const data = await fetchWebservice(pathApiUrlMask, token, path);
      dispatch(fetchVodPathSuccess(path, data));
    } catch (error) {
      dispatch(fetchVodPathError(path, error));
    }
  };
}

export default (pathApiUrlMask, path) => async (dispatch, getState) => {
  const state = getState();
  const token = getToken(state);

  // do nothing if not authenticated
  if (!token) {
    return Promise.resolve();
  }

  const requestPath = isHomePath(path) ? getHomePath(state) : path;

  return shouldFetchVodPath(state, requestPath)
    ? fetch(pathApiUrlMask, token, requestPath)(dispatch)
    : Promise.resolve();
};
