import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import BaseSlider from 'shared/components/presentational/Slider/Slider';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);

  ${mediaQueries.fromTablet`
    gap: var(--spacing-xxl);
  `}
`;

export const CardContainer = styled.div`
  margin: 0 calc(var(--container-minimum-padding) * -1);
  ${mediaQueries.fromTablet`
  margin: 0 calc(var(--container-minimum-padding-tablet) * -1);
  `}
`;

export const CardsWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  padding: var(--spacing-xs);

  ${mediaQueries.fromTablet`
    padding: var(--spacing-xs) calc(var(--container-minimum-padding-tablet) - var(--spacing-xs));
  `}
`;

export const Slider = styled(BaseSlider)`
  ${mediaQueries.fromTablet`
  margin: 0 calc(var(--spacing-xs) * -1);
  `}
`;
