export const DEFAULT_URLS_PARAMS = {
  portailId: 'smxaCxkyJMI.',
  media: 'PC',
  vect: 'INTERNET'
};

export const DEFAULT_URLS_PARAMS_V2 = {
  ...DEFAULT_URLS_PARAMS,
  portailId: 'aPPvUF1Ui80.'
};

export const CANAL_PLUS_URL = 'https://www.canalplus.com';
export const CANAL_PLUS_REGEXP =
  '(:?^)https:\\/\\/([a-z0-9]+\\.)*canalplus\\.com';
