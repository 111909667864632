import { FormattedOffer, StateOffer } from '../types';

export default function getSelectedOfferAndOption(
  offers: FormattedOffer[],
  selectedOptionCode?: string
): StateOffer | undefined | false {
  if (!selectedOptionCode) {
    return;
  }

  let selectedOption;
  const selectedOffer = offers.find(offer => {
    return offer.options.find(option => {
      if (option.code === selectedOptionCode) {
        selectedOption = option;
        return selectedOption;
      } else {
        return;
      }
    });
  });

  if (!selectedOffer || !selectedOption) {
    return false;
  }

  return {
    ...selectedOffer,
    option: selectedOption
  };
}
