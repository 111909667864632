import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Raw from 'shared/components/presentational/Raw/Raw';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

const Wrapper = styled.section`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  ${mediaQueries.toTablet`
      max-width: 100%;
      padding: 0 15px;
  `};
`;

export default class Text extends PureComponent {
  render() {
    const { text } = this.props;
    return (
      <Wrapper>
        <Raw html={text} />
      </Wrapper>
    );
  }
}
