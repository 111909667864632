import { push } from 'redux-first-history';

import { TASK_TYPE } from 'shared/modules/tasks/constants';
import getBlocksByNamespaces from 'shared/modules/page/selectors/getBlocksByNamespaces';
import setCurrentPath from 'shared/blocks/vod/hodor/actions/setCurrentPath';
import { namespace } from './index';
import propsFormatter from './propsFormatter';
import fetchVodPathIfNeeded from './actions/fetchVodPathIfNeeded';
import authenticateIfNeeded from './actions/authenticateIfNeeded';
import getHomePath from './selectors/getHomePath';
import setBaseLink from './actions/setBaseLink';
import createToken from 'shared/modules/page/webservices/createToken';
import formatCookie from 'shared/blocks/authentication/helpers/formatCookies';
import getCreateToken from 'shared/modules/page/selectors/apis/getCreateToken';
import clientCookies from 'client/cookies';
import setCookies from 'shared/helpers/universal-cookie/setCookies';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import getPageData from 'shared/modules/page/selectors/getPageData';
import getPagePath from 'shared/modules/page/selectors/getPagePath';
import { getDomainURL } from 'shared/modules/hostname/selectors/getDomainURL';
import createLoginRedirectUrl from 'shared/modules/page/helpers/createLoginRedirectUrl';
import isLoginLinkExternal from 'shared/modules/page/selectors/authentication/isLoginLinkExternal';

export default ({ dispatch, getState }) => [
  {
    pattern: '/:bigram([a-zA-Z]{2})?/vod/mycanal/:path*',
    [TASK_TYPE.DEFERRED_DATA]: async params => {
      const { bigram, path } = params;
      // Check if vod block exits in page
      const blocks = getBlocksByNamespaces(getState(), [namespace]);
      if (blocks.length) {
        // Grab the first one since we assume that
        // we can only have one vod block per page
        const block = blocks[0];
        const { authenticateApiUrl, pathApiUrlMask } = propsFormatter(
          block.content
        );
        const apiCreateToken = getCreateToken(getState());

        const {
          AuthOTTTokenV2,
          isAuthenticated,
          microEligibility,
          macroEligibility,
          offerZone,
          isoCountryCode
        } = clientCookies.getAll();

        if (!AuthOTTTokenV2) {
          // Redirect to login url if silent authentication failed
          const state = getState();
          const zone = clientCookies.get(COOKIE_KEYS.ZONE);
          const pageData = getPageData(state);
          const pagePath = getPagePath(state);
          const domainURL = getDomainURL(state);

          const loginRedirectUrl = createLoginRedirectUrl(
            domainURL,
            zone,
            pageData,
            pagePath,
            null
          );
          dispatch(push(loginRedirectUrl));
        } else {
          // All cookies necessary for Hodor authentication
          if (
            !isAuthenticated ||
            !microEligibility ||
            !macroEligibility ||
            !offerZone ||
            !isoCountryCode
          ) {
            const authenticationIsExternal = isLoginLinkExternal(getState());
            // Fetch a new token with user data to set on the cookies
            const {
              data: { response }
            } = await createToken(
              apiCreateToken.url,
              apiCreateToken.method,
              authenticationIsExternal
            );

            const cookiesToSet = formatCookie(response);
            setCookies(clientCookies, cookiesToSet);
          }
          // Get the token that allow use to fetch Hodor contents
          await authenticateIfNeeded(authenticateApiUrl)(dispatch, getState);

          // Default content path
          let url = getHomePath(getState());

          if (path) {
            url = `/${path}`;
          }

          // We will use this baseLink to build the internal navigation
          const baseLink = bigram ? `/${bigram}/vod/mycanal` : `/vod/mycanal`;

          dispatch(setBaseLink(baseLink));
          dispatch(setCurrentPath(url));

          // Fetch main content
          await fetchVodPathIfNeeded(pathApiUrlMask, url)(dispatch, getState);
        }
      }
    }
  }
];
