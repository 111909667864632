export const ACTION_TYPES = {
  SET_COOKIE: '@COOKIES/SET_COOKIE'
};

export const COOKIE_KEYS = {
  ZONE: 'isoCountryCode',
  LANG: 'lang',
  EXTERNAL_AUTH: 'authenticationIsExternal',
  TOKEN_PASS: 'AuthOTTTokenV2',
  NUM_SUBSCRIBER: 'numSubscriber',
  OFFER_ZONE: 'offerZone',
  SUBSCRIBER_ID: 'subscriberId'
};
