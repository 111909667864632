import scrollTo from 'shared/modules/page/actions/scrollTo';
import { ACTION_TYPES } from 'shared/modules/page/constants';

export default store => next => action => {
  const result = next(action);

  if (action.type === ACTION_TYPES.FETCH_SUCCESS) {
    const isTvGuide = action.payload.path.includes('/guide-tv-');
    if (!isTvGuide) {
      store.dispatch(scrollTo(0, 0));
    }
  }

  return result;
};
