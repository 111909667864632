import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg
    viewBox="0 0 229 228"
    width={width}
    height={height}
    className={className}
  >
    <g fillRule="nonzero" fill="none">
      <circle fill="currentColor" cx={115} cy={114} r={113.4} />
      <path
        fill="#FFF"
        d="M45.4 110.8v23.9H.4v-23.9h45zM92.4 46.5v135.7H66.3V69.9h-13V46.5zM132.5 108.8C120.3 104.7 114 93 114 80.2c0-20 13.3-35.8 34-35.8 20.5 0 33.8 15.8 33.8 35.8 0 12.8-6.1 24.3-18.5 28.8v.2c16.9 5.4 23.6 18.9 23.6 36 0 22.3-16.2 39.2-38.9 39.2s-39.1-16.9-39.1-39.2c0-17.5 6.7-30.4 23.6-36v-.4zm4 33c0 7 2 19.3 11.3 19.3 9.2 0 11.5-12.6 11.5-19.3 0-6.7-2.9-19.6-11.7-19.6-9.2-.1-11.1 12.5-11.1 19.6zm10.9-46.7c8.3 0 8.3-10.3 8.3-15.3 0-4.9-.9-14.4-8.3-14.4-6.5 0-7.4 9.4-7.4 14.2.1 5.3.8 15.5 7.4 15.5z"
      />
    </g>
  </svg>
);
