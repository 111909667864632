import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import Slider from 'react-slick';

import Raw from 'shared/components/presentational/Raw/Raw';
import Arrow from 'shared/components/presentational/Slider/Arrow';
import breakpoints from 'shared/constants/breakpoints';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxToRem from 'shared/helpers/styled-components/remHelper';
import Decoder from './Decoder';

const ArrowStyled = css`
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.6));
  z-index: 2;
  top: 50%;
  right: 0.625rem;
  width: 30px;
  height: 50px;
  display: ${props =>
    props.className.includes('slick-disabled') ? 'none' : 'block'} !important;
`;

const LeftArrow = styled(Arrow)`
  ${ArrowStyled};
  left: 10px;
`;

const RightArrow = styled(Arrow)`
  ${ArrowStyled};
  right: 10px;
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  user-select: none;
  max-width: 1000px;
  margin: 0 auto;

  ${mediaQueries.toTablet`
    padding: 0 10px;
  `};
`;

const Title = styled(Raw)`
  text-transform: uppercase;
  line-height: ${pxToRem(30)};
  font-size: ${pxToRem(22)};
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  margin: 0 auto 20px;
`;

const Subtitle = styled(Raw)`
  margin: 0 auto 20px;
  text-align: center;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(20)};
`;

const StyledDecoderWrapper = styled.div`
  padding: 0 3px;
  margin: 20px auto;
  max-width: 1000px;
`;

const StyledDecoder = styled(Decoder)`
  margin: auto;
`;

class Decoders extends PureComponent {
  // This is a hack for slick to prevent
  // error in width calculation
  componentDidUpdate() {
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    const { title, subtitle, decoders } = this.props;
    const sliderSettings = {
      nextArrow: <RightArrow name="ArrowRight" color={COLORS.white} />,
      prevArrow: <LeftArrow name="ArrowLeft" color={COLORS.white} />,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: breakpoints.phablet,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '15px'
          }
        }
      ]
    };

    sliderSettings.slidesToShow = decoders.length === 1 ? 1 : 2;
    sliderSettings.slidesToScroll = decoders.length === 1 ? 1 : 2;

    return (
      <Container>
        {title && <Title html={title} />}
        {subtitle && <Subtitle html={subtitle} />}

        <Slider {...sliderSettings}>
          {decoders.map((decoder, index) => (
            <StyledDecoderWrapper key={`Decoders__decoder${index}`}>
              <StyledDecoder decoder={decoder} />
            </StyledDecoderWrapper>
          ))}
        </Slider>
      </Container>
    );
  }
}

export default Decoders;
