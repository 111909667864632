/* eslint-disable react/prop-types */
/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Link from 'shared/components/presentational/CustomLink/CustomLink';
import HorizontalSliderMenu from 'shared/components/presentational/HorizontalSliderMenu/HorizontalSliderMenu';
import GabaritListContentGrid from 'shared/blocks/vod/hodor/components/childrens/GabaritListContentGrid';
import HodorChildLoader from 'shared/blocks/vod/hodor/containers/HodorChildContainer';

const Wrapper = styled.section`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0.5rem;
`;

export default class GabaritList extends PureComponent {
  render() {
    const { baseLink, data } = this.props;
    const selectedRubrique =
      data.rubriques.find(rubrique => rubrique.default) || data.rubriques[0];

    return (
      <Wrapper>
        <HorizontalSliderMenu>
          {data.rubriques.map((content, index) => (
            <Link
              to={`${baseLink}${content.path}`}
              alt={content.displayName}
              selected={content.path === selectedRubrique.path}
              key={`GabaritList_${index}`}
            >
              {content.displayName}
            </Link>
          ))}
        </HorizontalSliderMenu>

        <HodorChildLoader
          baseLink={baseLink}
          fetchUrl={selectedRubrique.URLPage}
        >
          <GabaritListContentGrid />
        </HodorChildLoader>
      </Wrapper>
    );
  }
}
