/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import { Content, FormattedProps } from './types';
import { getLinkHref } from 'shared/helpers/uri';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: get(content, 'title.label'),
  titleColor: get(content, 'title.color'),
  titleHtmlTag: get(content, 'title.htmlTag'),
  subtitle: content.subtitle || undefined,
  slideTitle: get(content, 'slide.title'),
  slideSubtitle: get(content, 'slide.subtitle'),
  alt:
    content?.slide?.mainMedia?.description ??
    content?.slide?.mainMedia?.filename,
  mainImageUrl: getLinkHref(get(content, 'slide.mainMedia.link', '')),
  responsiveImage: getLinkHref(get(content, 'slide.responsiveMedia.link', '')),
  href: getLinkHrefWithAuthentication(get(content, 'slide.link', ''))
});
