import { OTHER_CATEGORIES } from '../constants';
import { FormattedOffer } from '../types';

export interface OffersByCategory {
  [key: string]: FormattedOffer[];
}

export default (offers: FormattedOffer[]) =>
  offers.reduce((acc, offer) => {
    if (
      offer.category === '' ||
      offer.category === undefined ||
      offer.category === null
    ) {
      acc[OTHER_CATEGORIES] = [...(acc[OTHER_CATEGORIES] || []), { ...offer }];
    } else {
      acc[offer.category.toUpperCase()] = [
        ...(acc[offer.category.toUpperCase()] || []),
        { ...offer }
      ];
    }
    return acc;
  }, {} as OffersByCategory);
