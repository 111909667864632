import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg
    viewBox="0 0 229 228"
    width={width}
    height={height}
    className={className}
  >
    <g fillRule="nonzero" fill="none">
      <circle fill="currentColor" cx={115} cy={114} r={113.4} />
      <path
        fill="#FFF"
        d="M45.4 110.8v23.9H.4v-23.9h45zM92.4 46.5v135.7H66.3V69.9h-13V46.5zM186 114.4c0 23.9-5.6 70-38.2 70s-38.2-46.1-38.2-70 5.6-70 38.2-70 38.2 46.1 38.2 70zm-50.3 0c0 8.8-1.3 45.5 12.1 45.5s12.1-36.7 12.1-45.5c0-8.8 1.3-45.5-12.1-45.5s-12.1 36.7-12.1 45.5z"
      />
    </g>
  </svg>
);
