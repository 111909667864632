/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import { Content } from './types';

export default (content: Content) => ({
  title: get(content, 'title'),
  text: get(content, 'text'),
  confirmationText: get(content, 'confirmationText'),
  confirmation: {
    url: get(
      content,
      ['apis', 'register.subscribers.pre_registration.confirm', 'url'],
      ''
    ),
    method: get(
      content,
      ['apis', 'register.subscribers.pre_registration.confirm', 'method'],
      'PUT'
    )
  },
  submitButton: {
    label: get(content, 'buttons.submit.label', '')
  }
});
