/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { connect } from 'react-redux';

import getPath from 'shared/blocks/vod/hodor/selectors/getPath';
import Hodor from '../components/Hodor';
import getData from '../selectors/getData';
import getErrors from '../selectors/getErrors';
import getRequested from '../selectors/getRequested';
import getToken from '../selectors/getToken';
import getBaseLink from '../selectors/getBaseLink';
import getAuthError from '../selectors/getAuthError';

const mapStateToProps = state => {
  const token = getToken(state);
  const path = getPath(state);
  const errorsByPath = getErrors(state);
  const authError = getAuthError(state);

  if (!token) {
    return {
      data: null,
      loading: !authError,
      error: authError
    };
  }

  const dataByPath = getData(state);
  const requestedByPath = getRequested(state);

  const loading =
    requestedByPath[path] || (!dataByPath[path] && !errorsByPath[path]);

  return {
    path,
    baseLink: getBaseLink(state),
    data: dataByPath[path],
    error: errorsByPath[path],
    loading
  };
};

export default connect(mapStateToProps, null, null, {
  pure: false
})(Hodor);
