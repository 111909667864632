import React from 'react';
import { HitsProvided } from 'react-instantsearch-core';
import { connectHits } from 'react-instantsearch-dom';
import { useTranslation } from 'react-i18next';

import { AlgoliaHit } from './types';
import { ThemeFactory } from 'shared/types';
import forgeHitLink from './helpers/forgeHitLink';
import {
  HitsListTitle,
  HitsList,
  HitsListEntryWrapper,
  HitsListEntry,
  HitsListEntryTextWrapper,
  HitsListEntryImage,
  HitsListEntryTitle,
  HitsListEntrySubtitle,
  SeeAllResultsButton,
  HitsListNoResultsLabel,
  IconWrapper,
  ArticleIcon
} from './styles/components';

interface Props extends HitsProvided<AlgoliaHit> {
  title: string;
  seeAllResultsButtonLabel?: string;
  seeAllResultsButtonTheme?: ThemeFactory;
  onSeeAllResultsButtonClick?: () => void;
  hideSeeAllResultsButton?: boolean;
  onHitClick: () => void;
  isArticle?: boolean;
}

export function Hits({
  hits,
  title,
  seeAllResultsButtonLabel,
  seeAllResultsButtonTheme,
  onSeeAllResultsButtonClick,
  onHitClick,
  isArticle = false,
  hideSeeAllResultsButton = false
}: Props) {
  const { t } = useTranslation();

  const shouldShowSeeAllResultsButton =
    !hideSeeAllResultsButton &&
    hits.length > 0 &&
    onSeeAllResultsButtonClick &&
    seeAllResultsButtonLabel &&
    seeAllResultsButtonTheme;

  return (
    <>
      <HitsListTitle>{title}</HitsListTitle>
      {hits.length ? (
        <HitsList data-testid="search-engine-hits-list">
          {hits.map(hit => (
            <HitsListEntryWrapper key={hit.objectID}>
              <HitsListEntry
                onClick={onHitClick}
                to={
                  forgeHitLink(
                    hit.link ?? hit.path,
                    {
                      id_front: hit.id_front
                    },
                    isArticle
                  ) ?? '#'
                }
                // if link is to an article, we add a search param to be able to display
                // it within a page rather than a modal
                search={
                  isArticle
                    ? '?' + new URLSearchParams({ display: 'page' }).toString()
                    : undefined
                }
              >
                {hit.media && (
                  <>
                    <HitsListEntryImage
                      url={hit.media.link.href}
                      alt={hit.media.filename}
                    />
                    {isArticle && (
                      <IconWrapper>
                        <ArticleIcon />
                      </IconWrapper>
                    )}
                  </>
                )}

                <HitsListEntryTextWrapper>
                  <HitsListEntryTitle
                    dangerouslySetInnerHTML={{
                      __html:
                        hit._highlightResult?.title?.value ?? hit.title ?? ''
                    }}
                  ></HitsListEntryTitle>

                  {!hit.media && (
                    <HitsListEntrySubtitle
                      dangerouslySetInnerHTML={{
                        __html:
                          hit._highlightResult?.subtitle?.value ??
                          hit.subtitle ??
                          ''
                      }}
                    ></HitsListEntrySubtitle>
                  )}
                </HitsListEntryTextWrapper>
              </HitsListEntry>
            </HitsListEntryWrapper>
          ))}
        </HitsList>
      ) : (
        <HitsListNoResultsLabel>
          {t('blocks.searchEngine.indexNoResults')}
        </HitsListNoResultsLabel>
      )}

      {shouldShowSeeAllResultsButton && (
        <SeeAllResultsButton
          data-testid="search-engine-desktop-see-all-results-button"
          onClick={onSeeAllResultsButtonClick}
          theme={seeAllResultsButtonTheme}
        >
          <SeeAllResultsButton.children.Text>
            {seeAllResultsButtonLabel}
          </SeeAllResultsButton.children.Text>
        </SeeAllResultsButton>
      )}
    </>
  );
}

export default connectHits<Props, AlgoliaHit>(Hits);
