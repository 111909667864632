import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Index } from 'react-instantsearch-dom';

import { FormattedAlgoliaConfig } from '../../../types';
import { COLORS } from 'shared/constants/theme';
import Icon from 'shared/components/presentational/Icons';
import {
  RefinableSearchResultsWrapper,
  SearchIndicatorsWrapper,
  IndexFiltersWrapper,
  Breadcrumb,
  Chevron,
  RefinementsWrapper,
  IndexFiltersTitle,
  RefinableSearchResultsList,
  IndexWrapper,
  RefinableSearchResultsTitle,
  IndexFiltersMobileButton,
  MobileIndexFiltersModal,
  MobileIndexFiltersModalCloseButton,
  MobileIndexFiltersModalTitle,
  MobileIndexFiltersModalButtonsWrapper,
  MobileIndexFiltersModalClearButton,
  MobileIndexFiltersModalApplyButton
} from './styles/components';
import IndexFilters from './IndexFilters';
import Hits from './Hits';
import Pagination from './Pagination';
import VirtualPagination from './VirtualPagination';
import isIndexArticle from './helpers/isIndexArticle';

interface Props {
  searchEngine: FormattedAlgoliaConfig;
  mainColor: string;
  searchValue: string;
  maxPagesForIndex: number;
  indexFiltersOptions: { label: string; value: string }[];
  indexFiltersValues: string[];
  onHitClick: () => void;
  onIndexFilterToggle: (value: string) => void;
  clearIndexFilters: () => void;
}

export default function RefinableSearchResults({
  searchEngine,
  mainColor,
  searchValue,
  maxPagesForIndex,
  indexFiltersOptions,
  indexFiltersValues,
  onHitClick,
  onIndexFilterToggle,
  clearIndexFilters
}: Props) {
  const { t } = useTranslation();
  const [showMobileFiltersModal, setShowMobileFiltersModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const visibleIndicesNames = useMemo(() => {
    if (indexFiltersValues.length === 0) {
      return searchEngine.indices.map(index => index.indexName);
    }

    return searchEngine.indices
      .filter(index => indexFiltersValues.includes(index.indexName))
      .map(index => index.indexName);
  }, [searchEngine.indices, indexFiltersValues]);

  const toggleMobileFiltersModal = useCallback(() => {
    setShowMobileFiltersModal(!showMobileFiltersModal);
  }, [showMobileFiltersModal]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue, indexFiltersValues]);

  return (
    <>
      <MobileIndexFiltersModal
        visible={showMobileFiltersModal}
        data-testid="search-engine-mobile-filters-modal"
      >
        <MobileIndexFiltersModalCloseButton
          hoverColor={mainColor}
          onClick={toggleMobileFiltersModal}
        >
          <Icon name="Close" width="18px" height="18px" />
        </MobileIndexFiltersModalCloseButton>

        <MobileIndexFiltersModalTitle>
          {t('blocks.searchEngine.mobileIndexFiltersModalTitle')}
        </MobileIndexFiltersModalTitle>

        <IndexFiltersTitle>
          {t('blocks.searchEngine.indexFiltersTitle')}
        </IndexFiltersTitle>

        <IndexFilters
          indexFiltersOptions={indexFiltersOptions}
          indexFiltersValues={indexFiltersValues}
          onIndexFilterToggle={onIndexFilterToggle}
          checkmarkColor={mainColor}
          backgroundColor={COLORS.black}
        />

        <MobileIndexFiltersModalButtonsWrapper>
          <MobileIndexFiltersModalClearButton onClick={clearIndexFilters}>
            <MobileIndexFiltersModalClearButton.children.Text>
              {t('blocks.searchEngine.mobileIndexFiltersClearButtonLabel')}
            </MobileIndexFiltersModalClearButton.children.Text>
          </MobileIndexFiltersModalClearButton>

          <MobileIndexFiltersModalApplyButton
            theme={searchEngine.seeAllResultsButtonsTheme}
            onClick={toggleMobileFiltersModal}
          >
            <MobileIndexFiltersModalApplyButton.children.Text>
              {t('blocks.searchEngine.mobileIndexFiltersApplyButtonLabel')}
            </MobileIndexFiltersModalApplyButton.children.Text>
          </MobileIndexFiltersModalApplyButton>
        </MobileIndexFiltersModalButtonsWrapper>
      </MobileIndexFiltersModal>

      <RefinableSearchResultsWrapper data-testid="search-engine-refinable-results-wrapper">
        <SearchIndicatorsWrapper>
          <Breadcrumb>
            {t('blocks.searchEngine.breadcrumbFirstToken')}
            &nbsp;&nbsp;
            <Chevron>{'>'}</Chevron>
            &nbsp;&nbsp;
            {searchValue}
          </Breadcrumb>

          <RefinableSearchResultsTitle>
            {t('blocks.searchEngine.completeDisplayModeTitle')}
            &nbsp;:&nbsp;&quot;
            {searchValue}&quot;
          </RefinableSearchResultsTitle>
        </SearchIndicatorsWrapper>

        <RefinementsWrapper>
          <IndexFiltersMobileButton
            onClick={toggleMobileFiltersModal}
            data-testid="search-engine-filters-mobile-button"
          >
            <IndexFiltersMobileButton.children.Text>
              <Icon name="FunnelFilters" width="16px" height="12px" />

              {t('blocks.searchEngine.indexFiltersTitle')}
            </IndexFiltersMobileButton.children.Text>
          </IndexFiltersMobileButton>

          <IndexFiltersWrapper>
            <IndexFiltersTitle>
              {t('blocks.searchEngine.indexFiltersTitle')}
            </IndexFiltersTitle>

            <IndexFilters
              indexFiltersOptions={indexFiltersOptions}
              indexFiltersValues={indexFiltersValues}
              onIndexFilterToggle={onIndexFilterToggle}
              checkmarkColor={mainColor}
              backgroundColor={COLORS.sharkGray}
            />
          </IndexFiltersWrapper>

          <RefinableSearchResultsList>
            {searchEngine.indices.map(index => (
              <IndexWrapper
                // we only "hide" unchecked indices to allow algolia to make requests on them
                // this is needed to prevent Algolia to display stale results for the wrong index
                // https://discourse.algolia.com/t/react-instantsearch-switching-between-indexes-gives-stale-results/6638/4
                hidden={!visibleIndicesNames.includes(index.indexName)}
                key={index.indexName}
                data-testid="search-engine-index-hits-wrapper"
              >
                <Index indexName={index.indexName}>
                  <Hits
                    title={index.searchResultsLabel}
                    hideSeeAllResultsButton={true}
                    onHitClick={onHitClick}
                    isArticle={isIndexArticle(index.indexName)}
                  />

                  <VirtualPagination currentPage={currentPage} />
                </Index>
              </IndexWrapper>
            ))}
          </RefinableSearchResultsList>
        </RefinementsWrapper>

        <Pagination
          currentPage={currentPage}
          nbPages={maxPagesForIndex}
          goToPreviousPage={
            currentPage > 1 ? () => setCurrentPage(currentPage - 1) : undefined
          }
          goToNextPage={
            currentPage < maxPagesForIndex
              ? () => setCurrentPage(currentPage + 1)
              : undefined
          }
        />
      </RefinableSearchResultsWrapper>
    </>
  );
}
