/* eslint-disable react/prop-types,react/prefer-stateless-function */
/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { Fragment, PureComponent } from 'react';
import styled from 'styled-components';

import BaseGrid from 'shared/components/presentational/Grid/Grid';
import MEAProgram from 'shared/components/presentational/MEAProgram/MEAProgram';
import RenderMoreButtonContainer from 'shared/blocks/vod/hodor/containers/RenderMoreButtonContainer';

const Grid = styled(BaseGrid)`
  margin-top: 40px;
`;

const cols = {
  hd: 4,
  desktop: 4,
  tablet: 2,
  phablet: 1,
  phone: 1,
  phoneMini: 1
};

class GabaritListContentGrid extends PureComponent {
  render() {
    const { data, baseLink, path } = this.props;

    return (
      <Fragment>
        <Grid cols={cols}>
          {data.contents.map(content => (
            <MEAProgram
              key={content.contentID}
              title={content.title}
              subtitle={content.subtitle}
              imageUrl={content.URLImage}
              linkUrl={`${baseLink}${content.onClick.path}`}
            />
          ))}
        </Grid>
        <RenderMoreButtonContainer paging={data.paging} path={path} />
      </Fragment>
    );
  }
}

export default GabaritListContentGrid;
