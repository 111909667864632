import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 169 51" width={width} height={height} className={className}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M137 29l-2.2 6.3c0 .1 0 .2.1.2h4.1c.1 0 .1-.1.1-.2L137 29c.1-.1 0-.1 0 0zM93.8 28.4c0-.1 0-.1 0 0 0-.1-.1-.1 0 0l-2.9 6.4c-.1.1 0 .1 0 .1H95.3v-.1l-1.5-6.4z" />
      <path d="M57.5 16.3v34.1h110.7V16.3H57.5zM80 37.7l-.8 4.6c0 .2-.1.4-.1.4s-.1.1-.3.2c-2.8 1.2-5.8.8-5.8.8-5-.5-8.1-3.6-9.1-7.5-1.5-5.9 1.5-10.1 2.2-11 1.7-2.1 4.5-3.6 7.1-3.9 3.4-.5 5.9.3 7 .7 0 0 1.1.4 2 .9.4.3.5.4.5.4s0 .1-.1.5l-.9 4.2c0 .2-.1.3-.1.3s-.1 0-.5-.2c-2.6-1.8-5.6-1.6-5.6-1.6-2.3.1-5 1.4-5.8 4.4-.7 2.7 0 5 1.6 6.3 1.1.9 2.8 1.4 4.6 1.3 2-.1 3.5-.9 3.4-.9 0 0 .1 0 .2-.1.4-.2.5-.2.5-.2s.1.2 0 .4zm22.1 5.3s0 .1 0 0h-5.3l-.1-.2-.8-3.2c0-.1-.1-.1-.1-.1H88.6c-.1 0-.2.1-.2.1s-.1.1-.2.3l-1.4 3-.1.1s-.1.1-.2.1h-5.8c-.2 0-.2-.1-.2-.1v-.2l10.1-20.3.1-.1H97.3c.1 0 .2.1.2.1l.1.1 4.3 20.4h.2zm21.9.1h-5.5l-.1-.1-7.1-12.7-.1-.1s-.1 0-.1.1l-1.8 12.5c0 .1-.1.2-.1.2H104c-.1 0-.1-.1-.1-.1s-.1-.1-.1-.2l2.8-20.2c0-.1.1-.1.1-.1s.1-.1.2-.1h5.7c.1 0 .1.1.1.1s.1 0 .1.1l6.4 11.3c.1.2.2.2.2.2s.1 0 .1-.3l1.6-11.2c0-.1.1-.1.1-.1h5.5c.1 0 .1.1.1.1v.1L124 42.9v.2zm23.7 0h-5.4c-.1 0-.2-.1-.2-.2l-1.2-3.3c0-.1-.1-.2-.2-.2h-7.1c-.1 0-.2.1-.2.2L132 43c0 .1-.1.2-.2.2h-5.4c-.1 0-.1-.1-.1-.2l7.8-20.4c0-.1.1-.2.2-.2h5.5c.1 0 .2.1.2.2l7.7 20.4c.1.1.1.1 0 .1zm14.8-.1c0 .1-.1.2-.2.2h-12.7c-.1 0-.2-.1-.2-.2V21.8c0-.1.1-.2.2-.2h5.8c.1 0 .2.1.2.2v16c0 .1.1.2.2.2h6.5c.1 0 .2.1.2.2V43z" />
      <path d="M57.2 9.9c-.3.2-.6.5-.9.8-2.4 2.1-3.6 3-5 4.1-.4-1.8-.9-3.7-1.2-5.6 1.2-2.7 1.7-5.8.4-8-.5-.9-2.6-1.1-3.1 0-1 2.5-1.1 5-.8 7.5-.7 1.3-1.5 2.5-2.1 3.3-.7.9-2.1 2.4-3.2 1.5-2.3-1.8-2.7-6-3.2-8.6-.2-1.5-3.1-1.4-3.3 0-.5 3.1-.6 10-4.5 10.9v-.2c-.5-2.4-.7-5.2-1.6-7.5-.4-1-2.5-1.3-3.1-.2-1.7 3.2-2.4 6.8-3.2 10.4-.3-1.1-.5-2.2-.9-3.2-.8-2.6-1.5-6.1-3.6-8-.9-.8-2.4-.8-3 .4-1.1 2.2-2.1 4.6-2.8 7.2-.9-3.5-2.3-6.8-4.9-9.4-.8-.8-2.5-.8-3 .4C1 13.5.4 22 .7 30.4c.1 1.7 3.4 1.6 3.3-.1-.2-7.1.2-14.3 2.4-21.1 3.4 5.4 3.6 12.7 4 18.8.1 1.7 3.4 1.6 3.3-.1-.3-5.4.9-11.6 3.1-16.9.8 1.8 1.3 3.9 1.7 5.5.8 2.7 1.3 5.7 2.5 8.3.5 1.1 2.8 1.2 3.2-.1 1-3.4 1.5-7.1 2.4-10.7.1.8.2 1.6.4 2.4.6 3 1.1 6.2 2.1 9.1.5 1.5 3.8.9 3.2-.8-.7-2-1.2-4.2-1.6-6.4 3.4-.4 5.1-2.9 6-5.9.6 1.2 1.4 2.3 2.6 3.1 2.1 1.4 4.7.9 6.5-.7.5-.4 1-.9 1.5-1.5.3 1.3.6 2.5.8 3.8-.4.3-.8.7-1.3 1.1-3.4 3-10.6 8.8-9.1 14.1.8 2.8 4.3 4 6.8 3.9 3.3-.1 5.4-3.6 6.5-6.3 1.3-3.4 1.4-6.9 1-10.4-.1-.5-.1-1.1-.2-1.6.2-.1.4-.3.5-.5 4-3.3 3.4-2.2 7.2-5.7 1.5-1.4-1.3-2.5-2.3-1.8M49 24.6c-.2 2.9-1.3 6.7-3.7 8.6-2.2 1.7-4.7-.7-4.2-2.9.5-2.4 2.6-4.5 4.2-6.2 1.1-1.2 2.4-2.4 3.6-3.6.2 1.3.2 2.7.1 4.1" />
    </g>
  </svg>
);
