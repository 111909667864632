import { getLinkHref } from 'shared/helpers/uri';
import { Content, Item, FormattedProps } from './types';
import { BREAKPOINTS } from 'shared/components/presentational/Picture/constants';

export default function propsFormatter(content: Content): FormattedProps {
  return {
    items: content.items.map((item: Item) => {
      const desktopMediaLink = item.medias.desktopMedia?.link ?? null;
      const tabletMediaLink = item.medias.tabletMedia?.link ?? desktopMediaLink;
      const mobileMediaLink =
        item.medias.mobileMedia?.link ?? tabletMediaLink ?? desktopMediaLink;

      return {
        title: item.edito.title ?? '',
        description: item.edito.description ?? '',
        callToAction: item.edito.actionDetails
          ? {
              type: item.edito.actionDetails.type,
              label: item.edito.actionDetails.label,
              link: getLinkHref(item.edito.actionDetails.link)
            }
          : null,
        banner: {
          link: getLinkHref(item.medias.imageLink),
          pictures: {
            desktop: {
              url: getLinkHref(desktopMediaLink),
              w: BREAKPOINTS.DESKTOP + 1
            },
            tablet: {
              url: getLinkHref(tabletMediaLink),
              w: BREAKPOINTS.DESKTOP
            },
            mobile: {
              url: getLinkHref(mobileMediaLink),
              w: BREAKPOINTS.PHABLET
            }
          }
        }
      };
    })
  };
}
