import React, { PureComponent } from 'react';
import { TFunction } from 'i18next';

import Button from 'shared/components/presentational/Button';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';

export const buttonTheme = (color: string) =>
  buttonThemeFactory({
    backgroundColor: 'transparent',
    color,
    borderColor: 'none',
    textDecoration: 'none',
    padding: '0 0 0 0'
  });

export interface Props {
  onClick?: () => void;
  connected: boolean;
  className?: string;
  color: string;
  t: TFunction;
  innerRef?: (ref: any) => void;
}

export default class ConnectButton extends PureComponent<Props> {
  public render(): React.ReactNode {
    const { className, onClick, connected, color, t } = this.props;

    return (
      <Button
        className={className}
        onClick={onClick}
        theme={buttonTheme(color)}
      >
        <Button.children.SvgIcon xlink="/sprite.svg#cos-svg-profile" />
        <Button.children.Text>
          {connected
            ? t('blocks.header.client')
            : t('common.form.action.connexion')}
        </Button.children.Text>
      </Button>
    );
  }
}
