export const TOTAL_ZONE_COLUMNS = 3;

export enum DISPLAY_MODE {
  FLAG = 'flag',
  LIST = 'list'
}

export const DATA_TEST_IDS = {
  WRAPPER: 'modal-geolocation__wrapper'
};
