import React from 'react';

import { FormattedTileV1, FormattedTileV2 } from '../types';
import {
  CardWrapperTemplate1,
  CardWrapperTemplate2,
  LinkStyled,
  PictureTemplate1,
  PictureTemplate2,
  TitleTemplate1,
  TitleTemplate2,
  SubTitle,
  TitleWrapper,
  TextWrapper
} from './components';

export const CardTemplate1 = ({ tile }: { tile: FormattedTileV1 }) => {
  const props = tile.link?.href && { to: tile.link?.href };

  return (
    <CardWrapperTemplate1>
      {tile?.logo?.href && (
        <PictureTemplate1 url={tile?.logo.href} alt={tile?.logo.alt} />
      )}
      <TitleWrapper>
        {tile.title ? <TitleTemplate1>{tile.title}</TitleTemplate1> : null}
        {tile.description ? <SubTitle>{tile.description}</SubTitle> : null}
      </TitleWrapper>
      {tile.link ? (
        <LinkStyled {...props}>{tile.link?.label}</LinkStyled>
      ) : null}
    </CardWrapperTemplate1>
  );
};

export const CardTemplate2 = ({ tile }: { tile: FormattedTileV2 }) => (
  <CardWrapperTemplate2>
    {tile?.logo?.href && (
      <PictureTemplate2 url={tile?.logo.href} alt={tile?.logo.alt} />
    )}
    <TitleWrapper>
      {tile.title ? <TitleTemplate2>{tile.title}</TitleTemplate2> : null}
      {tile.description ? <SubTitle>{tile.description}</SubTitle> : null}
      {tile.text ? (
        <TextWrapper dangerouslySetInnerHTML={{ __html: tile.text }} />
      ) : null}
    </TitleWrapper>
  </CardWrapperTemplate2>
);
