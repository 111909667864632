import axios from 'axios';
import get from 'lodash/get';

import { TIMEOUT } from 'shared/modules/http/constants';
import { PostOfferParams } from '../../shared/types';

export default async ({
  url,
  method,
  contractInfo,
  personInfo,
  bankInfo
}: PostOfferParams) => {
  try {
    const response = await axios({
      url,
      method,
      headers: {
        Accept: 'application/json'
      },
      timeout: TIMEOUT.LONG,
      data: {
        contractInfo,
        personInfo,
        bankInfo
      }
    });
    return response;
  } catch (e) {
    const errorDetail = get(e, 'response.data.errors[0].detail');
    throw new Error(errorDetail);
  }
};
