/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import { Overlay as ToolTip } from 'react-overlays';
import styled from 'styled-components';
import size from 'lodash/size';
import { TFunction } from 'i18next';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { COLORS } from 'shared/constants/theme';
import Menu from './Menu';
import QuickAccess from './QuickAccess';
import Separator from './Separator';
import SearchButton from './SearchButton';
import _CanalBoxLink from './CanalBoxLink';
import _BoxLogged from './BoxLogged';
import _BoxNotLogged from './BoxNotLogged';
import { FormattedProps, PropsFromState, WithConnected } from '../../types';

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  height: 22px;

  ${mediaQueries.toTablet`
     display: none;
  `};
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AbstractToolTipContent = styled.div`
  z-index: 1;
  position: absolute;
  left: initial !important;
  top: 35px !important;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  margin: 10px;
  text-align: left;
  width: 320px;

  :before {
    content: '';
    position: absolute;

    right: 35px;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 13.5px 10px 13.5px;
    border-color: transparent transparent ${COLORS.white} transparent;
  }
`;

const LoginToolTipContent = styled(AbstractToolTipContent)<{
  positionWithMultipleLangs: boolean;
}>`
  right: ${props => (props.positionWithMultipleLangs ? 52 : -10)}px;
`;

const LangsToolTipContent = styled(AbstractToolTipContent)`
  right: -31px;
`;

const BoxNotLogged = styled(_BoxNotLogged)`
  width: 320px;
  padding: 30px 30px 15px;
`;

const BoxLogged = styled(_BoxLogged)`
  width: 320px;
  padding: 30px 30px 15px;
`;

const CanalBoxLink = styled(_CanalBoxLink)`
  padding-left: 30px;
`;

const LangsContent = styled.div`
  width: 100%;
  background-color: ${COLORS.white};
  padding: 10px 20px;
  color: ${COLORS.black};
  cursor: pointer;
`;

const Text = styled.div`
  padding: 10px 20px;
`;

interface Props
  extends Omit<
      FormattedProps,
      'logoUrl' | 'authenticationUrl' | 'redirectUrlAfterLogin' | 'algolia'
    >,
    Pick<PropsFromState, 'loginUrl' | 'langs'>,
    WithConnected {
  tooltipTarget: any;
  isMenuOpen: boolean;
  onItemClick: () => void;
  onSearchIconClick: () => void;
  t: TFunction;
  isLangsMenuOpen: boolean;
  enableSearchEngine: boolean;
  onLangSectionChanged: (arg1: string) => void;
  logoutUrl: string;
}

export default class NavigationDesktop extends PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      connected,
      mainMenuItems,
      quickAccessMenuItems,
      tooltipTarget,
      isMenuOpen,
      onItemClick,
      onSearchIconClick,
      loginUrl,
      logoutUrl,
      canalBoxUrl,
      canalBoxTitle,
      backgroundColor,
      mainLinksColor,
      quickAccessLinksColor,
      isAllowedToConnect,
      t,
      isLangsMenuOpen,
      enableSearchEngine,
      langs,
      onLangSectionChanged,
      boxTitle,
      boxSubscriptionManagementLabel,
      boxSubscriptionManagementUrl,
      boxLoyaltyProgramLabel,
      boxLoyaltyProgramUrl,
      signupCanalPlusTitle,
      signupCanalPlusLinkLabel,
      signupCanalPlusLink
    } = this.props;

    return (
      <Nav>
        <MenuWrapper>
          <Menu
            color={mainLinksColor}
            items={mainMenuItems}
            onItemClick={onItemClick}
          />

          {enableSearchEngine && (
            <SearchButton
              color={mainLinksColor}
              hoverColor={quickAccessLinksColor}
              onClick={onSearchIconClick}
            />
          )}
        </MenuWrapper>

        <QuickAccess
          color={quickAccessLinksColor}
          items={quickAccessMenuItems}
          onItemClick={onItemClick}
        />

        <ToolTip
          show={isMenuOpen}
          placement="bottom"
          container={this}
          target={tooltipTarget}
        >
          <LoginToolTipContent positionWithMultipleLangs={size(langs) > 1}>
            {isMenuOpen &&
              (loginUrl && isAllowedToConnect ? (
                connected ? (
                  <BoxLogged
                    logoutUrl={logoutUrl}
                    onItemClick={onItemClick}
                    boxTitle={boxTitle}
                    boxSubscriptionManagementLabel={
                      boxSubscriptionManagementLabel
                    }
                    boxSubscriptionManagementUrl={boxSubscriptionManagementUrl}
                    boxLoyaltyProgramLabel={boxLoyaltyProgramLabel}
                    boxLoyaltyProgramUrl={boxLoyaltyProgramUrl}
                    t={t}
                  />
                ) : (
                  <BoxNotLogged
                    loginUrl={loginUrl}
                    onItemClick={onItemClick}
                    boxTitle={boxTitle}
                    buttonColor={backgroundColor}
                    signupCanalPlusTitle={signupCanalPlusTitle}
                    signupCanalPlusLinkLabel={signupCanalPlusLinkLabel}
                    signupCanalPlusLink={signupCanalPlusLink}
                    t={t}
                  />
                )
              ) : isAllowedToConnect && loginUrl && canalBoxUrl ? (
                <Separator />
              ) : (
                canalBoxUrl && (
                  <CanalBoxLink
                    title={canalBoxTitle}
                    url={canalBoxUrl}
                    onClick={onItemClick}
                  />
                )
              ))}
          </LoginToolTipContent>
        </ToolTip>
        <ToolTip
          show={isLangsMenuOpen}
          placement="bottom"
          container={this}
          target={tooltipTarget}
        >
          <LangsToolTipContent>
            {isLangsMenuOpen && (
              <LangsContent>
                {Object.entries(langs).map(([value, label]) => (
                  <Text key={value} onClick={() => onLangSectionChanged(value)}>
                    {label}
                  </Text>
                ))}
              </LangsContent>
            )}
          </LangsToolTipContent>
        </ToolTip>
      </Nav>
    );
  }
}
