import React, { useMemo, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Dropdown } from './Dropdown/Dropdown';
import { useActiveIndex } from '../hooks/useActiveIndex';
import { FormattedProps } from '../types';
import { WebsiteLogo } from './WebsiteLogo';
import {
  HiddenList,
  List,
  NavigationMenuWrapper,
  UserMenuWrapper,
  WebsiteLogoWrapper,
  Wrapper
} from '../styles/components';
import {
  useSplitIndex,
  DropdownTriggerPositions
} from '../hooks/useSplitIndex';
import Button from '../containers/Button';
import ExpandableMenuDropdown from './ExpandableMenuDropdown';
import ExpandableMenuItem from './ExpandableMenuItem';
import isConnected from 'shared/helpers/auth/isConnected';
import isExternalURI from 'shared/helpers/uri/isExternalURI';
import LanguageSwitcher from '../containers/LanguageSwitcher';
import SearchEngine from '../containers/SearchEngine';
import { ModalsOpeningProvider } from '../providers/ModalsOpeningProvider';
import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';

type Props = FormattedProps;

export default function Menu({
  theme: { backgroundColor, link, backgroundColorModals, colorClosingModals },
  prospect,
  authentication,
  authenticated,
  selfcareIcon,
  websiteLogo,
  navigationMenu,
  languageSwitcher,
  algolia
}: Props) {
  const menuParentRef = useRef<HTMLElement>(null);
  const measurableListRef = useRef<HTMLUListElement>(null);
  const visibleListRef = useRef<HTMLUListElement>(null);
  const logoRef = React.useRef<HTMLDivElement | null>(null);
  const userMenuRef = React.useRef<HTMLDivElement | null>(null);

  const [allCookies] = useCookies();

  const connected = isConnected(allCookies || {});
  const { pathname } = useLocation();

  React.useEffect(
    function refreshPageIfAuthCookieExistsAndUserPressBackButton() {
      function refreshPage(event: PageTransitionEvent) {
        if (event.persisted) {
          const authCookieFound = document.cookie.match(
            /AuthOTTToken|AuthOTTTokenV2|WebsiteAccessToken|WebsiteAccessToken_stag/
          );
          if (
            (authCookieFound && !connected) ||
            (!authCookieFound && connected)
          ) {
            window.location.reload();
          }
        }
      }

      if (typeof window !== 'undefined') {
        window.addEventListener('pageshow', refreshPage);

        return function cleanup() {
          window.removeEventListener('pageshow', refreshPage);
        };
      }
    },
    [connected]
  );

  const selectedNavigationMenu = React.useMemo(
    () => (connected ? navigationMenu.authenticated : navigationMenu.prospect),
    [navigationMenu, connected]
  );

  const activeIndex = useActiveIndex(selectedNavigationMenu, pathname);

  const splitIndex = useSplitIndex(
    activeIndex,
    selectedNavigationMenu,
    menuParentRef,
    visibleListRef,
    measurableListRef
  );

  // Each child gets wrapped in an ExpandableMenuItem component
  const menuItemsInteractive = useMemo(
    () =>
      selectedNavigationMenu.map((navigationItem, index) => {
        const item = isExternalURI(navigationItem.link) ? (
          <CustomLink
            key={`expandableMenuItem_${String(index)}`}
            to={navigationItem.link}
          >
            {navigationItem.label}
          </CustomLink>
        ) : (
          <NavLink
            exact={true}
            to={navigationItem.link}
            id={`expandableMenuItem_${index}_onclick`}
            activeStyle={{
              color: link.linksColorSecondary
            }}
          >
            {navigationItem.label}
          </NavLink>
        );

        return (
          <ExpandableMenuItem
            link={link}
            id={`expandableMenuItem_${index}`}
            key={`expandableMenuItem_${String(index)}`}
          >
            {item}
          </ExpandableMenuItem>
        );
      }),
    [link, selectedNavigationMenu]
  );

  // The measurable menu items get props removed that determine ID and data-e2e attributes
  //(don't want duplicates in the DOM)
  const menuItemsMeasurable = useMemo(
    () =>
      selectedNavigationMenu.map((navigationItem, index) => (
        <ExpandableMenuItem
          link={link}
          key={`measurableMenuItem_${String(index)}`}
        >
          <NavLink
            to={navigationItem.link}
            id={`measurableMenuItem_${index}_onclick`}
            activeStyle={{
              color: link.linksColorSecondary
            }}
          >
            {navigationItem.label}
          </NavLink>
        </ExpandableMenuItem>
      )),
    [link, selectedNavigationMenu]
  );

  const interactiveMenuItems = useMemo(() => {
    if (splitIndex === DropdownTriggerPositions.NOT_YET_DETERMINED) {
      return null;
      // The component just mounted and we're calculating menu item sizes,
      //so we don't yet display the interactive menu items
    }
    if (splitIndex === DropdownTriggerPositions.NOT_USED) {
      return menuItemsInteractive;
    }
    const barItems = (menuItemsInteractive || []).slice(0, splitIndex);
    const dropdownItems = (menuItemsInteractive || []).slice(splitIndex);
    return (
      <>
        {barItems}
        {dropdownItems.length > 0 ? (
          <ExpandableMenuDropdown link={link} backgroundColor={backgroundColor}>
            {dropdownItems}
          </ExpandableMenuDropdown>
        ) : null}
      </>
    );
  }, [backgroundColor, link, menuItemsInteractive, splitIndex]);

  const button = connected ? authenticated.signedCTA : prospect.signupCTA;

  return (
    <Wrapper backgroundColor={backgroundColor} role="banner" data-testid="menu">
      <WebsiteLogoWrapper ref={logoRef}>
        {websiteLogo ? <WebsiteLogo websiteLogo={websiteLogo} /> : null}
      </WebsiteLogoWrapper>

      <NavigationMenuWrapper ref={menuParentRef} role="navigation">
        {/* The interactive list with potential dropdown */}
        <List ref={visibleListRef} data-e2e="expandableMenu__list">
          {interactiveMenuItems}
        </List>
        {/* (Visually hidden): full list of menu items used only to measure the size of menu items,
        in order the determine where the dropdown starts. */}
        <HiddenList ref={measurableListRef}>
          {menuItemsMeasurable}
          <ExpandableMenuDropdown
            backgroundColor={backgroundColor}
            link={link}
          />
        </HiddenList>
      </NavigationMenuWrapper>
      <UserMenuWrapper ref={userMenuRef}>
        {algolia.enableSearchEngine ? (
          <SearchEngine
            algolia={algolia}
            userMenuRef={userMenuRef}
            menuParentRef={menuParentRef}
            logoRef={logoRef}
          />
        ) : null}
        {button.label ? (
          <Button
            authentication={authentication}
            connected={connected}
            label={button.label}
            icon={button.icon}
            link={button.link}
            buttonTheme={button.buttonTheme}
          />
        ) : null}

        <ModalsOpeningProvider>
          {selfcareIcon ? (
            <Dropdown
              theme={{
                link,
                backgroundColorModals,
                colorClosingModals
              }}
              authentication={authentication}
              isConnected={connected}
              items={connected ? authenticated.userMenu : prospect.userMenu}
              icon={selfcareIcon}
            />
          ) : null}
          <LanguageSwitcher languageSwitcher={languageSwitcher} />
        </ModalsOpeningProvider>
      </UserMenuWrapper>
    </Wrapper>
  );
}
