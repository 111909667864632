/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import styled from 'styled-components';

import { container } from 'shared/constants/mixins';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import BaseSlider from 'shared/components/presentational/SliderOrList/SliderOrList';
import BaseSlide from 'shared/components/presentational/Slider/Slide/Slide';

export const Wrapper = styled.section``;

export const TitleSubtitleWrapper = styled.div`
  ${container};

  text-align: center;
`;

export const Slider = styled(BaseSlider)`
  & > button {
    height: 100%;
  }

  & img {
    height: auto !important;
  }
`;

export const Slide = styled(BaseSlide)`
  & picture {
    display: flex;
    justify-content: center;
    background-color: transparent;
  }

  & img {
    max-height: 475px;
    width: auto;

    ${mediaQueries.fromPhablet`
      max-height: 315px;
    `}
  }
`;
