import { Cookies } from 'react-cookie';

export default (
  cookies: Cookies,
  toErase: string,
  options = {} // https://www.npmjs.com/package/universal-cookie#removename-options
) => {
  const defaultOptions = { path: '/' };
  cookies.remove(toErase, Object.assign(defaultOptions, options));
};
