/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  buttons: {
    successRedirectButton: {
      label: content.buttons.successRedirectButton.label,
      theme: buttonThemeFactory({
        color: content.buttons.successRedirectButton.labelColor,
        backgroundColor: content.buttons.successRedirectButton.backgroundColor
      }),
      link: getLinkHrefWithAuthentication(
        content.buttons.successRedirectButton.link
      )
    },
    errorRedirectButton: {
      label: content.buttons.errorRedirectButton.label,
      theme: buttonThemeFactory({
        color: content.buttons.errorRedirectButton.labelColor,
        backgroundColor: content.buttons.errorRedirectButton.backgroundColor
      }),
      link: getLinkHrefWithAuthentication(
        content.buttons.errorRedirectButton.link
      )
    }
  },
  apis: {
    getSubscriberData: content.apis.getSubscriberData,
    checkAccount: content.apis.checkAccount
  }
});
