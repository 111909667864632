/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*       */
import React, { PureComponent } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

import ProgramDetail from 'shared/components/presentational/ProgramDetail/ProgramDetail';
import eismoToProgramDetailProps from 'shared/components/presentational/ProgramDetail/dto/eismo';
import Picture from 'shared/components/presentational/Picture';
import { createTemplate } from 'shared/helpers/template';
import { TV_CHANNEL_BASIC_AUTH } from 'shared/blocks/tv-guide/constants';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import clientCookies from 'client/cookies';
import { getFormatedChannelAndTvScheduleIds } from './helpers/getFormatedChannelAndTvScheduleIds';

class FetchProgram extends PureComponent {
  state = {
    data: null
  };

  componentDidMount() {
    const { url, vectorSat, vector, type, id, isNew } = this.props;

    const zone = clientCookies.get(COOKIE_KEYS.OFFER_ZONE);
    const location = clientCookies.get(COOKIE_KEYS.ZONE);
    const fetchURL = createTemplate(url)({
      vectorSat,
      vector,
      type,
      id,
      zone,
      location
    });
    const currentUrl = window?.location?.href ?? '/';
    const meowConfig = {
      url: `${fetchURL}/${getFormatedChannelAndTvScheduleIds(currentUrl)}`,
      method: 'GET',
      headers: { Authorization: TV_CHANNEL_BASIC_AUTH }
    };

    const legacyConfig = {
      url: fetchURL,
      method: 'GET'
    };

    const config = isNew ? meowConfig : legacyConfig;

    axios(config)
      .then(({ data }) => {
        this.setState({
          data
        });
      })
      .catch(error => {
        throw new Error(error);
      });
  }
  render() {
    const { data } = this.state;
    const { timezone, t } = this.props;

    if (!data || !timezone) return null;

    const pictureComponent = (
      <Picture
        url={data.detail.informations.URLImage}
        alt={data.detail.informations.title}
        width={860}
        height={480}
      />
    );

    return (
      <ProgramDetail
        t={t}
        {...eismoToProgramDetailProps(data, timezone)}
        coverComponent={pictureComponent}
      />
    );
  }
}

export default withTranslation()(FetchProgram);
