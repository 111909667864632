/* eslint-disable no-template-curly-in-string,no-restricted-globals */

import i18next from 'i18next';
import 'server/i18n';

export default function captcha(msg) {
  return this.test({
    name: 'captcha',
    message: msg || i18next.t('common.form.validation.captcha'),
    test: value => typeof value !== 'undefined' && value !== 'false'
  });
}
