/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { initScript } from 'shared/helpers/assets';
import ConfigHelperHOC from 'shared/components/presentational/ConfigHelperHOC';
import { initSmartAd } from '../helpers';

/*
 * Main wrapper should be a section
 * in order to inherit margins
 */
const Wrapper = styled.section`
  display: flex;
  justify-content: center;
`;

class Advertising extends PureComponent {
  componentDidMount() {
    const { publicConfig } = this.props;
    initScript(publicConfig.scripts.smart_ad_server).then(initSmartAd);
  }

  render() {
    return (
      <Wrapper>
        <div id="sas_45655" />
        <Helmet>
          <noscript>
            {`<a
              href="//www3.smartadserver.com/ac?jump=1&nwid=2136&siteid=272504&pgname=rg&fmtid=45655&visit=m&tmstp=[timestamp]&out=nonrich"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="//www3.smartadserver.com/ac?out=nonrich&nwid=2136&siteid=272504&pgname=rg&fmtid=45655&visit=m&tmstp=[timestamp]"
                border="0"
                alt=""
              />
            </a>`}
          </noscript>
        </Helmet>
      </Wrapper>
    );
  }
}

export default ConfigHelperHOC(Advertising);
