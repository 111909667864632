import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 24 18" width={width} height={height} className={className}>
    <g fill="currentColor" fillRule="evenodd">
      <rect width={16} height={2} rx={1} />
      <rect x={20} width={4} height={2} rx={1} />
      <rect y={8} width={16} height={2} rx={1} />
      <rect x={20} y={8} width={4} height={2} rx={1} />
      <rect y={16} width={16} height={2} rx={1} />
      <rect x={20} y={16} width={4} height={2} rx={1} />
    </g>
  </svg>
);
