import { pickBy, Dictionary } from 'lodash';

import { createTemplate } from 'shared/helpers/template';
import appendParamsToUrl from 'shared/helpers/uri/appendParamsToUrl';
import { DISPLAY_PAGE_PARAM } from 'shared/blocks/articles/shared/constants';

export default function forgeHitLink(
  link: string | undefined,
  params: { [param: string]: string | undefined } = {},
  isArticle = false
) {
  if (!link) {
    return undefined;
  }

  const cleanedParams = pickBy(
    params,
    v => v !== undefined && v !== null
  ) as Dictionary<string>;

  const finalLink = isArticle
    ? appendParamsToUrl(link, DISPLAY_PAGE_PARAM)
    : link;

  return createTemplate(finalLink)(cleanedParams);
}
