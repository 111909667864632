import styled from 'styled-components';

import { COLORS } from 'shared/constants/theme';

const ModalBody = styled.div<{ withCloseButton?: boolean }>`
  background-color: ${COLORS.white};
  padding: ${props => (props.withCloseButton ? '14px 34px 34px' : '34px')};
`;

export default ModalBody;
