/* eslint-disable no-template-curly-in-string,no-restricted-globals */

import i18next from 'i18next';
import 'server/i18n';

export default function isNumber(msg) {
  return this.test({
    name: 'isNumber',
    message: msg || i18next.t('common.form.validation.isNumber'),
    test: value => !value || !isNaN(value)
  });
}
