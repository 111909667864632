import React from 'react';
import {
  Index,
  useInstantSearch,
  Configure
} from 'react-instantsearch-hooks-web';

import { Grid } from '../../../header/components/components/SearchEngine/styles/components';
import { ConnectedHits, Hits } from './Hits';
import isIndexArticle from '../../../header/components/components/SearchEngine/helpers/isIndexArticle';
import { SEARCH_HIGHLIGHT_CLASS } from 'shared/blocks/header/components/components/SearchEngine/constants';
import { isFAQIndex } from '../../helper/isFAQIndex';
import { SearchEngine } from '../../types';

interface Props {
  searchEngine: SearchEngine;
  onSeeAllResultsButtonClick: (indexName: string) => void;
  onHitClick: () => void;
  bigram: string;
  pageLang: string;
  companyId: string;
  smartFaqUuId: string;
}

export default function SearchResultsGrid({
  searchEngine,
  onSeeAllResultsButtonClick,
  onHitClick,
  bigram,
  pageLang,
  companyId,
  smartFaqUuId
}: Props) {
  const hasShortcuts = searchEngine.shortcuts
    ? searchEngine.shortcuts.shortcutsItems.length > 0
    : false;
  const [showShortcuts, setShowShortcuts] = React.useState(hasShortcuts);
  const { results } = useInstantSearch();

  React.useEffect(() => {
    if (hasShortcuts) {
      setShowShortcuts(!results?.query);
    }
  }, [results, hasShortcuts]);

  if (!showShortcuts) {
    return (
      <Grid>
        {searchEngine.indices.map(index => {
          return (
            <div
              className={index.indexName}
              key={index.indexName}
              data-testid="search-engine-hits-list-wrapper"
            >
              <Index indexName={index.indexName}>
                <Configure
                  facetFilters={
                    isFAQIndex(index.indexName)
                      ? undefined
                      : [`area:${bigram}`, `lang:${pageLang}`]
                  }
                  highlightPreTag={`<em class="${SEARCH_HIGHLIGHT_CLASS}">`}
                  highlightPostTag={`</em>`}
                  filters={
                    isFAQIndex(index.indexName)
                      ? `question.category.thematic.company.id: "${companyId}" AND NOT question.disabled_zones_ids: "${smartFaqUuId}"`
                      : undefined
                  }
                />
                <ConnectedHits
                  title={index.searchResultsLabel}
                  seeAllResultsButtonLabel={index.seeAllResultsButtonLabel}
                  seeAllResultsButtonTheme={
                    searchEngine.seeAllResultsButtonsTheme
                  }
                  onSeeAllResultsButtonClick={() =>
                    onSeeAllResultsButtonClick(index.indexName)
                  }
                  onHitClick={onHitClick}
                  isArticle={isIndexArticle(index.indexName)}
                  isFAQIndex={isFAQIndex(index.indexName)}
                />
              </Index>
            </div>
          );
        })}
      </Grid>
    );
  } else {
    return (
      <div
        data-testid="search-engine-shortcuts"
        key={searchEngine.shortcuts.title}
      >
        <Hits
          title={searchEngine.shortcuts.title}
          onHitClick={onHitClick}
          hits={searchEngine.shortcuts.shortcutsItems!.map(shortcut => ({
            objectID: shortcut.id,
            title: shortcut.title,
            link: shortcut.link,
            _highlightResult: []
          }))}
        />
      </div>
    );
  }

  return null;
}
