/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { Content, FormattedProps } from './types';
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { BREAKPOINTS } from 'shared/components/presentational/Picture/constants';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: {
    subtitle: content.subtitle,
    titleLabel: content.title.label,
    titleHtmlTag: 'h1',
    titleColor: '#000000'
  },
  description: content.description,
  pictures: {
    desktop: {
      url: content.mediaDesktop?.link?.href ?? undefined,
      w: BREAKPOINTS.DESKTOP + 1,
      alt: content.mediaMobile?.description ?? 'image banner'
    },
    phone: {
      url: content.mediaMobile?.link?.href ?? undefined,
      w: BREAKPOINTS.DESKTOP
    },
    phoneMini: {
      url: content.mediaMobile?.link?.href ?? undefined,
      w: BREAKPOINTS.PHONE
    }
  },
  button: {
    theme: themeFactory({
      backgroundColor: content.button.backgroundColor,
      borderColor: undefined,
      color: content.button.color
    }),
    label: content.button.label,
    link: getLinkHrefWithAuthentication(content.button.link)
  }
});
