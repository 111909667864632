import React, { useCallback } from 'react';

import {
  ButtonConnected,
  ButtonStyled,
  CloseIconStyled,
  DescriptionStyled,
  DropdownMenuStyled,
  InfoWrapper,
  LinkWrapperStyled,
  OpacityStyled,
  TitleStyled
} from './DropdownMenuStyled';
import { Button } from './Button/Button';
import { FormatedMenu } from '../../types';
import { ThemeFactory } from 'shared/types';
import DropdownMenuItem from './DropdownMenuItem/DropdownMenuItem';
import Icon from 'shared/components/presentational/Icons';

type Props = {
  isConnected: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  items: FormatedMenu;
  theme: {
    link: ThemeFactory['link'];
    backgroundColorModals: string;
    colorClosingModals: string;
  };
  authentication: {
    loginLink: string;
    logoutLink: string;
  };
};
export const DropdownMenu = ({
  isConnected,
  onMouseEnter,
  onMouseLeave,
  items,
  theme,
  authentication: { logoutLink, loginLink }
}: Props) => {
  const { title, description, menu = [] } = items;
  const renderList = useCallback(
    (data, key) => (
      <DropdownMenuItem
        key={key}
        data={data}
        theme={theme}
        onClick={onMouseLeave}
      />
    ),
    [theme, onMouseLeave]
  );

  return (
    <>
      <DropdownMenuStyled
        backgroundColorModals={theme.backgroundColorModals}
        onMouseOver={onMouseEnter}
        onMouseLeave={onMouseLeave}
        isConnected={isConnected}
      >
        <InfoWrapper>
          <ButtonStyled onClick={onMouseLeave}>
            <CloseIconStyled colorClosingModals={theme.colorClosingModals}>
              <Icon name="Close" />
            </CloseIconStyled>
          </ButtonStyled>
          <TitleStyled>{title}</TitleStyled>
          <DescriptionStyled>{description}</DescriptionStyled>
          {!isConnected && <Button href={loginLink} items={items} />}
        </InfoWrapper>
        <LinkWrapperStyled>{menu.map(renderList)}</LinkWrapperStyled>
        {isConnected && (
          <ButtonConnected>
            <Button href={logoutLink} items={items} />
          </ButtonConnected>
        )}
      </DropdownMenuStyled>
      <OpacityStyled onClick={onMouseLeave} />
    </>
  );
};
