/* eslint-disable no-unused-expressions */
import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import chunk from 'lodash/chunk';

import BaseButton from 'shared/components/presentational/Button';
import Loader from 'shared/components/presentational/Loader';
import BaseModal from 'shared/components/presentational/Modal/BaseModal';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import { TOTAL_ZONE_COLUMNS, DISPLAY_MODE, DATA_TEST_IDS } from '../constants';
import { ComponentProps } from '../types';

const Wrapper = styled.div`
  padding: 40px;
  background-color: ${COLORS.white};
`;

const Title = styled.h1`
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  font-size: ${pxInRem(18)};
  color: ${COLORS.black};
`;

const Subtitle = styled.p`
  text-align: center;
  font-size: ${pxInRem(14)};
  font-family: ${FONT_FAMILIES.base};
`;

const StyledLoader = styled(Loader).attrs({ color: COLORS.black })`
  margin: 30px auto 0;
`;

const ZonesWrapper = styled.div`
  display: flex;
  max-width: 600px;

  ${mediaQueries.toPhablet`
    flex-direction: column;
  `};
`;

const Zones = styled.ul`
  flex: 1;

  ${mediaQueries.fromTablet`
     min-width: 140px;
  `};
`;

const StyledBaseModal = styled(BaseModal)`
  && {
    min-width: auto;
  }
`;

const ZoneButton = styled(BaseButton).attrs({
  theme: BaseButton.themes.blackTransparent
})`
  width: 100%;
  height: auto;
  min-height: 40px;
  padding: 10px;
  text-decoration: none;
`;

const ZoneButtonText = styled(BaseButton.children.Text)`
  font-family: ${FONT_FAMILIES.base};
  text-align: left;
`;

const Zone = styled.li<{ displayMode: DISPLAY_MODE }>`
  ${props =>
    props.displayMode === DISPLAY_MODE.LIST &&
    css`
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 10px;
        top: 18px;
        width: 5px;
        height: 5px;
        background-color: ${COLORS.amaranth};
        border-radius: 50%;
      }

      ${ZoneButton} {
        padding-left: 25px;
        justify-content: flex-start;
      }
    `};
  ${props =>
    props.displayMode === DISPLAY_MODE.FLAG &&
    css`
      ${ZoneButton} {
        justify-content: flex-start;
        align-items: flex-start;

        ${ZoneButtonText} {
          text-align: left;
          max-width: calc(100% - 30px);
          margin-left: 10px;
        }
      }
    `};
`;

const ZoneSelection = styled.div``;

const ZoneValidation = styled.div`
  min-width: 250px;

  ${mediaQueries.toPhablet`
    min-width: auto;
  `};
`;

const SelectedZone = styled.div`
  margin: 30px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectedZoneFlag = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const SelectedZoneName = styled.p<{ displayMode: DISPLAY_MODE }>`
  margin: 0;
  max-width: calc(100% - 40px);
  text-align: center;
  font-family: ${FONT_FAMILIES.base};
  ${props =>
    props.displayMode === DISPLAY_MODE.FLAG &&
    `
    text-align: initial;
  `};
`;

const ValidationActions = styled.div`
  display: flex;
  justify-content: center;
`;

const ValidateButton = styled(BaseButton).attrs({
  theme: BaseButton.themes.redPlainAmaranth
})`
  margin-left: 5px;
`;

const ModifyButton = styled(BaseButton).attrs({
  theme: BaseButton.themes.redStrokedAmaranth
})`
  margin-right: 5px;
`;

type Props = ComponentProps;

export default class ModalGeolocation extends PureComponent<Props> {
  public componentDidMount() {
    this.props.fetchZoneHandler(this.props.zones);
  }

  public onZoneValidated = () => {
    const { selectedZone } = this.props;

    if (selectedZone && selectedZone.bigram) {
      // window exits, since this modal is a client only component
      const currentPath = window.location.pathname;
      const currentParams = window.location.search;
      window.location.replace(
        `/${selectedZone.bigram}${currentPath}${currentParams}`
      );
    }
  };

  public render() {
    const {
      zones,
      selectedZone,
      fetchingZonePending,
      subtitle,
      title,
      buttonLabel,
      displayMode,
      totalZones,
      selectZoneHandler,
      resetZoneSelectionHandler,
      isOpen
    } = this.props;

    const showZonesSelectionStep = !fetchingZonePending && !selectedZone;
    const showZonesValidationStep = !fetchingZonePending && !!selectedZone;

    const totalZonesPerColumn = Math.ceil(totalZones / TOTAL_ZONE_COLUMNS);

    const modalProps = {
      overlayClassName: {
        base: 'ReactModal__Overlay geolocation',
        afterOpen: 'ReactModal__Overlay--after-open geolocation'
      },
      style: {
        overlay: {
          zIndex: 500
        }
      },
      isOpen
    };

    return (
      <StyledBaseModal modalProps={modalProps}>
        <Wrapper data-testid={DATA_TEST_IDS.WRAPPER}>
          <Title>{title}</Title>

          {fetchingZonePending && <StyledLoader />}

          {showZonesSelectionStep && (
            <ZoneSelection>
              <Subtitle>{subtitle}</Subtitle>

              <ZonesWrapper>
                {chunk(zones, totalZonesPerColumn).map(
                  (column, columnIndex) => (
                    <Zones key={`column-${columnIndex}`}>
                      {column.map((zone, zoneIndex) => (
                        <Zone
                          key={`zone-${zone.bigram}-${zoneIndex}`}
                          displayMode={displayMode}
                        >
                          <ZoneButton onClick={() => selectZoneHandler(zone)}>
                            {displayMode === DISPLAY_MODE.FLAG && (
                              <BaseButton.children.SvgFlag
                                width="20"
                                height="20"
                                bigram={zone.bigram}
                              />
                            )}

                            <ZoneButtonText>{zone.name}</ZoneButtonText>
                          </ZoneButton>
                        </Zone>
                      ))}
                    </Zones>
                  )
                )}
              </ZonesWrapper>
            </ZoneSelection>
          )}

          {showZonesValidationStep && (
            <ZoneValidation>
              <SelectedZone>
                {displayMode === DISPLAY_MODE.FLAG && (
                  <SelectedZoneFlag>
                    <svg width="30" height="30">
                      <use
                        xlinkHref={`/flags.svg#cos-svg-${selectedZone!.bigram}`}
                      />
                    </svg>
                  </SelectedZoneFlag>
                )}
                <SelectedZoneName displayMode={displayMode}>
                  {selectedZone!.name}
                </SelectedZoneName>
              </SelectedZone>
              <br />
              <ValidationActions>
                <ModifyButton onClick={resetZoneSelectionHandler}>
                  <BaseButton.children.Text>Modifier</BaseButton.children.Text>
                </ModifyButton>
                <ValidateButton onClick={this.onZoneValidated}>
                  <BaseButton.children.Text>
                    {buttonLabel}
                  </BaseButton.children.Text>
                </ValidateButton>
              </ValidationActions>
            </ZoneValidation>
          )}
        </Wrapper>
      </StyledBaseModal>
    );
  }
}
