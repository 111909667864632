import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 64 28" width={width} height={height} className={className}>
    <path
      d="M.8 14c0 7.6 6.2 13.8 13.8 13.8h34.7c7.6 0 13.8-6.2 13.8-13.8C63.1 6.4 57 .2 49.4.2S35.5 6.4 35.5 14c0 3.8 1.6 7.3 4.1 9.8H24.4c2.5-2.5 4.1-6 4.1-9.8C28.5 6.4 22.3.2 14.7.2 7.1.2.8 6.4.8 14zm38.7 0c0-5.4 4.4-9.8 9.8-9.8s9.8 4.4 9.8 9.8-4.4 9.8-9.8 9.8-9.8-4.4-9.8-9.8zM14.6 4.2c5.4 0 9.8 4.4 9.8 9.8s-4.4 9.8-9.8 9.8-9.8-4.4-9.8-9.8 4.4-9.8 9.8-9.8z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
