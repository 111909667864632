/* eslint-disable react/jsx-key */

import React, { Component } from 'react';
import styled from 'styled-components';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

import pxInRem from 'shared/helpers/styled-components/remHelper';
import Loader from 'shared/components/presentational/Loader';
import { initScript } from 'shared/helpers/assets';
import { initPlayer } from 'shared/components/presentational/OnePlayer/helpers/index';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import { PlayerConfig } from '../types';
import clientCookies from 'client/cookies';

const Container = styled.div<{ height?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  background-color: ${COLORS.black};
`;

const Title = styled.h1`
  font-size: ${pxInRem(24)};
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};
  line-height: ${pxInRem(28)};
  color: ${COLORS.white};
  text-align: center;
`;

interface Props {
  bundleURL: string;
  playerConfig: PlayerConfig;
  content: string;
  height?: number;
}

interface State {
  loading: boolean;
  credentials: {
    passToken?: string;
    microEligibility?: string;
  };
  error: boolean;
}

class OnePlayer extends Component<Props & WithTranslation, State> {
  private playerRef: any;
  private livePlayerWrapper: any;

  public readonly state = {
    loading: true,
    credentials: {},
    error: false
  };

  public componentDidMount() {
    initScript(this.props.bundleURL).then(this.checkCredentials);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.content !== nextProps.content) {
      if (!this.state.loading) {
        // Use checkCredentials method instead of onOnePlayerAPILoad
        // because it seem that the last doesn't reload the player
        this.checkCredentials();
      } else {
        initScript(nextProps.bundleURL).then(this.checkCredentials);
      }
    }
  }
  private checkCredentials = () => {
    const credentials: any = {};
    const passToken = clientCookies.get('AuthOTTTokenV2');
    const microEligibility = clientCookies.get('microEligibility');

    if (passToken) {
      credentials.passToken = passToken;
    }

    if (microEligibility) {
      credentials.microEligibility = microEligibility;
    }

    this.setState({ loading: false, credentials }, this.onOnePlayerAPILoad);
  };

  private onOnePlayerAPILoad = () => {
    initPlayer(
      this.livePlayerWrapper,
      {
        ...this.props.playerConfig,
        content: this.props.content
      },
      this.state.credentials
    ).then((res: any) => {
      if (!res) {
        this.setState({ error: true });
      } else {
        this.playerRef = res;
      }
    });
  };

  public componentWillUnmount() {
    if (this.playerRef) {
      this.playerRef.destroy();
    }
  }

  public render(): React.ReactNode {
    const { loading, error } = this.state;
    return (
      <Container height={this.props.height}>
        {loading && <Loader />}

        {error ? (
          <Title>
            <Trans
              i18nKey="components.onePlayer.notSupported"
              components={[<br />]}
            />
          </Title>
        ) : (
          <div ref={el => (this.livePlayerWrapper = el)} />
        )}
      </Container>
    );
  }
}

export default withTranslation()(OnePlayer);
