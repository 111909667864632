import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  ChangeEvent
} from 'react';
import { SearchBoxProvided } from 'react-instantsearch-core';
import { connectSearchBox } from 'react-instantsearch-dom';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import { Input } from './styles/components';
import { SEARCHBOX_DEBOUNCE_TIMEOUT } from './constants';

interface Props extends SearchBoxProvided {
  withBottomRadius: boolean;
  mainColor: string;
}

function SearchBox({
  currentRefinement,
  refine,
  withBottomRadius,
  mainColor
}: Props) {
  const { t } = useTranslation();
  const input = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState(currentRefinement);

  useEffect(() => {
    if (input.current) {
      input.current.focus();
    }
  }, []);

  const debouncedRefine = useMemo(
    () => debounce(refine, SEARCHBOX_DEBOUNCE_TIMEOUT),
    [refine]
  );

  const debouncedOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const currentValue = event.currentTarget.value;

      debouncedRefine(currentValue);
      setValue(currentValue);
    },
    [debouncedRefine]
  );

  return (
    <Input
      ref={input}
      type="text"
      value={value}
      onChange={debouncedOnChange}
      caretColor={mainColor}
      withBottomRadius={withBottomRadius}
      placeholder={t('blocks.searchEngine.findHelp')}
      data-testid="search-engine-searchbox"
    />
  );
}

export default connectSearchBox(SearchBox);
