import React from 'react';

import {
  Wrapper,
  Container,
  ImageContainer,
  Link,
  Image,
  TextContainer,
  Description
} from '../styles/components';
import CallToActionRenderer from '../CallToActionRenderer';
import { FormattedProps } from '../types';
import Title from 'shared/components/presentational/Title';

export default function AlternatingPresentation({ items }: FormattedProps) {
  return (
    <Wrapper>
      {items.map((item, index) => {
        return (
          <Container key={index}>
            <ImageContainer>
              {item.banner.link ? (
                <Link to={item.banner.link}>
                  <Image responsivesUrls={item.banner.pictures} />
                </Link>
              ) : (
                <Image responsivesUrls={item.banner.pictures} />
              )}
            </ImageContainer>
            <TextContainer>
              <Title titleLabel={item.title} titleHtmlTag="h2" />
              <Description>{item.description}</Description>
              {item.callToAction && (
                <CallToActionRenderer {...item.callToAction} />
              )}
            </TextContainer>
          </Container>
        );
      })}
    </Wrapper>
  );
}
