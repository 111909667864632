import axios from 'axios';

import { DECRYPT_DATA } from 'server/constants/routes';
import {
  DecryptedPayload,
  DecryptedFormData,
  EncryptedFormData
} from '../types';

export default async (
  encryptedFormData: EncryptedFormData
): Promise<DecryptedFormData> => {
  try {
    const { data } = await axios.post<DecryptedPayload<DecryptedFormData>>(
      DECRYPT_DATA,
      {
        data: encryptedFormData
      }
    );
    return data.payload;
  } catch {
    return Promise.reject();
  }
};
