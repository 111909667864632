import styled from 'styled-components';

import { container } from 'shared/constants/mixins';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import BaseButton from 'shared/components/presentational/Button';

/*
 * Main wrapper should be a section
 * in order to inherit margins
 */
export const Wrapper = styled.section`
  ${container}

  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQueries.fromTablet`
    align-items: var(--title-items-position, var(--text-align));
  `};
`;

export const StyledButton = styled(BaseButton)`
  margin-top: 8px;
  text-align: center;
`;
