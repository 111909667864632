import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 34 34" width={width} height={height} className={className}>
    <path
      d="M19.5 32.8c.3.4.8.6 1.3.6.6 0 1.1-.3 1.4-.8.1-.1.1-.2.2-.3l1.5-5.3h3.5c3.5 0 6.3-2.8 6.3-6.3V6.9c0-3.5-2.8-6.3-6.3-6.3H6.7C3.2.6.4 3.4.4 6.9v13.9c0 3.5 2.8 6.3 6.3 6.3h7.2l5.6 5.7zm-16.6-12V6.9c0-2.1 1.7-3.8 3.8-3.8h20.6c2.1 0 3.8 1.7 3.8 3.8v13.9c0 2.1-1.7 3.8-3.8 3.8h-4.4c-.5 0-1.1.4-1.2.9l-1.3 4.6-5-5.2c-.3-.3-.5-.4-.9-.4H6.7c-2.1.1-3.8-1.6-3.8-3.7z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
