import { OTHER_CATEGORIES } from '../constants';
import { OffersByCategory } from './getOffersByCategory';

export default (offersByCategory: OffersByCategory) => {
  const categories = Object.keys(offersByCategory).filter(
    category => category !== OTHER_CATEGORIES
  );

  if (Object.keys(offersByCategory).includes(OTHER_CATEGORIES)) {
    categories.push(OTHER_CATEGORIES);
  }

  return categories;
};
