import React, { Component, ReactNode, RefObject } from 'react';
import styled from 'styled-components';

import { SizesByDevice } from 'shared/components/presentational/Picture/types';
import provideAdditionalSourceImages from 'shared/components/presentational/Picture/helpers/provideAdditionalSourceImages';
import generateSizes from 'shared/components/presentational/Picture/helpers/generateSizes';
import getThumborUrl from 'shared/components/presentational/Picture/helpers/getThumborUrl';
import provideResponsivesImages from '../helpers/provideResponsivesImages';
export const BaseWrapper = styled.picture<{
  backgroundColor?: string;
}>`
  display: inline-block;
  ${props =>
    props.backgroundColor && `background-color: ${props.backgroundColor};`}

  width: 100%;
  line-height: 0;

  source {
    display: inline-block;
    line-height: 0;
  }
`;

const Image = styled.img<Omit<State, 'didMount'>>`
  transition: ${props => (props.forceLoad ? 'none' : 'opacity 0.5s ease-out')};
  opacity: ${props => (props.forceLoad || props.imageVisible ? '1' : '0')};
`;

export interface Props {
  url?: string;
  externalUrl?: string;
  sizes?: SizesByDevice;
  className?: string;
  width?: number;
  height?: number;
  alt?: string;
  placeholderColor?: string;
  disableLazy?: boolean;
  hasAnchor: boolean;
  thumborFilter?: number;
  customWrapper?: React.FC;
  responsivesUrls?: SizesByDevice;
}

interface State {
  imageVisible: boolean;
  forceLoad: boolean;
  didMount: boolean;
}

class Picture extends Component<Props, State> {
  private readonly ref: RefObject<HTMLImageElement>;
  public constructor(props: Props) {
    super(props);
    this.ref = React.createRef();
  }

  public readonly state = {
    imageVisible: false,
    forceLoad: false,
    didMount: false
  };

  public static readonly defaultProps = {
    disableLazy: false,
    hasAnchor: false,
    sizes: {}
  };

  public static getDerivedStateFromProps(props: Props, state: State) {
    // If the prop `disableLazy` is passed we set forceLoad state to true in order to add src and srcSet attributes
    if (props.disableLazy) {
      return {
        forceLoad: true
      };
    }
    // If an anchor is defined in the url and picture already mounted we set forceLoad state to true
    // We cannot set forceLoad to true on the first render as it would differ from the server side render
    if (props.hasAnchor && state.didMount) {
      return {
        forceLoad: true
      };
    }
    return null;
  }

  public componentDidMount() {
    this.setState({ didMount: true });
    // If the prop `disableLazy` is passed, we don't initialize the lazy loading
    if (!this.props.disableLazy) {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: [0, 0.1, 0.5, 0.75, 1.0]
      };
      const observer = new IntersectionObserver(
        this.handleObserverCallback,
        options
      );

      if (this.ref.current) {
        observer.observe(this.ref.current);
      }
    }
  }

  private handleObserverCallback = (
    entries: IntersectionObserverEntry[]
  ): void => {
    const entry = entries[0];
    /**
     * Intersection ratio does not handle the horizontal axis properly.
     */
    const isComingFromRight =
      entry.rootBounds &&
      entry.boundingClientRect.left < entry.rootBounds.right &&
      entry.intersectionRatio > 0;

    const isComingFromLeft =
      entry.rootBounds &&
      entry.boundingClientRect.right < entry.rootBounds.left &&
      entry.intersectionRatio > 0;

    if (
      entry.intersectionRatio >= 0.1 ||
      isComingFromLeft ||
      isComingFromRight
    ) {
      this.setState({
        imageVisible: true
      });
    }
  };

  private getSrc(): {
    src: string;
    srcSet: string | undefined;
    sources: { url: string; size: string }[] | undefined;
  } {
    const {
      externalUrl,
      url,
      width,
      height,
      sizes,
      responsivesUrls,
      thumborFilter
    } = this.props;
    const urlExtension = url?.split('.').pop();
    let src;
    if (externalUrl) {
      src = externalUrl;
    } else if (urlExtension === 'svg') {
      src = url;
    } else {
      src = getThumborUrl({ url, width, height, thumborFilter });
    }
    let srcSet;
    if (externalUrl || urlExtension === 'svg') {
      srcSet = undefined;
    } else if (!width && height) {
      srcSet = getThumborUrl({ url, width, height: height * 2, thumborFilter });
    } else if (width) {
      const _sizes = generateSizes(width, height);
      srcSet = provideAdditionalSourceImages(url, _sizes, thumborFilter);
    } else {
      srcSet = provideAdditionalSourceImages(url, sizes, thumborFilter);
    }

    let sources;
    //responsivesUrls : the devices must be ordered in descending order in the object
    if (responsivesUrls) {
      sources = provideResponsivesImages(responsivesUrls, thumborFilter);
    }

    return { src, srcSet, sources };
  }

  public render(): ReactNode {
    const { className, alt, placeholderColor, customWrapper } = this.props;
    const { imageVisible, forceLoad } = this.state;
    const { src, srcSet, sources } = this.getSrc();

    const Wrapper = customWrapper ? customWrapper : BaseWrapper;
    return (
      <Wrapper backgroundColor={placeholderColor}>
        {sources
          ? sources.map(source => {
              return (
                <source
                  key={source.size}
                  srcSet={source.url}
                  media={source.size}
                />
              );
            })
          : null}
        <Image
          data-testid="picture-img"
          ref={this.ref}
          className={className}
          alt={alt}
          src={imageVisible || forceLoad ? src : undefined}
          srcSet={imageVisible || forceLoad ? srcSet : undefined}
          forceLoad={forceLoad}
          imageVisible={imageVisible}
        />
      </Wrapper>
    );
  }
}

export default Picture;
