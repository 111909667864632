import decryptFormData from '../../shared/webservices/decryptFormData';
import clearOfferSubscriptionForm from './clearOfferSubscriptionForm';
import getSavedFormData from '../selectors/getSavedFormData';
import {
  CHECK_INTERVAL,
  CHECK_OFFER_SUBSCRIPTION_FORM_ACTION_TYPE
} from '../constants';

let checkTimeout: NodeJS.Timeout | undefined;

/**
 * Action loop which aims to remove the encrypted data from store (persisted in sessionStorage) if expired.
 * This loop is independent from the saving and retrieving mechanisms. It exists and lives,
 * whether a form has been saved or not.
 */
const checkEncryptedOfferSubscriptionForm = async (
  dispatch: any,
  getState: any
) => {
  dispatch({ type: CHECK_OFFER_SUBSCRIPTION_FORM_ACTION_TYPE });
  try {
    const savedFormData = getSavedFormData(getState());
    if (savedFormData) {
      await decryptFormData(savedFormData);
    }
  } catch {
    dispatch(clearOfferSubscriptionForm);
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  checkTimeout = setTimeout(
    () => dispatch(checkEncryptedOfferSubscriptionForm),
    CHECK_INTERVAL
  );
};

export default checkEncryptedOfferSubscriptionForm;
