/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { Content, FormattedProps } from './types';
import { getLinkHref } from 'shared/helpers/uri';

export default (content: Content): FormattedProps => ({
  title: content.title,
  subtitle: content.subtitle,
  services: content.services.map(service => ({
    ...service,
    icon: service.icon
      ? {
          image: getLinkHref(service.icon.link),
          alt: service.icon.description || service.icon.filename
        }
      : undefined,
    link: getLinkHref(service.link)
  }))
});
