import styled, { keyframes } from 'styled-components';

import Picture from 'shared/components/presentational/Picture';

export const IconStyled = styled.div<{ dark: boolean }>`
  padding: 7px;
  border-radius: 50%;
  fill: white;
  height: 32px;
  max-width: 32px;
  background-color: white;
  fill: #212429;
`;

export const HeaderProfileIconStyled = styled.div`
  fill: white;
  width: 32px;
  transition: 150ms filter ease;
  transform: scale(1);

  &:hover {
    transform: scale(1.1);
    filter: drop-shadow(0 0.1rem 0.4rem rgba(0, 0, 0, 0.5));
  }

  img,
  svg {
    width: 100%;
    height: 100%;
  }
`;

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ProfileAvatarStyled = styled.div`
  position: relative;

  width: 100%;
  img {
    width: 100%;
  }
`;

export const PictureStyled = styled(Picture)`
  animation: ${opacity} 350ms ease-in;
  padding: 3px;
  border-radius: 50%;
  background-color: white;
  fill: white;
  height: 32px !important ;
  width: 32px;
`;
