import React from 'react';
import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import {
  FOOTER_HEIGHT,
  HEADER_DESKTOP_HEIGHT,
  HEADER_MOBILE_HEIGHT,
  HEADER_UNIFIED_DESKTOP_HEIGHT,
  HEADER_UNIFIED_MOBILE_HEIGHT
} from 'shared/constants/heights';
import { IframeProps } from '../types';

const StyledIframe = styled.iframe<{
  hasUnifiedMenu: IframeProps['hasUnifiedMenu'];
}>`
  width: 100%;
  height: calc(
    100vh -
      ${props =>
        (props.hasUnifiedMenu
          ? HEADER_UNIFIED_DESKTOP_HEIGHT
          : HEADER_DESKTOP_HEIGHT) + FOOTER_HEIGHT}px
  );
  border: none;
  ${mediaQueries.toTablet<{ hasUnifiedMenu: IframeProps['hasUnifiedMenu'] }>`
    height: calc(
    100vh -
      ${props =>
        (props.hasUnifiedMenu
          ? HEADER_UNIFIED_MOBILE_HEIGHT
          : HEADER_MOBILE_HEIGHT) + FOOTER_HEIGHT}px
  );

  `};
`;

const Iframe = ({ src, hasUnifiedMenu, srcdoc }: IframeProps) => {
  return (
    <StyledIframe
      data-testid="iframe"
      hasUnifiedMenu={hasUnifiedMenu}
      src={src}
      srcDoc={srcdoc}
    />
  );
};

export default Iframe;
