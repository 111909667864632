import decryptFormData from '../../shared/webservices/decryptFormData';
import clearOfferSubscriptionForm from './clearOfferSubscriptionForm';
import getSavedFormData from '../selectors/getSavedFormData';
import { RETRIEVE_OFFER_SUBSCRIPTION_FORM_ACTION_TYPE } from '../constants';

export default async (dispatch: any, getState: any) => {
  dispatch({ type: RETRIEVE_OFFER_SUBSCRIPTION_FORM_ACTION_TYPE });

  try {
    const savedFormData = getSavedFormData(getState());

    if (savedFormData) {
      return await decryptFormData(savedFormData);
    }
  } catch {
    dispatch(clearOfferSubscriptionForm);
  }

  return Promise.reject();
};
