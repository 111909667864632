import React from 'react';
import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import Icon from 'shared/components/presentational/Icons';
import {
  CustomTitle,
  CustomSubtitle
} from 'shared/components/presentational/Title/sharedTitles';
import { MEAInfosProps } from '../types';
const MeaInfos = styled.div`
  position: absolute;
  bottom: 27px;
  left: 29px;
  color: white;
  ${mediaQueries.toPhablet`
    bottom: 20px;
    left: 15px;
  `};
  &:hover {
    ${CustomTitle} {
      text-decoration: underline;
    }
  }
`;
const MEASubtitle = styled(CustomSubtitle)`
  text-align: left;
`;

const StyledIcon = styled(Icon)`
  margin-left: 8px;
  font-size: ${pxInRem(12)};
`;

export default function MEAInfos({
  title,
  subtitle,
  titleHtmlTag,
  titleColor
}: MEAInfosProps) {
  return (
    <MeaInfos>
      {title && (
        <CustomTitle htmlTag={titleHtmlTag} color={titleColor}>
          {title}
        </CustomTitle>
      )}
      {subtitle && (
        <MEASubtitle color={titleColor}>
          {subtitle} <StyledIcon name="Arrow" />
        </MEASubtitle>
      )}
    </MeaInfos>
  );
}
