/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import loadable from '@loadable/component';

export const namespace = 'canalplus.block.program_mea_categories';
export const Component = loadable(() =>
  import('./containers/ProgramMEACategoriesContainer')
);
export { default as propsFormatter } from './propsFormatter';
