import React from 'react';
import styled from 'styled-components';

import Picture from 'shared/components/presentational/Picture';
import Banner from 'shared/components/presentational/Banner/Banner';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { COLORS } from 'shared/constants/theme';
import OfferList from './OfferList';
import OfferTabs from './OfferTabs';
import { Template, OffersProps, FormattedOffer } from '../types';
import Title from 'shared/components/presentational/Title';

const isHighlightedTemplate = (template: Template) =>
  template.includes('highlight');
const isDefaultTemplate = (template: Template) => template === 'default';
const isBannerTemplate = (template: Template) => template === 'highlightBanner';
const isImageTemplate = (template: Template) => template === 'highlightImage';
const isTabsTemplate = (template: Template) => template === 'tabs';

const isStar = (expected: boolean) => (offer: FormattedOffer) =>
  offer.isStar === expected;

const Wrapper = styled.section<{
  template: Template;
  backgroundColor?: string | null;
}>`
  margin: 0 auto;
  max-width: ${props => (isTabsTemplate(props.template) ? '100%' : '1170px')};
  padding-right: 5px;
  padding-left: 5px;
  ${props =>
    (isHighlightedTemplate(props.template) || isTabsTemplate(props.template)) &&
    `
    padding-top: 40px;
    padding-bottom: 40px;
  `};
  text-align: center;
  background-color: ${props => props.backgroundColor || COLORS.white};

  ${mediaQueries.toTablet<{
    template: Template;
    backgroundColor?: string | null;
  }>`
    margin: ${props => (isTabsTemplate(props.template) ? '0' : '0 10px')};
    padding-left: 20px;
    padding-right: 20px;
  `};
  ${mediaQueries.toPhablet`
    margin: 0 0;
    padding: 30px 8px 40px;
  `};
`;

const StyledPicture = styled(Picture)`
  width: 100%;
`;

const ColumnsWrapper = styled.div`
  ${mediaQueries.toPhablet`
    margin-top: 20px;
  `};
`;

const Offers = ({
  backgroundColor,
  template,
  offers,
  title,
  titleColor,
  titleHtmlTag,
  subtitle,
  imageUrl,
  banner
}: OffersProps) => (
  <Wrapper
    template={template}
    backgroundColor={
      isHighlightedTemplate(template) || isTabsTemplate(template)
        ? backgroundColor
        : null
    }
  >
    {(title || subtitle) && (
      <Title
        titleLabel={title}
        subtitle={subtitle}
        titleColor={titleColor}
        titleHtmlTag={titleHtmlTag}
      />
    )}
    {isImageTemplate(template) && (
      <StyledPicture url={imageUrl} width={1020} height={430} />
    )}
    {isBannerTemplate(template) && <Banner {...banner} />}

    <ColumnsWrapper>
      {isTabsTemplate(template) ? (
        <OfferTabs offers={offers} />
      ) : (
        <>
          <OfferList
            offers={offers.filter(isStar(false))}
            backgroundColor={
              isDefaultTemplate(template) ? backgroundColor : null
            }
          />
          <OfferList
            offers={offers.filter(isStar(true))}
            backgroundColor={
              isDefaultTemplate(template) ? backgroundColor : null
            }
          />
        </>
      )}
    </ColumnsWrapper>
  </Wrapper>
);

export default Offers;
