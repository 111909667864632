import React from 'react';

import { SignButton } from '../types';
import { SignCTAIcon, SignCTALabel, SignCTA } from '../styles/components';
import Icon from 'shared/components/presentational/Icons';
import Picture from 'shared/components/presentational/Picture';

export const Button = ({ icon, buttonTheme, label, link }: SignButton) => {
  if (!link) {
    return null;
  }

  return (
    <SignCTA to={link} theme={buttonTheme}>
      <SignCTALabel>{label}</SignCTALabel>
      <SignCTAIcon>
        {icon ? (
          <Picture
            url={icon.link?.href}
            alt={icon.description || ''}
            width={22}
          />
        ) : (
          <Icon
            arial-label={label}
            dark
            name="Subscribe"
            width="23"
            height="23"
          />
        )}
      </SignCTAIcon>
    </SignCTA>
  );
};
