import React from 'react';

import { FormattedTileV1 } from '../types';
import { CardTemplate1 } from './Card';
import { CardsWrapperTemplate1 } from './components';

interface Props {
  tiles: FormattedTileV1[];
  columns: string;
}

export const TilesV1 = ({ tiles, columns }: Props) => {
  const renderCardsTemplate1 = React.useCallback(
    (tile: FormattedTileV1, index: number) => (
      <CardTemplate1 key={index} tile={tile} />
    ),
    []
  );
  return (
    <CardsWrapperTemplate1 columns={columns}>
      {tiles.map(renderCardsTemplate1)}
    </CardsWrapperTemplate1>
  );
};
