/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import queryString from 'query-string';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import delay from 'lodash/delay';

import Icon from 'shared/components/presentational/Icons/Icon';
import ResponsiveHoc from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import {
  getThematics,
  getQuestionsByThematics,
  formatQuestionId
} from '../helpers';
import Menu, { MENU_OFFSET } from './Menu';
import ModalMenu from './ModalMenu';
import Thematic from './Thematic';
import {
  containerStyled,
  wrapperStyled,
  contentStyled
} from '../../shared/styles';
import {
  HEADER_DESKTOP_HEIGHT,
  HEADER_MOBILE_HEIGHT,
  HEADER_UNIFIED_DESKTOP_HEIGHT,
  HEADER_UNIFIED_MOBILE_HEIGHT
} from 'shared/constants/heights';
import { COLORS } from 'shared/constants/theme';
import { scrollTo } from 'shared/helpers/viewport';
import Title from 'shared/components/presentational/Title';

const Container = styled.section`
  ${containerStyled};
`;

const Aside = styled.div`
  position: relative;
  margin-top: ${MENU_OFFSET}px;
  flex: 1;
  ${mediaQueries.toPhablet`
    display: none;
  `};
`;

const Wrapper = styled.div`
  ${wrapperStyled};
  max-width: 900px;
`;

const Content = styled.div`
  ${contentStyled};
`;

// offset between FAB and bottom of the viewport
const FLOAT_BUTTON_OFFSET = 25;

const FloatButton = styled.div`
  display: ${props => (props.modalOpen === true ? 'none' : 'flex')};
  ${mediaQueries.fromTablet`display: none;`};
  justify-content: center;
  align-items: center;
  right: 25px;
  position: absolute;

  ${props =>
    props.position === 'top' &&
    css`
      top: ${FLOAT_BUTTON_OFFSET}px;
    `} ${props =>
    props.position === 'fixed' &&
    css`
      position: fixed;
      bottom: ${FLOAT_BUTTON_OFFSET}px;
    `} ${props =>
    props.position === 'bottom' &&
    `
    bottom: ${FLOAT_BUTTON_OFFSET}px;
  `} width: 50px;
  height: 50px;
  background-color: ${COLORS.black};
  border-radius: 50%;
`;

const CategoriesIcon = styled(Icon)`
  color: ${COLORS.white};
`;

const getHeaderDesktopHeight = hasUnifiedMenu =>
  hasUnifiedMenu ? HEADER_UNIFIED_DESKTOP_HEIGHT : HEADER_DESKTOP_HEIGHT;

const getHeaderMobileHeight = hasUnifiedMenu =>
  hasUnifiedMenu ? HEADER_UNIFIED_MOBILE_HEIGHT : HEADER_MOBILE_HEIGHT;

class FAQ extends Component {
  state = {
    wrapperRef: null,
    menuRef: null,
    menuPosition: 'top',
    modalState: false,
    openQuestion: {
      thematic: '',
      index: null
    },
    floatRef: null,
    floatButtonPosition: 'top'
  };

  componentDidMount() {
    const { urlParams } = this.props;
    // setting up the scroll event listener for menu highlight
    window.addEventListener('scroll', this.handleMenuPosition);
    window.addEventListener('scroll', this.handleFloatButtonPosition);

    // on mount, we navigate to the current thematic and index
    // a small delay aims to wait for the page to render properly
    delay(() => {
      this.handleNavigate(urlParams);
    }, 500);
  }

  componentWillUnmount() {
    // stop listening to scroll
    window.removeEventListener('scroll', this.handleMenuPosition);
    window.removeEventListener('scroll', this.handleFloatButtonPosition);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // when thematic and index change in query parameters, we navigate to the new given ones
    if (!isEqual(nextProps.urlParams, this.props.urlParams)) {
      this.handleNavigate(nextProps.urlParams);
    }

    // when screen size changes, we refresh navigation; this will reset the scroll to the current thematic and index
    if (!isEqual(nextProps.device, this.props.device)) {
      this.handleNavigate(nextProps.urlParams, nextProps.device);
    }
  }

  handleContainerRef = containerRef => {
    this.setState({ containerRef });
  };

  handleWrapperRef = wrapperRef => {
    this.setState({ wrapperRef });
  };

  handleMenuRef = menuRef => {
    this.setState({ menuRef });
  };

  handleFloatRef = floatRef => {
    this.setState({ floatRef });
  };

  handleModalState = () => {
    // handle the menu modal state (for phone devices)
    this.setState({ modalState: !this.state.modalState });
  };

  handleThematicChange = thematic => {
    // on click on a thematic in the menu, change params
    this.props.pushURLHandler(`?${queryString.stringify({ thematic })}`);
  };

  handleNavigate = debounce(({ thematic, index }, forcedDevice) => {
    const device = forcedDevice || this.props.device;
    const { hasUnifiedMenu } = this.props;

    // smooth scroll then open question
    if (thematic) {
      // If question's index is defined, get the question element instead of thematic.
      const element = document.getElementById(
        index ? formatQuestionId({ thematic, index }) : thematic
      );

      // We compute offset values we will deduce because we don't want the content clipping underneath the header.
      const offsetHeader = ['hd', 'desktop'].includes(device)
        ? getHeaderDesktopHeight(hasUnifiedMenu)
        : getHeaderMobileHeight(hasUnifiedMenu); // offset according to header size
      const offsetElement = !index ? 20 : 5; // additional offset before a thematic or before a question

      if (element) {
        const elementViewportRelativePosition = element.getBoundingClientRect()
          .top;
        const documentViewportRelativePosition = document.body.getBoundingClientRect()
          .top;

        const elementAbsolutePosition =
          elementViewportRelativePosition - documentViewportRelativePosition;
        const offset = elementAbsolutePosition - offsetHeader - offsetElement;
        // @hack: we have to delay the scroll,
        // otherwise we are facing scroll issues with the last thematic.
        delay(() => scrollTo(offset), 1);
      }
    }
    this.handleOpenQuestion({ thematic, index });
  }, 300);

  handleOpenQuestion = ({ thematic, index = 0 }) => {
    let openQuestion;
    if (thematic) {
      openQuestion = { thematic, index };
    } else {
      openQuestion = { thematic: getThematics(this.props.data)[0], index: 0 };
    }
    this.setState({
      openQuestion
    });
  };

  handleFloatButtonPosition = () => {
    const { floatButtonPosition, containerRef, floatRef } = this.state;
    const { mediaQueries: toPhablet } = this.props;
    if (containerRef && floatRef && toPhablet) {
      const {
        top: topOffset,
        bottom: bottomOffset
      } = containerRef.getBoundingClientRect();
      const { height: floatButtonHeight } = floatRef.getBoundingClientRect();
      const targetTopOffset =
        window.innerHeight - floatButtonHeight - FLOAT_BUTTON_OFFSET * 2;
      const targetBottomOffset = window.innerHeight;

      if (targetBottomOffset > bottomOffset) {
        // when the view is after the block;
        if (floatButtonPosition !== 'bottom') {
          this.setState({
            floatButtonPosition: 'bottom'
          });
        }
      } else if (targetTopOffset >= topOffset) {
        // when the view is on the block;
        if (floatButtonPosition !== 'fixed') {
          this.setState({
            floatButtonPosition: 'fixed'
          });
        }
      } else if (floatButtonPosition !== 'top') {
        this.setState({
          // when the view is before the block;
          floatButtonPosition: 'top'
        });
      }
    }
  };

  handleMenuPosition = () => {
    const { menuPosition, wrapperRef, menuRef } = this.state;
    const { device, hasUnifiedMenu } = this.props;

    if (wrapperRef && menuRef) {
      const headerHeight = ['desktop', 'hd'].includes(device)
        ? getHeaderDesktopHeight(hasUnifiedMenu)
        : getHeaderMobileHeight(hasUnifiedMenu);

      const {
        top: topOffset,
        bottom: bottomOffset
      } = wrapperRef.getBoundingClientRect();
      const { height: menuHeight } = menuRef.getBoundingClientRect();
      const targetTopOffset = headerHeight;
      const targetBottomOffset = headerHeight + MENU_OFFSET + menuHeight;
      if (targetBottomOffset > bottomOffset) {
        if (menuPosition !== 'bottom') {
          this.setState({
            // when the view is after the block;
            menuPosition: 'bottom'
          });
        }
      } else if (targetTopOffset >= topOffset) {
        if (menuPosition !== 'fixed') {
          this.setState({
            // when the view is on the block;
            menuPosition: 'fixed'
          });
        }
      } else if (menuPosition !== 'top') {
        this.setState({
          // when the view is before the block;
          menuPosition: 'top'
        });
      }
    }
  };

  getOpenedQuestion = thematic => {
    const { openQuestion } = this.state;
    return openQuestion.thematic === thematic ? openQuestion.index : undefined;
  };

  render() {
    const {
      title,
      subtitle,
      data,
      t,
      titleColor,
      titleHtmlTag,
      hasUnifiedMenu
    } = this.props;
    const { floatButtonPosition, menuPosition, modalState } = this.state;
    const thematics = getThematics(data);
    const questionsByThematics = getQuestionsByThematics(data);

    return (
      <Container ref={this.handleContainerRef}>
        <Title
          titleLabel={title}
          titleHtmlTag={titleHtmlTag}
          titleColor={titleColor}
          subtitle={subtitle}
        />
        <Wrapper ref={this.handleWrapperRef}>
          <Aside>
            <Menu
              ref={this.handleMenuRef}
              items={thematics}
              position={menuPosition}
              onClick={this.handleThematicChange}
              hasUnifiedMenu={hasUnifiedMenu}
            />
          </Aside>
          <Content>
            {thematics.map(thematic => (
              <Thematic
                key={thematic}
                thematic={thematic}
                questions={questionsByThematics[thematic]}
                openedQuestion={this.getOpenedQuestion(thematic)}
              />
            ))}
          </Content>
        </Wrapper>
        <FloatButton
          ref={this.handleFloatRef}
          position={floatButtonPosition}
          onClick={this.handleModalState}
          modalOpen={modalState}
        >
          <CategoriesIcon name="Categories" width={24} height={18} />
        </FloatButton>
        <ModalMenu
          modalState={modalState}
          items={thematics}
          onClick={this.handleThematicChange}
          handleModalState={this.handleModalState}
          t={t}
        />
      </Container>
    );
  }
}

export default ResponsiveHoc(FAQ);
