/* eslint-disable import/no-dynamic-require,no-unused-vars,no-console */
/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import merge from 'lodash/merge';

import baseConfig from '../../../../config/default.json';
import development from '../../../../config/development.json';
import acceptance from '../../../../config/acceptance.json';
import preProduction from '../../../../config/pre-production.json';
import production from '../../../../config/production.json';
import localBack from '../../../../config/local-back.json';

const DEFAULT = 'development';

const configByEnv = {
  'local-back': localBack,
  development,
  acceptance,
  'pre-production': preProduction,
  production
};

const currentEnvConfig =
  configByEnv[process.env.RAZZLE_NODE_CONFIG_ENV] || configByEnv[DEFAULT];
const finalConfig = merge(baseConfig, currentEnvConfig);

export default () => finalConfig.public;
