import React, { ReactNode } from 'react';
import useSharedRef from 'react-use-shared-ref';

import isChildrenOneElement from 'shared/helpers/react/isChildrenOneElement';

interface Props {
  anchorId: string;
  children: ReactNode;
}

export default ({ children, anchorId }: Props) => {
  if (!isChildrenOneElement(children)) {
    return children;
  }

  const { createRef } = useSharedRef();
  const ref = createRef(anchorId);

  return React.cloneElement(children, { ref });
};
