import { useEffect } from 'react';
import { connectPagination } from 'react-instantsearch-dom';

// as Algolia doesn't provide props types for this widget
interface Props {
  currentPage: number;
  refine: (page: number) => void;
}

function VirtualPagination({ currentPage, refine }: Props) {
  useEffect(() => {
    refine(currentPage);
  }, [refine, currentPage]);

  return null;
}

export default connectPagination(VirtualPagination);
