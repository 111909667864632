export const SEARCHBOX_MIN_CHARACTERS_REFINE = 2;
export const SEARCHBOX_DEBOUNCE_TIMEOUT = 300; // milliseconds

export const SEARCH_HIGHLIGHT_CLASS = 'search-highlight';
export const SEARCH_HIGHLIGHT_TAG = 'mark';
export const INDEX_EMPTY_RESULTS = {
  hits: [],
  nbHits: 0,
  nbPages: 0,
  page: 0,
  processingTimeMS: 0
};

export const ARTICLES_REGEXP = /ARTICLES/;

export const FAQ_ROOT = 'aide-en-ligne';

export const INDEX_NAMES = ['dev_FAQ', 'valid_FAQ', 'prod_FAQ'];
