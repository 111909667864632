import React from 'react';

import { StyledButton, Wrapper } from './styles/component';
import { Position, FormattedProps } from '../types';
import BaseButton from 'shared/components/presentational/Button';
import Title from 'shared/components/presentational/Title';

const STYLES: Record<Position, { textAlign: string; alignItems: string }> = {
  [Position.ALIGN_LEFT]: {
    textAlign: 'left',
    alignItems: 'start'
  },
  [Position.ALIGN_RIGHT]: {
    textAlign: 'right',
    alignItems: 'end'
  },
  [Position.ALIGN_CENTER]: {
    textAlign: 'center',
    alignItems: 'center'
  }
};

export default function Button({
  position,
  href,
  label,
  subtitle,
  theme,
  title,
  titleColor,
  titleHtmlTag
}: FormattedProps) {
  const { textAlign, alignItems } = STYLES[position];

  return (
    <Wrapper
      style={
        {
          '--title-position': textAlign ? textAlign : undefined,
          '--title-items-position': alignItems ? alignItems : undefined
        } as React.CSSProperties
      }
    >
      <Title
        titleLabel={title}
        subtitle={subtitle}
        titleHtmlTag={titleHtmlTag}
        titleColor={titleColor}
      />
      <StyledButton theme={theme} to={href}>
        <BaseButton.children.Text>{label}</BaseButton.children.Text>
      </StyledButton>
    </Wrapper>
  );
}
