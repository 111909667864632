/* eslint-disable no-nested-ternary */

import React, { PureComponent } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Modal from 'react-modal';
import { TFunction } from 'i18next';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { Z_INDEX } from '../../constants';
import BoxLogged from './BoxLogged';
import BoxNotLogged from './BoxNotLogged';
import Separator from './Separator';
import _QuickAccess from './QuickAccess';
import _Menu from './Menu';
import _CanalBoxLink from './CanalBoxLink';
import SelectField from 'shared/components/presentational/Select/SelectField';
import { FormattedProps, PropsFromState, WithConnected } from '../../types';
import { HEADER_MOBILE_HEIGHT } from 'shared/constants/heights';

const GlobalStyle = createGlobalStyle` 
  .NavigationMobile_overlay {
    ${mediaQueries.toTablet`
      background-color: rgba(0, 0, 0, 0.75)!important;
    `};
  }
`;

const Scrollable = styled.div`
  width: 100%;
  overflow-y: scroll;
  display: none;

  ${mediaQueries.toTablet`
    display: block;
  `};
`;

const Menu = styled(_Menu)<{ backgroundColor: string }>`
  width: 100%;
  padding: 20px 0;
  background-color: ${props => props.backgroundColor};
`;

const QuickAccess = styled(_QuickAccess)<{ color: string }>`
  width: 100%;
  background-color: ${props => props.color};
`;

const CanalBoxLink = styled(_CanalBoxLink)`
  padding-left: 15px;
`;

const StyledSelectField = styled.div<{ backgroundColor: string }>`
  padding: 20px 14px 0;
  background-color: ${props => props.backgroundColor};
`;

const modalStyles = {
  overlay: {
    ...Modal.defaultStyles.overlay,
    backgroundColor: 'transparent',
    zIndex: Z_INDEX.NAVIGATION_MOBILE
  },
  content: {
    position: 'fixed',
    top: `${HEADER_MOBILE_HEIGHT}px`,
    bottom: 'auto',
    width: '100%',
    maxWidth: '540px',
    outline: 'none',
    justifyContent: 'flex-start',
    flexDirection: 'column'
  }
};

export interface Props
  extends Omit<
      FormattedProps,
      'logoUrl' | 'authenticationUrl' | 'redirectUrlAfterLogin' | 'algolia'
    >,
    Omit<PropsFromState, 'bigram' | 'homeUrl' | 'domain'>,
    WithConnected {
  close: () => void;
  isOpen: boolean;
  onItemClick: () => void;
  onLangSectionChanged: (lang: string) => void;
  t: TFunction;
}

export default class NavigationMobile extends PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      connected,
      mainMenuItems,
      quickAccessMenuItems,
      close,
      isOpen,
      onItemClick,
      logoutUrl,
      loginUrl,
      canalBoxUrl,
      canalBoxTitle,
      backgroundColor,
      mainLinksColor,
      quickAccessLinksColor,
      isAllowedToConnect,
      t,
      langs,
      pageLang,
      onLangSectionChanged,
      boxTitle,
      boxSubscriptionManagementLabel,
      boxSubscriptionManagementUrl,
      boxLoyaltyProgramLabel,
      boxLoyaltyProgramUrl,
      signupCanalPlusTitle,
      signupCanalPlusLinkLabel,
      signupCanalPlusLink
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={close}
        style={modalStyles}
        ariaHideApp={false}
        overlayClassName="NavigationMobile_overlay"
        shouldCloseOnOverlayClick
      >
        <Scrollable>
          <StyledSelectField backgroundColor={backgroundColor}>
            <SelectField
              options={Object.entries(langs).map(([value, { label }]) => ({
                label,
                value
              }))}
              currentValue={pageLang}
              onSelectionChanged={onLangSectionChanged}
              backgroundColor={backgroundColor}
              textColor={mainLinksColor}
              menuPlacement="bottom"
              border
            />
          </StyledSelectField>

          <Menu
            backgroundColor={backgroundColor}
            color={mainLinksColor}
            items={mainMenuItems}
            onItemClick={onItemClick}
          />

          {isAllowedToConnect &&
            (loginUrl && connected ? (
              <BoxLogged
                logoutUrl={logoutUrl}
                onItemClick={onItemClick}
                boxTitle={boxTitle}
                boxSubscriptionManagementLabel={boxSubscriptionManagementLabel}
                boxSubscriptionManagementUrl={boxSubscriptionManagementUrl}
                boxLoyaltyProgramLabel={boxLoyaltyProgramLabel}
                boxLoyaltyProgramUrl={boxLoyaltyProgramUrl}
                t={t}
              />
            ) : (
              <BoxNotLogged
                loginUrl={loginUrl}
                onItemClick={onItemClick}
                boxTitle={boxTitle}
                buttonColor={backgroundColor}
                signupCanalPlusTitle={signupCanalPlusTitle}
                signupCanalPlusLinkLabel={signupCanalPlusLinkLabel}
                signupCanalPlusLink={signupCanalPlusLink}
                t={t}
              />
            ))}

          {isAllowedToConnect && loginUrl && canalBoxUrl && <Separator />}
          {canalBoxUrl && (
            <CanalBoxLink
              title={canalBoxTitle}
              url={canalBoxUrl}
              onClick={onItemClick}
            />
          )}
          <QuickAccess
            color={quickAccessLinksColor}
            items={quickAccessMenuItems}
            onItemClick={onItemClick}
          />
        </Scrollable>
        <GlobalStyle />
      </Modal>
    );
  }
}
