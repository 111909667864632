import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Loader, { spinCentered } from './Loader';

const Placeholder = styled.div`
  position: relative;
`;

const CenteredLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  animation: ${spinCentered} 500ms infinite linear;
  transform-origin: top left;
`;

export default class LoaderPlaceHolder extends PureComponent {
  render() {
    const { className, style, ...props } = this.props;
    return (
      <Placeholder className={className} style={style}>
        <CenteredLoader {...props} />
      </Placeholder>
    );
  }
}
