import postOffer from '../webservices/postOffer';
import { PostOfferParams } from '../../shared/types';
import { ACTION_TYPES } from '../constants';
import clearOfferSubscriptionForm from 'shared/blocks/eshop/offer-form/actions/clearOfferSubscriptionForm';
import { sendEvent } from 'shared/helpers/tracking/sendEvent';

const EVENT_NAME = 'pay subscription';
function submitOffer(params: PostOfferParams) {
  return async (dispatch: any) => {
    dispatch({ type: ACTION_TYPES.SUBMIT_PENDING });

    try {
      const response = await postOffer(params);

      if (response && response.status === 200) {
        // Tealium tracking send envent on e-shop success
        const products = params?.contractInfo?.products;
        sendEvent(EVENT_NAME, {
          event_type: EVENT_NAME,
          event_name: EVENT_NAME,
          product_code: products ? products[0] : undefined
        });

        dispatch({
          type: ACTION_TYPES.SUBMIT_SUCCESS
        });
        dispatch(clearOfferSubscriptionForm);
      } else {
        throw new Error('An error occurred');
      }
    } catch ({ message }) {
      dispatch({
        type: ACTION_TYPES.SUBMIT_ERROR,
        payload: {
          error: message ? message : true
        }
      });
    }
  };
}

export default (params: PostOfferParams) => async (dispatch: any) =>
  submitOffer(params)(dispatch);
