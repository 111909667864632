import { Content, FormattedProps } from './types';
import { getLinkHref } from 'shared/helpers/uri';

export default function propsFormatter(content: Content): FormattedProps {
  return {
    title: content.title,
    notice: content.text,
    pictogram: content.pictogram
      ? getLinkHref(content.pictogram.link)
      : undefined
  };
}
