/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: content.title,
  subtitle: content.subtitle,
  text: content.text,
  button:
    content.button?.label && content.button?.link?.href
      ? {
          label: content.button?.label,
          href: getLinkHrefWithAuthentication(content.button?.link)
        }
      : null,
  mailRecipient: content.mailRecipient || null,
  withMailing: Boolean(content.mailRecipient),
  api: {
    zone: content.currentZone,
    url: content.apis?.np6?.url ?? '',
    method: content.apis?.np6?.method ?? 'POST'
  }
});
