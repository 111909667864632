const getMetaContent = (
  list: { key: META; content?: string; name?: string }[],
  key: META
) => list?.find(item => item.name === key)?.content;

type META = 'page_level_1' | 'page_level_2' | 'page_level_3' | 'page_name';

export default function sendPageViewEvent(
  pageName: string,
  meta: { key: META; content?: string; name?: string }[]
) {
  const pageViewData: SendPageView = {
    pageTitle: pageName
  };

  const page_name = getMetaContent(meta, 'page_name');
  if (page_name) {
    pageViewData['page_name'] = page_name;
  }

  const page_level_1 = getMetaContent(meta, 'page_level_1');
  if (page_level_1) {
    pageViewData['page_level_1'] = page_level_1;
  }

  const page_level_2 = getMetaContent(meta, 'page_level_2');
  if (page_level_2) {
    pageViewData['page_level_2'] = page_level_2;
  }

  const page_level_3 = getMetaContent(meta, 'page_level_3');
  if (page_level_3) {
    pageViewData['page_level_3'] = page_level_3;
  }

  if (window.sendPageView) {
    window.sendPageView({
      name: pageName,
      data: {
        ...pageViewData
      }
    });
  }
}
