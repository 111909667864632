import React, { useCallback } from 'react';

import { FormattedChannel } from '../../types';
import {
  ProductWrapper,
  ProductDetails,
  ProductTitle,
  ProductDescription,
  ProductPrice,
  ChannelsGroup,
  ChannelImage
} from './styles/components';
import OfferDetailsRocContainer from 'shared/blocks/one-shop/shared/containers/OfferDetailsRocContainer';
import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';
import {
  ProductMajor,
  ProductMinor
} from 'shared/blocks/one-shop/shared/types';

type ProductType = ProductMajor | ProductMinor;
type ProductProps = {
  isMainProduct: boolean;
  channels?: FormattedChannel[];
} & ProductType;

const Product = ({ isMainProduct, channels, ...product }: ProductProps) => {
  const renderChannel = useCallback((channel, index) => {
    return (
      <React.Fragment key={`${index}-${channel.title}`}>
        {channel.link ? (
          <CustomLink to={channel.link}>
            <ChannelImage src={channel.icon.link} alt={channel.icon.alt} />
          </CustomLink>
        ) : (
          <ChannelImage src={channel.icon.link} alt={channel.icon.alt} />
        )}
      </React.Fragment>
    );
  }, []);

  return (
    <ProductWrapper isMainProduct={isMainProduct}>
      <ProductDetails>
        <div>
          <ProductTitle>{product.title}</ProductTitle>
          <ProductDescription>{product.description}</ProductDescription>
        </div>
        <ProductPrice>
          <OfferDetailsRocContainer {...product.offerDetails} variant="modal" />
        </ProductPrice>
      </ProductDetails>
      {channels && <ChannelsGroup>{channels.map(renderChannel)}</ChannelsGroup>}
    </ProductWrapper>
  );
};

export default Product;
