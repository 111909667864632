/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2019 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  zones: content.zones,
  emailModificationLabel: content.emailModificationLabel ?? '',
  passwordModificationLabel: content.passwordModificationLabel ?? '',
  countryModificationLabel: content.countryModificationLabel ?? '',
  subscriptionModificationLabel: content.subscriptionModificationLabel ?? '',
  subscriptionExplanationLabel: content.subscriptionExplanationLabel ?? '',
  subscriptionNumAboExplanationLabel:
    content.subscriptionNumAboExplanationLabel ?? '',
  subscriptionNumCardExplanationLabel:
    content.subscriptionNumCardExplanationLabel ?? '',
  optOutLabel: content.optOutLabel ?? '',
  legalMentions: content.legalMentions ?? '',
  confirmationTitle: content.confirmationTitle ?? '',
  confirmationDescription: content.confirmationDescription ?? '',
  confirmationClosure: content.confirmationClosure ?? '',
  apis: {
    getSubscriber: {
      url: content.apis?.['register.pass.user_data_v2']?.url ?? '',
      method: content.apis?.['register.pass.user_data_v2']?.method || 'GET'
    },
    updateSubscriber: {
      url: content.apis?.['register.subscriber.update_by_pass_v2']?.url ?? '',
      method:
        content.apis?.['register.subscriber.update_by_pass_v2']?.method || 'PUT'
    },
    updateEmailToken: {
      url: content.apis?.['register.token.update_email_by_pass_v2']?.url ?? '',
      method:
        content.apis?.['register.token.update_email_by_pass_v2']?.method ||
        'POST'
    }
  },
  subscriptionNumCardExplanationMedia: getLinkHrefWithAuthentication(
    content.subscriptionNumCardExplanationMedia?.link ?? ''
  ),
  subscriptionNumCardExplanationMediaDescription:
    content.subscriptionNumCardExplanationMedia?.description ?? ''
});
