import * as React from 'react';
import styled from 'styled-components';

import BaseLink, {
  Props as LinkProps
} from 'shared/components/presentational/CustomLink/CustomLink';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import { Props } from 'shared/components/presentational/Tab/type';
import BasePicture from 'shared/components/presentational/Picture';

interface DefaultLinkProps extends LinkProps {
  color: string;
  backgroundcolor: string;
}

const DefaultLink = styled(BaseLink)<DefaultLinkProps>`
  width: 100%;
  text-align: center;
  color: ${props => props.color};
  border-radius: inherit;
  padding: 0.625rem 0.5rem;
  text-decoration: none;
  text-transform: uppercase;
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};
  line-height: ${pxInRem('20px')};
  background-color: ${props => props.backgroundcolor};
  ${mediaQueries.toPhablet`
      font-size: 10px;
  `};
`;

const ActiveLink = styled(DefaultLink)<DefaultLinkProps>`
  color: ${props => props.backgroundcolor};
  background-color: ${props => props.color};
`;

const PictogramLabel = styled.p<{ labelColor: string }>`
  color: ${props => props.labelColor};
  font-size: ${pxInRem('16px')};
  font-style: normal;
  font-weight: ${FONT_WEIGHTS.bold};
  line-height: ${pxInRem('19px')};
  letter-spacing: 0px;
  text-align: center;
  text-transform: none;
  margin-top: 4px;
`;
const Picture = styled(BasePicture)`
  max-height: 32px;
`;

const Tab = ({
  active = false,
  label,
  url,
  onClick,
  className,
  labelColor,
  backgroundColor,
  pictogram,
  currentTabColor
}: Props) => {
  const Link = active ? ActiveLink : DefaultLink;

  return (
    <Link
      to={url}
      onClick={onClick}
      className={className}
      color={pictogram ? currentTabColor! : labelColor}
      backgroundcolor={backgroundColor}
    >
      {pictogram && <Picture externalUrl={pictogram} />}
      {pictogram ? (
        <PictogramLabel labelColor={labelColor}> {label}</PictogramLabel>
      ) : (
        label
      )}
    </Link>
  );
};

export default Tab;
