/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';

import ResponsiveHoc, {
  ResponsiveHocProps
} from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';
import { FormattedProps } from '../types';
import { Wrapper, Slider, Slide } from '../styles/components';
import Title from 'shared/components/presentational/Title';

type Props = FormattedProps & ResponsiveHocProps;

function Poster({
  title,
  subtitle,
  slider,
  mediaQueries,
  titleColor,
  titleHtmlTag
}: Props) {
  const slidesToShow = mediaQueries.fromTablet
    ? 4
    : mediaQueries.fromPhablet
    ? 3
    : 1;

  return (
    <Wrapper data-testid="poster">
      <Title
        titleLabel={title}
        subtitle={subtitle}
        titleColor={titleColor}
        titleHtmlTag={titleHtmlTag}
      />
      <Slider slidesToShow={slidesToShow}>
        {slider.map((slide, index) => {
          const imageHref = mediaQueries.fromDesktop
            ? slide.mediaDesktop?.link?.href
            : mediaQueries.fromTablet
            ? slide.mediaTablet?.link.href ?? slide.mediaDesktop?.link.href
            : slide.mediaMobile?.link.href ?? slide.mediaDesktop?.link.href;

          return imageHref ? (
            <Slide
              key={index}
              alt={slide.alt}
              href={slide.link}
              disableThumbnailLazyLoad={true}
              externalImageUrl={imageHref}
              imageUrl={imageHref}
            />
          ) : null;
        })}
      </Slider>
    </Wrapper>
  );
}

export default ResponsiveHoc(Poster);
