/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';

import { FormattedProps } from '../types';
import Container from 'shared/components/presentational/Container/Container';
import DueDateComponent from 'shared/components/presentational/DueDate';

export default function DueDate(props: FormattedProps) {
  return (
    <Container data-testid="due-date">
      <DueDateComponent {...props} />
    </Container>
  );
}
