import React, { PureComponent } from 'react';
import styled from 'styled-components';

import BackgroundVideo from 'shared/components/presentational/BackgroundVideo/BackgroundVideo';
import BaseButton from 'shared/components/presentational/Button';
import { FONT_FAMILIES, FONT_WEIGHTS, COLORS } from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import Button from 'shared/components/presentational/Button/Button';
import BaseRaw from 'shared/components/presentational/Raw/Raw';
import { FormattedProps } from '../types';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

const Wrapper = styled.div<{ height: string; width: string }>`
  position: relative;
  display: flex;
  width: ${props => props.width};
  height: ${props => props.height};

  ${mediaQueries.toTablet`
    height: 480px;
  `};
`;

const TextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 10px;
`;

const Text = styled(BaseRaw)`
  font-weight: ${FONT_WEIGHTS.normal};
  font-family: ${FONT_FAMILIES.base};
  font-size: ${pxInRem(14)};
  color: ${COLORS.white};
  margin: 0;
  max-width: 970px;
`;

const StyledButton = styled(BaseButton)`
  margin-top: 30px;
`;

interface Props extends FormattedProps {
  width?: string;
  height?: string;
}

export default class BackgroundVideoBlocks extends PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      src,
      poster,
      width = '100%',
      height = '650px',
      text,
      button
    } = this.props;
    return (
      <Wrapper height={height} width={width}>
        <BackgroundVideo src={src} poster={poster} />
        <TextWrapper>
          {text && <Text html={text} />}
          {button.label && (
            <StyledButton theme={button.theme} type="submit" to={button.link}>
              <Button.children.Text>{button.label}</Button.children.Text>
            </StyledButton>
          )}
        </TextWrapper>
      </Wrapper>
    );
  }
}
