import { Store } from 'redux';
import i18next from 'i18next';

import clientCookies from 'client/cookies';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import getPageLang from 'shared/modules/page/selectors/getPageLang';
import getZoneLangs from 'shared/modules/page/selectors/getZoneLangs';
import setCookieHelper from 'shared/helpers/universal-cookie/setCookie';
import getOnlyDomain from 'shared/modules/domain/selectors/getOnlyDomain';

export default async function handleI18n(store: Store, request?: any) {
  const setCookie = (
    name: string,
    value: string,
    options?: Record<string, string>
  ) => {
    try {
      setCookieHelper(request.universalCookies, name, value, options);
    } catch (e) {
      setCookieHelper(clientCookies, name, value, options);
    }
  };

  const loadLanguages = (languages: string[]) => {
    try {
      return request.i18n.loadLanguages(languages);
    } catch (e) {
      return i18next.loadLanguages(languages);
    }
  };

  const changeLanguage = (language: string) => {
    try {
      return request.i18n.changeLanguage(language);
    } catch (e) {
      return i18next.changeLanguage(language);
    }
  };

  const state = store.getState();

  // Set cookies and i18next according to the given page lang
  const langs = getZoneLangs(state);
  const pageLang = getPageLang(state);
  const domain = getOnlyDomain(state);

  setCookie(COOKIE_KEYS.LANG, pageLang, { domain: domain ?? '' });
  await loadLanguages(Object.keys(langs));
  await changeLanguage(pageLang);
}
