import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
  OfferDetailsRoc,
  Props
} from '../components/offerDetails/component/OfferDetailsRoc';
import getCurrencyDetails from 'shared/modules/page/selectors/getCurrencyDetails';
import { CurrencyDisplay } from 'shared/types';

function mapStateToProps(
  state: any
): {
  locale: string;
  currency: string | null;
  currencyDisplay?: CurrencyDisplay;
} {
  const { currency, currencyLocale, currencyDisplay } = getCurrencyDetails(
    state
  );
  return {
    locale: currencyLocale,
    currency,
    currencyDisplay
  };
}

export default compose<Props, any>(connect(mapStateToProps))(OfferDetailsRoc);
