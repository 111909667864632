/* eslint-disable react/prop-types */
import { Children, Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import scrollToComponent from 'react-scroll-to-component';
import get from 'lodash/get';

import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import {
  HEADER_DESKTOP_HEIGHT,
  HEADER_MOBILE_HEIGHT
} from 'shared/constants/heights';
import { TASK_TYPE } from 'shared/modules/tasks/constants';
import { TIMINGS } from 'shared/constants/theme';
import createMediaQueries from 'shared/helpers/viewport/mediaQueries';
import executeTasks from 'shared/modules/tasks/helpers/executeTasks';
import fetchPage from 'shared/modules/page/actions/fetch';
import getCurrentBreakpointDevice from 'shared/helpers/viewport/getCurrentBreakpointDevice';
import getPageData from 'shared/modules/page/selectors/getPageData';
import getPagePath from 'shared/modules/page/selectors/getPagePath';
import getPathForLocation from 'shared/modules/tasks/helpers/getPathForLocation';
import getRouteForLocation from 'shared/modules/tasks/helpers/getRouteForLocation';
import handleI18n from 'shared/modules/tasks/helpers/handleI18n';
import handleZone from 'shared/modules/tasks/helpers/handleZone';
import sendPageViewEvent from 'shared/helpers/tracking/sendPageViewEvent';
import withIsFetching from './withIsFetching';
import withSharedRefs from './withSharedRefs';

/**
 * The TaskRoutesExecutor makes sure any data tasks are
 * executed prior to our route being loaded.
 * @see ./src/shared/locationTasks/
 */
class TasksExecutor extends Component {
  scrollToAnchorTimeout = null;

  scrollToAnchor = (location, refs) => {
    const hashString = location.hash.replace('#', '');
    const anchorComponentRef = get(refs, hashString);

    if (anchorComponentRef) {
      // Create mediaQueries helper
      const currentDevice = getCurrentBreakpointDevice(window);
      const mediaQueries = createMediaQueries(currentDevice);

      // Arbitrary delay for some block pending to resolve
      this.scrollToAnchorTimeout = setTimeout(
        () =>
          scrollToComponent(anchorComponentRef, {
            align: 'top',
            offset: mediaQueries.toTablet
              ? -HEADER_MOBILE_HEIGHT
              : -HEADER_DESKTOP_HEIGHT
          }),
        TIMINGS.scrollToAnchor
      );
    }
  };

  sendPageView = () => {
    const data = getPageData(this.props.store.getState());
    const { meta } = data;

    if (data.title) {
      sendPageViewEvent(data.title, meta);
    }
  };

  async UNSAFE_componentWillMount() {
    const { location, store, refs } = this.props;

    if (location) {
      this.sendPageView();

      /**
       * This should be called once for the first client-side rendering.
       * Razzle used to check window.APP_STATE to figure out the first client-side rendering,
       * but doing so seems to be OK. This easier approach shows its limits if TaskRoutesExecutor unmounts.
       */
      await executeTasks(TASK_TYPE.DEFERRED_DATA, location.pathname, store);
      /**
       * Attempt to scroll after tasks execution.
       */
      this.scrollToAnchor(location, refs);
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const currentHash = this.props.location.hash;
    const nextHash = nextProps.location.hash;
    const currentLang = this.props.allCookies[COOKIE_KEYS.LANG];
    const nextLang = nextProps.allCookies[COOKIE_KEYS.LANG];
    const currentRoute = getRouteForLocation(this.props.location);
    const nextRoute = getRouteForLocation(nextProps.location);
    const nextPath = getPathForLocation(nextProps.location);

    const hasNextHash = Boolean(nextHash);
    const hasHashChanged = nextHash !== currentHash;
    const hasLangChanged = nextLang !== currentLang;
    const hasRouteChanged = nextRoute !== currentRoute;
    const hasLocationChanged =
      get(this.props, 'location.pathname') !==
      get(nextProps, 'location.pathname');
    const hasStoppedFetching = this.props.isFetching && !nextProps.isFetching;

    /**
     * We have to remove the query params for comparison
     * because `nextRoute` doesn't contain query params
     *  */
    const [pagePathWithoutParams] = getPagePath(
      this.props.store.getState()
    ).split('?');
    const nextRouteMatchesPagePath = nextRoute === pagePathWithoutParams;

    /**
     * Clear anchor scroll timeout if defined.
     */
    if (this.scrollToAnchorTimeout && (hasLocationChanged || hasHashChanged)) {
      clearTimeout(this.scrollToAnchorTimeout);
    }

    /**
     * Handle zone if language or location pathname has changed.
     * + handle i18n and execute tasks
     */
    if (hasLangChanged || hasLocationChanged) {
      /**
       * On client side we handle zone BEFORE fetching page because we already know available zones.
       */
      const isRedirectionNeeded = handleZone(
        nextProps.location.pathname,
        nextProps.store
      );
      if (isRedirectionNeeded) return;

      /**
       * Location may change but may not require to fetch the page.
       * Example: This allows VOD pages to work with content coming from HODOR.
       *
       * When closing an article modal, both location and route may change but the page data should stay the same.
       * In that case, we don't want to fetch the page again.
       */
      if (hasLangChanged || (hasRouteChanged && !nextRouteMatchesPagePath)) {
        try {
          const isRedirectionNeeded = await nextProps.store.dispatch(
            fetchPage(nextPath)
          );
          if (isRedirectionNeeded) return;
        } catch (error) {
          // If fetch page fails for any reason, we do not execute tasks
          return;
        }

        this.sendPageView();
      }

      /**
       * Handle i18n
       */
      handleI18n(nextProps.store);

      /**
       * Execute tasks
       */
      await executeTasks(
        TASK_TYPE.PREFETCH_DATA,
        nextProps.location.pathname,
        nextProps.store
      );
      await executeTasks(
        TASK_TYPE.DEFERRED_DATA,
        nextProps.location.pathname,
        nextProps.store
      );
    }

    /**
     * Scroll to anchor if has hash AND :
     * - hash has changed
     * - OR location has changed
     * - OR react-queries is not fetching anymore
     * We are likely to carry the hash from a pathname to another if we come from a (silent) login page for example.
     */
    if (
      hasNextHash &&
      (hasHashChanged || hasLocationChanged || hasStoppedFetching)
    ) {
      this.scrollToAnchor(nextProps.location, nextProps.refs);
    }
  }

  render() {
    return Children.only(this.props.children);
  }
}

// Wrapping with withRouter injects "location" prop
export default compose(
  withIsFetching,
  withSharedRefs,
  withCookies,
  withRouter
)(TasksExecutor);
