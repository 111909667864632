/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Content, FormattedApis, FormattedProps } from './types';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { COLORS } from 'shared/constants/theme';
import { PropsFormatterUtils } from 'shared/types';

export const getFormattedApis = (content: Content): FormattedApis => ({
  getAvailableRewards: {
    url: content.apis?.['old_selfcare.get_available_rewards']?.url ?? '',
    method:
      content.apis?.['old_selfcare.get_available_rewards']?.method || 'GET'
  },
  selectReward: {
    url: content.apis?.['old_selfcare.select_reward']?.url ?? '',
    method: content.apis?.['old_selfcare.select_reward']?.method || 'GET'
  },
  addReward: {
    url: content.apis?.['old_selfcare.add_reward']?.url ?? '',
    method: content.apis?.['old_selfcare.add_reward']?.method || 'PUT'
  },
  getSubscriberData: {
    url: content.apis?.getSubscriberData?.url ?? '',
    method: content.apis?.getSubscriberData?.method || 'GET',
    parameters: content.apis?.getSubscriberData?.parameters || {}
  }
});

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  apis: getFormattedApis(content),
  confirmationText: content.confirmationText,
  title: content.confirmationTitle.label ?? '',
  titleColor: content.confirmationTitle.color ?? '',
  titleHtmlTag: content.confirmationTitle.htmlTag ?? '',
  buttons: {
    validationButton: {
      label: content.buttons?.validationButton?.label ?? '',
      theme: buttonThemeFactory({
        backgroundColor:
          content.buttons?.validationButton?.backgroundColor ||
          COLORS.carnation,
        color: content.buttons?.validationButton?.labelColor || COLORS.white,
        padding: '0 40px'
      })
    },
    finishButton: {
      label: content.buttons?.finishButton?.label ?? '',
      theme: buttonThemeFactory({
        backgroundColor:
          content.buttons?.finishButton?.backgroundColor || COLORS.carnation,
        color: content.buttons?.finishButton?.labelColor || COLORS.white,
        padding: '0 40px'
      }),
      link: getLinkHrefWithAuthentication(
        content.buttons?.finishButton?.link ?? ''
      )
    }
  },
  contextCriteria: content.frontDisplayCriteria ?? null
});
