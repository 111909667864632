import React, { PureComponent } from 'react';
import styled from 'styled-components';

import PremiumLabel from 'shared/components/presentational/PremiumLabel';
import Raw from 'shared/components/presentational/Raw/Raw';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

const Wrapper = styled.div`
  padding: ${props => (props.isFull ? '35px' : '15px')};
  background-color: ${props => (props.isFull ? COLORS.concrete : 'none')};
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  ${mediaQueries.toTablet`
    margin-left: 15px;
    margin-right: 15px;
  `};
  ${mediaQueries.toPhablet`
    margin-left: 7px;
    margin-right: 7px;
  `};
  ${mediaQueries.toPhablet`
    padding: ${props => (props.isFull ? '25px' : '15px')};
  `};
`;
const Title = styled.h2`
  margin: 20px 0;
  text-align: center;
  font-size: ${pxInRem(22)};
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
`;
const Text = styled(Raw)`
  margin: 20px 0;
  padding: 0;
  font-size: ${pxInRem(14)};
`;

export default class Premium extends PureComponent {
  render() {
    const { template, isLabel, title, text } = this.props;

    const isFull = template === 'full';

    return (
      <Wrapper isFull={isFull}>
        {isLabel && <PremiumLabel />}
        {isFull && title && <Title>{title}</Title>}
        {isFull && text && <Text html={text} />}
      </Wrapper>
    );
  }
}
