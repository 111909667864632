import find from 'lodash/find';

import getShops from './getShops';

export default shopId => state => {
  const shops = getShops(state);
  if (shops.length) {
    return find(getShops(state), shop => shop.id === shopId, null);
  }
  return {};
};
