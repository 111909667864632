import React from 'react';

import { FormattedProps } from '../types';
import { Wrapper } from '../styles/components';
import { TabsProvider } from '../../shared/components/Tabs/component/Tabs/providers/TabsProvider';
import { TabsWrapper } from './TabsWrapper/TabsWrapper';
import Title from 'shared/components/presentational/Title';

const Jaquettes = ({ tabs, title }: FormattedProps) => {
  return (
    <Wrapper data-testid="oneShop.jaquettes.tabs">
      <Title titleLabel={title?.label} titleHtmlTag={title?.htmlTag} />
      <TabsProvider>
        <TabsWrapper tabs={tabs} />
      </TabsProvider>
    </Wrapper>
  );
};

export default Jaquettes;
