import { Content, FormattedProps } from './types';

export default (
  content: Content,
  {
    getLinkHrefWithAuthentication
  }: { getLinkHrefWithAuthentication: (link: any) => string }
): FormattedProps => {
  return {
    title: {
      label: content.title?.label || '',
      htmlTag: content.title?.htmlTag
    },
    description: {
      emptyDescription: content.defaultDescription,
      description: content.outPackageDescription
    },
    button:
      content.button.label && content.button.link
        ? {
            label: content.button.label,
            link: {
              rel: content.button.link.rel,
              href: getLinkHrefWithAuthentication(content.button.link)
            }
          }
        : null,
    apis: {
      contract_out_package: {
        url: content.apis?.contract_out_package?.url ?? '',
        method: content.apis?.contract_out_package?.method || 'GET',
        parameters: content.apis?.contract_out_package?.parameters || {}
      },
      getSubscriberData: {
        url: content.apis?.getSubscriberData?.url ?? '',
        method: content.apis?.getSubscriberData?.method || 'GET',
        parameters: content.apis?.getSubscriberData?.parameters || {}
      }
    }
  };
};
