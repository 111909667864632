/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2019 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import loadable from '@loadable/component';

export const namespace = 'canal.block.video.player';
export const Component = loadable(() => import('./components/Player'));
export { default as propsFormatter } from './propsFormatter';
