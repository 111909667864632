import { connect } from 'react-redux';

import { SearchEngine } from '../components/SearchEngine/SearchEngine';
import getSmartFaqUuid from 'shared/modules/page/selectors/getSmartFaqUuid';
import getCompanyId from 'shared/modules/page/selectors/getCompanyId';
import getCurrentZone from 'shared/modules/page/selectors/getCurrentZone';
import getPageLang from 'shared/modules/page/selectors/getPageLang';

export interface PropsFromState {
  companyId: string;
  smartFaqUuId: string;
  bigram: string;
  pageLang: string;
}

const mapStateToProps = (state: any): PropsFromState => {
  const companyId = getCompanyId(state) as string;
  const smartFaqUuId = getSmartFaqUuid(state) as string;
  const bigram = getCurrentZone(state) as string;
  const pageLang = getPageLang(state);

  return {
    companyId,
    smartFaqUuId,
    bigram,
    pageLang
  };
};

export default connect(mapStateToProps)(SearchEngine);
