import { TASK_TYPE } from 'shared/modules/tasks/constants';
import disneyServiceLocationTasks from '../disney-service/locationTasks.partial';

export default ({ dispatch, getState }) => [
  {
    pattern: '/:bigram([a-zA-Z]{2})?/*',
    [TASK_TYPE.DEFERRED_DATA]: () =>
      Promise.all([
        /**
         * Block Disney Service
         */
        disneyServiceLocationTasks({ dispatch, getState })
      ])
  }
];
