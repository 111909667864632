import { ACTION_TYPES } from '../constants';

export default function fetchVisualsSucceed(id, visuals) {
  return {
    type: ACTION_TYPES.FETCH_VISUALS_SUCCEED,
    payload: {
      id,
      visuals
    }
  };
}
