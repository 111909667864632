import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 142 48" width={width} height={height} className={className}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M87.7 18.1c-3.4 0-5.9 2.4-5.9 5.7 0 3.2 2.3 5.4 5.6 5.4.3 0 .6 0 1.2-.1l-1.7-2.2 3.2-2.3 1.9 2.6c.5-.7.7-1 .9-1.6.2-.6.3-1.3.3-2 0-3.3-2.2-5.5-5.5-5.5zM66 21.2c-.1-.6-.2-.9-.3-1.7-.1.3-.2.5-.2.7-.2.6-.4 1.2-.7 1.7l-1.8 4h4.1l-.7-3.5c-.2-.6-.3-1.1-.4-1.2z" />
      <path d="M126.9.5L9.3 3.1.5 47.5l141-5.7L126.9.5zm-74 25.3h-5.7l-1 7.7h-5l2.4-19.8H55l-.6 4.3h-6.3l-.4 3.3h5.7l-.5 4.5zM69 33.5l-.9-3.6h-6.9l-1.7 3.6h-5.6l9.8-19.8h5.7l4.8 19.8H69zm28.4-5.8c-.6 1.4-1 2.1-2.4 3.5l1.2 1.7-3.3 2.3-1.4-1.8c-.9.3-1.3.4-1.9.5-.7.1-1.4.2-2.2.2-6.4 0-10.8-4.1-10.8-10 0-6.2 4.7-10.5 11.2-10.5 6.2 0 10.4 4 10.4 9.8.1 1.3-.2 2.9-.8 4.3z" />
    </g>
  </svg>
);
