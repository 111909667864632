import { connect } from 'react-redux';

import Summary from '../components/Summary';
import { PropsFromDispatch, PropsFromState, FormattedProps } from '../types';
import { PostOfferParams, FormDataToEncrypt } from '../../shared/types';
import submitOffer from '../actions/submitOffer';
import flush from '../actions/flush';
import getSelectedOptionCode from 'shared/modules/router/selectors/queryParams/getSelectedOptionCode';
import getSelectedOfferAndOption from '../helpers/getSelectedOfferAndOption';
import retrieveOfferSubscriptionForm from '../../offer-form/actions/retrieveOfferSubscriptionForm';
import saveOfferSubscriptionForm from '../../shared/actions/saveOfferSubscriptionForm';
import getSummaryFromState from '../selectors/getSummaryFromState';
import getBigram from 'shared/modules/page/selectors/getCurrentZone';
import getSelectedMinorOffers from '../helpers/getSelectedMinorOffers';
import getSelectedMinorOffersCodes from '../helpers/getSelectedMinorOffersCodes';

const mapStateToProps = (
  state: any,
  ownProps: FormattedProps
): PropsFromState => {
  const selectedOptionCode = getSelectedOptionCode(state);
  const selectedOfferAndOption = getSelectedOfferAndOption(
    ownProps.offers,
    selectedOptionCode
  );

  const selectedMinorOffersCodes = getSelectedMinorOffersCodes(state);
  const selectedMinorOffers = getSelectedMinorOffers(
    selectedOfferAndOption,
    selectedMinorOffersCodes
  );

  const summary = getSummaryFromState(state);
  const subscriptionAPI = ownProps.createSubscriptionAPI({
    appLocations: getBigram(state).toUpperCase()
  });

  return {
    subscriptionAPI,
    selectedOfferAndOption,
    selectedMinorOffers,
    submitPending: summary.submit.pending,
    submitSuccess: summary.submit.success,
    submitError: summary.submit.error
  };
};

const mapDispatchToProps = (dispatch: any): PropsFromDispatch => ({
  retrieveOfferSubscriptionForm: () => dispatch(retrieveOfferSubscriptionForm),
  onSave: (formDataToEncrypt: FormDataToEncrypt) =>
    dispatch(saveOfferSubscriptionForm(formDataToEncrypt)),
  postOffer: (postOffersParams: PostOfferParams) => {
    submitOffer(postOffersParams)(dispatch);
  },
  flush: () => {
    dispatch(flush());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
