/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { useCallback } from 'react';

import {
  Modal,
  Content,
  PictureWrapper,
  Picture,
  TextWrapper,
  Title,
  Link
} from './styles/components';
import { Props } from './types';
import Raw from 'shared/components/presentational/Raw/Raw';

// Default style of the modal is overwritten in the Modal presentational component

const PopIn = ({
  id,
  title,
  text,
  picture,
  link,
  isOpen,
  togglePopIn
}: Props) => {
  const renderButton = useCallback(
    (link: Props['link']) => {
      if (!link) {
        return;
      }

      if (link.url) {
        return (
          <Link to={link.url} color={link.color}>
            {link.label}
          </Link>
        );
      } else {
        return (
          <Link onClick={togglePopIn} color={link.color}>
            {link.label}
          </Link>
        );
      }
    },
    [togglePopIn]
  );

  return (
    <div>
      <Modal id={id} isOpen={isOpen} contentLabel={title}>
        <Content>
          {picture && (
            <PictureWrapper data-testid="popin-picture-wrapper">
              <Picture
                url={picture.url}
                alt={picture.alt}
                sizes={{
                  tablet: { w: 380, h: 380 },
                  phone: { w: 278, h: 278 }
                }}
              ></Picture>
            </PictureWrapper>
          )}
          <TextWrapper>
            {title && <Title data-testid="popin-title">{title}</Title>}
            <Raw html={text} />
            {renderButton(link)}
          </TextWrapper>
        </Content>
      </Modal>
    </div>
  );
};

export default PopIn;
