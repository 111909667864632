import fetchMore from 'shared/blocks/vod/hodor/webservices/fetchMore';
import fetchMoreVodError from './fetchMoreVodError';
import fetchMoreVodRequest from './fetchMoreVodRequest';
import fetchMoreVodSuccess from './fetchMoreVodSuccess';

export default (path: string, originUrl: string) => {
  return async (dispatch: any) => {
    dispatch(fetchMoreVodRequest(path, originUrl));
    try {
      const data = await fetchMore(path);
      dispatch(fetchMoreVodSuccess(path, originUrl, data));
    } catch (error: any) {
      dispatch(fetchMoreVodError(path, originUrl, error));
    }
  };
};
