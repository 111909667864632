import styled, { css } from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

export const Wrapper = styled.section`
  background: ${COLORS.black};
  margin-top: 0 !important;
`;

export const Content = styled.div`
  text-align: left;
  background: ${COLORS.white};
  padding: 28px 15px;

  & > * {
    max-width: 860px;
    margin: 0 auto;
  }

  ${mediaQueries.toTablet`
    margin-top: 0;
  `};
`;

export const Title = styled.h1`
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${pxInRem(30)};
  text-transform: uppercase;

  ${mediaQueries.toPhablet`
    font-size: ${pxInRem(15)};
  `};
`;

export const GlobalInfo = styled.p`
  color: ${COLORS.boulder};
  margin-bottom: 2rem;

  .RatingCSA {
    display: inline-block; // IE 11
    width: 25px; // IE 11
    color: black;
  }

  .cos-svg {
    display: inline;
    vertical-align: middle;
    position: relative;
    bottom: 1px;
    margin-right: 2px;
  }

  .infoBold {
    margin-left: 2px;
    font-family: ${FONT_FAMILIES.brand} !important;
    font-weight: ${FONT_WEIGHTS.demi} !important;
    font-size: 12px;
    display: inline-block;
    color: ${COLORS.black};
  }
`;

export const InfoStyles = css`
  font-family: ${FONT_FAMILIES.base};
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: ${pxInRem(14)};
  line-height: ${pxInRem(20)};
  text-align: left;
`;

export const Author = styled.p`
  margin-top: 2rem;
  ${InfoStyles};
`;

export const Personalities = styled.p`
  ${InfoStyles};
`;

export const Summary = styled.p`
  ${InfoStyles};
`;

export const Prefix = styled.span`
  font-weight: ${FONT_WEIGHTS.demi};
`;

export const CoverWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 860px;
  margin: auto;
`;
