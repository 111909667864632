import styled, { css } from 'styled-components';

import mediaQueries, {
  customMediaQuery
} from 'shared/helpers/styled-components/mediaQueries';
import Container from '../../Container/Container';
import {
  SlideWrapper,
  SlideThumbnail
} from '../../Slider/Slide/styles/components';
import {
  BREAKPOINT_SLIDER_THREE_SLIDE,
  HALF_OF_CONTAINER_INNER_WIDTH,
  SLIDE_HORIZONTAL_GAP,
  SLIDE_HORIZONTAL_GAP_DESKTOP,
  SLIDE_HORIZONTAL_GAP_TABLET,
  THIRD_OF_CONTAINER_INNER_WIDTH,
  THREE_SLIDES_THUMBNAIL_HEIGHT_DESKTOP,
  THREE_SLIDES_THUMBNAIL_RATIO,
  THREE_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_PHONE,
  THREE_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_TABLET,
  TWO_SLIDES_THUMBNAIL_HEIGHT_DESKTOP,
  TWO_SLIDES_THUMBNAIL_RATIO,
  TWO_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_TABLET
} from '../../Slider/styles/constants';
import {
  CONTAINER_MAX_WIDTH_BREAKPOINT,
  DIMENSIONS
} from 'shared/constants/theme';
const {
  LONG_VERTICAL_MARGIN,
  MEDIUM_VERTICAL_MARGIN,
  SMALL_VERTICAL_MARGIN,
  CONTAINER_MINIMUM_PADDING,
  CONTAINER_MINIMUM_PADDING_TABLET
} = DIMENSIONS;

export const List = styled(Container)<{ slidesToShow: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${SlideWrapper}:not(:first-child) {
    margin-top: ${SMALL_VERTICAL_MARGIN}px;

    ${mediaQueries.fromPhone`
      margin-top: ${MEDIUM_VERTICAL_MARGIN}px;
    `}

    ${customMediaQuery(`${BREAKPOINT_SLIDER_THREE_SLIDE}px`)`
      margin-top: 0;
    `}
  }

  ${props =>
    props.slidesToShow > 1 &&
    css`
      ${customMediaQuery(`${BREAKPOINT_SLIDER_THREE_SLIDE}px`)`
        flex-wrap: nowrap;
        margin-bottom: ${MEDIUM_VERTICAL_MARGIN}px;
        margin-left: -${SLIDE_HORIZONTAL_GAP}px;
      `}

      ${mediaQueries.fromTablet`
        margin-left: -${SLIDE_HORIZONTAL_GAP_TABLET}px;
      `}
    `}

  ${props =>
    props.slidesToShow === 2 &&
    css`
      ${SlideWrapper} {
        ${customMediaQuery(`${BREAKPOINT_SLIDER_THREE_SLIDE}px`)`
          margin-left: ${SLIDE_HORIZONTAL_GAP}px;
          max-width: calc(50vw - ${
            CONTAINER_MINIMUM_PADDING + SLIDE_HORIZONTAL_GAP / 2
          }px);
        `}

        ${mediaQueries.fromTablet`
          margin-left: ${SLIDE_HORIZONTAL_GAP_TABLET}px;
          max-width: calc(50vw - ${
            CONTAINER_MINIMUM_PADDING + SLIDE_HORIZONTAL_GAP_TABLET / 2
          }px);
        `}

        ${mediaQueries.fromDesktop`
          max-width: calc(50vw - ${
            CONTAINER_MINIMUM_PADDING_TABLET + SLIDE_HORIZONTAL_GAP_DESKTOP / 2
          }px);
        `}

        ${customMediaQuery(`${CONTAINER_MAX_WIDTH_BREAKPOINT}px`)`
          max-width: ${
            HALF_OF_CONTAINER_INNER_WIDTH - SLIDE_HORIZONTAL_GAP_DESKTOP / 2
          }px;
        `}
      }
    `}

  ${props =>
    props.slidesToShow > 2 &&
    css`
      ${SlideWrapper} {
        ${customMediaQuery(`${BREAKPOINT_SLIDER_THREE_SLIDE}px`)`
          margin-left: ${SLIDE_HORIZONTAL_GAP}px;
          max-width: calc(50vw - ${
            CONTAINER_MINIMUM_PADDING - SLIDE_HORIZONTAL_GAP / 2
          }px);
        `}

        ${mediaQueries.fromTablet`
          margin-left: ${SLIDE_HORIZONTAL_GAP_TABLET}px;
          max-width: calc(50vw - ${
            (CONTAINER_MINIMUM_PADDING_TABLET * 2 +
              SLIDE_HORIZONTAL_GAP_TABLET) /
            2
          }px);
        `}

        ${mediaQueries.fromDesktop`
          max-width: calc(33.3vw - ${
            ((CONTAINER_MINIMUM_PADDING_TABLET + SLIDE_HORIZONTAL_GAP_DESKTOP) *
              2) /
            3
          }px);
        `}

        ${customMediaQuery(`${CONTAINER_MAX_WIDTH_BREAKPOINT}px`)`
          max-width: ${
            THIRD_OF_CONTAINER_INNER_WIDTH - SLIDE_HORIZONTAL_GAP_DESKTOP / 2
          }px;
        `}
      `}
  }

  ${mediaQueries.fromDesktop`
    margin-bottom: ${LONG_VERTICAL_MARGIN}px;
    margin-left: -${SLIDE_HORIZONTAL_GAP_DESKTOP}px;

    ${SlideWrapper} {
      margin-left: ${SLIDE_HORIZONTAL_GAP_DESKTOP}px;
    }
  `}

  // Slides thumbnail height is the same as the Slider
  ${SlideThumbnail} {
    ${mediaQueries.toPhone`
      height: calc(100vw / ${THREE_SLIDES_THUMBNAIL_RATIO});
    `}

    ${props =>
      props.slidesToShow === 2 &&
      css`
        height: ${TWO_SLIDES_THUMBNAIL_HEIGHT_DESKTOP}px;

        ${mediaQueries.toTablet`
          height: calc(100vw / ${TWO_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_TABLET} / ${TWO_SLIDES_THUMBNAIL_RATIO});
        `}

        ${mediaQueries.toPhone`
          height: calc(100vw / ${TWO_SLIDES_THUMBNAIL_RATIO});
        `}
      `}

    ${props =>
      props.slidesToShow > 2 &&
      css`
        height: ${THREE_SLIDES_THUMBNAIL_HEIGHT_DESKTOP}px;

        ${mediaQueries.toTablet`
          height: calc(100vw / ${THREE_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_TABLET} / ${THREE_SLIDES_THUMBNAIL_RATIO});
        `}

        ${mediaQueries.toPhablet`
          height: calc(100vw / ${THREE_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_PHONE} / ${THREE_SLIDES_THUMBNAIL_RATIO});
        `}

        ${mediaQueries.toPhone`
          height: calc(100vw / ${THREE_SLIDES_THUMBNAIL_RATIO});
        `}
      `}
  }
`;
