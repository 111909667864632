/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2021 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { AnyAction } from 'redux';
import storage from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';

import { actionType as FLUSH } from '../actions/flush';
import {
  SET_EVENT_CODE_ACTION_TYPE,
  SET_PAYMENT_TYPE_ACTION_TYPE,
  SET_CODE_PROPAL_ACTION_TYPE,
  SET_NUM_CONTRACT_ACTION_TYPE,
  SET_SUBSCRIPTION_CURRENCY_ACTION_TYPE,
  PAYMENT_TYPE
} from '../constants';

type State = {
  eventCode: string | null;
  paymentType: PAYMENT_TYPE | null;
  codePropal: string[];
  numContract: string | null;
  subscriptionCurrency: string | null;
};

const defaultState: State = {
  eventCode: null,
  paymentType: null,
  codePropal: [],
  numContract: null,
  subscriptionCurrency: null
};

const persistConfig = {
  key: 'selfcare/queryParams',
  storage
};

/**
 * This reducer is basically used to store data that used to be in queryParams
 * inside sessionStorage.
 */
function reducer(state: State = defaultState, action: AnyAction): State {
  switch (action.type) {
    case SET_EVENT_CODE_ACTION_TYPE: {
      return {
        ...state,
        eventCode: action?.payload ?? null
      };
    }

    case SET_PAYMENT_TYPE_ACTION_TYPE: {
      return {
        ...state,
        paymentType: action?.payload || null
      };
    }

    case SET_CODE_PROPAL_ACTION_TYPE: {
      return {
        ...state,
        codePropal: action?.payload || []
      };
    }

    case SET_NUM_CONTRACT_ACTION_TYPE: {
      return {
        ...state,
        numContract: action?.payload || null
      };
    }

    case SET_SUBSCRIPTION_CURRENCY_ACTION_TYPE: {
      return {
        ...state,
        subscriptionCurrency: action?.payload || null
      };
    }

    case FLUSH: {
      return defaultState;
    }

    default:
      return state;
  }
}

// This is where sessionStorage happens
export default persistReducer(persistConfig, reducer);
