import axios from 'axios';

import createTemplate from 'shared/helpers/template/createTemplate';
import getContentByTemplates from '../helpers/getContentByTemplate';

let urlPages = {
  // Empty init to use the wsFromPath
  // it allow us to have a map of the paths with the URLPages to fetch without using wsFromPath
};

export default async (wsFromPathApiUrl, token, path) => {
  const url = createTemplate(wsFromPathApiUrl)({ token, path });

  let response;

  if (!urlPages[path]) {
    const pathResponse = await axios.get(url);
    const contentUrl = pathResponse.data.redirectTo.URLPage;
    response = await axios.get(contentUrl);
  } else {
    response = await axios.get(urlPages[path]);
  }

  urlPages = { ...urlPages, ...getContentByTemplates(response.data) };

  return response.data;
};
