/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*       */

import React, { PureComponent } from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import StarRating from 'shared/components/presentational/StarRating';
import { GLOBAL_MAX_SCORE, TELERAMA_MAX_SCORE } from '../constant';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

const Wrapper = styled.div`
  & > * {
    min-width: 300px;
    background: ${COLORS.concrete};
    margin-right: 14px;
    margin-bottom: 14px;
    padding: 17.5px;
    display: inline-block;

    ${mediaQueries.toPhablet`
      min-width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 5px;
    `};
  }

  .telerama {
    display: block;
    margin-right: 0;
  }
`;

const Name = styled.span`
  text-transform: uppercase;
  font-family: ${FONT_FAMILIES.base};
  font-weight: ${FONT_WEIGHTS.bold};
  margin: 0 14px 0 0;
`;

const Rating = styled.div`
  display: inline-block;
  margin: 0 5px 0 0;
`;

const Text = styled.div`
  margin: 14px 0 0;
  font-family: ${FONT_FAMILIES.base};
`;

export default class Reviews extends PureComponent {
  static propTypes = {
    reviews: arrayOf(
      shape({
        name: string.isRequired,
        stars: shape({
          type: string.isRequired,
          value: number.isRequired
        }).isRequired,
        review: string
      })
    )
  };

  render() {
    const { reviews } = this.props;
    return (
      <Wrapper>
        {reviews.map((review, index) => (
          <div className={review.stars.type} key={`review-${index}`}>
            <Name>{review.name}</Name>
            <Rating>
              <StarRating
                max={
                  review.stars.type === 'telerama'
                    ? TELERAMA_MAX_SCORE
                    : GLOBAL_MAX_SCORE
                }
                value={review.stars.value}
                type={review.stars.type}
              />
            </Rating>
            {review.review && <Text>{review.review}</Text>}
          </div>
        ))}
      </Wrapper>
    );
  }
}
