import { Content, FormattedProps } from './types';

export default (content: Content): FormattedProps => ({
  title: content.title.label ?? '',
  titleColor: content.title.color,
  titleHtmlTag: content.title.htmlTag,
  template: content.template,
  subtitle: content.subtitle ?? undefined,
  color: content.color.default,
  toggleColor: content.color.toggle ?? undefined,
  advantages: content.advantages.map(advantage => ({
    title: advantage.title?.label,
    logo: advantage.icon.link.href,
    description: advantage.description
  }))
});
