/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTransition } from 'react-spring';
import get from 'lodash/get';
import Measure, { ContentRect, MeasuredComponentProps } from 'react-measure';

import { OfferCard as Props, ImageUrlAndAlt } from '../../types';
import SliderToggle from 'shared/components/presentational/SliderToggle/SliderToggle';
import breakpoints from 'shared/constants/breakpoints';
import useMedia from 'shared/helpers/devices/useMedia';
import RoundedCard from 'shared/components/presentational/RoundedCard/RoundedCard';
import {
  Channel,
  ChannelImage,
  ChannelsWrapper,
  OptionWrapper,
  DescriptionWrapper,
  AnimatedDescription,
  DescriptionContent,
  ToggleWrapper,
  Title,
  Subtitle,
  SubtitleWrapper,
  DecoderWrapper,
  Decoder,
  Button,
  PricesWrapper,
  Text,
  Link
} from './styles/components';
import Price from 'shared/components/presentational/Price/Price';
import createOfferValidationLink from '../../../shared/links/createOfferValidationLink';

const renderChannels = (channels: Props['channels']) => {
  if (channels) {
    const formattedChannels = channels.map(
      (channel: ImageUrlAndAlt, i: number) => (
        <Channel key={`eshop-offer-card-channel-${i}`}>
          <ChannelImage url={channel.imageUrl} alt={channel.imageAlt} />
        </Channel>
      )
    );
    return <ChannelsWrapper>{formattedChannels}</ChannelsWrapper>;
  }
};

const OfferCard = (props: Props) => {
  const {
    title,
    titleColor,
    subtitle,
    bannerURLByDevice,
    channels,
    decoder,
    options,
    currentOptionID,
    changeCurrentOptionID,
    button,
    moreInformation,
    channelsDescription,
    borderHoverColor,
    subtitleHeight,
    onSubtitleResize: handleSubtitleResize,
    minorOffers,
    onButtonClick,
    ...restProps
  } = props;

  const { t } = useTranslation();

  const transitions = useTransition(currentOptionID, null, {
    config: { mass: 0.1, tension: 200, friction: 20 },
    // @ts-ignore
    duration: 300,
    from: { transform: 'translate3d(0, -100%, 0)', opacity: 0 },
    enter: { transform: 'translate3d(0, 0%, 0)', opacity: 1 },
    leave: { transform: 'translate3d(0, 100%, 0)', opacity: 0 }
  });

  const [
    descriptionHeightByOfferIndex,
    updateDescriptionHeightByOfferIndex
  ] = useState<{ [index: string]: number }>({});

  const handleDescriptionResize: (
    i: string
  ) => (contentRect: ContentRect) => void = useCallback(
    (i: string) => ({ bounds }) => {
      if (bounds && bounds.height !== descriptionHeightByOfferIndex[i]) {
        updateDescriptionHeightByOfferIndex({
          ...descriptionHeightByOfferIndex,
          [i]: bounds.height
        });
      }
    },
    [updateDescriptionHeightByOfferIndex, descriptionHeightByOfferIndex]
  );

  // VALUES
  const bannerURLForDevice = useMedia<string>(
    [`(max-width:${breakpoints.phablet})`, `(max-width:${breakpoints.tablet})`],
    [bannerURLByDevice.mobile, bannerURLByDevice.tablet],
    bannerURLByDevice.desktop
  );

  const descriptionHeight = useMemo(
    () =>
      Object.values(descriptionHeightByOfferIndex).reduce(
        (acc, descriptionHeight: number) =>
          descriptionHeight > acc ? descriptionHeight : acc,
        0
      ),
    [descriptionHeightByOfferIndex]
  );

  const selectedOption = useMemo(
    () => options.find(el => el.id === currentOptionID),
    [options, currentOptionID]
  );

  // for tracking purpose only
  const buttonClassName = useMemo(
    () =>
      selectedOption
        ? `eshop-button-selector ${selectedOption.code}-selector`
        : '',
    [selectedOption]
  );

  const buttonLinkWithSelectedOption = createOfferValidationLink(
    button.link,
    get(selectedOption, 'code')
  );

  // RENDERS
  const renderDescriptionWithTransition = useMemo(
    () =>
      transitions.map(({ item: currentOptionID, key, props }) => {
        const selectedOption = options.find(el => el.id === currentOptionID);
        if (selectedOption) {
          return (
            <Measure key={key} bounds onResize={handleDescriptionResize(key)}>
              {({ measureRef }: MeasuredComponentProps) => (
                <AnimatedDescription ref={measureRef} style={props}>
                  <DescriptionContent html={selectedOption.description} />
                </AnimatedDescription>
              )}
            </Measure>
          );
        }
      }),
    [transitions, options]
  );

  const renderToggle = useCallback(() => {
    const toggleOptions = options.map(option => ({
      label: t('blocks.eshop.offers.options.months', { months: option.id }),
      value: option.id
    }));

    return (
      <SliderToggle
        name={`${title}OptionToggle`}
        options={toggleOptions}
        onChange={changeCurrentOptionID}
        value={currentOptionID}
      />
    );
  }, [title, currentOptionID, changeCurrentOptionID]);

  const hasMinorOffers = minorOffers.length > 0;

  const handleButtonClick = useCallback(() => {
    if (selectedOption) {
      onButtonClick(selectedOption.code);
    }
  }, [selectedOption, onButtonClick]);

  return (
    <RoundedCard
      imageUrl={bannerURLForDevice}
      imageAlt={title || ''}
      borderHoverColor={borderHoverColor}
      {...restProps}
    >
      {title && <Title color={titleColor}>{title}</Title>}
      <SubtitleWrapper height={subtitleHeight}>
        {subtitle && (
          <Measure bounds onResize={handleSubtitleResize}>
            {({ measureRef }: MeasuredComponentProps) => (
              <Subtitle ref={measureRef} html={subtitle} />
            )}
          </Measure>
        )}
      </SubtitleWrapper>
      {selectedOption && (
        <PricesWrapper>
          {selectedOption.strikeThroughPrice && (
            <Price value={selectedOption.strikeThroughPrice} strikeThrough />
          )}
          {selectedOption.marketingPriceInteger && (
            <Price
              value={selectedOption.marketingPriceInteger}
              exponent={selectedOption.marketingPriceExponent}
              hint={selectedOption.marketingPriceTerms}
            />
          )}
        </PricesWrapper>
      )}
      <OptionWrapper>
        {decoder && (
          <DecoderWrapper>
            <Decoder url={decoder.imageUrl} alt={decoder.imageAlt} />
          </DecoderWrapper>
        )}
        <DescriptionWrapper height={descriptionHeight}>
          {renderDescriptionWithTransition}
        </DescriptionWrapper>
      </OptionWrapper>
      <ToggleWrapper>{options.length > 1 && renderToggle()}</ToggleWrapper>
      {button.label && (
        <Button
          className={buttonClassName}
          theme={button.theme}
          to={!hasMinorOffers ? buttonLinkWithSelectedOption : undefined}
          onClick={hasMinorOffers ? handleButtonClick : undefined}
        >
          <Button.children.Text>{button.label}</Button.children.Text>
        </Button>
      )}
      {moreInformation.label && (
        <Link to={moreInformation.link}>{moreInformation.label}</Link>
      )}
      {renderChannels(channels)}
      <Text html={channelsDescription} />
    </RoundedCard>
  );
};

export default OfferCard;
