/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { Link, PropsFormatterUtils } from 'shared/types';
import { Content, FormattedProps } from './types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => {
  const formatedGroups = content?.groups?.map(group => ({
    title: group?.title,
    exponent: group?.exponent,
    channels: group?.channels?.map(currentChannel => ({
      exponent: currentChannel?.exponent,
      title: currentChannel?.channel?.title,
      description: currentChannel?.channel?.description,
      imageLink: currentChannel?.channel?.media?.link?.href,
      link: currentChannel?.channel.link
        ? getLinkHrefWithAuthentication(currentChannel?.channel.link)
        : undefined
    }))
  }));

  return {
    template: content.template,
    title: content?.title?.label,
    titleColor: content?.title?.color,
    titleHtmlTag: content?.title?.htmlTag,
    subtitle: content?.subtitle,
    groups: formatedGroups,
    seeMore: content.seeMore?.link
      ? {
          title: content.seeMore?.title ?? undefined,
          label: content.seeMore?.label ?? undefined,
          link:
            content.seeMore.link?.rel === 'modal'
              ? undefined
              : getLinkHrefWithAuthentication(content.seeMore.link as Link)
        }
      : null
  };
};
