/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';
import queryString from 'querystring';

import { createTemplate } from 'shared/helpers/template';

export default (content, { id, getLinkHrefWithAuthentication }) => ({
  id,
  timezone: get(content, 'timeZone', undefined),
  apiUrl: `${createTemplate(get(content, 'api.url', ''))({
    vectorSat: get(content, 'api.parameters.vectorSat')
  })}?${queryString.stringify({
    category: get(content, 'api.parameters.category'),
    range: get(content, 'api.parameters.range')
  })}`,
  title: get(content, 'title'),
  subtitle: get(content, 'subtitle'),
  buttonText: get(content, 'button.label', ''),
  buttonLink: getLinkHrefWithAuthentication(get(content, 'button.link', '')),
  createLinkUrl: createTemplate(
    getLinkHrefWithAuthentication(get(content, 'dynamicProgramLink', ''))
  )
});
