/**
 * Calculate the device corresponding to the current breakpoint
 */
import breakpoints, { Device } from 'shared/constants/breakpoints';

export default (w: Window): Device =>
  Object.entries(breakpoints).reduce(
    (acc, [breakpointName, breakpointValue]) => {
      if (w.matchMedia(`(max-width: ${breakpointValue})`).matches) {
        return breakpointName;
      }
      return acc;
    },
    Object.keys(breakpoints)[0]
  ) as Device;
