import React from 'react';

import { FormattedTileV2 } from '../types';
import { CardTemplate2 } from './Card';
import { CardsWrapperTemplate2 } from './components';

interface Props {
  tiles: FormattedTileV2[];
}

export const TilesV2 = ({ tiles }: Props) => {
  const renderCardsTemplate2 = React.useCallback(
    (tile: FormattedTileV2, index: number) => (
      <CardTemplate2 key={index} tile={tile} />
    ),
    []
  );
  return (
    <CardsWrapperTemplate2>
      {tiles.map(renderCardsTemplate2)}
    </CardsWrapperTemplate2>
  );
};
