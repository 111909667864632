export const FETCH_SUCCESS_CODES = {
  SUBSCRIBER_DATA: ['0'],
  CATEGORY_V_ACCESS: ['SBP-0020']
};

export const FETCH_ERROR_CODES = {
  CATEGORY_V_ACCESS: ['SBP-1021', 'COM-0011', 'COM-0010']
};

export const FETCH_ERROR_MESSAGE_I18N = 'common.fetch.error';
export const ERROR_MESSAGE_I18N = 'common.global.error';
export const FETCH_ERROR_MESSAGE_EN = 'Service unavailable.';

export const MESSAGE_TYPE = {
  ERROR: 'ERROR',
  LOCKED: 'LOCKED',
  SUCCESS: 'SUCCESS'
} as const;

export type MESSAGE_TYPE_KEYS = keyof typeof MESSAGE_TYPE;
export type MESSAGE_TYPE_VALUES = typeof MESSAGE_TYPE[MESSAGE_TYPE_KEYS];

export const OPTIONS_CHECKSUM_KEY = 'optionsChecksum';

export const SET_EVENT_CODE_ACTION_TYPE = 'SET_EVENT_CODE' as const;

export const SET_PAYMENT_TYPE_ACTION_TYPE = 'SET_PAYMENT_TYPE' as const;

export const SET_CODE_PROPAL_ACTION_TYPE = 'SET_CODE_PROPAL' as const;

export const SET_NUM_CONTRACT_ACTION_TYPE = 'SET_NUM_CONTRACT' as const;

export const SET_SUBSCRIPTION_CURRENCY_ACTION_TYPE = 'SET_SUBSCRIPTION_CURRENCY' as const;

export enum PAYMENT_TYPE {
  RENEWAL = 'renewal',
  UNPAID = 'unpaid'
}
