import React from 'react';

import Loader from 'shared/components/presentational/Loader';
import { formatHighlightsToSlides } from 'shared/helpers/eismo';
import { FormattedProps } from '../types';
import ManualSlider from '../../manual-slider/components/ManualSlider';
import { Wrapper } from '../../shared/styles/components';
import useMeaContentQuery from '../hooks/useMeaContent';

const AutomaticSlider = ({
  title,
  titleColor,
  titleHtmlTag,
  subtitle,
  template,
  redirectButtonLabel,
  redirectButtonUrl,
  redirectButtonTheme,
  createSlideHref,
  meaContentApi
}: FormattedProps) => {
  const { data, error, isLoading } = useMeaContentQuery(meaContentApi);

  if (isLoading && !data) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  if (error || !data) {
    return null;
  }

  const slides = formatHighlightsToSlides(data, createSlideHref);

  return (
    <ManualSlider
      title={title}
      titleColor={titleColor}
      titleHtmlTag={titleHtmlTag}
      subtitle={subtitle}
      template={template}
      redirectButtonLabel={redirectButtonLabel}
      redirectButtonUrl={redirectButtonUrl}
      redirectButtonTheme={redirectButtonTheme}
      slider={slides}
    />
  );
};

export default AutomaticSlider;
