/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { ChangeEventHandler, FocusEvent } from 'react';
import styled from 'styled-components';

import Checkbox from './Checkbox';

const CheckboxList = styled.ul``;

const CheckboxItem = styled(Checkbox)<{ scale: number }>`
  height: ${props => props.scale}rem;

  :not(:last-child) {
    margin-bottom: 12px;
  }
`;

export interface Props {
  value: string[];
  className?: string;
  name?: string | undefined;
  onFocus?: (event: FocusEvent<HTMLElement>) => void;
  onBlur?: (event: FocusEvent<HTMLElement>) => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  options: OptionType[];
  scale?: number;
  error?: string;
  disabled?: boolean;
}

interface OptionType {
  label: string;
  value: string;
}

export default function Checkboxes({
  className,
  name,
  onBlur,
  onChange,
  value: values = [],
  options,
  scale = 2,
  error,
  disabled
}: Props) {
  return (
    <CheckboxList id={name} className={className}>
      {options.map(({ label, value }) => (
        <CheckboxItem
          key={value}
          name={name}
          label={label}
          value={value}
          checked={values.includes(value)}
          onBlur={onBlur}
          onChange={onChange}
          isList={true}
          scale={scale}
          error={!!error}
          disabled={disabled}
        />
      ))}
    </CheckboxList>
  );
}
