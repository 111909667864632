/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

// eslint-disable import/prefer-default-export

import HttpClient from 'axios';

import { getPrograms } from '../selectors';
import { TV_CHANNEL_BASIC_AUTH } from '../constants';
import { getZapNumber } from '../helpers/getZapNumber';

export const constants = {
  SAVE_SCROLL_POSITION: 'TV_GUIDE_SAVE_SCROLL_POSITION',
  SELECT_TIME: 'TV_GUIDE_SELECT_TIME',
  FETCH_CHANNELS_REQUEST: 'TV_GUIDE_FETCH_CHANNELS_REQUEST',
  FETCH_CHANNELS_SUCCESS: 'TV_GUIDE_FETCH_CHANNELS_SUCCESS',
  FETCH_CHANNELS_ERROR: 'TV_GUIDE_FETCH_CHANNELS_ERROR',
  FETCH_CHANNELS_PROGRAMS_SUCCESS: 'TV_GUIDE_FETCH_CHANNELS_PROGRAMS_SUCCESS',
  FETCH_CHANNELS_PROGRAMS_REQUEST: 'TV_GUIDE_FETCH_CHANNELS_PROGRAMS_REQUEST',
  FETCH_CHANNELS_PROGRAMS_ERROR: 'TV_GUIDE_FETCH_CHANNELS_PROGRAMS_ERROR'
};

export function saveScrollPosition(position) {
  return {
    type: constants.SAVE_SCROLL_POSITION,
    payload: {
      position
    }
  };
}

export function fetchChannelsRequest() {
  return {
    type: constants.FETCH_CHANNELS_REQUEST,
    payload: {}
  };
}

export function fetchChannelsSuccess(payload) {
  return {
    type: constants.FETCH_CHANNELS_SUCCESS,
    payload
  };
}

export function fetchProgramsForChannelsSuccess(payload) {
  return {
    type: constants.FETCH_CHANNELS_PROGRAMS_SUCCESS,
    payload
  };
}

export const shouldFetchProgram = state => (day, channel) => {
  const zapNumber = getZapNumber(channel);
  return !getPrograms(day)(state)[zapNumber];
};

const getChannelUrl = (channel, day) => {
  return channel.URLChannelSchedule.replace(
    '?filter.day=0',
    `?filter.day=${day}`
  ).replace('?day=0', `?day=${day}`);
};

export function fetchProgramsForChannels(day, channels, isNew) {
  return async dispatch => {
    try {
      const responses = await Promise.all(
        channels.map(channel =>
          HttpClient({
            url: getChannelUrl(channel, day),
            method: 'GET',
            headers: isNew ? { Authorization: TV_CHANNEL_BASIC_AUTH } : null
          })
        )
      );
      return dispatch(
        fetchProgramsForChannelsSuccess({
          day,
          queries: responses.map((response, index) => ({
            channel: channels[index],
            programs: response.data
          }))
        })
      );
    } catch (error) {
      return null;
    }
  };
}

export function fetchProgramsForChannelsIfNeeded(channels, day = 0, isNew) {
  return async (dispatch, getState) => {
    const checkIfShouldFetchProgram = shouldFetchProgram(getState());
    const filteredChannels = channels.filter(channel =>
      checkIfShouldFetchProgram(day, channel)
    );

    if (filteredChannels.length === 0) return null;
    return fetchProgramsForChannels(day, filteredChannels, isNew)(dispatch);
  };
}

export function fetchChannels({ url, isNew }) {
  return async dispatch => {
    dispatch(fetchChannelsRequest());

    try {
      const response = await HttpClient({
        url,
        method: 'GET',
        headers: isNew ? { Authorization: TV_CHANNEL_BASIC_AUTH } : null
      });
      return dispatch(
        fetchChannelsSuccess({
          url,
          ...response.data
        })
      );
    } catch (error) {
      return null;
    }
  };
}

export function shouldFetchChannels(state, url) {
  const vectorUrlHasChanged = () => state.tvGuide.vectorUrl !== url;
  const hasNotFetchChannelsYet = () =>
    !Object.keys(state.tvGuide.channels.byZapNumber).length;

  return vectorUrlHasChanged() || hasNotFetchChannelsYet();
}

export function fetchChannelsIfNeeded(channelsApi) {
  return async (dispatch, getState) =>
    shouldFetchChannels(getState(), channelsApi.url)
      ? fetchChannels(channelsApi)(dispatch)
      : Promise.resolve();
}
