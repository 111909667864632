import * as React from 'react';

import {
  reducer,
  HandleModalsOpeningAction
} from '../reducers/ModalsOpeningReducer';

const initialState = {
  isLanguageSwitcherOpened: false,
  isUserMenuOpened: false
};

interface ModalsOpeningContextInterface {
  isLanguageSwitcherOpened: boolean;
  isUserMenuOpened: boolean;
}
export const ModalsOpeningContext = React.createContext<ModalsOpeningContextInterface>(
  {
    isLanguageSwitcherOpened: false,
    isUserMenuOpened: false
  }
);

const ModalsOpeningDispatchContext = React.createContext<
  React.Dispatch<HandleModalsOpeningAction> | undefined
>(undefined);

function ModalsOpeningProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <ModalsOpeningContext.Provider value={state}>
      <ModalsOpeningDispatchContext.Provider value={dispatch}>
        {children}
      </ModalsOpeningDispatchContext.Provider>
    </ModalsOpeningContext.Provider>
  );
}

const useHandleModalsOpening = () => {
  const context = React.useContext(ModalsOpeningContext);

  if (context === undefined) {
    throw new Error(
      'useHandleModalsOpening must be used within a ModalsOpeningProvider'
    );
  }
  return context;
};

function useHandleModalsOpeningDispatch() {
  const context = React.useContext(ModalsOpeningDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useHandleModalsOpeningDispatch must be used within a ModalsOpeningProvider'
    );
  }
  return context;
}

export {
  ModalsOpeningProvider,
  useHandleModalsOpening,
  useHandleModalsOpeningDispatch
};
