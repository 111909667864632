import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import getTagManagerHeadSnippet from '../helpers/getTagManagerHeadSnippet';
import getTealiumHeadSnippet from '../helpers/getTealiumHeadSnippet';

const CHANNEL = 'Customer Care C+I';
const DIDOMI_API_KEY = process.env.DIDOMI_API_KEY_PROD;

type Props = {
  zoneBigram: string;
  zoneDefaultLang: string;
  tagManager?: string;
  tealium?: string;
  noticeId?: string;
};

function TrackingConsent({
  zoneBigram,
  zoneDefaultLang,
  tagManager,
  tealium,
  noticeId
}: Props) {
  const [scriptTealiumLoaded, setScriptTealiumLoaded] = useState(false);

  // Loading the Tealium script in a useEffect to benefit from the onload function because
  // the Didomi script is loaded after the Tealium script
  useEffect(() => {
    if (!tealium) {
      return;
    }
    const scriptTealium = document.createElement('script');
    scriptTealium.dataset.id = 'tt-script';
    scriptTealium.src =
      'https://data.canalplus.com/production/v2/trcking.min.js';
    scriptTealium.dataset.channel = CHANNEL;
    scriptTealium.dataset.zone = zoneBigram;
    scriptTealium.dataset.locale = zoneDefaultLang;
    scriptTealium.dataset.tealiumKey = tealium;
    scriptTealium.onload = () => {
      setScriptTealiumLoaded(true);
    };

    document.head.appendChild(scriptTealium);

    // Clean script added when unmounting component
    return () => {
      document.head.removeChild(scriptTealium);
    };
  }, [zoneBigram, zoneDefaultLang, tealium]);

  return (
    <>
      <Helmet>
        {tealium && (
          <script type="text/javascript">{getTealiumHeadSnippet()}</script>
        )}
        {scriptTealiumLoaded && noticeId && (
          <script
            type="text/javascript"
            data-api-key={DIDOMI_API_KEY}
            data-notice-id={noticeId}
            src="https://static.canal-plus.net/boutique/didomi-v2.js"
          ></script>
        )}
        {tagManager && (
          <script type="text/javascript">
            {getTagManagerHeadSnippet(tagManager!)}
          </script>
        )}
      </Helmet>
      {tagManager && (
        <noscript>
          <iframe
            title="Google Tag Manager"
            src={`https://www.googletagmanager.com/ns.html?id=${tagManager}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
          `
        </noscript>
      )}
    </>
  );
}

export default TrackingConsent;
