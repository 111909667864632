import { useRef, useCallback } from 'react';

export default function usePropagateClick<T extends HTMLElement>() {
  const target = useRef<T | null>(null);

  const handleClick = useCallback(() => {
    if (target.current) {
      target.current.click();
    }
  }, []);

  return { target, handleClick };
}
