/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';
import omit from 'lodash/omit';

import {
  Content,
  BannerTemplate,
  LayoutFormattedProps,
  AnimationBannerContent,
  AnimationBannerItem,
  LayoutBannerContent,
  AnimationFormattedProps
} from './types';
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { PropsFormatterUtils } from 'shared/types';
import setLocalStorage from 'shared/helpers/localStorage/setLocalStorage';
import { APASH_PRODUCT_EXTERNAL_ID } from 'shared/constants/localstorage';
import { getLinkHref } from 'shared/helpers/uri';
import { BREAKPOINTS } from 'shared/components/presentational/Picture/constants';

enum BannerType {
  PRIMARY = 'primaryBanner',
  SECONDARY = 'secondaryBanner'
}

const getMediaAlt = (content: LayoutBannerContent, bannerType: BannerType) =>
  content.medias?.[bannerType]?.desktopMedia?.description ??
  content.medias?.[bannerType]?.desktopMedia?.filename ??
  content.banner?.media?.description ??
  content.banner?.media?.filename;

function isAnimationBannerContent(
  content: Content
): content is AnimationBannerContent {
  const bannerTemplate = content.banner?.template;

  return (
    bannerTemplate === BannerTemplate.AnimationBig ||
    bannerTemplate === BannerTemplate.AnimationSmall
  );
}

function propsFormatter(
  content: LayoutBannerContent,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): LayoutFormattedProps;
function propsFormatter(
  content: AnimationBannerContent,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): AnimationFormattedProps;
function propsFormatter(
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): AnimationFormattedProps | LayoutFormattedProps {
  if (isAnimationBannerContent(content)) {
    return formattedAnimationTemplate(content);
  }
  return formattedLayoutTemplate(content, getLinkHrefWithAuthentication);
}

function formattedAnimationTemplate(
  content: AnimationBannerContent
): AnimationFormattedProps {
  return {
    bannerTemplate: content.banner.template,
    autoPlaySpeed: content.banner?.autoPlaySpeed ?? null,
    items: content.items.map((item: AnimationBannerItem) => {
      const desktopMediaLink = item.medias.desktopMedia?.link ?? null;
      const tabletMediaLink = item.medias.tabletMedia?.link ?? desktopMediaLink;
      const mobileMediaLink =
        item.medias.mobileMedia?.link ?? tabletMediaLink ?? desktopMediaLink;

      return {
        sticker: item.logotype ?? '',
        title: item.title?.label,
        titleHtmlTag: item.title?.htmlTag,
        description: item.description ?? '',
        link: item.primaryLink
          ? {
              label: item.primaryLink.label,
              url: getLinkHref(item.primaryLink.link)
            }
          : null,
        button: {
          label: item.primaryButton?.label,
          url: getLinkHref(item.primaryButton?.link || '')
        },
        banner: {
          pictures: {
            desktop: {
              url: getLinkHref(desktopMediaLink),
              w: BREAKPOINTS.DESKTOP + 1
            },
            tablet: {
              url: getLinkHref(tabletMediaLink),
              w: BREAKPOINTS.DESKTOP
            },
            mobile: {
              url: getLinkHref(mobileMediaLink),
              w: BREAKPOINTS.PHABLET
            }
          }
        }
      };
    })
  };
}

function formattedLayoutTemplate(
  content: LayoutBannerContent,
  getLinkHrefWithAuthentication: PropsFormatterUtils['getLinkHrefWithAuthentication']
): LayoutFormattedProps {
  return {
    title: get(content, 'title.label'),
    titleColor: get(content, 'title.color'),
    titleHtmlTag: get(content, 'title.htmlTag'),
    subtitle: content.subtitle ?? undefined,
    priceInteger: content.banner?.price?.marketingPriceInteger,
    priceExponent: content.banner?.price?.marketingPriceExponent,
    priceTerms: content.banner?.price?.marketingPriceTerms,
    primaryMediaDesktop:
      content.medias?.primaryBanner?.desktopMedia?.link?.href,
    primaryMediaTablet: content.medias?.primaryBanner?.tabletMedia?.link?.href,
    primaryMediaMobile: content.medias?.primaryBanner?.mobileMedia?.link?.href,
    primaryMediaAlt: getMediaAlt(content, BannerType.PRIMARY),
    secondaryMediaDesktop:
      content.medias?.secondaryBanner?.desktopMedia?.link?.href,
    secondaryMediaTablet:
      content.medias?.secondaryBanner?.tabletMedia?.link?.href,
    secondaryMediaMobile:
      content.medias?.secondaryBanner?.mobileMedia?.link?.href,
    secondaryMediaAlt: getMediaAlt(content, BannerType.SECONDARY),
    bannerTitle: content.banner?.title?.label,
    bannerTitleHtmlTag: content.banner?.title?.htmlTag,
    bannerTitleColor: content.banner?.title?.color,
    bannerSubtitle: get(content, 'banner.subtitle'),
    bannerBackgroundImageUrl: getLinkHref(
      get(content, 'banner.media.link', '')
    ),
    bannerDescription: content.banner?.description,
    bannerBackgroundImageAlt:
      content.banner?.media?.description ?? 'banner image',
    bannerTemplate: get(content, 'banner.template'),
    bannerContentColor: content.banner?.color,
    bannerButtonLabel: get(content, 'buttons.bannerButton.label'),
    bannerButtonTheme: themeFactory({
      color: get(content, 'buttons.bannerButton.labelColor'),
      backgroundColor: get(content, 'buttons.bannerButton.backgroundColor')
    }),
    bannerButtonUrl: getLinkHrefWithAuthentication(
      get(content, 'buttons.bannerButton.link', '')
    ),
    logotype: content.banner?.logotype,
    handleBannerClick:
      content.banner?.productExternalId &&
      content.banner.template === BannerTemplate.TwoImages
        ? () =>
            setLocalStorage(
              APASH_PRODUCT_EXTERNAL_ID,
              content.banner.productExternalId!
            )
        : undefined
  };
}

export const hasFormattedProps = (formattedProps: LayoutFormattedProps) => {
  const testedProps = omit(formattedProps, [
    'bannerButtonTheme',
    'primaryMediaAlt',
    'secondaryMediaAlt',
    'bannerBackgroundImageAlt'
  ]);
  return Object.values(testedProps).some(
    propValue =>
      typeof propValue !== 'undefined' && propValue !== null && propValue !== ''
  );
};

export default propsFormatter;
