/* eslint-disable react/forbid-prop-types */
import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

import { scrollTo } from 'shared/helpers/viewport';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { FONT_WEIGHTS } from 'shared/constants/theme';
import DetailShowEpisodeList from '../childrens/DetailShowEpisodesList';
import DetailPage from './DetailPage.template';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 860px;
  margin: 0 auto;
  ${mediaQueries.toTablet`
    padding: 15px;
  `};
`;

const Title = styled.div`
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${pxInRem(20)};
`;

export default class DetailSeason extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    player: PropTypes.object,
    baseLink: PropTypes.string
  };

  isCurrentEpisode = episode =>
    episode.contentID === this.props.data.detail.selectedEpisode.episodeID;

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      scrollTo(0);
    }
  }

  render() {
    const { data, player, baseLink } = this.props;

    // If the key selectedEpisode is set, we need to find the episode with the same episodeID
    // as the episodes.contents[]contentID and override the detail.informations
    const formattedData = cloneDeep(data);

    // need to add the key isInOfferForVoD to get the player available in the DetailPage component
    formattedData.detail.informations.isInOfferForVoD = true;

    if (formattedData.detail.selectedEpisode) {
      formattedData.detail.informations = {
        personnalities: data.detail.informations.personnalities,
        reviews: data.detail.informations.reviews,
        recommendationTags: data.detail.informations.recommendationTags,
        ...formattedData.episodes.contents.find(this.isCurrentEpisode)
      };
      // The title is set here because we need the new title to amend it to the data title
      formattedData.detail.informations.title = `${data.detail.informations.title} - ${formattedData.detail.informations.title}`;
    } else {
      formattedData.detail.informations.contentID =
        formattedData.episodes.contents[0].contentID;
    }

    return (
      <Fragment>
        <DetailPage data={formattedData} player={player} />
        <Wrapper>
          <Title>{data.episodes.label}</Title>
          <DetailShowEpisodeList data={data} baseLink={baseLink} />
        </Wrapper>
      </Fragment>
    );
  }
}
