import styled from 'styled-components';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { COLORS } from 'shared/constants/theme';

interface ErrorComponent {
  className?: string;
  message?: string;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin: 20px 0;
  justify-content: center;
  background-color: ${COLORS.red};
  color: ${COLORS.white};
`;

const Text = styled.p`
  word-wrap: anywhere;
  text-align: center;
  margin: 0;
`;

const Error: React.FC<ErrorComponent & WithTranslation> = ({
  className,
  message,
  t
}) => (
  <Wrapper className={className} data-testid="error-wrapper">
    <Text>{message || t('common.global.error')}</Text>
  </Wrapper>
);

export default withTranslation()(Error);
