import styled from 'styled-components';

import { Tabs as TabsComponent } from 'shared/blocks/one-shop/shared/components/Tabs/component/Tabs/Tabs';
import { mediaQueries } from 'shared/helpers/styled-components/mediaQueries';

export const Tabs = styled(TabsComponent)`
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-m);

  ${mediaQueries.fromDesktop`
    margin-bottom: 32px;
  `};
`;
