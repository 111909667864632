import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { COLORS } from 'shared/constants/theme';

const Wrapper = styled.figure`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: ${COLORS.black};
  position: absolute;
  z-index: 0;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: inherit;
  object-fit: cover;
  object-position: center;
  opacity: 0.3;
`;

interface Props {
  src: string;
  poster: string;
}

export default class BackgroundVideo extends PureComponent<Props> {
  public render(): React.ReactNode {
    const { src, poster } = this.props;
    return (
      <Wrapper>
        <StyledVideo src={src} poster={poster} autoPlay muted loop playsInline>
          <p>Your browser doesn&apos;t support HTML5 video...</p>
        </StyledVideo>
      </Wrapper>
    );
  }
}
