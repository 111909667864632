import { Content, FormattedProps } from './types';
import { getLinkHref } from 'shared/helpers/uri';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  header: {
    title: {
      label: content.title.label || '',
      htmlTag: content.title.htmlTag
    },
    subtitle: content.subtitle
  },
  icon: content.pictogram?.link
    ? {
        image: getLinkHref(content.pictogram.link),
        alt: content.pictogram.description || content.pictogram.filename || ''
      }
    : null,
  content: {
    title: content.mainText,
    text: content.secondaryText
  },
  button: {
    label: content.button.label,
    link: getLinkHrefWithAuthentication(content.button.link)
  }
});
