import React from 'react';
import styled, { css } from 'styled-components';
import BaseSlider from 'react-slick';
import { stripUnit } from 'polished';

// Global
import { COLORS, DIMENSIONS, FONT_FAMILIES } from 'shared/constants/theme';
import breakpoints from 'shared/constants/breakpoints';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Arrow from 'shared/components/presentational/Slider/Arrow';
// Local
import {
  SLIDE_HORIZONTAL_GAP_DESKTOP,
  SLIDE_HORIZONTAL_GAP_TABLET,
  SLIDE_HORIZONTAL_GAP,
  TWO_SLIDES_THUMBNAIL_HEIGHT_DESKTOP,
  TWO_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_TABLET,
  TWO_SLIDES_THUMBNAIL_RATIO,
  THREE_SLIDES_THUMBNAIL_HEIGHT_DESKTOP,
  THREE_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_TABLET,
  THREE_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_PHONE,
  THREE_SLIDES_THUMBNAIL_RATIO,
  ARROW_BOX_WIDTH,
  ARROW_BOX_WIDTH_DESKTOP
} from './constants';
import { SlideThumbnail, SlideWrapper } from '../Slide/styles/components';
import { CustomTitle } from 'shared/components/presentational/Title/sharedTitles';

const StyledArrow = styled(Arrow)`
  & > svg {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.6));
    width: 50px;
    height: 50px;
  }
`;

export const SliderSkeleton = styled(BaseSlider).attrs(
  (props: {
    slidesToShow: number;
    nextArrow?: React.ReactElement;
    prevArrow?: React.ReactElement;
    showDots?: boolean;
    isCenterMode?: boolean;
    initialSlide?: number;
    isInfinite?: boolean;
    isOneShop?: boolean;
  }) => ({
    nextArrow: props.nextArrow ? (
      props.nextArrow
    ) : (
      <StyledArrow name="ArrowRight" color={COLORS.white} />
    ),
    prevArrow: props.prevArrow ? (
      props.prevArrow
    ) : (
      <StyledArrow name="ArrowLeft" color={COLORS.white} />
    ),
    dots: props.showDots ? props.showDots : false,
    useCss: true,
    infinite: props.isInfinite === undefined ? true : props.isInfinite,
    speed: 500,
    centerMode: props.isCenterMode !== undefined ? props.isCenterMode : true,
    slidesToScroll: 1,
    initialSlide: props.initialSlide !== undefined ? props.initialSlide : 1,
    slidesToShow: props.slidesToShow,
    responsive: [
      {
        breakpoint: stripUnit(breakpoints.tablet),
        settings: {
          slidesToShow:
            props.slidesToShow === 2 || props.isOneShop
              ? props.slidesToShow
              : props.slidesToShow - 1,
          arrows: false
        }
      },
      {
        breakpoint: stripUnit(breakpoints.phone),
        settings: {
          slidesToShow: 1,
          arrows: false
        }
      }
    ]
  })
)<{ hide: boolean }>`
  margin-bottom: ${DIMENSIONS.LONG_VERTICAL_MARGIN}px;

  transition: opacity 1s;

  ${props =>
    props.hide &&
    css`
      transition: opacity 0s;
      opacity: 0;
    `}

  .slick-next,
  .slick-prev {
    z-index: 2;
    top: 0;
    width: ${ARROW_BOX_WIDTH}px;
    ${mediaQueries.fromDesktop`
      width: ${ARROW_BOX_WIDTH_DESKTOP}px;
    `}
    ${props =>
      props.slidesToShow === 2 &&
      css`
        height: ${TWO_SLIDES_THUMBNAIL_HEIGHT_DESKTOP}px;
        ${mediaQueries.toTablet`
        height: calc(100vw / ${TWO_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_TABLET} / ${TWO_SLIDES_THUMBNAIL_RATIO});
      `}
        ${mediaQueries.toPhone`
          height: calc(100vw / ${TWO_SLIDES_THUMBNAIL_RATIO});
        `}
      `}
    ${props =>
      props.slidesToShow === 3 &&
      css`
        height: ${THREE_SLIDES_THUMBNAIL_HEIGHT_DESKTOP}px;
        ${mediaQueries.toTablet`
        height: calc(100vw / ${THREE_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_TABLET} / ${THREE_SLIDES_THUMBNAIL_RATIO});
      `}
        ${mediaQueries.toPhablet`
        height: calc(100vw / ${THREE_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_PHONE} / ${THREE_SLIDES_THUMBNAIL_RATIO});
      `}
      ${mediaQueries.toPhone`
          height: calc(100vw / ${THREE_SLIDES_THUMBNAIL_RATIO});
        `}
      `}
    transform: unset;

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }

    // Hide built-in arrow
    &:before {
      content: '';
    }
  }

  // Hide arrow if next/prev arrow is disabled
  .slick-disabled {
    display: none !important;
  }

  .slick-prev {
    left: 0;

    img {
      transform: rotate(180deg);
    }
  }

  .slick-next {
    right: 0;
  }

  ${SlideWrapper} {
    padding: 0 ${SLIDE_HORIZONTAL_GAP / 2}px;
    ${mediaQueries.fromTablet`
      padding: 0 ${SLIDE_HORIZONTAL_GAP_TABLET / 2}px;
    `}
    ${mediaQueries.fromDesktop`
      padding: 0 ${SLIDE_HORIZONTAL_GAP_DESKTOP / 2}px;
    `}
  }
  ${SlideThumbnail} {
    ${mediaQueries.toPhone`
      height: calc(100vw / ${THREE_SLIDES_THUMBNAIL_RATIO});
    `}
    ${props =>
      props.slidesToShow === 2 &&
      css`
        height: ${TWO_SLIDES_THUMBNAIL_HEIGHT_DESKTOP}px;
        ${mediaQueries.toTablet`
        height: calc(100vw / ${TWO_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_TABLET} / ${TWO_SLIDES_THUMBNAIL_RATIO});
      `}
        ${mediaQueries.toPhone`
          height: calc(100vw / ${TWO_SLIDES_THUMBNAIL_RATIO});
        `}
      `}
    ${props =>
      props.slidesToShow === 3 &&
      css`
        height: ${THREE_SLIDES_THUMBNAIL_HEIGHT_DESKTOP}px;
        ${mediaQueries.toTablet`
        height: calc(100vw / ${THREE_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_TABLET} / ${THREE_SLIDES_THUMBNAIL_RATIO});
      `}
        ${mediaQueries.toPhablet`
        height: calc(100vw / ${THREE_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_PHONE} / ${THREE_SLIDES_THUMBNAIL_RATIO});
      `}
      ${mediaQueries.toPhone`
          height: calc(100vw / ${THREE_SLIDES_THUMBNAIL_RATIO});
        `}
      `}
  }
`;

export const Title = styled(CustomTitle)`
  font-family: ${FONT_FAMILIES.base};
  text-align: left;
`;
