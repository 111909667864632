import React, { Fragment, PureComponent } from 'react';
import { createGlobalStyle } from 'styled-components';
import { withTranslation } from 'react-i18next';

import isMobile from 'shared/helpers/devices/isMobile';

const GlobalStyle = createGlobalStyle`
 #closeWebView {
    position: fixed;
    width: 27px;
    height: 27px;
    top: 12px;
    right: 10px;
    cursor: pointer;
  }
`;

class WebviewCloseButton extends PureComponent {
  isMobileDevice = () => isMobile(this.props.devices);

  render() {
    return (
      <Fragment>
        {/* piece of code required by Canal+ International PO */}
        {this.isMobileDevice() && (
          <a
            id="closeWebView"
            className="webviewLink"
            href="pass://closeWebView"
          >
            <img
              alt={this.props.t('components.webviewCloseButton.close')}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IArs4c6QAAAVhJREFUOBGt071KxEAQAOCZjZyFcGBldZUvIDZio6bxATw5RNBCiCK+i6CN0Tstztvr1FLsFB/C1leQE0RMds0ENuT2Jj+X3DbZzOx82b/gdW/gCz134Xl7HzCD5t/KQ9B6WWjAYYB/71fd+626rt8dXEboGTYb50iY35MbGtQjaNg99Q5eq3wgRhHWInT7pNP5iuG6uI2Sl8BVcQ6dgKfFs1AWNjiAfhJC7Bwf7b9RzG55KI0d24p08c3dcDNU4YMjnLaNF6G5MCU5vAxaCNu4ClU7WmNypSif1TK3Il0QzzxUzwL1JzTn1+mepvNcX3BBO0YzJVQBLuF3sGLnufdC2OwpzdSJbgkdKK2Aw9Kx3K0wqPlNqZA70DRo+pkwh5qiMjgL56Fl8Qm4DFoGH4OnQYvwBK6C5uExXAfNwnEWKIdjv/+y0Go1fl3XDcyAOk8p5eLoR6/+A0mBBxREip9KAAAAAElFTkSuQmCC"
              className="close"
            />
          </a>
        )}
        <GlobalStyle />
      </Fragment>
    );
  }
}

export default withTranslation()(WebviewCloseButton);
