export const BANNER_HEIGHT = 500; // in px

export const TWO_IMAGES_BANNER_HEIGHT = 620; // in pax

export const BANNER_PHABLET_HEIGHT = 480; // in px

export const TWO_IMAGES_BANNER_HEIGHT_PHONE = 470; //in px

export const BANNER_PHONE_HEIGHT = 330; // in px

export const TOP_BANNER_PADDING = 100; // in px

export const BOTTOM_BANNER_PADDING = 70; // in px
