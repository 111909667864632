import isArray from 'lodash/isArray';

import getQueryParams from 'shared/modules/router/selectors/getQueryParams';

export default (state: object): string | undefined => {
  const { option } = getQueryParams(state);

  if (!option || isArray(option)) {
    return undefined;
  }

  return option;
};
