export const SAVE_OFFER_SUBSCRIPTION_FORM_ACTION_TYPE =
  '@Eshop/SAVE_OFFER_SUBSCRIPTION_FORM';

export enum FIELDS {
  TITLE = 'title',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  CITY = 'city',
  POSTAL_CODE = 'postalCode',
  COUNTRY_CODE = 'countryCode',
  ADDRESS = 'address',
  ADDRESS_COMPLEMENT = 'additionalAddress',
  MOBILE_PHONE_NUMBER = 'mobilePhoneNumber',
  EMAIL = 'email',
  IBAN_CODE = 'ibanCode',
  REFUSE_NEWSLETTER = 'refuseNewsletter',
  OFFER_TERMS_AGREEMENT = 'offerTermsAgreement'
}
