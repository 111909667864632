import { TitleHtmlTag, Link } from 'shared/types';

export interface FormattedAdvantage {
  title?: string | null;
  logo: string;
  description?: string;
}

export enum TEMPLATE_DIGITAL_ADVANTAGES {
  FOLDS = 'default',
  FIXED = 'fixed'
}

export interface FormattedProps {
  title: string;
  titleColor: string;
  subtitle?: string;
  template: TEMPLATE_DIGITAL_ADVANTAGES;
  titleHtmlTag: TitleHtmlTag;
  color: string;
  toggleColor?: string;
  advantages: FormattedAdvantage[];
}

export interface Content {
  title: {
    label: string;
    color: string;
    htmlTag: TitleHtmlTag;
  };
  subtitle?: string | null;
  color: {
    default: string;
    toggle?: string;
  };
  template: TEMPLATE_DIGITAL_ADVANTAGES;
  advantages: ContentAdvantage[];
}

export type ContentAdvantage = {
  icon: {
    link: Link;
  };
  title?: {
    label: string | null;
  };
  description?: string;
};
