import React, { ChangeEventHandler, ReactNode, useCallback } from 'react';
import styled from 'styled-components';

import { BaseForm } from 'shared/types';
import { COLORS, TRANSITIONS } from 'shared/constants/theme';
import usePropagateClick from 'shared/helpers/dom/usePropagateClick';
import pxInRem from 'shared/helpers/styled-components/remHelper';

const Wrapper = styled.div<{
  isList?: boolean;
  disabled?: boolean;
  label?: string | ReactNode;
  scale: number;
}>`
  display: flex;
  flex-direction: ${props => (props.isList ? 'row' : 'column')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  ${props =>
    !props.label &&
    `
      width: ${props.scale}rem;
      height: ${props.scale}rem
    `}
`;

const Label = styled.label`
  display: flex;
  align-items: baseline;
  font-size: ${pxInRem(16)};
  line-height: ${pxInRem(23)};
`;

const CheckboxWrapper = styled.button.attrs({ type: 'button' })<{
  scale: number;
  color?: string;
  checked: boolean;
  borderColor?: string;
  backgroundColor?: string;
  label?: string | ReactNode;
  error?: boolean;
}>`
  position: relative;
  cursor: pointer;
  width: ${props => props.scale}rem;
  min-width: ${props => props.scale}rem;
  height: ${props => props.scale}rem;
  margin-right: ${props => (props.label ? 15 : 0)}px;

  &:before {
    transition: transform 0.4s ${TRANSITIONS.bounce};
    transform: rotate(-45deg) scale(0, 0);

    content: '';

    position: absolute;
    top: ${props => 0.2 * props.scale}rem;
    left: ${props => 0.25 * props.scale}rem;
    z-index: 1;

    width: ${props => 0.5 * props.scale}rem;
    height: ${props => 0.35 * props.scale}rem;

    border: 2px solid ${props => (props.color ? props.color : COLORS.torchRed)};
    border-top-style: none;
    border-right-style: none;
  }

  &:after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: ${props => props.scale}rem;
    height: ${props => props.scale}rem;

    background: ${props =>
      props.backgroundColor ? props.backgroundColor : COLORS.white};
    border-radius: 3px;
    border: 2px solid
      ${props =>
        props.error
          ? COLORS.amaranth
          : props.borderColor
          ? props.borderColor
          : COLORS.alto};
  }

  &:focus {
    &:after {
      border-color: ${props => (props.color ? props.color : COLORS.black)};
      border-radius: 3px;
    }
  }

  ${props =>
    props.checked &&
    `
    &:before {
      transform: rotate(-45deg) scale(1, 1);
    }

    &:after {
      border-color: ${
        props.error
          ? COLORS.amaranth
          : props.borderColor
          ? props.borderColor
          : COLORS.black
      };
    }
  `}
`;

const Input = styled.input.attrs({ type: 'checkbox' })<Props>``;

export interface Props extends BaseForm {
  value?: string;
  label?: string | ReactNode;
  checked: boolean;
  disabled?: boolean;
  isList?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  scale?: number;
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
  error?: boolean;
}

export default function Checkbox({
  name,
  value,
  checked,
  label,
  onChange,
  onBlur,
  disabled,
  scale = 2,
  color,
  borderColor,
  backgroundColor,
  className,
  isList = false,
  error
}: Props) {
  const {
    target: input,
    handleClick: propagateInputClick
  } = usePropagateClick<HTMLInputElement>();

  const renderInput = useCallback(() => {
    return (
      <CheckboxWrapper
        scale={scale}
        color={color}
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        checked={checked}
        disabled={disabled}
        onClick={propagateInputClick}
        onBlur={onBlur}
        label={label}
        name={name}
        role="checkbox"
        aria-checked={checked}
        error={error}
      >
        <Input
          ref={input}
          name={name}
          value={value || name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          tabIndex={-1}
        />
      </CheckboxWrapper>
    );
  }, [
    scale,
    color,
    borderColor,
    checked,
    disabled,
    propagateInputClick,
    onBlur,
    label,
    name,
    value,
    onChange
  ]);

  return (
    <Wrapper
      label={label}
      scale={scale}
      className={className}
      isList={isList}
      disabled={disabled}
    >
      {label ? (
        <Label>
          {renderInput()}
          <span>{label}</span>
        </Label>
      ) : (
        renderInput()
      )}
    </Wrapper>
  );
}
