import { TASK_TYPE } from 'shared/modules/tasks/constants';
import getBlocksByNamespaces from 'shared/modules/page/selectors/getBlocksByNamespaces';
import fetchVodUrlIfNeeded from '../shared/actions/fetchVodUrlIfNeeded';
import { namespace } from './index';
import { getFormattedApis } from './propsFormatter';

export default ({ dispatch, getState }) => [
  {
    pattern: '/:bigram([a-zA-Z]{2})?/',
    [TASK_TYPE.DEFERRED_DATA]: async () => {
      const state = getState();

      const blocks = getBlocksByNamespaces(state, [namespace]);

      if (blocks.length) {
        const block = blocks[0];

        const { categoriesListApiUrl, categoryApiUrl } = getFormattedApis(
          block.content
        );

        await Promise.all([
          dispatch(fetchVodUrlIfNeeded(categoriesListApiUrl)),
          dispatch(fetchVodUrlIfNeeded(categoryApiUrl))
        ]);
      }
    }
  }
];
