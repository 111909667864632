import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { TFunction } from 'i18next';

import Button from 'shared/components/presentational/Button';
import buttonWitheTheme from './themes/button.white';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import { FormattedProps, PropsFromState } from '../../types';

const Wrapper = styled.div`
  width: 100%;
  background-color: ${COLORS.white};
  padding: 25px 15px 15px;
`;

const Title = styled.p`
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  color: ${COLORS.black};
  margin-bottom: 10px;
`;

const RotatedSvgIcon = styled(Button.children.SvgIcon)`
  transform: rotate(-90deg);
`;

const buttonSharedCSS = css`
  justify-content: flex-start;
  padding-left: 0 !important;
`;
const StyledButton = styled(Button)`
  ${buttonSharedCSS};
`;
const StyledButtonWithOffset = styled(Button)`
  ${buttonSharedCSS};
  position: relative;
  left: -4px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const logoutButtonTheme = {
  button: {
    ...Button.themes.blueTransparent.button,
    normal: {
      ...Button.themes.blueTransparent.button.normal,
      textDecoration: 'none'
    }
  }
};

export interface Props
  extends Pick<PropsFromState, 'logoutUrl'>,
    Pick<
      FormattedProps,
      | 'boxTitle'
      | 'boxSubscriptionManagementLabel'
      | 'boxSubscriptionManagementUrl'
      | 'boxLoyaltyProgramLabel'
      | 'boxLoyaltyProgramUrl'
    > {
  className?: string;
  onItemClick: () => void;
}

export default class BoxLogged extends PureComponent<Props & { t: TFunction }> {
  public render(): React.ReactNode {
    const {
      className,
      logoutUrl,
      boxTitle,
      boxSubscriptionManagementLabel,
      boxSubscriptionManagementUrl,
      boxLoyaltyProgramLabel,
      boxLoyaltyProgramUrl,
      onItemClick,
      t
    } = this.props;

    const showSubscriptionManagement = Boolean(boxSubscriptionManagementUrl);
    const showLoyaltyProgram = Boolean(boxLoyaltyProgramUrl);
    const showLogout = Boolean(logoutUrl);

    return (
      <Wrapper className={className}>
        <Title>{boxTitle}</Title>
        <Content>
          {showSubscriptionManagement && (
            <StyledButton
              theme={buttonWitheTheme}
              to={boxSubscriptionManagementUrl}
              onClick={onItemClick}
            >
              <Button.children.Text>
                {boxSubscriptionManagementLabel}
              </Button.children.Text>
              <RotatedSvgIcon
                xlink="/sprite.svg#cos-svg-menu"
                width="20"
                height="20"
              />
            </StyledButton>
          )}
          {showLoyaltyProgram && (
            <StyledButton
              theme={buttonWitheTheme}
              to={boxLoyaltyProgramUrl}
              onClick={onItemClick}
            >
              <Button.children.Text>
                {boxLoyaltyProgramLabel}
              </Button.children.Text>
              <RotatedSvgIcon
                xlink="/sprite.svg#cos-svg-menu"
                width="20"
                height="20"
              />
            </StyledButton>
          )}
          {showLogout && (
            <StyledButtonWithOffset
              theme={logoutButtonTheme}
              to={logoutUrl}
              onClick={onItemClick}
            >
              <Button.children.SvgIcon
                xlink="/sprite.svg#cos-svg-power"
                width="20"
                height="20"
              />
              <Button.children.Text>
                {t('blocks.header.disconnect')}
              </Button.children.Text>
            </StyledButtonWithOffset>
          )}
        </Content>
      </Wrapper>
    );
  }
}
