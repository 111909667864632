import get from 'lodash/get';
import queryString from 'query-string';

export default state => {
  const { thematic = '', index = 0 } = queryString.parse(
    get(state, 'router.location.search', '')
  );

  return {
    thematic,
    index: parseInt(index, 10)
  };
};
