/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { ACTION_TYPES } from '../constants';

// -----------------------------------------------------------------------------
// EXPORTED REDUCER STATE TYPE

/* export type State = {
  /!**
   * The visuals with S size, fetched manually, semi-automatically or automatically
   *!/
  visualsSByBlockId: {}
}; */

// -----------------------------------------------------------------------------
// PRIVATES

const defaultState = {
  visualsSByBlockId: {}
};

// -----------------------------------------------------------------------------
// REDUCER

export default function programPack(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_VISUALS_RESET:
      return {
        ...state,
        visualsSByBlockId: {
          ...state.visualsSByBlockId,
          [action.payload.id]: undefined
        }
      };
    case ACTION_TYPES.FETCH_VISUALS_SUCCEED:
      return {
        ...state,
        visualsSByBlockId: {
          ...state.visualsSByBlockId,
          [action.payload.id]: action.payload.visuals
        }
      };
    default:
      return state;
  }
}
