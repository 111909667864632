import React, { PureComponent } from 'react';
import { FieldProps } from 'formik';

import Radio, { Props as RadioProps } from '../Radio';

export default class RadioField extends PureComponent<
  FieldProps<any> & RadioProps
> {
  public render() {
    const {
      field: { name, value, onChange, onBlur },
      id,
      label,
      className,
      disabled,
      ...props
    } = this.props;

    return (
      <Radio
        name={name}
        className={className}
        label={label}
        value={id}
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        {...props}
      />
    );
  }
}
