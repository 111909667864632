import get from 'lodash/get';

import {
  ArticleContent,
  FormattedArticleContent,
  FormattedFragment
} from '../types';
import { getLinkHref } from 'shared/helpers/uri';
import formatFragments from './formatFragments';

export default (
  articleContent: ArticleContent,
  domainUrl: string
): FormattedArticleContent => {
  return {
    id: get(articleContent, 'articleId', null),
    title: get(articleContent, 'title', null),
    subtitle: get(articleContent, 'subtitle', null),
    link: get(articleContent, 'link', null),
    slug: get(articleContent, 'slug', ''),
    mainMedia: getLinkHref(get(articleContent, 'mainMedia.link', '')),
    responsiveMedia: getLinkHref(
      get(articleContent, 'responsiveMedia.link', '')
    ),
    fragments: articleContent.fragments
      .map(item => formatFragments(item, domainUrl))
      .filter(Boolean) as FormattedFragment[]
  };
};
