import get from 'lodash/get';

import { getFilterOptions } from './';
import { FILTERS_TYPE } from '../constants';

export default filterOn => state => {
  const filter = get(state, `shops.shared.filters.${filterOn}`, null);

  switch (filter.type) {
    case FILTERS_TYPE.SELECT:
    case FILTERS_TYPE.AUTO_COMPLETE:
      return {
        ...filter,
        options: getFilterOptions(filter.filterOn)(state)
      };
    default:
      return filter;
  }
};
