import React from 'react';
import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import BaseArrow from 'shared/components/presentational/Slider/Arrow';
import { ARROW_WIDTH } from './constants';

export const Wrapper = styled.div.attrs({ tabIndex: 0, role: 'radiogroup' })<{
  withArrows?: boolean;
}>`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: ${COLORS.concrete};
  border-radius: var(--border-radius-xs);
  padding: 2px;
  ${props =>
    props.withArrows &&
    css`
      width: calc(100% + ${2 * ARROW_WIDTH}px);
      margin-left: -${ARROW_WIDTH}px;
      margin-right: -${ARROW_WIDTH}px;
    `}
`;

export const ValueLabel = styled.label.attrs({ tabIndex: -1 })<{
  checked?: boolean;
}>`
  position: relative;
  flex: 1;
  font-size: ${pxInRem(12)};
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};
  color: ${COLORS.doveGray};
  text-align: center;
  padding: 6px;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
  ${props =>
    props.checked &&
    css`
      cursor: inherit;
      border-radius: var(--border-radius-xs);
      border-color: ${COLORS.alto};
      background-color: ${COLORS.white};
      color: ${COLORS.black};
    `}
`;

export const Value = styled.input.attrs({ tabIndex: -1, type: 'radio' })`
  position: absolute;
  left: -9999px;
`;

const Arrow = styled(BaseArrow).attrs({
  tabIndex: -1,
  color: COLORS.doveGray,
  size: 15
})`
  position: absolute;
  top: 8px;
  cursor: pointer;
`;

export const ArrowLeft = styled(Arrow).attrs({ name: 'ArrowLeft' })`
  left: ${(ARROW_WIDTH - 15) / 2}px;
`;

export const ArrowRight = styled(Arrow).attrs({ name: 'ArrowRight' })`
  right: ${(ARROW_WIDTH - 15) / 2}px;
`;

export const SliderWrapper = styled.div`
  margin: 0 ${ARROW_WIDTH}px;
  width: calc(100% - ${2 * ARROW_WIDTH}px);
  overflow: hidden;
`;

export const SliderTrack = styled(animated.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: ${props => React.Children.count(props.children) * 50}%;
  & > * {
    width: 50%;
  }
`;
