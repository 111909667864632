import { OfferFormValues } from '../shared/types';
import { FIELDS } from '../shared/constants';

export const RETRIEVE_OFFER_SUBSCRIPTION_FORM_ACTION_TYPE =
  '@Eshop/RETRIEVE_OFFER_SUBSCRIPTION_FORM';

export const CHECK_OFFER_SUBSCRIPTION_FORM_ACTION_TYPE =
  '@Eshop/CHECK_OFFER_SUBSCRIPTION_FORM';

export const CLEAR_OFFER_SUBSCRIPTION_FORM_ACTION_TYPE =
  '@Eshop/CLEAR_OFFER_SUBSCRIPTION_FORM';

export const INITIAL_DEFAULT_VALUES: OfferFormValues = {
  [FIELDS.TITLE]: null,
  [FIELDS.FIRST_NAME]: '',
  [FIELDS.LAST_NAME]: '',
  [FIELDS.COUNTRY_CODE]: null,
  [FIELDS.POSTAL_CODE]: null,
  [FIELDS.CITY]: null,
  [FIELDS.ADDRESS]: '',
  [FIELDS.ADDRESS_COMPLEMENT]: '',
  [FIELDS.MOBILE_PHONE_NUMBER]: '',
  [FIELDS.EMAIL]: ''
};

export const CHECK_INTERVAL = 1000 * 60 * 5; // 5 minutes in ms
