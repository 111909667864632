import get from 'lodash/get';

import { getLinkHref } from 'shared/helpers/uri';
import { createTemplate } from 'shared/helpers/template';
import getPentagramFromPage from '../selectors/getPentagramFromPage';

export default (page: any, zone?: string) => {
  const loginLink = get(page, 'authentication.loginLink');
  const availableZones = get(page, 'site.zones');
  const offerZone = getPentagramFromPage(page);
  if (!loginLink) {
    return `/login`;
  }

  if (loginLink.rel === 'external') {
    const rawUrl = getLinkHref(loginLink);

    const formatedUrl = createTemplate(rawUrl)({
      applocation: zone || availableZones[0],
      offerZone: offerZone,
      client_id_external_authentication_URL:
        page.site?.canalBox.client_id_external_authentication_URL,
      portail_id_external_authentication_URL:
        page.site?.canalBox.portail_id_external_authentication_URL
    });
    return formatedUrl;
  }

  return getLinkHref(loginLink);
};
