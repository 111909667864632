/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

// eslint-disable import/prefer-default-export

import axios from 'axios';

import { ZONE_WEBSERVICE_URL } from '../constants';

export const TYPES = {
  FETCH_ZONE: {
    PENDING: 'GEOLOCATION_FETCH_ZONE_PENDING',
    SUCCESS: 'GEOLOCATION_FETCH_ZONE_SUCCESS',
    ERROR: 'GEOLOCATION_FETCH_ZONE_ERROR'
  },
  FETCH_COORDINATES: {
    PENDING: 'GEOLOCATION_FETCH_COORDINATES_PENDING',
    SUCCESS: 'GEOLOCATION_FETCH_COORDINATES_SUCCESS',
    ERROR: 'GEOLOCATION_FETCH_COORDINATES_ERROR'
  }
};

export function fetchZonePending() {
  return {
    type: TYPES.FETCH_ZONE.PENDING
  };
}

export function fetchZoneSuccess(result, availableZones) {
  return {
    type: TYPES.FETCH_ZONE.SUCCESS,
    payload: {
      zone: result,
      availableZones
    }
  };
}

export function fetchZoneError(error) {
  return {
    type: TYPES.FETCH_ZONE.ERROR,
    payload: error
  };
}

export function fetchCoordinatesPending() {
  return {
    type: TYPES.FETCH_COORDINATES.PENDING
  };
}

export function fetchCoordinatesSuccess(result) {
  return {
    type: TYPES.FETCH_COORDINATES.SUCCESS,
    payload: result
  };
}

export function fetchCoordinatesError(error) {
  return {
    type: TYPES.FETCH_COORDINATES.ERROR,
    payload: error
  };
}

export function fetchZone(availableZones) {
  return async dispatch => {
    dispatch(fetchZonePending());

    let response;
    try {
      response = await axios.get(ZONE_WEBSERVICE_URL);
    } catch (error) {
      return dispatch(fetchZoneError(error));
    }

    return dispatch(fetchZoneSuccess(response.data, availableZones));
  };
}

export function fetchCoordinates() {
  return async dispatch => {
    dispatch(fetchCoordinatesPending());

    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    })
      .then(response => {
        dispatch(
          fetchCoordinatesSuccess({
            latitude: response.coords.latitude,
            longitude: response.coords.longitude
          })
        );
        return response.coords;
      })
      .catch(error => {
        dispatch(fetchCoordinatesError(error));
        throw Error('Unable to retrieve your location.');
      });
  };
}
