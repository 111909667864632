import { createGlobalStyle } from 'styled-components';

import { ThemePalette } from 'shared/modules/page/types';

export const ThemePaletteGlobalStyle = createGlobalStyle<{
  theme: ThemePalette;
}>`
  :root {
    --surface-default: ${({ theme }) => theme?.surface?.default};
    --surface-hover: ${({ theme }) => theme?.surface?.hover};
    --surface-background: ${({ theme }) => theme?.surface?.background};
    
    --primary-default: ${({ theme }) => theme?.primary?.default};
    --primary-pressed: ${({ theme }) => theme?.primary?.pressed};
    --primary-hover: ${({ theme }) => theme?.primary?.hover};

    --secondary-dark-default: ${({ theme }) => theme?.secondaryDark?.default};
    --secondary-dark-pressed: ${({ theme }) => theme?.secondaryDark?.pressed};
    --secondary-dark-hover: ${({ theme }) => theme?.secondaryDark?.hover};

    --text-secondary-main: ${({ theme }) => theme?.text?.secondary.main};
    
    --text-default-main: ${({ theme }) => theme?.text?.default.main};
    --text-default-light: ${({ theme }) => theme?.text?.default.light};
    
    --border-main: ${({ theme }) => theme?.surface?.background};
  }

  [data-theme='one-shop'] {
    --surface-dark: ${({ theme }) => theme?.surface?.dark};
    --surface-light: ${({ theme }) => theme?.surface?.light};
    --surface-information: ${({ theme }) => theme?.surface?.information};

    --secondary-light-default: ${({ theme }) => theme?.secondaryLight?.default};
    --secondary-light-hover: ${({ theme }) => theme?.secondaryLight?.hover};

    --text-default-disabled: ${({ theme }) => theme?.text?.default.disabled};
    --text-secondary-light: ${({ theme }) => theme?.text?.secondary.light};
    
    --icons-default: ${({ theme }) => theme?.icons?.default};
    --icons-secondary: ${({ theme }) => theme?.icons?.secondary};
  }
`;
