/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*       */

import { HOURS } from '../constants';
import { hoursToMinutes } from './index';

export default durationInMinutes =>
  (durationInMinutes / hoursToMinutes(HOURS.length)) * 100;
