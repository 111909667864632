import axios from 'axios';

import { ENCRYPT_DATA } from 'server/constants/routes';
import {
  EncryptedFormData,
  FormDataToEncrypt,
  PayloadToEncrypt
} from '../../shared/types';

export default async (
  formDataToEncrypt: FormDataToEncrypt
): Promise<EncryptedFormData> => {
  const payloadToEncrypt: PayloadToEncrypt<FormDataToEncrypt> = {
    version: 1,
    payload: formDataToEncrypt
  };
  try {
    const { data } = await axios.post(ENCRYPT_DATA, {
      data: payloadToEncrypt
    });
    return data;
  } catch {
    return Promise.reject();
  }
};
