/*       */
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Header from './AccordionHeader';
import Content from './AccordionContent';
import Handle from './AccordionHandle';

const Wrapper = styled.div`
  background-color: #f1f1f1;
  margin-bottom: 10px;
`;

export default class Item extends PureComponent {
  static displayName = 'Accordion.Item';

  render() {
    const { children, className, isOpened, selectCurrent, id } = this.props;

    const toRender = React.Children.map(children, child => {
      if (!child || !child.type) return false;
      switch (child.type.displayName) {
        case Header.displayName: {
          return React.cloneElement(child, {
            ...child.props,
            isOpened,
            onHeaderClick: selectCurrent
          });
        }

        case Content.displayName:
        case Handle.displayName: {
          return React.cloneElement(child, {
            ...child.props,
            isOpened
          });
        }

        default: {
          // eslint-disable-next-line no-console
          console.warn(
            `Wrong implementation: 
             You must provide either an Accordion.Header, Accordion.Content or Accordion.Handle to Accordion.Item Component.`
          );
          return false;
        }
      }
    });

    return (
      <Wrapper id={id} className={className}>
        {toRender}
      </Wrapper>
    );
  }
}
