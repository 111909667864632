import get from 'lodash/get';

import { HighlightContent, HighlightsData, ProgramParams } from './types';
import eventUrlToProgramParams from './eventUrlToProgramParams';

export default (
  data: HighlightsData,
  createSlideHref: (params: ProgramParams) => string
) => {
  try {
    const contents: HighlightContent[] = get(data, 'contents', []);
    return contents.map((content: HighlightContent) => ({
      title: content.title,
      subtitle: content.subtitle,
      imageUrl: content.URLImage,
      href: createSlideHref(
        eventUrlToProgramParams(get(content, 'onClick.URLPage'))
      )
    }));
  } catch {
    console.warn('Fail to format highlight content into slides.');
    return [];
  }
};
