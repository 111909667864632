import styled from 'styled-components';

import { NavigationMenuLink } from '../types';
import Button from 'shared/components/presentational/Button';
import DropdownIndicator from 'shared/components/presentational/DropdownIndicator';
import Icon from 'shared/components/presentational/Icons';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxToRem from 'shared/helpers/styled-components/remHelper';
import {
  HEADER_UNIFIED_DESKTOP_OVERLAY_TOP_POSITION,
  HEADER_UNIFIED_MOBILE_OVERLAY_TOP_POSITION,
  HEADER_UNIFIED_USER_MENU_HEIGHT
} from 'shared/constants/heights';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

export const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export const HiddenList = styled(List)`
  visibility: hidden;
  overflow: hidden;
  position: absolute;
  top: -10px;
  margin-left: -16px;
`;

export const ShowMore = styled.button<{
  link: NavigationMenuLink;
}>`
  color: ${props => props.link.linksColor};

  font-family: inherit;
  font-weight: ${FONT_WEIGHTS.demi};
  &[aria-expanded='true'] {
    color: ${props => props.link.linksColorSecondary};
  }
  &:hover {
    ${mediaQueries.fromDesktop<{
      link: NavigationMenuLink;
    }>`
     color: ${props => props.link.linksColorSecondary};
     cursor: pointer;
     `};
  }
`;

export const GenericListItem = styled.li<{
  link: NavigationMenuLink;
}>`
  line-height: 20px;
  font-size: ${pxToRem(16)};
  color: ${props => props.link.linksColor};
  white-space: nowrap;

  &:hover,
  &:focus {
    color: ${props => props.link.linksColorSecondary};
  }
`;

export const ExpandableMenuItem = styled.li`
  padding: 14px 0;
`;

export const PlusDropDownSubMenu = styled.ul<{ backgroundColor: string }>`
  position: absolute;
  right: 0px;
  top: 29px;
  background-color: ${props => props.backgroundColor};
  padding: 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  display: flex;
  gap: 14px;

  li {
    padding: 0;
  }

  ${mediaQueries.fromTablet`
    right: calc(-50% + 10px);
    top: 41px;
  `};
`;

export const ListItem = styled(GenericListItem)<{
  link: NavigationMenuLink;
}>`
  padding-right: 32px;
  color: ${props => props.link.linksColor};

  ${mediaQueries.toPhone`
  padding-right: 16px;

  `};
`;

export const PlusListItem = styled(ListItem)<{
  link: NavigationMenuLink;
}>`
  position: relative;
  color: ${props => props.link.linksColor};
`;

export const UserMenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height: ${pxToRem(HEADER_UNIFIED_USER_MENU_HEIGHT)};
  flex: 1;
  order: 2;
  gap: 12px;
  flex-grow: 0;
  ${mediaQueries.fromTablet`
  order: 3;
  gap: 16px;
  `};
`;

export const NavigationMenuWrapper = styled.nav`
  flex: 1 0 100%;
  max-height: 40px;
  position: relative;
  order: 3;
  justify-content: flex-start;
  display: flex;
  margin-top: 16px;
  font-weight: ${FONT_WEIGHTS.demi};

  ${mediaQueries.fromTablet`
  order: 2;
  flex: inherit;
  margin-top: 0;
  flex-grow: 1;
`};
`;

export const StyledArrow = styled(Icon)`
  margin-left: 8px;
`;

export const LangsContent = styled.div<{
  link: NavigationMenuLink;
  backgroundColor: string;
}>`
  z-index: 2;
  position: absolute;
  width: 320px;
  right: -16px;
  top: ${pxToRem(HEADER_UNIFIED_MOBILE_OVERLAY_TOP_POSITION)};

  background-color: ${props => props.backgroundColor};

  color: ${props => props.link.linksColor};
  cursor: pointer;
  border-radius: 6px;

  ${mediaQueries.fromTablet`
  top: ${pxToRem(HEADER_UNIFIED_DESKTOP_OVERLAY_TOP_POSITION)};
  right: -32px;

  `};
`;

export const StyledButton = styled(Button)`
  padding: 0;
  font-weight: ${FONT_WEIGHTS.normal};
  &:not(:last-child) {
    padding-bottom: 10px;
  }
  margin-block-end: 0;
  font-size: 16px;
`;

export const StyledButtonText = styled(Button.children.Text)`
  :first-letter {
    text-transform: capitalize;
  }
`;

export const StyledToggleButton = styled.button<{
  isLangsMenuOpen: boolean;
  link: NavigationMenuLink;
}>`
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  color: ${props =>
    props.isLangsMenuOpen
      ? props.link.linksColorSecondary
      : props.link.linksColor};
  &:hover {
    color: ${props => props.link.linksColorSecondary};
  }
`;

export const LangItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 20px;
  border-top: solid 1px #242424;
  box-shadow: 0px 2px 20px 0px #0000004d;
`;

export const LangLabel = styled(Button.children.Text)`
  font-family: ${FONT_FAMILIES.base};
  font-weight: ${FONT_WEIGHTS.normal};
`;

export const StyledDropdownIndicator = styled(DropdownIndicator)`
  margin-left: 5px;
`;
export const SwitchLangWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const SwitchLangLabel = styled.p`
  margin: 16px;
  font-size: 16px;
`;

export const OverLay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  right: calc(50% - 32px);
  top: calc(50% - 36px);
  padding: 16px;
  flex-wrap: wrap;
  z-index: 1;

  ${mediaQueries.fromTablet`
  right: calc(50% - 50px);
  
  padding: 0 32px;
  flex-wrap: nowrap;
  `};
`;

export const SignCTAIcon = styled.div`
  display: none;
  ${mediaQueries.toPhone`
  display: flex;
  justify-content: center;
  width: 32px;
  heigh: 32px;
  flex-shrink: 1;
  border-radius: 6px;
`};
`;

export const SignCTALabel = styled(Button.children.Text)`
  display: block;
  font-weight: bold;
  margin-left: 0;
  padding: 7px 8px;
  width: min-content;

  ${mediaQueries.toPhone`
  display: none;
`};
`;

export const SignCTA = styled(Button)`
  padding: 0;
  height: 32px;
  justify-content: center;
  align-self: flex-end;
  transition: all 250ms ease;
  white-space: nowrap;
`;

export const WebsiteLogoWrapper = styled.div`
  flex-shrink: 0;
  margin-right: 40px;
  order: 1;
`;

export const Wrapper = styled.header<{
  backgroundColor: string;
}>`
  background-color: ${props => props.backgroundColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  padding: 16px;
  padding-bottom: 12px;
  flex-wrap: wrap;
  z-index: 4;
  height: 96px;

  ${mediaQueries.fromTablet`
  height: 64px;
  padding: 0 32px;
  flex-wrap: nowrap;
  `};
`;
