import styled from 'styled-components';

import pxInRem from 'shared/helpers/styled-components/remHelper';
import BaseButton from 'shared/components/presentational/Button/index';

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  margin: 30px;
`;

export const Button = styled(BaseButton)`
  font-size: ${pxInRem(12)};
`;
