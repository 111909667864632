export const PROGRAM_TIME_FORMAT = 'H[h]mm';

export const GROUPING_MIN_PROGRAM_DURATION = 30; // in minutes
export const GROUPING_MAX_GROUP_DURATION = 30; // in minutes

// The number of channel item that will be fetch at the same time
export const PER_PAGE = 6;

export const TIME_PERIODS = {
  now: 'now',
  tonight: 'tonight',
  week: 'week'
};

export const HOURS = [
  { label: 'blocks.tvGuide.hours.6' },
  { label: 'blocks.tvGuide.hours.7' },
  { label: 'blocks.tvGuide.hours.8' },
  { label: 'blocks.tvGuide.hours.9' },
  { label: 'blocks.tvGuide.hours.10' },
  { label: 'blocks.tvGuide.hours.11' },
  { label: 'blocks.tvGuide.hours.12' },
  { label: 'blocks.tvGuide.hours.13' },
  { label: 'blocks.tvGuide.hours.14' },
  { label: 'blocks.tvGuide.hours.15' },
  { label: 'blocks.tvGuide.hours.16' },
  { label: 'blocks.tvGuide.hours.17' },
  { label: 'blocks.tvGuide.hours.18' },
  { label: 'blocks.tvGuide.hours.19' },
  { label: 'blocks.tvGuide.hours.20' },
  { label: 'blocks.tvGuide.hours.21' },
  { label: 'blocks.tvGuide.hours.22' },
  { label: 'blocks.tvGuide.hours.23' },
  { label: 'blocks.tvGuide.hours.24' },
  { label: 'blocks.tvGuide.hours.1' },
  { label: 'blocks.tvGuide.hours.2' },
  { label: 'blocks.tvGuide.hours.3' },
  { label: 'blocks.tvGuide.hours.4' },
  { label: 'blocks.tvGuide.hours.5' }
];

export const COMPLEX_GRID_MIN_TEXT_WIDTH = 130;

export const DAY_START_TIME = { hour: 6, minute: 0, second: 0, millisecond: 0 };
export const TONIGHT_TIME = { hour: 21, minute: 0, second: 0, millisecond: 0 };

export const HEADERS_NOW = ['blocks.tvGuide.now', 'blocks.tvGuide.following'];
export const HEADERS_TONIGHT = [
  'blocks.tvGuide.tonightFirst',
  'blocks.tvGuide.tonightSecond'
];

export const TV_CHANNEL_BASIC_AUTH = `Basic ${process.env.TV_CHANNEL_BASIC_AUTH}`;
