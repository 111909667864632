import { COLORS } from 'shared/constants/theme';
import themeFactory from '../../Button/helpers/themeFactory';

export const INNER_MODAL_WRAPPER_WIDTH = 1280; // in px

export const MIN_RELATIVE_OUTER_PADDING = 5; // in vw

export const MODAL_WITH_MIN_OUTER_PADDING_BREAKPOINT =
  INNER_MODAL_WRAPPER_WIDTH * (100 / (100 - MIN_RELATIVE_OUTER_PADDING * 2)); // in px

export const BUTTON_THEME = themeFactory({
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  color: COLORS.white,
  textDecoration: 'underline',
  hover: {
    color: COLORS.amaranth
  }
});

export const CUSTOM_STYLE = {
  overlay: {
    backgroundColor: 'rgba(33, 36, 41, 0.9)',
    zIndex: 400 // overlay header
  }
};
