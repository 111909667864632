import { COLORS } from 'shared/constants/theme';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { FormattedSubscriptionOption, SubscriptionOption } from './types';

export const formatSubscriptionOptions = (
  subscriptionOptions: SubscriptionOption[]
): FormattedSubscriptionOption[] =>
  subscriptionOptions.map((option: SubscriptionOption) => ({
    code: option.code,
    description: option.description,
    price: option.price,
    isMajor: option.isMajor,
    summaryText: option.summaryText,
    summaryProrataText: option.summaryProrataText,
    image: {
      imageAlt: option.image?.filename,
      imageSrc: option.image?.link.href
    },
    modalTitle: option.modalTitle,
    modalSubtitle: option.modalSubtitle,
    modalDescription: option.modalDescription,
    modalImage: {
      imageAlt: option.modalImage?.filename,
      imageSrc: option.modalImage?.link?.href
    },
    modalLink: {
      label: option.modalLinkLabel,
      theme: buttonThemeFactory({
        color: option.modalLinkLabelColor || COLORS.black,
        backgroundColor: 'transparent'
      })
    },
    channels: (option.channels || []).map(channel => ({
      title: channel.title,
      media: {
        imageAlt: channel.media?.filename,
        imageSrc: channel.media?.link?.href
      }
    }))
  }));
