import React, { PureComponent } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import merge from 'lodash/merge';
import get from 'lodash/get';

import pxInRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import * as themes from 'shared/components/presentational/Visual/themes/index';
import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';
import Picture from 'shared/components/presentational/Picture';
import Icon from 'shared/components/presentational/Icons/Icon';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

const Wrapper = styled.div`
  position: relative;
  background-color: ${COLORS.black};
`;

const ShadowWrapper = styled.div`
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    ${props => props.theme.visual.shadowStyle};
  }
`;

const Placeholder = styled.div`
  background: ${COLORS.black};
  height: 200px;
  ${mediaQueries.toPhablet`
    height: ${props => props.theme.visual.phoneHeight};
  `};
`;

const Image = styled(Picture).attrs(props => ({
  width: parseInt(props.theme.visual.width, 10),
  height: parseInt(props.theme.visual.height, 10)
}))`
  display: block;
  object-fit: cover;
  width: 100%;
  max-width: ${props => props.theme.visual.maxWidth};
  height: ${props => props.theme.visual.height};

  ${mediaQueries.toPhablet`
    height: ${props => props.theme.visual.phoneHeight};
  `};
`;

const Title = styled.h3`
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  font-size: ${props => pxInRem(props.theme.visual.titleSize)};
  color: ${COLORS.white};
  margin-bottom: 4px;
`;

const Subtitle = styled.span`
  display: flex;
  align-items: center;
  margin: 0;
  font-family: ${FONT_FAMILIES.base};
  font-size: ${pxInRem(14)};
  color: ${COLORS.white};
`;

const ArrowIcon = styled(Icon).attrs({
  name: 'Arrow'
})`
  margin-left: 8px;
  font-size: 12px;
`;

const Infos = styled.div`
  position: absolute;
  ${props => props.theme.visual.infosPosition};
`;

const LinkWrapper = styled(CustomLink)`
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: ${props => props.theme.visual.maxWidth};
  width: 100%;
  height: 100%;

  &:hover {
    ${Title} {
      text-decoration: underline;
    }
  }
`;

export default class Visual extends PureComponent {
  static themes = {
    ...themes
  };

  render() {
    const {
      title,
      subtitle,
      imageUrl,
      linkUrl,
      theme,
      alt,
      ...props
    } = this.props;

    let safeTheme;
    if (typeof theme === 'string') {
      safeTheme = Visual.themes[theme] || Visual.themes.defaultTheme;
    } else {
      safeTheme = merge({}, Visual.themes.defaultTheme, theme);
    }
    return (
      <ThemeProvider theme={safeTheme}>
        <Wrapper {...props}>
          <LinkWrapper to={linkUrl}>
            {imageUrl ? (
              <ShadowWrapper>
                <Image alt={alt} url={imageUrl} disableLazy />
              </ShadowWrapper>
            ) : (
              <Placeholder />
            )}
            <Infos>
              {title && <Title>{title}</Title>}
              {subtitle && (
                <Subtitle>
                  {subtitle}
                  {get(safeTheme, 'visual.arrow', true) && <ArrowIcon />}
                </Subtitle>
              )}
            </Infos>
          </LinkWrapper>
        </Wrapper>
      </ThemeProvider>
    );
  }
}
