import React from 'react';

import {
  PaginationWrapper,
  PaginationButton,
  PaginationText,
  ReversedIcon
} from './styles/components';
import Icon from 'shared/components/presentational/Icons';

// as Algolia doesn't provide props types for this widget
interface Props {
  currentPage: number;
  nbPages: number;
  goToPreviousPage?: () => void;
  goToNextPage?: () => void;
}

export default function Pagination({
  currentPage,
  nbPages,
  goToPreviousPage,
  goToNextPage
}: Props) {
  return (
    <PaginationWrapper>
      <PaginationButton onClick={goToPreviousPage} disabled={!goToPreviousPage}>
        <ReversedIcon name="Chevron" width="24px" height="24px" />
      </PaginationButton>

      <PaginationText>
        {currentPage}&nbsp;sur&nbsp;{nbPages}
      </PaginationText>

      <PaginationButton onClick={goToNextPage} disabled={!goToNextPage}>
        <Icon name="Chevron" width="24px" height="24px" />
      </PaginationButton>
    </PaginationWrapper>
  );
}
