import { lighten } from 'polished';

import { COLORS } from 'shared/constants/theme';

const buttonStyle = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  color: COLORS.black,
  textDecoration: 'none'
};

export default {
  button: {
    padding: '0 0 0 0',
    normal: buttonStyle,
    disabled: buttonStyle,
    hover: {
      ...buttonStyle,
      color: lighten(0.4, COLORS.black)
    }
  }
};
