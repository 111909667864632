import { connect } from 'react-redux';
import { ReactCookieProps, withCookies } from 'react-cookie';
import omit from 'lodash/omit';
import { compose, mapProps } from 'recompose';
import { withTranslation } from 'react-i18next';

import getCurrentZone from 'shared/modules/page/selectors/getCurrentZone';
import isConnected from 'shared/helpers/auth/isConnected';
import getZoneLangs from 'shared/modules/page/selectors/getZoneLangs';
import getPageLang from 'shared/modules/page/selectors/getPageLang';
import createLoginRedirectUrl from 'shared/modules/page/helpers/createLoginRedirectUrl';
import createLogoutUrl from 'shared/modules/page/helpers/createLogoutUrl';
import Header from '../components/Header';
import { FormattedProps, PropsFromState, WithConnected } from '../types';
import getPageData from 'shared/modules/page/selectors/getPageData';
import { getDomainURL } from 'shared/modules/hostname/selectors/getDomainURL';
import getPagePath from 'shared/modules/page/selectors/getPagePath';
import getSmartFaqUuid from 'shared/modules/page/selectors/getSmartFaqUuid';
import getCompanyId from 'shared/modules/page/selectors/getCompanyId';
import getPentagramFromState from 'shared/modules/page/selectors/getPentagramFromState';
import getOnlyDomain from 'shared/modules/domain/selectors/getOnlyDomain';

const withConnected = compose(
  withCookies,
  mapProps((ownProps: PropsFromState & ReactCookieProps): PropsFromState &
    WithConnected => ({
    connected: isConnected(ownProps.allCookies || {}),
    // we don't want to pass down the allCookies object to avoid unexpected renders
    ...omit(ownProps, ['allCookies', 'cookies'])
  }))
);

const mapStateToProps = (
  state: any,
  ownProps: FormattedProps
): PropsFromState => {
  const bigram = getCurrentZone(state) as string;
  const pentagram = getPentagramFromState(state) as string;
  const pageData = getPageData(state) as object;
  const pagePath = getPagePath(state);
  const domainURL = getDomainURL(state) as string;
  const companyId = getCompanyId(state) as string;
  const smartFaqUuId = getSmartFaqUuid(state) as string;

  const loginRedirectUrl = createLoginRedirectUrl(
    domainURL,
    bigram,
    pageData,
    pagePath,
    ownProps.redirectUrlAfterLogin
  );

  const logoutUrl = createLogoutUrl(domainURL, pagePath, pageData, pentagram);

  return {
    companyId,
    smartFaqUuId,
    loginUrl: loginRedirectUrl,
    logoutUrl,
    bigram,
    langs: getZoneLangs(state),
    pageLang: getPageLang(state),
    domain: getOnlyDomain(state) ?? ''
  };
};

export default compose(
  connect(mapStateToProps),
  withConnected,
  withTranslation()
  // @ts-ignore
)(Header);
