/** Fonts imports **/
import canalLightRomainWOFF from '../fonts/canallightromain.woff';
import canalLightRomainWOFF2 from '../fonts/canallightromain.woff2';
import canalLightItalicWOFF2 from '../fonts/canallightitalic.woff2';
import canalLightItalicTTF from '../fonts/canallightitalic.ttf';
import canalDemiRomainWOFF from '../fonts/canaldemiromain.woff';
import canalDemiRomainWOFF2 from '../fonts/canaldemiromain.woff2';
import canalDemiItalicWOFF2 from '../fonts/canaldemiitalic.woff2';
import canalDemiItalicTTF from '../fonts/canaldemiitalic.ttf';
import canalBoldRomainWOFF from '../fonts/canalboldromain.woff';
import canalBoldRomainWOFF2 from '../fonts/canalboldromain.woff2';
import canalBoldItalicWOFF2 from '../fonts/canalbolditalic.woff2';
import canalBoldItalicTTF from '../fonts/canalbolditalic.ttf';
import pantonRegularItalicOTF from '../fonts/panton-regular-italic.otf';
import pantonBoldItalicOTF from '../fonts/panton-bold-italic.otf';
import pantonBlackItalicOTF from '../fonts/panton-black-italic.otf';
import orbitronRegularTTF from '../fonts/orbitron-regular.ttf';
import orbitronMediumTTF from '../fonts/orbitron-medium.ttf';
import orbitronBoldTTF from '../fonts/orbitron-bold.ttf';
import orbitronBlackTTF from '../fonts/orbitron-black.ttf';
import canalNewLightWOFF2 from '../fonts/canal-new-light.woff2';
import canalNewDemiWOFF2 from '../fonts/canal-new-demi.woff2';
import canalNewBoldWOFF2 from '../fonts/canal-new-bold.woff2';
import hindRegularWOFF2 from '../fonts/hind-regular.woff2';
import hindSemiBoldWOFF2 from '../fonts/hind-demi.woff2';
import hindBoldWOFF2 from '../fonts/hind-bold.woff2';
import montserratExtraBoldWOFF2 from '../fonts/montserrat-extra-bold.woff2';
import montserratExtraLightWOFF2 from '../fonts/montserrat-extra-light.woff2';
import montserratSemiBoldWOFF2 from '../fonts/montserrat-semi-bold.woff2';

export enum Font {
  CanalNew = 'CanalNew',
  Canal = 'Canal',
  Panton = 'Panton',
  Orbitron = 'Orbitron'
}

export const fontSwitcher = (font: Font) => {
  return `



      @font-face {
        font-family: 'Hind';
        src: url('${hindRegularWOFF2}') format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Hind';
        src: url('${hindSemiBoldWOFF2}') format('woff2');
        font-weight: 600;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Hind';
        src: url('${hindBoldWOFF2}') format('woff2');
        font-weight: 700;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Canal';
        src: url('${canalLightRomainWOFF2}') format('woff2'),
          url('${canalLightRomainWOFF}') format('woff');
        font-weight: 200;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Canal';
        src: url('${canalLightRomainWOFF2}') format('woff2'),
          url('${canalLightRomainWOFF}') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: fallback
      }
      
      @font-face {
        font-family: 'Canal';
        src: url('${canalLightItalicWOFF2}') format('woff2'),
          url('${canalLightItalicTTF}') format('truetype');
        font-weight: 300;
        font-style: italic;
        font-display: fallback
      }

      @font-face {
        font-family: 'Canal';
        src: url('${canalDemiRomainWOFF2}') format('woff2'),
          url('${canalDemiRomainWOFF}') format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Canal';
        src: url('${canalDemiItalicWOFF2}') format('woff2'),
          url('${canalDemiItalicTTF}') format('truetype');
        font-weight: 600;
        font-style: italic;
        font-display: fallback
      }

      @font-face {
        font-family: 'Canal';
        src: url('${canalBoldItalicWOFF2}') format('woff2'),
          url('${canalBoldItalicTTF}') format('truetype');
        font-weight: 700;
        font-style: italic;
        font-display: fallback
      }

      @font-face {
        font-family: 'Canal';
        src: url('${canalBoldRomainWOFF2}') format('woff2'),
          url('${canalBoldRomainWOFF}') format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: fallback
      }

      @font-face {
        font-family: 'Canal';
        src: url('${canalBoldRomainWOFF2}') format('woff2'),
          url('${canalBoldRomainWOFF}') format('woff');
        font-weight: 800;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Montserrat';
        src: url('${montserratExtraLightWOFF2}') format('woff2');
        font-weight: 200;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Montserrat';
        src: url('${montserratSemiBoldWOFF2}') format('woff2');
        font-weight: 600;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Montserrat';
        src: url('${montserratExtraBoldWOFF2}') format('woff2');
        font-weight: 800;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'CanalNew';
        src: url('${canalNewLightWOFF2}') format('woff2');
        font-weight: 200;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'CanalNew';
        src: url('${canalNewDemiWOFF2}') format('woff2');
        font-weight: 600;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'CanalNew';
        src: url('${canalNewBoldWOFF2}') format('woff2');
        font-weight: 800;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Panton';
        src: url('${pantonRegularItalicOTF}') format('opentype');
        font-weight: 400;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Panton';
        src: url('${pantonBoldItalicOTF}') format('opentype');
        font-weight: 700;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Panton';
        src: url('${pantonBlackItalicOTF}') format('opentype');
        font-weight: 900;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Panton';
        src: url('${pantonRegularItalicOTF}') format('opentype');
        font-weight: 400;
        font-style: italic;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Panton';
        src: url('${pantonBoldItalicOTF}') format('opentype');
        font-weight: 700;
        font-style: italic;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Panton';
        src: url('${pantonBlackItalicOTF}') format('opentype');
        font-weight: 900;
        font-style: italic;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Orbitron';
        src: url('${orbitronRegularTTF}') format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Orbitron';
        src: url('${orbitronMediumTTF}') format('truetype');
        font-weight: 500;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Orbitron';
        src: url('${orbitronBoldTTF}') format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: 'Orbitron';
        src: url('${orbitronBlackTTF}') format('truetype');
        font-weight: 900;
        font-style: normal;
        font-display: fallback;
      }

      :root {
        --brand-font: ${font};
      }
    `;
};
