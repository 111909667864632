import React, { useState, useCallback } from 'react';

import {
  Channel,
  ChannelWrapper,
  ContentWrapper,
  Icon,
  Item,
  Subtitle,
  Wrapper
} from './styles/component';

const Accordion = ({
  title,
  subtitle,
  channels,
  index,
  moreChannelsIcon,
  isMoreChannels
}: {
  index: number;
  isMoreChannels: boolean;
  title: string;
  subtitle: string;
  moreChannelsIcon: { label: string | null; href: string };
  channels: { label: string | null; href: string }[];
}) => {
  const [isOpen, setIsOpen] = useState(index === 0);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const renderChannels = useCallback((channel, index) => {
    return (
      <Channel
        key={`${channel?.label}-${index}`}
        alt={channel?.label}
        url={channel?.href}
      />
    );
  }, []);

  const displayChannelsList = isMoreChannels
    ? [...channels, moreChannelsIcon]
    : channels;

  return (
    <Wrapper>
      <Item
        key={index}
        onClick={handleToggle}
        aria-expanded={isOpen}
        aria-controls={`group-${index}`}
        id={`item-${index}`}
      >
        {title}
        {displayChannelsList.length > 0 && (
          <Icon
            name={`${isOpen ? 'ArrowTop' : 'ArrowBottom'}`}
            width="12px"
            height="12px"
          />
        )}
      </Item>
      <ContentWrapper
        role="region"
        id={`group-${index}`}
        aria-labelledby={`item-${index}`}
        aria-hidden={!isOpen}
      >
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <ChannelWrapper>
          {displayChannelsList.map(renderChannels)}
        </ChannelWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Accordion;
