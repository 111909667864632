import { Content, FormattedProps } from './types';

export default (content: Content): FormattedProps => ({
  title: content.title.label ?? '',
  titleColor: content.title.color,
  titleHtmlTag: content.title.htmlTag,
  buttonColor: content.button.backgroundColor ?? '',
  buttonTextColor: content.button.labelColor ?? '',
  buttonLabel: content.button.label ?? '',
  apis: {
    getEquipments: {
      url: content.apis?.getEquipments?.url ?? '',
      method: content.apis?.getEquipments?.method || 'GET'
    },
    getSubscriberData: {
      url: content.apis?.getSubscriberData?.url ?? '',
      method: content.apis?.getSubscriberData?.method || 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    }
  },
  contextCriteria: content.frontDisplayCriteria ?? null
});
