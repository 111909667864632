import getQueryParams from 'shared/modules/router/selectors/getQueryParams';

export default function getSelectedMinorOffersCodes(
  state: object
): string[] | undefined {
  const { minor } = getQueryParams(state, { arrayFormat: 'comma' });

  if (!minor) {
    return undefined;
  } else if (typeof minor === 'string') {
    return [minor];
  }

  return minor;
}
