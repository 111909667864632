import styled, { css } from 'styled-components';

import { BasePlaceholder, Props } from './Placeholder';

export default styled(BasePlaceholder)<Props>`
  :before {
    ${props =>
      props.value || props.focused
        ? css`
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0s 0.3s;
          `
        : css`
            transition: opacity 0.3s, visibility 0s 0s;
          `};
  }
`;
