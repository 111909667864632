/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { getLinkHref } from 'shared/helpers/uri';
import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';
import { isJaquette } from '../shared/helpers/isJaquette';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: content.title,
  subtitle: content.subtitle,
  jaquettes: content.jaquettes.map(jaquette => {
    return isJaquette(jaquette)
      ? {
          type: jaquette.type,
          picture: jaquette.media.link
            ? {
                image: getLinkHref(jaquette.media.link),
                alt: jaquette.media.description || jaquette.media.filename
              }
            : null,
          link: getLinkHrefWithAuthentication(jaquette.link)
        }
      : {
          type: jaquette.type,
          picture: jaquette.seeMore.icon
            ? {
                image: getLinkHref(jaquette.seeMore.icon?.link),
                alt:
                  jaquette.seeMore.icon.description ||
                  jaquette.seeMore.icon.filename
              }
            : null,
          link: jaquette.seeMore
            ? getLinkHrefWithAuthentication(jaquette.seeMore.link)
            : null,
          label: jaquette.seeMore?.label
        };
  })
});
