/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { useState, useCallback, useMemo } from 'react';

import { scrollTo } from 'shared/helpers/viewport';
import { FormattedProps, OfferCard as OfferCardProps } from '../types';
import { STEPS } from '../constants';
import {
  Wrapper,
  OffersWrapper,
  OfferCard,
  MinorOffersButtonsWrapper
} from '../styles/components';
import MinorOffer from './MinorOffer/MinorOffer';
import Button from 'shared/components/presentational/Button/Button';
import createOfferValidationLink from '../../shared/links/createOfferValidationLink';
import getInitialOptionID from '../helpers/getInitialOptionID';
import Title from 'shared/components/presentational/Title';

export default (props: FormattedProps) => {
  const {
    title,
    titleHtmlTag,
    titleColor,
    borderHoverColor,
    offers,
    selectionOfferReturnButton,
    optionSelectionButton
  } = props;

  const initialOptionID = getInitialOptionID(offers);
  const [currentOptionID, setCurrentOptionID] = useState(initialOptionID);
  const [
    subtitleHeightByOfferIndex,
    updateSubtitleHeightByOfferIndex
  ] = useState<{ [index: number]: number }>({});
  const [currentStep, setStep] = useState<STEPS>(STEPS.MAIN_OFFERS);
  const [selectedOptionCode, setSelectedOptionCode] = useState<string | null>(
    null
  );
  const [selectedMinorOffers, setSelectedMinorOffers] = useState<string[]>([]);

  const handleSubtitleResize: (
    i: number
  ) => OfferCardProps['onSubtitleResize'] = useCallback(
    i => ({ bounds }) => {
      if (bounds && bounds.height !== subtitleHeightByOfferIndex[i]) {
        updateSubtitleHeightByOfferIndex({
          ...subtitleHeightByOfferIndex,
          [i]: bounds.height
        });
      }
    },
    [updateSubtitleHeightByOfferIndex, subtitleHeightByOfferIndex]
  );

  const subtitleHeight = useMemo(
    () =>
      Object.values(subtitleHeightByOfferIndex).reduce(
        (acc, subtitleHeight: number) =>
          subtitleHeight > acc ? subtitleHeight : acc,
        0
      ),
    [subtitleHeightByOfferIndex]
  );

  const selectedOffer = useMemo(
    () =>
      offers.find(offer =>
        offer.options.some(option => option.code === selectedOptionCode)
      ),
    [offers, selectedOptionCode]
  );

  const handleBackButtonClick = useCallback(() => {
    setSelectedMinorOffers([]);
    setStep(STEPS.MAIN_OFFERS);
  }, []);

  const validationButtonLink = useMemo(() => {
    if (selectedOffer && selectedOptionCode) {
      return createOfferValidationLink(
        selectedOffer.button.link,
        selectedOptionCode,
        selectedMinorOffers
      );
    }

    return undefined;
  }, [offers, selectedOptionCode, selectedMinorOffers]);

  const handleOptionSelection = useCallback((offerCode: string) => {
    setSelectedOptionCode(offerCode);
    setStep(STEPS.MINOR_OFFERS);
    scrollTo(0);
  }, []);

  const handleMinorOfferToggle = useCallback(
    (minorOfferCode: string) => {
      if (!selectedMinorOffers.includes(minorOfferCode)) {
        setSelectedMinorOffers(state => [minorOfferCode, ...state]);
      } else {
        setSelectedMinorOffers(state =>
          state.filter(el => el !== minorOfferCode)
        );
      }
    },
    [selectedMinorOffers]
  );

  const renderedOffers = useMemo(
    () =>
      offers
        .filter(offer =>
          offer.options.some(option => option.id === currentOptionID)
        )
        .map((offer, i) => (
          <OfferCard
            key={`eshop-offer-card-${i}`}
            currentOptionID={currentOptionID}
            changeCurrentOptionID={setCurrentOptionID}
            borderHoverColor={borderHoverColor}
            onSubtitleResize={handleSubtitleResize(i)}
            subtitleHeight={subtitleHeight}
            onButtonClick={handleOptionSelection}
            {...offer}
          />
        )),
    [
      offers,
      currentOptionID,
      handleOptionSelection,
      subtitleHeight,
      handleSubtitleResize,
      borderHoverColor
    ]
  );

  const renderedMinorOffers = useMemo(
    () =>
      selectedOffer &&
      selectedOffer.minorOffers.map(minorOffer => (
        <MinorOffer
          key={minorOffer.code}
          minorOffer={minorOffer}
          toggleChecked={() => handleMinorOfferToggle(minorOffer.code)}
          checked={selectedMinorOffers.includes(minorOffer.code)}
          checkboxColor={
            optionSelectionButton.theme.button.hover.backgroundColor
          }
        />
      )),
    [offers, selectedOptionCode, selectedMinorOffers, handleMinorOfferToggle]
  );

  return (
    <Wrapper>
      <Title
        titleLabel={title}
        titleColor={titleColor}
        titleHtmlTag={titleHtmlTag}
      />

      {currentStep === STEPS.MAIN_OFFERS && (
        <OffersWrapper>{renderedOffers}</OffersWrapper>
      )}

      {currentStep === STEPS.MINOR_OFFERS && (
        <>
          <OffersWrapper>{renderedMinorOffers}</OffersWrapper>

          <MinorOffersButtonsWrapper>
            <Button
              theme={selectionOfferReturnButton.theme}
              onClick={handleBackButtonClick}
            >
              <Button.children.Text>
                {selectionOfferReturnButton.label}
              </Button.children.Text>
            </Button>

            <Button
              theme={optionSelectionButton.theme}
              to={validationButtonLink}
            >
              <Button.children.Text>
                {optionSelectionButton.label}
              </Button.children.Text>
            </Button>
          </MinorOffersButtonsWrapper>
        </>
      )}
    </Wrapper>
  );
};
