import { PropsFormatterUtils } from 'shared/types';
import { Content, FormattedProps } from './types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  template: content.template,
  title: content.title?.label ?? '',
  titleHtmlTag: content.title?.htmlTag,
  subtitle: content.subtitle ?? undefined,
  columns: content.columns || '2',
  tiles: content.tiles.map(tile => ({
    title: tile.title,
    logo: tile?.icon?.link
      ? { href: tile.icon.link.href, alt: tile.icon.description || '' }
      : null,
    description: tile.description,
    link:
      tile.link && tile.label
        ? {
            href: getLinkHrefWithAuthentication(tile.link),
            label: tile.label
          }
        : null,
    text: tile.text
  }))
});
