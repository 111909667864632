import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxToRem from 'shared/helpers/styled-components/remHelper';
import Button from 'shared/components/presentational/Button/Button';
import Icon from 'shared/components/presentational/Icons';
import { SEARCH_ENGINE_PANEL_MAX_WIDTH } from 'shared/blocks/header/components/components/SearchEngine/styles/constants';
import {
  SEARCH_HIGHLIGHT_CLASS,
  SEARCH_HIGHLIGHT_TAG
} from 'shared/blocks/header/components/components/SearchEngine/constants';
import { COLORS, FONT_WEIGHTS } from 'shared/constants/theme';
import { ellipsis } from 'shared/blocks/mea/shared/styles/mixins';
import { AlgoliaTheme, LinkColors } from '../types';
import {
  HEADER_UNIFIED_DESKTOP_OVERLAY_TOP_POSITION,
  HEADER_UNIFIED_MOBILE_OVERLAY_TOP_POSITION,
  HEADER_UNIFIED_USER_MENU_HEIGHT
} from 'shared/constants/heights';

export const SearchTrigger = styled.button<{
  isSearchOpen: boolean;
  inputWidth: number;
  userMenuElementWidth: number;
  navigationWidth: number;
  colors: LinkColors;
}>`
  cursor: pointer;
  color: ${props => props.colors.linksColor};
  transition: 250ms;
  position: absolute;
  padding: 0;
  top: ${pxToRem(6)};
  left: ${props =>
    props.isSearchOpen
      ? -props.navigationWidth + props.userMenuElementWidth + 7
      : '-20'}px;
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  z-index: 6;
  ${mediaQueries.fromTablet<{
    isSearchOpen: boolean;
    inputWidth: number;
    navigationWidth: number;
  }>`
  z-index: 9;
  top: ${pxToRem(8)};
  left: ${props => (props.isSearchOpen ? -props.inputWidth + 35 : -20)}px;
  `}
  &:hover {
    color: ${props => props.colors.linksColorSecondary};
  }
`;

export const SearchWrap = styled.div<{
  menuElementsWidth: number;
  userMenuElementWidth: number;
  isSearchOpen: boolean;
}>`
  position: absolute;
  overflow: ${props => (props.isSearchOpen ? 'visible' : 'hidden')};
  width: ${props => (props.isSearchOpen ? 'calc(100vw - 20px)' : '0')};
  margin: ${pxToRem(10)};
  top: ${pxToRem(-20)};
  right: ${props =>
    props.isSearchOpen
      ? -props.userMenuElementWidth - HEADER_UNIFIED_USER_MENU_HEIGHT / 2
      : '20'}px;

  transition: 250ms;

  transition-timing-function: ease;
  pointer-events: auto;
  z-index: 5;
  ${mediaQueries.fromTablet<{
    menuElementsWidth: number;
    isSearchOpen: boolean;
  }>`
  
  width: ${props =>
    props.isSearchOpen
      ? `calc(100vw - (${props.menuElementsWidth}px + 64px + 20px))`
      : 0};
  top: -${pxToRem(18)};
  right: -${pxToRem(20)};
  z-index: 8;
    `}
`;

export const SearchInput = styled.input<{ theme: AlgoliaTheme }>`
  box-sizing: border-box;
  height: ${pxToRem(48)};
  font-size: ${pxToRem(16)};
  width: 100%;
  padding-left: ${pxToRem(50)};
  background: ${props => props.theme.inputBackgroundColor};
  color: ${props => props.theme.link.linksColor};
  border: 0.1rem solid #3a3a3a;
  border-radius: ${pxToRem(3)};
  animation: introduceInput 250ms;
  &:focus-visible {
    outline: 1px solid ${props => props.theme.link.linksColorSecondary};
  }
  ${mediaQueries.fromTablet`
  padding-left: ${pxToRem(65)};
    `}
`;

export const CloseButton = styled(Button)<{ isSearchOpen: boolean }>`
  position: absolute;
  right: ${pxToRem(12)};
  top: ${pxToRem(12)};
  display: ${props => (props.isSearchOpen ? 'block' : 'none')};
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};
  background: #3a3a3a;
  padding: ${pxToRem(7)};
  border-radius: 50%;
  line-height: 0.5;

  ${mediaQueries.fromTablet`
  top: ${pxToRem(12)};
    `}
`;

export const SearchIcon = styled(Icon)`
  transform: scaleX(-1);
`;

export const SearchEngineWrapper = styled.div`
  position: relative;
`;

export const SearchEnginePanel = styled.div<{ color: string }>`
  color: ${props => props.color};
  overflow-y: auto;
  padding: 16px 32px;
  height: calc(100vh - 55px);

  ${mediaQueries.fromTablet`
    height: fit-content;
    padding: ${pxToRem(16)} ${pxToRem(40)};
  `}
`;

export const InnerModal = styled.div<{ backgroundColor: string }>`
  box-shadow: ${pxToRem(0)} ${pxToRem(2)} ${pxToRem(20)} rgba(0, 0, 0, 0.3);
  border-radius: ${pxToRem(0)} ${pxToRem(0)} ${pxToRem(4)} ${pxToRem(4)};
  background-color: ${props => props.backgroundColor};

  ${mediaQueries.fromTablet`
  max-height: calc(100vh - ${HEADER_UNIFIED_DESKTOP_OVERLAY_TOP_POSITION}px);
  overflow: auto;
`}
`;

export const SearchEnginePanelWidthHolder = styled.div`
  max-width: ${SEARCH_ENGINE_PANEL_MAX_WIDTH}px;
`;

export const HitsListTitle = styled.b`
  font-weight: 700;
  font-style: italic;
  font-size: ${pxToRem(16)};
`;

export const HitsListEntryTitle = styled.p`
  margin-bottom: 0;

  & > .${SEARCH_HIGHLIGHT_CLASS}, ${SEARCH_HIGHLIGHT_TAG} {
    font-weight: ${FONT_WEIGHTS.bold};
    font-style: normal;
    // reset api default style for new search engine
    background-color: unset;
    color: unset;
  }
`;

export const SeeAllResultsButton = styled(Button)`
  display: none;
  padding: 10px;
  margin-left: 14px;
  margin-top: 12px;

  ${mediaQueries.fromTablet`
    display: inline-flex;
  `}
`;

export const MobileSeeAllResultsButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 10px;
  right: 10px;
  padding: 15px 20px;
  background-color: ${COLORS.black};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;

  ${SeeAllResultsButton} {
    margin: 0;
    width: 100%;
    text-align: center;

    ${mediaQueries.toPhablet`
      display: inline-flex;
    `}
  }

  ${mediaQueries.fromTablet`
    display: none;
  `}
`;

export const RefinableSearchResultsTitle = styled.h2`
  width: 100%;
  font-size: ${pxToRem(21)};
  font-weight: ${FONT_WEIGHTS.demi};
  font-style: italic;
  white-space: nowrap;
  margin: 0 0 10px 0;
  text-transform: uppercase;
  ${ellipsis()}

  ${mediaQueries.fromTablet`
    width: calc(66.66% - 26px);
    margin: 0 0 0 26px;
    padding-left: 14px;
  `}
`;

export const MobileIndexFiltersModalTitle = styled.h1`
  width: 66.66%;
  font-size: ${pxToRem(24)};
  font-weight: ${FONT_WEIGHTS.demi};
  font-style: italic;
  text-transform: uppercase;
  margin: 0 0 24px 0;
`;

export const Overlay = styled.div<{ userMenuWidth: number }>`
  position: absolute;
  right: ${props => -props.userMenuWidth - 16}px;
  top: ${pxToRem(HEADER_UNIFIED_MOBILE_OVERLAY_TOP_POSITION)};
  height: 100vh;
  width: 100vw;
  background-color: rgba(13, 13, 13, 0.6);
  overflow: visible !important;
  z-index: 1;
  ${mediaQueries.fromTablet<{ userMenuWidth: number }>`
  right: ${props => -props.userMenuWidth - 32}px;
  top: -${pxToRem(HEADER_UNIFIED_USER_MENU_HEIGHT / 2)};
  `};

  ${mediaQueries.fromDesktop`
    z-index: 7;
  `}
`;
