import { TitleHtmlTag, Link } from 'shared/types';

export interface FormattedTileV1 {
  title?: string | null;
  logo: {
    href: string;
    alt: string;
  } | null;
  description?: string;
  link?: {
    href: string;
    label: string;
  } | null;
}

export interface FormattedTileV2 {
  title?: string | null;
  logo: {
    href: string;
    alt: string;
  } | null;
  description?: string;
  text?: string;
  link?: {
    href: string;
    label: string;
  } | null;
}

export enum TEMPLATES {
  V1 = 'V1',
  V2 = 'V2'
}

export interface FormattedTemplate1 {
  template: TEMPLATES.V1;
  title: string;
  subtitle?: string;
  titleHtmlTag: TitleHtmlTag;
  tiles: FormattedTileV1[];
  columns: string;
}

export interface FormattedTemplate2 {
  template: TEMPLATES.V2;
  title: string;
  subtitle?: string;
  titleHtmlTag: TitleHtmlTag;
  tiles: FormattedTileV2[];
  columns: string;
}

export type FormattedProps = FormattedTemplate1 | FormattedTemplate2;

export interface Content {
  template: TEMPLATES;
  columns?: string;
  title: {
    label: string | null;
    htmlTag: TitleHtmlTag;
  };
  subtitle?: string | null;
  tiles: ContentTile[];
}

export type ContentTile = {
  icon: {
    link: Link;
    description: string | null;
  };
  title?: string | null;
  description?: string;
  label?: string;
  link?: Link;
  text?: string;
};
