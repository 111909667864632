/* eslint-disable react/prop-types */
/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';
import { useCookies } from 'react-cookie';
import dayjs from 'dayjs';

import 'shared/assets/icons/icons.font';
import initDayjs from 'shared/helpers/dayjs/init';
import { init as initYup } from 'shared/helpers/yup';
import GlobalStyle from 'shared/assets/styles/main';
import PageContainer from 'shared/modules/page/containers/PageContainer';
import ErrorContainer from 'shared/modules/error/containers/ErrorContainer';
import { TIMEOUT } from 'shared/modules/http/constants';
import { COOKIE_KEYS } from './modules/cookies/constants';
import getFont from './modules/page/selectors/getFont';
import { Font, fontSwitcher } from './assets/styles/fonts';
import getPageData from './modules/page/selectors/getPageData';
import propsFormatter from './modules/page/propsFormatter';

// set up dayjs locales and needed plugins
initDayjs();

// set yup locale and additional methods
initYup();

// set axios default configuration
axios.defaults.timeout = TIMEOUT.DEFAULT;

const DEFAULT_TITLE = 'Canal +';
const TITLE_TEMPLATE = '%s';

const META = [
  {
    name: 'description',
    content: 'Site Canal +'
  },
  // Default content encoding.
  { name: 'charset', content: 'utf-8' },
  // This is important to signify your application is mobile responsive!
  {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1.0, shrink-to-fit=no'
  },
  // Providing a theme color is good if you are doing a progressive
  // web application.
  { name: 'theme-color', content: '#2b2b2b' }
];

const App = ({ store }) => {
  const [cookies] = useCookies([COOKIE_KEYS.LANG]);
  const currentLang = cookies[COOKIE_KEYS.LANG];
  const font = getFont(store.getState());
  const pageData = getPageData(store.getState());
  const data = propsFormatter(pageData);

  dayjs.locale(currentLang);

  const htmlAttributes = {
    lang: currentLang,
    'xmlns:og': 'http://ogp.me/ns#'
  };

  return (
    <div className="app">
      <Helmet
        htmlAttributes={htmlAttributes}
        titleTemplate={TITLE_TEMPLATE}
        defaultTitle={DEFAULT_TITLE}
        meta={META}
        style={[{ cssText: fontSwitcher(font ?? Font.Canal) }]}
      />

      {data && <PageContainer />}
      <ErrorContainer />
      <GlobalStyle />
    </div>
  );
};

export default App;
