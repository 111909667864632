export default function getHighlightTitle(
  highlightedWord: string,
  title: string
) {
  const newTitle = title.replace(
    highlightedWord,
    `<em class="search-highlight">${highlightedWord}</em>`
  );
  return newTitle;
}
