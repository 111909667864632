// import { KEYCODE, throttle } from '@canalplus/mycanal-commons';

import React from 'react';

import { SearchWrap } from '../styles/searchEngine';

export type HeaderSearchCoords = {
  searchFormTop: number;
  searchFormLeft: number;
  searchFormRight: number;
};

export type HeaderWithSearchLayoutProps = {
  isSearchOpen: boolean;
  inputWidth: any;
  children: React.ReactElement[];
};

const HeaderWithSearchLayout = ({
  children,
  isSearchOpen,
  inputWidth
}: HeaderWithSearchLayoutProps): JSX.Element => {
  return (
    <SearchWrap
      isSearchOpen={isSearchOpen}
      menuElementsWidth={inputWidth.menuElementsWidth}
      userMenuElementWidth={inputWidth.elUserMenu}
      role="search"
    >
      {children}
    </SearchWrap>
  );
};

HeaderWithSearchLayout.displayName = 'HeaderWithSearchLayout';

export default HeaderWithSearchLayout;
