import styled from 'styled-components';

import { FONT_WEIGHTS } from 'shared/constants/theme';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  color: var(--text-default-main);

  p {
    margin: 0;
  }
`;

export const WrapperNetPrice = styled.p<{ isSmall?: boolean }>`
  display: flex;
  gap: ${({ isSmall }) => (isSmall ? 'unset' : '4px')};
  align-items: flex-end;
  flex-wrap: wrap;
`;

export const PriceInformation = styled.p`
  font-size: var(--text-size-s);
  line-height: var(--line-height-m);
  color: var(--text-default-light);
`;

export const StrikeThroughPrice = styled.p`
  text-decoration: line-through;
  color: var(--text-default-main);
`;

export const MarketingPriceInteger = styled.span<{ isSmall?: boolean }>`
  font-size: ${({ isSmall }) =>
    isSmall ? 'var(--text-size-h2)' : 'var(--text-size-xxl)'};
  line-height: ${({ isSmall }) =>
    isSmall ? 'var(--line-height-l)' : 'var(--line-height-xxl)'};
  font-weight: ${FONT_WEIGHTS.demi};
`;

export const MarketingPriceExponent = styled.span<{ isSmall?: boolean }>`
  font-size: ${({ isSmall }) =>
    isSmall ? 'var(--text-size-s)' : 'var(--text-size-h2)'};
  line-height: ${({ isSmall }) =>
    isSmall ? 'var(--line-height-m)' : 'inherit'};
  font-weight: ${FONT_WEIGHTS.demi};
`;

export const MarketingPriceTerms = styled.div`
  display: flex;
  margin-left: 2px;
  font-size: var(--text-size-s);
  line-height: var(--line-height-base);
  color: var(--text-default-light);
`;

export const RightColumn = styled.p<{
  variant?: 'default' | 'modal' | 'selection';
}>`
  color: var(--text-default-light);
  text-align: ${({ variant }) => (variant === 'modal' ? 'right' : 'left')};
  word-break: break-all;

  ${mediaQueries.fromTablet<{ variant?: 'default' | 'modal' | 'selection' }>`
    text-align: ${({ variant }) => (variant !== 'default' ? 'right' : 'left')};
  `}
`;

export const AvailableCommitmentDurations = styled.p`
  font-size: var(--text-size-s);
  line-height: var(--line-height-base);
  font-weight: ${FONT_WEIGHTS.demi};
  color: var(--text-default-main);

  ${mediaQueries.fromTablet`
      width: 90px;
      overflow-wrap: break-word;
  `}
`;

export const PriceWrapper = styled.div`
  gap: var(--spacing-xs);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ${mediaQueries.fromTablet`
      align-items: flex-end;
      display: inline-flex;
      flex-direction: row;
      gap: var(--spacing-l);
      justify-content: space-between;
 `};
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;

export const LeftColumn = styled.div<{
  variant?: 'default' | 'modal' | 'selection';
}>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;

  justify-content: ${({ variant }) =>
    variant === 'modal' ? 'flex-end' : 'flex-start'};
  text-align: ${({ variant }) => (variant === 'modal' ? 'right' : 'left')};

  ${mediaQueries.fromTablet<{ variant?: 'default' | 'modal' | 'selection' }>`
    justify-content: ${({ variant }) =>
      variant !== 'default' ? 'flex-end' : 'flex-start'};
    text-align: ${({ variant }) => (variant !== 'default' ? 'right' : 'left')};
  `}
`;
