import { useQuery } from 'react-query';
import { AxiosRequestHeaders } from 'axios';

import { APIHttpClient } from 'shared/modules/http/HttpClient';
import { ArticleContent } from '../types';

const useArticleContentQuery = (
  url: string | undefined,
  {
    miniSiteDomain,
    uniqueId,
    lang
  }: {
    miniSiteDomain?: string;
    uniqueId?: string;
    lang?: string;
  }
) => {
  const enabled = !!url && !!miniSiteDomain && !!lang;

  return useQuery<ArticleContent, string>(
    url!,
    async () => {
      const headers: AxiosRequestHeaders = {
        'X-UNIQUE-ID': uniqueId!,
        'X-MINISITE-DOMAIN': miniSiteDomain!
      };

      if (lang) {
        headers['X-LANG'] = lang;
      }

      return APIHttpClient.get(url!, {
        headers,
        validateStatus: status => status >= 200 && status < 300
      }).then(({ data }) => data);
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};

export default useArticleContentQuery;
