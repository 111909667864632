/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';
import { COLORS } from 'shared/constants/theme';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: get(content, 'title.label', ''),
  titleColor: get(content, 'title.color', COLORS.black),
  titleHtmlTag: get(content, 'title.htmlTag'),
  subtitle: content.subtitle ?? undefined,
  slider: content.slider.map(slide => ({
    mediaDesktop: slide.mediaDesktop,
    mediaTablet: slide.mediaTablet,
    mediaMobile: slide.mediaMobile,
    link: slide.link ? getLinkHrefWithAuthentication(slide.link) : undefined,
    alt: slide.mediaDesktop.description ?? slide.mediaDesktop.filename
  }))
});
