import get from 'lodash/get';

import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { COLORS } from 'shared/constants/theme';
import { formatSubscriptionOptions } from '../shared/propsFormatter';

export const getFormattedApis = (content: Content): FormattedProps['apis'] => ({
  getAvailableProposals: {
    url: content.apis?.get_available_proposals?.url ?? '',
    method: content.apis?.get_available_proposals?.method || 'GET'
  },
  getSubscriberData: {
    url: content.apis?.getSubscriberData?.url ?? '',
    method: content.apis?.getSubscriberData?.method ?? 'GET',
    parameters: content.apis?.getSubscriberData?.parameters || {}
  }
});

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: get(content, 'title.label', ''),
  titleColor: get(content, 'title.color'),
  titleHtmlTag: get(content, 'title.htmlTag'),
  subtitle: content.subtitle ?? undefined,
  subscriptionOptions: formatSubscriptionOptions(content.subscriptionOptions),
  subscriptionButton: {
    label: content.buttons?.subscriptionButton?.label,
    theme: buttonThemeFactory({
      color: content.buttons?.subscriptionButton?.labelColor || COLORS.white,
      backgroundColor:
        content.buttons?.subscriptionButton?.backgroundColor || COLORS.amaranth
    }),
    link: getLinkHrefWithAuthentication(
      content.buttons?.subscriptionButton.link ?? ''
    )
  },
  optionsListButton: {
    label: content.buttons?.optionsListButton?.label,
    theme: buttonThemeFactory({
      color: content.buttons?.optionsListButton?.labelColor || COLORS.white,
      backgroundColor:
        content.buttons?.optionsListButton?.backgroundColor || COLORS.amaranth
    }),
    link: getLinkHrefWithAuthentication(
      content.buttons?.optionsListButton?.link ?? ''
    )
  },
  apis: getFormattedApis(content),
  contextCriteria: content.frontDisplayCriteria ?? null
});
