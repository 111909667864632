import { connect } from 'react-redux';

import getHash from 'shared/modules/router/selectors/getHash';
import Picture from '../components/Picture';

const mapStateToProps = (state: object) => {
  const hash = getHash(state);
  const anchor = hash.replace('#', '');
  const hasAnchor = Boolean(anchor); // always false on SSR

  return {
    hasAnchor
  };
};

const PictureContainer = connect(mapStateToProps)(Picture);

export default PictureContainer;
