import get from 'lodash/get';
import has from 'lodash/has';

import buttonThemeFactory from '../../components/presentational/Button/helpers/themeFactory';
import { Content, OffersProps, Offer, Channel } from './types';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): OffersProps => ({
  template: content.template,
  title: get(content, 'title.label', ''),
  titleColor: get(content, 'title.color', ''),
  titleHtmlTag: get(content, 'title.htmlTag'),
  subtitle: content.subtitle ?? undefined,
  backgroundColor: content.background_color,
  imageUrl: has(content, 'media.link.href')
    ? get(content, 'media.link.href')
    : null,
  banner: content.banner
    ? {
        imageUrl: get(content, 'banner.media.link.href'),
        text: get(content, 'banner.text'),
        backgroundColor: get(content, 'banner.background_color'),
        buttonText: get(content, 'banner.button_label'),
        buttonUrl: getLinkHrefWithAuthentication(
          get(content, 'banner.link', {})
        ),
        buttonTheme: buttonThemeFactory({
          color: get(content, 'banner.button_label_color'),
          backgroundColor: get(content, 'banner.button_color')
        })
      }
    : null,
  offers: (get(content, 'offers', []) as Offer[]).map(offer => ({
    title: offer.title,
    subtitle: offer.subtitle,
    titleColor: offer.titleColor,
    text: offer.text,
    isLabelPremium: offer.isLabelPremium,
    isStar: offer.isStar,
    buttonUrl: getLinkHrefWithAuthentication(get(offer, 'link', '')),
    channels: (get(offer, 'channels', []) as Channel[]).map(channelRoot => ({
      title: channelRoot.channel.title,
      imageUrl: get(channelRoot, 'channel.media.link.href'),
      exponent: channelRoot.exponent
    })),
    category: get(offer, 'category', null)
  }))
});
