import React from 'react';

import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import setCookie from 'shared/helpers/universal-cookie/setCookie';
import clientCookies from 'client/cookies';
import Button from 'shared/components/presentational/Button';
import {
  LangItemsWrapper,
  LangLabel,
  LangsContent,
  OverLay,
  StyledButton,
  StyledDropdownIndicator,
  StyledToggleButton,
  SwitchLangLabel,
  SwitchLangWrapper
} from '../styles/components';
import { OwnProps, Props } from '../containers/LanguageSwitcher';
import {
  useHandleModalsOpening,
  useHandleModalsOpeningDispatch
} from '../providers/ModalsOpeningProvider';
import { HandleModalsOpeningType } from '../reducers/ModalsOpeningReducer';
import { Lang } from 'shared/types';

export const LanguageSwitcher = ({
  langs,
  pageLang,
  languageSwitcher: { link, backgroundColorModals, theme, label },
  domain
}: Props & OwnProps) => {
  const onLangSectionChanged = React.useCallback((lang: string) => {
    setCookie(clientCookies, COOKIE_KEYS.LANG, lang, { domain });
  }, []);

  const dispatch = useHandleModalsOpeningDispatch();
  const { isLanguageSwitcherOpened } = useHandleModalsOpening();

  const toggleIsOpen = React.useCallback(() => {
    dispatch({
      type: isLanguageSwitcherOpened
        ? HandleModalsOpeningType.CLOSE_LANGUAGE_SWITCHER
        : HandleModalsOpeningType.OPEN_LANGUAGE_SWITCHER
    });
  }, [isLanguageSwitcherOpened, dispatch]);

  const hasAtLeastTwoLang = langs && Object.keys(langs).length > 1;
  const handleClick = React.useCallback(
    value => {
      onLangSectionChanged(value);

      dispatch({
        type: HandleModalsOpeningType.CLOSE_LANGUAGE_SWITCHER
      });
    },
    [dispatch, onLangSectionChanged]
  );
  const renderLangItem = React.useCallback(
    ([value, { label }]: [string, Lang]) => {
      return (
        <StyledButton
          key={value}
          onClick={() => handleClick(value)}
          theme={theme}
        >
          <LangLabel>{label}</LangLabel>
        </StyledButton>
      );
    },
    [theme, handleClick]
  );

  if (hasAtLeastTwoLang) {
    return (
      <SwitchLangWrapper>
        <>
          <StyledToggleButton
            isLangsMenuOpen={isLanguageSwitcherOpened}
            link={link}
            onClick={toggleIsOpen}
          >
            <Button.children.Text>
              {pageLang.toUpperCase()}
            </Button.children.Text>
            <StyledDropdownIndicator isOpen={isLanguageSwitcherOpened} />
          </StyledToggleButton>
          {isLanguageSwitcherOpened ? (
            <>
              <OverLay onClick={toggleIsOpen} />
              <LangsContent link={link} backgroundColor={backgroundColorModals}>
                <SwitchLangLabel>{label}</SwitchLangLabel>
                <LangItemsWrapper>
                  {Object.entries(langs).map(renderLangItem)}
                </LangItemsWrapper>
              </LangsContent>
            </>
          ) : null}
        </>
      </SwitchLangWrapper>
    );
  }
  return null;
};
