import { GeographicalAreaResponse } from '../types';

export default (response: GeographicalAreaResponse) => {
  const dedupedPostalCodes = Object.keys(
    response.data.reduce(
      (acc, result) => ({ ...acc, [result.postalCode]: result.postalCode }),
      {}
    )
  );

  return dedupedPostalCodes.map(c => ({ label: c, value: c }));
};
