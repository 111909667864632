import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 66 96" width={width} height={height} className={className}>
    <path
      d="M54 96c6.6 0 12-5.4 12-12V12c0-6.6-5.4-12-12-12H12C5.4 0 0 5.4 0 12v72c0 6.6 5.4 12 12 12h42zM42 84.8c0 1.2-1 2.2-2.2 2.2H26.2C25 87 24 86 24 84.8v-4.5c0-1.2 1-2.2 2.2-2.2h13.5c1.2 0 2.2 1 2.2 2.2v4.5h.1zM12 12h42v57H12V12z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
