/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

export default content => ({
  url: get(content, 'api.url', ''),
  timezone: content.timeZone,
  vectorSat: get(content, 'api.parameters.vectorSat', ''),
  vector: get(content, 'api.parameters.vector', ''),
  type: get(content, 'api.parameters.type', ''),
  id: get(content, 'api.parameters.id', ''),
  isNew: get(content, 'api.isNew', false)
});
