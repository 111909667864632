import Accordion from './Accordion';
import Item from './AccordionItem';
import Header from './AccordionHeader';
import Content from './AccordionContent';
import Handle from './AccordionHandle';

Accordion.Item = Item;
Accordion.Header = Header;
Accordion.Content = Content;
Accordion.Handle = Handle;

export default Accordion;
