/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: content.title,
  subtitle: content.subtitle,
  zone: content.currentZone,
  backButton:
    content.backButton.label &&
    getLinkHrefWithAuthentication(content.backButton.link)
      ? {
          label: content.backButton.label,
          link: getLinkHrefWithAuthentication(content.backButton.link)
        }
      : null,
  submitButton:
    content.validButton.label &&
    getLinkHrefWithAuthentication(content.validButton.link)
      ? {
          label: content.validButton.label,
          link: getLinkHrefWithAuthentication(content.validButton.link)
        }
      : null
});
