import React, { PureComponent } from 'react';
import { number, shape, string, node } from 'prop-types';

import RatingCSAIcons from 'shared/components/presentational/RatingCSAIcons';
import Diffusions from 'shared/components/presentational/ProgramDetail/core/Diffusions';
import Labels from 'shared/components/presentational/ProgramDetail/core/Labels';
import Reviews from 'shared/components/presentational/ProgramDetail/core/Reviews';
import {
  Wrapper,
  Content,
  GlobalInfo,
  Author,
  Prefix,
  Personalities,
  Summary,
  Title,
  CoverWrapper
} from 'shared/components/presentational/ProgramDetail/ProgramDetail.styled';

const authorInfoShape = shape({
  content: string.isRequired,
  prefix: string.isRequired
});

class ProgramDetail extends PureComponent {
  static propTypes = {
    title: string.isRequired,
    firstSubtitleA: string.isRequired,
    firstSubtitleB: string.isRequired,
    secondSubtitle: string.isRequired,
    ratingCSA: number,
    authorInfo: authorInfoShape,
    personnalitiesInfo: authorInfoShape,
    diffusions: Diffusions.propTypes.diffusions,
    labels: Labels.propTypes.labels,
    reviews: Reviews.propTypes.reviews,
    summary: string,
    timezone: string,
    coverComponent: node
  };

  render() {
    const {
      title,
      ratingCSA,
      summary,
      labels,
      reviews,
      diffusions,
      firstSubtitleA,
      firstSubtitleB,
      secondSubtitle,
      authorInfo,
      personnalitiesInfo,
      timezone,
      coverComponent,
      t
    } = this.props;

    return (
      <Wrapper>
        {coverComponent && <CoverWrapper>{coverComponent}</CoverWrapper>}
        <Content>
          <Title>{title}</Title>
          <GlobalInfo>
            <span>{firstSubtitleA}</span>
            {ratingCSA && <RatingCSAIcons rating={ratingCSA} />}
            <span className="infoBold">{firstSubtitleB}</span>
            <br />
            <span>{secondSubtitle}</span>
          </GlobalInfo>
          {labels && <Labels labels={labels} t={t} />}
          {authorInfo && (
            <Author>
              <Prefix>{authorInfo.prefix}&nbsp;</Prefix>
              <span>{authorInfo.content}</span>
            </Author>
          )}
          {personnalitiesInfo && (
            <Personalities>
              <Prefix>{personnalitiesInfo.prefix}&nbsp;</Prefix>
              <span>{personnalitiesInfo.content}</span>
            </Personalities>
          )}
          {summary && <Summary>{summary} </Summary>}
          {reviews && <Reviews reviews={reviews} />}
          {diffusions && diffusions.length > 0 && timezone && (
            <Diffusions diffusions={diffusions} timezone={timezone} t={t} />
          )}
        </Content>
      </Wrapper>
    );
  }
}

export default ProgramDetail;
