import styled from 'styled-components';

import { COLORS, KEYFRAMES } from 'shared/constants/theme';
import { SPINNER_SIZE } from './constants';

export const Wrapper = styled.div`
  display: block;
  position: relative;
  width: ${SPINNER_SIZE}px;
  height: ${SPINNER_SIZE}px;
`;

export const Circle = styled.div`
  display: block;
  border-radius: 50%;
  border-style: solid;
  animation: ${KEYFRAMES.rotate} 1s linear infinite;
  width: ${SPINNER_SIZE}px;
  height: ${SPINNER_SIZE}px;
  border-width: 5px;
  border-color: ${COLORS.amaranth} transparent ${COLORS.amaranth}
    ${COLORS.amaranth};
`;
