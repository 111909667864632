import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 36 36" width={width} height={height} className={className}>
    <path
      d="M18 .3C8.3.3.4 7.6.4 16.7c0 5.1 2.6 9.7 6.5 12.7v6.2l6-3.3c1.6.4 3.3.7 5 .7 9.7 0 17.6-7.3 17.6-16.4C35.5 7.5 27.7.3 18 .3zm1.7 22.1l-4.5-4.8-8.7 4.8 9.6-10.3 4.6 4.8 8.6-4.8-9.6 10.3z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
