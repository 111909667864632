import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Raw from 'shared/components/presentational/Raw/Raw';
import Accordion from 'shared/components/presentational/Accordion';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Icon from 'shared/components/presentational/Icons/Icon';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import List from './ChannelsList';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  user-select: none;
  max-width: 1000px;
  margin: 0 auto;
  ${mediaQueries.toTablet`
    margin-left: 15px;
    margin-right: 15px;
  `};
  ${mediaQueries.toPhablet`
    margin-left: 7px;
    margin-right: 7px;
  `};
`;

const Title = styled(Raw)`
  text-align: center;
  margin: 0 15px 15px;
  font-family: ${FONT_FAMILIES.base};
  text-transform: uppercase;
  line-height: ${pxInRem(24)};
  font-size: ${pxInRem(24)};
  font-weight: ${FONT_WEIGHTS.bold};
  ${mediaQueries.toPhablet`
    line-height: ${pxInRem(15)};
    font-size: ${pxInRem(13)};
  `};
`;

const Subtitle = styled(Raw)`
  text-align: center;
  font-family: ${FONT_FAMILIES.base};
  margin: 0 15px 40px;
  font-size: ${pxInRem(14)};
  line-height: ${pxInRem(18)};
  ${mediaQueries.toPhablet`
    margin-bottom: 25px;
    line-height: ${pxInRem(16.5)};
    font-size: ${pxInRem(13)};
  `};
`;

const StyledAccordionItem = styled(Accordion.Item)`
  background-color: ${COLORS.concrete};
  margin-bottom: 10px;
`;

const StyledAccordionHeader = styled(Accordion.Header)`
  line-height: ${pxInRem(14)};
  font-size: ${pxInRem(14)};
  padding: 30px 40px 25px;
  font-weight: ${FONT_WEIGHTS.bold};
  position: relative;
  ${mediaQueries.toPhablet`
        padding: 20px 25px 15px;
        font-size: ${pxInRem(13)};
      `};
`;

const StyledAccordionContent = styled(Accordion.Content)`
  padding-bottom: 5px;
`;

const StyledAccordionHandle = styled(Accordion.Handle)`
  overflow: hidden;
  position: absolute;
  right: 35px;
  top: 0;
  bottom: 0;
  width: 20px;
  ${mediaQueries.toPhablet &&
  `
    right: 20px;
  `};
`;

StyledAccordionItem.displayName = 'Accordion.Item';
StyledAccordionHeader.displayName = 'Accordion.Header';
StyledAccordionContent.displayName = 'Accordion.Content';
StyledAccordionHandle.displayName = 'Accordion.Handle';

class Channels extends PureComponent {
  render() {
    const { title, subtitle, groups } = this.props;

    return (
      <Container>
        {title && <Title html={title} />}
        {subtitle && <Subtitle html={subtitle} />}

        <Accordion forcedOpenItems={[0, 1, 2]}>
          {groups.map((group, index) => (
            <StyledAccordionItem key={`Channels_accordionItem${index}`}>
              <StyledAccordionHeader>
                <span>{group.title}</span>&nbsp;
                {group.exponent && <sup>{group.exponent}</sup>}
                <StyledAccordionHandle>
                  <Icon
                    name="ArrowBottom"
                    width={10}
                    height={10}
                    color={COLORS.black}
                  />
                </StyledAccordionHandle>
              </StyledAccordionHeader>
              <StyledAccordionContent>
                <List channels={group.channels} />
              </StyledAccordionContent>
            </StyledAccordionItem>
          ))}
        </Accordion>
      </Container>
    );
  }
}

export default Channels;
