import reverse from 'lodash/reverse';

import { ProgramParams } from './types';

// This helpers aims to deduce the type and the id of a program using its EISMO url.
// Output params can be used to create the link to program details page.

// we expect the url to be the following format:
// http://service.canal-overseas.com/ott-frontend/vector/{vecteurSat}/{type}/{id}

export default (apiUrl: string): ProgramParams => {
  const urlSlices = apiUrl.split('/');
  const [id, type] = reverse(urlSlices);
  return { id, type };
};
