/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/sort-comp */
import React from 'react';
import Modal from 'react-modal';

import Button from 'shared/components/presentational/Button';
import { BaseModalProps, InnerModalProps } from './types';
import {
  OuterWrapper,
  InnerWrapper,
  CloseButtonWrapper,
  CloseButton
} from './styles/components';
import { BUTTON_THEME, CUSTOM_STYLE } from './styles/constants';

function InnerModal(props: InnerModalProps) {
  const { children, onCloseRequest, className } = props;
  return (
    <OuterWrapper className={className}>
      {onCloseRequest && (
        <CloseButtonWrapper>
          <CloseButton theme={BUTTON_THEME} onClick={onCloseRequest}>
            <Button.children.SvgIcon
              width="18"
              height="18"
              xlink="/sprite.svg#cos-svg-close"
            />
          </CloseButton>
        </CloseButtonWrapper>
      )}
      <InnerWrapper>{children}</InnerWrapper>
    </OuterWrapper>
  );
}

const AlternativeModal = ({
  children,
  modalProps = {},
  className
}: BaseModalProps) => (
  <Modal
    style={CUSTOM_STYLE}
    bodyOpenClassName="preventScroll"
    {...modalProps}
    ariaHideApp={false}
  >
    <InnerModal
      onCloseRequest={modalProps.onCloseRequest}
      className={className}
    >
      {children}
    </InnerModal>
  </Modal>
);

export default AlternativeModal;
