import React, { PureComponent } from 'react';

import Raw from 'shared/components/presentational/Raw/Raw';

export interface Props {
  markup: string;
}

export default class Html extends PureComponent<Props> {
  public render(): React.ReactNode {
    const { markup } = this.props;

    if (!markup) {
      return false;
    }

    return <Raw html={markup} />;
  }
}
