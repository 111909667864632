import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 26 24" width={width} height={height} className={className}>
    <path
      d="M26 12c0 .6-.4 1-1 1H9.1c-.4 1.2-1.6 2.1-2.9 2.1-1.3 0-2.5-.9-2.9-2.1H1c-.6 0-1-.4-1-1s.4-1 1-1h2.2c.4-1.2 1.6-2.1 2.9-2.1 1.3 0 2.5.9 2.9 2.1h16c.6 0 1 .4 1 1zm-1 7.5h-9.1c-.4-1.2-1.6-2.1-2.9-2.1-1.3 0-2.5.9-2.9 2.1H1c-.6 0-1 .4-1 1s.4 1 1 1h9.1c.4 1.2 1.6 2.1 2.9 2.1 1.3 0 2.5-.9 2.9-2.1H25c.6 0 1-.4 1-1s-.4-1-1-1zM1 4.5h15.9c.4 1.2 1.6 2.1 2.9 2.1 1.3 0 2.5-.9 2.9-2.1H25c.6 0 1-.4 1-1s-.4-1-1-1h-2.2C22.4 1.3 21.2.4 19.9.4c-1.3 0-2.5.9-2.9 2.1H1c-.6 0-1 .4-1 1s.4 1 1 1z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
