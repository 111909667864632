/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { ACTION_TYPES } from '../actions';

// -----------------------------------------------------------------------------
// EXPORTED REDUCER STATE TYPE
/*
export type State = {
  MEAProgramsByBlockId: {},
  MEAProgramsByCategory: {},
  categories: {}
};
*/

// -----------------------------------------------------------------------------
// PRIVATES

const defaultState = {
  MEAProgramsByBlockId: {},
  MEAProgramsByCategory: {},
  categories: {}
};

// -----------------------------------------------------------------------------
// REDUCER

export default function programMEA(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PROGRAMS_BY_BLOCK:
      return {
        ...state,
        MEAProgramsByBlockId: {
          ...state.MEAProgramsByBlockId,
          [action.payload.id]: action.payload.formattedPrograms
        }
      };
    case ACTION_TYPES.FETCH_PROGRAMS_BY_CATEGORY:
      return {
        ...state,
        MEAProgramsByCategory: {
          ...state.MEAProgramsByCategory,
          [action.payload.category]: action.payload.formattedPrograms
        }
      };
    case ACTION_TYPES.FETCH_PROGRAMS_CATEGORIES:
      return {
        ...state,
        categories: action.payload.formattedCategories
      };
    default:
      return state;
  }
}
