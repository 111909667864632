import { css } from 'styled-components';

import { COLORS } from 'shared/constants/theme';

export default {
  visual: {
    width: '990px',
    maxWidth: 'none',
    height: '390px',
    phoneHeight: '390px',
    shadowStyle: css`
      box-shadow: 0 -120px 120px -60px ${COLORS.black} inset;
    `,
    infosPosition: css`
      left: 25px;
      bottom: 25px;
    `,
    titleSize: 18
  }
};
