export const THUMBOR_SERVER = 'thumb.canalplus.pro';

export const BANNER_THUMBOR_FILTER = 100;

export const LABEL_BREAKPOINTS = {
  RETINA: 'retina',
  HD: 'hd',
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  PHABLET: 'phablet',
  PHONE: 'phone',
  PHONE_MINI: 'phoneMini'
};

export const BREAKPOINTS = {
  RETINA: 2880,
  HD: 1920,
  DESKTOP: 1200,
  TABLET: 992,
  PHABLET: 767,
  PHONE: 480,
  PHONE_MINI: 320
};

const breakpoints = {
  [LABEL_BREAKPOINTS.PHONE_MINI]: BREAKPOINTS.PHONE_MINI,
  [LABEL_BREAKPOINTS.PHONE]: BREAKPOINTS.PHONE,
  [LABEL_BREAKPOINTS.PHABLET]: BREAKPOINTS.PHABLET,
  [LABEL_BREAKPOINTS.TABLET]: BREAKPOINTS.TABLET,
  [LABEL_BREAKPOINTS.DESKTOP]: BREAKPOINTS.DESKTOP,
  [LABEL_BREAKPOINTS.HD]: BREAKPOINTS.HD,
  [LABEL_BREAKPOINTS.RETINA]: BREAKPOINTS.RETINA
};

export default breakpoints;
