import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Button from 'shared/components/presentational/Button';
import theme from './themes/button.white';
import { COLORS } from 'shared/constants/theme';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 75px;
  width: 100%;
  background-color: ${COLORS.white};
`;

const RotatedSvgIcon = styled(Button.children.SvgIcon)`
  transform: rotate(-90deg);
`;

interface Props {
  className?: string;
  url: string;
  title?: string;
  onClick?: () => void;
}

export default class CanalBoxLink extends PureComponent<Props> {
  public render(): React.ReactNode {
    const { className, url, title, onClick } = this.props;

    return (
      <Wrapper className={className}>
        <Button to={url} onClick={onClick} theme={theme}>
          {title && <Button.children.Text>{title}</Button.children.Text>}
          <RotatedSvgIcon
            xlink="/sprite.svg#cos-svg-menu"
            width="15"
            height="15"
          />
        </Button>
      </Wrapper>
    );
  }
}
