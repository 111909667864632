import { COLORS } from 'shared/constants/theme';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import getEvents from 'shared/blocks/selfcare/event-list/helpers/getEvents';
import { PropsFormatterUtils } from 'shared/types';
import { Content, FormattedProps } from './types';

export const getFormattedApis = (content: Content): FormattedProps['apis'] => ({
  getAvailableEvents: {
    url: content.apis?.['old_selfcare.get_available_events']?.url ?? '',
    method: content.apis?.['old_selfcare.get_available_events']?.method || 'GET'
  },
  getSubscriberData: {
    url: content.apis?.getSubscriberData?.url ?? '',
    method: content.apis?.getSubscriberData?.method || 'GET',
    parameters: content.apis?.getSubscriberData?.parameters || {}
  }
});

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => {
  const basketFallbackUrl = getLinkHrefWithAuthentication(
    content.links?.basket ?? ''
  );

  const events = getEvents(
    content.events,
    basketFallbackUrl,
    getLinkHrefWithAuthentication
  );

  return {
    events,
    eventDetailsUrl: getLinkHrefWithAuthentication(
      content.links.eventDetails ?? ''
    ),
    buttonLabel: content.buttons?.displayMoreButton.label ?? '',
    buttonTheme: buttonThemeFactory({
      backgroundColor:
        content.buttons?.displayMoreButton?.backgroundColor || COLORS.carnation,
      color: content.buttons?.displayMoreButton?.labelColor || COLORS.white,
      padding: '0 40px'
    }),
    apis: getFormattedApis(content),
    contextCriteria: content.frontDisplayCriteria ?? null
  };
};
