import { connect } from 'react-redux';

import { Authentication } from '../types';
import { DropdownMenu } from '../components/DropdownMenu/DropdownMenu';
import { getDomainURL } from 'shared/modules/hostname/selectors/getDomainURL';
import createLoginRedirectUrl from 'shared/modules/page/helpers/createLoginRedirectUrl';
import createLogoutUrl from 'shared/modules/page/helpers/createLogoutUrl';
import getCurrentZone from 'shared/modules/page/selectors/getCurrentZone';
import getPageData from 'shared/modules/page/selectors/getPageData';
import getPagePath from 'shared/modules/page/selectors/getPagePath';
import getPentagramFromState from 'shared/modules/page/selectors/getPentagramFromState';

export interface Props {
  authentication: {
    loginLink: string;
    logoutLink: string;
  };
}

export interface OwnProps {
  authentication: Authentication;
}

const mapStateToProps = (state: any, ownProps: OwnProps): Props => {
  const bigram = getCurrentZone(state) as string;
  const pentagram = getPentagramFromState(state) as string;
  const pageData = getPageData(state) as object;
  const pagePath = getPagePath(state);
  const domainURL = getDomainURL(state) as string;

  const loginRedirectUrl = createLoginRedirectUrl(
    domainURL,
    bigram,
    pageData,
    pagePath,
    ownProps.authentication.login.redirectLinkAfterLogin
  );

  const logoutLink = createLogoutUrl(domainURL, pagePath, pageData, pentagram);

  return {
    ...ownProps,
    authentication: {
      loginLink: ownProps.authentication.login.isCustomLink
        ? ownProps.authentication.login.link
        : loginRedirectUrl,
      logoutLink: ownProps.authentication.logout.isCustomLink
        ? ownProps.authentication.logout.link
        : logoutLink
    }
  };
};

export default connect(mapStateToProps)(DropdownMenu);
