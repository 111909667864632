import styled from 'styled-components';
import { Field } from 'formik';

import BaseRaw from 'shared/components/presentational/Raw/Raw';
import Button from 'shared/components/presentational/Button';
import RadioGroup from 'shared/components/presentational/Form/Fields/RadioGroupField';
import BaseRadioField from 'shared/components/presentational/Form/Fields/RadioField';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { mediaQueries } from 'shared/helpers/styled-components/mediaQueries';
import { FONT_FAMILIES } from 'shared/constants/theme';
import { container } from 'shared/constants/mixins';
import { FieldWrapper } from '../../../shared/styles/components';

export const Wrapper = styled.section`
  ${container}
`;

export const Form = styled.form``;

export const Subtitle = styled(BaseRaw)<{ color?: string }>`
  font-size: ${pxInRem(14)};
  font-family: ${FONT_FAMILIES.brand};
  margin: 0 0 32px 8px;
  margin-left: 8px;
  color: ${props => props.color ?? 'inherit'};

  ${mediaQueries.fromTablet`
    margin-bottom: 48px;
  `}
`;

export const RadiosWrapper = styled(RadioGroup)`
  > fieldset {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const RadioField = styled(Field).attrs({ component: BaseRadioField })`
  margin-right: 32px;
`;

export const CheckboxFieldWrapper = styled(FieldWrapper)`
  font-size: ${pxInRem(18)};

  label {
    display: flex;
    align-items: center;
  }

  p {
    margin-bottom: 0;
  }
`;

export const SubmitButtonWrapper = styled.div`
  margin: 30px 0 80px 0;
  text-align: center;

  ${mediaQueries.fromTablet`
    margin-top: 48px;
  `}
`;

export const SubmitButton = Button;

export const TitleWrapper = styled.div`
  display: block;
  margin-bottom: 12px;
`;

export const PhoneAndEmailNote = styled.div`
  font-style: italic;
  font-size: ${pxInRem(14)};
  font-family: ${FONT_FAMILIES.brand};
  margin-bottom: 8px;

  ${mediaQueries.fromTablet`
    font-size: ${pxInRem(16)};
  `}
`;
