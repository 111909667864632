import React from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
}

export default ({ className, width = '1em', height = '1em' }: Props) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 13 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75557 0.900098C3.75711 0.900098 1.32638 3.4073 1.32638 6.5001C1.32638 9.59289 3.75711 12.1001 6.75557 12.1001C9.75403 12.1001 12.1848 9.59289 12.1848 6.5001C12.1848 3.4073 9.75403 0.900098 6.75557 0.900098ZM0.550781 6.5001C0.550781 2.96548 3.32876 0.100098 6.75557 0.100098C10.1824 0.100098 12.9604 2.96548 12.9604 6.5001C12.9604 10.0347 10.1824 12.9001 6.75557 12.9001C3.32876 12.9001 0.550781 10.0347 0.550781 6.5001Z"
    />
    <path
      d="M6.34679 9.75173H7.17125V5.71813H6.34679V9.75013V9.75173ZM6.75475 4.48933C6.43743 4.49112 6.17808 4.22862 6.17383 3.90133C6.17381 3.74067 6.23627 3.58671 6.34721 3.47393C6.45815 3.36115 6.60831 3.29898 6.76406 3.30133C7.0805 3.30133 7.34498 3.56453 7.34498 3.89093C7.34458 4.05132 7.28195 4.20487 7.17105 4.3173C7.06015 4.42973 6.91023 4.49169 6.75475 4.48933Z"
      fill="currentColor"
    />
  </svg>
);
