import React from 'react';

import { FormattedProps } from '../types';
import {
  Image,
  WrapperBlock,
  WrapperImageAndDescription,
  Button,
  WrapperTitleAndContent,
  WrapperContent,
  WrapperContentTitle,
  ContentTitle,
  Description
} from '../styles/components';
import Title from 'shared/components/presentational/Title';

const Contact = ({
  button,
  icon,
  content,
  header: { subtitle, title }
}: FormattedProps) => {
  return (
    <WrapperBlock data-testid="oneShop.contact">
      <Title
        subtitle={subtitle}
        titleHtmlTag={title?.htmlTag}
        titleLabel={title?.label}
      />
      <WrapperTitleAndContent>
        <WrapperContent>
          <WrapperImageAndDescription>
            {icon ? <Image src={icon.image} alt={icon.alt} /> : null}
            <WrapperContentTitle>
              <ContentTitle>{content.title}</ContentTitle>
              <Description>{content.text}</Description>
            </WrapperContentTitle>
          </WrapperImageAndDescription>
          <Button label={button.label} link={button.link} />
        </WrapperContent>
      </WrapperTitleAndContent>
    </WrapperBlock>
  );
};

export default Contact;
