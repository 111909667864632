import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Raw from 'shared/components/presentational/Raw/Raw';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

const Wrapper = styled.section`
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 0 !important;

  ${mediaQueries.toTablet`
    max-width: 100%;
 `};
`;

const Title = styled(Raw)`
  text-transform: uppercase;
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${pxInRem(27)};
  line-height: ${pxInRem(40)};
  margin: 20px;
  word-break: break-word;

  ${mediaQueries.toPhablet`
    font-size: ${pxInRem(20)};
    line-height: ${pxInRem(26)};
    margin: 25px;
    margin-bottom: 10px;
 `};
`;
const SubTitle = styled(Raw)`
  font-size: ${pxInRem(14)};
  font-family: ${FONT_FAMILIES.base};
  line-height: ${pxInRem(20)};
  margin: 20px;
  word-break: break-word;
  ${mediaQueries.toPhablet`
    line-height: ${pxInRem(16)};
    margin: 25px;
    margin-top: 10px;
 `};
`;

export default class SubHeader extends PureComponent {
  render() {
    const { title, subtitle } = this.props;
    return (
      <Wrapper>
        <Title html={title} />
        {subtitle && <SubTitle html={subtitle} />}
      </Wrapper>
    );
  }
}
