/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';

/* eslint-disable @typescript-eslint/no-unused-vars */
export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({});
