import { useState, useEffect } from 'react';

/**
 * Values according to media queries.
 *
 * @param queries - Ordered from the most specific to the least specific
 * @param values
 * @param defaultValue
 */
export default function useMedia<T>(
  queries: string[],
  values: Partial<T[]>,
  defaultValue: T
): T {
  // Array containing a media query list for each query
  let mediaQueryLists: MediaQueryList[] = [];

  try {
    mediaQueryLists = queries.map(q => window.matchMedia(q));
  } catch {
    // can't test queries in SSR
  }

  // Function that gets value based on matching media query
  const getValue = (): T => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    // Get value for matching media query
    const matchingValue = values[index];
    // Return related value or defaultValue if none
    if (typeof matchingValue !== 'undefined') {
      return matchingValue;
    }
    return defaultValue;
  };

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useEffect(() => {
    // Event listener callback
    // Note: By defining getValue outside of useEffect we ensure that it has ...
    // ... current values of hook args (as this hook callback is created once on mount).
    const handler = () => setValue(getValue);
    // Set a listener for each media query with above handler as callback.
    mediaQueryLists.forEach(mql => mql.addListener(handler));
    // Remove listeners on cleanup
    return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
  }, []); // Empty array ensures effect is only run on mount and unmount

  return value;
}
