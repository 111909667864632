import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { Content, FormattedProps } from './types';
import { formatSubscriptionOptions } from '../shared/propsFormatter';
import { PropsFormatterUtils } from 'shared/types';

export const getFormattedApis = (content: Content): FormattedProps['apis'] => ({
  getAvailableProposals: {
    url: content?.apis?.get_available_proposals?.url ?? '',
    method: content?.apis?.get_available_proposals?.method || 'GET'
  },
  retrieveOffersByProposals: {
    url: content?.apis?.retrieve_offers_by_proposals?.url ?? '',
    method: content?.apis?.retrieve_offers_by_proposals?.method || 'POST'
  },
  getSubscriptionAndOptions: {
    url: content?.apis?.get_subscription_and_options?.url ?? '',
    method: content?.apis?.get_subscription_and_options?.method || 'GET'
  },
  getSubscriberData: {
    url: content.apis?.getSubscriberData?.url ?? '',
    method: content.apis?.getSubscriberData?.method ?? 'GET',
    parameters: content.apis?.getSubscriberData?.parameters || {}
  }
});

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: content?.title?.label || '',
  titleColor: content?.title?.color || '',
  titleHtmlTag: content?.title?.htmlTag || '',
  subtitle: content?.subtitle || undefined,
  subscriptionOptions: formatSubscriptionOptions(
    content?.subscriptionOptions || []
  ),
  subscriptionButton: {
    label: content?.buttons?.subscriptionButton?.label ?? '',
    theme: themeFactory({
      backgroundColor: content.buttons?.subscriptionButton?.backgroundColor,
      color: content?.buttons?.subscriptionButton?.labelColor
    }),
    url: getLinkHrefWithAuthentication(
      content?.buttons?.subscriptionButton?.link ?? ''
    )
  },
  apis: getFormattedApis(content),
  contextCriteria: content.frontDisplayCriteria ?? null
});
