import get from 'lodash/get';

import { Content } from 'shared/blocks/dynamic/offer/back-link/types';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
) => ({
  label: content.label,
  link: getLinkHrefWithAuthentication(get(content, 'link', ''))
});
