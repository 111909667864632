import get from 'lodash/get';

import { getFilterWithOptions } from './index';

export default state => {
  const filtersInReducer = get(state, 'shops.shared.filters', {});
  return Object.keys(filtersInReducer).map(key =>
    getFilterWithOptions(key)(state)
  );
};
