export interface Content {
  apis: {
    smartfaq: {
      url: string;
    };
  };
}

export default (content: Content) => {
  return {
    apiUrl: content.apis.smartfaq.url
  };
};
