/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { COLORS } from 'shared/constants/theme';
import { formatLineBreak } from 'shared/helpers/text';
import { Content, FormattedProps } from './types';
import { TitleHtmlTag } from 'shared/types';

export default (content: Content): FormattedProps => {
  return {
    title: content.title.label ?? '',
    titleColor: content.title.color ?? '',
    titleHtmlTag: content.title.htmlTag ?? TitleHtmlTag.h2,
    presentations: content.presentations.map(presentation => ({
      title: presentation.title ?? '',
      titleColor: presentation.titleColor ?? COLORS.black,
      text: formatLineBreak(presentation.text ?? ''),
      imageUrl: presentation.media.link.href ?? '',
      imageAlt: presentation.media.description ?? presentation.media.filename
    }))
  };
};
