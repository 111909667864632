import React, { PureComponent } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: ${props => (props.isOpened ? 'block' : 'none')};
`;

export default class Content extends PureComponent {
  static displayName = 'Accordion.Content';

  render() {
    const { children, className, isOpened } = this.props;

    return (
      <Wrapper className={className} isOpened={isOpened}>
        {children}
      </Wrapper>
    );
  }
}
