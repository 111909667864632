import get from 'lodash/get';
import has from 'lodash/has';

import { PropsFormatterUtils } from 'shared/types';
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { COLORS } from 'shared/constants/theme';
import { Content, FormattedProps } from 'shared/blocks/presentation/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => {
  return {
    authenticated: content.authenticated,
    template: content.template,
    backgroundColor: get(content, 'background', COLORS.white),
    title: get(content, 'title.label'),
    titleColor: get(content, 'title.color'),
    titleHtmlTag: get(content, 'title.htmlTag'),
    text: content.text,
    legalNotices: has(content, 'legalNotices')
      ? {
          title: get(content, 'legalNotices.title', ''),
          notice: get(content, 'legalNotices.text', '')
        }
      : undefined,
    buttonText: get(content, 'button.label', ''),
    buttonUrl: getLinkHrefWithAuthentication(get(content, 'button.link', null)),
    imageURL: get(content, 'media.link.href', ''),
    alt: content.media.description ?? content.media.filename,
    theme: themeFactory({
      backgroundColor: get(content, 'button.color', COLORS.black),
      borderColor: get(content, 'button.color', COLORS.black),
      color: get(content, 'button.labelColor', COLORS.white)
    })
  };
};
