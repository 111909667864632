import { GeographicalAreaResponse } from '../types';

export default (response: GeographicalAreaResponse) => {
  const dedupedCities = Object.keys(
    response.data.reduce(
      (acc, result) => ({ ...acc, [result.city]: result.city }),
      {}
    )
  );

  return dedupedCities.map(c => ({ label: c, value: c }));
};
