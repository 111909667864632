/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

// eslint-disable import/prefer-default-export
import { push } from 'redux-first-history';

import {
  getPageNumberForShopId,
  getHashForShopId,
  getPageNumberForSelectedShop
} from '../selectors';

export const TYPES = {
  SELECT_SHOP: 'SHOPS_MAP_SELECT',
  CHANGE_PAGE: 'SHOPS_MAP_CHANGE_PAGE'
};

export const changePageAction = page => ({
  type: TYPES.CHANGE_PAGE,
  payload: {
    page
  }
});

export const selectShop = id => (dispatch, getState) => {
  const state = getState();
  dispatch(push(getHashForShopId(id)(state)));
  dispatch(changePageAction(getPageNumberForShopId(id)(state)));
};

export const changePage = page => (dispatch, getState) => {
  if (page) {
    dispatch(changePageAction(page));
  } else {
    // if page is not given, try to set current page according to selected shop
    const state = getState();
    dispatch(changePageAction(getPageNumberForSelectedShop(state)));
  }
};
