import React, { useCallback } from 'react';

import { useTabs } from '../Tabs/providers/TabsProvider';
import { Wrapper } from './styles/components';

export const Tab = ({
  title,
  index,
  isCurrentTab
}: {
  title: string;
  index: number;
  isCurrentTab: boolean;
}) => {
  const { setCurrentTab } = useTabs();

  const onclick = useCallback(
    function onclick() {
      setCurrentTab(index);
    },
    [setCurrentTab, index]
  );

  return (
    <li>
      <Wrapper isCurrentTab={isCurrentTab} onClick={onclick}>
        {title}
      </Wrapper>
    </li>
  );
};
