/**
 * This task aims to keep synchronized zone information between the url and the cookie.
 *
 * @param location
 * @param store
 * @param request
 */
import { replace } from 'redux-first-history';

import getAvailableZones from 'shared/modules/page/selectors/getAvailableZones';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import clientCookies from 'client/cookies';
import setCookieHelper from 'shared/helpers/universal-cookie/setCookie';
import clearCookieHelper from 'shared/helpers/universal-cookie/clearCookie';
import { isMonoZone } from 'shared/helpers/zones';
import prependBigramToPathname from 'shared/helpers/uri/prependBigramToPathname';
import removeBigramFromUrl from 'shared/helpers/uri/removeBigramFromUrl';
import getBigramFromUrl from 'shared/helpers/uri/getBigramFromUrl';
import getPentagramFromState from 'shared/modules/page/selectors/getPentagramFromState';

type isRedirectionNeeded = boolean;

const handleZone = (
  pathname: string,
  store: any,
  request?: any,
  response?: any
): isRedirectionNeeded => {
  const getCookie = (name: string) => {
    try {
      return request.universalCookies.get(name);
    } catch (e) {
      return clientCookies.get(name);
    }
  };

  const setCookie = (name: string, value: string) => {
    try {
      setCookieHelper(request.universalCookies, name, value);
    } catch (e) {
      setCookieHelper(clientCookies, name, value);
    }
  };

  const clearCookie = (name: string) => {
    try {
      clearCookieHelper(request.universalCookies, name);
    } catch (e) {
      clearCookieHelper(clientCookies, name);
    }
  };

  const redirect = (url: string) => {
    try {
      response.redirect(url);
    } catch (e) {
      store.dispatch(replace(url));
    }
  };

  const state = store.getState();
  const pentagram = getPentagramFromState(state);
  const availableZones = getAvailableZones(state);
  const defaultZone = availableZones[0];
  const isZoneValid = (zone: string) =>
    availableZones.includes(zone.toLowerCase());

  const bigram = getBigramFromUrl(pathname);

  const isoCountryCode: string | undefined =
    getCookie(COOKIE_KEYS.ZONE) || undefined;

  const isSiteMonoZone = isMonoZone(availableZones);

  if (pentagram) {
    setCookie(COOKIE_KEYS.OFFER_ZONE, pentagram);
  }

  if (bigram) {
    if (isZoneValid(bigram)) {
      setCookie(COOKIE_KEYS.ZONE, bigram.toUpperCase());
      return false;
    } else {
      redirect(removeBigramFromUrl(pathname));
      return true;
    }
  }

  if (isSiteMonoZone) {
    if (defaultZone) {
      setCookie(COOKIE_KEYS.ZONE, defaultZone.toLocaleUpperCase());
    }
    return false;
  }

  if (isoCountryCode) {
    if (isZoneValid(isoCountryCode)) {
      redirect(prependBigramToPathname(isoCountryCode, pathname));
      return true;
    } else {
      clearCookie(COOKIE_KEYS.ZONE);
      return false;
    }
  }
  return false;
};

export default handleZone;
