/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import Tabs from '../components/Tabs';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: any) => ({
  pushURLHandler: (url: string) => {
    dispatch(push({ ...location, pathname: url }));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
