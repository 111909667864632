import styled from 'styled-components';
import { darken } from 'polished/lib/index';

import Button from 'shared/components/presentational/Button/Button';
import { BUTTON_SIZES } from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';

const FormButton = styled(Button)`
  height: ${BUTTON_SIZES.form}px;
  font-size: ${pxInRem(16)};
  padding: 20px 40px;

  ${props => `
    &:focus {
      background-color: ${darken(
        0.2,
        props.theme.button.hover.backgroundColor ??
          props.defaultTheme.button.hover.backgroundColor
      )};
      border-color: ${darken(
        0.2,
        props.theme.button.hover.backgroundColor ??
          props.defaultTheme.button.hover.backgroundColor
      )};
    }
  `}
`;

export default FormButton;
