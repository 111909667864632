import get from 'lodash/get';

import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';

export default (content, { getLinkHrefWithAuthentication }) => ({
  theme: themeFactory({
    backgroundColor: content.backgroundColor,
    borderColor: content.backgroundColor,
    color: content.labelColor
  }),
  label: content.label,
  href: getLinkHrefWithAuthentication(get(content, 'link', ''))
});
