import cs from 'classnames';
import React, { PureComponent, ReactNode } from 'react';

import FieldHint from '../FieldHint';
import { HintProps } from '../Hint';

interface Props {
  id: string;
  label?: string;
  value?: string;
  error?: any;
  touched?: boolean;
  className?: string;
}

export default class RadioGroupField extends PureComponent<HintProps & Props> {
  public render(): ReactNode {
    const {
      value,
      error,
      touched,
      label,
      className,
      children,
      isRequired,
      hint
    } = this.props;

    const classes = cs(
      'input-field',
      {
        'is-success': value || (!error && touched), // handle prefilled or user-filled
        'is-error': !!error && touched
      },
      className
    );

    return (
      <div className={classes}>
        <fieldset>
          {label && <legend>{label}</legend>}
          {children}
          {touched && (
            <FieldHint error={error} hint={hint} isRequired={isRequired} />
          )}
        </fieldset>
      </div>
    );
  }
}
