import { StringSchema } from 'yup';

export default function forbiddenCharacters(
  this: StringSchema,
  msg: string
): StringSchema {
  const specialCharRegex = /(!|@|\?|&|"|#|%)+/;

  return this.test({
    name: 'forbiddenCharacters',
    message: msg || 'common.form.validation.forbiddenCharacters',
    test: value => !value || !value.match(specialCharRegex)
  });
}
