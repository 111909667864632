import axios from 'axios';

import { WILDCARD_PATH } from 'shared/blocks/smart-faq/constants';

export default async (url: string, zone: string, referrerUrl: string) => {
  const responseType = 'text';
  const method = 'get';
  const params = {
    ___zone: zone,
    ___base_url: `${referrerUrl}/${WILDCARD_PATH}/`
  };

  return axios({ method, responseType, url, params })
    .then(res => res.data)
    .catch(e => e);
};
