import React from 'react';

import { throttle } from '../helper/throttle';

export enum HeaderSearchPhase {
  CLOSED, // Search input is not rendered
  POSITION_INITIAL, // Search input positioned around search trigger
  POSITION_EXPANDED, // Search input stretches horizontally to determined points
  CLOSING // Search input returns to the initial position as the css fades it out
}

interface SearchInputWidth {
  inputWidth: number;
  searchTriggerWidth: number;
  navigationWidth: number;
  menuElementsWidth: number;
  elUserMenu: number;
}

const inputWidthDefaultValue = {
  inputWidth: 0,
  searchTriggerWidth: 0,
  navigationWidth: 0,
  menuElementsWidth: 0,
  elUserMenu: 0
};

interface Refs {
  searchTriggerRef: React.RefObject<HTMLElement>;
  userMenuRef: React.RefObject<HTMLElement>;
  menuParentRef: React.RefObject<HTMLElement>;
  logoRef: React.RefObject<HTMLElement>;
}

export const useSearchInputWidth = (isSearchOpen: boolean, refs: Refs) => {
  const [inputWidth, setInputWidth] = React.useState<SearchInputWidth>(
    inputWidthDefaultValue
  );

  const updateCoords = React.useCallback(() => {
    setInputWidth({
      searchTriggerWidth: refs.searchTriggerRef?.current?.offsetWidth ?? 0,
      navigationWidth: refs.menuParentRef?.current?.offsetWidth ?? 0,
      inputWidth:
        (refs.searchTriggerRef?.current?.offsetWidth ?? 0) +
        (refs.menuParentRef?.current?.offsetWidth ?? 0),
      menuElementsWidth:
        (refs.logoRef?.current?.offsetWidth ?? 0) +
        (refs.userMenuRef?.current?.offsetWidth ?? 0),
      elUserMenu: refs.userMenuRef?.current?.offsetWidth ?? 0
    });
  }, [
    refs?.searchTriggerRef?.current,
    refs?.menuParentRef?.current,
    refs?.logoRef?.current,
    refs?.userMenuRef?.current
  ]);

  React.useEffect(() => {
    if (isSearchOpen) {
      const updateCoordsThrottled = throttle(updateCoords, 16);
      window.addEventListener('resize', updateCoordsThrottled);
      return () => window.removeEventListener('resize', updateCoordsThrottled);
    }
    return;
  }, [isSearchOpen, updateCoords]);

  React.useEffect(() => {
    updateCoords();
  }, [isSearchOpen, updateCoords]);

  return { inputWidth };
};
