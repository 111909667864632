import React, { useCallback, useState } from 'react';

import { Buttons } from './Buttons/Buttons';
import { FormattedProps } from '../types';
import { Propal } from './Propal/Propal';
import { Titles } from './Titles/Titles';
import { Wrapper, Propals, Form, WrapperTop } from '../styles/components';

export const PropalSelection = ({
  description,
  nextButton,
  previousButton,
  propals,
  title,
  offerLabel
}: FormattedProps) => {
  const [selectedPropalId, setSelectedPropalId] = useState(
    propals[0].product.productId
  );

  const renderPropal = useCallback(
    (propal, index) => (
      <Propal
        key={`${index}-${propal.title}`}
        propal={propal}
        selectedPropalId={selectedPropalId}
      />
    ),
    [selectedPropalId]
  );

  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);

    const productId = formData.get('product')?.toString() ?? '';
    setSelectedPropalId(productId);
  };

  return (
    <Wrapper>
      <Form
        onChange={handleChange}
        action={nextButton?.link || undefined}
        aria-label="form"
      >
        <WrapperTop>
          <Titles
            title={title}
            offerLabel={offerLabel}
            description={description}
          />
          <Propals>{propals.map(renderPropal)}</Propals>
        </WrapperTop>
        <Buttons nextButton={nextButton} previousButton={previousButton} />
      </Form>
    </Wrapper>
  );
};
