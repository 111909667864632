import { css, SimpleInterpolation, ThemedCssFunction } from 'styled-components';
import upperFirst from 'lodash/upperFirst';

import breakpoints, { Device } from 'shared/constants/breakpoints';
import { pxSum } from './pxHelper';

// Each breakpoint defines 3 media queries, for example:
// "fromDesktop": which aims all devices larger than desktop (including desktop)
// "desktop": which aims desktop devices only
// "toDesktop": which aims all devices smaller than desktop (including desktop)

// phoneMini only have "toPhoneMini" media query as very is no smaller device

type KeysMediaQueries =
  | 'hd'
  | 'desktop'
  | 'tablet'
  | 'phone'
  | 'phablet'
  | 'phoneMini'
  | 'toDesktop'
  | 'toHd'
  | 'toTablet'
  | 'toPhablet'
  | 'toPhone'
  | 'toPhoneMini'
  | 'fromHd'
  | 'fromDesktop'
  | 'fromTablet'
  | 'fromPhablet'
  | 'fromPhone';

type MediaQueries = Record<KeysMediaQueries, ThemedCssFunction<object>>;

const mediaQuery = (minWidth: string | null, maxWidth?: string) => (
  strings: TemplateStringsArray,
  ...interpolations: SimpleInterpolation[]
) => {
  if (minWidth && maxWidth) {
    // @ts-ignore
    // prettier-ignore
    return css`@media (min-width:${minWidth}) and (max-width:${maxWidth}){${css(strings, ...interpolations)};}`;
  } else if (minWidth) {
    // @ts-ignore
    // prettier-ignore
    return css`@media (min-width:${minWidth}){${css(strings, ...interpolations)};}`;
  }
  // @ts-ignore
  // prettier-ignore
  return css`@media (max-width:${maxWidth!}){${css(strings, ...interpolations)};}`;
};

export const getPreviousBreakpoint = (breakpoint: Device) => {
  const breakpointsArray = Object.keys(breakpoints).reverse();
  const index = breakpointsArray.indexOf(breakpoint);
  if (index > 0) {
    return breakpointsArray[index - 1];
  }
  return null;
};

export const getLowerBreakpointWidth = (breakpoint: Device) => {
  const previousBreakpointWidth =
    // @ts-ignore
    breakpoints[getPreviousBreakpoint(breakpoint)];
  return previousBreakpointWidth ? pxSum(previousBreakpointWidth, '1px') : null;
};

// @ts-ignore
export const mediaQueries: MediaQueries = Object.keys(breakpoints).reduce(
  // @ts-ignore
  (mediaQueries: Partial<MediaQueries>, breakpoint: Device) => {
    const lowerWidth = getLowerBreakpointWidth(breakpoint);
    // @ts-ignore
    const higherWidth = breakpoints[breakpoint];
    return Object.assign(
      mediaQueries,
      { [`to${upperFirst(breakpoint)}`]: mediaQuery(null, higherWidth) },
      lowerWidth && {
        [breakpoint]: mediaQuery(lowerWidth, higherWidth),
        [`from${upperFirst(breakpoint)}`]: mediaQuery(lowerWidth)
      }
    );
  },
  {}
);

export const customMediaQuery = mediaQuery;

export default mediaQueries;
