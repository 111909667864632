/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
export default {
  SAVE_FORM: 'SAVE_FORM',
  CLEAR_FORM: 'CLEAR_FORM',
  CLEAR_FORMS: 'CLEAR_FORMS'
};
