/*  LocationTasks  */
import { Store } from 'redux';

import tvGridLocationTasks from 'shared/blocks/tv-guide/locationTasks';
import programPackVodLegacyLocationTasks from 'shared/blocks/program-pack/vod-legacy/locationTasks';
import programMEALocationTasks from 'shared/blocks/program-mea/default/locationTasks';
import programMEACategoriesLocationTasks from 'shared/blocks/program-mea/categories/locationTasks';
import selfcareLocationTasks from 'shared/blocks/selfcare/shared/locationTasks';
import VodLegacyFolderLocationTasks from 'shared/blocks/vod/legacy/folder/locationTasks';
import VodLegacyDetailLocationTasks from 'shared/blocks/vod/legacy/detail/locationTasks';
import VodLegacyCatalogLocationTasks from 'shared/blocks/vod/legacy/catalog/locationTasks';
import vodHodorLocationTasks from 'shared/blocks/vod/hodor/locationTasks';
import faqLocationTasks from 'shared/blocks/smart-faq/locationTasks';
import { Task } from './types';

/**
 * Task routes allow us to execute various tasks based on the current location.
 * This is handy for things like prefetching of data on the server.  Please
 * see the README.md for more information.
 *
 * Note: if your route tasks collection starts to get large consider using
 * a memoize function, such as the one available from lodash to avoid
 * unnecessary creation of the collection.
 */
function locationTasks(store: Store): Task[] {
  return ([] as Task[]).concat(
    tvGridLocationTasks(store),
    programPackVodLegacyLocationTasks(store),
    programMEALocationTasks(store),
    programMEACategoriesLocationTasks(store),
    selfcareLocationTasks(store),
    VodLegacyFolderLocationTasks(store),
    VodLegacyDetailLocationTasks(store),
    VodLegacyCatalogLocationTasks(store),
    vodHodorLocationTasks(store),
    faqLocationTasks(store)
  );
}

export default locationTasks;
