import getLinkHref from './getLinkHref';
import { Rel } from 'shared/types';
import prependBigramToPathname from 'shared/helpers/uri/prependBigramToPathname';

export default function addBigramToUrl(bigram: string, href: string) {
  const hrefWithProtocol = getLinkHref({ rel: Rel.external, href });
  const url = new URL(hrefWithProtocol);

  url.pathname = prependBigramToPathname(bigram, url.pathname);

  return url.toString();
}
