/* eslint-disable global-require */

import React from 'react';
import { hydrate } from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { withSSR } from 'react-i18next';
import { CookiesProvider } from 'react-cookie';
import { RefContextProvider } from 'react-use-shared-ref';
import { persistStore } from 'redux-persist';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';
import set from 'lodash/set';
import 'allsettled-polyfill';
import { loadableReady } from '@loadable/component';

import App from 'shared/App';
import configureStore from 'shared/redux/configureStore';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import TasksExecutor from './components/TasksExecutor';
import checkEncryptedData from './actions/checkEncryptedData';
import clientCookies from './cookies';
import queryClient from './queryClient';

const dehydratedState = window.__REACT_QUERY_STATE__;

/**
 * Passing initial translations / initial language down to client
 */
const AppWithI18n = withSSR()(App);

/**
 * Create our Redux store client side
 * Server side rendering would have mounted our state on this global.
 */

const { store, history } = configureStore({
  initialState: window.APP_STATE,
  history: createBrowserHistory()
});

/**
 * Start actions
 */
store.dispatch(checkEncryptedData);

/**
 *  Sync persisted store with redux store
 */
const persistor = persistStore(store);

/**
 * Get initial language from client cookies
 */
const initialLanguage = clientCookies.get(COOKIE_KEYS.LANG);

/**
 * Expose cookies in a passJSON global variable for tracking tool to take them into account.
 */
window.passJSON = clientCookies.getAll();

clientCookies.addChangeListener(({ name, value }) => {
  set(window.passJSON, name, value);
});

/**
 * Render App
 */
persistor.subscribe(() => {
  // https://stackoverflow.com/questions/53173999/using-redux-with-redux-persist-with-server-side-rendering
  const { bootstrapped } = persistor.getState();

  if (bootstrapped) {
    loadableReady(() => {
      hydrate(
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={dehydratedState}>
              <CookiesProvider cookies={clientCookies}>
                <RefContextProvider>
                  <Router history={history}>
                    <TasksExecutor store={store}>
                      <AppWithI18n
                        store={store}
                        initialI18nStore={window.APP_INITIAL_I18N_STORE}
                        initialLanguage={initialLanguage}
                      />
                    </TasksExecutor>
                  </Router>
                </RefContextProvider>
              </CookiesProvider>
            </Hydrate>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Provider>,
        document.querySelector('#root')
      );
    });
  }
});

if (module.hot) {
  module.hot.accept();
}
