import styled from 'styled-components';

/*
 * Main wrapper should be a section
 * in order to inherit margins
 */
export default styled.section`
  display: block;
  width: 100%;
`;
