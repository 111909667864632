import get from 'lodash/get';

import { orderZones } from 'shared/helpers/zones';
import { COLORS } from 'shared/constants/theme';
import { PropsFormatterUtils, Rel } from 'shared/types';
import { Content, FormattedProps, Menu } from './types';

const handleMenusLink = (
  menus: Menu[][],
  getLinkHrefWithAuthentication: PropsFormatterUtils['getLinkHrefWithAuthentication']
) =>
  menus.map((menu: Menu[]) =>
    menu.map((item: Menu) => ({
      label: item.title,
      link: getLinkHrefWithAuthentication(get(item, 'link', null)),
      isCookie: item?.link?.rel === Rel.cookies
    }))
  );

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => {
  return {
    zones: orderZones(get(content, 'geolocation.zones', [])),
    columns: handleMenusLink(
      get(content, 'menus', [[]]),
      getLinkHrefWithAuthentication
    ),
    socialNetworkLinks: Object.keys(content.socialNetworks).reduce(
      (acc, name: string) => ({
        ...acc,
        [name]: getLinkHrefWithAuthentication(
          get(content, `socialNetworks.${name}.link`, '')
        )
      }),
      {}
    ),
    backgroundColor: get(content, 'backgroundColor', COLORS.black),
    textColor: get(content, 'textColor', COLORS.white),
    hoverColor: get(content, 'hoverColor', COLORS.cerulean)
  };
};
