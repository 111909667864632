import get from 'lodash/get';
import merge from 'lodash/merge';

import * as availableThemes from '../themes';

const themes: any = availableThemes;

const getSafeTheme = (theme: any, defaultTheme: any) => {
  if (typeof theme === 'string') {
    return themes[theme] || defaultTheme;
  }

  if (
    !get(theme, 'button.normal.backgroundColor') ||
    !get(theme, 'button.hover.backgroundColor') ||
    !get(theme, 'button.disabled.backgroundColor')
  ) {
    return defaultTheme;
  }

  return merge({}, defaultTheme, theme);
};

export default getSafeTheme;
