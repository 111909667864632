import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import { FormDataToEncrypt } from '../../shared/types';
import OfferSubscriptionFormConnectable from '../components/OfferSubscriptionFormConnectable';
import saveOfferSubscriptionForm from '../../shared/actions/saveOfferSubscriptionForm';
import retrieveOfferSubscriptionForm from '../actions/retrieveOfferSubscriptionForm';
import getCurrentZone from 'shared/modules/page/selectors/getCurrentZone';
import { createTemplate } from 'shared/helpers/template';
import { FormattedProps, PropsFromState, PropsFromDispatch } from '../types';

const mapStateToProps = (
  state: any,
  ownProps: FormattedProps
): PropsFromState => {
  const bigram = getCurrentZone(state);

  return {
    crmGeographicalAreaApi: {
      ...ownProps.crmGeographicalAreaApi,
      url: createTemplate(ownProps.crmGeographicalAreaApi.url)({ bigram })
    }
  };
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: FormattedProps
): PropsFromDispatch => ({
  retrieveOfferSubscriptionForm: () => dispatch(retrieveOfferSubscriptionForm),
  onSave: (formDataToEncrypt: FormDataToEncrypt) =>
    dispatch(saveOfferSubscriptionForm(formDataToEncrypt)),
  onSubmit: () => {
    dispatch(push({ ...location, pathname: ownProps.confirmationButtonLink }));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferSubscriptionFormConnectable);
