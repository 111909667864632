/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

export const getFormattedApis = content => ({
  folderUrl: content.apis['legacy_vod.folder'].url
});

export default (content, { getLinkHrefWithAuthentication }) => ({
  ...getFormattedApis(content),
  detailUrl: getLinkHrefWithAuthentication(content.links.detail)
});
