import React, { PureComponent } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import TabPanels from './TabPanels';
import TabList from './TabList';

const Wrapper = styled.div`
  text-align: center;
`;

class Tabs extends PureComponent {
  state = {
    activeIndex: 0
  };

  displayActivePanel = activeIndex => {
    this.setState({ activeIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const children = React.Children.map(this.props.children, child => {
      if (get(child, 'type.displayName') === TabPanels.displayName) {
        return React.cloneElement(child, {
          activeIndex
        });
      } else if (get(child, 'type.displayName') === TabList.displayName) {
        return React.cloneElement(child, {
          activeIndex,
          onActivate: this.displayActivePanel
        });
      }
      return child;
    });
    return <Wrapper>{children}</Wrapper>;
  }
}

export default Tabs;
