import React from 'react';

import { Wrapper, Circle } from './styles/components';

export default function Spinner() {
  return (
    <Wrapper>
      <Circle />
    </Wrapper>
  );
}
