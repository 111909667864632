import themeFactory from '../../../components/presentational/Button/helpers/themeFactory';
import { Content, FormattedProps } from './types';

export default (content: Content): FormattedProps => ({
  title: content.title.label ?? '',
  titleColor: content.title.color,
  titleHtmlTag: content.title.htmlTag,
  buttonTheme: themeFactory({
    color: content.buttons?.displayMoreButton?.labelColor ?? '',
    backgroundColor: content.buttons?.displayMoreButton?.backgroundColor || ''
  }),
  buttonLabel: content.buttons?.displayMoreButton?.label ?? '',
  apis: {
    getSubscriberData: {
      url: content.apis?.getSubscriberData?.url ?? '',
      method: content.apis?.getSubscriberData?.method || 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    },
    getPaymentsHistory: {
      url: content.apis?.['selfcare.accouting.get_payments_hist']?.url ?? '',
      method:
        content.apis?.['selfcare.accouting.get_payments_hist']?.method || 'GET'
    }
  },
  contextCriteria: content.frontDisplayCriteria ?? null
});
