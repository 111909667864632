/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2019 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
export enum TEMPLATE_VISUAL {
  LEFT = 'visualleft',
  RIGHT = 'visualright',
  CENTRAL = 'visualcentral',
  CENTRAL_LARGE = 'visualcentrallarge'
}
