import isArray from 'lodash/isArray';

import getQueryParams from 'shared/modules/router/selectors/getQueryParams';

export default (state: any) => {
  const token = getQueryParams(state).token;

  if (!token || isArray(token)) {
    return null;
  }
  return token;
};
