import clientCookies from 'client/cookies';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import {
  HEADERS,
  AUTHENTICATION_HEADER_VALUES
} from 'shared/modules/http/constants';

const retrieveHeadersFromCookies = () => {
  const tokenPass = clientCookies.get(COOKIE_KEYS.TOKEN_PASS);
  const numSubscriber = clientCookies.get(COOKIE_KEYS.NUM_SUBSCRIBER);
  const subscriberId = clientCookies.get(COOKIE_KEYS.SUBSCRIBER_ID);
  const offerZone = clientCookies.get(COOKIE_KEYS.OFFER_ZONE);
  const isoCountryCode = clientCookies.get(COOKIE_KEYS.ZONE);
  const authenticationIsExternal =
    clientCookies.get(COOKIE_KEYS.EXTERNAL_AUTH) === 'true';
  return {
    [HEADERS.TOKEN_PASS]: tokenPass,
    [HEADERS.NUM_SUBSCRIBER]: numSubscriber,
    [HEADERS.OFFER_ZONE]: offerZone,
    [HEADERS.SUBSCRIBER_ID]: subscriberId,
    [HEADERS.ISO_COUNTRY_CODE]: isoCountryCode,
    [HEADERS.AUTHENTICATION]: authenticationIsExternal
      ? AUTHENTICATION_HEADER_VALUES.external
      : AUTHENTICATION_HEADER_VALUES.internal
  };
};

export default retrieveHeadersFromCookies;
