/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';

import Container from 'shared/components/presentational/Container/Container';
import {
  Wrapper,
  ButtonContainer,
  Button
} from '../../shared/styles/components';
import Title from 'shared/components/presentational/Title';
import { FormattedProps, Template } from '../types';
import SliderOrList from 'shared/components/presentational/SliderOrList/SliderOrList';
import Slide from 'shared/components/presentational/Slider/Slide/Slide';
import ResponsiveHoc, {
  ResponsiveHocProps
} from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';

const slidesToShowByTemplate = {
  [Template.TWO_SLIDES]: 2,
  [Template.THREE_SLIDES]: 3
};

type Props = FormattedProps & ResponsiveHocProps;

const ManualSlider = ({
  title,
  titleColor,
  titleHtmlTag,
  subtitle,
  slider,
  template,
  redirectButtonLabel,
  redirectButtonTheme,
  redirectButtonUrl,
  mediaQueries
}: Props) => {
  let slidesToShow = slidesToShowByTemplate[template];

  if (mediaQueries.toTablet) {
    slidesToShow = 2;
  }

  return (
    <Wrapper>
      <Container>
        <Title
          titleLabel={title}
          subtitle={subtitle}
          titleColor={titleColor}
          titleHtmlTag={titleHtmlTag}
        />
      </Container>

      <SliderOrList slidesToShow={slidesToShow}>
        {slider.map((slide, index) => (
          <Slide
            data-index={index}
            key={index}
            disableThumbnailLazyLoad={true}
            {...slide}
          />
        ))}
      </SliderOrList>

      {redirectButtonLabel && (
        <ButtonContainer>
          <Button theme={redirectButtonTheme} to={redirectButtonUrl}>
            <Button.children.Text>{redirectButtonLabel}</Button.children.Text>
          </Button>
        </ButtonContainer>
      )}
    </Wrapper>
  );
};

export default ResponsiveHoc(ManualSlider);
