import styled from 'styled-components';

import mediaQueries, {
  customMediaQuery
} from 'shared/helpers/styled-components/mediaQueries';
import {
  MIN_WIDTH,
  MINIMUM_GAP,
  TWO_SLIDES_BREAKPOINT,
  THREE_SLIDES_BREAKPOINT
} from './../../OfferCard/styles/constants';
import Raw from 'shared/components/presentational/Raw/Raw';
import {
  COLORS,
  DIMENSIONS,
  CONTAINER_MAX_WIDTH_BREAKPOINT,
  FONT_FAMILIES,
  FONT_WEIGHTS,
  SIXTEEN_NINTHS_HEIGHT_RATIO
} from 'shared/constants/theme';
import {
  Picture as BasePicture,
  Subtitle,
  Text
} from 'shared/blocks/selfcare/shared/components/Subscription/Subscription.styled';

// reusing Subscription style
export { Subtitle, Text as Description };

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid ${COLORS.alto};
  padding: 40px 20px;
  min-width: ${MIN_WIDTH}px;
  max-width: 320px;
  margin: 0 ${MINIMUM_GAP / 2}px;
  margin-bottom: 50px;

  ${mediaQueries.toPhablet`
    margin-bottom: 15px;
  `};

  ${customMediaQuery(`${TWO_SLIDES_BREAKPOINT}px`)`
    width: calc(50vw - ${
      (DIMENSIONS.CONTAINER_MINIMUM_PADDING * 2 + MINIMUM_GAP) / 2
    }px );
  `}

  ${mediaQueries.fromTablet`
    width: calc(50vw - ${
      (DIMENSIONS.CONTAINER_MINIMUM_PADDING_TABLET * 2 + MINIMUM_GAP) / 2
    }px );
  `}

  ${customMediaQuery(`${THREE_SLIDES_BREAKPOINT}px`)`
    width: calc(33.33vw - ${
      (DIMENSIONS.CONTAINER_MINIMUM_PADDING_TABLET * 2 + MINIMUM_GAP * 2) / 3
    }px );
  `}

  ${customMediaQuery(`${CONTAINER_MAX_WIDTH_BREAKPOINT}px`)`
    width: ${(DIMENSIONS.CONTAINER_INNER_WIDTH - MINIMUM_GAP * 2) / 3}px;
  `}
`;

export const PictureWrapper = styled.div`
  position: relative;
  padding-top: ${SIXTEEN_NINTHS_HEIGHT_RATIO}%;
  width: 100%;
`;

export const Picture = styled(BasePicture)`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

export const MarketingPrice = styled(Raw)`
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
`;
