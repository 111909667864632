import React from 'react';
import styled from 'styled-components';

import LoaderPlaceHolder from 'shared/components/presentational/Loader/LoaderPlaceholder';
import { COLORS } from 'shared/constants/theme';

const Loader = styled(LoaderPlaceHolder)`
  width: 100%;
  height: 200px;
`;

export default () => <Loader color={COLORS.black} />;
