import { Content, FormattedProps } from './types';
import { EngagementUnit } from '../shared/types';
import { getLinkHref } from 'shared/helpers/uri';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => {
  return {
    title: content.title,
    subtitle: content.subtitle,
    tabs: content.tabs.map(tab => ({
      tabTitle: tab.tabTitle,
      title: tab.title,
      subtitle: tab.subtitle,
      contents: tab.products.map(product => ({
        productId: product.productId,
        title: product.title,
        promotionalSticker: product.promotionalSticker || null,
        groups: product.groups.map(group => ({
          title: group.title,
          subtitle: group.subtitle || '',
          exponent: group.exponent,
          isMoreChannels: group.isMoreChannels,
          channels: group.channels.map(channel => ({
            label: channel.channel.media.description,
            href: getLinkHref(channel.channel.media.link)
          }))
        })),
        primaryButton:
          product.primaryButton?.label && product.primaryButton?.link
            ? {
                label: product.primaryButton.label,
                link: getLinkHrefWithAuthentication(product.primaryButton.link)
              }
            : null,
        secondaryButton:
          product.secondaryButton?.label && product.secondaryButton?.link
            ? {
                label: product.secondaryButton.label,
                link: getLinkHrefWithAuthentication(
                  product.secondaryButton.link
                )
              }
            : null,
        moreChannelsIcon: {
          label: product.moreChannelsIcon?.description,
          href: product.moreChannelsIcon?.link.href
        },
        offerDetails: {
          availableCommitmentDurations: product.availableCommitmentDurations,
          commitmentConditions: product.commitmentConditions,
          engagementDuration: null,
          engagementUnit: EngagementUnit[product.engagementUnit],
          netPrice: product.netPrice,
          price: product.price,
          priceInformation: product.priceInformation,
          promoDuration: product.promoDuration
        }
      }))
    }))
  };
};
