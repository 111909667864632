import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  broadcastingWay: content.broadcastingWay,
  title: content.title.label,
  titleColor: content.title.color,
  titleHtmlTag: content.title.htmlTag,
  subtitle: content.subtitle ?? undefined,
  iconColor: content.colors.icon,
  backgroundColor: content.colors.background,
  cards: content.cards.map(card => ({
    icon: card.icon?.link
      ? getLinkHrefWithAuthentication(card.icon.link)
      : undefined,
    description: card.icon?.description ?? undefined,
    content: card.content,
    link: getLinkHrefWithAuthentication(card.link)
  })),
  apis: {
    getSubscriberData: {
      url: content.apis?.getSubscriberData?.url ?? '',
      method: content.apis?.getSubscriberData?.method || 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    }
  }
});
