import queryString from 'query-string';

import getHash from './getHash';

export default (state: object) => {
  const hash = getHash(state);

  if (!hash) {
    return {};
  }

  const hashString = hash.replace('#', '');

  return queryString.parse(hashString);
};
