import styled from 'styled-components';

import { FONT_WEIGHTS } from 'shared/constants/theme';

export const Label = styled.p`
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin: var(--title-margin);
  font-weight: ${FONT_WEIGHTS.demi};
`;

export const Description = styled(Label)`
  font-weight: ${FONT_WEIGHTS.normal};
  color: var(--text-default-light);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
`;

export const WrapperMinor = styled(Wrapper)`
  gap: cal(var(--spacing-xs) / 2);
`;
