import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg
    viewBox="0 0 229 228"
    width={width}
    height={height}
    className={className}
  >
    <g fillRule="nonzero" fill="none">
      <circle fill="currentColor" cx={115} cy={114} r={113.4} />
      <path
        fill="#FFF"
        d="M45.4 110.8v23.9H.4v-23.9h45zM92.4 46.5v135.7H66.3V69.9h-13V46.5zM113.1 93.7l-.4-7.4c0-25.2 7.2-42.1 35.5-42.1 22.5 0 36.5 11.3 36.5 34.7 0 13.9-4.9 24.8-11.2 36.9l-22.7 43h31.5v23.4H111l30.6-56.2c7-13 17.5-31.3 17.5-46.3 0-7-2.2-16.7-10.8-16.7-10.3 0-10.8 14.6-10.8 22l.4 8.6-24.8.1z"
      />
    </g>
  </svg>
);
