/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import { createTemplate } from 'shared/helpers/template';

export default (content, { id, getLinkHrefWithAuthentication }) => ({
  id,
  categoriesApiUrl: createTemplate(
    get(
      content,
      'apiCategories.url',
      'https://service.canal-overseas.com/ott-frontend/vector/{vectorSat}/categories'
    )
  )({
    vectorSat: get(content, 'api.parameters.vectorSat')
  }),
  createLinkUrl: createTemplate(
    getLinkHrefWithAuthentication(get(content, 'dynamicProgramLink', ''))
  )
});
