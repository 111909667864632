import flatMap from 'lodash/flatMap';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';

import { Device } from 'shared/constants/breakpoints';
import { SizesByDevice } from 'shared/components/presentational/Picture/types';
import getThumborUrl from 'shared/components/presentational/Picture/helpers/getThumborUrl';
import breakpoints, {
  LABEL_BREAKPOINTS
} from 'shared/components/presentational/Picture/constants';

function cleanAndOrderSrcSet(
  flatMap: (string | false)[],
  sourceKeys: string[]
): string {
  const compactMapSrcSet = compact(flatMap);
  if (head(sourceKeys) === LABEL_BREAKPOINTS.PHONE_MINI) {
    compactMapSrcSet.reverse();
  }
  return compactMapSrcSet.join(', ');
}

export default function provideAdditionalSourceImages(
  url?: string,
  sizes?: SizesByDevice,
  thumborFilter?: number
): string | undefined {
  if (!sizes || isEmpty(sizes)) {
    return undefined;
  }
  const flatMapSrcSet = flatMap(Object.entries(sizes), size => {
    let width = 0;
    let height = 0;
    const sizeName = size[0] as Device;
    const sizeConfig = size[1];
    if (sizeConfig && sizeConfig.w) {
      width = sizeConfig.w;
    }
    if (sizeConfig && sizeConfig.h) {
      height = sizeConfig.h;
    }

    if (width && breakpoints[sizeName]) {
      const thumborUrl = getThumborUrl({
        url: sizeConfig?.url ?? url,
        width,
        height,
        thumborFilter
      });
      return `${thumborUrl} ${width}w`;
    }

    return false;
  });

  return cleanAndOrderSrcSet(flatMapSrcSet, Object.keys(sizes));
}
