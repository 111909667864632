import { fetchContent } from 'shared/helpers/eismo';
import highlightsUrlToCategory from 'shared/helpers/eismo/highlightsUrlToCategory';
import {
  formatProgramMEAContent,
  formatProgramMEACategories
} from '../helpers';

const FETCH_PROGRAMS_BY_BLOCK = 'FETCH_PROGRAMS_BY_BLOCK';
const FETCH_PROGRAMS_BY_CATEGORY = 'FETCH_PROGRAMS_BY_CATEGORY';
const FETCH_PROGRAMS_CATEGORIES = 'FETCH_PROGRAMS_CATEGORIES';

export const ACTION_TYPES = {
  FETCH_PROGRAMS_BY_BLOCK,
  FETCH_PROGRAMS_BY_CATEGORY,
  FETCH_PROGRAMS_CATEGORIES
};

export function fetchProgramsHighlightsByBlock(
  blockId,
  apiUrl,
  timezone,
  createLinkUrl
) {
  return async dispatch => {
    try {
      const programs = await fetchContent(apiUrl);
      const formattedPrograms = formatProgramMEAContent(
        programs.contents,
        timezone,
        createLinkUrl
      );

      return dispatch({
        type: FETCH_PROGRAMS_BY_BLOCK,
        payload: { id: blockId, formattedPrograms }
      });
    } catch (error) {
      return null;
    }
  };
}

export function fetchProgramsCategories(apiUrl) {
  return async dispatch => {
    try {
      const categories = await fetchContent(apiUrl);
      const formattedCategories = formatProgramMEACategories(categories);

      return dispatch({
        type: FETCH_PROGRAMS_CATEGORIES,
        payload: { formattedCategories }
      });
    } catch (error) {
      return null;
    }
  };
}

export function fetchProgramsHighlightsByCategory(
  fetchedCategories,
  apiUrl,
  timezone,
  createLinkUrl
) {
  return async dispatch => {
    try {
      const category = highlightsUrlToCategory(apiUrl);

      // if given category (or "null" category) not fetched already
      if (
        (category && !fetchedCategories.includes(category)) ||
        !fetchedCategories.includes('null')
      ) {
        const programs = await fetchContent(apiUrl);

        const formattedPrograms = formatProgramMEAContent(
          programs.contents,
          timezone,
          createLinkUrl
        );

        return dispatch({
          type: FETCH_PROGRAMS_BY_CATEGORY,
          payload: { category, formattedPrograms }
        });
      }
      return null;
    } catch (error) {
      return null;
    }
  };
}
