import React from 'react';
import styled from 'styled-components';

import Title from 'shared/components/presentational/Title';
import { FormattedProps } from '../types';
import AdvantageItem from './AdvantageItem';
import { container } from 'shared/constants/mixins';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

const Wrapper = styled.div`
  ${container}
  margin-bottom: 40px;
  ${mediaQueries.fromTablet`
   margin-bottom: 48px;
`}
`;

const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  ${mediaQueries.toTablet`
 gap: 10px;
`}
`;

const Advantages = ({
  advantages,
  color,
  toggleColor,
  title,
  titleHtmlTag,
  titleColor,
  subtitle,
  template
}: FormattedProps) => {
  const renderCards = React.useCallback(
    (advantage, index) => (
      <AdvantageItem
        key={index}
        advantage={advantage}
        color={color}
        toggleColor={toggleColor}
        template={template}
      />
    ),
    [color, toggleColor, template]
  );

  return (
    <Wrapper>
      <Title
        titleLabel={title}
        subtitle={subtitle}
        titleColor={titleColor}
        titleHtmlTag={titleHtmlTag}
      />
      <CardsWrapper>{advantages.map(renderCards)}</CardsWrapper>
    </Wrapper>
  );
};

export default Advantages;
