import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 182 48" width={width} height={height} className={className}>
    <g fill="#currentColor" fillRule="nonzero">
      <path d="M166.9.5L9.3 3.1.5 47.5l181-5.7L166.9.5zm-136.3 33H19.2l2.4-19.8h5l-1.9 15.4H31l-.4 4.4zm15.5-15.4h-5.9l-.4 3.4h5.6l-.5 4.3h-5.7l-.4 3.3H45l-.6 4.3H33.3l2.4-19.8h10.9l-.5 4.5zm8.2 1.9c.3.6.8.9 2.3 1.6 3.3 1.6 4.4 3 4.4 5.6 0 4-3.1 6.8-7.6 6.8-2.4 0-4.1-.5-6.7-2.2L49 28c1.8 1.2 2.9 1.6 4.2 1.6 1.6 0 2.6-.8 2.6-2 0-.9-.5-1.3-2.3-2.2-3.5-1.9-4.5-3.2-4.5-5.8 0-3.6 3.1-6.2 7.3-6.2 2 0 3.8.6 5.8 1.8L59.8 19c-1.4-1-2.4-1.5-3.5-1.5-1.4 0-2.2.7-2.2 1.7 0 .3.1.6.2.8zm30.2-1.9h-4.2l-1.9 15.4h-5l1.9-15.4H71l.5-4.3H85l-.5 4.3zm18.7 7.1c-.5 3.7-1 5.2-2.3 6.5-1.5 1.4-3.9 2.2-6.8 2.2-5.1 0-8.3-2.6-8.3-6.7 0-.6.1-1.6.2-2.8l1.3-10.7h5L91 24.4c0 .2 0 .4-.1.8v.7c0 2.2 1.1 3.2 3.4 3.2 2.4 0 3.4-1.1 3.8-4.1l1.4-11.3h5l-1.3 11.5zm17-7.1H116l-1.9 15.4h-5l1.9-15.4h-4.3l.5-4.3h13.5l-.5 4.3zm11.5 16.1c-6.3 0-10.8-4.3-10.8-10.3 0-6.2 4.7-10.9 11.2-10.9 6.2 0 10.5 4.1 10.5 10.1-.1 6.5-4.6 11.1-10.9 11.1zM151.2 20c.3.6.8.9 2.3 1.6 3.3 1.6 4.4 3 4.4 5.6 0 4-3.1 6.8-7.6 6.8-2.4 0-4.1-.5-6.7-2.2l2.3-3.8c1.8 1.2 2.9 1.6 4.2 1.6 1.6 0 2.6-.8 2.6-2 0-.9-.5-1.3-2.3-2.2-3.6-1.8-4.6-3.1-4.6-5.7 0-3.6 3.1-6.2 7.3-6.2 2 0 3.8.6 5.8 1.8l-2.2 3.8c-1.4-1-2.4-1.5-3.5-1.5-1.4 0-2.2.7-2.2 1.7 0 .2.1.5.2.7z" />
      <path d="M131.9 17.8c-3.4 0-5.9 2.6-5.9 6 0 3.3 2.4 5.7 5.6 5.7 3.3 0 5.8-2.5 5.8-5.9 0-3.4-2.3-5.8-5.5-5.8z" />
    </g>
  </svg>
);
