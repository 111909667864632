import get from 'lodash/get';

import { COLORS } from 'shared/constants/theme';
import { Content, FormattedProps } from './types';
import { PropsFormatterUtils, Menu } from 'shared/types';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';

export const SEE_ALL_RESULTS_BUTTON_LABEL_BY_INDEX: Record<string, string> = {
  dev_OFFERS: 'Voir toutes les offres',
  dev_PROGRAMS: 'Voir tous les programmes',
  rec_OFFERS: 'Voir toutes les offres',
  rec_PROGRAMS: 'Voir tous les programmes',
  prp_OFFERS: 'Voir toutes les offres',
  prp_PROGRAMS: 'Voir tous les programmes',
  prod_OFFERS: 'Voir toutes les offres',
  prod_PROGRAMS: 'Voir tous les programmes',
  default: 'Voir tous les résultats'
};

const handleMenuLinks = (
  menu: Menu[],
  getLinkHrefWithAuthentication: PropsFormatterUtils['getLinkHrefWithAuthentication']
) =>
  menu.map((menu: Menu) => ({
    id: get(menu, 'id', ''),
    title: get(menu, 'title', ''),
    link: getLinkHrefWithAuthentication(get(menu, 'link', null))
  }));

export default (
  content: Content,

  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  mainMenuItems: handleMenuLinks(
    get(content, 'mainMenu', []),
    getLinkHrefWithAuthentication
  ),
  quickAccessMenuItems: handleMenuLinks(
    get(content, 'quickAccessMenu', []),
    getLinkHrefWithAuthentication
  ),

  algolia: {
    enableSearchEngine: content.enableSearchEngine,
    appId: content.algolia.parameters.appId,
    apiKey: content.algolia.parameters.appKey,
    seeAllResultsButtonsTheme: buttonThemeFactory({
      backgroundColor: content.quickAccessLinksColor || COLORS.cerulean,
      color: COLORS.white
    }),
    mobileSeeAllResultsButtonLabel:
      content.algolia.parameters.mobileSeeAllResultsButtonLabel ||
      'mobileLabel',
    indices: [
      ...content.algolia.parameters.indexes.map(index => ({
        indexName: index.indexName,
        searchResultsLabel: index.searchResultsLabel,
        seeAllResultsButtonLabel:
          SEE_ALL_RESULTS_BUTTON_LABEL_BY_INDEX[index.indexName] ??
          SEE_ALL_RESULTS_BUTTON_LABEL_BY_INDEX['default']
      }))
    ],
    shortcuts: content.algolia.shortcutAlgoliaMenu.map(item => ({
      id: item.id,
      title: item.title,
      link: getLinkHrefWithAuthentication(item.link)
    })),
    shortcutsTitle: content.algolia.shortcutAlgoliaName
  },
  logoUrl: getLinkHrefWithAuthentication(get(content, 'siteLogo.link', '')),
  isAllowedToConnect: get(content, 'authentication.authCanalPlus.allow', false),
  canalBoxUrl: getLinkHrefWithAuthentication(
    get(content, 'authentication.authCanalBox.link', '')
  ),
  canalBoxTitle: get(content, 'authentication.authCanalBox.title', undefined),
  backgroundColor: content.backgroundColor || COLORS.black,
  mainLinksColor: content.mainLinksColor || COLORS.white,
  quickAccessLinksColor: content.quickAccessLinksColor || COLORS.cerulean,
  redirectUrlAfterLogin: getLinkHrefWithAuthentication(
    get(content, 'authentication.authCanalPlus.redirectLinkAfterLogin', '')
  ),
  boxTitle: get(content, 'authentication.title', ''),
  boxSubscriptionManagementLabel: get(
    content,
    'authentication.authCanalPlus.subscriptionManagement.label',
    ''
  ),
  boxSubscriptionManagementUrl: getLinkHrefWithAuthentication(
    get(content, 'authentication.authCanalPlus.subscriptionManagement.link', '')
  ),
  boxLoyaltyProgramLabel: get(
    content,
    'authentication.authCanalPlus.loyaltyProgram.label',
    ''
  ),
  boxLoyaltyProgramUrl: getLinkHrefWithAuthentication(
    get(content, 'authentication.authCanalPlus.loyaltyProgram.link', '')
  ),
  signupCanalPlusTitle: get(
    content,
    'authentication.signupCanalPlus.title',
    ''
  ),
  signupCanalPlusLinkLabel: get(
    content,
    'authentication.signupCanalPlus.label',
    ''
  ),
  signupCanalPlusLink: getLinkHrefWithAuthentication(
    get(content, 'authentication.signupCanalPlus.link', '')
  )
});
