import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Raw from 'shared/components/presentational/Raw/Raw';
import Picture from 'shared/components/presentational/Picture';
import Link from 'shared/components/presentational/Link';
import Button from 'shared/components/presentational/Button';
import PremiumLabel from 'shared/components/presentational/PremiumLabel';
import pxToRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import { OfferProps } from '../types';

const Wrapper = styled.div<{ backgroundColor?: string | null }>`
  background-color: ${props => props.backgroundColor || COLORS.white};
  padding: 55px 20px 20px;
  border-radius: 2px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Content = styled.div``;

const Label = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  text-align: center;
`;

const TitleWrapper = styled(Link)`
  text-decoration: none;
`;

const Title = styled.h3`
  font-size: ${pxToRem(22)};
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  color: ${props => props.color};
  text-align: center;
  text-transform: uppercase;
  margin: 15px 0;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubTitle = styled.h4`
  font-size: ${pxToRem(14)};
  font-family: ${FONT_FAMILIES.base};
  font-weight: ${FONT_WEIGHTS.normal};
  padding: 0;
  margin: 10px 0 25px;
`;

const Channels = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  margin: 0 -20px 40px;
  height: 122px;

  ${mediaQueries.toPhablet`
    margin-bottom: 20px;
  `};
`;

const StyledPicture = styled(Picture)`
  width: 54px;
  margin: 4px 12px;
  display: inline-block;
  align-self: center;
`;

const Exponent = styled.sup`
  top: -15px;
  margin-left: -12px;
  height: 12px;
  line-height: 12px;
  width: 12px;
  font-size: ${pxToRem(10)};
  font-family: ${FONT_FAMILIES.base};
  white-space: nowrap;
  align-self: center;
`;

const Description = styled(Raw)`
  line-height: normal;
  margin-bottom: 40px;

  ${mediaQueries.toPhablet`
    margin-bottom: 20px;
  `};
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
  align-self: flex-end;
`;

const Offer = ({
  offer: {
    titleColor,
    isLabelPremium,
    title,
    subtitle,
    channels,
    text,
    buttonUrl
  },
  backgroundColor,
  className
}: OfferProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper className={className} backgroundColor={backgroundColor}>
      <Content>
        {isLabelPremium && (
          <Label>
            <PremiumLabel />
          </Label>
        )}
        <TitleWrapper to={buttonUrl}>
          <Title color={backgroundColor ? COLORS.black : titleColor}>
            {title}
          </Title>
        </TitleWrapper>
        <SubTitle>{subtitle}</SubTitle>
        {channels.length !== 0 && (
          <Channels className="channels">
            {channels.map((channel, index) => (
              <div key={`${channel.title}-${index}-channel`}>
                <StyledPicture
                  width={54}
                  url={channel.imageUrl}
                  alt={channel.title}
                  placeholderColor="transparent"
                />
                {channel.exponent && <Exponent>{channel.exponent}</Exponent>}
              </div>
            ))}
          </Channels>
        )}

        {text && <Description html={text} />}
      </Content>
      {buttonUrl && (
        <StyledButton to={buttonUrl} theme="blackPlain">
          <Button.children.Text>
            {t('blocks.myCanal.discover')}
          </Button.children.Text>
        </StyledButton>
      )}
    </Wrapper>
  );
};

export default Offer;
