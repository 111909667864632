import queryString from 'query-string';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import includes from 'lodash/includes';

export default (
  silentLoginUrl: string,
  loginUrl: string,
  currentUrl: string
) => {
  const [currentUrlWithoutParam, queryParams] = currentUrl.split('?');
  const params = queryString.parse(queryParams);
  const sessionToken = params.sessionToken;
  const token = params.token;
  const refererUrl = params.referer;
  const redirectUri = params.redirect_uri;
  const otherParams = omit(params, [
    'token',
    'referer',
    'redirect_uri',
    'sessionToken'
  ]);

  let referer = queryString.stringifyUrl({
    url: currentUrlWithoutParam,
    query: otherParams
  });

  if (redirectUri && !isArray(redirectUri)) {
    referer = redirectUri;
  }

  if (refererUrl && !isArray(refererUrl)) {
    referer = refererUrl;
  }

  if (includes(referer, loginUrl)) {
    referer = '/';
  }

  return queryString.stringifyUrl({
    url: silentLoginUrl,
    query: {
      token,
      referer,
      sessionToken
    }
  });
};
