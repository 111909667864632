import { ACTION_TYPES } from './constants';

const defaultState = {
  path: '',
  data: { title: '', url: '', site: { title: '' }, devices: [] },
  scrollTop: 0,
  window: { width: 0, height: 0 }
};

export default function page(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
