import React, { PureComponent } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto;

  ${props => props.isOpened && '> svg { transform: rotate(180deg);}'};
`;

export default class Handle extends PureComponent {
  static displayName = 'Accordion.Handle';

  render() {
    const { children, className, isOpened } = this.props;

    return (
      <Wrapper className={className} isOpened={isOpened}>
        {children}
      </Wrapper>
    );
  }
}
