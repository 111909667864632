import { Store } from 'redux';

import { TASK_TYPE } from 'shared/modules/tasks/constants';
import getFirstBlockWithNamespace from 'shared/modules/page/selectors/getFirstBlockWithNamespace';
import getSmartFaqUuid from 'shared/modules/page/selectors/getSmartFaqUuid';
import { namespace } from './index';
import propsFormatter from './propsFormatter';
import fetch from './actions/fetch';
import getBigram from 'shared/modules/page/selectors/getCurrentZone';
import { DEFAULT_SLUG } from 'shared/blocks/smart-faq/constants';
import getData from 'shared/blocks/smart-faq/selectors/getData';
import { getBrandHostname } from 'shared/blocks/smart-faq/selectors/getBrandHostname';

const fetchTask = ({ dispatch, getState }: Store) => async ({
  slug = DEFAULT_SLUG
}) => {
  const state = getState();

  const block = getFirstBlockWithNamespace(state, namespace);

  if (block) {
    const { bySlug } = getData(state);
    const current = bySlug[slug];

    if (!current) {
      const { apiUrl } = propsFormatter(block.content);
      const zone = getSmartFaqUuid(state);
      const bigram = getBigram(state);
      const brandHostname = getBrandHostname(state);

      const siteUrl = `${brandHostname}/${bigram}`;
      const action = fetch(apiUrl, slug, zone, siteUrl);

      await action(dispatch);
    }
  }
};

export default (store: Store) => [
  {
    pattern: '/:bigramme([a-zA-Z]{2})?/aide-en-ligne/:slug*',
    [TASK_TYPE.DEFERRED_DATA]: fetchTask(store)
  }
];
