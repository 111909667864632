import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'shared/blocks/selfcare/shared/components/Loader';
import { INITIAL_DEFAULT_VALUES } from '../constants';
import { TitleOption, Props } from '../types';
import { Wrapper } from './styles/components';
import ApiError from 'shared/components/presentational/ApiError';
import getTitleResponseToTitleOptions from '../helpers/getTitleResponseToTitleOptions';
import OfferSubscriptionForm from './OfferSubscriptionForm';
import useGetTitlesQuery from '../hooks/useGetTitles';

export default (props: Omit<Props, 'titleOptions' | 'defaultValues'>) => {
  const { t } = useTranslation();

  const [defaultValues, setDefaultValues] = useState(INITIAL_DEFAULT_VALUES);
  const [defaultValuesPending, setDefaultValuesPending] = useState(true);

  const { data: titlesData, error: titlesError } = useGetTitlesQuery(
    props.crmGetTitlesApi
  );

  const titleOptions = useMemo<TitleOption[] | undefined>(() => {
    if (titlesData && titlesData.data.length > 0) {
      return getTitleResponseToTitleOptions(titlesData);
    }
  }, [titlesData]);

  const isTitlePending = !titleOptions && !titlesError;

  useEffect(() => {
    props
      .retrieveOfferSubscriptionForm()
      .then(decryptedFormData => {
        // override default values if form data are retrieved
        setDefaultValues(decryptedFormData.values);
        setDefaultValuesPending(false);
      })
      // do nothing if no form data are retrieved
      .catch(() => {
        setDefaultValuesPending(false);
      });
  }, []);

  if (defaultValuesPending || isTitlePending) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  if (titlesError) {
    return <ApiError text={t('common.global.error')} id="get_title_error" />;
  }

  return (
    <OfferSubscriptionForm
      {...props}
      titleOptions={titleOptions}
      defaultValues={defaultValues}
    />
  );
};
