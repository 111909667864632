import { css } from 'styled-components';

export default {
  visual: {
    width: '325px',
    maxWidth: 'none',
    height: '200px',
    phoneHeight: '165px',
    shadowStyle: css`
      box-shadow: 0 -119px 116px -60px #000 inset;
    `,
    infosPosition: css`
      left: 15px;
      bottom: 15px;
    `,
    titleSize: 15,
    arrow: false
  }
};
