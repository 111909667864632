export const QUERY_KEYS = {
  ACCOUNT_DATA: 'accountData',
  ALLMOL_QUOTAS: 'allmolQuotas',
  AVAILABLE_REWARDS_BY_NUM_CONTRACT: 'availableReward',
  CAN_RESET_PARENTAL_CODE: 'canResetParentalCode',
  CAN_SEND_REAC_COMMAND_BY_NUM_CONTRACT: 'canSendReacCommand',
  CHECK_ACCOUNT: 'checkAccount',
  CHECKOUT_VISA: 'checkoutVisa',
  GET_ADDRESSES_BY_NUM_CONTRACT: 'getAddresses',
  GET_AVAILABLE_EVENTS_BY_NUM_CONTRACT: 'getAvailableEvents',
  GET_AVAILABLE_PROPOSALS: 'getAvailableProposals',
  GET_DISNEY_ACTIVATION_STATUS: 'getDisneyActivationStatus',
  GET_DOCUMENTS: 'getDocuments',
  GET_EQUIPMENTS_BY_NUM_CONTRACT: 'getEquipments',
  GET_OUT_PACKAGE: 'getOutPackage',
  GET_PARENT_CODE: 'getParentCode',
  GET_PAYMENT_HISTORY_BY_NUM_CONTRACT: 'getPaymentsHistory',
  GET_PHONES_BY_NUM_CONTRACT: 'getPhones',
  GET_RENEWAL_OFFER: 'getRenewalOffer',
  GET_RENEWAL_OFFERS: 'getRenewalOffers',
  GET_SUBSCRIPTION_AND_OPTIONS: 'getSubscriptionAndOptions',
  GET_UNPAID_INFOS_BY_CONTRACTS_LIST: 'getUnpaidInfos',
  GET_CONTRACT_INVOICE_LIST: 'getContractInvoiceList',
  READ_ALL_AVAILABLE_OFFERS: 'readAllAvailableOffers',
  REGISTER_SUBSCRIPTION_UPGRADE: 'registerSubscriptionUpgrade',
  RETRIEVE_ELIGIBILITY: 'retrieveEligibility',
  SELECT_EVENT: 'selectEvent',
  SIMULATE_SUBSCRIPTION_UPGRADE: 'simulateSubscriptionUpgrade',
  SUBSCRIBER_DATA: 'subscriberData',
  UPDATE_OFFERS_SELECTION: 'updateOffersSelection',
  VERIFY_RENEWAL_OFFERS: 'verifyRenewalOffers',
  GET_CANAL_PLUS_TELECOM_AUTHENTICATION: 'getCanalPlusTelecomAuthentication',
  GET_DATA_CONSUMPTION: 'getDataConsumption'
};

export const CONTEXT_CONDITIONS = {
  BROADCASTING_WAY: 'broadcastingWay',
  IS_ACTIVE: 'isActive',
  ERROR_CODE: 'errorCode'
};

export const CONTEXT_BEHAVIOURS = {
  DISPLAY: 'display',
  HIDE: 'hide'
};

export const NO_CONTRACT_ERROR_CODE = '0101';
