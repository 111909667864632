export default (url, options) => {
  const defaultOptions = {
    defer: true,
    async: false
  };
  const { defer, async } = Object.assign(defaultOptions, options);

  return new Promise(resolve => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.setAttribute('src', url);
    if (async) {
      script.setAttribute('async', 'async');
    }
    if (defer) {
      script.setAttribute('defer', 'defer');
    }
    script.onreadystatechange = resolve;
    script.onload = resolve;

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script, head);
  });
};
