/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import styled from 'styled-components';

import { COLORS, FONT_FAMILIES } from 'shared/constants/theme';

const Wrapper = styled.span`
  height: 20px;
  display: inline-block;

  svg {
    max-width: 90px;
    height: 20px;
    margin: 0 auto;
    display: block;
    color: ${COLORS.white};
    background-color: #fbb800;
    background-image: linear-gradient(to right, #fbb800 0%, #e47726 100%);
    border-radius: 2px;
    font-size: 10px;
    font-family: ${FONT_FAMILIES.base};
  }
`;

const Label = () => (
  <Wrapper>
    <svg
      viewBox="0 0 90 20"
      id="cos-svg-premium"
      className="cos-svg cos-svg-premium"
      width="100%"
      height="100%"
    >
      <polygon points="15.3,4.9 16.8,7.9 20.2,8.4 17.7,10.9 18.4,14.1 15.3,12.6 12.3,14.1 12.9,10.9 10.4,8.4 13.8,7.9 " />
      <text transform="matrix(1 0 0 1 25 13.5)">PREMIUM</text>
    </svg>
  </Wrapper>
);

export default Label;
