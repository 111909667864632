import React from 'react';

import {
  Divider,
  Wrapper,
  WrapperLeft,
  WrapperRight
} from '../styles/components';
import { Badge } from '../../shared/components/Badge/component/Badge';
import { Button } from 'shared/blocks/one-shop/shared/components/Button/Button';
import { FormattedProps } from '../types';
import { Minors } from './Minors/Minors';
import OfferDetailsRocContainer from '../../shared/containers/OfferDetailsRocContainer';
import { Sticker } from '../../shared/components/Sticker/component/Sticker';
import Title from 'shared/components/presentational/Title';

const PropalDetails = ({
  title,
  description,
  users,
  promotionalSticker,
  minors,
  button,
  offerDetails
}: FormattedProps) => {
  const { icon, label } = users;

  const displayMinors = minors && minors.length >= 1;
  const displayBadge = icon || label;

  return (
    <Wrapper data-testid="oneshop.propal.details">
      <WrapperLeft>
        {promotionalSticker && <Sticker label={promotionalSticker} />}
        <Title titleLabel={title} subtitle={description} titleHtmlTag="h1" />
        {displayBadge && <Badge label={label} icon={icon} />}
      </WrapperLeft>
      <WrapperRight>
        <OfferDetailsRocContainer {...offerDetails} />
        {displayMinors && (
          <>
            <Divider />
            <Minors minors={minors} />
          </>
        )}
        {button.label && button.link && (
          <Button
            fullWidthMobile
            fullWidthDesktop
            label={button.label}
            link={button.link}
          />
        )}
      </WrapperRight>
    </Wrapper>
  );
};

export default PropalDetails;
