import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  line-height: 100%;
  margin-left: 8px;
  :first-child {
    margin-left: 0;
  }
`;

interface Props {
  bigram: string;
  width?: string;
  height?: string;
  className?: string;
}

export default class SvgFlag extends PureComponent<Props> {
  public static readonly displayName = 'Button.SvgFlag';

  public render(): ReactNode {
    const { className, width, height, bigram } = this.props;
    return (
      <Wrapper className={className}>
        <svg width={width} height={height}>
          <use xlinkHref={`/flags.svg#cos-svg-${bigram}`} />
        </svg>
      </Wrapper>
    );
  }
}
