import { connect } from 'react-redux';

import { hasPageUnifiedMenu } from 'shared/modules/page/selectors/hasPageMenuUnified';
import getPageData from 'shared/modules/page/selectors/getPageData';
import Iframe from '../components/Iframe';
import pushURLHandler from 'shared/helpers/router/pushURLHandler';

const mapStateToProps = (state: any) => {
  const pageData = getPageData(state);
  const hasUnifiedMenu = hasPageUnifiedMenu(pageData);

  return {
    hasUnifiedMenu
  };
};

const mapDispatchToProps = {
  pushURLHandler
};

export default connect(mapStateToProps, mapDispatchToProps)(Iframe);
