import styled from 'styled-components';

import { container } from 'shared/constants/mixins';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

export const Wrapper = styled.section`
  ${container}
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: var(--surface-background);
  gap: var(--spacing-l);
`;

export const Propals = styled.div`
  border-radius: 2px;
`;

export const WrapperPropal = styled.div<{ isSelected?: boolean }>`
  display: flex;
  gap: var(--spacing-xl);
  align-items: center;
  border: ${({ isSelected }) =>
    isSelected
      ? '1px solid var(--surface-dark)'
      : '1px solid var(--surface-default)'};
  border-radius: 2px;
  padding: var(--spacing-xl);

  ${mediaQueries.fromTablet`
    &:hover {
      background: var(--surface-hover);
    }
  `}
`;

export const WrapperTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-l) * 2);
`;
