import { AnyAction } from 'redux';

import { CLEAR_OFFER_SUBSCRIPTION_FORM_ACTION_TYPE } from '../constants';

const clearOfferSubscriptionForm = {
  type: CLEAR_OFFER_SUBSCRIPTION_FORM_ACTION_TYPE
};

type ClearOfferSubscriptionFormAction = typeof clearOfferSubscriptionForm;

export function isClearOfferSubscriptionFormAction(
  action: AnyAction
): action is ClearOfferSubscriptionFormAction {
  return action.type === CLEAR_OFFER_SUBSCRIPTION_FORM_ACTION_TYPE;
}

export default clearOfferSubscriptionForm;
