import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';

import Accordion from 'shared/components/presentational/Accordion';
import Icon from 'shared/components/presentational/Icons/Icon';
import Raw from 'shared/components/presentational/Raw/Raw';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import {
  accordionHeaderStyled,
  accordionItemStyled,
  accordionContentStyled,
  rawStyled
} from '../../shared/styles';

const ThematicTitle = styled.h2`
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${pxInRem(20)};
  padding-left: 21px;
  text-transform: uppercase;

  ${mediaQueries.toPhablet`text-align: center; font-size: ${pxInRem(
    18
  )}; padding-left: 0;`};
`;

const ThematicWrapper = styled.div`
  margin-top: 33px;
`;

const StyledAccordionHeader = styled(Accordion.Header)`
  ${accordionHeaderStyled};
`;

const StyledAccordionItem = styled(Accordion.Item)`
  ${accordionItemStyled};
`;

const StyledAccordionContent = styled(Accordion.Content)`
  ${accordionContentStyled};
`;

const StyledRaw = styled(Raw)`
  ${rawStyled};
`;

const StyledQuestion = styled.span`
  font-family: ${FONT_FAMILIES.base};
`;

StyledAccordionHeader.displayName = 'Accordion.Header';
StyledAccordionItem.displayName = 'Accordion.Item';
StyledAccordionContent.displayName = 'Accordion.Content';

export default class Thematic extends PureComponent {
  render() {
    const { thematic, questions, openedQuestion } = this.props;

    return (
      <ThematicWrapper key={thematic} id={thematic}>
        <ThematicTitle>{thematic}</ThematicTitle>
        <Fragment>
          <Accordion forcedOpenItems={[openedQuestion]}>
            {questions.map((q, index) => (
              <StyledAccordionItem
                key={index}
                thematic={thematic}
                id={`${thematic}-${index}`}
              >
                <StyledAccordionHeader>
                  <StyledQuestion>{q.question}</StyledQuestion>
                  <Accordion.Handle>
                    <Icon
                      name="ArrowBottom"
                      width={13}
                      height={13}
                      color={COLORS.tundora}
                    />
                  </Accordion.Handle>
                </StyledAccordionHeader>
                <StyledAccordionContent>
                  <StyledRaw html={q.response} />
                </StyledAccordionContent>
              </StyledAccordionItem>
            ))}
          </Accordion>
        </Fragment>
      </ThematicWrapper>
    );
  }
}
