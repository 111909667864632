/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { Content, FormattedProps } from './types';
import { formatSlide } from '../manual-slider/propsFormatter';
import { getLinkHref } from 'shared/helpers/uri';
import { PropsFormatterUtils } from 'shared/types';
import { Slide } from '../manual-slider/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: get(content, 'title.label'),
  titleColor: get(content, 'title.color'),
  titleHtmlTag: get(content, 'title.htmlTag'),
  subtitle: content.subtitle ?? undefined,
  slider: (get(content, 'slider', []) as Slide[]).map(
    formatSlide(getLinkHrefWithAuthentication)
  ),
  bannerTitle: get(content, 'banner.title'),
  bannerSubtitle: get(content, 'banner.subtitle'),
  bannerBackgroundImageUrl: getLinkHref(get(content, 'banner.media.link', '')),
  bannerBackgroundImageAlt:
    content.banner?.media?.description ?? content.banner?.media?.filename,
  bannerTemplate: get(content, 'banner.template'),
  bannerButtonLabel: get(content, 'buttons.bannerButton.label'),
  bannerButtonTheme: themeFactory({
    color: get(content, 'buttons.bannerButton.labelColor'),
    backgroundColor: get(content, 'buttons.bannerButton.backgroundColor')
  }),
  bannerButtonUrl: getLinkHrefWithAuthentication(
    get(content, 'buttons.bannerButton.link', '')
  )
});
