export default () => {
  /* eslint-disable */
  sas.cmd = sas.cmd || [];
  sas.cmd.push(() => {
    sas.setup({
      networkid: 2136,
      domain: '//www3.smartadserver.com',
      async: true
    });
  });
  sas.cmd.push(() => {
    sas.call('std', {
      siteId: 272504, //
      pageId: 1018332, // Page : CanalAfrique_AF/rg
      formatId: 45655, // Format : Pave 1 300x250
      target: '' // Ciblage
    });
  });
};
