/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
/*       */
/* eslint-disable import/prefer-default-export */

import { OrderedZone } from 'shared/helpers/zones/orderZones';

export const TYPES = {
  SELECT_ZONE: 'MODAL_GEOLOCATION_SELECT_ZONE',
  VALIDATE_ZONE_SELECTION: 'MODAL_GEOLOCATION_VALIDATE_ZONE_SELECTION',
  RESET_ZONE_SELECTION: 'MODAL_GEOLOCATION_RESET_ZONE_SELECTION'
};

export function selectZone(zone: OrderedZone) {
  return {
    type: TYPES.SELECT_ZONE,
    payload: zone
  };
}

export function validateZoneSelection(zone: OrderedZone) {
  return {
    type: TYPES.VALIDATE_ZONE_SELECTION,
    payload: zone
  };
}

export function resetZoneSelection() {
  return {
    type: TYPES.RESET_ZONE_SELECTION
  };
}
