import React, {
  useMemo,
  useCallback,
  useReducer,
  useEffect,
  useRef
} from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { MultipleQueriesQuery } from '@algolia/client-search';
import useOnClickOutside from 'use-onclickoutside';

import {
  reducer,
  getInitialState,
  SearchEngineActionType as ActionType
} from './reducer';
import { Content } from '../../../styles/components';
import { FormattedAlgoliaConfig } from '../../../types';
import {
  HEADER_MOBILE_HEIGHT,
  HEADER_DESKTOP_HEIGHT
} from 'shared/constants/heights';
import ResponsiveHoc, {
  ResponsiveHocProps
} from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';
import Icon from 'shared/components/presentational/Icons';
import {
  Modal,
  InnerModal,
  InputWrapper,
  Top,
  StickyBorderTop,
  SearchIcon,
  SearchBoxCloseButton,
  SearchEnginePanelWrapper,
  SearchEnginePanelWidthHolder,
  SearchEnginePanel,
  MobileSeeAllResultsButtonWrapper,
  SeeAllResultsButton
} from './styles/components';
import Logo from '../Logo';
import SearchBox from './SearchBox';
import SearchResultsGrid from './SearchResultsGrid';
import RefinableSearchResults from './RefinableSearchResults';
import {
  SEARCH_HIGHLIGHT_CLASS,
  INDEX_EMPTY_RESULTS,
  SEARCHBOX_MIN_CHARACTERS_REFINE,
  FAQ_ROOT,
  INDEX_NAMES
} from './constants';
import { SEARCH_ENGINE_MODAL_BOTTOM_PADDING } from './styles/constants';
import breakpoints from 'shared/constants/breakpoints';
import forgeHitLink from './helpers/forgeHitLink';
import isMatchingQuery from './helpers/isMatchingQuery';
import getHighlightTitle from './helpers/getHighlightTitle';
import { FaqHit } from './types';

type Props = {
  searchEngine: FormattedAlgoliaConfig;
  bigram: string;
  pageLang: string;
  backgroundColor: string;
  opened: boolean;
  logoUrl: string;
  textColor: string;
  mainColor: string;
  companyId: string | null;
  smartFaqUuId: string | null;

  closeNav: () => void;
  onCloseRequest: () => void;
} & ResponsiveHocProps;

function SearchEngine({
  searchEngine,
  bigram,
  smartFaqUuId,
  companyId,
  pageLang,
  backgroundColor,
  opened,
  logoUrl,
  textColor,
  mainColor,
  closeNav,
  onCloseRequest,
  mediaQueries
}: Props) {
  const { t } = useTranslation();
  const hasShortcuts = searchEngine.shortcuts
    ? searchEngine.shortcuts.length > 0
    : false;

  const [state, dispatch] = useReducer(reducer, getInitialState(hasShortcuts));
  const searchResultsPanel = useRef<HTMLDivElement | null>(null);
  const searchboxWrapper = useRef<HTMLDivElement | null>(null);

  const fixSearchResultsPanelHeight = useCallback(() => {
    if (searchResultsPanel.current) {
      if (state.showResults) {
        const headerHeight = mediaQueries.toTablet
          ? HEADER_MOBILE_HEIGHT
          : HEADER_DESKTOP_HEIGHT;

        searchResultsPanel.current.style.height = `${
          window.innerHeight - headerHeight + SEARCH_ENGINE_MODAL_BOTTOM_PADDING
        }px`;
      } else {
        searchResultsPanel.current.style.height = 'auto';
      }
    }
  }, [mediaQueries, state.showResults]);

  useEffect(() => {
    // prevent background body scroll while results panel is open
    document.body.style.overflowY = opened ? 'hidden' : 'auto';

    // reset search engine on opening and closing
    dispatch({
      type: ActionType.RESET,
      hasShortcuts
    });

    // fix results panel height when opening
    fixSearchResultsPanelHeight();
  }, [opened, searchEngine.shortcuts?.length]);

  useEffect(() => {
    const resizeHandler = debounce(() => fixSearchResultsPanelHeight(), 350);

    // fix results panel height on screen resize
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [fixSearchResultsPanelHeight]);

  useEffect(() => {
    if (state.showResults || state.showShortcuts) {
      fixSearchResultsPanelHeight();
    }
  }, [state.showResults, state.showShortcuts, fixSearchResultsPanelHeight]);

  // algolia search client but tapping in search requests and debouncing effect

  const algoliaClient = useMemo(() => {
    const searchClient = algoliasearch(searchEngine.appId, searchEngine.apiKey);

    return {
      ...searchClient,
      search: (requests: readonly MultipleQueriesQuery[]) => {
        if (requests[0].params?.query !== state.searchValue) {
          dispatch({
            type: ActionType.SEARCH,
            // there's only one searchbox so no risk for the query to be different in other requests
            value: requests[0].params?.query as string
          });
        }

        const isSearchLessThanTwoChars =
          !requests[0].params?.query ||
          requests[0].params.query.length < SEARCHBOX_MIN_CHARACTERS_REFINE;

        // return empty results when searching string of less than two characters
        if (isSearchLessThanTwoChars) {
          algoliaClient.clearCache();

          return Promise.resolve({
            results: requests.map(() => INDEX_EMPTY_RESULTS)
          });
        }
        const areIndicesHidden =
          state.resultsDisplayMode === 'complete' &&
          state.indexFilters.length > 0 &&
          state.indexFilters.length < searchEngine.indices.length;
        const visibleIndicesRequests = areIndicesHidden
          ? requests.filter(req => state.indexFilters.includes(req.indexName))
          : requests;

        const response = searchClient.search<FaqHit>(
          visibleIndicesRequests.map(req =>
            // if the index is the FAQ index, updating filters on the fly to fit index fields
            INDEX_NAMES.includes(req.indexName)
              ? {
                  ...req,
                  params: {
                    ...req.params,
                    facetFilters: undefined,
                    filters: `question.category.thematic.company.id: "${companyId}" AND NOT question.disabled_zones_ids: "${smartFaqUuId}"`
                  }
                }
              : req
          )
        );

        return response.then(({ results }) => {
          const maxPagesForIndex = Math.max(
            ...results.map(index => index.nbPages)
          );

          dispatch({
            type: ActionType.RECEIVING_SEARCH_RESULTS,
            maxPagesForIndex
          });

          return Promise.resolve({
            results: requests.map(req => {
              // for requests not made because hidden, returning empty results
              const res =
                results.find(res => res.index === req.indexName) ??
                INDEX_EMPTY_RESULTS;
              // if the index is the FAQ index, formatting results to fit with expected hits
              if ('index' in res && INDEX_NAMES.includes(req.indexName)) {
                return {
                  ...res,
                  hits: res.hits.map(hit => ({
                    objectID: hit.objectID,
                    title: hit?.question?.label,
                    _highlightResult: {
                      title: {
                        matchedWords: req.params!.query
                          ? isMatchingQuery(
                              req.params!.query,
                              hit.question!.label
                            )
                          : [],
                        value:
                          req.params!.query &&
                          getHighlightTitle(
                            req.params!.query,
                            hit.question!.label
                          )
                      }
                    },
                    link: forgeHitLink(
                      `/{faqRoot}/{thematic}/{category}/{question}`,
                      {
                        faqRoot: FAQ_ROOT,
                        thematic: hit.question!.category.thematic.slug,
                        category: hit.question!.category.slug,
                        question: hit.question!.slug
                      }
                    )
                  }))
                };
              }

              return res;
            })
          });
        });
      }
    };
  }, [searchEngine.appId, searchEngine.apiKey, companyId]);

  const indexFiltersOptions = useMemo(
    () =>
      searchEngine.indices.map(index => ({
        label: index.searchResultsLabel,
        value: index.indexName,
        checked: state.indexFilters.includes(index.indexName)
      })),
    [searchEngine.indices, state.indexFilters]
  );

  const handleCloseSearchEngine = useCallback(() => {
    onCloseRequest();
    algoliaClient.clearCache();
  }, [algoliaClient, onCloseRequest]);

  const handleClickOutside = useCallback(
    event => {
      // we don't want to close the search engine on click outside on mobile
      if (window.matchMedia(`(max-width: ${breakpoints.tablet})`).matches) {
        return;
      }

      // as some elements might be hidden depending on viewport width
      const visibleEngineElements = [
        searchResultsPanel,
        searchboxWrapper
      ].filter(el => Boolean(el.current));

      if (
        visibleEngineElements.some(el => el.current!.contains(event.target))
      ) {
        return;
      }

      handleCloseSearchEngine();
    },
    [handleCloseSearchEngine]
  );

  useOnClickOutside(searchResultsPanel, handleClickOutside);
  useOnClickOutside(searchboxWrapper, handleClickOutside);

  const handleSeeAllResultsButtonClick = useCallback(
    (indexName?: string) =>
      dispatch({ type: ActionType.CLICK_SEE_ALL_RESULTS, indexName }),
    [dispatch]
  );

  const handleIndexFilterToggle = useCallback(
    (indexName: string) =>
      dispatch({ type: ActionType.TOGGLE_INDEX_REFINEMENT, indexName }),

    [dispatch]
  );

  const handleIndexFiltersClear = useCallback(
    () => dispatch({ type: ActionType.CLEAR_INDEX_REFINEMENT }),
    [dispatch]
  );

  // memoizing results panel to prevent infinite call on `algoliaClient.search`
  const resultsPanel = useMemo(() => {
    return (
      <SearchEnginePanelWrapper data-testid="search-engine-results-panel">
        <SearchEnginePanelWidthHolder>
          <SearchEnginePanel ref={searchResultsPanel}>
            <>
              <StickyBorderTop borderColor={mainColor} />

              {state.resultsDisplayMode === 'simple' ? (
                <SearchResultsGrid
                  searchEngine={searchEngine}
                  onSeeAllResultsButtonClick={handleSeeAllResultsButtonClick}
                  onHitClick={handleCloseSearchEngine}
                  showResults={state.showResults}
                  showShortcuts={state.showShortcuts}
                />
              ) : (
                <RefinableSearchResults
                  mainColor={mainColor}
                  searchEngine={searchEngine}
                  searchValue={state.searchValue}
                  indexFiltersOptions={indexFiltersOptions}
                  indexFiltersValues={state.indexFilters}
                  onIndexFilterToggle={handleIndexFilterToggle}
                  clearIndexFilters={handleIndexFiltersClear}
                  maxPagesForIndex={state.maxPagesForIndex || 1}
                  onHitClick={handleCloseSearchEngine}
                />
              )}
            </>
          </SearchEnginePanel>
        </SearchEnginePanelWidthHolder>
      </SearchEnginePanelWrapper>
    );
  }, [
    searchEngine,
    mainColor,
    state.resultsDisplayMode,
    state.searchValue,
    state.indexFilters,
    state.maxPagesForIndex,
    state.showShortcuts,
    state.showResults,
    indexFiltersOptions,
    handleSeeAllResultsButtonClick,
    handleIndexFilterToggle,
    handleIndexFiltersClear,
    handleCloseSearchEngine
  ]);

  return (
    <Modal
      backgroundColor="transparent"
      modalProps={{
        isOpen: opened,
        style: {
          overlay: {
            zIndex: 300,
            backgroundColor: 'rgba(0, 0, 0, 0.70)'
          },
          content: { transform: 'none' }
        }
      }}
    >
      <InstantSearch
        // `indexName` value here has no real impact on the search as we use Index
        // for multiple-indices search, just setting the first actual visible index for "consistency"
        indexName={
          state.indexFilters.length === 1
            ? state.indexFilters[0]
            : searchEngine.indices[0].indexName
        }
        searchClient={algoliaClient}
      >
        <Configure
          facetFilters={[`area:${bigram}`, `lang:${pageLang}`]}
          highlightPreTag={`<em class="${SEARCH_HIGHLIGHT_CLASS}">`}
          highlightPostTag={`</em>`}
        />

        <InnerModal
          backgroundColor={backgroundColor}
          data-testid="search-engine-modal"
        >
          <Content>
            <Top>
              <Logo imageUrl={logoUrl} linkUrl="/" onClick={closeNav} t={t} />
            </Top>

            <InputWrapper ref={searchboxWrapper}>
              <SearchIcon width="18px" height="18px" color={mainColor} />
              <SearchBox
                withBottomRadius={!state.showResults && !state.showShortcuts}
                mainColor={mainColor}
              />

              <SearchBoxCloseButton
                color={textColor}
                hoverColor={mainColor}
                onClick={handleCloseSearchEngine}
              >
                <Icon name="Close" width="16px" height="16px" />
              </SearchBoxCloseButton>
            </InputWrapper>

            {(state.showResults || state.showShortcuts) && resultsPanel}
          </Content>
        </InnerModal>
      </InstantSearch>

      {state.showResults && state.resultsDisplayMode === 'simple' && (
        <MobileSeeAllResultsButtonWrapper>
          <SeeAllResultsButton
            data-testid="search-engine-mobile-see-all-results-button"
            onClick={() => handleSeeAllResultsButtonClick()}
            theme={searchEngine.seeAllResultsButtonsTheme}
          >
            <SeeAllResultsButton.children.Text>
              {/* TODO : change when searchEngine.mobileSeeAllResultsButtonLabel is ready*/}
              {searchEngine.mobileSeeAllResultsButtonLabel === 'mobileLabel'
                ? t(`${searchEngine.mobileSeeAllResultsButtonLabel}`)
                : searchEngine.mobileSeeAllResultsButtonLabel}
            </SeeAllResultsButton.children.Text>
          </SeeAllResultsButton>
        </MobileSeeAllResultsButtonWrapper>
      )}
    </Modal>
  );
}

export default ResponsiveHoc(SearchEngine);
