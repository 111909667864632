/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from '../types';
import {
  Wrapper,
  HeadingsWrapper,
  Title,
  Subtitle
} from '../../shared/styles/components';
import { Lock } from 'shared/components/presentational/Icons/components';
import TunnelSummary from '../../shared/components/TunnelSummary';
import { sendEvent } from 'shared/helpers/tracking/sendEvent';

const STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed'
};

export default function SubscriptionEndProcess({
  buttons,
  visaStatus,
  operatorName
}: Props) {
  const { t } = useTranslation();
  const isPaymentSuccess = visaStatus === 'ACCEPT';

  React.useEffect(() => {
    if (visaStatus) {
      sendEvent('renew subscription', {
        event_name: 'renew subscription',
        payment_method: 'CB',
        operator: operatorName,
        status: isPaymentSuccess ? STATUS.SUCCESS : STATUS.FAILED
      });
    }
  }, [visaStatus, isPaymentSuccess]);

  // TODO: Uncomment when we are able to know which offer was subscribed
  // const numMaterial = subscriberData?.listOfContracts?.find(
  //   contract => contract.numContract === numContract
  // )?.listOfMaterials[0]?.numMaterial;

  // const {
  //   data: checkAccountData,
  //   error: checkAccountError
  // } = useCheckAccountQuery(
  //   apis,
  //   {
  //     numMaterial,
  //     currency,
  //     CPAYScode,
  //     operatorName
  //   },
  //   '1'
  // );

  // if (subscriberDataError || checkAccountError) {
  //   return (
  //     <Wrapper data-testid="subscription-end-process-error">
  //       <Error message={subscriberDataError || checkAccountError.message} />
  //     </Wrapper>
  //   );
  // }

  if (!visaStatus) {
    return null;
  }

  return (
    <Wrapper data-testid="subscription-end-process">
      <HeadingsWrapper>
        <Title>
          {isPaymentSuccess
            ? t('blocks.selfcare.subscriptionTunnel.recap.title')
            : t('blocks.selfcare.subscriptionTunnel.recap.oops')}
        </Title>
        <Subtitle>
          <Lock />
          <span>{t('blocks.selfcare.subscriptionTunnel.subtitle')}</span>
        </Subtitle>
      </HeadingsWrapper>

      <TunnelSummary
        summaryContext="payment-recap"
        paymentRecapSuccess={isPaymentSuccess}
        recapButtons={buttons}
        // TODO: Uncomment when we are able to know which offer was subscribed
        hideSubscriptionInfos={true}
        // subscriberData={subscriberData}
        // resubscriptionNumber={numMaterial}
        // actualOffer={checkAccountData?.mainOffer}
        // actualOptions={checkAccountData?.optionsList}
        // actualDuration={checkAccountData?.duration}
        // actualAmount={checkAccountData?.amount}
        // currency={checkAccountData?.currency}
      />
    </Wrapper>
  );
}
