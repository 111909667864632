import React, { PureComponent } from 'react';
import styled from 'styled-components';

import BaseButton from 'shared/components/presentational/Button';

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
`;

class Button extends PureComponent {
  render() {
    const { theme, label, href } = this.props;

    return (
      <Wrapper>
        <BaseButton theme={theme} to={href}>
          <BaseButton.children.Text>{label}</BaseButton.children.Text>
        </BaseButton>
      </Wrapper>
    );
  }
}

export default Button;
