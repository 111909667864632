/**
 * Filter and returns the given blocks list against the given hiddenBlocks config.
 * Filtering is made based on the device type.
 */
export default function filterHiddenBlocks(
  blocks: { type: string }[],
  hiddenBlocks: { mobile?: string[]; desktop?: string[] } | undefined,
  isMobile: boolean
) {
  if (!hiddenBlocks) {
    return blocks;
  }

  const hiddenBlocksKey = isMobile ? 'mobile' : 'desktop';

  return blocks.filter(
    block => !hiddenBlocks[hiddenBlocksKey]?.includes(block.type)
  );
}
