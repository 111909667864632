import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import objectSupport from 'dayjs/plugin/objectSupport';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/fr';
import 'dayjs/locale/my';
import 'dayjs/locale/am';
import 'dayjs/locale/vi';
import 'dayjs/locale/de';

export default function init() {
  dayjs.extend(utc);
  dayjs.extend(timezone); // timezone depends on the utc plugin
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(objectSupport);
  dayjs.extend(customParseFormat);
}
