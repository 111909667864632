import take from 'lodash/take';

import * as GuideTVAction from './actions';
import { PER_PAGE } from './constants';
import { TASK_TYPE } from 'shared/modules/tasks/constants';
import { createTemplate } from 'shared/helpers/template';
import getCurrentZone from 'shared/modules/page/selectors/getCurrentZone';
import { getDayIndex } from './helpers';
import getFilteredChannelsByZapNumber from './selectors/getFilteredChannelsByZapNumber';
import getFirstBlockWithNamespace from 'shared/modules/page/selectors/getFirstBlockWithNamespace';
import getPentagramFromState from 'shared/modules/page/selectors/getPentagramFromState';
import hasBlocks from 'shared/modules/page/selectors/hasBlocks';
import { namespace } from './index';
import propsFormatter from './propsFormatter';

export default ({ dispatch, getState }) => [
  {
    pattern: '/:bigram([a-zA-Z]{2})?/guide-tv-:period*/:dayIndex?',
    [TASK_TYPE.PREFETCH_DATA]: async () => {
      const state = getState();

      if (hasBlocks(state)) {
        const block = getFirstBlockWithNamespace(state, namespace);

        if (block) {
          const { day, keptChannels, channelsApi } = propsFormatter(
            block.content
          );

          const location = getCurrentZone(state);
          const zone = getPentagramFromState(state);
          const dayIndex = getDayIndex(day);
          const formatedChannelsQueryUrl = createTemplate(channelsApi.url)({
            location,
            zone
          });
          channelsApi.url = formatedChannelsQueryUrl;
          await dispatch(GuideTVAction.fetchChannelsIfNeeded(channelsApi));

          const channels = getFilteredChannelsByZapNumber(getState(), {
            keptChannels
          });

          const firstChannels = take(Object.values(channels), PER_PAGE * 2);

          await dispatch(
            GuideTVAction.fetchProgramsForChannels(
              dayIndex,
              firstChannels,
              channelsApi.isNew
            )
          );
        }
      }
    }
  }
];
