/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import get from 'lodash/get';

import { ACTION_TYPES } from '../constants';
import concatData from 'shared/blocks/vod/hodor/helpers/concatData';

// -----------------------------------------------------------------------------
// EXPORTED REDUCER STATE TYPE
/*
export type State = {
  baseLint: null | string,
  path: null | string,
  authenticate: {
    token: null | string,
    arborescence: []
  },
  data: {},
  errors: {},
  requested: {}
}; */

// -----------------------------------------------------------------------------
// PRIVATES

const defaultState = {
  baseLink: null,
  currentPath: null,
  authenticate: {
    token: null,
    arborescence: []
  },
  data: {},
  errors: {},
  requested: {}
};

export default function hodor(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_CURRENT_PATH: {
      return {
        ...state,
        path: action.payload.path
      };
    }
    case ACTION_TYPES.SET_BASE_LINK: {
      return {
        ...state,
        baseLink: action.payload.baseLink
      };
    }
    case ACTION_TYPES.AUTH_VOD_SUCCESS: {
      return {
        ...state,
        authenticate: action.payload.data
      };
    }
    case ACTION_TYPES.AUTH_VOD_ERROR: {
      return {
        ...state,
        authenticate: {
          ...defaultState.authenticate,
          error: get(
            action.payload.error,
            'response.data.text',
            'An error was occured.'
          )
        }
      };
    }
    case ACTION_TYPES.FETCH_VOD_PATH_REQUEST: {
      return {
        ...state,
        requested: {
          ...state.requested,
          [action.payload.url]: true
        }
      };
    }
    case ACTION_TYPES.FETCH_VOD_PATH_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.url]: action.payload.data
        },
        requested: {
          ...state.requested,
          [action.payload.url]: false
        },
        errors: {
          ...state.errors,
          [action.payload.url]: false
        }
      };
    }
    case ACTION_TYPES.FETCH_VOD_PATH_ERROR: {
      return {
        ...state,
        requested: {
          ...state.requested,
          [action.payload.url]: false
        },
        errors: {
          ...state.errors,
          [action.payload.url]: get(
            action.payload.error,
            'response.data.text',
            'An error was occured.'
          )
        }
      };
    }
    case ACTION_TYPES.FETCH_MORE_VOD_SUCCESS: {
      const concatenatedData = concatData(
        state.data[action.payload.originUrl],
        action.payload.data
      );

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.originUrl]: concatenatedData
        },
        errors: {
          ...state.errors,
          [action.payload.originUrl]: false
        }
      };
    }
    case ACTION_TYPES.FETCH_MORE_VOD_ERROR: {
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.originUrl]: get(
            action.payload.error,
            'response.data.text',
            'An error was occured.'
          )
        }
      };
    }

    default:
      return state;
  }
}
