import { keyframes } from 'styled-components';

export const COLORS = {
  white: '#ffffff',
  concrete: '#f2f2f2',
  mercury: '#e8e8e8',
  alto: '#ddd',
  gainsboro: '#dcdcdc',
  gray: '#cccccc',
  silverChalice: '#a2a2a2',
  lightgray: '#F5F5F5',
  lightgraySecondary: '#EBEBEB',
  boulder: '#757575',
  blackRussian: '#22252A',
  sharkGray: '#2d2f34',
  doveGray: '#696969',
  tundora: '#404040',
  mineShaft: '#2c2c2c',
  black: '#000000',
  denim: '#125bc8',
  dodgerBlue: '#14a4fe',
  cerulean: '#00afec',
  fluorescentOrange: '#ffbe00',
  mantisGreen: '#6ddc42',
  japaneseLaurel: '#1da005',
  strongRed: '#f00000',
  red: '#e00000',
  guardsmanRed: '#cc0000',
  valencia: '#d83d3d',
  carnation: '#f2524b',
  amaranth: '#ec3654',
  frenchPass: '#c1dffe',
  torchRed: '#FF002A',
  boulderBis: '#767676',
  paymentGrey: '#e5e5e5',
  gallery: '#F0F0F0',
  blueApash: '#015FCC',
  redApash: '#FFB8BC',
  greyApash: '#EBEBEB',
  myCanal: '#EC3655'
};

export const FONT_FAMILIES = {
  brand: 'var(--brand-font), Hind, sans-serif',
  base: 'Hind, sans-serif',
  new: 'Canal'
};

export const FONT_WEIGHTS = {
  normal: '400', // fallback on 200 for Canal family
  demi: '600', // fallback on 700 for Panton and Orbitron families
  bold: '700' // fallback on 900 for Panton and Orbitron families
};

export const TRANSITIONS = {
  bounce: 'cubic-bezier(.45,1.8,.5,.75)'
};

export const KEYFRAMES = {
  fadeIn: keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `,
  rotate: keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `
};

export const TIMINGS = {
  scrollToAnchor: 500,
  throttlePace: 300
};

export const DIMENSIONS = {
  CONTAINER_MINIMUM_PADDING: 24, // in px
  CONTAINER_MINIMUM_PADDING_TABLET: 120, // in px
  CONTAINER_INNER_WIDTH: 1000, // in px
  LONG_VERTICAL_MARGIN: 40, // in px
  MEDIUM_VERTICAL_MARGIN: 30, // in px
  SMALL_VERTICAL_MARGIN: 20, // in px
  EXTRA_SMALL_VERTICAL_MARGIN: 10 // in px
};

export const BORDER_RADIUS = 4; // in px

export const CONTAINER_MAX_WIDTH_BREAKPOINT =
  DIMENSIONS.CONTAINER_INNER_WIDTH +
  DIMENSIONS.CONTAINER_MINIMUM_PADDING_TABLET * 2;

export const SIXTEEN_NINTHS_HEIGHT_RATIO = 56.25;

export const BUTTON_SIZES = {
  form: 44, // in px
  default: 40 // in px
};
