import axios from 'axios';
import partial from 'lodash/partial';

import formatDetailLink from 'shared/blocks/vod/legacy/shared/helpers/formatDetailLink';
import formatFolderLink from 'shared/blocks/vod/legacy/shared/helpers/formatFolderLink';
import { TEMPLATE_TYPES } from 'shared/blocks/vod/legacy/shared/constants';
import getLinkHref from 'shared/helpers/uri/getLinkHref';
import fetchVisualsSucceed from 'shared/blocks/program-pack/generic/actions/fetchVisualsSucceed';

export default function fetchAutomaticVisualsS(
  id,
  visualsS,
  folderUrl,
  detailUrl
) {
  return async dispatch => {
    try {
      // fetch
      const { data } = await axios.get(visualsS.apiUrl);

      // format
      const linkFormatter = {
        [TEMPLATE_TYPES.DETAIL]: partial(formatDetailLink, detailUrl),
        [TEMPLATE_TYPES.FOLDER]: partial(formatFolderLink, folderUrl)
      };

      const visuals = data.strates[0].contents.map(content => ({
        title: content.title,
        subtitle: content.subtitle,
        imageUrl: content.URLImage,
        linkUrl: getLinkHref({
          href: linkFormatter[content.type](content.onClick.URLPage),
          rel: 'self'
        })
      }));

      // dispatch
      return dispatch(fetchVisualsSucceed(id, visuals));
    } catch (error) {
      return null;
    }
  };
}
