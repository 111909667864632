import { replace } from 'redux-first-history';

import fetchPage from 'shared/modules/page/api/fetchPage';
import setError from 'shared/modules/error/actions/setError';
import fetchSuccess from './fetchSuccess';
import fetchRequest from './fetchRequest';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import getHostname from 'shared/modules/hostname/selectors/getHostname';
import { getDomainURL } from 'shared/modules/hostname/selectors/getDomainURL';
import isConnected from 'shared/helpers/auth/isConnected';
import isExternalURI from 'shared/helpers/uri/isExternalURI';
import clientCookies from 'client/cookies';
import createLoginRedirectUrl from 'shared/modules/page/helpers/createLoginRedirectUrl';
import getSilentLoginUrl from '../helpers/getSilentLoginUrl';
import getLoginUrlFromPageWithFallback from '../helpers/getLoginUrlFromPageWithFallback';
import createSilentLoginRedirectUrl from '../helpers/createSilentLoginRedirectUrl';
import getParamFromPath from '../helpers/getParamFromPath';
import { PAGE_TYPE } from '../constants';
import hasDisplayPageParams from 'shared/blocks/articles/shared/helpers/hasDisplayPageParams';

export default (path, request, response) => async (
  dispatch,
  getState,
  { uniqueId }
) => {
  const getCookie = name => {
    try {
      return request.universalCookies.get(name);
    } catch (e) {
      return clientCookies.get(name);
    }
  };

  const getCookies = () => {
    try {
      return request.universalCookies.getAll();
    } catch (e) {
      return clientCookies.getAll();
    }
  };

  const redirect = url => {
    try {
      response.redirect(url);
    } catch (e) {
      isExternalURI(url)
        ? window.location.replace(url)
        : dispatch(replace(url));
    }
  };
  const hostname = getHostname(getState());

  // We don't want to take into account the lang in cookies
  // if user has not already visited a given zone
  // This allows to use the default lang of the first zone chosen by the user
  // instead of the default lang of the default zone
  const zone = getCookie(COOKIE_KEYS.ZONE);
  const targetLang = zone ? getCookie(COOKIE_KEYS.LANG) : undefined;

  dispatch(fetchRequest(path, targetLang));
  const userIsConnected = isConnected(getCookies());

  try {
    const data = await fetchPage(path, hostname, {
      uniqueId,
      lang: targetLang,
      userIsConnected
    });

    if (!data.blocks) {
      return Promise.reject(new Error('no blocks on page'));
    }

    /**
     * Redirect to silent login if passIdParam
     */
    const passIdParam = getParamFromPath(path, 'token');
    const sessionTokenParam = getParamFromPath(path, 'sessionToken');
    const updateEmailToken = getParamFromPath(path, 'updateEmailToken');
    const silentLoginUrl = getSilentLoginUrl(data);
    const loginUrl = getLoginUrlFromPageWithFallback(data);

    if (passIdParam || sessionTokenParam) {
      if (data.type !== PAGE_TYPE.SILENT_LOGIN) {
        // if already on silent authentication page just passthrough in order to perform client side createToken
        // else redirect to silent authentication page
        redirect(createSilentLoginRedirectUrl(silentLoginUrl, loginUrl, path));
        return true;
      }
    }

    /**
     * Redirect to silent login if go to login while already connected
     * This strategy aims to refresh cookies when coming from Canal+ (canalplus.com) website to authenticate.
     */
    if (data.type === PAGE_TYPE.LOGIN && userIsConnected && !updateEmailToken) {
      redirect(createSilentLoginRedirectUrl(silentLoginUrl, loginUrl, path));
      return true;
    }

    /**
     * Redirect to login page if authentication is needed for requested page
     */
    if (
      data.authentication &&
      data.authentication.authenticated &&
      !userIsConnected
    ) {
      const domainURL = getDomainURL(getState(), data.site?.domain?.brand);
      redirect(createLoginRedirectUrl(domainURL, zone, data, path, undefined));
      return true;
    }

    /**
     * On client side, do not replace current page if target page is an article
     * (The articles list block on the current page is able to display the article in its modale),
     * unless it comes from the algolia search (with a router search set to display=page).
     * TODO: check if current page contain an articles list block ?
     */
    const displayPage = hasDisplayPageParams(getState());
    if (
      data.type === PAGE_TYPE.ARTICLE &&
      typeof window !== 'undefined' &&
      !displayPage
    ) {
      return false;
    }

    dispatch(fetchSuccess({ path, data, lang: targetLang }));
    return false;
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};
