import React, { PureComponent, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import Loader from './Loader';
import { Props as ButtonProps } from '../Button';
import styles from '../styles';

const Button = styled.button`
  ${styles};
`;

interface Props extends ButtonProps {
  type?: 'submit' | 'reset' | 'button';
}

export default class ButtonDefault extends PureComponent<Props> {
  public render(): ReactNode {
    const { children, loading, disabled, ...props } = this.props;
    const mustBeDisabled = loading || disabled;

    return (
      <Button {...props} disabled={mustBeDisabled}>
        {React.Children.map(children, child =>
          child
            ? React.cloneElement(
                child as ReactElement<Props & { visible: boolean }>,
                {
                  visible: !loading
                }
              )
            : undefined
        )}
        {loading && <Loader />}
      </Button>
    );
  }
}
