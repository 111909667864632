/**
 * Constants represents sizes according to different breakpoints.
 * Because mea blocs are thought "mobile-first", the smallest sizes can be written without breakpoint in their name.
 */
import { DIMENSIONS } from 'shared/constants/theme';

export const SLIDE_HORIZONTAL_GAP = 10; // in px

export const SLIDE_HORIZONTAL_GAP_TABLET = 24; // in px

export const SLIDE_HORIZONTAL_GAP_DESKTOP = 40; // in px

export const SLIDE_THUMBNAIL_HEIGHT_PHONE = 120; // in px (150 on mockups)

export const TWO_SLIDES_THUMBNAIL_RATIO = 1.88; // based on the desktop ratio

export const TWO_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_TABLET = 2.18;

export const TWO_SLIDES_THUMBNAIL_HEIGHT_DESKTOP = 250;

export const THREE_SLIDES_THUMBNAIL_RATIO = 1.7; // based on the desktop ratio

export const THREE_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_TABLET = 3.18;

export const THREE_SLIDES_THUMBNAIL_WIDTH_ESTIMATION_PHONE = 2.18;

export const THREE_SLIDES_THUMBNAIL_HEIGHT_DESKTOP = 176; // in px (244 on mockups)

export const ARROW_BOX_WIDTH =
  DIMENSIONS.CONTAINER_MINIMUM_PADDING_TABLET - SLIDE_HORIZONTAL_GAP_TABLET;

export const ARROW_BOX_WIDTH_DESKTOP =
  DIMENSIONS.CONTAINER_MINIMUM_PADDING_TABLET - SLIDE_HORIZONTAL_GAP_DESKTOP;

export const BREAKPOINT_SLIDER_THREE_SLIDE = 480; // in px

export const HALF_OF_CONTAINER_INNER_WIDTH =
  DIMENSIONS.CONTAINER_INNER_WIDTH / 2; // in px

export const THIRD_OF_CONTAINER_INNER_WIDTH =
  DIMENSIONS.CONTAINER_INNER_WIDTH / 3; // in px

export const TITLE_POSITION = 'left';
