import styled from 'styled-components';

import Picture from 'shared/components/presentational/Picture';
import pxInRem from 'shared/helpers/styled-components/remHelper';

export const SlideThumbnail = styled(Picture)`
  object-fit: cover;
  width: 100%;
`;

export const SlideHeading = styled.div`
  margin: ${pxInRem(12)} 0;
`;

export const SlideWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
