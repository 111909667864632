/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*       */

import React from 'react';

import {
  AVAILABLE_RATINGS,
  CSA_MAPPING_SVG
} from 'shared/components/presentational/RatingCSAIcons/constants';

const hasRating = number => AVAILABLE_RATINGS.includes(number);
const RatingCSAIcons = ({ rating }) => {
  if (!hasRating(rating)) return null;

  const CSAIcon = CSA_MAPPING_SVG[rating];
  return <CSAIcon className="RatingCSA" />;
};

export default RatingCSAIcons;
