import { useEffect, useState, useCallback } from 'react';

import { throttle } from '../helper/throttle';
import { FormatedLabeledLink } from '../types';

export enum DropdownTriggerPositions {
  NOT_YET_DETERMINED = -2,
  NOT_USED = -1
}
const EXPANDABLE_MENU_THROTTLE_DURATION = 250; // ms

export const useSplitIndex = (
  activeIndex: number,
  navigationMenu: FormatedLabeledLink[],
  menuParentRef: React.RefObject<HTMLElement>,
  visibleListRef: React.RefObject<HTMLElement>,
  measurableListRef: React.RefObject<HTMLElement>
) => {
  /* Special fix for intergalactic bug 2.0 (see the old Navigation sharedcomponent for 1.0).
     Basically, there was an inconsistency between the SSR & CSR, which caused a wrong
     css classname to be applied, which broke the UI.  This is fixed by only rendering
     the visible list once we are clientside and after the first render. */
  const [isInitiatedClientSide, setIsInitiatedClientSide] = useState(false);
  useEffect(() => {
    setIsInitiatedClientSide(true);
  }, []);

  // splitIndex determines what goes in the horizontal and dropdown lists
  const [splitIndex, setSplitIndex] = useState<
    number | DropdownTriggerPositions
  >(
    isInitiatedClientSide
      ? DropdownTriggerPositions.NOT_USED
      : DropdownTriggerPositions.NOT_YET_DETERMINED
  );

  const getMenuParentWidth = useCallback(() => {
    if (menuParentRef && menuParentRef.current) {
      return menuParentRef.current.offsetWidth;
    }
    return 0;
  }, [menuParentRef]);

  const getVisibleMenuWidth = useCallback(() => {
    if (visibleListRef && visibleListRef.current) {
      return visibleListRef.current.offsetWidth;
    }
    return 0;
  }, [visibleListRef]);

  const getActiveElement = useCallback(() => {
    const listElement = visibleListRef.current;
    if (listElement && activeIndex >= 0) {
      return listElement.children[activeIndex];
    }
    return null;
  }, [visibleListRef, activeIndex]);

  // Determines where the menu needs to split its contents, if they're too long to all fit
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateSplitIndex = useCallback(
    throttle(() => {
      if (
        menuParentRef &&
        menuParentRef.current &&
        measurableListRef &&
        measurableListRef.current
      ) {
        const menuWidth = getMenuParentWidth();
        const menuItems: Element[] = Array.from(
          measurableListRef.current.children
        );

        const dropdownTriggerEl = menuItems.pop(); // In the measurable list, the dropdown trigger is the last element
        const dropdownTriggerWidth = dropdownTriggerEl
          ? dropdownTriggerEl.clientWidth
          : 0;
        const menuItemWidths = menuItems.map(el => el.clientWidth);

        // Dropdown is used when all menu items do not fit within the menu width
        const isDropdownNeeded =
          menuItemWidths.reduce((total, num) => total + num, 0) > menuWidth;
        if (isDropdownNeeded) {
          // Find where to insert the dropdown trigger into the list of menu items so it's as far right as possible
          let childrenWithDropdownWidth = dropdownTriggerWidth;
          for (let i = 0; i < menuItemWidths.length; i += 1) {
            childrenWithDropdownWidth += menuItemWidths[i];
            if (childrenWithDropdownWidth >= menuWidth) {
              if (i !== splitIndex) {
                setSplitIndex(i);
              }
              break;
            }
          }
        } else if (
          !isDropdownNeeded &&
          splitIndex !== DropdownTriggerPositions.NOT_USED
        ) {
          setSplitIndex(DropdownTriggerPositions.NOT_USED);
        }
      }
    }, EXPANDABLE_MENU_THROTTLE_DURATION),
    [measurableListRef, navigationMenu, splitIndex]
  );
  // On mount and when layout mode changes: Measure elements and determine splitIndex
  useEffect(() => {
    if (
      !isInitiatedClientSide &&
      splitIndex !== DropdownTriggerPositions.NOT_USED
    ) {
      setSplitIndex(DropdownTriggerPositions.NOT_USED);
    } else if (isInitiatedClientSide) {
      calculateSplitIndex();
    }
  }, [calculateSplitIndex, isInitiatedClientSide, splitIndex]);

  // Recalculate splitIndex whenever screen size changes
  useEffect(() => {
    if (isInitiatedClientSide) {
      window.addEventListener('resize', calculateSplitIndex);
      return () => {
        window.removeEventListener('resize', calculateSplitIndex);
      };
    }
    return;
  });

  const scrollToActiveMenuItem = useCallback(() => {
    const listElement = visibleListRef.current;
    const activeItem = getActiveElement();
    if (listElement && activeItem) {
      const menuWidth = getVisibleMenuWidth();
      const {
        clientWidth: activeItemWidth,
        offsetLeft: activeItemOffsetLeft
      } = activeItem as HTMLElement;
      const scrollToPosition =
        activeItemOffsetLeft + activeItemWidth / 2 - menuWidth / 2;
      listElement.scrollLeft = scrollToPosition; // Animation is handled by native css
    }
  }, [getActiveElement, getVisibleMenuWidth, visibleListRef]);

  // Scroll the list to center the active menu item when
  // the activeIndex changes (only when the dropdown feature is disabled)
  useEffect(() => {
    if (!isInitiatedClientSide) {
      scrollToActiveMenuItem();
    }
  }, [activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return splitIndex;
};
