import { useQuery } from 'react-query';
import axios from 'axios';

import { HighlightsData } from 'shared/helpers/eismo/types';
import { Api } from 'shared/types';

const useMeaContentQuery = (api: Api) => {
  return useQuery<HighlightsData, string>(
    api.url,
    async () => {
      return axios.request<HighlightsData>(api).then(response => response.data);
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};

export default useMeaContentQuery;
