import React, { FC } from 'react';

import { Wrapper, Chip } from './styles/components';
import Title from 'shared/components/presentational/Title';

export const Engagment: FC<{
  sticker: string | null;
  definition?: string | null;
  title: string;
}> = ({ sticker, definition, title }) => {
  return (
    <Wrapper>
      <Title
        titleLabel={title.toUpperCase()}
        subtitle={definition}
        titleHtmlTag="h3"
      />
      {sticker && <Chip>{sticker}</Chip>}
    </Wrapper>
  );
};
