import React from 'react';
import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Offer from './Offer';
import { OfferListProps, OfferProps } from '../types';

const OffersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  margin-bottom: 10px;
  ${mediaQueries.toPhablet`
    margin-bottom: 0;
  `};
`;

const StyledOffer = styled(Offer)<OfferProps>`
  width: ${props =>
    props.offer.isStar ? 'calc(50% - 10px)' : 'calc(25% - 10px)'};
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  display: flex;
  ${props => props.tabs && 'padding: 30px; width: calc(33% - 10px);'};
  ${mediaQueries.fromDesktop<OfferProps>`
    ${props => {
      if (props.tabs) {
        return `max-width: 330px;`;
      } else if (props.offer.isStar) {
        return `max-width: 510px;`; // 250px * 2 + 10px (two columns with the space between)
      }
      return `max-width: 250px;`;
    }}
  `}
  ${mediaQueries.toTablet<OfferProps>`
    width: ${props =>
      props.offer.isStar ? 'calc(100% - 10px)' : 'calc(50% - 10px)'};
  `}
  ${mediaQueries.toPhablet`
    margin: 0;
    margin-bottom: 8px;
    width: 100%;
  `};
`;

const OfferList = ({ offers, backgroundColor, tabs }: OfferListProps) => (
  <OffersWrapper>
    {offers.map((offer, index) => (
      <StyledOffer
        key={`${offer.title}-${index}-${offer.isStar ? 'star' : 'unStar'}`}
        offer={offer}
        backgroundColor={backgroundColor}
        tabs={!!tabs}
      />
    ))}
  </OffersWrapper>
);

export default OfferList;
