import { Content, FormattedProps } from './types';

export default (content: Content): FormattedProps => ({
  title: content.title,
  displayBlock: content.displayBlock,
  displayContractSelectList: content.displayContractSelectList || false,
  dataSource: content.datasource,
  apis: {
    getSubscriberData: {
      url: content.apis.getSubscriberData.url ?? '',
      method: content.apis?.getSubscriberData?.method || 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    }
  }
});
