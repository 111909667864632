/* eslint-disable no-unused-vars */
/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
/* eslint-disable no-console */
import ConfigHelper from '../config';

const logConfigKey = process.env.BUILD_TARGET;
const minLogLevel = ConfigHelper.getClientConfig(`log.${logConfigKey}.level`);

/**
 * Return if the given log level should be logged
 *
 * @param {string} level
 * @returns {boolean}
 */
const relevant = level => {
  if (minLogLevel === 'error' && level === 'error') {
    return true;
  }
  if (minLogLevel === 'warn' && (level === 'error' || level === 'warn')) {
    return true;
  }
  if (
    minLogLevel === 'info' &&
    (level === 'error' || level === 'warn' || level === 'info')
  ) {
    return true;
  }
  if (minLogLevel === 'debug') {
    return true;
  }

  return false;
};

const getTimestamp = () => new Date().toISOString();

/**
 * Return a logger instance depending on the application context
 *
 * @returns {*}
 */
const getLogger = () => {
  let logger;
  if (process.env.BUILD_TARGET === 'server') {
    // This is the server, using winston
    // Conditionnal require is mandatory since only node server can handle it
    // eslint-disable-next-line global-require
    const winston = require('winston');
    const { consoleFormat } = require('winston-console-format');

    let transportExtraOptions = {};

    // Improve logs in development
    if (process.env.NODE_ENV === 'development') {
      transportExtraOptions = {
        format: winston.format.combine(
          winston.format.colorize({ all: true }),
          winston.format.padLevels(),
          consoleFormat({
            showMeta: true,
            metaStrip: ['timestamp', 'service'],
            inspectOptions: {
              depth: 2,
              colors: true,
              maxArrayLength: Infinity,
              breakLength: 120,
              compact: Infinity
            }
          })
        )
      };
    }

    // Returning a new winston logger that will be singleton with use of this module
    logger = winston.createLogger({
      exitOnError: false,
      transports: [
        new winston.transports.Console({
          handleExceptions: true,
          level: minLogLevel,
          stderrLevels: ['error'],
          timestamp: () => getTimestamp(),
          ...transportExtraOptions
        })
      ],
      format: winston.format.combine(
        winston.format.json(),
        winston.format.timestamp()
      )
    });
  } else {
    // This is the SPA, using a simple logger
    logger = {
      error(message, metadata = {}) {
        if (!relevant('error')) {
          return;
        }

        console.error(
          `${getTimestamp()} ERROR ${message} ${JSON.stringify(metadata)}`
        );
      },

      warn(message, metadata = {}) {
        if (!relevant('warn')) {
          return;
        }

        console.warn(
          `${getTimestamp()} WARN ${message} ${JSON.stringify(metadata)}`
        );
      },

      info(message, metadata = {}) {
        if (!relevant('info')) {
          return;
        }

        console.info(
          `${getTimestamp()} INFO ${message} ${JSON.stringify(metadata)}`
        );
      },

      debug(message, metadata = {}) {
        if (!relevant('debug')) {
          return;
        }

        console.log(
          `${getTimestamp()} DEBUG ${message} ${JSON.stringify(metadata)}`
        );
      }
    };
  }

  /**
   * Generate a loggable metadata object based on a provided data object
   *
   * This method is building a meta data object by placing some relevant log data at
   * places expected by the explotation team and reported on log dashboards
   *
   *
   * @param {object} data An object whose properties will be added as log metadata
   * @return {object}
   */
  logger.generateMetadata = ({ uniqueId, statusCode, startAt, ...other }) => {
    const metadata = {};

    // Request id is moved to req.uniqueId
    if (uniqueId) {
      metadata.req = { uniqueId };
    }

    // Status code is moved to res.statusCode
    if (statusCode) {
      metadata.res = { statusCode };
    }

    // responseTime is calculated using startAt
    if (startAt) {
      metadata.responseTime = new Date().getTime() - startAt;
    }

    // date is ISO 8601 to be read in the json file
    metadata.date = getTimestamp();

    // Other data properties are merged as if
    return Object.assign(metadata, other);
  };

  /**
   * Generate a loggable metadata object in an API call context
   *
   * @param {string} apiName     The API name (tms, pass, ...)
   * @param {string} serviceName The service used the API (createToken, wsFromPath, ...)
   * @param {object} data
   * @returns {Object}
   */
  logger.generateApiMetadata = (apiName, serviceName, data) =>
    logger.generateMetadata(
      Object.assign(data, {
        service: apiName,
        webservice: serviceName
      })
    );

  return logger;
};

export default getLogger();
