import isArray from 'lodash/isArray';

import getQueryParams from 'shared/modules/router/selectors/getQueryParams';
import getHash from '../getHash';
import getAccessToken from '../hashParams/getAccessToken';

export default (state: any) => {
  const refererUrl = getQueryParams(state).referer;

  if (!refererUrl || isArray(refererUrl)) {
    return null;
  }

  /**
   * Because the server doesn't have access to the hash, the referer can be computed without it for SSR redirection.
   * That means the hash will be on the (silent) login url but not in its referer query param.
   *
   * Example given:
   * - After a client-side redirection: /silent-login?redirect_uri=%2Fpage%23anchor
   * - After a server-side redirection: /silent-login?redirect_uri=%2Fpage#anchor
   *
   * Thus we ensure that the anchor is properly passing through the (silent) login process.
   *
   * This rule should not apply however to hash containing access token (during external authentication).
   */
  const hash = getHash(state);
  const accessToken = getAccessToken(state);

  if (hash && !accessToken) {
    return `${refererUrl}${hash}`;
  }

  return refererUrl;
};
