import { AnyAction } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import { SavedFormData } from '../../shared/types';
import { isSaveOfferSubscriptionFormAction } from '../../shared/actions/saveOfferSubscriptionForm';
import { isClearOfferSubscriptionFormAction } from '../actions/clearOfferSubscriptionForm';

const persistConfig = {
  key: 'eshop/offer-form',
  storage
};

const defaultState: {
  savedFormData: SavedFormData;
} = {
  savedFormData: null
};

const reducer = (
  state: typeof defaultState = defaultState,
  action: AnyAction
): typeof defaultState => {
  if (isSaveOfferSubscriptionFormAction(action)) {
    return {
      savedFormData: action.payload
    };
  }
  if (isClearOfferSubscriptionFormAction(action)) {
    return defaultState;
  }
  return state;
};

export default persistReducer(persistConfig, reducer);
