import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 50 50" width={width} height={height} className={className}>
    <path
      d="M20.4954 16.507C20.8123 16.1824 21.2422 16 21.6905 16C22.1389 16 22.5688 16.1824 22.8857 16.507L29.5046 23.284C29.8218 23.6082 30 24.0482 30 24.5071C30 24.9659 29.8218 25.4059 29.5046 25.7302L22.8828 32.5042C22.2213 33.1696 21.1592 33.1646 20.5037 32.4929C19.8482 31.8213 19.8445 30.7343 20.4954 30.058L25.9206 24.5056L20.4954 18.9533C20.1782 18.629 20 18.189 20 17.7302C20 17.2713 20.1782 16.8313 20.4954 16.507Z"
      fill="currentColor"
    />
  </svg>
);
