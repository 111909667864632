/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';
import Picture from 'shared/components/presentational/Picture';
import BaseRaw from 'shared/components/presentational/Raw/Raw';
import { COLORS } from 'shared/constants/theme';

const Wrapper = styled.div<{ backgroundColor: string }>`
  display: grid;
  grid-template-rows: 6rem 1fr 6rem;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  padding: 20px 55px;
  height: 100%;
`;

const LinkWrapper = styled(Wrapper)<{ backgroundColor: string }>`
  cursor: pointer;
`;

const ImageWrapper = styled.div<{
  width: number;
  height: number;
}>`
  display: flex;
  margin: 5px auto;
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
`;

const Raw = styled(BaseRaw)`
  font-size: 1.2rem;
  margin: 0 auto 5px;
  width: 100%;
  p {
    margin: 0;
  }
`;

const Link = styled.p<{ linkColor: string }>`
  display: flex;
  color: ${props => props.linkColor};
  text-decoration: underline;
  margin: 10px auto;
`;

interface DefaultProps {
  linkTarget?: '_self' | '_blank';
  backgroundColor?: string;
  linkColor?: string;
}

export interface Props extends DefaultProps {
  html?: string;
  linkLabel?: string;
  linkUrl?: string;
  pictureUrl: string;
  pictureAlt?: string;
  pictureWidth?: number;
  pictureHeight?: number;
  pictureWrapperWidth?: number;
  pictureWrapperHeight?: number;
  disableLazy?: boolean;
}

export default class Tile extends PureComponent<Props> {
  public static readonly defaultProps = {
    linkTarget: '_self',
    linkColor: COLORS.carnation,
    backgroundColor: COLORS.concrete
  };

  public render(): React.ReactNode {
    const {
      backgroundColor,
      html,
      linkUrl,
      linkTarget,
      linkLabel,
      linkColor,
      pictureUrl,
      pictureAlt,
      pictureWidth,
      pictureHeight,
      pictureWrapperWidth,
      pictureWrapperHeight,
      disableLazy
    } = this.props;

    if (linkUrl) {
      return (
        <CustomLink to={linkUrl} target={linkTarget}>
          <LinkWrapper backgroundColor={backgroundColor!}>
            {html && <Raw html={html} />}
            {pictureUrl && (
              <ImageWrapper width={pictureWidth!} height={pictureHeight!}>
                <Picture
                  url={pictureUrl}
                  alt={pictureAlt}
                  width={pictureWidth}
                  height={pictureHeight}
                  disableLazy={disableLazy}
                />
              </ImageWrapper>
            )}
            {linkLabel && <Link linkColor={linkColor!}>{linkLabel}</Link>}
          </LinkWrapper>
        </CustomLink>
      );
    }

    return (
      <Wrapper backgroundColor={backgroundColor!}>
        {html && <Raw html={html} />}
        {pictureUrl && (
          <ImageWrapper
            width={pictureWrapperWidth!}
            height={pictureWrapperHeight!}
          >
            <Picture
              url={pictureUrl}
              alt={pictureAlt}
              width={pictureWidth}
              height={pictureHeight}
              disableLazy={disableLazy}
            />
          </ImageWrapper>
        )}
      </Wrapper>
    );
  }
}
