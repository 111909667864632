import styled, { css } from 'styled-components';

import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { a11yContent } from 'shared/constants/mixins';

export const PriceComplement = styled.span<{ withHint: boolean }>`
  display: inline-block;
  vertical-align: ${props => (props.withHint ? 'bottom' : 'top')};
  font-size: 0.32em;
  line-height: calc(0.5em / 0.32);
  margin-left: ${pxInRem(3)};
`;

export const PriceWrapper = styled.div<{
  strikeThrough?: boolean;
  description: string;
}>`
  position: relative;
  white-space: nowrap;
  font-size: ${pxInRem(48)};
  line-height: ${pxInRem(48)};
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${props =>
    props.strikeThrough ? FONT_WEIGHTS.normal : FONT_WEIGHTS.bold};

  ${props =>
    props.strikeThrough &&
    css`
      font-size: ${pxInRem(16)};
      line-height: ${pxInRem(16)};
      ${PriceComplement} {
        font-size: 0.5em;
        line-height: 1em;
      }
      ::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: ${pxInRem(1)};
        background-color: currentColor;
        transform: skew(14deg, -14deg) scaleY(1.2) translateY(-86%);
      }
    `}

  ${props => a11yContent(props.description)}
`;

export const PriceHint = styled.span`
  display: block;
  font-family: ${FONT_FAMILIES.brand};
  font-size: 0.87em;
  margin-left: -0.3em;
`;
