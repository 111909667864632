import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 87 87" width={width} height={height} className={className}>
    <g
      stroke="currentColor"
      strokeWidth={6}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3.4 83.4l40-80 40 80zM43.4 73.4v-1m0-49v40" />
    </g>
  </svg>
);
