import { Link, CustomTitle, TitleHtmlTag } from 'shared/types';

export enum Position {
  ALIGN_LEFT = 'alignLeft',
  ALIGN_RIGHT = 'alignRight',
  ALIGN_CENTER = 'alignCenter'
}

export type FormattedProps = {
  label: string;
  theme: any;
  href: string;
  position: Position;
  title: string;
  titleHtmlTag: TitleHtmlTag;
  titleColor: string;
  subtitle: string;
};

export type Content = {
  title: CustomTitle;
  subtitle: string;
  template: Position;
  button: {
    label: string;
    colorLabel: string;
    backgroundColor: string;
  };
  link: Link;
};
