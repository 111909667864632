/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  ChangeEvent,
  Fragment
} from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Formik, Form, FormikActions } from 'formik';
import * as yup from 'yup';

import { SummaryProps, StateOffer } from '../types';
import { INITIAL_DEFAULT_VALUES } from '../constants';
import {
  Wrapper,
  Button,
  ButtonWrapper,
  AgreementWrapper,
  Checkbox,
  SubscriptionText,
  Cartridge,
  OfferTitle,
  AgreementText,
  AgreementError,
  PriceWrapper,
  SummaryText,
  ErrorWrapper,
  OfferTypeTitle,
  CartridgeWrapper,
  MinorOffers,
  MinorOfferLogo,
  MinorOfferPrice,
  MinorOfferBillingInformation,
  BankInfoLabel
} from '../styles/components';
import InputField from 'shared/components/presentational/Form/Fields/InputField';
import PopIn from 'shared/components/presentational/PopIn';
import Price from 'shared/components/presentational/Price/Price';
import Loader from 'shared/blocks/selfcare/shared/components/Loader';
import Error from 'shared/components/presentational/Error/Error';
import {
  InformationMessageWrapper,
  InformationMessageImage,
  InformationMessage,
  FieldsGrid,
  FieldWrapper
} from 'shared/blocks/eshop/shared/styles/components';
import getPostOffersParams from '../helpers/getPostOffersParams';
import {
  SubmittedAllStepsFormValues,
  SummaryFormValues
} from '../../shared/types';
import { FIELDS } from '../../shared/constants';
import Title from 'shared/components/presentational/Title';

export default function Summary({
  title,
  titleColor,
  titleHtmlTag,
  subscriptionAPI,
  agreementText,
  agreementError,
  agreementColorError,
  newsletterAgreementLabel,
  button,
  mainColor,
  redirectPopin,
  errorPopin,
  selectedOfferAndOption,
  selectedMinorOffers,
  postOffer,
  onSave: handleSave,
  submitSuccess,
  submitPending,
  flush,
  retrieveOfferSubscriptionForm,
  submitError,
  mainOfferSubtitle,
  minorOfferSubtitle,
  informationMessage
}: SummaryProps) {
  const { t } = useTranslation();
  const [offer, setOffer] = useState<StateOffer | undefined>(undefined);
  const [invalidCode, setInvalidCode] = useState<boolean | undefined>(
    undefined
  );
  const [savedFormValues, setSavedFormValues] = useState<
    SubmittedAllStepsFormValues | undefined
  >(undefined);
  const [savedFormValuesPending, setSavedFormValuesPending] = useState(true);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        [FIELDS.IBAN_CODE]: yup
          .string()
          .required(t('common.form.ibanCode.empty'))
          .isIban(),
        [FIELDS.OFFER_TERMS_AGREEMENT]: yup
          .boolean()
          .required(agreementError)
          .oneOf([true], agreementError),
        [FIELDS.REFUSE_NEWSLETTER]: yup.boolean()
      }),
    [agreementError, t]
  );

  useEffect(() => {
    retrieveOfferSubscriptionForm()
      .then(decryptedFormData => {
        // if saved form has been submitted
        if (decryptedFormData.submitted) {
          // use retrieved values
          setSavedFormValues(
            decryptedFormData.values as SubmittedAllStepsFormValues
          );
        }
        // if no values are retrieved, an error is displayed
        setSavedFormValuesPending(false);
      })
      // do nothing if no form data are retrieved
      .catch(() => {
        setSavedFormValuesPending(false);
      });

    if (selectedOfferAndOption === false) {
      setInvalidCode(true);
    } else if (typeof invalidCode === 'undefined') {
      setInvalidCode(false);
      setOffer(selectedOfferAndOption as StateOffer);
    }

    return () => {
      // Reset the store when unmounting the component
      flush();
    };
  }, []);

  const handleSubmit = useCallback(
    (
      values: SummaryFormValues,
      { setTouched }: FormikActions<SummaryFormValues>
    ) => {
      setTouched({ ibanCode: true, offerTermsAgreement: true });

      if (values.offerTermsAgreement && selectedOfferAndOption) {
        const allStepsValues = {
          ...savedFormValues,
          [FIELDS.IBAN_CODE]: values[FIELDS.IBAN_CODE],
          [FIELDS.REFUSE_NEWSLETTER]: values[FIELDS.REFUSE_NEWSLETTER]
        } as SubmittedAllStepsFormValues;

        const postOffersParams = getPostOffersParams(
          subscriptionAPI,
          selectedOfferAndOption,
          selectedMinorOffers || [],
          allStepsValues
        );

        postOffer(postOffersParams);
        handleSave({ submitted: true, values: allStepsValues });
      }
    },
    [
      handleSave,
      postOffer,
      savedFormValues,
      selectedMinorOffers,
      selectedOfferAndOption,
      subscriptionAPI
    ]
  );

  const renderedMinorOffers = useMemo(() => {
    if (selectedMinorOffers && selectedMinorOffers.length) {
      return (
        <MinorOffers>
          {minorOfferSubtitle && (
            <OfferTypeTitle>{minorOfferSubtitle}</OfferTypeTitle>
          )}

          {selectedMinorOffers.map(minorOffer => (
            <Fragment key={minorOffer.code}>
              <Cartridge color={mainColor}>
                <MinorOfferLogo
                  url={minorOffer.imageUrl}
                  alt={minorOffer.imageAlt}
                />

                <MinorOfferPrice html={minorOffer.marketingPrice || ''} />
              </Cartridge>

              {minorOffer.billingInformation && (
                <MinorOfferBillingInformation
                  html={minorOffer.billingInformation}
                />
              )}
            </Fragment>
          ))}
        </MinorOffers>
      );
    }
  }, [selectedMinorOffers, minorOfferSubtitle, mainColor]);

  if (typeof invalidCode === 'undefined' || savedFormValuesPending) {
    return <Loader />;
  }

  if (invalidCode) {
    return (
      <ErrorWrapper>
        <Error message={t('blocks.eshop.summary.invalidCode')}></Error>
      </ErrorWrapper>
    );
  }

  if (typeof offer === 'undefined') {
    return (
      <ErrorWrapper>
        <Error message={t('blocks.eshop.summary.missingCode')}></Error>
      </ErrorWrapper>
    );
  }

  if (typeof savedFormValues === 'undefined') {
    return (
      <ErrorWrapper>
        <Error message={t('blocks.eshop.summary.missingForm')}></Error>
      </ErrorWrapper>
    );
  }

  return (
    <Wrapper>
      <Title
        titleLabel={title}
        titleColor={titleColor}
        titleHtmlTag={titleHtmlTag}
      />
      <CartridgeWrapper>
        <OfferTypeTitle>{mainOfferSubtitle}</OfferTypeTitle>

        <Cartridge color={mainColor}>
          <OfferTitle color={offer.titleColor}>{offer.title}</OfferTitle>

          <PriceWrapper>
            {offer.option.previousPrice && (
              <Price value={offer.option.previousPrice} strikeThrough={true} />
            )}

            <Price
              value={offer.option.priceInteger}
              exponent={offer.option.priceExponent}
              hint={offer.option.priceTerms}
            />
          </PriceWrapper>
        </Cartridge>
      </CartridgeWrapper>

      <SummaryText html={offer.option.text} />

      {renderedMinorOffers}

      <div>
        <Formik
          initialValues={{
            ...INITIAL_DEFAULT_VALUES,
            [FIELDS.IBAN_CODE]: savedFormValues.ibanCode,
            [FIELDS.REFUSE_NEWSLETTER]: savedFormValues.refuseNewsletter
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, errors, setFieldValue, touched, setFieldTouched }) => (
            <Form>
              <FieldsGrid>
                <FieldWrapper fullWidth={true}>
                  <BankInfoLabel
                    label={t('blocks.eshop.offerSubscriptionForm.yourBankInfo')}
                  />
                  <Field
                    onBlur={() => setFieldTouched(FIELDS.IBAN_CODE)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFieldValue(
                        FIELDS.IBAN_CODE,
                        e.currentTarget.value.split(' ').join('')
                      )
                    }
                    component={InputField}
                    name={FIELDS.IBAN_CODE}
                    placeholder={t('common.form.ibanCode.label')}
                    type="text"
                    maxLength={27}
                    isRequired={true}
                  />
                </FieldWrapper>
              </FieldsGrid>

              {offer.option.subscriptionText && (
                <SubscriptionText html={offer.option.subscriptionText} />
              )}

              <AgreementWrapper>
                <Field
                  component={Checkbox}
                  name={FIELDS.OFFER_TERMS_AGREEMENT}
                  isRequired={true}
                  onBlur={() => setFieldTouched(FIELDS.OFFER_TERMS_AGREEMENT)}
                  onChange={() =>
                    setFieldValue(
                      FIELDS.OFFER_TERMS_AGREEMENT,
                      !values[FIELDS.OFFER_TERMS_AGREEMENT]
                    )
                  }
                  checked={Boolean(values[FIELDS.OFFER_TERMS_AGREEMENT])}
                  scale={2}
                  color={mainColor}
                  borderColor={
                    values[FIELDS.OFFER_TERMS_AGREEMENT] === false &&
                    touched.offerTermsAgreement
                      ? agreementColorError
                      : undefined
                  }
                />
                <AgreementText html={agreementText} />

                {errors.offerTermsAgreement && touched.offerTermsAgreement && (
                  <AgreementError color={agreementColorError}>
                    {errors.offerTermsAgreement}
                  </AgreementError>
                )}
              </AgreementWrapper>

              {newsletterAgreementLabel && (
                <AgreementWrapper>
                  <Field
                    onBlur={() => setFieldTouched(FIELDS.REFUSE_NEWSLETTER)}
                    component={Checkbox}
                    name={FIELDS.REFUSE_NEWSLETTER}
                    checked={values[FIELDS.REFUSE_NEWSLETTER]}
                    color={mainColor}
                    onChange={() =>
                      setFieldValue(
                        FIELDS.REFUSE_NEWSLETTER,
                        !values[FIELDS.REFUSE_NEWSLETTER]
                      )
                    }
                  />
                  <AgreementText html={newsletterAgreementLabel} />
                </AgreementWrapper>
              )}

              {informationMessage.message && (
                <InformationMessageWrapper
                  backgroundColor={informationMessage.backgroundColor}
                >
                  {informationMessage.image.imageSrc && (
                    <InformationMessageImage
                      url={informationMessage.image.imageSrc}
                      alt={informationMessage.image.imageAlt}
                    />
                  )}
                  <InformationMessage html={informationMessage.message} />
                </InformationMessageWrapper>
              )}

              <ButtonWrapper>
                <Button
                  type="submit"
                  theme={button.theme}
                  loading={submitPending}
                >
                  <Button.children.Text>{button.label}</Button.children.Text>
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </div>

      <PopIn
        id="confirmationPopin"
        isOpen={submitSuccess}
        text={redirectPopin.text}
        title={redirectPopin.title}
        link={redirectPopin.link}
        picture={redirectPopin.image}
      />

      <PopIn
        id="errorPopin"
        isOpen={Boolean(submitError)}
        text={errorPopin.text}
        title={errorPopin.title}
        link={errorPopin.link}
        picture={errorPopin.image}
      />
    </Wrapper>
  );
}
