/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

export const namespace = 'canal.block.item_grid';
export { default as Component } from './components/ItemGrid';
export { default as propsFormatter } from './propsFormatter';
