import get from 'lodash/get';

import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { COLORS } from 'shared/constants/theme';
import { ButtonApi, Link, PropsFormatterUtils } from 'shared/types';
import { Content, FormattedProps, Offer, OfferList } from './types';

interface ButtonProps {
  button?: ButtonApi;
  getLinkHrefWithAuthentication: (link: Link | '' | null) => string;
}

function getButton({ button, getLinkHrefWithAuthentication }: ButtonProps) {
  if (button) {
    return {
      label: get(button, 'label'),
      theme: themeFactory({
        color: get(button, 'labelColor', COLORS.white),
        backgroundColor: get(button, 'backgroundColor', COLORS.black)
      }),
      link: getLinkHrefWithAuthentication(get(button, 'link', ''))
    };
  }

  return undefined;
}

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => {
  const offerList: OfferList[] = content.tabs.map(group => {
    const offers: Offer[] = group.columns.map(column => {
      return {
        media: column.media
          ? {
              url: column.media.link.href,
              alt: column.media.description ?? ''
            }
          : undefined,
        dailymotionVideoId: column.dailymotionVideoId,
        title: column.title,
        text: column.text
      };
    });

    return {
      title: group.title,
      columns: offers,
      template: group.template
    };
  });

  return {
    title: get(content, 'title.label', ''),
    titleColor: get(content, 'title.color', ''),
    titleHtmlTag: get(content, 'title.htmlTag'),
    subtitle: content.subtitle ?? undefined,
    tabActiveColor: content.color.active,
    tabDefaultColor: content.color.default,
    tabs: offerList,
    button: getButton({
      button: content.button,
      getLinkHrefWithAuthentication
    })
  };
};
