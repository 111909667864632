import { PropsFormatterUtils } from 'shared/types';
import { Content, FormattedProps } from './types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  regularizationLink: getLinkHrefWithAuthentication(
    content?.regularizationLink
  ),
  contractLink: getLinkHrefWithAuthentication(content?.contractLink),
  apis: {
    getSubscriberData: {
      url: content.apis.getSubscriberData?.url ?? '',
      method: content.apis.getSubscriberData?.method || 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    },
    getUnpaidInfos: {
      url: content.apis.getUnpaidInfo?.url ?? '',
      method: content.apis.getUnpaidInfo?.method || 'GET'
    }
  }
});
