import React from 'react';
import styled from 'styled-components';

import { WebsiteLogo as WebsiteLogoInterface } from '../types';
import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';

const HomeLogo = styled.img`
  height: 32px;
`;

export const WebsiteLogo = ({
  websiteLogo
}: {
  websiteLogo: WebsiteLogoInterface;
}) => (
  <CustomLink to={websiteLogo.link}>
    <HomeLogo src={websiteLogo.media.url} alt={websiteLogo.media.alt} />
  </CustomLink>
);
