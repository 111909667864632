import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 109 77" width={width} height={height} className={className}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M.6 75.8V63.4h6.9v2.1H3v2.7h4.3v2.1H3v3.3h4.4v2.1zM19.2 75.8l-7.3-8.4v8.4H9.5V63.4h1.9l7.3 8.3v-8.3h2.4v12.4h-1.9zM29.8 75.8l-3.3-5h-.3v5h-2.4V63.4h3.6c1.4 0 2.5.3 3.2.9.8.6 1.2 1.7 1.2 2.8 0 .8-.3 1.7-.7 2.3-.5.6-1.1 1-2.1 1.2l3.9 5.2h-3.1zm-3-10.4h-.7v3.8h.7c1.6 0 2.4-.7 2.4-1.9 0-1.3-.8-1.9-2.4-1.9zM34.1 75.8V63.4H41v2.1h-4.4v2.7h4.3v2.1h-4.3v3.3H41v2.1zM48.9 76.1c-3.8 0-6.5-2.7-6.5-6.4 0-3.8 2.7-6.5 6.6-6.5 1.5 0 2.8.5 3.9 1.3.5.4.8.8 1.4 1.6l-2.1 1.1c-.4-.5-.5-.8-.9-1.1-.6-.5-1.4-.8-2.3-.8-2.3 0-4.1 1.8-4.1 4.3s1.7 4.2 4.1 4.2c.9 0 1.7-.3 2.3-.8.3-.2.5-.5.6-.8.2-.3.2-.5.3-1h-2.8v-2.1H55v.4c-.1 4-2.4 6.6-6.1 6.6zM56.9 75.8V63.4h2.4v12.4h-2.4zM65.7 76.1c-1.8 0-3.1-.8-4.3-2.4l1.6-1.5c.5 1.1 1.5 1.6 2.6 1.6s1.9-.7 1.9-1.7c0-.3-.1-.6-.3-.8-.3-.4-.6-.6-2.1-1.1-1-.3-1.6-.7-2.1-1.1-.6-.5-1-1.4-1-2.2 0-2.1 1.6-3.6 3.9-3.6 1.4 0 2.6.5 3.6 1.4l-1.3 1.6c-.6-.7-1.2-1-2-1-1 0-1.7.6-1.7 1.4 0 .2.1.5.2.6.3.3.6.5 1.6.9 1.4.5 1.9.8 2.5 1.2.7.6 1.1 1.5 1.1 2.6.1 2.4-1.7 4.1-4.2 4.1zM76 65.5v10.3h-2.4V65.5h-2.7v-2.1h7.9v2.1zM86.3 75.8l-3.3-5h-.3v5h-2.4V63.4h3.6c1.4 0 2.5.3 3.2.9.8.6 1.2 1.7 1.2 2.8 0 .8-.3 1.7-.7 2.3-.5.6-1.1 1-2.1 1.2l3.9 5.2h-3.1zm-2.9-10.4h-.7v3.8h.7c1.6 0 2.4-.7 2.4-1.9-.1-1.3-.9-1.9-2.4-1.9zM90.6 75.8V63.4h6.9v2.1H93v2.7h4.3v2.1H93v3.3h4.4v2.1zM105.6 75.8l-3.3-5h-.3v5h-2.4V63.4h3.6c1.4 0 2.5.3 3.2.9.8.6 1.2 1.7 1.2 2.8 0 .8-.3 1.7-.7 2.3-.5.6-1.1 1-2.1 1.2l3.9 5.2h-3.1zm-2.9-10.4h-.7v3.8h.7c1.6 0 2.4-.7 2.4-1.9-.1-1.3-.9-1.9-2.4-1.9zM53.9 30.1c4.2 0 7.6-3.4 7.6-7.6 0-4.2-3.4-7.6-7.6-7.6-4.2 0-7.6 3.4-7.6 7.6 0 4.2 3.5 7.6 7.6 7.6z" />
      <path d="M32.4.9V44h43.1V.9H32.4zm39.3 39.4H36.2V4.7h35.5v35.6z" />
    </g>
  </svg>
);
