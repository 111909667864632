import React from 'react';
import styled from 'styled-components';

import Icon from 'shared/components/presentational/Icons';
import BaseButton from 'shared/components/presentational/Button';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

interface Props {
  color: string;
  hoverColor: string;
  onClick: () => void;
  className?: string;
}

const Button = styled(BaseButton).attrs<Props>(props => ({
  theme: buttonThemeFactory({
    color: props.color,
    backgroundColor: 'transparent',
    hover: {
      color: props.hoverColor
    }
  })
}))<Props>`
  padding: 0 0 0 7px;

  ${mediaQueries.fromTablet`
    padding: 0 0 0 20px;
  `}
`;

export default function SearchButton({
  color,
  hoverColor,
  onClick,
  className
}: Props) {
  return (
    <Button
      className={className}
      color={color}
      hoverColor={hoverColor}
      onClick={onClick}
      data-testid="search-engine-button"
    >
      <Icon name="Search" width="20px" height="20px" />
    </Button>
  );
}
