import { compose, mapProps } from 'recompose';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import omit from 'lodash/omit';

import Page from 'shared/modules/page/components/Page';
import isConnected from 'shared/helpers/auth/isConnected';
import getToken from 'shared/modules/router/selectors/queryParams/getToken';
import getAccessToken from 'shared/modules/router/selectors/hashParams/getAccessToken';
import getReferer from 'shared/modules/router/selectors/queryParams/getReferer';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import propsFormatter from '../propsFormatter';
import getCreateToken from '../selectors/apis/getCreateToken';
import flush from 'shared/blocks/selfcare/shared/actions/flush';
import isLoginLinkExternal from '../selectors/authentication/isLoginLinkExternal';
import replaceURLHandler from 'shared/helpers/router/replaceURLHandler';
import getPageData from '../selectors/getPageData';
import { getDomainURL } from '../../hostname/selectors/getDomainURL';
import getCurrentZone from '../selectors/getCurrentZone';
import getZoneDefaultLang from '../selectors/getZoneDefaultLang';
import createLoginRedirectUrl from '../helpers/createLoginRedirectUrl';
import getPagePath from '../selectors/getPagePath';
import ConfigHelper from 'shared/helpers/config';
import getRedirectUri from 'shared/modules/router/selectors/queryParams/getRedirectUri';
import getUserAgent from 'shared/modules/requestInfo/selectors/getUserAgent';
import isDeviceMobileFromUserAgent from 'shared/helpers/devices/isDeviceMobileFromUserAgent';
import filterHiddenBlocks from '../helpers/filterHiddenBlocks';
import getPentagramFromState from '../selectors/getPentagramFromState';
import getQueryParams from 'shared/modules/router/selectors/getQueryParams';
import getSiteTheme from 'shared/modules/page/selectors/getTheme';
import { isOneShopLayout } from '../helpers/isOneShopLayout';

const withConnectedAndCookies = compose(
  withCookies,
  mapProps(ownProps => ({
    connected: isConnected(ownProps.allCookies || {}),
    // we don't want to pass down the allCookies object to avoid unexpected renders
    ...omit(ownProps, ['allCookies'])
  }))
);

const mapDispatchToProps = {
  redirect: replaceURLHandler,
  flushSelfcare: flush
};

const mapStateToProps = (state, { cookies }) => {
  const pageData = getPageData(state);
  const formattedPageData = propsFormatter(pageData);
  const pagePath = getPagePath(state);
  const domainURL = getDomainURL(state);
  const refererUrl = getReferer(state);
  const accessProviderUrl = ConfigHelper.getClientConfig(
    'external.services.oauth.provider'
  );
  const zoneBigram = getCurrentZone(state);
  const zoneDefaultLang = getZoneDefaultLang(state);

  const loginUrlFactory = createLoginRedirectUrl(
    domainURL,
    cookies.get(COOKIE_KEYS.ZONE),
    pageData,
    pagePath
  );

  const loginUrl = loginUrlFactory(refererUrl);
  const redirectUri = getRedirectUri(state);
  const sessionTokenParam = getQueryParams(state)?.sessionToken;
  const userAgent = getUserAgent(state);
  const hiddenBlocks = formattedPageData.hiddenBlocks;
  const isDeviceMobile = userAgent
    ? isDeviceMobileFromUserAgent(userAgent)
    : false;
  const filteredBlocks = filterHiddenBlocks(
    formattedPageData.blocks,
    hiddenBlocks,
    isDeviceMobile
  );

  const pentagram = getPentagramFromState(state);

  return {
    redirectUri,
    loginUrl,
    loginUrlFactory,
    authenticationIsExternal: isLoginLinkExternal(state),
    data: {
      ...formattedPageData,
      blocks: filteredBlocks,
      theme: getSiteTheme(state),
      isOneShopLayout: isOneShopLayout(pageData.layout)
    },
    apiCreateToken: getCreateToken(state),
    passIdParam: getToken(state),
    accessToken: getAccessToken(state),
    refererUrl: refererUrl || '/',
    accessProviderUrl,
    pentagram,
    sessionTokenParam,
    zoneBigram,
    zoneDefaultLang
  };
};

export default compose(
  withConnectedAndCookies,
  connect(mapStateToProps, mapDispatchToProps)
)(Page);
