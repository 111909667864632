/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: content.informations.title?.label ?? undefined,
  titleColor: content.informations.title?.color ?? undefined,
  titleHtmlTag: content.informations.title?.htmlTag ?? undefined,
  subtitle: content.informations.subtitle ?? undefined,
  iconUrl: content.informations.icon?.link.href ?? undefined,
  iconAlt: content.informations.icon?.description ?? undefined,
  content: content.informations.content ?? undefined,
  backButtonUrl: getLinkHrefWithAuthentication(
    content.informations.backButton ?? ''
  ),
  nextButtonUrl: getLinkHrefWithAuthentication(
    content.informations.nextButton ?? ''
  ),
  customerServiceUrl: getLinkHrefWithAuthentication(
    content.informations.customerServiceUrl ?? ''
  ),
  resetPasswordUrl: getLinkHrefWithAuthentication(
    content.informations.resetPasswordUrl ?? ''
  )
});
