/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*       */
import get from 'lodash/get';

import { createTemplate } from 'shared/helpers/template';
import { getLinkHref } from '../../helpers/uri';

export default content => ({
  programUrlPattern: getLinkHref({
    rel: get(content, 'dynamicProgramLink.rel'),
    href: get(content, 'dynamicProgramLink.href')
  }),
  type: content.type,
  channelsApi: {
    url: createTemplate(get(content, 'api.url', ''))({
      vectorSat: get(content, 'api.parameters.vectorSat', ''),
      vector: get(content, 'api.parameters.vector', '')
    }),
    isNew: get(content, 'api.isNew', false)
  },
  day: content.day,
  timezone: content.timeZone,
  keptChannels: get(content, 'channels', null)
});
