import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg
    viewBox="0 0 246 139"
    width={width}
    height={height}
    className={className}
  >
    <g fill="currentColor" fillRule="nonzero">
      <path d="M78 0C34.9 0 0 23.4 0 52.3 0 67 9 80.2 23.4 89.7L15.7 116s27.7-9.3 40.6-13.4c6.9 1.3 14.2 2.1 21.8 2.1 43.1 0 78-23.4 78-52.3S121.1 0 78 0zm36 61.3c0 .7-.4 1.1-1 1.1H88.4c-1 0-1.3.2-1.3 1.2v25c0 .9-.2 1.3-.9 1.3h-19c-.7 0-1-.5-1-1.1v-25c0-1-.4-1.4-1.2-1.4H40.1c-.7 0-1.1-.5-1.1-1.3v-19c0-.7.3-1.1 1-1.1h25c.7 0 1.1-.4 1.1-1.2V18.4c0-.6.4-.9.9-.9h19c.7 0 1.1.3 1.1.9v21.5c0 1 .2 1.2 1 1.2H113c.7 0 1 .4 1 1.2v19z" />
      <path d="M246 84.3c0-24.9-29.9-45-66.8-45s-66.8 20.1-66.8 45 29.9 45 66.8 45c6.2 0 12.2-.6 17.9-1.6L232 139l-6.6-22.2c12.7-8.2 20.6-19.7 20.6-32.5zm-64.9 30.6c-4.9 0-9-4-9-8.9 0-5.1 3.9-9 9-9 5 0 9 3.9 9 9 0 4.8-4 8.9-9 8.9zm14.4-30.5c-2.4 1.8-3.6 2.4-7.7 3.9v5.3h-14.1V78.8c1.5.4 2.1.5 3.1.5 5.8 0 10.1-3.3 10.1-7.7 0-3.2-2.5-5.6-5.8-5.6-3.2 0-5 2.1-5.2 5.8h-15.3c.2-10.7 8.7-18 20.9-18 11.2 0 20.2 8.1 20.2 18.2 0 4.7-2.3 9.5-6.2 12.4z" />
    </g>
  </svg>
);
