const breakpoints: Breakpoints = {
  hd: '1900px',
  desktop: '1200px',
  tablet: '992px',
  phablet: '767px',
  phone: '480px',
  phoneMini: '320px'
};

export type Breakpoints = { [key in Device]: string };

export type Device =
  | 'phoneMini'
  | 'phone'
  | 'phablet'
  | 'tablet'
  | 'desktop'
  | 'hd';

export default breakpoints;
