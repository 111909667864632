import get from 'lodash/get';

export default content => ({
  title: content.title,
  subtitle: content.subtitle,
  groups: get(content, 'groups', []).map(group => ({
    title: group.title,
    exponent: group.exponent,
    channels: get(group, 'channels', []).map(data => ({
      title: get(data, 'channel.title', ''),
      imageUrl: get(data, 'channel.media.link.href', ''),
      exponent: data.exponent
    }))
  }))
});
