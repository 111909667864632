import React, { PureComponent } from 'react';

import {
  SlideWrapper,
  SlideHeading,
  SlideThumbnail
} from 'shared/components/presentational/Slider/Slide/styles/components';
import {
  SlideTitle,
  SlideSubtitle
} from 'shared/blocks/mea/shared/styles/components';
import { TWO_SLIDES_THUMBNAIL_HEIGHT_DESKTOP } from 'shared/components/presentational/Slider/styles/constants';
import {
  ClickableSlideWrapper,
  IconWrapper,
  ArticleIcon
} from '../styles/components';
import { ARTICLE_SLIDE_TESTID } from '../constants';

export interface Props {
  className?: string;
  style?: object;
  title: string | null;
  subtitle: string | null;
  imageUrl: string;
  alt?: string;
  onClick?: () => void;
  onHover?: () => void;
}

export default class ArticleSlide extends PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      title,
      subtitle,
      imageUrl,
      alt,
      className,
      style,
      onClick,
      onHover
    } = this.props;

    return (
      <SlideWrapper
        as={onClick ? ClickableSlideWrapper : SlideWrapper}
        data-testid={ARTICLE_SLIDE_TESTID}
        className={className}
        style={style}
        onClick={onClick}
        onMouseEnter={onHover}
      >
        <SlideThumbnail
          alt={alt}
          url={imageUrl}
          height={TWO_SLIDES_THUMBNAIL_HEIGHT_DESKTOP}
        />
        <SlideHeading>
          <SlideTitle>{title}</SlideTitle>
          <SlideSubtitle>{subtitle}</SlideSubtitle>
        </SlideHeading>
        <IconWrapper>
          <ArticleIcon />
        </IconWrapper>
      </SlideWrapper>
    );
  }
}
