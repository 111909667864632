import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import without from 'lodash/without';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';

import Item from './AccordionItem';

/* type AccordionProps = {
  children: any,
  className: ?string,
  forcedOpenItems: ?Array
  // index: ?number,
  // thematic: ?string
}; */

const Wrapper = styled.div``;

class Accordion extends React.PureComponent {
  state = {
    opened: []
  };

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ])
  };

  UNSAFE_componentWillMount() {
    this.setState({
      opened: this.props.forcedOpenItems || []
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.forcedOpenItems, this.props.forcedOpenItems)) {
      this.setState({
        opened: uniq([...this.state.opened, ...nextProps.forcedOpenItems])
      });
    }
  }

  isOpened = index => {
    const { opened } = this.state;
    return opened.includes(index);
  };

  toggleItem = index => () => {
    const { opened } = this.state;
    this.setState(() => {
      if (opened.includes(index)) {
        return { opened: without(opened, index) };
      }
      return { opened: [...opened, index] };
    });
  };

  render() {
    const { className } = this.props;

    const children = React.Children.map(this.props.children, (child, index) => {
      if (child && child.type && child.type.displayName === Item.displayName) {
        return React.cloneElement(child, {
          ...child.props,
          isOpened: this.isOpened(index),
          selectCurrent: this.toggleItem(index)
        });
      }

      // eslint-disable-next-line no-console
      console.warn(
        `Wrong implementation:
         You must provide at least one Accordion.Item child to Accordion Component.`
      );

      return false;
    });

    return <Wrapper className={className}>{children}</Wrapper>;
  }
}

Accordion.Header = React.PureComponent;
Accordion.Content = React.PureComponent;
Accordion.Handle = React.Component;
Accordion.Item = React.PureComponent;

export default Accordion;
