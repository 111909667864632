import React from 'react';
import styled from 'styled-components';

import Raw from 'shared/components/presentational/Raw/Raw';
import Accordion from 'shared/components/presentational/Accordion';
import Picture from 'shared/components/presentational/Picture';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Icon from 'shared/components/presentational/Icons/Icon';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import ChannelsList from '../../channels/components/ChannelsList';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #F1F1F1;
  margin-bottom: 10px;
  font-family: ${FONT_FAMILIES.base};
  
  img {
      max-width: 180px;
    }

  p {
    margin: 0;
    line-height: ${pxInRem(21)};

    &:not(:last-child) {
      margin-bottom: ${pxInRem(12)};
    }
  }

  a {
    color: ${COLORS.cerulean};
    text-decoration: underline;
  }
  }
`;

const StyledRaw = styled(Raw)`
  display: flex;
  align-items: baseline;
  font-family: ${FONT_FAMILIES.brand};

  ${mediaQueries.toPhablet`
      font-size: ${pxInRem(14)}
    `} p {
    margin-bottom: 0;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: baseline;
`;

const PictogramsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: -5px 0;
`;

const StyledPicture = styled(Picture)`
  display: flex;
  height: 30px;
`;

const Plus = styled.span`
  font-weight: ${FONT_WEIGHTS.normal};
  margin: 0 ${pxInRem(5)};
`;

const AccordionFeaturesItem = styled(Accordion.Item)`
  background-color: #dddddd;
`;

const AccordionChannelsItem = styled(Accordion.Item)`
  background-color: #f1f1f1;
  margin-bottom: 10px;
`;

const AccordionFeaturesHeader = styled(Accordion.Header)`
  line-height: ${pxInRem(20)};
  font-size: ${pxInRem(14)};
  padding: 15px 50px 15px 30px;
  font-weight: ${FONT_WEIGHTS.bold};
  position: relative;
  justify-content: space-between;
  align-items: flex-start;

  ${mediaQueries.toPhablet &&
  `font-size: ${pxInRem(13)}; padding: 15px 40px 15px 15px;`} ${props =>
    props.disabled &&
    `padding: 15px 30px; ${mediaQueries.toPhablet && 'padding: 15px'}`};
`;

const AccordionChannelsHeader = styled(Accordion.Header)`
  line-height: ${pxInRem(20)};
  font-size: ${pxInRem(14)};
  padding: 15px 50px 15px 30px;
  font-weight: ${FONT_WEIGHTS.bold};
  position: relative;
  justify-content: space-between;
  align-items: flex-start;

  ${mediaQueries.toPhablet && 'padding-left: 20px;'};
`;

const StyledAccordionHandle = styled(Accordion.Handle)`
  position: absolute;
  right: 25px;
  top: 0;
  bottom: 0;
  padding: 15px 0;
  align-items: center;

  ${mediaQueries.toPhablet && 'right: 15px;'};
`;

const StyledAccordionContent = styled(Accordion.Content)`
  padding: 0 20px 20px;
`;

AccordionFeaturesHeader.displayName = 'Accordion.Header';
AccordionChannelsHeader.displayName = 'Accordion.Header';
AccordionFeaturesItem.displayName = 'Accordion.Item';
AccordionChannelsItem.displayName = 'Accordion.Item';
StyledAccordionHandle.displayName = 'Accordion.Handle';
StyledAccordionContent.displayName = 'Accordion.Content';

const Decoder = ({ decoder }) => (
  <Container>
    {/* Header */}
    <Header>
      <div>
        <Picture url={decoder.imageUrl} width={180} />
      </div>
      {decoder.text && <Raw html={decoder.text} />}
    </Header>

    {/* Features */}
    <Accordion>
      {decoder.features.map((feature, index) => (
        <AccordionFeaturesItem key={`Decoder__accordionItem${index}`}>
          <AccordionFeaturesHeader disabled>
            {feature.pictograms.length ? (
              <PictogramsWrapper>
                {feature.pictograms.map((picto, pictoKey) => [
                  <StyledPicture
                    key={`Decoder__pictogram${pictoKey}`}
                    url={picto.imageUrl}
                    alt={feature.title}
                    height={30}
                  />,
                  pictoKey < feature.pictograms.length - 1 ? (
                    <Plus key={`Decoder__plus${pictoKey}`}>+</Plus>
                  ) : null
                ])}
              </PictogramsWrapper>
            ) : (
              <Flex>
                {feature.title && <span>{feature.title}&nbsp;</span>}
                {feature.exponent && <sup>{feature.exponent}&nbsp;</sup>}
              </Flex>
            )}
            <StyledRaw html={feature.text} />
          </AccordionFeaturesHeader>
        </AccordionFeaturesItem>
      ))}
    </Accordion>

    {/* Channels */}
    <Accordion>
      {decoder.groups.map((group, index) => (
        <AccordionChannelsItem key={`Decoder__accordionItem${index}`}>
          <AccordionChannelsHeader disabled={group.channels.length === 0}>
            <Flex>
              {group.title && <span>{group.title}&nbsp;</span>}
              {group.exponent && <sup>{group.exponent}&nbsp;</sup>}
            </Flex>
            <StyledRaw html={group.text} />
            {group.channels.length > 0 && (
              <StyledAccordionHandle>
                <Icon
                  name="ArrowBottom"
                  width={10}
                  height={10}
                  color={COLORS.black}
                />
              </StyledAccordionHandle>
            )}
          </AccordionChannelsHeader>
          {group.channels.length > 0 && (
            <StyledAccordionContent>
              <ChannelsList channels={group.channels} />
            </StyledAccordionContent>
          )}
        </AccordionChannelsItem>
      ))}
    </Accordion>
  </Container>
);

export default Decoder;
