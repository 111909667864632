import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Button from 'shared/components/presentational/Button';
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { COLORS } from 'shared/constants/theme';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxInRem from 'shared/helpers/styled-components/remHelper';

const List = styled.ul`
  display: flex;
  align-items: center;

  ${mediaQueries.toTablet`
    flex-direction: column;
    align-items: flex-start;
  `};
`;

const ListItem = styled.li`
  :not(:last-child) {
    margin-right: 20px;
  }

  ${mediaQueries.toTablet`
    display: flex;
    align-items: center;
    height: 40px;
    margin-right: 0 !important;
  `};
`;
const ButtonText = styled(Button.children.Text)`
  ${mediaQueries.toTablet`
    font-size: ${pxInRem(16)};
    padding: 0 14px;
    width: 100%;
  `};
`;

const buttonThemeFactory = (color?: string) =>
  themeFactory({
    backgroundColor: 'transparent',
    color: color || COLORS.white,
    padding: '0 0 0 0'
  });

interface Props {
  className?: string;
  items: {
    id: string;
    title: string;
    link: string;
  }[];
  onItemClick?: () => void;
  color?: string;
}

export default class Menu extends PureComponent<Props> {
  public render(): React.ReactNode {
    const { className, items, onItemClick, color } = this.props;

    return (
      <List className={className}>
        {items.map((item, index) => (
          <ListItem key={`${item.id}-${index}`}>
            <Button
              to={item.link}
              theme={buttonThemeFactory(color)}
              onClick={onItemClick}
            >
              <ButtonText>{item.title}</ButtonText>
            </Button>
          </ListItem>
        ))}
      </List>
    );
  }
}
