export default {
  wrapper: {
    flexDirection: 'column'
  },
  text: {
    textAlign: 'center',
    marginTop: '20px'
  },
  image: {
    maxWidth: '100%'
  },
  imageMobile: {
    minHeight: '250px'
  }
};
