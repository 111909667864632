/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

const getAutomaticVisuals = visuals => ({
  template: 'automatic',
  apiUrl: visuals.url
});

const getVisualsByTemplate = visuals => {
  if (visuals.template) {
    return getAutomaticVisuals(visuals);
  }
};

export default (content, { id, getLinkHrefWithAuthentication }) => ({
  id,
  title: content.title,
  subtitle: content.subtitle,
  visualsXL: get(content, 'visualsXL', []).map(visual => ({
    title: get(visual, 'title', ''),
    subtitle: get(visual, 'subtitle', ''),
    imageUrl: get(visual, 'media.link.href'),
    alt: visual.media.description ?? visual.media.filename,
    linkUrl: getLinkHrefWithAuthentication(get(visual, 'link'))
  })),
  visualsS: getVisualsByTemplate(get(content.apis, 'legacy_vod.category', {})),
  visualsXS: get(content, 'visualsXS', []).map(visual => ({
    imageUrl: get(visual, 'media.link.href'),
    imageDescription: get(visual, 'media.description'),
    alt: visual.media.description ?? visual.media.filename,
    linkUrl: getLinkHrefWithAuthentication(get(visual, 'link'))
  })),
  buttonUrl: getLinkHrefWithAuthentication(
    get(content, 'buttons.mainButton.link', '')
  ),
  buttonText: get(content, 'buttons.mainButton.label', ''),
  folderUrl: content.links.folder.href,
  detailUrl: content.links.detail.href
});
