/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { PureComponent } from 'react';
import cs from 'classnames';
import get from 'lodash/get';

import ErrorBlock from 'shared/components/presentational/ErrorBlock/index';
import blocks from 'shared/blocks/index';
import { namespace as modalGeolocationNamespace } from 'shared/blocks/modal-geolocation/index';
import Config from 'shared/helpers/config/index';
import Anchor from './Anchor';

/**
 * Create ErrorBlock from the given parameters
 *
 * @param {string} type        Type of the block has errors
 * @param {string} id          CSS id of the component has errors
 *
 * @returns {ErrorBlock}
 */

const getErrorBlock = (type, id) => (
  <ErrorBlock
    description={`Block ${type} lack of information and it cannot be displayed.`}
    key={id}
    hide={Config.getClientConfig('showErrorBlock') !== true}
  />
);

class BlockErrorCatcher extends PureComponent {
  componentDidCatch(error) {
    console.error(
      `An error occurred while rendering block ${this.props.id} : ${error.stack}`
    );
  }

  render() {
    return this.props.children;
  }
}

/**
 * Create a component from the given parameters
 *
 * @param {number} technicalId              Id of the block or the fragment
 * @param {string} type                     Type of the block
 * @param {string} id                       CSS id of the component
 * @param {object} content                  Content of the component (the settings)
 * @param {object} propsFormatterUtils      PropsFormatter tools and helpers
 * @returns {React.Component}
 */
const getComponent = (technicalId, type, id, content, propsFormatterUtils) => {
  const block = blocks[type];

  if (!block) {
    // eslint-disable-next-line no-console
    console.error(`Block ${type}:${id} was not found.`);

    return getErrorBlock(type, id);
  }

  /**
   * BlockComponent is either a Component or a tag type (string)
   */
  const BlockComponent = block.Component;

  if (!BlockComponent) {
    // eslint-disable-next-line no-console
    console.error(`React component of the block ${id} was not found.`);

    return getErrorBlock(type, id);
  }

  let formattedProps;
  try {
    formattedProps = block.propsFormatter
      ? block.propsFormatter(content, {
          id: technicalId,
          ...propsFormatterUtils
        })
      : {};
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `An error occurred while formatting block properties ${id} : ${error.stack}`
    );

    return getErrorBlock(type, id);
  }

  /**
   * anchorId comes from the content object as it varies according to language.
   */
  const anchorId = get(content, 'anchorId');

  /**
   * TODO: until backend will provide a "modal" container,
   * we need to consider blocks rendered in a modal as not regular Block because their
   * content is moved in a react portal and therefore the Block is empty.
   */
  const componentCSSClassNames = cs('Block', {
    Empty: type === modalGeolocationNamespace
  });

  if (!anchorId) {
    return (
      <BlockErrorCatcher key={`component-factory-${id}`} id={id}>
        <div className={componentCSSClassNames}>
          <BlockComponent {...formattedProps} />
        </div>
      </BlockErrorCatcher>
    );
  }
  return (
    <BlockErrorCatcher key={`component-factory-${id}`} id={id}>
      <Anchor anchorId={anchorId}>
        <div className={componentCSSClassNames}>
          <BlockComponent {...formattedProps} />
        </div>
      </Anchor>
    </BlockErrorCatcher>
  );
};

export default getComponent;
