import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { COLORS } from 'shared/constants/theme';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Icon from '../../Icons/Icon';

interface Props {
  color?: string;
  name: string;
  className?: string;
  onClick?: () => void;
  size: number;
  phoneSize: number;
}

const StyledButton = styled.button<Omit<Props, 'name' | 'color'>>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  &::before {
    display: none;
    visibility: hidden;
  }
`;

const StyledIcon = styled(Icon)<Pick<Props, 'size' | 'phoneSize' | 'color'>>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  color: ${props => props.color};

  ${mediaQueries.toPhablet<{ phoneSize: number }>`
    width: ${props => props.phoneSize}px;
    height: ${props => props.phoneSize}px;
  `};
  &:hover {
    color: ${props => props.color};
  }
`;

export default class Arrow extends PureComponent<Props> {
  public static readonly defaultProps = {
    color: COLORS.black,
    size: 27,
    phoneSize: 18
  };

  public render() {
    const { onClick, name, color, size, phoneSize, ...props } = this.props;

    return (
      <StyledButton
        onClick={onClick}
        size={size}
        phoneSize={phoneSize}
        {...props}
      >
        <StyledIcon
          color={color}
          name={name}
          size={size}
          phoneSize={phoneSize}
        />
      </StyledButton>
    );
  }
}
