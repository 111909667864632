import React from 'react';

import { FormattedProps } from '../types';
import { Wrapper, Button } from '../styles/components';

type Props = FormattedProps;

export default function CookieConsentButton({ button }: Props) {
  const handleClick = () => {
    if (typeof window !== 'undefined' && window.Didomi) {
      window.Didomi.preferences.show();
    }
  };

  return (
    <Wrapper data-testid="cookie-consent-button">
      <Button theme={button.theme} onClick={handleClick}>
        <Button.children.Text>{button.label}</Button.children.Text>
      </Button>
    </Wrapper>
  );
}
