import styled from 'styled-components';

import Icon from 'shared/components/presentational/Icons/Icon';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { COLORS } from 'shared/constants/theme';
import { SlideWrapper } from 'shared/components/presentational/Slider/Slide/styles/components';
import {
  MAIN_IMAGE_HEIGHT,
  MAIN_IMAGE_HEIGHT_PHONE
} from '../../shared/styles/constants';

export const ClickableSlideWrapper = styled(SlideWrapper)`
  cursor: pointer;
`;

export const ArticleIcon = styled(Icon).attrs({ name: 'Article' })`
  width: 15px;
  height: 15px;
  color: black;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 40px 0 0;
  border-color: ${COLORS.gainsboro} transparent transparent transparent;
  ${ArticleIcon} {
    position: absolute;
    top: -35px;
    left: 5px;
  }
`;

export const InnerModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${COLORS.white};
  min-height: ${MAIN_IMAGE_HEIGHT}px;
  ${mediaQueries.toPhablet`
    min-height: ${MAIN_IMAGE_HEIGHT_PHONE}px;
  `}
`;
