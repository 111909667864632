/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Content, FormattedProps } from './types';
import { PropsFormatterUtils, Link } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: content.title,
  subtitle: content.subtitle,
  iconUrl: content.icon?.link.href,
  iconAlt: content.icon?.description ?? '',
  moreInformation: content.moreInformationLabel ?? '',
  paymentMethods: content.paymentMethods.map(method => ({
    ...method,
    subtitle: method.subtitle ?? '',
    informations: {
      iconUrl: method.informations.icon?.link.href,
      iconAlt: method.informations.icon?.description ?? '',
      color: method.informations.textColor ?? '',
      label: method.informations.labelText ?? '',
      backgroundColor: method.informations.backgroundColor ?? ''
    },
    operators: method.operators.map(operator => ({
      label: operator.labelText ?? '',
      imageUrl: operator.image?.link.href ?? '',
      imageAlt: operator.image?.description ?? '',
      link:
        operator.link.tel ??
        getLinkHrefWithAuthentication(operator.link as Link)
    }))
  }))
});
