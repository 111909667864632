import uniq from 'lodash/uniq';
import get from 'lodash/get';

import favicon32 from 'shared/assets/icons/favicon-32x32.png';
import favicon16 from 'shared/assets/icons/favicon-16x16.png';
import { getLinkHref } from 'shared/helpers/uri';

const getContainers = data => uniq(data.blocks.map(block => block.container));
const getFavIcon = data => {
  const { favicon } = data.site;

  if (favicon) {
    return [
      {
        rel: 'icon',
        type: favicon.contentType,
        href: getLinkHref(favicon.link)
      }
    ];
  }
  return [
    { rel: 'icon', type: 'image/png', href: favicon32, sizes: '32x32' },
    { rel: 'icon', type: 'image/png', href: favicon16, sizes: '16x16' }
  ];
};

const getAvailableZones = zones => Object.keys(zones);

export default data => {
  try {
    return {
      containers: getContainers(data),
      type: data.type,
      template: data.type,
      title: data.title,
      blocks: data.blocks,
      theme: data.theme,
      authentication: {
        authenticated: data.authentication.authenticated,
        loginLink: getLinkHref(data.authentication.loginLink),
        logoutLink: getLinkHref(data.authentication.logoutLink),
        externalLogoutLink: getLinkHref(data.authentication.externalLogoutLink)
      },
      url: getLinkHref(get(data, 'link')),
      meta: data.meta || [],
      scripts: data.scripts || [],
      favicon: getFavIcon(data),
      zones: getAvailableZones(data.site.zones),
      hiddenBlocks: data.hiddenBlocks ?? {},
      trackingConsent: data.trackingConsent
    };
  } catch {
    return undefined;
  }
};
