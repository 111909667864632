import { connect } from 'react-redux';

import { getDomainURL } from 'shared/modules/hostname/selectors/getDomainURL';
import { FormattedProps } from '../types';
import getBigram from 'shared/modules/page/selectors/getCurrentZone';
import { formatDomainUrl } from 'shared/modules/page/helpers/formatDomainUrl';
import Channels from '../components/Channels';
import { createTemplate } from 'shared/helpers/template';

const mapStateToProps = (
  state: object,
  ownProps: FormattedProps
): FormattedProps => {
  const domainUrl = getDomainURL(state);
  const bigram = getBigram(state);

  const formatedDomainUrl = formatDomainUrl(domainUrl, bigram);

  return {
    ...ownProps,
    groups: ownProps.groups.map(group => {
      return {
        ...group,
        channels: group.channels.map(channel => {
          return {
            ...channel,
            link: channel.link
              ? createTemplate(channel.link.replace('http:', 'https:'))({
                  domain: formatedDomainUrl || ''
                })
              : undefined
          };
        })
      };
    })
  };
};

export default connect(mapStateToProps)(Channels);
