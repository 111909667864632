import { lighten, transparentize } from 'polished/lib/index';

import { COLORS } from 'shared/constants/theme';

export default {
  button: {
    normal: {
      backgroundColor: 'transparent',
      borderColor: COLORS.amaranth,
      color: COLORS.amaranth,
      textDecoration: 'none'
    },
    hover: {
      backgroundColor: transparentize(0.8, COLORS.amaranth),
      borderColor: lighten(0.2, COLORS.amaranth),
      color: lighten(0.2, COLORS.amaranth),
      textDecoration: 'none'
    },
    disabled: {
      backgroundColor: lighten(0.2, COLORS.amaranth),
      borderColor: lighten(0.2, COLORS.amaranth),
      color: COLORS.white,
      textDecoration: 'none'
    }
  }
};
