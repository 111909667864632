/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import loadable from '@loadable/component';

export const namespace =
  'canalplus.block.selfcare.spotlight_subscription_options_list';
export const Component = loadable(
  () => import('./containers/SpotlightSubscriptionOptionsContainer')
);
export { default as propsFormatter, getFormattedApis } from './propsFormatter';
