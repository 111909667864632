import styled from 'styled-components';

import { Position } from 'shared/blocks/button/types';
import { FONT_FAMILIES, COLORS, FONT_WEIGHTS } from 'shared/constants/theme';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxInRem from 'shared/helpers/styled-components/remHelper';

interface TitleProps {
  position?: Position;
  color?: string | null;
  htmlTag?: string | null;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--title-margin, 24px);
  gap: var(--spacing-xs, 8px);

  ${mediaQueries.fromTablet`
    align-items: var(--title-position, var(--text-align, inherit));
  `};
`;

export const CustomSubtitle = styled.span`
  display: block;
  line-height: var(--line-height-m);
  font-family: ${FONT_FAMILIES.base};
  font-size: var(--text-size-base);
  text-align: var(--text-align);

  ${mediaQueries.fromTablet`
    text-align: var(--title-position, var(--text-align, inherit));
  `};

  color: ${props => (props.color ? props.color : 'var(--text-default-light)')};
`;

const BaseTitle = styled.h4<TitleProps>`
  margin: 0;
  font-weight: ${FONT_WEIGHTS.bold};
  white-space: pre-line;
  text-align: var(--title-position, var(--text-align, inherit));

  ${mediaQueries.fromTablet`
    text-align: var(--title-position, var(--text-align, inherit));
  `};

  color: ${props => (props.color ? props.color : 'var(--text-default-main)')};
`;

export const CustomTitle = styled(BaseTitle).attrs<TitleProps>(props => ({
  as: props.htmlTag ?? 'h4'
}))<TitleProps>`
  ${props => {
    if (props.htmlTag === 'h1') {
      return `
        font-size: var(--text-size-h1);
        line-height: ${pxInRem(36)};
        `;
    } else if (props.htmlTag === 'h2') {
      return `
        font-size: var(--text-size-h2);
        line-height: ${pxInRem(30)};
      `;
    } else if (props.htmlTag === 'h3') {
      return `
        font-size: var(--text-size-h3);
        line-height: var(--line-height-h3) ;
      `;
    } else
      return `
      font-size: ${pxInRem(18)};
      font-style: normal;
      font-weight: 400;
      line-height: ${pxInRem(22)};
      color: ${props.color ?? COLORS.black};
      `;
  }}
`;
