import { createSelector } from 'reselect';
import get from 'lodash/get';

import getCurrentCategory from './getCurrentCategory';
import getAllProgramsByCategory from './getAllProgramsByCategory';

export default createSelector(
  getCurrentCategory,
  getAllProgramsByCategory,
  (currentCategory, programCategories) =>
    get(programCategories, currentCategory, [])
);
