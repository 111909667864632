import styled from 'styled-components';

import {
  DIMENSIONS,
  CONTAINER_MAX_WIDTH_BREAKPOINT
} from 'shared/constants/theme';
import BaseWrapper from 'shared/components/presentational/Wrapper/Wrapper';
import { container } from 'shared/constants/mixins';
import mediaQueries, {
  customMediaQuery
} from 'shared/helpers/styled-components/mediaQueries';
import BaseOfferCard from '../components/OfferCard/OfferCard';
import {
  MIN_WIDTH,
  MINIMUM_GAP,
  THREE_SLIDES_BREAKPOINT,
  TWO_SLIDES_BREAKPOINT
} from '../components/OfferCard/styles/constants';

export const Wrapper = styled(BaseWrapper)`
  ${container}
`;

export const OffersWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 44px -${MINIMUM_GAP / 2}px 32px -${MINIMUM_GAP / 2}px;
  ${mediaQueries.toPhablet`
    margin: -8px -${MINIMUM_GAP / 2}px 16px -${MINIMUM_GAP / 2}px;
  `}
`;

export const OfferCard = styled(BaseOfferCard)`
  min-width: ${MIN_WIDTH}px;
  max-width: 320px;
  margin: 0 ${MINIMUM_GAP / 2}px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  ${mediaQueries.toPhablet`
    margin-bottom: 15px;
  `};

  ${customMediaQuery(`${TWO_SLIDES_BREAKPOINT}px`)`
    width: calc(50vw - ${
      (DIMENSIONS.CONTAINER_MINIMUM_PADDING * 2 + MINIMUM_GAP) / 2
    }px );
  `}

  ${mediaQueries.fromTablet`
    width: calc(50vw - ${
      (DIMENSIONS.CONTAINER_MINIMUM_PADDING_TABLET * 2 + MINIMUM_GAP) / 2
    }px );
  `}

  ${customMediaQuery(`${THREE_SLIDES_BREAKPOINT}px`)`
    width: calc(33.33vw - ${
      (DIMENSIONS.CONTAINER_MINIMUM_PADDING_TABLET * 2 + MINIMUM_GAP * 2) / 3
    }px );
  `}

  ${customMediaQuery(`${CONTAINER_MAX_WIDTH_BREAKPOINT}px`)`
    width: ${(DIMENSIONS.CONTAINER_INNER_WIDTH - MINIMUM_GAP * 2) / 3}px;
  `}
`;

export const MinorOffersButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaQueries.toPhoneMini`
    flex-direction: column;

    * {
      width: 100%;
      text-align: center;
    }

    > *:not(:first-child) {
      margin-top: 5px;
    }

    > *:not(:last-child) {
      margin-bottom: 5px;
    }
  `}

  ${mediaQueries.fromPhone`
    > *:not(:first-child) {
      margin-left: 5px;
    }

    > *:not(:last-child) {
      margin-right: 5px;
    }
  `}
`;
