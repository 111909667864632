import React from 'react';

import { FormattedProduct, FormattedProps } from '../types';
import {
  Wrapper,
  Picture,
  ContentWrapper,
  PictureWrapper,
  BlockWrapper,
  PictureShadow
} from '../styles/components';
import Title from 'shared/components/presentational/Title';
import { Sticker } from '../../shared/components/Sticker/component/Sticker';
import { Button } from 'shared/blocks/one-shop/shared/components/Button/Button';
import ResponsiveHoc, {
  ResponsiveHocProps
} from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';
import { Video } from 'shared/components/presentational/Video/video';
import { getDisplayedVideo } from 'shared/components/presentational/Video/helpers/getDisplayedVideo';
import { OneShopArrow } from 'shared/components/presentational/Slider/styles/OneShopComponents';
import { getFormatedBannerUrlByDevice } from '../helpers/getFormatedBannerUrlByDevice';
import OfferDetailsRocContainer from 'shared/blocks/one-shop/shared/containers/OfferDetailsRocContainer';
import Slider from 'shared/components/presentational/Slider/Slider';

const OfferHighlight = ({
  products,
  template,
  autoPlaySpeed,
  mediaQueries
}: FormattedProps & ResponsiveHocProps) => {
  const renderHighlights = React.useCallback(
    ({
      title,
      description,
      bannerUrlByDevice,
      primaryButton,
      offerDetails,
      promotionalSticker
    }: FormattedProduct) => {
      const { isVideo, url } = getDisplayedVideo(
        mediaQueries,
        bannerUrlByDevice
      );

      return (
        <Wrapper>
          <PictureWrapper>
            <PictureShadow></PictureShadow>
            {isVideo ? (
              <Video url={url} />
            ) : (
              <Picture
                responsivesUrls={getFormatedBannerUrlByDevice(
                  bannerUrlByDevice
                )}
                template={template}
              />
            )}
          </PictureWrapper>
          <ContentWrapper>
            {promotionalSticker && <Sticker label={promotionalSticker} />}
            <Title
              subtitle={description}
              titleHtmlTag="h1"
              titleLabel={title}
            />
            <OfferDetailsRocContainer {...offerDetails} />
            {primaryButton ? (
              <Button
                fullWidthMobile
                label={primaryButton.label}
                link={primaryButton.href}
              />
            ) : null}
          </ContentWrapper>
        </Wrapper>
      );
    },
    [mediaQueries, template]
  );
  return (
    <BlockWrapper data-testid="oneShop.offer.highlight">
      {products.length > 1 ? (
        <Slider
          showDots={true}
          isCenterMode={false}
          isInfinite={true}
          initialSlide={0}
          nextArrow={
            <OneShopArrow
              name="ArrowLeftOneShop"
              isTransparent={true}
              isSecondaryDark={true}
            />
          }
          prevArrow={<></>}
          slidesToShow={1}
          autoPlaySpeed={autoPlaySpeed}
          isOneShop={true}
        >
          {products.map(renderHighlights)}
        </Slider>
      ) : (
        products.map(renderHighlights)
      )}
    </BlockWrapper>
  );
};

export default ResponsiveHoc(OfferHighlight);
