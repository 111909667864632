import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Button from 'shared/components/presentational/Button';
import Icon from 'shared/components/presentational/Icons';
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import remHelper from 'shared/helpers/styled-components/remHelper';
import { COLORS } from 'shared/constants/theme';

const Wrapper = styled.section`
  max-width: 1000px;
  margin-top: 20px !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0 !important;
`;

// @TODO transform remHelper.js to Typescript
const buttonTheme = themeFactory({
  color: COLORS.black,
  backgroundColor: 'transparent',
  textDecoration: 'none',
  padding: 0,
  // @ts-ignore
  lineHeight: remHelper(20),
  fontSize: remHelper(12)
});

const StyledIcon = styled(Icon)<any>`
  transform: rotate(180deg);
`;

const StyledText = styled(Button.children.Text)`
  text-decoration: none;
`;

interface Props {
  label: string;
  link: string;
}

export default class BackLink extends PureComponent<Props> {
  public render(): React.ReactNode {
    const { label, link } = this.props;

    return (
      <Wrapper>
        <Button to={link} theme={buttonTheme}>
          <StyledIcon name="CosArrow" />
          <StyledText>{label}</StyledText>
        </Button>
      </Wrapper>
    );
  }
}
