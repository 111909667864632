import { COOKIE_KEYS } from '../modules/cookies/constants';

export const ACTION_TYPES = {
  SET_IS_CONNECTED: '@AUTH/SET_IS_CONNECTED'
};

export const AUTH_PASS_LEGACY_COOKIE = 'AuthOTTToken';
export const AUTH_PASS_COOKIE = 'AuthOTTTokenV2';
export const AUTH_KPLUS_COOKIE = 'WebsiteAccessToken';
export const AUTH_KPLUS_PREPROD_COOKIE = 'WebsiteAccessToken_stag';
export const AUTH_COOKIE_KEY = [
  AUTH_PASS_LEGACY_COOKIE,
  AUTH_PASS_COOKIE,
  AUTH_KPLUS_COOKIE,
  AUTH_KPLUS_PREPROD_COOKIE
];

//cookies used by canalbox, returned by keycloak
export const AUTH_CANALBOX_COOKIE_KEY = ['access_token', 'refresh_token'];

export const AUTH_COOKIES_LIST = [
  AUTH_PASS_LEGACY_COOKIE,
  AUTH_PASS_COOKIE,
  AUTH_KPLUS_COOKIE,
  AUTH_KPLUS_PREPROD_COOKIE,
  'AuthIdToken',
  'subscriberId',
  'numSubscriber',
  'pdsNormal',
  'pdsOtt',
  'vector',
  'offerZone',
  'offerLocation',
  'macroEligibility',
  'microEligibility',
  'isAuthenticated',
  COOKIE_KEYS.EXTERNAL_AUTH,
  AUTH_CANALBOX_COOKIE_KEY[0],
  AUTH_CANALBOX_COOKIE_KEY[1]
];
