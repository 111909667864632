import { connect } from 'react-redux';

import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import { getDomainURL } from 'shared/modules/hostname/selectors/getDomainURL';
import { PropsFromState, FormattedProps } from '../types';
import Article from '../components/Article';
import clientCookies from 'client/cookies';
import fetchArticle from '../../shared/action/fetchArticle';
import getArticleSlug from '../../shared/helpers/getArticleSlug';
import getHostname from 'shared/modules/hostname/selectors/getHostname';
import getMiniSiteDomain from 'shared/helpers/zones/getMiniSiteDomain';
import getPagePath from 'shared/modules/page/selectors/getPagePath';
import getBigram from 'shared/modules/page/selectors/getCurrentZone';
import { formatDomainUrl } from 'shared/modules/page/helpers/formatDomainUrl';

const mapStateToProps = (
  state: object,
  ownProps: FormattedProps
): PropsFromState => {
  // rely on page path because we do not have access to router pathname on SSR
  const pagePath = getPagePath(state);
  const slug = getArticleSlug(pagePath);
  const lang = clientCookies.get(COOKIE_KEYS.LANG);
  const hostname = getHostname(state);
  const miniSiteDomain = getMiniSiteDomain(hostname);
  const domainUrl = getDomainURL(state);
  const bigram = getBigram(state);

  if (slug) {
    return {
      url: ownProps.createArticleContentHref({ slug }),
      lang,
      miniSiteDomain,
      domainUrl: formatDomainUrl(domainUrl, bigram)
    };
  }

  return {
    url: undefined,
    domainUrl: formatDomainUrl(domainUrl, bigram)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  articleContentRequestFetcher: (path: string) => dispatch(fetchArticle(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(Article);
