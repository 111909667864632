export const ACTION_TYPES = {
  FETCH_VOD_PATH_REQUEST: '@VOD_HODOR/FETCH_URL_REQUEST',
  FETCH_VOD_PATH_SUCCESS: '@VOD_HODOR/FETCH_URL_SUCCESS',
  FETCH_VOD_PATH_ERROR: '@VOD_HODOR/FETCH_URL_ERROR',
  FETCH_MORE_VOD_REQUEST: '@VOD_HODOR/FETCH_MORE_REQUEST',
  FETCH_MORE_VOD_SUCCESS: '@VOD_HODOR/FETCH_MORE_SUCCESS',
  FETCH_MORE_VOD_ERROR: '@VOD_HODOR/FETCH_MORE_ERROR',
  AUTH_VOD_REQUEST: '@VOD_HODOR/AUTH_REQUEST',
  AUTH_VOD_SUCCESS: '@VOD_HODOR/AUTH_SUCCESS',
  AUTH_VOD_ERROR: '@VOD_HODOR/AUTH_ERROR',
  SET_CURRENT_PATH: '@VOD/SET_CURRENT_PATH',
  SET_BASE_LINK: '@VOD/SET_BASE_LINK'
};

export const TEMPLATE_TYPES = {
  MOSAIC: 'mosaic',
  CONTENT_GRID: 'contentGrid',
  GABARIT_LIST: 'gabaritList',
  DETAIL_PAGE: 'detailPage',
  DETAIL_SHOW: 'detailShow',
  DETAIL_SEASON: 'detailSeason'
};

export const DEFAULT_MACRO = 'LVTV_ANT,MYCANAL,ALD_CS';

export const BASE_URL = '/vod/mycanal';
export const DEFAULT_ERROR_MESSAGE = 'common.global.error';
export const VOD_HODOR_ROUTES_REGEXP = /^(\/?([a-zA-Z]{2}\/)?vod\/mycanal)\S*/g;

export const URL_KEYS = {
  URLPage: 'URLPage',
  path: 'path'
};
