import React from 'react';

import { DropdownStyled } from './DropdownStyled';
import { Authentication, FormatedMenu, SelfcareIcon } from '../../types';
import { ThemeFactory } from 'shared/types';
import DropdownMenu from '../../containers/DropdownMenu';
import HeaderProfileIcon from '../ProfileIcon/ProfileIcon';
import { useDropdown } from '../../hooks/useDropdown';
import { useHandleModalsOpening } from '../../providers/ModalsOpeningProvider';
import ResponsiveHoc, {
  ResponsiveHocProps
} from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';

export type Props = {
  items: FormatedMenu;
  isConnected: boolean;
  icon: SelfcareIcon;
  theme: {
    link: ThemeFactory['link'];
    backgroundColorModals: string;
    colorClosingModals: string;
  };
  authentication: Authentication;
};

const UserMenu = ({
  items,
  theme,
  isConnected,
  icon,
  authentication,
  mediaQueries
}: Props & ResponsiveHocProps): JSX.Element => {
  const { isUserMenuOpened } = useHandleModalsOpening();

  const { openUserMenu, closeDropdown, toggleUserMenu } = useDropdown();

  const displayedSelfcareIcon = React.useMemo(
    () =>
      isConnected
        ? {
            url: icon.authenticated.link.href,
            alt: icon.authenticated.description
          }
        : {
            url: icon.prospect.link.href,
            alt: icon.prospect.description
          },
    [
      isConnected,
      icon.authenticated.description,
      icon.authenticated.link.href,
      icon.prospect.description,
      icon.prospect.link.href
    ]
  );

  return (
    <DropdownStyled
      className="HeaderProfileStyled"
      data-testid="header-profile"
      onMouseEnter={() => {
        mediaQueries.fromDesktop && openUserMenu();
      }}
      onMouseLeave={() => {
        mediaQueries.fromDesktop && closeDropdown();
      }}
    >
      <button
        data-e2e={`headerProfile-icon--${
          isConnected ? 'connected' : 'notConnected'
        }`}
        onClick={toggleUserMenu}
        type="button"
        id="onclick"
        aria-label="icon-avatar"
        className="HeaderProfileStyled__button"
        aria-haspopup
      >
        <HeaderProfileIcon
          icon={{
            url: displayedSelfcareIcon.url,
            alt: displayedSelfcareIcon.alt
          }}
        />
      </button>
      {isUserMenuOpened && (
        <DropdownMenu
          theme={theme}
          items={items}
          isConnected={isConnected}
          onMouseEnter={() => {
            openUserMenu();
          }}
          onMouseLeave={() => {
            closeDropdown();
          }}
          authentication={authentication}
        />
      )}
    </DropdownStyled>
  );
};

export const Dropdown = ResponsiveHoc(UserMenu);
