import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 74 96" width={width} height={height} className={className}>
    <path
      d="M62.7 38.3V26C62.7 11.9 51.2.3 37 .3S11.3 11.9 11.3 26v12.3H.3v57.3h73.2V38.3H62.7zm-8.6 0H19.9v-12c0-9.4 7.7-17.1 17.1-17.1 9.4 0 17.1 7.7 17.1 17.1v12zm11.5 49.3H8.4V46.3h57.2v41.3zm-37.5-9.5l5-11.1c-1.6-1.2-2.7-3.1-2.7-5.3 0-3.6 2.9-6.6 6.6-6.6 3.6 0 6.6 2.9 6.6 6.6 0 2.2-1.1 4.1-2.7 5.3l5 11.1H28.1z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
