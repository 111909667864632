import React from 'react';

export default ({ className = '', width = '12', height = '20' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.50898 19.0088L0.741211 17.241L7.98233 9.99991L0.74121 2.75879L2.50898 0.991022L11.5179 9.99991L2.50898 19.0088Z"
      fill="#1A1A1A"
    />
  </svg>
);
