/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

export default (content, { getLinkHrefWithAuthentication }) => ({
  buttonLabel: get(content, 'buttonLabel', null),
  label: get(content, 'question', null),
  options: content.choices.map(choice => ({
    label: choice.label,
    value: getLinkHrefWithAuthentication(choice.link)
  }))
});
