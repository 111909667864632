import { TEMPLATE_TYPES, URL_KEYS } from '../constants';
import getPathMapByKey from './getPathMapByKey';

/**
 * /!\
 * This code is here mainly to prevent re-fetch on "Path" service (C+I)
 *
 * Return an object of the path and URLPage in this format:
 * {path: URLPage}
 */
export default function getContentByTemplate(data) {
  switch (data.currentPage.displayTemplate) {
    case TEMPLATE_TYPES.GABARIT_LIST: {
      /**
       * We teak the path...
       *
       * @TODO: check why we are doing this ?
       */
      const items = data.rubriques.map(rubrique => ({
        URLPage: rubrique.URLPage,
        path: rubrique.URLPage
      }));

      return getPathMapByKey(items, URL_KEYS.URLPage);
    }
    case TEMPLATE_TYPES.DETAIL_SHOW: {
      const items = data.detail.seasons.map(content => content.onClick);
      return getPathMapByKey(items, URL_KEYS.URLPage);
    }
    case TEMPLATE_TYPES.DETAIL_SEASON: {
      /**
       * /!\
       * We tweak url to remove the query params because
       * react-router-dom "matchPath" method used in shared/modules/tasks/helpers/executeTasks
       * does not support query params.
       * Indeed the path we get from taskroute that is used by fetch webservice
       * wont match the url that we are storing here to avoid "Path (C+ service) re-fetch"
       *
       * Ask PO for more detail
       */
      const items = data.episodes.contents.map(content => ({
        ...content,
        path: content.path.split('?')[0]
      }));
      return getPathMapByKey(items, URL_KEYS.URLPage);
    }
    case TEMPLATE_TYPES.MOSAIC:
    case TEMPLATE_TYPES.CONTENT_GRID: {
      const items = data.contents.map(content => content.onClick);
      return getPathMapByKey(items, URL_KEYS.URLPage);
    }
    default:
      return {};
  }
}
