/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

export default content => ({
  buttonGeolocation: get(content, 'geolocButtonLabel', ''),
  api: get(content, 'api', { url: '', parameters: {} }),
  countryCode: get(content, 'api.parameters.isoCountryCode', '')
});
