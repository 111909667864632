/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';

import { FormattedProps } from '../types';
import { Wrapper, SlideImage, SlideWrapper } from '../styles/components';
import ResponsiveHoc, {
  ResponsiveHocProps
} from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';
import BaseLink from 'shared/components/presentational/CustomLink/CustomLink';
import Container from 'shared/components/presentational/Container/Container';
import {
  SlideTitle,
  SlideSubtitle
} from 'shared/blocks/mea/shared/styles/components';
import Title from 'shared/components/presentational/Title';

type Props = FormattedProps & ResponsiveHocProps;

function IntermediateXl({
  title,
  titleColor,
  titleHtmlTag,
  subtitle,
  slideTitle,
  slideSubtitle,
  mainImageUrl,
  responsiveImage,
  alt,
  href,
  mediaQueries
}: Props) {
  let imageUrl = mainImageUrl;
  if (mediaQueries.toPhone && responsiveImage) {
    imageUrl = responsiveImage;
  }

  return (
    <Wrapper data-testid="intermediate">
      <Container>
        <Title
          titleLabel={title}
          subtitle={subtitle}
          titleColor={titleColor}
          titleHtmlTag={titleHtmlTag}
        />
        <SlideWrapper as={href ? BaseLink : undefined} to={href!}>
          <SlideImage url={imageUrl} alt={alt} />
          <SlideTitle> {slideTitle}</SlideTitle>
          <SlideSubtitle>{slideSubtitle}</SlideSubtitle>
        </SlideWrapper>
      </Container>
    </Wrapper>
  );
}

export default ResponsiveHoc(IntermediateXl);
