import get from 'lodash/get';

export default content => ({
  authenticateApiUrl: content.apis['hodor_vod.authenticate'].url,
  pathApiUrlMask: content.apis['hodor_vod.ws_from_path'].url,
  player: {
    bundleURL: get(content.player, 'bundleURL', ''),
    playerConfig: {
      platform: get(content.player, 'platform', ''),
      context: get(content.player, 'context', ''),
      env: get(content.player, 'env', ''),
      base: get(content.player, 'base', ''),
      skin: get(content.player, 'skin', ''),
      params: {
        offerZone: get(content.player, 'zone', ''),
        vectorId: get(content.player, 'vectorId', ''),
        autoplay: false
      }
    }
  }
});
