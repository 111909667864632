import styled from 'styled-components';

import Picture from 'shared/components/presentational/Picture';
import { Top as BaseTop, Content } from '../../../../styles/components';
import BaseModal, {
  ModalScrollWrapper
} from 'shared/components/presentational/Modal/BaseModal';
import Checkbox from 'shared/components/presentational/Form/Checkbox';
import Icon from 'shared/components/presentational/Icons';
import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';
import Button from 'shared/components/presentational/Button';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import mediaQueries, {
  customMediaQuery
} from 'shared/helpers/styled-components/mediaQueries';
import { COLORS, FONT_WEIGHTS, FONT_FAMILIES } from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import {
  SEARCH_ENGINE_MODAL_X_PADDING,
  SEARCH_ENGINE_MODAL_BOTTOM_PADDING,
  SEARCH_ENGINE_PANEL_MAX_WIDTH
} from './constants';
import { SEARCH_HIGHLIGHT_CLASS, SEARCH_HIGHLIGHT_TAG } from '../constants';
import {
  HEADER_DESKTOP_HEIGHT,
  HEADER_MOBILE_HEIGHT
} from 'shared/constants/heights';
import { ellipsis } from 'shared/blocks/mea/shared/styles/mixins';

export const Modal = styled(BaseModal)<{ backgroundColor: string }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: ${HEADER_MOBILE_HEIGHT}px;
  background-color: ${props => props.backgroundColor};
  overflow: visible !important;

  & ${ModalScrollWrapper} {
    max-height: none !important;
  }

  ${mediaQueries.fromTablet`
    height: ${HEADER_DESKTOP_HEIGHT}px;
  `}
`;

export const InnerModal = styled.div<{ backgroundColor: string }>`
  padding: 10px;
  background-color: ${props => props.backgroundColor};

  ${mediaQueries.fromTablet`
    padding: 15px ${SEARCH_ENGINE_MODAL_X_PADDING}px ${SEARCH_ENGINE_MODAL_BOTTOM_PADDING}px;
  `}
`;

export const Top = styled(BaseTop)`
  display: none;
  margin-bottom: 6px;

  ${mediaQueries.fromTablet`
    display: flex;
  `}
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${mediaQueries.fromTablet`
    margin-top: 15px;
  `}
`;

export const Input = styled.input<{
  caretColor: string;
  withBottomRadius: boolean;
}>`
  width: 100%;
  height: 30px;
  background-color: ${COLORS.blackRussian};
  padding: 0 40px;
  border: none;
  border-radius: ${props => (props.withBottomRadius ? '4px' : '4px 4px 0 0')};
  caret-color: ${props => props.caretColor};
  color: ${COLORS.white};

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-family: ${FONT_FAMILIES.brand};
    font-size: ${pxInRem(16)};
    color: ${COLORS.white};
    opacity: 0.6;
  }
`;

export const SearchIcon = styled(Icon).attrs({ name: 'Search' })<{
  color: string;
}>`
  position: absolute;
  left: 8px;
  color: ${props => props.color};
`;

interface CloseButtonProps {
  color: string;
  hoverColor: string;
}

export const CloseButton = styled(Button).attrs<CloseButtonProps>(props => ({
  theme: buttonThemeFactory({
    color: props.color,
    backgroundColor: 'transparent',
    hover: {
      color: props.hoverColor
    }
  })
}))<CloseButtonProps>``;

export const SearchBoxCloseButton = styled(CloseButton)`
  position: absolute;
  right: 8px;
  height: fit-content;
  padding: 0 0 0 13px;

  ${mediaQueries.fromTablet`
    position: static;
    padding: 0 0 0 8px;
  `}
`;

export const SearchEnginePanelWrapper = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  padding: 0 10px;
`;

export const SearchEnginePanelWidthHolder = styled(Content)`
  max-width: ${SEARCH_ENGINE_PANEL_MAX_WIDTH}px;

  ${mediaQueries.fromTablet`
    padding-right: 24px;
    margin: 0 20px;
  `}

  ${customMediaQuery(
    `${SEARCH_ENGINE_PANEL_MAX_WIDTH + SEARCH_ENGINE_MODAL_X_PADDING * 2}px`
  )`
    margin: 0 auto;
  `}
`;

export const SearchEnginePanel = styled.div`
  background-color: ${COLORS.blackRussian};
  padding: 0 40px 70px;
  color: ${COLORS.white};
  overflow-y: scroll;

  ${mediaQueries.fromTablet`
    height: fit-content;
    padding: 0 40px 20px;
  `}
`;

export const StickyBorderTop = styled.div<{ borderColor: string }>`
  width: 100%;
  position: sticky;
  top: 0;
  border-top: 1px solid ${props => props.borderColor};
  height: 10px;
  background-color: ${COLORS.blackRussian};
  z-index: 10;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 24px;
  padding: 8px 0 18px 0;

  ${mediaQueries.fromTablet`
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 37px;
    column-gap: 26px;
  `}
`;

export const SeeAllResultsButton = styled(Button)`
  display: none;
  padding: 10px;
  margin-left: 14px;
  margin-top: 12px;

  ${mediaQueries.fromTablet`
    display: inline-flex;
  `}
`;

export const MobileSeeAllResultsButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 10px;
  right: 10px;
  padding: 15px 20px;
  background-color: ${COLORS.black};
  display: flex;
  justify-content: center;
  align-items: center;

  ${SeeAllResultsButton} {
    margin: 0;
    width: 100%;
    text-align: center;

    ${mediaQueries.toPhablet`
      display: inline-flex;
    `}
  }

  ${mediaQueries.fromTablet`
    display: none;
  `}
`;

export const HitsList = styled.ol`
  padding: 18px 0 0 14px;
`;

export const HitsListTitle = styled.b`
  font-weight: ${FONT_WEIGHTS.demi};
  font-style: italic;
`;

export const HitsListEntryWrapper = styled.li`
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const HitsListEntry = styled(CustomLink)`
  display: flex;
  color: ${COLORS.white};
  text-align: left;
  cursor: pointer;
  font-family: inherit;
  line-height: 1.4;
  position: relative;
  &:hover {
    text-decoration: underline;
  }
`;

const HitsListEntryImageWrapper = styled.div`
  width: min(120px, 50%);
  min-width: min(120px, 50%);
  max-width: min(120px, 50%);
  margin-right: 14px;
  height: 70px;
`;

export const HitsListEntryImage = styled(Picture).attrs({
  customWrapper: HitsListEntryImageWrapper
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const HitsListEntryTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HitsListEntryTitle = styled.p`
  margin-bottom: 0;

  & > .${SEARCH_HIGHLIGHT_CLASS} {
    font-weight: ${FONT_WEIGHTS.bold};
    font-style: normal;
  }
`;

export const HitsListEntrySubtitle = styled.p`
  margin-bottom: 0;

  & > .${SEARCH_HIGHLIGHT_CLASS}, ${SEARCH_HIGHLIGHT_TAG} {
    font-weight: ${FONT_WEIGHTS.bold};
    font-style: normal;
    // reset api default style for new search engine
    background-color: unset;
    color: unset;
  }
`;

export const HitsListNoResultsLabel = styled.p`
  padding: 18px 0 0 14px;
  font-style: italic;
`;

export const RefinableSearchResultsWrapper = styled.div`
  padding: 18px 0;

  column-gap: 26px;

  & ${HitsList} {
    padding-left: 30px;
  }
`;

export const SearchIndicatorsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: baseline;
  margin-bottom: 16px;

  ${mediaQueries.fromTablet`
    flex-direction: row;
    margin-bottom: 40px;
  `}
`;

export const Breadcrumb = styled.p`
  width: 100%;
  font-size: ${pxInRem(14)};
  white-space: nowrap;
  margin: 10px 0 0;
  ${ellipsis()}

  ${mediaQueries.fromTablet`
    width: 33.33%;
    margin: 0;
  `}
`;

export const Chevron = styled.span`
  font-family: ${FONT_FAMILIES.base};
`;

export const RefinementsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueries.fromTablet`
    flex-direction: row;
  `}
`;

export const IndexFiltersWrapper = styled.div`
  display: none;

  ${mediaQueries.fromTablet`
    display: block;
    width: 33.33%;
  `}
`;

export const GhostButton = styled(Button).attrs({
  theme: buttonThemeFactory({
    backgroundColor: COLORS.sharkGray,
    color: COLORS.white,
    borderColor: COLORS.white
  })
})`
  padding: 6px 10px;
  width: fit-content;
`;

export const IndexFiltersMobileButton = styled(GhostButton)`
  margin-bottom: 24px;

  ${mediaQueries.fromTablet`
    display: none;
  `}

  & svg {
    margin-right: 8px;
  }
`;

export const MobileIndexFiltersModal = styled.div<{ visible: boolean }>`
  position: fixed;
  top: ${props => (props.visible ? '0' : '100%')};
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${COLORS.black};
  padding: 37px 30px;
  z-index: 10;
  transition: top 0.3s ease;
`;

export const MobileIndexFiltersModalCloseButton = styled(CloseButton).attrs({
  color: COLORS.white
})`
  position: absolute;
  top: 16px;
  right: 13px;
  height: fit-content;
  padding: 0;
`;

export const MobileIndexFiltersModalTitle = styled.h1`
  width: 66.66%;
  font-size: ${pxInRem(24)};
  font-weight: ${FONT_WEIGHTS.demi};
  font-style: italic;
  margin: 0 0 24px 0;
`;

export const MobileIndexFiltersModalButtonsWrapper = styled.div`
  display: flex;
  margin-top: 43px;
`;

export const MobileIndexFiltersModalClearButton = styled(GhostButton)`
  margin-right: 16px;
`;

export const MobileIndexFiltersModalApplyButton = styled(Button)`
  padding: 6px 10px;
`;

export const IndexFiltersTitle = styled.p`
  margin-bottom: 24px;
`;

export const IndexFiltersList = styled.ol``;

export const IndexFiltersListEntry = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const IndexFiltersCheckbox = styled(Checkbox).attrs({
  borderColor: COLORS.white
})`
  & button::after {
    border-radius: 3px;
  }
`;

export const IndexFiltersListEntryText = styled.span`
  margin-left: 16px;
  font-size: ${pxInRem(16)};
`;

export const RefinableSearchResultsList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 24px;

  ${mediaQueries.fromTablet`
    width: calc(66.66% - 26px);
    margin-left: 26px;
    row-gap: 40px;
  `}
`;

export const RefinableSearchResultsTitle = styled.h2`
  width: 100%;
  font-size: ${pxInRem(21)};
  font-weight: ${FONT_WEIGHTS.demi};
  font-style: italic;
  white-space: nowrap;
  margin: 0 0 10px 0;
  ${ellipsis()}

  ${mediaQueries.fromTablet`
    width: calc(66.66% - 26px);
    margin: 0 0 0 26px;
    padding-left: 14px;
  `}
`;

export const IndexWrapper = styled.div<{ hidden: boolean }>`
  display: ${props => (props.hidden ? 'hidden' : 'block')};
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  ${mediaQueries.fromTablet`
    margin-top: 40px;
  `}
`;

export const PaginationButton = styled(GhostButton)`
  padding: 12px 8px;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const PaginationText = styled.span`
  margin: 0 50px;
`;

export const ReversedIcon = styled(Icon)`
  transform: rotate(180deg);
`;

export const ArticleIcon = styled(Icon).attrs({ name: 'Article' })`
  width: 11px;
  height: 11px;
  color: black;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 32px 32px 0 0;
  border-color: ${COLORS.gainsboro} transparent transparent transparent;
  ${ArticleIcon} {
    position: absolute;
    top: -30px;
    left: 5px;
  }
`;

export const ShortcutsWrapper = styled.div`
  padding: 6px 0 12px;
`;
