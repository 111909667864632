import { css } from 'styled-components';

import { COLORS } from 'shared/constants/theme';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

export default {
  visual: {
    width: '1440px',
    maxWidth: '1440px',
    height: '430px',
    phoneHeight: '240px',
    shadowStyle: css`
      box-shadow: 0 -100px 100px ${COLORS.black} inset;

      // arbitrary breakpoint based on component max-width
      @media (min-width: 1440px) {
        box-shadow: 40px -40px 40px ${COLORS.black} inset,
          -40px -40px 40px ${COLORS.black} inset,
          0 -100px 100px ${COLORS.black} inset;
      }
    `,
    infosPosition: css`
      bottom: 40px;
      left: 65px;
      ${mediaQueries.toPhablet`
        bottom: 20px;
        left: 15px;
      `};
    `,
    titleSize: 18
  }
};
