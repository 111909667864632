import styled from 'styled-components';

import Hint from './Hint';

const FieldHint = styled(Hint)`
  margin-top: 6px;
  margin-bottom: 6px;
`;

export default FieldHint;
