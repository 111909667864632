import React from 'react';

const SvgCosArrow = ({ className, width = '20px', height = '20px' }) => (
  <svg
    viewBox="0 0 40 40"
    width={width}
    height={height}
    className={className}
    fill="currentColor"
    fillRule="nonzero"
  >
    <path d="M28.3 20.038c0-.3-.1-.6-.3-.9l-6.7-7.1c-.5-.4-1.3-.6-1.8-.1s-.5 1.4 0 1.8l4.7 5H12.9c-.7 0-1.2.6-1.2 1.2 0 .7.6 1.3 1.2 1.3h11.3l-4.7 5c-.4.4-.5 1.4 0 1.8.5.5 1.4.3 1.8-.1l6.7-7.1c.2-.2.3-.5.3-.8z" />
  </svg>
);

export default SvgCosArrow;
