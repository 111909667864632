import { BannerUrlByDevice } from 'shared/types';

export const getFormatedBannerUrlByDevice = (
  bannerUrlByDevice: BannerUrlByDevice
) => {
  const mediaArray = Object.values(bannerUrlByDevice);

  const sortedMediaArray = mediaArray.sort((a, b) => {
    if (a?.w === undefined || b?.w === undefined) {
      return 0;
    }
    return b.w - a.w;
  });

  const biggestImageMedia = sortedMediaArray.find(media => !media?.isVideo)
    ?.url as string;

  const smallestImageMedia = sortedMediaArray
    .reverse()
    .find(media => !media?.isVideo)?.url as string;

  const hasVideoMedia = mediaArray.some(media => media?.isVideo);

  const formatedBannerUrlByDevice = {
    desktop: { ...bannerUrlByDevice.desktop },
    tablet: { ...bannerUrlByDevice.tablet },
    mobile: { ...bannerUrlByDevice.mobile },
    mobileMini: { ...bannerUrlByDevice.mobileMini }
  };

  if (hasVideoMedia) {
    if (formatedBannerUrlByDevice.desktop.isVideo) {
      formatedBannerUrlByDevice.desktop.url = biggestImageMedia;
    }
    if (formatedBannerUrlByDevice.tablet?.isVideo) {
      formatedBannerUrlByDevice.tablet.url =
        biggestImageMedia || (smallestImageMedia as string);
    }
    if (formatedBannerUrlByDevice.mobile?.isVideo) {
      formatedBannerUrlByDevice.mobile.url = smallestImageMedia as string;
    }
    if (formatedBannerUrlByDevice.mobileMini?.isVideo) {
      formatedBannerUrlByDevice.mobileMini.url = smallestImageMedia as string;
    }
  }
  return formatedBannerUrlByDevice;
};
