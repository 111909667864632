import React, { Component } from 'react';
import styled from 'styled-components';

import { COLORS } from 'shared/constants/theme';

const Divider = styled.hr`
  margin: 0;
  border: 0;
  height: 0;
  border-bottom: 1px solid ${COLORS.gainsboro};
`;

export default class Separator extends Component {
  public shouldComponentUpdate() {
    return false;
  }

  public render(): React.ReactNode {
    return <Divider />;
  }
}
