/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/sort-comp */
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import debounce from 'lodash/debounce';

import Button from '../Button';
import { COLORS } from 'shared/constants/theme';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { BaseModalProps, InnerModalProps } from './types';

export const InnerModalWrapper = styled.div<{ maxHeight: number }>`
  overflow: hidden;
  max-height: ${props => props.maxHeight}px;
  min-width: 500px;
  ${mediaQueries.toPhablet`
      min-width: 90%;
   `}
`;

export const ModalCloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${COLORS.white};
`;

export const StyledButton = styled(Button)`
  padding: 15px;
`;

export const ModalScrollWrapper = styled.div`
  overflow-y: auto;
`;

class InnerModal extends PureComponent<InnerModalProps> {
  public readonly state = {
    modalHeight: 500,
    modalScrollWrapperHeight: 440
  };

  private resizeModal = () => {
    const modalHeight = Math.floor(window.innerHeight * 0.9);

    this.setState({
      modalHeight,
      modalScrollWrapperHeight: modalHeight - 60
    });
  };

  private resizeHandler = debounce(this.resizeModal, 100);

  public componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);

    this.resizeModal();
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  public render() {
    const { modalHeight, modalScrollWrapperHeight } = this.state;
    const { children, onCloseRequest, className, closeIconSize } = this.props;
    return (
      <InnerModalWrapper className={className} maxHeight={modalHeight}>
        {onCloseRequest && (
          <ModalCloseButton>
            <StyledButton
              theme={Button.themes.blackTransparent}
              onClick={onCloseRequest}
            >
              <Button.children.SvgIcon
                width={closeIconSize || '18'}
                height={closeIconSize || '18'}
                xlink="/sprite.svg#cos-svg-close"
              />
            </StyledButton>
          </ModalCloseButton>
        )}
        <ModalScrollWrapper style={{ maxHeight: modalScrollWrapperHeight }}>
          {children}
        </ModalScrollWrapper>
      </InnerModalWrapper>
    );
  }
}

const BaseModal = ({
  children,
  modalProps = {},
  className
}: BaseModalProps) => (
  <Modal
    {...modalProps}
    ariaHideApp={false}
    bodyOpenClassName="preventScroll"
    onRequestClose={modalProps.onCloseRequest}
  >
    <InnerModal
      onCloseRequest={modalProps.onCloseRequest}
      className={className}
      closeIconSize={modalProps.closeIconSize}
    >
      {children}
    </InnerModal>
  </Modal>
);

export default BaseModal;
