import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { Content, FormattedProps } from './types';
import { formatSubscriptionOptions } from '../shared/propsFormatter';
import { PropsFormatterUtils } from 'shared/types';
import { COLORS } from 'shared/constants/theme';

export const getFormattedApis = (content: Content): FormattedProps['apis'] => ({
  registerSubscriptionUpgrade: {
    url: content.apis?.register_subscription_upgrade?.url ?? '',
    method: content.apis?.register_subscription_upgrade?.method || 'POST'
  },
  getAvailableProposals: {
    url: content.apis?.get_available_proposals?.url ?? '',
    method: content.apis?.get_available_proposals?.method || 'GET'
  },
  retrieveOffersByProposals: {
    url: content.apis?.retrieve_offers_by_proposals?.url ?? '',
    method: content.apis?.retrieve_offers_by_proposals?.method || 'POST'
  },
  getSubscriberData: {
    url: content.apis?.getSubscriberData?.url ?? '',
    method: content.apis?.getSubscriberData?.method ?? 'GET',
    parameters: content.apis?.getSubscriberData?.parameters || {}
  }
});

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: content.title.label,
  subscriptionOptions: formatSubscriptionOptions(
    content.subscriptionOptions || []
  ),
  titleColor: content.title.color || COLORS.black,
  titleHtmlTag: content.title.htmlTag,
  summaryTosAgreementText: content.summaryTosAgreementText ?? '',
  redirectPopinText: content.redirectPopinText ?? '',
  redirectPopinLinkLabel: content.redirectPopinLinkLabel ?? '',
  redirectPopinLinkTheme: themeFactory({
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: content.redirectPopinLinkLabelColor ?? '',
    textDecoration: 'underline'
  }),
  redirectPopinUrl: getLinkHrefWithAuthentication(
    content.redirectPopinLink ?? ''
  ),
  confirmationButtonLabel: content.buttons?.confirmationButton?.label ?? '',
  confirmationButtonTheme: themeFactory({
    backgroundColor: content.buttons?.confirmationButton?.backgroundColor,
    color: content.buttons?.confirmationButton?.labelColor
  }),
  apis: getFormattedApis(content)
});
