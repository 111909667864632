import { SummaryFormValues } from '../shared/types';
import { FIELDS } from '../shared/constants';

export enum ACTION_TYPES {
  SET_OFFER = '@Eshop/SUMMARY_SET_OFFER',
  INVALID_OFFER = '@Eshop/SUMMARY_INVALID_OFFER',
  FLUSH = '@Eshop/SUMMARY_FLUSH',
  PENDING = '@Eshop/SUMMARY_PENDING',
  SUBMIT_PENDING = '@Eshop/SUMMARY_SUBMIT_PENDING',
  SUBMIT_SUCCESS = '@Eshop/SUMMARY_SUBMIT_OFFER_SUCCESS',
  SUBMIT_ERROR = '@Eshop/SUMMARY_SUBMIT_OFFER_ERROR'
}

export const INITIAL_DEFAULT_VALUES: SummaryFormValues = {
  [FIELDS.OFFER_TERMS_AGREEMENT]: false,
  [FIELDS.IBAN_CODE]: '',
  [FIELDS.REFUSE_NEWSLETTER]: false
};
