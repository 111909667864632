import React from 'react';

import { IconProps } from '../types';

export default ({ className, width = '1em', height = '1em' }: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-2.1 0 19.244 17.172"
  >
    <path d="M7.522.001a4.32 4.496 0 1 1 .001 8.995A4.496 4.496 0 0 1 7.522 0m-8.565 17.172c-.88 0-1.364-1.004-.841-1.712 2.13-2.88 5.547-4.753 9.406-4.753 3.86 0 7.275 1.872 9.406 4.753.523.708.04 1.712-.841 1.712z"></path>
  </svg>
);
