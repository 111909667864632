/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2021 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Content, FormattedProps } from './types';

export default (content: Content): FormattedProps => ({
  apis: {
    getSubscriberData: {
      url: content.apis?.getSubscriberData?.url ?? '',
      method: content.apis?.getSubscriberData?.method || 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    },
    getSubscriptionAndOptions: {
      url:
        content.apis?.['selfcare.subscription.get_subscription_and_options']
          .url ?? '',
      method:
        content.apis?.['selfcare.subscription.get_subscription_and_options']
          .method || 'GET'
    }
  }
});
