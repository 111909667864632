/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { connect } from 'react-redux';

import { getDomainURL } from 'shared/modules/hostname/selectors/getDomainURL';
import JsonLd from '../components/JsonLd';

const mapStateToProps = state => {
  const domainURL = getDomainURL(state);
  const getAbsoluteLink = (pageURI = '') => `${domainURL}${pageURI}`;

  return {
    getAbsoluteLink
  };
};

export default connect(mapStateToProps)(JsonLd);
