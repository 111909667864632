/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { createSelector } from 'reselect';
import dayjs from 'dayjs';

import { getPrograms } from './';
import {
  GROUPING_MIN_PROGRAM_DURATION,
  GROUPING_MAX_GROUP_DURATION
} from '../constants';
import { getDurationInMinutes, getRelativeWidthForDuration } from '../helpers';

/**
 * This method aims to compute data to avoid this task to component
 * it give a width in % to the ProgramModel (how big should be the component on the grid)
 * it groups ProgramModels that have a width too low to be displayed
 *
 *  * return <Array>
 * eg :[ [ProgramModel], [ProgramModel, ProgramModel, ...], [ProgramModel], .... ]
 */

export const computeProgramsForComplexGrid = (programs, dayStart, timezone) => {
  const results = [];

  programs
    .reduce((acc, curr, index) => {
      const beginningOfProgram = dayjs.unix(curr.startTime).tz(timezone);
      if (beginningOfProgram.isSameOrAfter(dayStart)) {
        const nextProgram = programs[index + 1];

        const duration = getDurationInMinutes(
          curr.startTime,
          nextProgram ? nextProgram.startTime : curr.endTime
        );

        const width = getRelativeWidthForDuration(duration);

        const program = {
          ...curr,
          duration,
          width
        };

        return [...acc, [program]];
      }
      return acc;
    }, [])
    .forEach((ps, index) => {
      // Programs with duration < GROUPING_MIN_PROGRAM_DURATION are grouped
      // If the grouped programs total duration exceed GROUPING_MAX_GROUP_DURATION
      // an other group is created

      if (index && ps[0].duration < GROUPING_MIN_PROGRAM_DURATION) {
        const previousIndex = results.length - 1;
        const previousPrograms = results[previousIndex];
        if (
          previousPrograms[0].duration < GROUPING_MIN_PROGRAM_DURATION &&
          results[previousIndex].reduce((acc, curr) => acc + curr.duration, 0) <
            GROUPING_MAX_GROUP_DURATION
        ) {
          results[previousIndex] = [...previousPrograms, ps[0]];
        } else {
          results.push(ps);
        }
      } else {
        results.push(ps);
      }
    });

  return results;
};

/**
 * This selector returns an object with channel zapNumber as key
 * and an array for programs as value
 *
 * @param <Int> day - the day index
 * @param <Dayjs> dayStart - the dayjs date that represent the selected day at day start time.
 * @param <String> timezone - the timezone that need to be applied to programs' date
 *
 * return <Object>
 * eg :
 * {
 *   <zapNumber> : [ [ProgramModel], [ProgramModel, ProgramModel], [ProgramModel], .... ],
 *   <zapNumber> : [ [ProgramModel], ... ],
 *   ....
 * }
 */
export default (day, dayStart, timezone) =>
  createSelector([getPrograms(day)], allProgramsByZapNumber =>
    Object.keys(allProgramsByZapNumber).reduce((_acc, zapNumber) => {
      const acc = _acc;
      acc[zapNumber] = computeProgramsForComplexGrid(
        allProgramsByZapNumber[zapNumber],
        dayStart,
        timezone
      );
      return acc;
    }, {})
  );
