import { useQuery } from 'react-query';
import create, { State } from 'zustand';
import { useCallback } from 'react';

import { SelfcareApis } from 'shared/modules/page/selectors/getSelfcareApis';
import getNumSubscriber from 'client/helpers/getNumSubscriber';
import { QUERY_KEYS } from '../constants';
import abstractWebservice, {
  AbstractResponse
} from '../webservices/abstractWebservice';

export interface Contract {
  name?: string;
  numContract: string;
  indActive: string;
  indCancelled: string;
  indReturned: string;
  indStopRenewal?: string;
  paymentMethod: string;
  indDirectDebit: string;
  indCash: string;
  endDate: string;
  nbPoints: string;
  listOfOffers: {
    code: string;
    label: string;
    shortLabel?: string | null;
    htmlLabel?: string | null;
    main: string;
    metaOfferCode: string;
  }[];
  listOfMaterials: {
    numMaterial: string;
    typeMaterial: string;
  }[];
  contractId: string;
  edocumentsList?: any[];
  providerId?: string;
  providerRef?: string;
  broadcastingWay?: string;
}

export interface SubscriberData {
  returnCode: string;
  errorCode?: string;
  errorLabel?: string;
  idBase: string;
  numSubscriber: string;
  subscriberid?: string;
  title: string;
  lastName: string;
  firstName: string;
  numContract: string;
  listOfContracts: Contract[];
  contractId?: string;
  indLcen: string;
  indLcenCie: string;
  prospectMessage?: string;
  prospect?: boolean | null;
}

interface Error {
  prospectMessage?: string;
  prospect?: boolean | null;
  status?: number;
}

interface AttemptsState extends State {
  attempts: number;
  addAttempt: () => void;
  reset: () => void;
}

export const useAttempts = create<AttemptsState>(set => ({
  attempts: 0,
  addAttempt: () => set(state => ({ attempts: state.attempts + 1 })),
  reset: () => {
    set(() => ({ attempts: 0 }));
  }
}));

const useSubscriberDataQuery = (
  apis: Pick<SelfcareApis, 'getSubscriberData'>,
  redirectToSilentLogin: () => void,
  setNumContractIfNeeded: (numContract: string) => void,
  enabled = true
) => {
  const numSubscriber = getNumSubscriber();

  const { attempts, addAttempt } = useAttempts();

  const errorCallback = useCallback(
    (error: Error) => {
      if (attempts < 1 && error.status === 403) {
        addAttempt();
        redirectToSilentLogin();
        return;
      }
    },
    [attempts, addAttempt]
  );

  const successCallback = useCallback(
    (subscriberData: AbstractResponse & SubscriberData) => {
      const { numContract } = subscriberData;
      if (numContract) {
        setNumContractIfNeeded(numContract);
      }
    },
    [setNumContractIfNeeded]
  );

  return useQuery<AbstractResponse & SubscriberData, Error>(
    QUERY_KEYS.SUBSCRIBER_DATA,
    () => {
      const { getSubscriberData } = apis;
      return abstractWebservice<AbstractResponse & SubscriberData>(
        getSubscriberData,
        undefined,
        { params: { ...getSubscriberData?.parameters, numSubscriber } }
      );
    },
    {
      onSuccess: successCallback,
      onError: errorCallback,
      enabled
    }
  );
};

export default useSubscriberDataQuery;
