import get from 'lodash/get';

import { FETCHING_STATES } from '../reducers';

export default id => state => {
  if (!id) return false;
  const fetchingState = get(
    state,
    `shops.shared.shopsDetails.byId[${id}]`,
    FETCHING_STATES.PENDING
  );
  return fetchingState !== FETCHING_STATES.SUCCESS;
};
