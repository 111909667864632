import React from 'react';

import { FormattedProps, TEMPLATES } from '../types';
import { Wrapper } from './components';
import Title from 'shared/components/presentational/Title';
import { TilesV1 } from './TilesV1';
import { TilesV2 } from './TilesV2';

const Tiles = ({
  template,
  columns,
  subtitle,
  tiles,
  title,
  titleHtmlTag
}: FormattedProps) => {
  return (
    <Wrapper data-testid="oneShop.titles">
      <Title
        subtitle={subtitle}
        titleHtmlTag={titleHtmlTag}
        titleLabel={title}
      />
      {template === TEMPLATES.V1 ? (
        <TilesV1 tiles={tiles} columns={columns} />
      ) : (
        <TilesV2 tiles={tiles} />
      )}
    </Wrapper>
  );
};

export default Tiles;
