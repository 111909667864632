import getChannelsByZapNumber from './getChannelsByZapNumber';

export default (state, { keptChannels }) => {
  const channels = getChannelsByZapNumber(state);

  if (keptChannels && keptChannels.length) {
    return Object.entries(channels).reduce(
      (filteredChannels, [zapNumber, channel]) => {
        const channelEpgId = parseInt(channel.epgID, 10);
        if (keptChannels.includes(channelEpgId)) {
          return {
            ...filteredChannels,
            [zapNumber]: channel
          };
        }
        return filteredChannels;
      },
      {}
    );
  }

  return channels;
};
