import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 30 30" width={width} height={height} className={className}>
    <path
      d="M10.5 23.6c6.7 6.7 11.9 8.4 18 2.3.9-1 1.9-2.2.6-3.5l-4.9-3.7c-1.5-.9-2.1-.3-2.9.6-2.7 3-5.6 2-7.6 0l-3-2.9c-2-2-3-4.8 0-7.6.9-.8 1.4-1.4.6-2.9L7.6 1C6.3-.3 5 .6 4.1 1.6-2 7.6-.3 12.8 6.4 19.5l4.1 4.1z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
