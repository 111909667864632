/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*       */

import axios from 'axios';

import ConfigHelper from '../../helpers/config/index';
import { INVALID_SLUG_FORMAT_ERROR } from 'shared/constants/api';

/**
 * Remove proxy settings from environment variables to fix a bug with axios
 * @see https://stackoverflow.com/a/43439886
 *
 *
 */
if (process.env.http_proxy) {
  delete process.env.http_proxy;
}
if (process.env.HTTP_PROXY) {
  delete process.env.HTTP_PROXY;
}
if (process.env.https_proxy) {
  delete process.env.https_proxy;
}
if (process.env.HTTPS_PROXY) {
  delete process.env.HTTPS_PROXY;
}

function getHttpsAgent() {
  if (process.env.BUILD_TARGET === 'server' && process.env.PROXY_AUTOMATES) {
    const HttpsProxyAgent = require('https-proxy-agent').HttpsProxyAgent;
    return new HttpsProxyAgent(process.env.PROXY_AUTOMATES);
  }
  return undefined;
}

export const HTTP_CLIENT_COMMON_CONFIG = {
  timeout: ConfigHelper.getClientConfig('api.structure.timeout'),
  headers: {
    'X-UNIQUE-ID': '-'
  },
  httpsAgent: getHttpsAgent()
};

export const DefaultHttpClient = axios.create(HTTP_CLIENT_COMMON_CONFIG);

export const APIHttpClient = axios.create({
  baseURL: process.env.API_BASE_URL
    ? process.env.API_BASE_URL
    : ConfigHelper.getClientConfig('api.structure.base_url'),
  ...HTTP_CLIENT_COMMON_CONFIG
});

APIHttpClient.interceptors.request.use(requestInterceptor);

const INVALID_SLUG_FORMAT = /.*(http(s?)):\//i;

/**
 *
 * @param {AxiosRequestConfig} config
 * @returns
 */
export async function requestInterceptor(config) {
  if (INVALID_SLUG_FORMAT.test(config.url)) {
    throw new Error(`${INVALID_SLUG_FORMAT_ERROR} : ${config.url}`);
  }

  return config;
}
