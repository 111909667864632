/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import { createTemplate } from 'shared/helpers/template';
import { FIELDS } from './constants';

export default (content, { getLinkHrefWithAuthentication }) => ({
  title: get(content, 'title', ''),
  apiUrl: get(content, 'api.url', ''),
  apiMethod: get(content, 'api.method', 'POST'),
  helpers: {
    [FIELDS.DECODER_NUMBER]: get(content, 'decodeurToolTip.text', ''),
    [FIELDS.SUBSCRIBER_NUMBER]: get(content, 'subscriberToolTip.text', '')
  },
  infoText: get(content, 'text', ''),
  infoMedia: get(content, 'media.link.href', ''),
  infoMediaAlt: get(content, 'media.description', 'media.filename'),
  termsLink: getLinkHrefWithAuthentication(get(content, 'useTerms.link', null)),
  submitButtonLabel: get(content, 'validationSubmitButton.label'),
  authenticationUrl: getLinkHrefWithAuthentication(
    get(content, 'authenticationLink')
  ),
  zones: Object.entries(get(content, 'zones', {})).reduce(
    (options, [key, value]) => [
      ...options,
      { value: key.toUpperCase(), label: value }
    ],
    []
  ),
  confirmRegisterTitle: get(content, 'confirmRegister.title', ''),
  confirmRegisterTextCreator: createTemplate(
    get(content, 'confirmRegister.text', '')
  ),
  legalNoticeTitle: get(content, 'legalNotices.title', ''),
  legalNoticeText: get(content, 'legalNotices.text', '')
});
