export const ACTION_TYPES = {
  FETCH_VOD_URL_REQUEST: '@VOD_LEGACY/FETCH_URL_REQUEST',
  FETCH_VOD_URL_SUCCESS: '@VOD_LEGACY/FETCH_URL_SUCCESS',
  FETCH_VOD_URL_ERROR: '@VOD_LEGACY/FETCH_URL_ERROR'
};

export const TEMPLATE_TYPES = {
  DETAIL: 'detailPage',
  FOLDER: 'folder'
};
