/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';

import Loader from 'shared/blocks/selfcare/shared/components/Loader';
import Error from 'shared/components/presentational/Error/Error';
import Container from 'shared/components/presentational/Container/Container';
import ResponsiveHoc from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';
import { Wrapper } from '../styles/components';
import { Props } from '../types';
import ArticleContentComponent from '../../shared/components/ArticleContent';
import formatArticleContent from '../../shared/helpers/formatArticleContent';
import useArticleContentQuery from '../../shared/hooks/useArticleContent';

const Article = ({
  url,
  miniSiteDomain,
  lang,
  mediaQueries,
  domainUrl
}: Props) => {
  const { data, error, isLoading } = useArticleContentQuery(url, {
    miniSiteDomain,
    lang
  });

  if (error || !url) {
    return (
      <Wrapper>
        <Container>
          <Error />
        </Container>
      </Wrapper>
    );
  }

  if (isLoading || !data) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }
  const article = formatArticleContent(data, domainUrl);
  return (
    <Wrapper>
      <ArticleContentComponent article={article} mediaQueries={mediaQueries} />
    </Wrapper>
  );
};

export default ResponsiveHoc(Article);
