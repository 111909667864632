import React from 'react';

import { StyledButton, Label, Wrapper } from './styles/components';

export const Button = ({
  label,
  link,
  type,
  fullWidthMobile,
  fullWidthDesktop,
  isSecondary,
  ...props
}: {
  label: string;
  link?: string;
  type?: string;
  fullWidthMobile?: boolean;
  fullWidthDesktop?: boolean;
  isSecondary?: boolean;
}) => {
  return (
    <Wrapper {...props}>
      <StyledButton
        type={type}
        fullwidthmobile={fullWidthMobile}
        fullwidthdesktop={fullWidthDesktop}
        isSecondary={isSecondary}
        to={link}
      >
        <Label>{label}</Label>
      </StyledButton>
    </Wrapper>
  );
};
