/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

const Wrapper = styled.div`
  width: 100%;
  min-height: 150px;
  padding: 25px;
  background-color: #f00;
  color: #fff;
  text-align: center;
  display: block;
`;

const Title = styled.p`
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  font-size: 1.3rem;
  padding: 20px;
`;

const Description = styled.p`
  font-size: 1rem;
  padding: 0 0 20px;
`;

const DEFAULT_TITLE = 'Error block';

const ErrorBlock = ({ title, description, hide }) => {
  if (hide) {
    return false;
  }

  return (
    <Wrapper hide={hide}>
      <Title>{title || DEFAULT_TITLE}</Title>
      <Description>{description}</Description>
    </Wrapper>
  );
};

ErrorBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hide: PropTypes.bool
};

export default ErrorBlock;
