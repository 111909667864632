import get from 'lodash/get';

export default function getResubscriptionConfig(
  state: object
): {
  CPAYScode: number;
  currencies: string[];
  operatorName: string;
} {
  return get(state, 'page.data.zone.resubscriptionConfig', {});
}
