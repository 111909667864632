import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg
    viewBox="0 0 229 228"
    width={width}
    height={height}
    className={className}
  >
    <g fillRule="nonzero" fill="none">
      <circle fill="currentColor" cx={115} cy={114} r={113.4} />
      <path
        fill="#FFF"
        d="M45.4 110.8v23.9H.4v-23.9h45zM92.4 46.5v135.7H66.3V69.9h-13V46.5zM166.7 46.5l-25.9 52.9.4.4c5-4.5 9.7-7.4 16.9-7.4 20.5 0 27.4 25.7 27.4 42.1 0 24.3-9.5 49.9-38 49.9-27 0-37.1-23.9-37.1-47.7 0-24.7 9-45.4 19.3-67.1l11.2-23h25.8v-.1zm-30.2 93.1c0 6.5 2.3 22 11.3 22 10.3 0 11.5-17.3 11.5-24.1 0-6.7-1.6-21.2-10.8-21.2-10.1-.1-12 16.5-12 23.3z"
      />
    </g>
  </svg>
);
