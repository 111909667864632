import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 29 61" width={width} height={height} className={className}>
    <path
      d="M28 1V10.8c0 .4-.1.5-.5.5h-5c-.6 0-1.1.1-1.7.2-1.1.2-1.8 1.1-1.9 2.2-.1 2.2-.1 4.3-.1 6.5 0 .4.3.3.5.3H28.1c-.4 3.5-.7 6.9-1.1 10.3h-8.2V60.7H7.6V58 31.5v-.8H.1v-.5-9.3c0-.4.1-.5.5-.5H7c.5 0 .6-.1.6-.6 0-2.8 0-5.6.1-8.3.1-2 .5-4 1.4-5.8 1-2 2.5-3 4.8-4 .9-.4 2.5-.7 3.6-.8"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
