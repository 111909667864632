import { TFunction } from 'i18next';
import replace from 'lodash/replace';

export type Error =
  | { key: string; values: { [key: string]: string } }
  | string
  | undefined
  | false;

export default function translateStaticYupError(error: Error, t: TFunction) {
  if (!error) {
    return undefined;
  }
  if (typeof error === 'string') {
    return t(error);
  }

  let errorI18n = t(error.key);

  if (error.values) {
    for (const key in error.values) {
      errorI18n = replace(errorI18n, '{' + key + '}', error.values[key]);
    }
  }
  return errorI18n;
}
