import { ACTION_TYPES } from '../constants';

export default (url: string, originUrl: string, data: any) => ({
  type: ACTION_TYPES.FETCH_MORE_VOD_SUCCESS,
  payload: {
    url,
    originUrl,
    data
  }
});
