import { createSelector } from 'reselect';
import get from 'lodash/get';
import queryString from 'querystring';

import getCategoriesByName from './getCategoriesByName';

export default createSelector(
  getCategoriesByName,
  state =>
    queryString.parse(get(state, 'router.location.search', '?').slice(1))
      .category || null,
  (categories, categoryParam) =>
    categoryParam && Object.keys(categories).includes(categoryParam)
      ? categoryParam
      : null
);
