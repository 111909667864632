import shouldAuthenticate from 'shared/blocks/vod/hodor/selectors/shouldAuthenticate';
import authenticateError from 'shared/blocks/vod/hodor/actions/authenticateError';
import authenticateRequest from 'shared/blocks/vod/hodor/actions/authenticateRequest';
import authenticateSuccess from 'shared/blocks/vod/hodor/actions/authenticateSuccess';
import authenticateWebservice from 'shared/blocks/vod/hodor/webservices/authenticate';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import clientCookies from 'client/cookies';

export default authenticateApiUrl => async (dispatch, getState) => {
  if (shouldAuthenticate(getState())) {
    const appLocation = clientCookies.get(COOKIE_KEYS.ZONE).toLowerCase();
    const offerZone = clientCookies.get('offerZone');
    const isAuthenticated = clientCookies.get('isAuthenticated') === 'true';
    const macros = clientCookies.get('macroEligibility');
    const micros = clientCookies.get('microEligibility');

    dispatch(authenticateRequest());
    try {
      const data = await authenticateWebservice(
        authenticateApiUrl,
        appLocation,
        offerZone,
        macros,
        isAuthenticated,
        micros
      );

      dispatch(authenticateSuccess(data));
    } catch (error) {
      dispatch(authenticateError(error));
    }
  }
};
