import React, { useCallback } from 'react';

import Icon from 'shared/components/presentational/Icons';
import SelectField from 'shared/components/presentational/Select/SelectField';
import {
  buttonTheme,
  Columns,
  LinkColumn,
  NavItem,
  SocialButton,
  SocialNetworks,
  StyledAside,
  StyledButton,
  StyledFooter,
  StyledSelectField,
  StyledText,
  Wrapper
} from './Footer.styled';
import { COLORS } from 'shared/constants/theme';
import {
  Columns as ColmunsType,
  FormattedProps,
  PropsFromDispatch,
  PropsFromState
} from '../types';

type Props = FormattedProps & PropsFromState & PropsFromDispatch;

const Footer = ({
  backgroundColor,
  columns,
  currentZone,
  hoverColor,
  pushURLHandler,
  socialNetworkLinks,
  textColor,
  zones
}: Props) => {
  const onZoneSelectionChanged = useCallback(
    function onZoneSelectionChanged(bigram: string) {
      pushURLHandler(`/${bigram}`);
    },
    [pushURLHandler]
  );

  const showCookieBanner = useCallback(function showCookieBanner() {
    if (typeof window !== 'undefined' && window.Didomi) {
      window.Didomi.preferences.show();
    }
  }, []);

  const getButtonProps = useCallback(
    function getButtonProps(item: ColmunsType) {
      const props: {
        onClick?: () => void;
        to?: string;
        disabled?: boolean;
      } = {};

      if (item.isCookie) {
        props.onClick = showCookieBanner;
      } else {
        props.to = item.link;
        props.disabled = true;
      }
      return props;
    },
    [showCookieBanner]
  );

  return (
    <StyledFooter backgroundColor={backgroundColor} textColor={textColor}>
      <Wrapper>
        <Columns>
          {columns.map((column, columnIndex) => (
            <LinkColumn key={`column-${columnIndex}`}>
              <nav>
                <ul>
                  {column.map((item, navItemIndex) => (
                    <NavItem key={`nav-item-${navItemIndex}`}>
                      <StyledButton
                        {...getButtonProps(item)}
                        theme={buttonTheme({ textColor, hoverColor })}
                      >
                        <StyledText>{item.label}</StyledText>
                      </StyledButton>
                    </NavItem>
                  ))}
                </ul>
              </nav>
            </LinkColumn>
          ))}

          <StyledAside>
            <SocialNetworks>
              {socialNetworkLinks.facebook && (
                <SocialButton
                  to={socialNetworkLinks.facebook}
                  theme={buttonTheme({ textColor, hoverColor })}
                >
                  <Icon name="Facebook" width="20" height="20" />
                </SocialButton>
              )}

              {socialNetworkLinks.twitter && (
                <SocialButton
                  to={socialNetworkLinks.twitter}
                  theme={buttonTheme({ textColor, hoverColor })}
                >
                  <Icon name="Twitter" width="20" height="20" />
                </SocialButton>
              )}

              {socialNetworkLinks.instagram && (
                <SocialButton
                  to={socialNetworkLinks.instagram}
                  theme={buttonTheme({ textColor, hoverColor })}
                >
                  <Icon name="Instagram" width="20" height="20" />
                </SocialButton>
              )}

              {socialNetworkLinks.dailymotion && (
                <SocialButton
                  to={socialNetworkLinks.dailymotion}
                  theme={buttonTheme({ textColor, hoverColor })}
                >
                  <Icon name="Dailymotion" width="24" height="24" />
                </SocialButton>
              )}

              {socialNetworkLinks.youtube && (
                <SocialButton
                  to={socialNetworkLinks.youtube}
                  theme={buttonTheme({ textColor, hoverColor })}
                >
                  <Icon name="Youtube" width="24" height="24" />
                </SocialButton>
              )}

              {socialNetworkLinks.spotify && (
                <SocialButton
                  to={socialNetworkLinks.spotify}
                  theme={buttonTheme({ textColor, hoverColor })}
                >
                  <Icon name="Spotify" width="22" height="22" />
                </SocialButton>
              )}
            </SocialNetworks>
            <StyledSelectField>
              <SelectField
                options={zones.map(zone => ({
                  label: zone.name,
                  value: zone.bigram
                }))}
                currentValue={currentZone as string}
                onSelectionChanged={onZoneSelectionChanged}
                backgroundColor={backgroundColor}
                textColor={textColor}
                hoverColor={hoverColor}
                borderColor={COLORS.white}
                selectedColor={COLORS.white}
              />
            </StyledSelectField>
          </StyledAside>
        </Columns>
      </Wrapper>
    </StyledFooter>
  );
};

export default Footer;
