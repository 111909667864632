/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { COLORS } from 'shared/constants/theme';
import Grid from 'shared/components/presentational/Grid/Grid';

const Wrapper = styled(Grid)`
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.mercury};
  padding: 10px;
  transition: box-shadow ease 0.2s;
  min-height: 125px;

  &:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
  }
`;

const cols = {
  hd: 6,
  desktop: 6,
  tablet: 5,
  phablet: 3,
  phone: 3,
  phoneMini: 1
};

export default class Mosaic extends PureComponent {
  render() {
    const { className, children } = this.props;

    return (
      <Wrapper cols={cols} className={className}>
        {React.Children.map(children, this.renderItem)}
      </Wrapper>
    );
  }

  renderItem = child => <Item key={child.key}>{child}</Item>;
}
