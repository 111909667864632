/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import styled from 'styled-components';

import Picture from 'shared/components/presentational/Picture';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { DIMENSIONS } from 'shared/constants/theme';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const SlideWrapper = styled.div``;

export const SlideImageWrapper = styled.div`
  margin: 0 0 12px;
  overflow: hidden;
`;

export const SlideImage = styled(Picture).attrs({
  customWrapper: SlideImageWrapper
})`
  max-height: 200px;
  width: calc(100% + ${DIMENSIONS.CONTAINER_MINIMUM_PADDING * 2}px);
  object-fit: cover;

  ${mediaQueries.fromPhablet`
    width: 100%;
    max-height: 100%;
  `}
`;
