import React from 'react';

import { FormattedFragment, FragmentType } from './types';
import {
  FragmentWrapper,
  MediaFragmentWrapper,
  ImageFragmentWrapper,
  TextFragment,
  ImageFragment,
  VideoFragment
} from './styles/components';

export default (fragments: FormattedFragment[]) => {
  return fragments.map((fragment: FormattedFragment, index) => {
    if (fragment.type === FragmentType.text) {
      return (
        <FragmentWrapper key={index}>
          <TextFragment html={fragment.content} />
        </FragmentWrapper>
      );
    }
    if (fragment.type === FragmentType.image) {
      const { url, alt, link } = fragment;

      if (link) {
        return (
          <ImageFragmentWrapper key={index} to={link}>
            <ImageFragment url={url} alt={alt ? alt : undefined} />
          </ImageFragmentWrapper>
        );
      }
      return (
        <FragmentWrapper key={index}>
          <ImageFragment url={url} alt={alt ? alt : undefined} />
        </FragmentWrapper>
      );
    }
    if (fragment.type === FragmentType.video) {
      const { videoUrl, posterUrl } = fragment;
      return (
        <MediaFragmentWrapper key={index}>
          <VideoFragment src={videoUrl} poster={posterUrl}>
            <p>Your browser doesn&apos;t support HTML5 video...</p>
          </VideoFragment>
        </MediaFragmentWrapper>
      );
    }
  });
};
