import clientCookies from 'client/cookies';
import ConfigHelper from '../config';
import getLinkWithZone from './getLinkWithZone';
import getLinkWithLang from './getLinkWithLang';
import { Link } from 'shared/types';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';

/**
 * Transform an api link into a path
 */
export default (link: Link | '' | null): string => {
  if (!(link && link.href)) {
    return '';
  }

  switch (link.rel) {
    case 'self':
    case 'prev':
    case 'next': {
      // Rel is "self", "next or "prev", it is a link on the API
      // Removing api base url from the link
      let path;
      let apiBaseUrl = ConfigHelper.getClientConfig('api.structure.base_url');
      if (link.href.includes(apiBaseUrl)) {
        path = link.href.replace(apiBaseUrl, '');
      } else {
        // For now the API is not consistent and may give self links in http while it is requested in https
        // Until fixed, the front must be smart enough to use the correct protocol on self links
        // So we're fallbacking here by using the base_url api protocol for these self links
        apiBaseUrl = apiBaseUrl.replace('https://', 'http://');
        path = link.href.replace(apiBaseUrl, '');
      }

      // Ensure leading slash
      return path.startsWith('/') ? path : `/${path}`;
    }
    case 'email':
      // Rel is "email", it is an email
      return `mailto:${link.href}`;
    case 'external': {
      let href = link.href;

      if (link.href.includes('{zone}')) {
        const allCookies = clientCookies.getAll();
        const zone = allCookies?.[COOKIE_KEYS.ZONE]?.toLowerCase();
        if (zone) {
          href = getLinkWithZone(link, zone);
        }
      }

      if (link.href.includes('{lang}')) {
        const allCookies = clientCookies.getAll();
        const lang = allCookies?.[COOKIE_KEYS.LANG];
        if (lang) {
          href = getLinkWithLang(link, lang);
        }
      }

      if (
        link.href.indexOf('https://') === -1 &&
        link.href.indexOf('http://') === -1 &&
        !link.href.startsWith('tel:')
      ) {
        return `http://${href}`;
      }

      return href;
    }

    default:
      // Unmatched rel, link is invalid
      return '';
  }
};
