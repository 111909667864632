import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { Button } from 'shared/blocks/one-shop/shared/components/Button/Button';

export const Wrapper = styled.div`
  display: flex;
  gap: var(--spacing-l);
  flex-direction: column;

  ${mediaQueries.fromTablet`
    flex-direction: row-reverse;
    `};
`;

export const PreviousButton = styled(Button)`
  display: flex;
  justify-content: flex-start;

  ${mediaQueries.fromTablet`
    order: 2;
  `};
`;

export const NextButton = styled(Button)`
  display: flex;
  justify-content: flex-end;

  ${mediaQueries.fromTablet`
    order: 1; 
  `};
`;
