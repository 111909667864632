import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Handle from './AccordionHandle';

/*
type AccordionHeaderProps = {
  children: any,
  className: ?string,
  isOpened: boolean,
  onHeaderClick: () => void,
  disabled: boolean
};
*/
const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
`;

export default class Header extends PureComponent {
  static displayName = 'Accordion.Header';

  static defaultProps = {
    disabled: false
  };

  render() {
    const {
      children,
      className,
      isOpened,
      onHeaderClick,
      disabled
    } = this.props;

    const toRender = React.Children.map(children, child => {
      if (
        child &&
        child.type &&
        child.type.displayName === Handle.displayName
      ) {
        return React.cloneElement(child, {
          ...child.props,
          isOpened
        });
      }

      return child;
    });

    return (
      <StyledButton
        className={className}
        onClick={disabled ? null : onHeaderClick}
        disabled={disabled}
        isOpened={isOpened}
      >
        {toRender}
      </StyledButton>
    );
  }
}
