import styled from 'styled-components';

import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

const TabButton = styled.button`
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${props =>
    props.isActive ? FONT_WEIGHTS.demi : FONT_WEIGHTS.normal};
  border-bottom: ${props =>
    props.isActive ? `2px solid ${COLORS.black}` : '2px solid transparent'};
  padding: 0 50px 8px;
  text-transform: uppercase;
  font-size: ${pxInRem(13)};
  cursor: pointer;
  margin-bottom: 10px;

  ${mediaQueries.toPhablet`
    padding: 0 30px 8px;
  `};
`;

export default TabButton;
