import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 36 36" width={width} height={height} className={className}>
    <path
      d="M29.8 8.2H16v-2h4.9c.5 0 1-.4 1-1V1.3c0-.5-.4-1-1-1H15c-.5 0-1 .4-1 1v6.9H6.2C3 8.2.3 11.7.3 16.1v10.8c0 .5.4 1 1 1H14v6.9c0 .5.4 1 1 1h5.9c.5 0 1-.4 1-1v-6.9h12.8c.5 0 1-.4 1-1V16c0-4.3-2.7-7.8-5.9-7.8zM16 2.3h4v2h-4v-2zm-5.9 23.6H2.3V16c0-3.2 1.8-5.9 3.9-5.9 2.1 0 3.9 2.7 3.9 5.9v9.9zm9.9 7.8h-4v-5.9h4v5.9zm13.7-7.8H12.1V16c0-2.3-.8-4.4-2-5.9h4V17c0 .5.4 1 1 1 .5 0 1-.4 1-1v-6.9h13.7c2.1 0 3.9 2.7 3.9 5.9v9.9z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
