import { connect } from 'react-redux';
import { compose } from 'recompose';

import { PropsFromState, Props, FormattedProps } from '../types';
import SubscriptionEndProcess from '../components/SubscriptionEndProcess';
import getNumContract from 'shared/blocks/selfcare/shared/selectors/getNumContract';
import getResubscriptionConfig from 'shared/modules/page/selectors/getResubscriptionConfig';
import getSubscriptionCurrency from 'shared/blocks/selfcare/shared/selectors/getSubscriptionCurrency';
import getQueryParams from 'shared/modules/router/selectors/getQueryParams';
import { VISA_STATUS_QUERY_PARAM_NAME } from 'server/visaProcessPaymentRecap/constants';

function mapStateToProps(state: any): PropsFromState {
  const numContract = getNumContract(state);
  const resubscriptionConfig = getResubscriptionConfig(state);
  const currency = getSubscriptionCurrency(state);
  const visaStatus = getQueryParams(state)[VISA_STATUS_QUERY_PARAM_NAME] as
    | string
    | null;

  return {
    numContract,
    visaStatus,
    CPAYScode: resubscriptionConfig.CPAYScode.toString(),
    operatorName: resubscriptionConfig.operatorName,
    currency: currency ?? resubscriptionConfig.currencies[0] ?? ''
  };
}

export default compose<Props, FormattedProps>(
  connect(mapStateToProps)
  // withSubscriberDataQuery
)(SubscriptionEndProcess);
