import get from 'lodash/get';

import { COLORS } from 'shared/constants/theme';
import { PropsFormatterUtils } from 'shared/types';
import { Content, FormattedProps, Block } from './types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: get(content, 'title.label', undefined),
  subtitle: content.subTitle ?? undefined,
  template: get(content, 'template', 'columns'),
  background: get(content, 'background', COLORS.white),
  titleColor: get(content, 'title.color', COLORS.black),
  titleHtmlTag: get(content, 'title.htmlTag'),
  columns: (get(content, 'blocks', []) as Block[]).map(block => ({
    text: get(block, 'text', ''),
    pictogram: get(block, 'pictogram.link.href', ''),
    buttonLabel: get(block, 'button.label', ''),
    buttonLink: getLinkHrefWithAuthentication(get(block, 'button.link', ''))
  }))
});
