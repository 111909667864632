/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import {
  Content,
  FormattedProps,
  FormattedPopinApash,
  PopinApashContent
} from './types';
import { Link, PropsFormatterUtils } from 'shared/types';
import { COLORS } from 'shared/constants/theme';

export const getFormattedPopins = (
  popins: PopinApashContent[],
  getLinkHrefWithAuthentication: { (link: Link | '' | null): string }
) =>
  popins
    .map(popin => ({
      title: popin.title,
      description: popin.text,
      template: popin.template,
      mediaDesktop: popin.mediaDesktop?.link.href ?? undefined,
      mediaMobile: popin.mediaMobile?.link.href ?? undefined,
      link: popin.backLink
        ? {
            label: popin.backLink.label,
            url: getLinkHrefWithAuthentication(popin.backLink.link ?? '')
          }
        : undefined,
      button: {
        label: popin.button?.label ?? '',
        link: getLinkHrefWithAuthentication(popin.button?.link ?? ''),
        theme: themeFactory({
          color: popin.button?.labelColor || COLORS.white,
          backgroundColor: popin.button?.backgroundColor || COLORS.myCanal
        })
      }
    }))
    .reduce((acc, d) => {
      acc[d.template] = { ...d };
      return acc;
    }, {} as FormattedPopinApash);

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  partnerId: content.partnerId ?? '',
  saleDeviceId: content.saleDeviceId ?? '',
  paymentType: content.paymentType ?? '',
  termsConditions: content.termsConditions ?? '',
  validationConditions: content.validationConditions,
  checkboxErrorMessage: content.requiredErrorMessage ?? '',
  errorColor: content.errorColor ?? COLORS.redApash,
  broadcastingWay: content.broadcastingWay,
  apis: {
    getSubscriberData: {
      url: content.apis?.getSubscriberData?.url ?? '',
      method: content.apis?.getSubscriberData?.method || 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    },
    apashPartnerId: {
      url: content.apis?.['apash.api.partner_id']?.url ?? '',
      method: content.apis?.['apash.api.partner_id']?.method || 'POST'
    },
    apashManagementActs: {
      url: content.apis?.['apash.api.management_acts']?.url ?? '',
      method: content.apis?.['apash.api.management_acts']?.method || 'POST'
    },
    apashManagementActsRenewal: {
      url: content.apis?.['apash.api.management_acts_renewal']?.url ?? '',
      method:
        content.apis?.['apash.api.management_acts_renewal']?.method || 'POST'
    },
    email: {
      url: content.apis?.email?.url ?? '',
      method: content.apis?.email?.method || 'POST'
    },
    cgaGetPeople: {
      url: content.apis?.cgaGetPeople?.url ?? '',
      method: content.apis?.cgaGetPeople?.method || 'GET'
    }
  },
  popins: getFormattedPopins(content.popins, getLinkHrefWithAuthentication)
});
