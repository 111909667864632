import { FocusEvent } from 'react';

/**
 * Awaken
 */

export interface Block<T> {
  id: number;
  type: string;
  container: string;
  content: T;
}

export interface Media {
  link: Link;
  filename: string;
  size: number;
  description: string | null;
  contentType: string;
}

export interface Image {
  link: Link;
  filename: string;
  size: number;
  description: string | null;
  contentType: string;
}

export enum Rel {
  self = 'self',
  external = 'external',
  prev = 'prev',
  next = 'next',
  email = 'email',
  cookies = 'cookies',
  signUp = 'signUpLink',
  loginLink = 'loginLink',
  logoutLink = 'logoutLink',
  modal = 'modal'
}

export interface Link {
  rel: Rel;
  href: string;
}

export interface Api<p = any> {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH';
  parameters?: p;
}

export interface TaskRouteReduxParams {
  dispatch: (action: any) => any;
  getState: () => any;
}

export interface Button {
  label: string;
  link: Link | null;
}

export interface ButtonApi extends Button {
  labelColor: string;
  backgroundColor: string;
}

export interface ThemeFactory {
  button: {
    padding: string | number | null;
    normal: Theme;
    hover: Theme;
    disabled: Theme;
  };
  link?: {
    linksColor: string;
    linksColorSecondary: string;
    linksFontWeight: string;
  };
}

export interface Theme {
  backgroundColor: string;
  borderColor: string;
  color: string;
  textDecoration: string;
}

export interface BaseForm {
  className?: string;
  name?: string;
  required?: boolean;
  placeholder?: string;
  onFocus?: (event: FocusEvent<HTMLElement>) => void;
  onBlur?: (event: FocusEvent<HTMLElement>) => void;
  error?: any;
  disabled?: boolean;
}

export interface ConfigHelperHOC {
  publicConfig: any; // TODO
}

export interface PropsFormatterUtils {
  id: string;
  getLinkHrefWithAuthentication: (link: Link | '' | null) => string;
}

/**
 * Zones
 */

export type KeyZones =
  | 'bf'
  | 'bi'
  | 'bj'
  | 'cm'
  | 'cv'
  | 'cg'
  | 'ci'
  | 'dj'
  | 'ga'
  | 'gm'
  | 'gh'
  | 'gw'
  | 'gn'
  | 'gq'
  | 'ml'
  | 'mr'
  | 'ne'
  | 'ng'
  | 'cd'
  | 'rw'
  | 'cf'
  | 'sl'
  | 'sn'
  | 'td'
  | 'tg';

export enum TitleHtmlTag {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4'
}
export interface CustomTitle {
  label?: string;
  color?: string;
  htmlTag?: TitleHtmlTag;
}

export interface Menu {
  title: string;
  link: Link;
  id: string;
  children?: React.ReactNode;
}

export interface AlgoliaIndex {
  indexName: string;
  searchResultsLabel: string;
  indexKey: string;
  seeMoreButtonLabel: string;
}
export interface RawAlgoliaConfig {
  parameters: {
    appId: string;
    appKey: string;
    mobileSeeAllResultsButtonLabel: string;
    indexes: AlgoliaIndex[];
  };
  shortcutAlgoliaMenu: Menu[];
  shortcutAlgoliaName: string;
}

interface Picture {
  url: string;
  alt: string;
  w: number;
  isVideo: boolean;
}
export interface BannerUrlByDevice {
  desktop: Picture;
  tablet?: Picture;
  mobile?: Picture;
  mobileMini?: Picture;
}

export type CurrencyDisplay = 'symbol' | 'code' | 'name';

export interface Currency {
  locale: string;
  currency: string | null;
  currencyDisplay?: CurrencyDisplay;
}

export interface Lang {
  label: string;
  currencyDisplay: CurrencyDisplay;
  currencyLocale: string;
}

export type Langs = Record<string, Lang>;

export type AutoPlaySpeed = number | null;
