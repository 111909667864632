/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { stripUnit } from 'polished';
import delay from 'lodash/delay';

import pxInRem from 'shared/helpers/styled-components/remHelper';
import breakpoints from 'shared/constants/breakpoints';
import {
  ArrowRight,
  ArrowLeft
} from 'shared/components/presentational/StyledArrow';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

const sliderAttrs = {
  nextArrow: <ArrowRight />,
  prevArrow: <ArrowLeft />,
  infinite: false,
  swipe: false,
  slidesToScroll: 1,
  slidesToShow: 6,
  responsive: [
    {
      breakpoint: stripUnit(breakpoints.desktop),
      settings: {
        slidesToShow: 6
      }
    },
    {
      breakpoint: stripUnit(breakpoints.tablet),
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: stripUnit(breakpoints.phablet),
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 300,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};

const StyledSlider = styled(Slider).attrs(sliderAttrs)`
  .slick-next {
    right: 0 !important;
  }
  .slick-prev {
    left: 0 !important;
  }

  .slick-prev,
  .slick-next {
    height: 100%;
    z-index: 1;
    & svg {
      background-color: white;
      box-shadow: 0 0 30px 15px white;
    }
    &.slick-disabled {
      display: none !important;
    }
  }

  .slick-track {
    display: flex;
    align-items: center;
  }
`;

const Item = styled.div`
  cursor: pointer;
  padding: 11px 25px;
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  font-size: ${pxInRem(13)};
  text-transform: uppercase;
  text-align: center;
  border-bottom: 2px solid
    ${props => (props.selected ? COLORS.black : 'transparent')};
`;

const responsiveSlidesToShow = () => {
  const slidesToShow = sliderAttrs.responsive.reduce((acc, curr) => {
    if (window.matchMedia(`(max-width: ${curr.breakpoint}px)`).matches) {
      return curr.settings.slidesToShow;
    }

    return acc;
  }, sliderAttrs.responsive[0].settings.slidesToShow);

  return slidesToShow;
};

export default class HorizontalSliderMenu extends PureComponent {
  sliderRef;

  resolveSliderRef = ref => {
    this.sliderRef = ref;
  };

  componentDidMount() {
    const { children } = this.props;
    const selectedSlideIndex = React.Children.toArray(children).findIndex(
      child => child.props.selected
    );

    const slideToGo = () => {
      // restSlideIndex is the index of the slide before the last slide to see the selectedSlide
      const restSlideIndex = children.length - responsiveSlidesToShow();
      if (selectedSlideIndex !== -1) {
        // if current category exists
        if (selectedSlideIndex <= restSlideIndex) {
          // if its index is inferior to rest slide index
          return selectedSlideIndex;
        }
        // else return rest slide index
      }
      return restSlideIndex;
    };
    if (this.sliderRef && selectedSlideIndex >= responsiveSlidesToShow()) {
      delay(() => this.sliderRef.slickGoTo(slideToGo()), 1000);
    }
  }

  render() {
    const { className, children } = this.props;
    const { resolveSliderRef } = this;

    return (
      <StyledSlider
        ref={resolveSliderRef}
        initialSlide={0}
        className={className}
      >
        {React.Children.map(children, this.renderItem)}
      </StyledSlider>
    );
  }

  renderItem = child => {
    const { selected, ...props } = child.props;
    return (
      <Item key={child.key} selected={selected}>
        {React.cloneElement(child, props)}
      </Item>
    );
  };
}
