import React, { useCallback } from 'react';

import { FormattedJaquette } from '../../types';
import { FormattedTab } from 'shared/blocks/one-shop/shared/components/Tabs/component/Tabs/types';
import { TabContent } from '../TabContent';
import { Tabs } from './styles/components';
import { useTabs } from '../../../shared/components/Tabs/component/Tabs/providers/TabsProvider';

export const TabsWrapper = ({
  tabs
}: {
  tabs: FormattedTab<FormattedJaquette>[];
}) => {
  const { currentTab } = useTabs();

  const renderTabs = useCallback(
    function renderTabs(tab, index) {
      return (
        <TabContent tab={tab} isActive={currentTab === index} key={index} />
      );
    },
    [currentTab]
  );

  const tabsTitle = tabs.map(tab => tab.tabTitle);

  return <Tabs tabsTitle={tabsTitle}>{tabs.map(renderTabs)}</Tabs>;
};
