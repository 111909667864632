export const ACTION_TYPES = {
  FETCH_REQUEST: '@FAQ/FETCH_REQUEST',
  FETCH_ERROR: '@FAQ/FETCH_ERROR',
  FETCH_SUCCESS: '@FAQ/FETCH_SUCCESS'
};

export const FAQ_ROUTES_REGEXP = /^(\/?([a-zA-Z]{2}\/)?aide-en-ligne)\S*/g;

export const WILDCARD_PATH = 'aide-en-ligne';

export const DEFAULT_SLUG = 'accueil';

export const DEFAULT_ERROR_MESSAGE = 'une erreur est survenu';
export const FALLBACK_HTML = `<div class="faq-error">${DEFAULT_ERROR_MESSAGE}</div>`;
