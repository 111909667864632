import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 10 6" width={width} height={height} className={className}>
    <path
      d="M9.5 1.1c0 .1 0 .1-.1.2L5.2 5.5c-.1.1-.1.1-.2.1s-.1 0-.2-.1L.6 1.3s-.1-.1-.1-.2 0-.1.1-.2L1 .5c.1-.1.1-.1.2-.1s.1 0 .2.1L5 4 8.5.5c.1-.1.1-.1.2-.1s.1 0 .2.1l.4.4.2.2z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
