export const TABS = {
  MAP: 'blocks.shops.map.showMap',
  LIST: 'blocks.shops.map.showList'
};

export const TABS_KEYS = Object.keys(TABS).reduce(
  (acc, curr) => ({ ...acc, [curr]: curr }),
  {}
);

export const PER_PAGE = 6;

export const MINIMUM_PAGE_NUMBER = 0;

// Do not translate to I18n
export const SHOP_TYPES = {
  BOUTIQUE: 'Boutiques CANAL+',
  STAND: 'Stand CANAL+',
  AGENCE: 'Agences',
  PVAI: 'Points de ventes Agréés indépendants',
  PVA: 'Points de ventes Agréés (PVA)'
};
