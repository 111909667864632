import get from 'lodash/get';

export default content => ({
  player: {
    content: get(content.livePlayer, 'content', ''),
    bundleURL: get(content.livePlayer, 'bundleURL', ''),
    playerConfig: {
      platform: get(content.livePlayer, 'platform', ''),
      context: get(content.livePlayer, 'context', ''),
      env: get(content.livePlayer, 'env', ''),
      base: get(content.livePlayer, 'base', ''),
      skin: get(content.livePlayer, 'skin', ''),
      params: {
        offerZone: get(content.livePlayer, 'zone', ''),
        vectorId: get(content.livePlayer, 'vectorId', ''),
        autoplay: true
      }
    }
  }
});
