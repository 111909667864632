import { TaskRouteReduxParams } from 'shared/types';
import getFirstBlockWithNamespace from 'shared/modules/page/selectors/getFirstBlockWithNamespace';
import { namespace } from './index';
import flush from './actions/flush';

export default async ({ dispatch, getState }: TaskRouteReduxParams) => {
  const block = getFirstBlockWithNamespace(getState(), namespace);

  if (block) {
    // Get apis from content
    // const apis = getFormattedApis(block.content);

    // Flush previously fetched data
    await dispatch(flush());
  }
};
