import React from 'react';
import styled from 'styled-components';

import BaseTitle from 'shared/components/presentational/Title';
import { FormattedProps } from '../types';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 20px;
  text-align: center;
`;

const Title = ({ label, color, htmlTag, subtitle }: FormattedProps) => (
  <Wrapper className="wrapper-title">
    <BaseTitle
      titleLabel={label}
      titleColor={color}
      titleHtmlTag={htmlTag}
      subtitle={subtitle}
    />
  </Wrapper>
);

export default Title;
