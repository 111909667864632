/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => {
  return {
    apis: {
      getSubscriberData: content.apis?.getSubscriberData,
      checkAccount: content.apis?.checkAccount,
      getRenewalOffers: content.apis?.getRenewalOffers,
      verifyRenewalOffers: content.apis?.verifyRenewalOffers,
      apiVisaSecure: content.apis?.apiVisaSecure
    },
    idBase: content.idBase,
    summaryLink: content.summaryLink,
    termsConditions: content.termsConditions,
    legalNotice: {
      title: content.legalNotice?.title,
      pictogram: getLinkHrefWithAuthentication(
        content.legalNotice?.pictogram?.filename?.link ?? null
      ),
      redirectLink: getLinkHrefWithAuthentication(
        content.legalNotice?.redirectLink ?? null
      )
    },
    encryptedCheckAccount: content.encryptedCheckAccount,
    encryptedVerifyRenewalOffers: content.encryptedVerifyRenewalOffers,
    visa_checkout_access_code:
      content.variables.visa_checkout_access_code ?? '',
    visa_checkout_merchant_id:
      content.variables.visa_checkout_merchant_id ?? '',
    visa_checkout_secure_secret:
      content.variables.visa_checkout_secure_secret ?? ''
  };
};
