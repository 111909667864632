import upperFirst from 'lodash/upperFirst';
import dayjs from 'dayjs';

import { PROGRAM_TIME_FORMAT } from 'shared/components/presentational/ProgramDetail/constant';

export default (programData, timezone) => {
  const data = programData.detail.informations || {};
  const startTime = data.startTime
    ? dayjs.unix(data.startTime).tz(timezone).format(PROGRAM_TIME_FORMAT)
    : '';
  const [authorInfo, personnalitiesInfo] = data.personnalities || [];

  return {
    title: data.title,
    ratingCSA: data.CSA,
    summary: data.summary,
    labels: data.labels || {},
    reviews: data.reviews || [],
    diffusions: programData.detail.diffusions || [],
    firstSubtitleA: `${data.channelName || ''} – ${upperFirst(startTime)}`,
    firstSubtitleB: `${data.audioLanguage || ''} ${data.HD ? 'HD' : ''}`,
    secondSubtitle: `${data.subGenre || ''} – ${data.editorialTitle || ''}`,
    authorInfo,
    personnalitiesInfo,
    timezone
  };
};
