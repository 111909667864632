import { LoginResponse, LoginResponseV2 } from '../types';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';

const isResponseV2 = (
  response: LoginResponse | LoginResponseV2
): response is LoginResponseV2 => 'personId' in response.userData;

/* ------------------------------------------------------------
  Mapping for the @Pass V2 Auth API
  https://jira.toolbox.canal-overseas.com/browse/AWA-905
------------------------------------------------------------ */
const formatCookies = (
  response: LoginResponse | LoginResponseV2,
  authenticationIsExternal = false
) => {
  const { passToken, passId } = response;
  const {
    cgaNumber,
    // epgId_ott,
    vectCode,
    offerZone,
    offerLocation,
    macroEligibility,
    microEligibility
  } = response.userData;

  if (isResponseV2(response)) {
    return {
      AuthOTTTokenV2: passToken,
      AuthIdToken: passId,
      subscriberId: cgaNumber,
      numSubscriber: response.userData.personId,
      pdsNormal: response.userData.epgid,
      pdsOTT: 'tmp', // epgId_ott,
      vector: vectCode,
      offerZone,
      offerLocation,
      macroEligibility,
      microEligibility,
      isAuthenticated: response.userData.isAuthenticated.toString(),
      [COOKIE_KEYS.ZONE]: response.userData.appLocation,
      [COOKIE_KEYS.EXTERNAL_AUTH]: authenticationIsExternal.toString()
    };
  }

  return {
    AuthOTTTokenV2: passToken,
    AuthIdToken: passId,
    subscriberId: cgaNumber,
    numSubscriber: response.userData.crmNumber,
    pdsNormal: response.userData.epgId,
    pdsOTT: 'tmp', // epgId_ott,
    vector: vectCode,
    offerZone,
    offerLocation,
    macroEligibility,
    microEligibility,
    isAuthenticated: response.userData.isAuthenticated,
    [COOKIE_KEYS.ZONE]: response.userData.isoCountryCode,
    [COOKIE_KEYS.EXTERNAL_AUTH]: authenticationIsExternal.toString()
  };
};

export default formatCookies;
