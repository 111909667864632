import React, { PureComponent } from 'react';
import styled from 'styled-components';

import pxToRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import Picture from 'shared/components/presentational/Picture';
import Raw from 'shared/components/presentational/Raw/Raw';
import { ComponentProps } from './types';
import Button from '../Button';
import { BANNER_THUMBOR_FILTER } from '../Picture/constants';

const Wrapper = styled.div<{ backgroundColor?: string }>`
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: row;

  ${mediaQueries.toTablet`
    flex-direction: column;
  `};
`;
const Left = styled.div`
  position: relative;
  flex: 7;
  justify-content: center;
  align-items: center;
  display: flex;

  ${mediaQueries.toTablet`
    height: inherit;
    min-width: inherit;
    display: block;
  `};
`;

const Right = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${COLORS.white};
  padding: 20px;
  font-size: ${pxToRem(22)};
  line-height: ${pxToRem(30)};
`;

const StyledPicture = styled(Picture)`
  object-fit: cover;
  object-position: bottom right;
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin: 20px auto;
  ${mediaQueries.toPhablet`
    margin: 10px auto;
  `};
`;

const Text = styled(Raw)`
  width: 100%;
  text-transform: uppercase;
  font-size: ${pxToRem(14)};
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
`;

export default class Banner extends PureComponent<ComponentProps> {
  public render(): React.ReactNode {
    const {
      className,
      imageUrl,
      imageAlt,
      text,
      backgroundColor,
      buttonUrl,
      buttonText,
      buttonTheme,
      children
    } = this.props;

    return (
      <Wrapper className={className} backgroundColor={backgroundColor}>
        <Left>
          <StyledPicture
            thumborFilter={BANNER_THUMBOR_FILTER}
            url={imageUrl}
            height={371}
            alt={imageAlt}
          />
          {children}
        </Left>
        <Right>
          {text && <Text html={text} />}
          <StyledButton to={buttonUrl} theme={buttonTheme}>
            <Button.children.Text>{buttonText}</Button.children.Text>
          </StyledButton>
        </Right>
      </Wrapper>
    );
  }
}
