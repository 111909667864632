import { Store } from 'redux';
import { matchPath } from 'react-router';

import { TASK_TYPE } from 'shared/modules/tasks/constants';
import locationTasks from '../locationTasks';
import { Task } from '../types';

const executeTasks = (
  executionType: TASK_TYPE,
  pathname: string,
  store: Store
) => {
  return Promise.all(
    locationTasks(store).reduce((executedTasks: Promise<any>[], task: Task) => {
      const taskToExecute = task[executionType];

      if (typeof taskToExecute === 'undefined') {
        return executedTasks;
      }

      if (task.pattern) {
        const match = matchPath(pathname, { path: task.pattern });

        if (!match) {
          return executedTasks;
        }

        if (match.params) {
          return [...executedTasks, taskToExecute(match.params)];
        }
      }

      return [...executedTasks, taskToExecute({})];
    }, [])
  );
};

export default executeTasks;
