/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  ...content,
  redirectApash: getLinkHrefWithAuthentication(content.redirectApash),
  popin: {
    ...content.popins[0],
    isOpen: true,
    mediaDesktop: content.popins[0].mediaDesktop
      ? getLinkHrefWithAuthentication(content.popins[0].mediaDesktop.link)
      : undefined,
    mediaMobile: content.popins[0].mediaMobile
      ? getLinkHrefWithAuthentication(content.popins[0].mediaMobile.link)
      : content.popins[0].mediaDesktop
      ? getLinkHrefWithAuthentication(content.popins[0].mediaDesktop.link)
      : undefined,
    button: {
      ...content.popins[0].button,
      theme: themeFactory({
        color: content.popins[0].button.labelColor,
        backgroundColor: content.popins[0].button.backgroundColor
      })
    },
    backLink: {
      label: content.popins[0].backLink.label,
      url: getLinkHrefWithAuthentication(content.popins[0].backLink.link)
    }
  },
  nextButton: {
    ...content.nextButton,
    link: getLinkHrefWithAuthentication(content?.nextButton?.link),
    theme: themeFactory({
      color: content.nextButton.labelColor,
      backgroundColor: content.nextButton.backgroundColor
    })
  },
  previousButton: {
    ...content.previousButton,
    link: getLinkHrefWithAuthentication(content?.previousButton?.link),
    theme: themeFactory({
      color: content.previousButton.labelColor,
      backgroundColor: content.previousButton.backgroundColor
    })
  },
  anchorId: null
});
