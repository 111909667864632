import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 50 16" width={width} height={height} className={className}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M18 0H1C.4 0 0 .4 0 1v12c0 .6.4 1 1 1h6c-.6 0-1 .4-1 1s.4 1 1 1h2c.6 0 1-.4 1-1s-.4-1-1-1h32c-.6 0-1 .4-1 1s.4 1 1 1h2c.6 0 1-.4 1-1s-.4-1-1-1h6c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1H18zm30 12H2V2h46v10z" />
      <path d="M29 10c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1s-1 .4-1 1v4c0 .6.4 1 1 1zM33 10c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1s-1 .4-1 1v4c0 .6.4 1 1 1zM37 10c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1s-1 .4-1 1v4c0 .6.4 1 1 1zM41 10c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1s-1 .4-1 1v4c0 .6.4 1 1 1zM45 10c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1s-1 .4-1 1v4c0 .6.4 1 1 1zM7 4C5.3 4 4 5.3 4 7s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
    </g>
  </svg>
);
