/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*       */

import { connect } from 'react-redux';

import Error from 'shared/modules/error/components/Error';

const mapStateToProps = state => ({
  hasError: state.error.hasError,
  message: state.error.message
});

const ErrorContainer = connect(mapStateToProps)(Error);

export default ErrorContainer;
