import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

import { DEFAULT_LANG } from './constants';

/**
 * load translation using xhr -> see /public/locales
 * and pass the i18n instance to react-i18next
 *
 * @see https://github.com/i18next/i18next-http-backend
 */
i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    fallbackLng: DEFAULT_LANG,
    ns: ['translations'],
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: false
    }
  });

export default i18n;
