import React from 'react';

import { FormattedProps } from '../types';
import { TabsProvider } from '../../shared/components/Tabs/component/Tabs/providers/TabsProvider';
import { TabsWrapper } from './TabsWrapper';
import Title from 'shared/components/presentational/Title';
import { Wrapper } from './styles/components';

const OffersCardBlock = ({ title, subtitle, tabs }: FormattedProps) => {
  return (
    <Wrapper data-testid="offer-cards">
      <Title
        titleLabel={title?.label}
        titleHtmlTag={title?.htmlTag}
        subtitle={subtitle}
      />
      <TabsProvider>
        <TabsWrapper tabs={tabs} />
      </TabsProvider>
    </Wrapper>
  );
};

export default OffersCardBlock;
