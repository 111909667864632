import get from 'lodash/get';

export default content =>
  get(content, 'rubriques', []).reduce((acc, category) => {
    const categoryLabel = category.displayName;
    const categoryUrl = category.URLPage;
    if (categoryUrl) {
      return {
        ...acc,
        [categoryUrl]: categoryLabel
      };
    }
    return acc;
  }, {});
