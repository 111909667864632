import styled, { css } from 'styled-components';

import { ellipsis } from './mixins';
import BaseButton from 'shared/components/presentational/Button/Button';
import BaseContainer from 'shared/components/presentational/Container/Container';
import BaseWrapper from 'shared/components/presentational/Wrapper/Wrapper';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import {
  FONT_FAMILIES,
  COLORS,
  DIMENSIONS,
  FONT_WEIGHTS
} from 'shared/constants/theme';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

export const Wrapper = BaseWrapper;

export const Title = styled.h1`
  margin: ${pxInRem(22)} 0 ${pxInRem(14)};
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  font-size: ${pxInRem(26)};
  text-align: center;
  color: ${COLORS.black};
  ${mediaQueries.toPhablet`
    font-size: ${pxInRem(18)};
  `}
`;

export const Subtitle = styled.span`
  display: block;
  font-family: ${FONT_FAMILIES.base};
  font-weight: ${FONT_WEIGHTS.normal};
  font-size: ${pxInRem(16)};
  text-align: center;
  color: ${props => props.color ?? COLORS.boulder};
`;

export const Button = styled(BaseButton)`
  ${props =>
    !props.to &&
    !props.onClick &&
    css`
      cursor: initial;
    `}
`;

export const ButtonContainer = styled(BaseContainer)`
  display: flex;
  justify-content: center;
  margin: ${DIMENSIONS.MEDIUM_VERTICAL_MARGIN}px 0;
`;

export const SlideTitle = styled.h4`
  font-family: ${FONT_FAMILIES.base};
  font-weight: ${FONT_WEIGHTS.normal};
  font-size: ${pxInRem(18)};
  margin: 0;
  line-height: ${pxInRem(28)};

  ${ellipsis()}
`;

export const SlideSubtitle = styled.span`
  display: block;
  font-family: ${FONT_FAMILIES.base};
  font-size: ${pxInRem(14)};
  color: ${COLORS.boulder};
  margin-top: ${pxInRem(8)};

  ${ellipsis()}
`;
