import get from 'lodash/get';

import { COLORS } from 'shared/constants/theme';
import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: get(content, 'title.label', ''),
  position: get(content, 'template'),
  titleColor: get(content, 'title.color', COLORS.black),
  titleHtmlTag: get(content, 'title.htmlTag'),
  subtitle: get(content, 'subtitle', ''),
  theme: themeFactory({
    backgroundColor: content.button.backgroundColor,
    borderColor: content.button.backgroundColor,
    color: content.button.colorLabel
  }),
  href: getLinkHrefWithAuthentication(get(content.button, 'link', '')),
  label: content.button.label
});
