import isArray from 'lodash/isArray';

import getQueryParams from 'shared/modules/router/selectors/getQueryParams';
import getHash from '../getHash';

export default (state: any) => {
  const redirectUri = getQueryParams(state).redirect_uri;

  if (!redirectUri || isArray(redirectUri)) {
    return null;
  }

  /**
   * Because the server doesn't have access to the hash, the referer is computed without it for SSR redirection.
   * That means the hash will be on the login url but not in its referer query param.
   *
   * Example given:
   * - After a client-side redirection: /login?redirect_uri=%2Fpage%23anchor
   * - After a server-side redirection: /login?redirect_uri=%2Fpage#anchor
   *
   * Thus we ensure that the anchor is properly passing through the login process.
   */
  const hash = getHash(state);
  if (hash) {
    return `${redirectUri}${hash}`;
  }

  return redirectUri;
};
