import { css } from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

export const fromTabletContainer = css`
  ${mediaQueries.fromTablet`
    padding-left: var(--container-minimum-padding-tablet);
    padding-right: var(--container-minimum-padding-tablet);
  `}

  @media (min-width:  var(--container-max-width-breakpoint)) {
    padding-left: calc(50vw - var(--half-of-container-inner-width));
    padding-right: calc(50vw - var(--half-of-container-inner-width));
  }
`;

export const container = css`
  padding-left: var(--container-minimum-padding);
  padding-right: var(--container-minimum-padding);
  ${fromTabletContainer}
`;

/**
 * Padding-like behavior + ability to collapse with the first child top margin
 * and the last child bottom margin.
 * @param size
 */
export const collapsePadding = (size: string) => css`
  &:before {
    content: '';
    display: table;
    margin-bottom: ${size};
  }
  &:after {
    content: '';
    display: table;
    margin-top: ${size};
  }
`;

/**
 * Add "announced content" but hidden to the view.
 *
 * Taken from: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/del
 * @param content
 * @param insertBefore
 */
export const a11yContent = (content: string, insertBefore = true) => css`
  &::${insertBefore ? 'before' : 'after'} {
    content: '${content}';
    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;
