import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 36 20" width={width} height={height} className={className}>
    <path
      d="M18 0C10.5 0 4 4 .6 10 4 15.9 10.5 20 18 20c7.5 0 14-4 17.4-10C32 4.1 25.5 0 18 0zm-.4 16.9c-3.9 0-7.1-3.1-7.1-7s3.2-7 7.1-7c3.9 0 7.1 3.1 7.1 7 .1 3.9-3.1 7-7.1 7z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
