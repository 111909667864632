import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState
} from 'react';

export interface TabsContextType {
  currentTab: number;
  setCurrentTab: Dispatch<SetStateAction<number>>;
}

export const TabsContext = createContext<TabsContextType | undefined>(
  undefined
);

function TabsProvider({ children }: { children: ReactNode }) {
  const [currentTab, setCurrentTab] = useState(0);

  const contextValue: TabsContextType = {
    currentTab,
    setCurrentTab
  };

  return (
    <TabsContext.Provider value={contextValue}>{children}</TabsContext.Provider>
  );
}

const useTabs = (): TabsContextType => {
  const context = useContext(TabsContext);

  if (context === undefined) {
    throw new Error('useTabs must be used within a TabsProvider');
  }
  return context;
};

export { TabsProvider, useTabs };
