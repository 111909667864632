import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 11 12" width={width} height={height} className={className}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M10 6.1v-.3c-.1-1.2-.6-2.3-1.4-3.1-.1-.1-.3-.2-.5-.1-.2 0-.3.2-.4.4-.1.2 0 .4.2.6.9.9 1.2 2 1.1 3.2-.1 1.1-.7 2-1.6 2.7-.9.6-2 .9-3.1.6-2-.4-3.3-2.4-3-4.4.1-.8.5-1.5 1.1-2.2.1-.1.2-.3.2-.4 0-.2-.1-.3-.2-.4-.3-.1-.4-.1-.5-.1-.2 0-.3.1-.4.2C.2 4-.2 5.5.1 7.2c.2 1.3 1 2.4 2.1 3.2 1.1.8 2.4 1 3.8.8 2.4-.5 4.1-2.5 4.1-4.9-.1-.1-.1-.1-.1-.2z" />
      <path d="M4.4 3.5v3c0 .3.3.5.6.5s.6-.2.6-.5V1.3c0-.1 0-.2-.2-.4S5 .7 4.8.8c-.2 0-.4.3-.4.5v2.2z" />
    </g>
  </svg>
);
