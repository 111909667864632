const CONTAINER_INNER_WIDTH = 1320;

export const THEME_ONE_SHOP = {
  TEXT_ALIGN: 'left',
  DIMENSIONS: {
    CONTAINER_MINIMUM_PADDING: 16,
    CONTAINER_MINIMUM_PADDING_TABLET: 60,
    CONTAINER_MAX_WIDTH_BREAKPOINT: CONTAINER_INNER_WIDTH + 60 * 2,
    HALF_OF_CONTAINER_INNER_WIDTH: CONTAINER_INNER_WIDTH / 2
  }
};
