import React from 'react';

import RawTabs from 'shared/components/presentational/Tabs';
import OfferList from './OfferList';
import { getOffersByCategory, getOffersCategories } from '../helpers';
import { FormattedOffer } from '../types';

interface Props {
  offers: FormattedOffer[];
}

const OfferTabs = ({ offers }: Props) => {
  const offersByCategory = getOffersByCategory(offers);
  const categories = getOffersCategories(offersByCategory);
  const Tabs = RawTabs as any;
  return (
    <Tabs>
      <Tabs.List>
        {categories.map((category, index) => (
          <Tabs.Tab key={index}>{category}</Tabs.Tab>
        ))}
      </Tabs.List>
      <Tabs.Panels>
        {categories.map((category, index) => (
          <Tabs.Panel key={index}>
            <OfferList tabs offers={offersByCategory[category]} />
          </Tabs.Panel>
        ))}
      </Tabs.Panels>
    </Tabs>
  );
};

export default OfferTabs;
