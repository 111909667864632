import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { WithTranslation } from 'react-i18next';
import { lighten } from 'polished';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import {
  HEADER_DESKTOP_HEIGHT,
  HEADER_MOBILE_HEIGHT
} from 'shared/constants/heights';
import { COLORS } from 'shared/constants/theme';
import DropdownIndicator from 'shared/components/presentational/DropdownIndicator';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import Button from 'shared/components/presentational/Button';
import { FormattedProps, PropsFromState, WithConnected } from '../types';
import { Content, Top } from '../styles/components';
import setCookie from 'shared/helpers/universal-cookie/setCookie';
import clientCookies from 'client/cookies';
import { Z_INDEX } from '../constants';
import Logo from './components/Logo';
import BigMac from './components/BigMac';
import NavigationDesktop from './components/NavigationDesktop';
import NavigationMobile from './components/NavigationMobile';
import BaseSearchButton from './components/SearchButton';
import ConnectButton, { buttonTheme } from './components/ConnectButton';
import SearchEngine from './components/SearchEngine/SearchEngine';

const HeightHolder = styled.div`
  height: ${HEADER_DESKTOP_HEIGHT}px;
  ${mediaQueries.toTablet`
    height: auto;
  `};
`;

const Wrapper = styled.header<{
  backgroundColor: string;
  isMenuOpened: boolean;
}>`
  backface-visibility: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  height: ${HEADER_DESKTOP_HEIGHT}px;
  padding: 15px 30px 25px;
  background-color: ${props => props.backgroundColor};
  color: ${COLORS.white};
  z-index: ${props =>
    props.isMenuOpened ? Z_INDEX.HEADER : Z_INDEX.NAVIGATION_MOBILE};

  ${mediaQueries.toTablet<{
    backgroundColor: string;
    isMenuOpened: boolean;
  }>`
    height: ${HEADER_MOBILE_HEIGHT}px;
    padding: 5px 15px;
    border-bottom: 1.5px solid ${props => lighten(0.17, props.backgroundColor)};
  `};
`;

const HeaderButton = styled.div`
  position: absolute;
  right: 0;
  top: -5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaQueries.toTablet`
      display: none;
  `};
`;

const StyledDropdownIndicator = styled(DropdownIndicator)`
  margin-left: 5px;
`;

const VerticalBar = styled.div<{ color: string }>`
  height: 25px;
  width: 1px;
  background-color: ${props => props.color};
  margin: 0 10px;
`;

const SearchButton = styled(BaseSearchButton)`
  ${mediaQueries.fromDesktop`
    display: none;
  `}
`;

export type Props = FormattedProps &
  PropsFromState &
  WithConnected &
  WithTranslation;

export default function Header({
  // Container props
  connected,
  // PropsFormatter props
  mainMenuItems,
  quickAccessMenuItems,
  logoUrl,
  logoutUrl,
  canalBoxUrl,
  canalBoxTitle,
  backgroundColor,
  mainLinksColor,
  quickAccessLinksColor,
  isAllowedToConnect,
  loginUrl,
  companyId,
  smartFaqUuId,
  langs,
  bigram,
  pageLang,
  domain,
  algolia,
  boxTitle,
  boxSubscriptionManagementLabel,
  boxSubscriptionManagementUrl,
  boxLoyaltyProgramLabel,
  boxLoyaltyProgramUrl,
  signupCanalPlusTitle,
  signupCanalPlusLinkLabel,
  signupCanalPlusLink,
  t
}: Props) {
  const connectButtonRef = useRef<ConnectButton | null>(null);

  const [searchOpened, setSearchOpened] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [langMenuOpened, setLangMenuOpened] = useState(false);

  const toggleNav = useCallback(() => {
    setMenuOpened(!menuOpened);
    setLangMenuOpened(false);
  }, [menuOpened]);

  const toggleLangNav = useCallback(() => {
    setLangMenuOpened(!langMenuOpened);
    setMenuOpened(false);
  }, [langMenuOpened]);

  const closeNav = useCallback(() => {
    setLangMenuOpened(false);
    setMenuOpened(false);
  }, []);

  const toggleSearch = useCallback(() => {
    closeNav();
    setSearchOpened(!searchOpened);
  }, [searchOpened]);

  const onLangSectionChanged = useCallback((lang: string) => {
    setCookie(clientCookies, COOKIE_KEYS.LANG, lang, { domain });
    setLangMenuOpened(false);
  }, []);

  const hasAtLeastOneLang = langs && Object.keys(langs).length > 1;
  const hasAuthenticationLink = canalBoxUrl || (loginUrl && isAllowedToConnect);

  return (
    <HeightHolder>
      <SearchEngine
        searchEngine={algolia}
        companyId={companyId}
        smartFaqUuId={smartFaqUuId}
        backgroundColor={backgroundColor}
        opened={searchOpened}
        logoUrl={logoUrl}
        textColor={mainLinksColor}
        mainColor={quickAccessLinksColor}
        closeNav={closeNav}
        onCloseRequest={toggleSearch}
        bigram={bigram}
        pageLang={pageLang}
      />

      <Wrapper
        role="banner"
        isMenuOpened={menuOpened || langMenuOpened}
        backgroundColor={backgroundColor}
      >
        <Content>
          <Top>
            <Logo imageUrl={logoUrl} linkUrl="/" onClick={closeNav} t={t} />
            <BigMac onClick={toggleNav} isOpen={menuOpened} />

            {algolia.enableSearchEngine && (
              <SearchButton
                color={mainLinksColor}
                hoverColor={quickAccessLinksColor}
                onClick={toggleSearch}
              />
            )}

            <HeaderButton>
              {hasAuthenticationLink && (
                <ConnectButton
                  color={mainLinksColor}
                  onClick={toggleNav}
                  connected={connected}
                  ref={connectButtonRef}
                  t={t}
                />
              )}
              {pageLang && hasAtLeastOneLang && (
                <>
                  {hasAuthenticationLink && (
                    <VerticalBar color={mainLinksColor} />
                  )}
                  <Button
                    onClick={toggleLangNav}
                    theme={buttonTheme(mainLinksColor)}
                  >
                    <Button.children.Text>
                      {pageLang.toUpperCase()}
                    </Button.children.Text>
                    <StyledDropdownIndicator isOpen={langMenuOpened} />
                  </Button>
                </>
              )}
            </HeaderButton>
          </Top>

          <NavigationDesktop
            tooltipTarget={connectButtonRef.current}
            connected={connected}
            mainMenuItems={mainMenuItems}
            quickAccessMenuItems={quickAccessMenuItems}
            isMenuOpen={menuOpened}
            onItemClick={closeNav}
            onSearchIconClick={toggleSearch}
            logoutUrl={logoutUrl}
            loginUrl={loginUrl}
            canalBoxUrl={canalBoxUrl}
            canalBoxTitle={canalBoxTitle}
            backgroundColor={backgroundColor}
            mainLinksColor={mainLinksColor}
            quickAccessLinksColor={quickAccessLinksColor}
            isAllowedToConnect={isAllowedToConnect}
            boxTitle={boxTitle}
            boxSubscriptionManagementLabel={boxSubscriptionManagementLabel}
            boxSubscriptionManagementUrl={boxSubscriptionManagementUrl}
            boxLoyaltyProgramLabel={boxLoyaltyProgramLabel}
            boxLoyaltyProgramUrl={boxLoyaltyProgramUrl}
            signupCanalPlusTitle={signupCanalPlusTitle}
            signupCanalPlusLinkLabel={signupCanalPlusLinkLabel}
            signupCanalPlusLink={signupCanalPlusLink}
            enableSearchEngine={algolia.enableSearchEngine}
            t={t}
            // Langs menu tooltip
            isLangsMenuOpen={langMenuOpened}
            langs={langs}
            onLangSectionChanged={onLangSectionChanged}
          />

          <NavigationMobile
            close={closeNav}
            connected={connected}
            mainMenuItems={mainMenuItems}
            quickAccessMenuItems={quickAccessMenuItems}
            isOpen={menuOpened || langMenuOpened}
            onItemClick={toggleNav}
            loginUrl={loginUrl}
            logoutUrl={logoutUrl}
            canalBoxUrl={canalBoxUrl}
            canalBoxTitle={canalBoxTitle}
            backgroundColor={backgroundColor}
            mainLinksColor={mainLinksColor}
            quickAccessLinksColor={quickAccessLinksColor}
            isAllowedToConnect={isAllowedToConnect}
            boxTitle={boxTitle}
            boxSubscriptionManagementLabel={boxSubscriptionManagementLabel}
            boxSubscriptionManagementUrl={boxSubscriptionManagementUrl}
            boxLoyaltyProgramLabel={boxLoyaltyProgramLabel}
            boxLoyaltyProgramUrl={boxLoyaltyProgramUrl}
            signupCanalPlusTitle={signupCanalPlusTitle}
            signupCanalPlusLinkLabel={signupCanalPlusLinkLabel}
            signupCanalPlusLink={signupCanalPlusLink}
            companyId={companyId}
            smartFaqUuId={smartFaqUuId}
            t={t}
            // Langs menu tooltip
            langs={langs}
            pageLang={pageLang}
            onLangSectionChanged={onLangSectionChanged}
          />
        </Content>
      </Wrapper>
    </HeightHolder>
  );
}
