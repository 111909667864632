import axios from 'axios';
import queryString from 'query-string';

export default async (
  authenticateApiUrl,
  appLocation,
  offerZone,
  macros,
  isAuthenticated,
  micros
) => {
  const query = queryString.stringify({
    appLocation,
    offerZone,
    macros,
    isAuthenticated: Number(isAuthenticated),
    micros
  });

  const response = await axios.get(`${authenticateApiUrl}?${query}`);
  return response.data;
};
