import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Column from './ContactColumn';
import Title from 'shared/components/presentational/Title';
import * as themes from '../themes';
import { FormattedProps } from '../types';

const Wrapper = styled.section<{ background: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${props => props.background};
  margin-bottom: 0 !important; // do not apply generic block margin behaviour
  padding: ${props => props.theme.contact.padding};
`;

const Content = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 10px;

  ${mediaQueries.toPhablet`
    padding: 2px;
  `};
`;

const Columns = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export default function Contact({
  title,
  columns,
  subtitle,
  template,
  background,
  titleColor,
  titleHtmlTag
}: FormattedProps) {
  const theme = themes[template] || themes.defaultTheme;
  return (
    <ThemeProvider theme={theme}>
      <Wrapper background={background}>
        <Content>
          <Title
            titleLabel={title}
            subtitle={subtitle}
            titleColor={titleColor}
            titleHtmlTag={titleHtmlTag}
          />
          <Columns>
            {columns.map((column, index) => (
              <Column {...column} key={`Contact_column${index}`} />
            ))}
          </Columns>
        </Content>
      </Wrapper>
    </ThemeProvider>
  );
}
