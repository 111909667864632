export const API_ROBOTS_ENDPOINT = 'seo/robots.txt';
export const API_SITEMAP_ENDPOINT = 'seo/sitemap.xml';

export const DEFAULT_LANG = 'fr';
export const DEFAULT_LANGS = {
  [DEFAULT_LANG]: {
    label: 'Français',
    currencyDisplay: 'symbol',
    currencyLocale: 'fr-FR'
  }
};

export const ALLMOL_KEY_PARAMS = 'P6VM4h7rM8kgTQWnk4YZ';
export const ALLMOL_ENCRYPT_IV = '0000000000000000';

export const REQUIRED_PROCESS_ENV_VARIABLES = [
  'RAZZLE_SECRET_CODE_ALLMOL',
  'RAZZLE_SECRET_CODE_ENCRYPT',
  'CLIENT_SECRET',
  'TV_CHANNEL_BASIC_AUTH',
  'DIDOMI_API_KEY_PROD'
];

export const OPTIONAL_PROCESS_ENV_VARIABLES = [
  'API_BASE_URL',
  'PROXY_AUTOMATES',
  'RAZZLE_COMMIT_SHA',
  'RAZZLE_MINISITE_DOMAIN'
];
