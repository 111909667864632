import React from 'react';

import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';
import { Overlay } from '../../styles/searchEngine';

export const ModalOverlay = ({
  userMenuWidth,
  onClick
}: {
  userMenuWidth: number;
  onClick(): void;
}) => {
  useLockBodyScroll();
  return <Overlay userMenuWidth={userMenuWidth} onClick={onClick} />;
};
