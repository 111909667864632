/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Content, FormattedProps } from './types';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  broadcastingWay: content.broadcastingWay,
  saleDeviceId: content.saleDeviceId,
  title: content.title.label,
  titleColor: content.title.color,
  titleHtmlTag: content.title.htmlTag,
  subtitle: content.subtitle ?? undefined,
  description: content.description ?? '',
  button: {
    label: content.previousButton.label,
    link: getLinkHrefWithAuthentication(content.previousButton.link ?? ''),
    theme: buttonThemeFactory({
      backgroundColor: content.previousButton.backgroundColor,
      color: content.previousButton.labelColor
    })
  },
  confirmation: {
    title: content.confirmation.title.label,
    titleColor: content.confirmation.title.color,
    titleHtmlTag: content.confirmation.title.htmlTag,
    subtitle: content.confirmation.subtitle ?? undefined,
    description: content.confirmation.description ?? '',
    button: {
      label: content.confirmation.previousButton.label,
      link: getLinkHrefWithAuthentication(
        content.confirmation.previousButton.link ?? ''
      ),
      theme: buttonThemeFactory({
        backgroundColor: content.confirmation.previousButton.backgroundColor,
        color: content.confirmation.previousButton.labelColor
      })
    }
  },
  apis: {
    getSubscriberData: {
      url: content.apis.getSubscriberData?.url ?? '',
      method: content.apis.getSubscriberData?.method || 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    },
    getPaymentMeanInformation: {
      url: content.apis['get_payment_mean_information']?.url ?? '',
      method: content.apis['get_payment_mean_information']?.method || 'GET'
    },
    updateBankCard: {
      url: content.apis['update_bank_card']?.url ?? '',
      method: content.apis['update_bank_card']?.method || 'GET'
    }
  },
  contextCriteria: content.frontDisplayCriteria ?? null
});
