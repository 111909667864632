export default function getHeightWithRatio(
  originalWidth: number,
  originalHeight: number | undefined,
  width: number
): number {
  if (width && originalHeight && originalWidth) {
    return Math.round((originalHeight * width) / originalWidth);
  }
  return 0;
}
