import { isJaquette } from '../shared/helpers/isJaquette';
import { Content, FormattedProps } from './types';
import { getLinkHref } from 'shared/helpers/uri';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => {
  return {
    title: content.title,
    subtitle: content.subtitle,
    tabs: content.tabs.map(tab => ({
      tabTitle: tab.tabTitle,
      title: tab.title || '',
      subtitle: tab.subtitle,
      contents: tab.jaquettes
        .filter(
          jaquette =>
            (isJaquette(jaquette) && jaquette.media !== null) ||
            (!isJaquette(jaquette) && jaquette.seeMore.link !== null)
        )
        .map(jaquette => ({
          type: jaquette.type,
          link: getLinkHrefWithAuthentication(
            isJaquette(jaquette) ? jaquette.link : jaquette?.seeMore.link
          ),
          media: {
            description: isJaquette(jaquette)
              ? jaquette.media.description || ''
              : jaquette?.seeMore?.label,
            href: getLinkHref(
              isJaquette(jaquette)
                ? jaquette.media.link
                : jaquette?.seeMore.icon?.link
            )
          }
        }))
    }))
  };
};
