/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { connect } from 'react-redux';

import WebviewCloseButton from 'shared/components/presentational/WebviewCloseButton/WebviewCloseButton';
import getDevices from 'shared/modules/page/selectors/getDevices';

const mapStateToProps = state => ({
  devices: getDevices(state)
});

const WebviewCloseButtonContainer = connect(mapStateToProps)(
  WebviewCloseButton
);

export default WebviewCloseButtonContainer;
