import React from 'react';
// @ts-ignore
import { useHits, UseHitsProps, THit } from 'react-instantsearch-hooks-web';
import { useTranslation } from 'react-i18next';

import { ThemeFactory } from 'shared/types';
import forgeHitLink from '../../../header/components/components/SearchEngine/helpers/forgeHitLink';
import {
  HitsList,
  HitsListEntryWrapper,
  HitsListEntry,
  HitsListEntryTextWrapper,
  HitsListEntryImage,
  HitsListEntrySubtitle,
  SeeAllResultsButton,
  HitsListNoResultsLabel,
  IconWrapper,
  ArticleIcon
} from '../../../header/components/components/SearchEngine/styles/components';
import { FAQ_ROOT } from 'shared/blocks/header/components/components/SearchEngine/constants';
import { HitsListTitle, HitsListEntryTitle } from '../../styles/searchEngine';
interface Props {
  title: string;
  seeAllResultsButtonLabel?: string;
  seeAllResultsButtonTheme?: ThemeFactory;
  onSeeAllResultsButtonClick?: () => void;
  hideSeeAllResultsButton?: boolean;
  onHitClick: () => void;
  isArticle?: boolean;
  isFAQIndex?: boolean;
}

interface FAQHit {
  objectID: string;
  question: {
    label: string;
    slug: string;
    category: {
      slug: string;
      thematic: {
        slug: string;
      };
    };
  };
}

const transformFAQItems: UseHitsProps['transformItems'] = (
  items: THit<FAQHit>[]
) => {
  return items.map(item => ({
    ...item,
    objectID: item.objectID,
    title: item?.question?.label,

    link: forgeHitLink(`/{faqRoot}/{thematic}/{category}/{question}`, {
      faqRoot: FAQ_ROOT,
      thematic: item.question!.category.thematic.slug,
      category: item.question!.category.slug,
      question: item.question!.slug
    })
  }));
};

export const ConnectedHits = ({
  title,
  seeAllResultsButtonLabel,
  seeAllResultsButtonTheme,
  onSeeAllResultsButtonClick,
  onHitClick,
  isArticle = false,
  hideSeeAllResultsButton = false,
  isFAQIndex
}: Props) => {
  const { hits } = useHits({
    transformItems: isFAQIndex ? transformFAQItems : undefined
  });

  return (
    <Hits
      title={title}
      seeAllResultsButtonLabel={seeAllResultsButtonLabel}
      seeAllResultsButtonTheme={seeAllResultsButtonTheme}
      onSeeAllResultsButtonClick={onSeeAllResultsButtonClick}
      onHitClick={onHitClick}
      isArticle={isArticle}
      hideSeeAllResultsButton={hideSeeAllResultsButton}
      hits={hits}
    />
  );
};

export function Hits({
  title,
  seeAllResultsButtonLabel,
  seeAllResultsButtonTheme,
  onSeeAllResultsButtonClick,
  onHitClick,
  isArticle = false,
  hideSeeAllResultsButton = false,
  hits
}: Props & { hits: THit[] | THit<FAQHit>[] }) {
  const { t } = useTranslation();

  const shouldShowSeeAllResultsButton =
    !hideSeeAllResultsButton &&
    hits.length > 0 &&
    onSeeAllResultsButtonClick &&
    seeAllResultsButtonLabel &&
    seeAllResultsButtonTheme;

  return (
    <>
      <HitsListTitle>{title}</HitsListTitle>
      {hits.length ? (
        <HitsList data-testid="search-engine-hits-list">
          {hits.map(hit => (
            <HitsListEntryWrapper key={hit.objectID}>
              <HitsListEntry
                onClick={onHitClick}
                to={
                  forgeHitLink(
                    hit.link ?? hit.path,
                    {
                      id_front: hit.id_front
                    },
                    isArticle
                  ) ?? '#'
                }
                // if link is to an article, we add a search param to be able to display
                // it within a page rather than a modal
                search={
                  isArticle
                    ? '?' + new URLSearchParams({ display: 'page' }).toString()
                    : undefined
                }
              >
                {hit.media && (
                  <>
                    <HitsListEntryImage
                      url={hit.media.link.href}
                      alt={hit.media.filename}
                    />
                    {isArticle && (
                      <IconWrapper>
                        <ArticleIcon />
                      </IconWrapper>
                    )}
                  </>
                )}

                <HitsListEntryTextWrapper>
                  <HitsListEntryTitle
                    dangerouslySetInnerHTML={{
                      __html:
                        hit._highlightResult?.title?.value ?? hit.title ?? ''
                    }}
                  ></HitsListEntryTitle>

                  {!hit.media && (
                    <HitsListEntrySubtitle
                      dangerouslySetInnerHTML={{
                        __html:
                          hit._highlightResult?.subtitle?.value ??
                          hit.subtitle ??
                          ''
                      }}
                    ></HitsListEntrySubtitle>
                  )}
                </HitsListEntryTextWrapper>
              </HitsListEntry>
            </HitsListEntryWrapper>
          ))}
        </HitsList>
      ) : (
        <HitsListNoResultsLabel>
          {t('blocks.searchEngine.indexNoResults')}
        </HitsListNoResultsLabel>
      )}

      {shouldShowSeeAllResultsButton && (
        <SeeAllResultsButton
          type="button"
          data-testid="search-engine-desktop-see-all-results-button"
          onClick={onSeeAllResultsButtonClick}
          theme={seeAllResultsButtonTheme}
        >
          <SeeAllResultsButton.children.Text>
            {seeAllResultsButtonLabel}
          </SeeAllResultsButton.children.Text>
        </SeeAllResultsButton>
      )}
    </>
  );
}

export default Hits;
