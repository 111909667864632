import removeMultipleForwardSlashes from 'shared/helpers/uri/removeMultipleForwardSlashes';
import { VOD_HODOR_ROUTES_REGEXP } from 'shared/blocks/vod/hodor/constants';
import { FAQ_ROUTES_REGEXP } from 'shared/blocks/smart-faq/constants';

const DYNAMIC_ROUTES = [VOD_HODOR_ROUTES_REGEXP, FAQ_ROUTES_REGEXP];

const getRouteForLocation = (location: Location) => {
  const sanitizedPathname = removeMultipleForwardSlashes(location.pathname);

  return DYNAMIC_ROUTES.map(
    blacklistRoute => new RegExp(blacklistRoute)
  ).reduce((pathname, regex) => {
    const result = regex.exec(pathname);

    if (result === null) {
      return pathname;
    }

    // return the result from the first capturing group
    return `${result[1]}/`;
  }, sanitizedPathname);
};

export default getRouteForLocation;
