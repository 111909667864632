import styled from 'styled-components';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader/Loader';
import { COLORS } from 'shared/constants/theme';
import clientCookies from 'client/cookies';

const LoadingWrapper = styled.div`
  height: ${props => `${props.height || 300}px`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

class AuthDisplayHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      shouldDisplay: false
    };
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    requestLegacyAuth: PropTypes.bool.isRequired,
    requestAwakenAuth: PropTypes.bool.isRequired,
    height: PropTypes.number
  };

  getShouldDisplay = props => {
    const { requestLegacyAuth, requestAwakenAuth } = props;

    if (requestLegacyAuth) {
      return Boolean(clientCookies.get('AuthOTTToken'));
    }
    if (requestAwakenAuth) {
      return Boolean(clientCookies.get('AuthOTTTokenV2'));
    }

    return true;
  };

  checkAndSetShouldDisplay = props => {
    this.setState({
      loading: false,
      shouldDisplay: this.getShouldDisplay(props)
    });
  };

  componentDidMount() {
    this.checkAndSetShouldDisplay(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!document) return;
    if (
      nextProps.requestLegacyAuth !== this.props.requestLegacyAuth ||
      nextProps.requestAwakenAuth !== this.props.requestAwakenAuth
    ) {
      this.checkAndSetShouldDisplay(nextProps);
    }
  }

  render() {
    const { children, height } = this.props;
    const { loading, shouldDisplay } = this.state;

    if (loading) {
      return (
        <LoadingWrapper height={height}>
          <Loader color={COLORS.black} />
        </LoadingWrapper>
      );
    }

    return shouldDisplay && children;
  }
}

export default AuthDisplayHandler;
