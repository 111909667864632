import * as React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import ResponsiveHoc from 'shared/components/presentational/ResponsiveHOC';
import { ResponsiveHocProps } from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';
import Raw from 'shared/components/presentational/Raw/Raw';
import Title from 'shared/components/presentational/Title';
import Spacer from 'shared/components/presentational/Spacer';
import Button from 'shared/components/presentational/Button';
import ApashButton from 'shared/components/presentational/Button/ApashButton';
import { COLORS, FONT_FAMILIES } from 'shared/constants/theme';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import BellIcon from 'shared/components/presentational/Icons/components/Bell';
import { FormattedProps } from 'shared/blocks/due-date/types';

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  ${mediaQueries.fromTablet`
    flex-direction: row;
    margin-bottom: 60px;
    justify-content: space-between;
  `}
  justify-content: space-around;
`;

const Date = styled.span`
  text-align: center;
  font-size: ${pxInRem(14)};
  color: ${props => props.color};
  margin-top: 7px;
`;

const Description = styled(Raw)`
  text-align: center;
  font-family: ${FONT_FAMILIES.base};
  color: ${COLORS.mineShaft};
  font-size: ${pxInRem(16)};
  line-height: 1.3;
  ${mediaQueries.fromTablet`
    margin: 0 2rem;
    text-align: left;
  `}
`;

const ReminderWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  top: -12px;
  left: 35%;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconMarker = styled.div`
  width: 4px;
  height: 32px;
  background: #000;
  border-radius: 2px;
  margin-bottom: 1rem;
`;

const ReminderText = styled.span`
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: ${props => props.color};
  margin: 0 0 0.3rem 1rem;
`;

const TimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 6rem;

  ${mediaQueries.fromTablet`
    margin: 0 2rem;
    min-width: 50%;
  `}
`;

const LabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  margin-bottom: 2rem;
`;

const TimeDescription = styled.span`
  text-align: center;
  font-weight: bold;
  font-size: ${pxInRem(14)};
  height: ${pxInRem(30)} !important;
  color: ${props => props.color};

  sup {
    font-size: 9px;
    top: 0;
  }
`;

const Timeline = styled.div`
  position: relative;

  height: 4px;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(237, 54, 85, 1) 50%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 1) 85%,
    rgba(255, 255, 255, 0) 100%
  );

  ${mediaQueries.fromTablet`
    background: linear-gradient(
      90deg,
      rgba(237,54,85,0) 0%,
      rgba(237,54,85,0) 15%,
      rgba(237,54,85,1) 15%,
      rgba(237,54,85,1) 50%,
      rgba(0,0,0,1) 50%,
      rgba(0,0,0,1) 85%,
      rgba(255,255,255,0) 100%
    );
  `}
`;

interface DateCircleProps {
  color: string;
  positionLeft: string;
}

const DateCircle = styled.div<DateCircleProps>`
  position: absolute;
  width: 32px;
  height: 32px;
  border: solid 10px ${props => props.color};
  background-color: ${COLORS.white};
  top: -16px;
  left: calc(${props => props.positionLeft} - 16px);
  border-radius: 50%;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
`;

interface BulletConfig {
  color: string;
  positionLeft: string;
}

interface ItemConfig {
  label?: React.ReactElement;
  color: string;
  date: string | null;
  bullet: BulletConfig;
}

interface ReminderProps {
  color: string;
}

const DueDate = ({
  title,
  description,
  color,
  button,
  mediaQueries,
  subtitle,
  handleClick
}: FormattedProps & ResponsiveHocProps) => {
  const { t } = useTranslation();
  const Reminder = ({ color }: ReminderProps) => (
    <ReminderWrapper>
      <IconWrapper>
        <IconMarker />
        <BellIcon width="2rem" height="2rem" />
      </IconWrapper>
      <ReminderText color={color}>
        {t('blocks.apash.dueDate.reminder')}
      </ReminderText>
    </ReminderWrapper>
  );

  const renderDateItems = React.useCallback(
    ({ color, label, date }: ItemConfig) => (
      <LabelWrapper key={`date-${date}`}>
        {label}
        <Date color={color}>{date}</Date>
      </LabelWrapper>
    ),
    []
  );

  const renderBullets = React.useCallback(
    ({ bullet: { color, positionLeft } }: ItemConfig) => (
      <DateCircle color={color} positionLeft={positionLeft} />
    ),
    []
  );

  const itemsConfig = [
    {
      label: (
        <TimeDescription color={color.secondary}>
          {t('blocks.apash.dueDate.trialPeriod')}
        </TimeDescription>
      ),
      color: color.default,
      date: null,
      bullet: {
        color: color.secondary,
        positionLeft: `${mediaQueries.toPhablet ? 2 : 15}%`
      }
    },
    {
      label: (
        <TimeDescription color={color.default}>
          1<sup>{t('blocks.apash.dueDate.exponent')}</sup>{' '}
          {t('blocks.apash.dueDate.dueDate')}
        </TimeDescription>
      ),
      color: color.default,
      date: dayjs().add(6, 'days').format('DD/MM'),
      bullet: { color: color.default, positionLeft: '50%' }
    },
    {
      label: <TimeDescription color={color.default}>{''}</TimeDescription>,
      color: color.default,
      date: dayjs().add(6, 'days').add(1, 'month').format('DD/MM'),
      bullet: { color: color.default, positionLeft: '85%' }
    }
  ];

  return (
    <ComponentWrapper>
      <Title
        titleLabel={title.label}
        titleHtmlTag={title.htmlTag}
        titleColor={title.color}
        subtitle={subtitle}
      />
      <Spacer height={1} mobileHeight={0.5} />
      <MainWrapper>
        <TimelineWrapper>
          <LabelsWrapper>{itemsConfig.map(renderDateItems)}</LabelsWrapper>
          <Timeline>
            {itemsConfig.map(renderBullets)}
            <Reminder color={color.default} />
          </Timeline>
        </TimelineWrapper>
        <Description html={description} />
      </MainWrapper>
      <ApashButton
        theme={button.theme}
        to={button.link}
        type="large"
        onClick={handleClick}
      >
        <Button.children.Text>{button.label}</Button.children.Text>
      </ApashButton>
    </ComponentWrapper>
  );
};

export default ResponsiveHoc(DueDate);
