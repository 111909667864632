import React from 'react';
import styled from 'styled-components';

import Raw from 'shared/components/presentational/Raw/Raw';
import Button from 'shared/components/presentational/Button';
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import Picture from 'shared/components/presentational/Picture';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { COLORS, FONT_FAMILIES } from 'shared/constants/theme';
import { FormattedBlock } from '../types';

const Wrapper = styled.div`
  width: calc(${props => 100 / props.theme.contact.maxItem}% - 10px);
  margin: 5px;
  padding: 35px 15px;
  border: ${props => props.theme.contact.border};
  box-shadow: ${props => props.theme.contact.boxShadow};

  ${mediaQueries.toTablet`
      width: calc(33% - 10px);
  `} ${mediaQueries.toPhablet`
    width: 100%;
  `};
`;

const StyledPicture = styled(Picture)`
  display: flex;
  width: 40px;
  margin: 0 auto 15px;
`;

const StyledRaw = styled(Raw)`
  font-family: ${FONT_FAMILIES.base};
  font-size: ${pxInRem(14)};
  line-height: ${pxInRem(22)};
`;

const StyledButton = styled(Button)`
  margin: 0 auto 20px;
`;

const buttonTheme = themeFactory({
  color: COLORS.black,
  backgroundColor: 'transparent',
  textDecoration: 'underline'
});

export default function ContactColumn({
  text,
  pictogram,
  buttonLabel,
  buttonLink
}: FormattedBlock) {
  return (
    <Wrapper>
      <StyledPicture
        url={pictogram}
        width={40}
        placeholderColor="transparent"
      />
      {text && <StyledRaw html={text} />}
      {buttonLabel && buttonLink && (
        <StyledButton to={buttonLink} theme={buttonTheme}>
          <Button.children.Text>{buttonLabel}</Button.children.Text>
        </StyledButton>
      )}
    </Wrapper>
  );
}
