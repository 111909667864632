/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { PureComponent } from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import remHelper from 'shared/helpers/styled-components/remHelper';
import Picture from 'shared/components/presentational/Picture';
import { DIFFUSIONS_TIME_FORMAT } from 'shared/components/presentational/ProgramDetail/constant';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

const Wrapper = styled.div`
  margin-top: 28px;
  border-top: 1px solid ${COLORS.mercury};
`;

const Container = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
`;

const Title = styled.h2`
  margin-top: 38.5px;
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  text-transform: uppercase;
  font-size: ${remHelper(22)};
  line-height: ${remHelper(30)};

  ${mediaQueries.toPhablet`
    font-size: ${remHelper(15)}
  `};
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  height: 45px;
  margin: 9px 0;
  padding-left: 40px;
  width: 50%;
  font-size: ${remHelper(14)};
  font-family: ${FONT_FAMILIES.base};
  text-transform: capitalize;

  ${mediaQueries.toPhablet`
      min-width: 100%;
  `};
`;

const DiffusionTime = styled.span`
  margin-left: 35px;
`;

const PictureWrapper = styled.div`
  width: 50px;
`;

export default class Diffusions extends PureComponent {
  static propTypes = {
    timezone: string.isRequired,
    diffusions: arrayOf(
      shape({
        URLLogo: string.isRequired,
        URLLogoBlack: string.isRequired,
        ZapNumber: string.isRequired,
        broadcastId: string.isRequired,
        diffusionDateUTC: number.isRequired,
        epgID: string.isRequired,
        name: string.isRequired,
        sharingUrl: string.isRequired
      })
    )
  };

  render() {
    const { diffusions, timezone, t } = this.props;
    return (
      <Wrapper>
        <Title>{t('components.programmDetail.diffusion')}</Title>
        <Container>
          {diffusions.map((diff, index) => {
            const diffTime = dayjs
              .unix(diff.diffusionDateUTC)
              .tz(timezone)
              .format(DIFFUSIONS_TIME_FORMAT);

            return (
              <Item key={index}>
                <PictureWrapper>
                  <Picture
                    url={diff.URLLogo}
                    height={45}
                    placeholderColor="transparent"
                  />
                </PictureWrapper>
                <DiffusionTime>{diffTime}</DiffusionTime>
              </Item>
            );
          })}
        </Container>
      </Wrapper>
    );
  }
}
