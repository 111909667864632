import { Cookies } from 'react-cookie';

import setCookie from './setCookie';

const setCookies = (
  cookies: Cookies,
  cookiesToSet: { [name: string]: string },
  options = {} // https://www.npmjs.com/package/universal-cookie#setname-value-options
) => {
  Object.keys(cookiesToSet).forEach(name => {
    setCookie(cookies, name, cookiesToSet[name], options);
  });
};

export default setCookies;
