import { useQuery } from 'react-query';
import axios from 'axios';

import { Api } from 'shared/types';
import { GetTitlesResponse } from '../types';

const useGetTitlesQuery = (api: Api) => {
  return useQuery<GetTitlesResponse, string>(
    api.url,
    async () => {
      return axios
        .request<GetTitlesResponse>(api)
        .then(response => response.data);
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};

export default useGetTitlesQuery;
