import { Api, ButtonApi, Media, ThemeFactory } from 'shared/types';
import { EventObject } from '../../new-selfcare/shared/hooks/useSelectEventQuery';
import { WithSubscriberDataProps } from '../../new-selfcare/shared/hocs/withSubscriberDataQuery';
import { WithAvailableEventsProps } from '../../new-selfcare/shared/hocs/withGetAvailableEventsQuery';
import { WithSelectEventProps } from '../../new-selfcare/shared/hocs/withSelectEventQuery';
import { WithAllmolQuotasProps } from '../../new-selfcare/shared/hocs/withAllmolQuotasQuery';
import { WithSelectObjectsMutationProps } from '../../new-selfcare/shared/hocs/withSelectObjectsMutation';
import { WithBookEventObjectsMutationProps } from '../../new-selfcare/shared/hocs/withBookEventObjectsMutation';
import { WithEncryptAllmolMutationProps } from '../../new-selfcare/shared/hocs/withEncryptAllmolMutation';

export enum EVENT_TYPE {
  ALLMOL = 'allmol',
  CLASSIC = 'classic'
}

export interface Event {
  code: string;
  description: string;
  type: EVENT_TYPE;
  media: Media;
}

export interface Content {
  events: Event[];
  apis: {
    [key: string]: Api;
  };
  buttonsLabelColor: string;
  buttonsBackgroundColor: string;
  collectionPointsLabel: string;
  termsAndConditionsText: string;
  confirmationText: string;
  buttons: {
    [key: string]: ButtonApi;
  };
}

export interface FormattedProps {
  confirmationHtml: string;
  confirmationButton: {
    label: string;
    link: string;
  };
  cancelButton: {
    label: string;
    link: string;
  };
  termsAndConditionsHtml: string;
  distributorsHtml: string;
  events: FormattedEvent[];
  buttonThemes: {
    submit: ThemeFactory;
    confirmation: ThemeFactory;
    cancel: ThemeFactory;
  };
  apis: {
    getAvailableEvents: Api;
    selectEvent: Api;
    selectObjects: Api;
    bookEventObjects: Api;
    allmolIframeUrl: string;
    allmolQuotas: Api;
    getSubscriberData: Api;
  };
}

export interface PropsFromState {
  eventCode: string | null;
  numContract: string | string[] | null;
}

export type Props = FormattedProps &
  PropsFromState &
  WithSubscriberDataProps &
  WithAvailableEventsProps &
  WithSelectObjectsMutationProps &
  WithBookEventObjectsMutationProps &
  WithEncryptAllmolMutationProps;

export type QuantitiesPropsFromParent = Pick<
  Props,
  'apis' | 'numContract' | 'eventCode'
> & {
  onSubmit: (eventDetail: EventObject, quantity: number) => void;
  event: FormattedEvent | undefined;
  eventType: EVENT_TYPE | undefined;
  device: string;
  cancelButton: {
    label: string;
    link: string;
  };
  buttonThemes: {
    submit: ThemeFactory;
    cancel: ThemeFactory;
  };
} & WithAvailableEventsProps;

export type QuantitiesProps = QuantitiesPropsFromParent &
  WithSelectEventProps &
  WithAllmolQuotasProps;

export type DistributorsPropsFromParent = Pick<
  Props,
  'apis' | 'numContract' | 'eventCode'
> & {
  eventType: EVENT_TYPE | undefined;
  html: string;
  termsAndConditionsHtml?: string;
  onSubmit: (object: EventObject, numDistrib: string) => void;
  cancelButton: {
    label: string;
    link: string;
  };
  buttonThemes: {
    submit: ThemeFactory;
    cancel: ThemeFactory;
  };
} & WithSelectObjectsMutationProps;

export type DistributorsProps = DistributorsPropsFromParent &
  WithSelectEventProps;

export type ConfirmationProps = Pick<Props, 'numContract'> & {
  buttonTheme: ThemeFactory;
  confirmationButton: {
    label: string;
    link: string;
  };
  html: string;
} & WithBookEventObjectsMutationProps;

export type AllmolIframeProps = {
  allmolIframeUrl: string;
} & WithEncryptAllmolMutationProps;

export interface FormattedEvent {
  label: string | null;
  code: string;
  description: string;
  type: EVENT_TYPE;
  imageUrl: string;
  imageAlt: string;
}
