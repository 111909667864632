import { THUMBOR_SERVER } from 'shared/components/presentational/Picture/constants';
import createTemplate from 'shared/helpers/template/createTemplate';
import fallbackImage from 'shared/assets/images/fallback.png';

export const getCleanUrl = (url: string) => url.replace(/(^\w+:|^)\/\//, '');
export default function getThumborUrl({
  url,
  width = 0,
  height = 0,
  thumborFilter = 100
}: {
  url: string | undefined;
  width?: number;
  height?: number;
  thumborFilter?: number;
}) {
  if (typeof url === 'undefined') {
    return fallbackImage;
  }

  const isAlreadyOptimised = url.includes(THUMBOR_SERVER);
  const widthAndHeight = `${width}x${height}`;

  if (isAlreadyOptimised) {
    const getUrlWithResolution = createTemplate(url);
    return getUrlWithResolution({ resolutionXY: widthAndHeight });
  }

  return `https://${THUMBOR_SERVER}/http/unsafe/${widthAndHeight}/smart/filters:quality(${thumborFilter})/${getCleanUrl(
    url
  )}`;
}
