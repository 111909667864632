import styled from 'styled-components';

import Button from 'shared/components/presentational/Button';

export const ButtonCTA = styled(Button)`
  padding: 12px 8px;
  height: fit-content;
  width: 100%;
  justify-content: center;
  align-self: flex-end;
  border-radius: var(--border-radius-xs);

  color: ${({ theme }) => theme.color};

  &:hover {
    color: ${({ theme }) => theme.hover};
  }
`;
export const Wrapper = styled.div`
  width: 100%;
`;

export const Label = styled(Button.children.Text)`
  display: block;
  margin-left: 0;
  font-size: 16px;
`;
