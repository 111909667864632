import styled from 'styled-components';

import Picture from 'shared/components/presentational/Picture';
import { BORDER_RADIUS, COLORS } from 'shared/constants/theme';
import { collapsePadding } from 'shared/constants/mixins';

export const ContentWrapper = styled.div`
  flex: 1;
  align-self: stretch;
  padding: 0 20px;
  ${collapsePadding('24px')}
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const Wrapper = styled.div<{ borderHoverColor?: string }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 10px 30px rgba(0, 0, 0, 0.04);
  border-radius: ${BORDER_RADIUS}px;
  border: 1px solid ${COLORS.concrete};
  overflow: hidden;
  &:hover {
    border: solid 2px ${props => props.borderHoverColor || COLORS.amaranth};
    // the following lines aim to compensate border thickness change
    ${ContentWrapper} {
      padding: 0 19px;
      ${collapsePadding('23px')}
    }
    ${ImageWrapper} {
      margin: -1px -1px 1px;
    }
  }
`;

export const Image = styled(Picture)`
  width: 100%;
  height: 166px;
  border-radius: 3px 3px 0 0;
  object-fit: cover;
`;
