import { connect } from 'react-redux';

import getCurrentZone from 'shared/modules/page/selectors/getCurrentZone';
import Footer from '../components/Footer';
import { PropsFromDispatch, PropsFromState } from '../types';
import pushURLHandler from 'shared/helpers/router/pushURLHandler';

const mapStateToProps = (state: any): PropsFromState => ({
  currentZone: (getCurrentZone(state) || '').toLowerCase()
});

const mapDispatchToProps: PropsFromDispatch = {
  pushURLHandler
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
