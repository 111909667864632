import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

interface SpacerProps {
  height: number;
  mobileHeight?: number;
}

const Spacer = styled.div<SpacerProps>`
  height: ${props => props.height}rem;
  ${mediaQueries.toPhablet<SpacerProps>`
     height: ${props => props.mobileHeight}rem;
  `}
`;

export default Spacer;
