import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 10"
    width={width}
    height={height}
    className={className}
  >
    <path
      d="M0 0v2h12V0H0zm0 6h12V4H0v2zm0 4h8V8H0v2z"
      fillRule="nonzero"
      fill="currentColor"
    ></path>
  </svg>
);
