import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.66669L5 5.66669L9 1.66669"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);
