import { BannerUrlByDevice } from 'shared/types';

export const getDisplayedVideo = (
  mediaQueries: { [mediaQuery: string]: boolean },
  bannerUrlByDevice: BannerUrlByDevice
) => {
  if (mediaQueries.toPhablet) {
    return (
      bannerUrlByDevice.mobile ||
      bannerUrlByDevice.tablet ||
      bannerUrlByDevice.desktop
    );
  }
  if (mediaQueries.toDesktop) {
    return bannerUrlByDevice.tablet || bannerUrlByDevice.desktop;
  }

  return bannerUrlByDevice.desktop;
};
