import React from 'react';

import { IconProps } from '../types';

export default ({ className, width = '1em', height = '1em' }: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m22 7.457-1.876 10.457c-.173.967-.968 1.679-1.913 1.732l-.114.003H7.131c-.95 0-1.77-.666-2-1.603l-.024-.114L3.084 7.174a.299.299 0 0 0-.237-.24l-.052-.005H1.109V5.112h1.686c.95 0 1.77.666 2 1.604l.024.113 2.023 10.758c.023.124.12.219.237.24l.052.005h10.966c.125 0 .233-.08.276-.196l.014-.052 1.875-10.456 1.738.33Z"
      fill="#fff"
    />
    <path d="M13.468 4.347v7.269h-1.767V4.347h1.767Z" fill="#fff" />
    <path
      d="m15.788 7.945 1.25 1.285-4.453 4.58-4.453-4.58 1.25-1.285 3.203 3.295 3.203-3.295Z"
      fill="#fff"
    />
  </svg>
);
