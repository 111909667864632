import { connect } from 'react-redux';

import { orderZones } from 'shared/helpers/zones';
import { fetchZone } from 'shared/modules/geolocation/actions/index';
import getToken from 'shared/modules/router/selectors/queryParams/getToken';
import getPageType from 'shared/modules/page/selectors/getPageType';
import { PAGE_TYPE } from 'shared/modules/page/constants';
import {
  validateZoneSelection,
  resetZoneSelection,
  selectZone
} from '../redux/actions';
import ModalGeolocation from '../components/ModalGeolocation';
import { FormattedProps, PropsFromState, PropsFromDispatch } from '../types';

/**
 * Connected container for Geolocation component
 */
const mapStateToProps = (
  state: any,
  ownProps: FormattedProps
): PropsFromState => {
  const { fetchingZonePending } = state.geolocation;

  const { selectedZone } = state.modalGeolocation;

  const zones = orderZones(ownProps.allZones);

  const passParamId = getToken(state);
  const pageType = getPageType(state);

  /*
  GeolocationModal should be closed during silent authentication,
  otherwise it would "flash" during each redirection
  */
  const modalMustBeClosed =
    Boolean(passParamId) || pageType === PAGE_TYPE.SILENT_LOGIN;

  return {
    isOpen: !modalMustBeClosed,
    selectedZone,
    fetchingZonePending,
    zones,
    totalZones: zones.length
  };
};

const mapDispatchToProps: PropsFromDispatch = {
  fetchZoneHandler: fetchZone,
  selectZoneHandler: selectZone,
  validateZoneSelectionHandler: validateZoneSelection,
  resetZoneSelectionHandler: resetZoneSelection
};

const ModalGeolocationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalGeolocation);

export default ModalGeolocationContainer;
