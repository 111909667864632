import { Link, Media } from 'shared/types';

export type Jaquette = {
  type: string;
  link: Link;
  media: Media;
};

export type SeeMore = {
  type: string;
  seeMore: { icon: Media; label: string; link: Link };
};

export type JaquetteCard = {
  type: string;
  link: string | null;
  picture: { image: string; alt: string } | null;
};

export type SeeMoreCard = JaquetteCard & {
  label: string;
};

export type EngagementUnitShort = 'M' | 'J' | 'P';

export enum EngagementUnit {
  M = 'month',
  J = 'day',
  P = 'package'
}

export type ProductMinor = {
  productId: string;
  title: string;
  description: string | null;
  sendToCRM: boolean;
  offerDetails: {
    price: number;
    promoDuration: string;
    engagementUnit: EngagementUnit;
    commitmentConditions: string | null;
  };
};

export type ProductMajor = {
  productId: string;
  title: string;
  description?: string | null;
  promotionalSticker: string | null;
  offerDetails: {
    availableCommitmentDurations: string;
    commitmentConditions: string | null;
    engagementDuration: string | null;
    engagementUnit: EngagementUnit | null;
    netPrice?: number;
    price?: number;
    priceInformation?: string;
    promoDuration: string;
  };
};
