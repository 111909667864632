import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

export interface OrderedZone {
  name: string;
  bigram: string;
}

export interface Zone {
  [key: string]: string;
}

const formatZones = (zones: Zone): OrderedZone[] =>
  Object.keys(zones).map(zone => ({
    bigram: zone.toLowerCase(),
    name: zones[zone]
  }));

const sortByNameAsc = (zones: OrderedZone[]): OrderedZone[] =>
  orderBy(zones, ['name'], ['asc']);

// uses createSelector for performance concerns
export default createSelector([formatZones], formattedZones =>
  sortByNameAsc(formattedZones)
);
