import styled from 'styled-components';

import { FONT_WEIGHTS } from 'shared/constants/theme';
import IconComponent from 'shared/components/presentational/Icons';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Picture from 'shared/components/presentational/Picture';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  width: 100%;
`;

export const Item = styled.button`
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  font-size: var(--text-size-base);
  font-weight: ${FONT_WEIGHTS.demi};
  color: var(--text-default-main);

  &:focus-visible {
    outline: auto;
  }
`;

export const Icon = styled(IconComponent)`
  color: var(--icons-default);
`;

export const Channel = styled(Picture)`
  display: flex;
  height: 39px;
  width: 52px;
  display: block;
  margin: auto;
  object-fit: contain;

  ${mediaQueries.fromTablet`
    height: 48px;
    width: 64px;
  `}

  ${mediaQueries.fromDesktop`
    height: 66px;
    width: 88px;
  `}
`;

export const Subtitle = styled.p`
  margin: 0;
  margin-top: var(--spacing-s);
  color: var(--text-default-light);
`;

export const ChannelWrapper = styled.div`
  display: grid;
  gap: var(--spacing-xs);
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(5, 1fr);
  position: relative;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
  font-size: var(--text-size-s);
  color: var(--text-default-light);

  &[aria-hidden='true'] {
    display: none;
  }
`;
