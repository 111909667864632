/* eslint-disable react/no-danger */
/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*       */

import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { getImageWithSize } from 'shared/helpers/image';

const LOGO_WIDTH = 500;

/**
 * JsonLd component for displaying json-ld data
 * @constructor
 */
class JsonLd extends React.Component {
  getJsonLdData() {
    const { data, getAbsoluteLink } = this.props;

    if (!data) {
      return data;
    }

    const jsonLdData = cloneDeep(data);

    // First level url is replaced by current page url
    if (jsonLdData && (jsonLdData.url || jsonLdData.url === null)) {
      jsonLdData.url = getAbsoluteLink();
    }

    // mainEntity path is prefixed by site domain
    if (jsonLdData && jsonLdData.mainEntity && jsonLdData.mainEntity.url) {
      jsonLdData.mainEntity.url = getAbsoluteLink(jsonLdData.mainEntity.url);
    }

    // searchPath path is prefixed by site domain
    if (
      jsonLdData &&
      jsonLdData.potentialAction &&
      jsonLdData.potentialAction.target &&
      jsonLdData.potentialAction.target.urlTemplate
    ) {
      jsonLdData.potentialAction.target.urlTemplate = getAbsoluteLink(
        jsonLdData.potentialAction.target.urlTemplate
      );
    }

    if (jsonLdData && jsonLdData.mainEntity && jsonLdData.mainEntity.logo) {
      jsonLdData.mainEntity.logo = getImageWithSize(
        jsonLdData.mainEntity.logo,
        LOGO_WIDTH
      );
    }

    return jsonLdData;
  }

  render() {
    const jsonLdData = this.getJsonLdData();

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData) }}
      />
    );
  }
}

export default JsonLd;
