import isArray from 'lodash/isArray';

import getHashParams from 'shared/modules/router/selectors/getHashParams';

export default (state: any) => {
  const accessToken = getHashParams(state)['access_token'];

  if (!accessToken || isArray(accessToken)) {
    return null;
  }
  return accessToken;
};
