export default (inputPrice: string | number) => {
  if (typeof inputPrice === 'number') {
    return { price: inputPrice.toString(), priceComplement: '' };
  }

  const digitSplit = inputPrice.split(/(\D+)/g);

  return digitSplit.reduce(
    (acc, digitOrNot: string) => {
      // first loop
      if (acc.price === '') {
        return {
          price: digitOrNot,
          priceComplement: ''
        };
      }

      // optional second loop (if first was not a digit)
      if (!isNaN(parseInt(digitOrNot)) && acc.priceComplement === '') {
        return {
          price: `${acc.price}${digitOrNot}`,
          priceComplement: ''
        };
      }

      return {
        price: acc.price,
        priceComplement: `${acc.priceComplement}${digitOrNot}`
      };
    },
    { price: '', priceComplement: '' }
  );
};
