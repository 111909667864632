import { Content, FormattedProps } from './types';

export default (content: Content): FormattedProps => ({
  title: content.title.label ?? '',
  titleColor: content.title.color ?? '',
  titleHtmlTag: content.title.htmlTag ?? '',
  apis: {
    getUnpaidInfos: {
      url: content.apis?.['selfcare.accouting.get_unpaid_info']?.url ?? '',
      method:
        content.apis?.['selfcare.accouting.get_unpaid_info']?.method || 'GET'
    },
    registerPrepayment: {
      url: content.apis?.['selfcare.accouting.register_prepayment']?.url ?? '',
      method:
        content.apis?.['selfcare.accouting.register_prepayment']?.method ||
        'PUT'
    },
    getRenewalOffer: {
      url: content.apis?.['selfcare.accounting.get_renewal_offer']?.url ?? '',
      method:
        content.apis?.['selfcare.accounting.get_renewal_offer']?.method || 'GET'
    },
    getSubscriberData: {
      url: content.apis?.getSubscriberData?.url ?? '',
      method: content.apis?.getSubscriberData?.method || 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    }
  }
});
