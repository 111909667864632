import get from 'lodash/get';
import queryString from 'querystring';

export default function getParamFromPath(
  currentPath: string,
  paramName: string
) {
  const [, queryParams] = currentPath.split('?');
  const params = queryString.parse(queryParams);
  return get(params, paramName, null);
}
