/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';

import { WILDCARD_PATH } from '../smart-faq/constants';
import createTemplate from 'shared/helpers/template/createTemplate';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';

const getFaqUrlTemplate = content => {
  const baseUrl = get(content, 'apis.smartfaq.url', '');

  return createTemplate(
    `${baseUrl}/configuration?___zone={smartFaqUUID}&___base_url=//{hostname}/{bigram}/${WILDCARD_PATH}`
  );
};

export default (content, { getLinkHrefWithAuthentication }) => ({
  template: content.template,
  formId: kebabCase(get(content, 'form.name')),
  formCategory: get(content, 'form.category'),
  title: get(content, 'title'),
  subtitle: get(content, 'subtitle'),
  questions: get(content, 'form.questions', []).map(question => ({
    ...question,
    placeholder: question.placeholder,
    options: Object.entries(
      get(question, 'settings.choices', [])
    ).map(([value, { label }]) => ({ label, value }))
  })),
  backButtonLink: getLinkHrefWithAuthentication(
    get(content, 'backButton.link', null)
  ),
  backButtonLabel: get(content, 'backButton.label'),
  backButtonTheme: buttonThemeFactory({
    color: get(content, 'backButton.labelColor'),
    backgroundColor: get(content, 'backButton.backgroundColor'),
    hover: { backgroundColor: get(content, 'backButton.hoverColor') }
  }),
  submitButtonLink: getLinkHrefWithAuthentication(
    get(content, 'validButton.link', null)
  ),
  submitButtonLabel: get(content, 'validButton.label'),
  submitButtonTheme: buttonThemeFactory({
    color: get(content, 'validButton.labelColor'),
    backgroundColor: get(content, 'validButton.backgroundColor'),
    hover: { backgroundColor: get(content, 'validButton.hoverColor') }
  }),
  // AES-256-CBC encrypted mail recipient
  mailRecipient: content.mailRecipient || null,
  withMailing: Boolean(get(content, 'mailRecipient')),
  apiUrl: get(content, 'apis.np6.url', ''),
  apiMethod: get(content, 'apis.np6.method', 'POST'),
  zone: get(content, 'currentZone', null),
  faqUrlTemplate: getFaqUrlTemplate(content)
});
