import React from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
}
export default ({ className, width = '1em', height = '1em' }: Props) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 30 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.9592 22.7143C26.3469 21.6939 25.2828 19.3265 24.8367 15.3265L24.7143 13.2041C24.7143 9.28571 22.2245 5.77551 18.5918 4.42857V4.26531C18.5918 3.39929 18.2478 2.56875 17.6354 1.95639C17.0231 1.34402 16.1925 1 15.3265 1C14.4605 1 13.63 1.34402 13.0176 1.95639C12.4052 2.56875 12.0612 3.39929 12.0612 4.26531V4.42566C8.42856 5.77259 5.93877 9.2828 5.93877 13.2012L5.77551 15.2857C5.32653 19.3265 4.2653 21.6531 3.65306 22.6735C2.14286 22.9592 1 24.3061 1 25.8571C1.0025 26.7224 1.34733 27.5515 1.95915 28.1633C2.57097 28.7751 3.40006 29.1199 4.2653 29.1224H11.0437C11.3294 31.3265 13.1254 33 15.2886 33C17.4519 33 19.2478 31.2857 19.5335 29.1224H26.3469C27.2127 29.1199 28.0422 28.7747 28.6541 28.1623C29.266 27.5498 29.6105 26.72 29.6122 25.8542C29.6122 24.3032 28.4694 22.9971 26.9592 22.7114V22.7143ZM15.3265 2.63265C15.7591 2.634 16.1736 2.80644 16.4795 3.11233C16.7854 3.41822 16.9578 3.83271 16.9592 4.26531H13.6939C13.6952 3.83271 13.8677 3.41822 14.1735 3.11233C14.4794 2.80644 14.8939 2.634 15.3265 2.63265ZM7.40816 15.449L7.57142 13.3265V13.2857C7.57142 9.97959 9.73468 7 12.8775 5.93878H17.7755C20.9183 7 23.0816 9.97959 23.0816 13.2857L23.242 15.4898C23.6064 18.9155 24.4256 21.2478 25.1195 22.6735H5.57142C6.2274 21.2449 7.04081 18.8776 7.40816 15.449ZM15.3265 31.4111C14.0612 31.4111 12.9592 30.4315 12.7143 29.1662H17.8979C17.6939 30.4694 16.5918 31.4082 15.3265 31.4082V31.4111ZM26.3469 27.5306H4.30612C3.87757 27.5343 3.46473 27.3694 3.15663 27.0715C2.84853 26.7736 2.66987 26.3665 2.65915 25.9381C2.64844 25.5097 2.80655 25.0942 3.09937 24.7813C3.3922 24.4683 3.79628 24.283 4.22449 24.2653H26.4293C26.8576 24.283 27.2618 24.4683 27.5546 24.7813C27.8475 25.0943 28.0056 25.51 27.9948 25.9385C27.984 26.367 27.8051 26.7741 27.4969 27.072C27.1886 27.3698 26.7756 27.5346 26.3469 27.5306Z"
      fill="black"
      stroke="black"
      strokeWidth="0.3"
    />
    <path
      d="M20.8379 2.30581C20.7413 2.25678 20.636 2.22732 20.528 2.21914C20.42 2.21096 20.3115 2.22422 20.2086 2.25815C20.1058 2.29208 20.0106 2.34601 19.9287 2.41684C19.8468 2.48767 19.7797 2.574 19.7313 2.67087C19.6828 2.76774 19.654 2.87323 19.6465 2.98127C19.639 3.08931 19.6529 3.19777 19.6875 3.3004C19.7221 3.40304 19.7766 3.49783 19.8479 3.57931C19.9193 3.6608 20.006 3.72737 20.1032 3.7752C21.8629 4.65159 23.3422 6.00298 24.3737 7.67647C25.4052 9.34996 25.9477 11.2787 25.9399 13.2446C25.9399 13.4611 26.0259 13.6687 26.179 13.8218C26.3321 13.9749 26.5397 14.0609 26.7562 14.0609C26.9727 14.0609 27.1804 13.9749 27.3335 13.8218C27.4866 13.6687 27.5726 13.4611 27.5726 13.2446C27.5726 8.59152 25.0011 4.42535 20.8379 2.30581Z"
      fill="black"
      stroke="black"
      strokeWidth="0.3"
    />
    <path
      d="M9.81672 2.30657C5.65346 4.3882 3.08203 8.59228 3.08203 13.2453C3.08203 13.4618 3.16804 13.6695 3.32113 13.8226C3.47422 13.9757 3.68185 14.0617 3.89836 14.0617C4.11486 14.0617 4.3225 13.9757 4.47559 13.8226C4.62868 13.6695 4.71468 13.4618 4.71468 13.2453C4.7069 11.2796 5.24937 9.3509 6.28073 7.67744C7.31209 6.00397 8.79118 4.65251 10.5507 3.77596C10.7454 3.67863 10.8936 3.50792 10.9624 3.30138C11.0313 3.09484 11.0154 2.8694 10.918 2.67465C10.8207 2.47989 10.65 2.33177 10.4435 2.26288C10.2369 2.19399 10.0115 2.20997 9.81672 2.3073V2.30657Z"
      fill="black"
      stroke="black"
      strokeWidth="0.3"
    />
  </svg>
);
