/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*       */

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import pushURLHandler from 'shared/helpers/router/pushURLHandler';
import Form from '../components/Referral';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  pushURLHandler
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(Form);
