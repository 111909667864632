import queryString from 'querystring';
import get from 'lodash/get';

import clientCookies from 'client/cookies';
import { TASK_TYPE } from 'shared/modules/tasks/constants';
import getPageData from 'shared/modules/page/selectors/getPageData';
import getPagePath from 'shared/modules/page/selectors/getPagePath';
import { getDomainURL } from 'shared/modules/hostname/selectors/getDomainURL';
import createLoginRedirectUrl from 'shared/modules/page/helpers/createLoginRedirectUrl';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import getLinkHrefWithAuthenticationFactory from 'shared/helpers/uri/getLinkHrefWithAuthenticationFactory';
import { namespace } from './';
import {
  fetchProgramsCategories,
  fetchProgramsHighlightsByCategory
} from '../shared/actions';
import { getFetchableCategoryApiUrl } from '../shared/selectors';
import propsFormatter from './propsFormatter';

export default ({ dispatch, getState }) => [
  {
    pattern: '/:bigram([a-zA-Z]{2})?/*',
    [TASK_TYPE.PREFETCH_DATA]: async () => {
      const { page } = getState();

      if (page.data && page.data.blocks) {
        await Promise.all(
          page.data.blocks
            .filter(block => block.type === namespace)
            .map(async ({ content }) => {
              const state = getState();
              // Create getLinkHrefWithAuthentication helper
              const pageData = getPageData(state);
              const pagePath = getPagePath(state);
              const domainURL = getDomainURL(state);

              const loginUrlFactory = createLoginRedirectUrl(
                domainURL,
                clientCookies.get(COOKIE_KEYS.ZONE),
                pageData,
                pagePath
              );

              const getLinkHrefWithAuthentication = getLinkHrefWithAuthenticationFactory(
                loginUrlFactory,
                clientCookies
              );

              // Get formatted props
              const {
                categoriesApiUrl,
                timezone,
                createLinkUrl
              } = propsFormatter(content, {
                getLinkHrefWithAuthentication
              });

              // fetch categories
              await dispatch(fetchProgramsCategories(categoriesApiUrl));

              // get current category from query parameters
              const [, queryParams] = page.path.split('?');
              const params = queryString.parse(queryParams);
              const categoryParam = get(params, 'category', null);

              // get api url for current category param
              const apiUrl = getFetchableCategoryApiUrl(categoryParam)(
                getState()
              );

              if (apiUrl !== null) {
                // fetch current category programs
                await dispatch(
                  fetchProgramsHighlightsByCategory(
                    [], // no category has been fetched yet
                    apiUrl,
                    timezone,
                    createLinkUrl
                  )
                );
              }
            })
        );
      }
    }
  }
];
