import { darken } from 'polished/lib/index';

import { COLORS } from 'shared/constants/theme';

export default {
  button: {
    normal: {
      backgroundColor: COLORS.white,
      borderColor: COLORS.white,
      color: COLORS.red,
      textDecoration: 'none'
    },
    hover: {
      backgroundColor: darken(0.05, COLORS.white),
      borderColor: darken(0.05, COLORS.white),
      color: COLORS.red,
      textDecoration: 'none'
    },
    disabled: {
      backgroundColor: darken(0.2, COLORS.white),
      borderColor: darken(0.2, COLORS.white),
      color: darken(0.2, COLORS.red),
      textDecoration: 'none'
    }
  }
};
