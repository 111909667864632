/* eslint-disable react/prop-types */

import React, { PureComponent } from 'react';
import styled from 'styled-components';

import ProgramDetail from 'shared/components/presentational/ProgramDetail/ProgramDetail';
import hodorToProgramDetailProps from 'shared/components/presentational/ProgramDetail/dto/hodor';
import OnePlayer from 'shared/components/presentational/OnePlayer/components/OnePlayer';
import BasePicture from 'shared/components/presentational/Picture';
import { COVER_IMG_HEIGHT } from 'shared/components/presentational/ProgramDetail/constant';

const Picture = styled(BasePicture)`
  max-height: ${props => props.height}px;
`;

class DetailPage extends PureComponent {
  render() {
    const { data, player } = this.props;

    const {
      isPlayerAvailable,
      URLImage,
      playerContent,
      ...props
    } = hodorToProgramDetailProps(data);

    if (!isPlayerAvailable) {
      return (
        <ProgramDetail
          {...props}
          coverComponent={<Picture url={URLImage} height={COVER_IMG_HEIGHT} />}
        />
      );
    }

    return (
      <ProgramDetail
        {...props}
        coverComponent={
          <OnePlayer
            bundleURL={player.bundleURL}
            playerConfig={player.playerConfig}
            content={playerContent}
            height={COVER_IMG_HEIGHT}
          />
        }
      />
    );
  }
}

export default DetailPage;
