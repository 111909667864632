import fetchWebservice from '../webservices/fetch';
import fetchError from './fetchError';
import fetchSuccess from './fetchSuccess';
import fetchRequest from './fetchRequest';

export default (apiUrl, slug, zone, referrerUrl) => async dispatch => {
  dispatch(fetchRequest(slug));
  try {
    const targetUrl = `${apiUrl}/${slug}`;
    const data = await fetchWebservice(targetUrl, zone, referrerUrl);
    return dispatch(fetchSuccess(data, slug));
  } catch (e) {
    return dispatch(fetchError(e.response.data));
  }
};
