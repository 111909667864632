import React from 'react';

import {
  ChannelGridItem,
  CustomLink
} from 'shared/components/presentational/ChannelsGroup/styles/components';
import { SeeMoreWrapper } from '../styles/components';
import { Modal } from './Modal';
import { Channel } from 'shared/components/presentational/ChannelsGroup/types';

interface Props {
  link?: string;
  title?: string;
  modalProps: {
    title: string;
    channels: Channel[];
  };
}

export const SeeMore = ({
  link,
  title,
  modalProps: { title: modalTitle, channels }
}: Props) => {
  const [isModalOpened, toggleModal] = React.useState(false);

  return (
    <>
      <CustomLink
        to={link}
        onClick={link ? undefined : () => toggleModal(!isModalOpened)}
      >
        <ChannelGridItem>
          <SeeMoreWrapper>{title}</SeeMoreWrapper>
        </ChannelGridItem>
      </CustomLink>
      <Modal
        title={modalTitle}
        channels={channels}
        toggleModal={toggleModal}
        isModalOpened={isModalOpened}
      />
    </>
  );
};
