import { BREAKPOINTS } from 'shared/components/presentational/Picture/constants';
import { Content, FormattedProps } from './types';
import { EngagementUnit } from '../shared/types';
import { getLinkHref } from 'shared/helpers/uri';
import { isVideo } from './helpers/isVideo';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  template: content.banner.template,
  autoPlaySpeed: content.banner.autoPlaySpeed,
  products: content.products.map(product => ({
    productId: product.productId,
    title: product.title,
    description: product.description,
    promotionalSticker: product.promotionalSticker,
    bannerUrlByDevice: {
      desktop: {
        url: getLinkHref(product.media.desktopBanner.link || ''),
        alt: product.media.desktopBanner.description || '',
        w: BREAKPOINTS.DESKTOP + 1,
        isVideo: isVideo(product.media.desktopBanner.contentType)
      },
      tablet: {
        url: getLinkHref(
          product.media?.tabletBanner?.link || product.media.desktopBanner.link
        ),
        alt:
          product.media?.tabletBanner?.description ||
          product.media?.desktopBanner?.description ||
          '',
        w: BREAKPOINTS.DESKTOP,
        isVideo: isVideo(
          product.media.tabletBanner?.contentType ||
            product.media.desktopBanner.contentType
        )
      },
      mobile: {
        url: getLinkHref(
          product.media?.mobileBanner?.link ||
            product.media?.tabletBanner?.link ||
            product.media.desktopBanner.link
        ),
        alt:
          product.media?.mobileBanner?.description ||
          product.media?.tabletBanner?.description ||
          product.media?.desktopBanner?.description ||
          '',
        w: BREAKPOINTS.PHABLET,
        isVideo: isVideo(
          product.media.mobileBanner?.contentType ||
            product.media.tabletBanner?.contentType ||
            product.media.desktopBanner.contentType
        )
      },
      mobileMini: {
        url: getLinkHref(
          product.media?.mobileBanner?.link ||
            product.media?.tabletBanner?.link ||
            product.media.desktopBanner.link
        ),
        alt:
          product.media?.mobileBanner?.description ||
          product.media?.tabletBanner?.description ||
          product.media?.desktopBanner?.description ||
          '',
        w: BREAKPOINTS.PHONE,
        isVideo: isVideo(
          product.media.mobileBanner?.contentType ||
            product.media.tabletBanner?.contentType ||
            product.media.desktopBanner.contentType
        )
      }
    },
    primaryButton:
      product.primaryButton?.label && product.primaryButton?.link
        ? {
            label: product.primaryButton?.label,
            href: getLinkHrefWithAuthentication(product.primaryButton?.link)
          }
        : null,
    offerDetails: {
      availableCommitmentDurations: product.availableCommitmentDurations,
      commitmentConditions: product.commitmentConditions,
      engagementDuration: null,
      engagementUnit: EngagementUnit[product.engagementUnit],
      netPrice: product.netPrice,
      price: product.price,
      priceInformation: product.priceInformation,
      promoDuration: product.promoDuration
    }
  }))
});
