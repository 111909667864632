import get from 'lodash/get';

export default state => {
  const idString = get(
    /^#(\d*)/g.exec(get(state, 'router.location.hash')),
    '[1]',
    null
  );
  return idString && parseInt(idString, 10);
};
