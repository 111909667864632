import * as React from 'react';
import styled from 'styled-components';

import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import BaseWrapper from 'shared/components/presentational/Wrapper/Wrapper';
import RawTabs from 'shared/components/presentational/Tabs';
import ApashButton from 'shared/components/presentational/Button/ApashButton';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { FormattedProps, Offer, OfferList, Template } from '../types';
import Picture, {
  BaseWrapper as ImageBaseWrapper
} from 'shared/components/presentational/Picture';
import { Component as Video } from 'shared/blocks/video';
import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import Title from 'shared/components/presentational/Title';
import isVideoMedia from '../helper/isVideoMedia';

const Tabs = RawTabs as any;

const Layout: {
  [key in Template]: {
    width: number;
    height: number;
  };
} = {
  '1column': {
    width: 680,
    height: 380
  },
  '2columns': {
    width: 480,
    height: 250
  },
  '3columns': {
    width: 306,
    height: 176
  }
};

const Wrapper = styled(BaseWrapper)`
  margin: 40px 24px !important;
  width: initial;

  ${mediaQueries.fromTablet`
    margin: 48px 0;
  `};
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: ${pxInRem(24)};
`;

const TabsListWrapper = styled.div`
  margin: 0 0 32px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;

  ${mediaQueries.fromTablet`
    justify-content: center;
  `};

  > * {
    flex-grow: 0;
    white-space: nowrap;
  }
`;

const StyledTab = styled(Tabs.Tab)<{
  activeColor: string;
  defaultColor?: string;
}>`
  font-family: ${FONT_FAMILIES.brand};
  text-transform: none;
  display: block;
  color: ${props =>
    props.isActive ? props.activeColor : props.defaultColor ?? COLORS.black};
  border-bottom: ${props =>
    props.isActive
      ? `3px solid ${props.activeColor}`
      : '3px solid transparent'};
  font-weight: ${FONT_WEIGHTS.bold};
  padding: 0 15px 6px !important;
  font-size: ${pxInRem(16)};
  line-height: 1.2;
`;

const OfferWrapper = styled.div`
  margin-bottom: 24px;
  text-align: left;

  ${mediaQueries.fromTablet`
    margin-bottom: 32px;
  `};
`;

const OfferListWrapper = styled.div<{ maxWidth: number }>`
  ${mediaQueries.fromTablet`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `};

  > ${OfferWrapper} {
    flex: 1;
    margin-left: 20px;
    margin-right: 20px;
    max-width: ${props => props.maxWidth}px;
  }
`;

const MediaWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 185px;
  width: 100%;
  margin-bottom: 16px;
  overflow: hidden;

  ${mediaQueries.fromTablet<{ maxHeight: number }>`
    height: ${props => props.maxHeight}px;
  `};
`;
const CustomImageBaseWrapper = styled(ImageBaseWrapper)`
  height: 100%;
`;
const OfferImage = styled(Picture)`
  object-fit: contain;
  height: 100%;
  width: 100%;
`;

const OfferTitle = styled.h3`
  font-size: ${pxInRem(16)};
  margin: 0 0 24px;
  line-height: 1.2;
`;

const OfferContent = styled.div`
  margin: 0 0 16px;
  font-size: ${pxInRem(16)};
  line-height: 1.2;
`;

export const Link = styled(CustomLink)<{ color?: string }>`
  display: block;
  font-weight: bold;
  font-size: ${pxInRem(16)};
  line-height: 1.2;
  color: ${props => props.color ?? 'inherit'};
`;
const ContentTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${FONT_FAMILIES.base};
  }
`;

const CustomApashButton = styled(ApashButton)`
  max-width: 80%;
`;

const StyledVideo = styled.video`
  height: 100%;
`;

export default function EditoTabs({
  title,
  titleColor,
  titleHtmlTag,
  subtitle,
  tabs,
  tabDefaultColor,
  tabActiveColor,
  button
}: FormattedProps) {
  const renderOffer = React.useCallback(
    (offer: Offer, index, template: Template) => {
      const maxHeight = Layout[template].height ?? Layout['3columns'].height;
      return (
        <OfferWrapper key={index}>
          {offer.media ? (
            <MediaWrapper maxHeight={maxHeight}>
              {isVideoMedia(offer.media.url) ? (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <StyledVideo controls>
                  <source src={offer.media.url} type="video/mp4" />
                </StyledVideo>
              ) : (
                <OfferImage
                  url={offer.media.url}
                  alt={offer.media.alt}
                  customWrapper={CustomImageBaseWrapper}
                />
              )}
            </MediaWrapper>
          ) : null}
          {offer.dailymotionVideoId ? (
            <MediaWrapper maxHeight={maxHeight}>
              <Video id={offer.dailymotionVideoId} />
            </MediaWrapper>
          ) : null}
          <OfferTitle>{offer.title}</OfferTitle>
          <OfferContent dangerouslySetInnerHTML={{ __html: offer.text }} />
        </OfferWrapper>
      );
    },
    []
  );

  const renderOfferList = React.useCallback((offerList: OfferList, index) => {
    const maxWidth =
      Layout[offerList.template].width ?? Layout['3columns'].width;

    return (
      <Tabs.Panel key={index}>
        <ContentTabWrapper>
          <OfferListWrapper maxWidth={maxWidth}>
            {offerList.columns.map((offer, index) =>
              renderOffer(offer, index, offerList.template)
            )}
          </OfferListWrapper>

          {button?.label ? (
            <CustomApashButton theme={button.theme} to={button.link}>
              <ApashButton.children.Text>
                {button.label}
              </ApashButton.children.Text>
            </CustomApashButton>
          ) : null}
        </ContentTabWrapper>
      </Tabs.Panel>
    );
  }, []);

  const renderTab = React.useCallback((offerList, index) => {
    return (
      <StyledTab
        key={index}
        activeColor={tabActiveColor}
        defaultColor={tabDefaultColor}
      >
        {offerList.title}
      </StyledTab>
    );
  }, []);

  return (
    <Wrapper data-testid="edito-tabs">
      <Header>
        <Title
          titleLabel={title}
          subtitle={subtitle}
          titleColor={titleColor}
          titleHtmlTag={titleHtmlTag}
        />
      </Header>
      <Tabs>
        <Tabs.List customWrapper={TabsListWrapper}>
          {tabs.map(renderTab)}
        </Tabs.List>

        <Tabs.Panels>{tabs.map(renderOfferList)}</Tabs.Panels>
      </Tabs>
    </Wrapper>
  );
}
