export default (_hexColor: string) => {
  if (!_hexColor) return false;

  let hexColor = _hexColor;

  if (hexColor.length === 4) {
    hexColor = [0, 1, 1, 2, 2, 3, 3].reduce(
      (acc, curr) => acc + _hexColor[curr],
      ''
    );
  }

  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq <= 128;
};
