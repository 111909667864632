import React from 'react';

import {
  SlideWrapper,
  SlideHeading,
  SlideThumbnail
} from './styles/components';
import { SlideSubtitle } from 'shared/blocks/mea/shared/styles/components';
import {
  TWO_SLIDES_THUMBNAIL_HEIGHT_DESKTOP,
  TITLE_POSITION
} from '../styles/constants';
import BaseLink from 'shared/components/presentational/CustomLink/CustomLink';
import { COLORS } from 'shared/constants/theme';
import { Title } from '../styles/components';

export interface Props {
  className?: string;
  title?: string | null;
  subtitle?: string | null;
  imageUrl?: string;
  alt?: string;
  externalImageUrl?: string;
  href?: string;
  disableThumbnailLazyLoad?: boolean;
}

export default function Slide({
  title,
  subtitle,
  imageUrl,
  alt,
  externalImageUrl,
  href,
  className,
  disableThumbnailLazyLoad,
  ...props
}: Props) {
  return (
    <SlideWrapper
      className={className}
      as={href ? BaseLink : SlideWrapper}
      to={href}
      {...props}
    >
      <SlideThumbnail
        alt={alt}
        url={imageUrl}
        externalUrl={externalImageUrl}
        height={TWO_SLIDES_THUMBNAIL_HEIGHT_DESKTOP}
        placeholderColor={COLORS.concrete}
        disableLazy={disableThumbnailLazyLoad}
      />
      {(title || subtitle) && (
        <SlideHeading
          style={{ '--title-position': TITLE_POSITION } as React.CSSProperties}
        >
          {title && <Title>{title}</Title>}
          {subtitle && <SlideSubtitle>{subtitle}</SlideSubtitle>}
        </SlideHeading>
      )}
    </SlideWrapper>
  );
}
