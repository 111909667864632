import React from 'react';
import styled from 'styled-components';

import LegalNotice from 'shared/components/presentational/LegalNotice';
import Button from 'shared/components/presentational/Button';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { COLORS } from 'shared/constants/theme';
import PresentationContent from 'shared/blocks/presentation/components/PresentationContent';
import AuthDisplayHandler from 'shared/components/presentational/AuthDisplayHandler/AuthDisplayHandler';
import { FormattedProps } from 'shared/blocks/presentation/types';
import Title from 'shared/components/presentational/Title';

const Section = styled.section<{ backgroundColor?: string }>`
  background-color: ${props => props.backgroundColor || COLORS.white};
  margin: 0 !important;
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 15px 50px 14px;
  ${mediaQueries.toTablet`
    max-width: 100%;
  `};
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 30px 0;
`;

const StyledButton = styled(Button)`
  ${mediaQueries.toPhoneMini`
    min-width: auto;
    width: 100%;
  `};
`;

const Presentation = ({
  template,
  backgroundColor,
  title,
  titleColor,
  titleHtmlTag,
  text,
  imageURL,
  alt,
  legalNotices,
  buttonText,
  buttonUrl,
  theme,
  authenticated
}: FormattedProps) => (
  <AuthDisplayHandler
    requestLegacyAuth={authenticated && authenticated.legacy}
    requestAwakenAuth={authenticated && authenticated.awaken}
    height={300}
  >
    <Section backgroundColor={backgroundColor}>
      <Container>
        <Title
          titleLabel={title}
          titleColor={titleColor}
          titleHtmlTag={titleHtmlTag}
        />

        <PresentationContent
          template={template}
          imageURL={imageURL}
          alt={alt}
          text={text}
        />
        {buttonText && (
          <ButtonWrapper>
            <StyledButton to={buttonUrl} title={title} theme={theme}>
              <Button.children.Text>{buttonText}</Button.children.Text>
            </StyledButton>
          </ButtonWrapper>
        )}
        {(legalNotices?.title || legalNotices?.notice) && (
          <LegalNotice
            title={legalNotices.title}
            notice={legalNotices.notice}
          />
        )}
      </Container>
    </Section>
  </AuthDisplayHandler>
);

export default Presentation;
