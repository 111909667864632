import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 110 87" width={width} height={height} className={className}>
    <path
      d="M40.9 62.4l31.2-19.1-31.2-19v38.1zM6.3 86.6h96.8c4.2 0 6.2-2 6.2-6.2V7c0-4.2-2-6.2-6.2-6.2H6.3C2.1.8.1 2.8.1 7v73.4c-.1 4.2 2 6.2 6.2 6.2zm1.5-7.8V71h7.8v7.8H7.8zm0-15.6v-7.8h7.8v7.8H7.8zm0-15.6v-7.8h7.8v7.8H7.8zm0-15.6v-7.8h7.8V32H7.8zm0-15.6V8.6h7.8v7.8H7.8zm15.7 62.4V8.6H86v70.3H23.5v-.1zm70.2 0V71h7.8v7.8h-7.8zm0-15.6v-7.8h7.8v7.8h-7.8zm0-15.6v-7.8h7.8v7.8h-7.8zm0-15.6v-7.8h7.8V32h-7.8zm0-15.6V8.6h7.8v7.8h-7.8z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
