import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 38 38" width={width} height={height} className={className}>
    <path
      d="M33.1.6H5C2.7.6.8 2.5.8 4.8v18.3c0 2.3 1.9 4.2 4.2 4.2h28c2.3 0 4.2-1.9 4.2-4.2V4.8c.1-2.3-1.8-4.2-4.1-4.2zM5 3h28c.2 0 .4 0 .5.1L19 14.3 4.5 3.1c.2-.1.3-.1.5-.1zm29.9 20.1c0 1-.8 1.8-1.8 1.8H5c-1 0-1.8-.8-1.8-1.8v-18L19 17.3 34.9 5.2v17.9z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
