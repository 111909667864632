/* eslint-disable no-irregular-whitespace */
/**
 * getDayIndex
 * @param day : The day string to parse - eg lundi-1 | vendredi-5
 * @returns {int}
 */

const DEFAULT_INDEX = 0;

export default day => {
  if (day && typeof day === 'string') {
    const data = day.split('-');
    if (data.length > 1) {
      return parseInt(data[1], 10) || DEFAULT_INDEX;
    }
  }

  return DEFAULT_INDEX;
};
