import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import fetchMoreVodIfNeeded from 'shared/blocks/vod/hodor/actions/fetchMoreVodIfNeeded';
import RenderMoreButton from 'shared/blocks/vod/hodor/components/RenderMoreButton';

const mapDispatchToProps = {
  fetchMore: fetchMoreVodIfNeeded
};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation()
  // @ts-ignore
)(RenderMoreButton);
