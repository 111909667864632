import React from 'react';

import { Label, Wrapper } from './styles/components';
import Picture from 'shared/components/presentational/Picture';

export const Badge = ({
  label,
  icon
}: {
  label: string | null;
  icon: string;
}) => (
  <Wrapper>
    <Picture url={icon} />
    <Label> {label}</Label>
  </Wrapper>
);
