import styled from 'styled-components';

// Global
import { customMediaQuery } from 'shared/helpers/styled-components/mediaQueries';
import BaseButton from 'shared/components/presentational/Button';
// Local
import {
  INNER_MODAL_WRAPPER_WIDTH,
  MIN_RELATIVE_OUTER_PADDING,
  MODAL_WITH_MIN_OUTER_PADDING_BREAKPOINT
} from './constants';

export const OuterWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  padding: calc(50vw - ${INNER_MODAL_WRAPPER_WIDTH / 2}px);
  padding-top: 75px;
  padding-bottom: 25vh;
  ${customMediaQuery(null, `${MODAL_WITH_MIN_OUTER_PADDING_BREAKPOINT}px`)`
    padding-left: ${MIN_RELATIVE_OUTER_PADDING}vw;
    padding-right: ${MIN_RELATIVE_OUTER_PADDING}vw;
  `}
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: 15px;
  right: 20px;
`;

export const CloseButton = styled(BaseButton)`
  padding: 10px;
`;

export const InnerWrapper = styled.div`
  width: 100%;
`;
