import { Content, FormattedProps } from './types';
import { EngagementUnit } from '../shared/types';
import { PropsFormatterUtils } from 'shared/types';
import getLinkHref from 'shared/helpers/uri/getLinkHref';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: content.title.toUpperCase(),
  offerLabel: content.propals[0]?.offerLabel?.toUpperCase() ?? '',
  description: content.description,
  propals: content.propals.map(propal => ({
    featuresTitle: propal.featuresTitle,
    propalDetailsButtonText: propal.propalDetailsButton,
    image: propal.image
      ? {
          link: getLinkHref(propal?.image?.link),
          alt: propal?.image?.description || propal?.image?.filename
        }
      : null,
    product: {
      productId: propal.defaultPropal.mainProduct.productId,
      title: propal.title,
      description: propal.definition,
      promotionalSticker: propal.marketingPromise,
      offerDetails: {
        availableCommitmentDurations: '',
        commitmentConditions: propal.defaultPropal.commitmentConditions,
        engagementDuration: null,
        engagementUnit: EngagementUnit[propal.defaultPropal.engagementUnit],
        netPrice: propal.defaultPropal.netPrice,
        price: propal.defaultPropal.price,
        promoDuration: propal.defaultPropal.promoDuration
      }
    },
    minorProductsIds: propal.defaultPropal.minorProducts
      .reduce((acc: string[], { productId, sendToCRM }) => {
        if (sendToCRM) {
          acc.push(productId);
        }
        return acc;
      }, [])
      .join(','),
    modal: propal.propalDetailsPopin
      ? {
          title: propal.propalDetailsPopin.propalDetails.title,
          product: {
            productId:
              propal.propalDetailsPopin.propalDetails.mainProduct.productId,
            title: propal.propalDetailsPopin.propalDetails.totalRecapText,
            promotionalSticker: null,
            offerDetails: {
              availableCommitmentDurations: '',
              commitmentConditions:
                propal.propalDetailsPopin.propalDetails.commitmentConditions,
              engagementDuration: null,
              engagementUnit: propal.propalDetailsPopin.propalDetails
                .engagementUnit
                ? EngagementUnit[
                    propal.propalDetailsPopin.propalDetails.engagementUnit
                  ]
                : null,
              netPrice: propal.propalDetailsPopin.propalDetails.netPrice,
              price: propal.propalDetailsPopin.propalDetails.price,
              promoDuration:
                propal.propalDetailsPopin.propalDetails.promoDuration
            }
          },
          minorProducts: propal.propalDetailsPopin.propalDetails.minorProducts.map(
            minorProduct => ({
              productId: minorProduct.productId,
              title: minorProduct.title,
              description: minorProduct.description,
              sendToCRM: minorProduct.sendToCRM,
              offerDetails: {
                price: minorProduct.price,
                promoDuration: '',
                engagementUnit: EngagementUnit[minorProduct.engagementUnit],
                commitmentConditions: minorProduct.commitmentConditions
              },
              channels: minorProduct.channels.map(channel => ({
                title: channel.title,
                description: channel.description,
                link: getLinkHref(channel.link ?? ''),
                icon: channel.media && {
                  link: getLinkHref(channel.media.link),
                  alt: channel.media.description || channel.media.filename
                }
              }))
            })
          ),
          minorProductsIds: propal.propalDetailsPopin.propalDetails.minorProducts
            .reduce((acc: string[], { productId, sendToCRM }) => {
              if (sendToCRM) {
                acc.push(productId);
              }
              return acc;
            }, [])
            .join(','),
          confirmButton:
            propal.propalDetailsPopin.confirmButton?.label &&
            propal.propalDetailsPopin.confirmButton?.link
              ? {
                  label: propal.propalDetailsPopin.confirmButton.label,
                  link: getLinkHrefWithAuthentication(
                    propal.propalDetailsPopin.confirmButton.link
                  )
                }
              : undefined,
          cancelButton: propal.propalDetailsPopin.cancelButton
        }
      : null,
    features: propal.features
      ? propal.features.map(feature => ({
          title: feature.title,
          icon: {
            media: feature.icon.media
              ? {
                  link: getLinkHref(feature.icon.media?.link),
                  alt:
                    feature.icon.media?.description ||
                    feature.icon.media?.filename
                }
              : undefined
          },
          logos: feature.logos
            ? feature.logos.map(logo => ({
                link: getLinkHref(logo?.link),
                alt: logo?.description || logo?.filename
              }))
            : undefined
        }))
      : null,
    gifts: propal.gifts
      ? propal.gifts.map(gift => ({
          icon: gift.media && {
            link: getLinkHref(gift?.media?.link),
            alt: gift.media.description || gift.media.filename
          },
          text: gift.text
        }))
      : null
  })),
  previousButton:
    content.previousButton?.label && content.previousButton?.link
      ? {
          label: content.previousButton.label,
          link: getLinkHrefWithAuthentication(content.previousButton.link)
        }
      : undefined,
  nextButton:
    content.nextButton?.label && content.nextButton?.link
      ? {
          label: content.nextButton.label,
          link: getLinkHrefWithAuthentication(content.nextButton.link)
        }
      : undefined
});
