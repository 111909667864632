/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import { Content, FormattedProps } from './types';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { COLORS } from 'shared/constants/theme';

export default (content: Content): FormattedProps => ({
  button: {
    label: get(content.button, 'label'),
    theme: buttonThemeFactory({
      color: get(content.button, 'labelColor', COLORS.white),
      backgroundColor: get(content.button, 'backgroundColor', COLORS.amaranth)
    })
  }
});
