import styled, { css } from 'styled-components';

// Global
import {
  FONT_FAMILIES,
  COLORS,
  DIMENSIONS,
  FONT_WEIGHTS
} from 'shared/constants/theme';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import Picture from 'shared/components/presentational/Picture';
import BaseContainer from 'shared/components/presentational/Container/Container';
// Shared
import { removeInnerContentVerticalMargin } from '../../shared/styles/mixins';
import {
  Wrapper as SharedWrapper,
  Button as SharedButton
} from '../../shared/styles/components';
// Local
import {
  BANNER_HEIGHT,
  BANNER_PHABLET_HEIGHT,
  BANNER_PHONE_HEIGHT,
  BOTTOM_BANNER_PADDING,
  TOP_BANNER_PADDING,
  TWO_IMAGES_BANNER_HEIGHT,
  TWO_IMAGES_BANNER_HEIGHT_PHONE
} from './constants';
import { BannerTemplate, BannerTemplateLayout } from '../types';
import {
  CustomSubtitle,
  CustomTitle
} from 'shared/components/presentational/Title/sharedTitles';
const { LONG_VERTICAL_MARGIN, MEDIUM_VERTICAL_MARGIN } = DIMENSIONS;
import BaseLink from 'shared/components/presentational/CustomLink/CustomLink';
import Button from 'shared/components/presentational/Button';

export const BannerPrice = styled.div<{ color?: string }>`
  font-family: ${FONT_FAMILIES.brand};
  display: flex;
  margin-top: 10px;
  text-transform: uppercase;
  color: ${props => props.color ?? COLORS.black};
  align-items: center;
  p:nth-child(1) {
    font-size: ${pxInRem(56)};
    margin: 0px;
    font-weight: ${FONT_WEIGHTS.bold};
  }
`;

export const BANNHER_HEIGHT = {
  MOBILE_TEMPLATE_SMALL: 140,
  DESKTOP_TEMPLATE_SMALL: 341
};

export const PriceDetails = styled.div`
  display: flex;
  flex-direction: column;

  p:nth-child(1) {
    font-size: ${pxInRem(24)};
    font-weight: ${FONT_WEIGHTS.bold};
    margin: 0px;
  }
`;

export const BannerDescription = styled.p<{
  color?: string;
  template?: string;
}>`
  font-family: ${FONT_FAMILIES.base};
  font-size: ${pxInRem(16)};
  margin: ${props =>
    props.template === BannerTemplate.TwoImages ? '8px 0 0' : '10px 0 0'};
  color: ${props => props.color ?? COLORS.black};
`;

/**
 * Banner title
 */
export const BannerTitle = styled(CustomTitle)`
  text-align: inherit;
  font-family: ${FONT_FAMILIES.base};
`;

/**
 * Banner subtitle
 */
export const BannerSubtitle = styled(CustomSubtitle)`
  font-size: ${pxInRem(16)};
  text-align: inherit;
`;

export const TwoImagesBannerSubtitle = styled.h3`
  margin-top: 16px;
  margin-bottom: 0;
  font-size: ${pxInRem(20)} !important;
  color: ${props => props.color ?? 'inherit'};
  text-align: inherit;
`;

/**
 * Banner heading
 */
export const BannerHeading = styled.div`
  position: relative;
  ${mediaQueries.fromTablet<{ template: string }>`
max-width: ${props =>
    props.template === BannerTemplate.TwoImages ? '360px' : '600px'};
  `};
  margin-bottom: ${props =>
    props.template === BannerTemplate.TwoImages ? '8px' : '0'};
  ${mediaQueries.toPhone<{ template: string }>`
margin-top: ${props => props.template === BannerTemplate.TwoImages && '25px'};
  `};
`;

/**
 * Banner image
 */
export const BannerImage = styled(Picture).attrs({ disableLazy: true })`
  position: absolute;
  min-width: 100%;
  max-width: unset;
  height: 100%;
  left: 50%;
  object-fit: cover;
  top: 50%;
  transform: translate(-50%, -50%);

  ${mediaQueries.toPhablet<{ template?: string }>`
      height: ${props => props.template === BannerTemplate.TwoImages && 260}px ;
      top: ${props => props.template === BannerTemplate.TwoImages && '27%'} ;
      object-fit: ${props =>
        props.template === BannerTemplate.TwoImages && 'contain'} ;
    `}
`;

export const SecondaryBannerImage = styled(Picture).attrs({
  disableLazy: true
})`
  margin-top: -220px;
  ${mediaQueries.toPhablet`
  margin-top: -100px !important;
  width: 100%;
  height: 100%;
  object-fit: contain;
`};
`;

/**
 * Banner (wrapper)
 */

export const SecondaryBanner = styled.div`
  position: relative;
  text-align: center;
  padding: 0;
`;
interface BannerProps {
  to: string;
  template: BannerTemplateLayout;
  hasButton: boolean;
}

export const Banner = styled(BaseContainer)<BannerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${props =>
    props.template === BannerTemplate.TwoImages
      ? TWO_IMAGES_BANNER_HEIGHT
      : BANNER_HEIGHT}px;
  margin-bottom: ${LONG_VERTICAL_MARGIN}px;
  overflow: hidden;
  color: ${COLORS.white};
  padding-top: ${props =>
    props.template === BannerTemplate.TwoImages ? 0 : TOP_BANNER_PADDING}px;
  padding-bottom: ${BOTTOM_BANNER_PADDING}px;

  ${mediaQueries.toPhablet<BannerProps>`
 padding-top:${props =>
   props.template === BannerTemplate.TwoImages ? 0 : TOP_BANNER_PADDING}px;
background-color: ${props =>
    props.template === BannerTemplate.TwoImages ? COLORS.black : 'transparent'};

    padding-bottom: ${BOTTOM_BANNER_PADDING}px;
    margin: ${MEDIUM_VERTICAL_MARGIN}px 0;
    height:  ${props =>
      props.template === BannerTemplate.TwoImages
        ? TWO_IMAGES_BANNER_HEIGHT_PHONE
        : BANNER_PHABLET_HEIGHT}px  ;
  `};

  ${mediaQueries.toPhone<BannerProps>`
    height: ${props =>
      props.template === BannerTemplate.TwoImages
        ? TWO_IMAGES_BANNER_HEIGHT_PHONE
        : BANNER_PHONE_HEIGHT}px  ;
  `};

  /* Banner items layout */
  ${removeInnerContentVerticalMargin(BannerHeading)}

  ${SharedButton} {
    flex-shrink: 0;
    margin: 0;
    margin-top: ${MEDIUM_VERTICAL_MARGIN}px;
  }

  /* Template variations */
  ${props =>
    props.template === BannerTemplate.Center &&
    css<BannerProps>`
      text-align: center;
      align-items: center;
      justify-content: center;
      ${BannerSubtitle} {
        margin: 8px auto 0;
      }

      /* When banner contain a button, position it at the bottom while keeping heading centered */
      ${props =>
        props.hasButton &&
        mediaQueries.fromTablet`
      &::before {
        content: '';
      }
      justify-content: space-between;
    `}
    `}

  ${props =>
    props.template === BannerTemplate.Top &&
    `
    ${BannerSubtitle} {
      margin: 8px auto 0;
    }
    text-align: center;
    align-items: center;
    justify-content: flex-start;
  `}

  ${props =>
    props.template === BannerTemplate.Left &&
    css`
      text-align: center;
      align-items: center;
      justify-content: center;

      ${BannerSubtitle} {
        margin: 8px auto 0;
      }

      ${mediaQueries.fromTablet`
      ${BannerSubtitle} {
        margin-left: 0;
      }
      text-align: left;
      align-items: flex-start;
    `}
    `}

  ${props =>
    props.template === BannerTemplate.Right &&
    css`
      text-align: center;
      align-items: center;
      justify-content: center;
      ${BannerSubtitle} {
        margin: 8px auto 0;
      }
      ${mediaQueries.fromTablet`
      ${BannerSubtitle} {
        margin-right: 0;
      }
      text-align: right;
      align-items: flex-end;
    `}
    `}

    ${props =>
    props.template === BannerTemplate.TwoImages &&
    css`
      text-align: left;
      align-items: flex-start;
      justify-content: center;
      ${mediaQueries.toPhablet`
      & > picture::after {
        position: absolute;
        content: '';
        height: 260px;
        width: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 50%), linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 50%);
      };
      `};
      ${mediaQueries.fromTablet`
      ${BannerSubtitle} {
        margin-left: 0;
      }
      text-align: left;
      align-items: flex-start;
    `}
    `};
  /* Hide banner button on phone for alignTop and alignCenter templates */
  ${props =>
    (props.template === BannerTemplate.Center ||
      props.template === BannerTemplate.Top) &&
    css`
      ${mediaQueries.toPhablet`
    ${SharedButton} {
      display: none;
    }
    `}
    `}
`;

/**
 * Heading
 */
export const HeadingContainer = styled(BaseContainer)`
  margin-top: 55px;
`;

/**
 * Wrapper
 */
export const Wrapper = styled(SharedWrapper)`
  margin-top: 0 !important;
  ${Banner}:first-child {
    margin-top: 0;
  }
`;

// --------  HERO BANNER ANIMATION --------

export const BlockWrapper = styled.section`
  background-color: var(--surface-dark);
`;

export const Container = styled.section`
  padding: 0 0 var(--spacing-m) 0;

  ${mediaQueries.fromPhablet`
    padding: unset;
  `}
`;

export const SliderContainer = styled.div`
  // important is needed because shared/assets/css/slick/slick.css uses display: block
  display: flex !important;
  flex-direction: column-reverse;
  justify-content: space-between;

  ${mediaQueries.fromTablet`
    display: grid !important;
    grid-template-columns: 1fr 2fr;
    padding: var(--spacing-xl) 0;
  `}

  ${mediaQueries.fromHd`
    padding: 0;
  `}
`;

export const ContentWrapper = styled.div`
  grid-column: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 var(--spacing-m);
  gap: var(--spacing-xl);

  ${mediaQueries.fromHd`
      padding: 0 60px;
  `}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;

export const Description = styled.p`
  font-size: var(--text-size-base);
  color: #b2b2b2;
  line-height: var(--line-height-m);
  margin: 0;
`;

export const StyledLink = styled(BaseLink)`
  font-size: var(--text-size-base);
  font-weight: ${FONT_WEIGHTS.demi};
  color: ${COLORS.amaranth};
  text-decoration: none;

  &:hover {
    color: var(--primary-hover);
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${COLORS.amaranth};
  border: none;
  border-radius: var(--border-radius-xs);
  height: var(--spacing-xxl);
  width: 100%;
  min-width: 189px;
  padding: var(--spacing-xs) var(--spacing-m);
  font-size: var(--text-size-l);

  ${mediaQueries.fromDesktop`
      width: fit-content;
      min-width: 214px;
      height: var(--spacing-xxl);
  `}

  &:hover {
    background-color: var(--primary-hover);
  }
`;

export const PictureWrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

export const PictureShadow = styled.div`
  background: linear-gradient(180deg, transparent 0, #000);
  bottom: 0;
  height: 100px;
  position: absolute;
  width: 100%;
  z-index: 1;

  ${mediaQueries.fromTablet`
      background: linear-gradient(270deg, transparent 0, #000);
      bottom: auto;
      height: 100%;
      top: 0;
      width: 100px;
  `}
`;

export const Image = styled(Picture).attrs({})<{
  template: 'animationBig' | 'animationSmall';
}>`
  display: initial !important;
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: ${({ template }) =>
    template === BannerTemplate.AnimationSmall
      ? `${BANNHER_HEIGHT.MOBILE_TEMPLATE_SMALL}px`
      : 'unset'};

  ${mediaQueries.fromTablet<{
    template: 'animationBig' | 'animationSmall';
  }>`
    max-height: ${({ template }) =>
      template === BannerTemplate.AnimationSmall
        ? `${BANNHER_HEIGHT.DESKTOP_TEMPLATE_SMALL}px`
        : 'unset'};
  `}
`;
