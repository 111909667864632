/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import loadable from '@loadable/component';

export const namespace = 'canal.block.pre_registration.update_password';
export const Component = loadable(
  () => import('./containers/UpdatePasswordContainer')
);
export { default as propsFormatter } from './propsFormatter';
