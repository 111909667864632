import React from 'react';
import styled, { css } from 'styled-components';
import Scrollspy from 'react-scrollspy';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import {
  HEADER_DESKTOP_HEIGHT,
  HEADER_MOBILE_HEIGHT,
  HEADER_UNIFIED_MOBILE_HEIGHT,
  HEADER_UNIFIED_DESKTOP_HEIGHT
} from 'shared/constants/heights';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

// number of pixels between the header and the menu
export const MENU_OFFSET = 80;

const List = styled.div`
  width: 88%;
  ${props =>
    props.position === 'fixed' &&
    css`
      position: fixed;
      top: ${MENU_OFFSET + props.hasUnifiedMenu
        ? HEADER_UNIFIED_MOBILE_HEIGHT
        : HEADER_MOBILE_HEIGHT}px; // offset summed with header height

      width: 12%;
      ${mediaQueries.fromDesktop`
      top: ${
        MENU_OFFSET + props.hasUnifiedMenu
          ? HEADER_UNIFIED_DESKTOP_HEIGHT
          : HEADER_DESKTOP_HEIGHT
      }px; // offset summed with header height (from desktop)
    `};
    `} ${props =>
    props.position === 'bottom' &&
    `
    position: absolute;
    bottom: 0;
  `} & ul > .current {
    color: ${COLORS.cerulean};
    font-family: ${FONT_FAMILIES.base};
    font-weight: ${FONT_WEIGHTS.bold};
  }
`;

const Item = styled.li`
  margin-bottom: 14px;
  font-family: ${FONT_FAMILIES.base};
  cursor: pointer;
`;

export default React.forwardRef((props, ref) => {
  const { items, onClick, position, hasUnifiedMenu } = props;

  return (
    <List position={position} ref={ref} hasUnifiedMenu={hasUnifiedMenu}>
      <Scrollspy
        items={items}
        currentClassName="current"
        style={{ width: '85%' }}
        offset={-130}
      >
        {items.map(item => (
          <Item key={item} onClick={() => onClick(item)}>
            {item}
          </Item>
        ))}
      </Scrollspy>
    </List>
  );
});
