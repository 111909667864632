import React from 'react';
import styled from 'styled-components';

import BaseBanner from 'shared/components/presentational/Banner/Banner';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Link from 'shared/components/presentational/Link';
import MEAInfos from './MeaInfos';
import { FormattedProps } from '../types';
import { TEMPLATES } from '../constants';

const Wrapper = styled.section`
  position: relative;
  max-width: 1000px;
  margin: auto;
  ${mediaQueries.toTablet`
    margin-left: 15px;
    margin-right: 15px;
  `};
  ${mediaQueries.toPhablet`
    margin-left: 0;
    margin-right: 0;
  `};
`;

export default function Banner({
  template,
  meaTitle,
  meaTitleColor,
  meaTitleHtmlTag,
  meaSubtitle,
  meaUrl,
  banner
}: FormattedProps) {
  return (
    <Wrapper>
      <BaseBanner {...banner}>
        {template === TEMPLATES.MEA &&
          (meaUrl ? (
            <Link to={meaUrl}>
              <MEAInfos
                title={meaTitle}
                titleHtmlTag={meaTitleHtmlTag}
                titleColor={meaTitleColor}
                subtitle={meaSubtitle}
              />
            </Link>
          ) : (
            <MEAInfos
              title={meaTitle}
              titleHtmlTag={meaTitleHtmlTag}
              titleColor={meaTitleColor}
              subtitle={meaSubtitle}
            />
          ))}
      </BaseBanner>
    </Wrapper>
  );
}
