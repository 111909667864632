import styled from 'styled-components';

import { HEADER_UNIFIED_DESKTOP_OVERLAY_TOP_POSITION } from 'shared/constants/heights';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { mediaQueries } from 'shared/helpers/styled-components/mediaQueries';

export const DropdownStyled = styled.div`
  width: ${pxInRem(32)};
  height: ${pxInRem(HEADER_UNIFIED_DESKTOP_OVERLAY_TOP_POSITION)};

  button {
    background: transparent;
    cursor: pointer;
    display: inline-block;
    font: inherit;
    padding: 0;
    text-decoration: none;
    vertical-align: middle;
    border: none;
    outline: red;
  }
  ${mediaQueries.fromDesktop`
    z-index: 6;
  `};
`;
