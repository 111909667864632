import get from 'lodash/get';

export default (payload: {
  availableZones: { bigram: string; name: string }[];
  zone: { continent: string; country_code: string; ip: string };
}) => {
  const availableZones = get(payload, 'availableZones', []);
  const countryCode = get(payload, 'zone.country_code', '').toLowerCase();

  if (availableZones.length && countryCode) {
    return availableZones.find(zone => zone.bigram === countryCode) ?? null;
  }

  return null;
};
