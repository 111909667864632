/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import { Content, FormattedProps } from './types';
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { PropsFormatterUtils } from 'shared/types';
import { DAILYMOTION_API_BASE } from 'shared/constants/api';

export default function propsFormatter(
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps {
  const playlistId = get(content, 'playlistId');

  return {
    title: get(content, 'title.label'),
    titleColor: get(content, 'title.color'),
    titleHtmlTag: get(content, 'title.htmlTag'),
    subtitle: content.subtitle ?? undefined,
    template: get(content, 'template'),
    redirectButtonLabel: get(content, 'buttons.redirectButton.label', ''),
    redirectButtonTheme: themeFactory({
      backgroundColor: get(content, 'buttons.redirectButton.backgroundColor'),
      color: get(content, 'buttons.redirectButton.labelColor')
    }),
    redirectButtonLink: getLinkHrefWithAuthentication(
      get(content, 'buttons.redirectButton.link', '')
    ),
    playlistId,
    playlistUrl: playlistId
      ? `${DAILYMOTION_API_BASE}/playlist/${playlistId}/videos?fields=thumbnail_480_url%2Ctitle%2Cduration%2Cid`
      : undefined
  };
}
