import * as yup from 'yup';
import { setLocale } from 'yup/lib/customLocale';

import YUP_LOCALE from 'shared/constants/yup';
import {
  isNumber,
  password,
  equalTo,
  captcha,
  isPhoneNumber,
  isIban,
  forbiddenCharacters
} from './';

export default () => {
  setLocale(YUP_LOCALE);
  yup.addMethod(yup.string, 'equalTo', equalTo);
  yup.addMethod(yup.string, 'isNumber', isNumber);
  yup.addMethod(yup.string, 'password', password);
  yup.addMethod(yup.string, 'isPhoneNumber', isPhoneNumber);
  yup.addMethod(yup.string, 'isIban', isIban);
  yup.addMethod(yup.string, 'forbiddenCharacters', forbiddenCharacters);
  yup.addMethod(yup.string, 'captcha', captcha);
};
