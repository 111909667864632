/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import { Content, Item, Props } from './types';
import { PropsFormatterUtils } from 'shared/types';

const handleTilesLinks = (
  items: Item[],
  getLinkHrefWithAuthentication: PropsFormatterUtils['getLinkHrefWithAuthentication']
) =>
  items.map((item: Item) => ({
    html: item.text,
    // @ts-ignore : inference doesn't work here on lodash/get
    linkUrl: getLinkHrefWithAuthentication(get(item, 'link', '')),
    linkLabel: item.label,
    pictureUrl: getLinkHrefWithAuthentication(get(item, 'pictogram.link', '')),
    pictureWidth: 100,
    pictureWrapperWidth: 100,
    pictureWrapperHeight: 100
  }));

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): Props => ({
  buttonColor: content.labelColor,
  backgroundColor: content.backgroundColor,
  tiles: handleTilesLinks(
    get(content, 'items', []),
    getLinkHrefWithAuthentication
  )
});
