/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';
import styled from 'styled-components';

import Picture from 'shared/components/presentational/Picture';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Raw from 'shared/components/presentational/Raw/Raw';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import Title from 'shared/components/presentational/Title';
import { FormattedProps } from '../types';
import pxToRem from 'shared/helpers/styled-components/remHelper';

/*
 * Main wrapper should be a section
 * in order to inherit margins
 */
const Wrapper = styled.section`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  ${mediaQueries.toTablet`
    margin-left: 10px;
    margin-right: 10px;
  `};
`;

const PresentationsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

const Presentation = styled.div`
  position: relative;
  margin: 5px;
  flex-basis: calc(50% - 10px);
  ${mediaQueries.toPhablet`
    flex-basis: calc(100% - 10px);
  `};
`;

const PresentationImage = styled(Picture).attrs({
  height: 276
})`
  object-fit: cover;
  height: 276px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 15px;
  ${mediaQueries.toTablet`
    height: 204px;
  `} ${mediaQueries.toPhablet`
    margin-bottom: 5px;
    height: 163px;
  `};
`;

const PresentationTitle = styled.h4`
  font-family: ${FONT_FAMILIES.base};
  font-weight: ${FONT_WEIGHTS.normal};
  font-size: ${pxToRem(18)};
  color: ${props => props.color || COLORS.black};
  margin: 0 0 8px 0;
`;

const PresentationText = styled(Raw)`
  font-family: ${FONT_FAMILIES.base};
  line-height: 1.43rem;
`;

const PresentationsMPack = ({
  title,
  presentations,
  titleHtmlTag,
  titleColor
}: FormattedProps) => {
  const renderPresentation = React.useCallback((presentation, index) => {
    return (
      <Presentation key={`presentation-m-${index}`}>
        <PresentationImage
          url={presentation.imageUrl}
          alt={presentation.imageAlt}
        />
        <PresentationTitle color={presentation.titleColor}>
          {presentation.title}
        </PresentationTitle>
        <PresentationText html={presentation.text} />
      </Presentation>
    );
  }, []);

  return (
    <Wrapper>
      <Title
        titleLabel={title}
        titleHtmlTag={titleHtmlTag}
        titleColor={titleColor}
      />
      <PresentationsWrapper>
        {presentations.map(renderPresentation)}
      </PresentationsWrapper>
    </Wrapper>
  );
};

export default PresentationsMPack;
