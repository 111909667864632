/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { BREAKPOINTS } from 'shared/components/presentational/Picture/constants';
import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  ...content,
  broadcastingWay: content.broadcastingWay,
  saleDeviceId: content.saleDeviceId,
  pictures: {
    desktop: {
      url: content.mediaDesktop?.link?.href ?? undefined,
      w: BREAKPOINTS.DESKTOP + 1,
      alt: content.popins[0].mediaDesktop?.description ?? 'image banner'
    },
    phone: {
      url: content.mediaMobile?.link?.href ?? content.mediaDesktop?.link?.href,
      w: BREAKPOINTS.DESKTOP
    },
    phoneMini: {
      url: content.mediaMobile?.link?.href ?? content.mediaDesktop?.link?.href,
      w: BREAKPOINTS.PHONE
    }
  },
  title: {
    label: content.title?.label,
    color: content.title?.color,
    htmlTag: content.title?.htmlTag
  },
  subtitle: content.subtitle,
  content: content.content,
  buttonNext: {
    theme: themeFactory({
      backgroundColor: content.buttonNext.backgroundColor,
      borderColor: undefined,
      color: content.buttonNext.labelColor
    }),
    label: content.buttonNext.label
  },
  buttonBack: {
    theme: themeFactory({
      backgroundColor: content.buttonBack.backgroundColor,
      borderColor: undefined,
      color: content.buttonBack.labelColor
    }),
    label: content.buttonBack.label,
    link: getLinkHrefWithAuthentication(content.buttonBack.link)
  },
  popin: {
    template: content.popins[0].template,
    medias: {
      desktop: {
        url: content.popins[0].mediaDesktop?.link?.href ?? undefined,
        w: BREAKPOINTS.DESKTOP + 1
      },
      phone: {
        url: content.popins[0].mediaMobile?.link?.href ?? undefined,
        w: BREAKPOINTS.DESKTOP
      },
      phoneMini: {
        url: content.popins[0].mediaMobile?.link?.href ?? undefined,
        w: BREAKPOINTS.PHONE
      }
    },
    title: {
      label: content.popins[0].title?.label,
      color: content.popins[0].title?.color,
      htmlTag: content.popins[0].title?.htmlTag
    },
    text: content.popins[0].text,
    button: {
      theme: themeFactory({
        backgroundColor: content.popins[0].button.backgroundColor,
        borderColor: undefined,
        color: content.popins[0].button.labelColor
      }),
      label: content.popins[0].button.label,
      link: getLinkHrefWithAuthentication(content.popins[0].button.link)
    },
    backLink: {
      label: content.popins[0].backLink?.label,
      url: getLinkHrefWithAuthentication(content.popins[0].backLink?.link)
    }
  },
  apis: {
    getSubscriberData: content.apis.getSubscriberData,
    contract_renewal_cancelling: content.apis.contract_renewal_cancelling,
    revert_cancellation: content.apis['apash.api.revert_cancellation']
  }
});
