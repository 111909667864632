import styled from 'styled-components';
import { darken, lighten } from 'polished';

import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';
import Picture from 'shared/components/presentational/Picture';
import isDarkColor from 'shared/components/presentational/Button/helpers/isDarkColor';

const CustomPictureWrapper = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
`;

export const PictureStyled = styled(Picture).attrs({
  customWrapper: CustomPictureWrapper
})`
  margin-right: 0.6rem;
  width: 100%;
  heigth: 100%;
`;

export const LinkStyled = styled(CustomLink)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const LabelStyled = styled.span<{ color: string; theme: {} }>`
  flex-grow: 1;
  font-size: 16px;
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.linksColor};
  font-weight: ${({ theme }) => theme.linksFontWeight};
  word-wrap: break-word;
  display: block;
  width: 150px;
  word-wrap: break-word;
`;

export const ListItemStyled = styled.li<{ backgroundColorModals: string }>`
  display: list-item;
  padding: 3px 16px;
  &:hover {
    background-color: ${({ backgroundColorModals }) =>
      isDarkColor(backgroundColorModals)
        ? lighten(0.2, backgroundColorModals)
        : darken(0.2, backgroundColorModals)};
  }
`;

export const PisctureWrapperStyled = styled.div`
  flex-shrink: 1;
`;
