import { useEffect } from 'react';
import { usePagination } from 'react-instantsearch-hooks-web';
// as Algolia doesn't provide props types for this widget
interface Props {
  currentPage: number;
}

function VirtualPagination({ currentPage }: Props) {
  const { refine } = usePagination();

  useEffect(() => {
    refine(currentPage - 1);
  }, [refine, currentPage]);

  return null;
}

export default VirtualPagination;
