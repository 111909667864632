import get from 'lodash/get';

import { FormattedFragment, Fragment, FragmentType } from '../types';
import { getLinkHref } from 'shared/helpers/uri';
import { createTemplate } from 'shared/helpers/template';

const formatFragment = (
  fragment: Fragment,
  domainUrl: string
): FormattedFragment | undefined => {
  if (fragment.type === FragmentType.text) {
    return fragment;
  }

  if (fragment.type === FragmentType.image) {
    const link = getLinkHref(get(fragment, 'link', '')).replace(
      'http:',
      'https:'
    );

    return {
      type: FragmentType.image,
      url: getLinkHref(get(fragment, 'content.link', '')),
      alt: get(fragment, 'content.description', ''),
      link: createTemplate(link)({ domain: domainUrl })
    };
  }
  if (fragment.type === FragmentType.video) {
    return {
      type: FragmentType.video,
      videoUrl: getLinkHref(get(fragment, 'content.video.link', '')),
      posterUrl: getLinkHref(get(fragment, 'content.poster.link', ''))
    };
  }
};

export default formatFragment;
