import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg viewBox="0 0 52 50" width={width} height={height} className={className}>
    <path
      d="M26 .038l8.1 16.3 17.9 2.8-12.9 12.9 2.9 18-16-8.4-16.1 8.4 2.9-18L0 19.138l17.9-2.8z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
