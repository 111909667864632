import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Picture from 'shared/components/presentational/Picture';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

const List = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  width: 100%;
  max-width: 672px; // (60 + 24) * 8
  ${mediaQueries.toTablet`
    max-width: 496px; // (90 + 34) * 4
  `};
  ${mediaQueries.toPhablet`
    max-width: 282px; // (60 + 34) * 3
  `};
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-items: center;
  margin: 0 12px 25px;
  height: 40px;
  width: auto;
  ${mediaQueries.toTablet`
    margin: 0 17px 25px;
    height: 60px;
  `};
  ${mediaQueries.toPhablet`
    margin: 0 17px 15px;
    height: 40px;
  `};
`;

const Exponent = styled.sup`
  margin-left: 5px;
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
`;

const PictureWrapper = styled.div`
  width: 60px;
  ${mediaQueries.toTablet`
    width: 90px;
  `};
  ${mediaQueries.toPhablet`
    width: 60px;
  `};
`;

const StyledPicture = styled(Picture)`
  width: 100%;
  display: block;
`;

class ChannelsList extends PureComponent {
  render() {
    const { channels } = this.props;

    return (
      <List>
        {channels.map((channel, i) => (
          <Item key={`Channels_channel${i}`}>
            <PictureWrapper>
              <StyledPicture
                url={channel.imageUrl}
                height={37}
                alt={channel.title}
                placeholderColor="transparent"
              />
            </PictureWrapper>
            {channel.exponent && <Exponent>{channel.exponent}</Exponent>}
          </Item>
        ))}
      </List>
    );
  }
}

ChannelsList.displayName = 'Channels.List';

export default ChannelsList;
